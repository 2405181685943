/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import useAuth from '../../../hooks/useAuth'
import { GET, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { MyTooltip } from '../../../components/ToolTip/ToolTip'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type AppconfigResponseType = {
  name: string
  locationId: number
  info: string
  type: string
  background: null | string
  banner: null | string
  img: null | string
  icon: null | string
  content: {
    qtdProductsPagination: number
    useLogoMenu: boolean
    pdv: any
    webappcolor: {
      backgroundbuttonhome: string
      backgroundcart: string
      backgroundfooter: string
      backgroundheader: string
      backgroundmenu: string
      backgroundstatus: string
      backgroundstatusactive: string
      backgroundtitlebar: string
      backgroundwebapp: string
      fontbutton: string
      fontbuttonhome: string
      fontcart: string
      fontcategory: string
      fontheader: string
      fontinfocart: string
      fontmenu: string
      fontstatus: string
      fonttitlebar: string
      highlightfooter: string
      iconsfooter: string
      iconsheader: string
      icontitlebar: string
      webappbutton: string
    }
  }
}

type AppconfigResponseSendType = {
  name?: string
  locationId?: number
  info?: string
  background?: null | string
  banner?: null | string
  img?: null | string
  icon?: null | string
  content?: {
    qtdProductsPagination?: number
    useLogoMenu?: boolean
    pdv?: any
    webappcolor?: {
      backgroundbuttonhome?: string
      backgroundcart?: string
      backgroundfooter?: string
      backgroundheader?: string
      backgroundmenu?: string
      backgroundstatus?: string
      backgroundstatusactive?: string
      backgroundtitlebar?: string
      backgroundwebapp?: string
      fontbutton?: string
      fontbuttonhome?: string
      fontcart?: string
      fontcategory?: string
      fontheader?: string
      fontinfocart?: string
      fontmenu?: string
      fontstatus?: string
      fonttitlebar?: string
      highlightfooter?: string
      iconsfooter?: string
      iconsheader?: string
      icontitlebar?: string
      webappbutton?: string
    }
  }
}

type PluginconfigResponseType = {
  id: number
  name: string
  plugintypeId: number
  plugintemplateId: number
}

type PluginconfigType = {
  value: number
  select: string
  plugintypeId: number
  plugintemplateId: number
}

const ConfiguracoesContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false)
  const [restrictCep, setRestrictCep] = useState('cep')
  const [pluginconfigId, setPluginconfigId] = useState<number | null>(null)
  const [gatewayId, setGatewayId] = useState<number | null>(null)
  const [gatewayPixId, setGatewayPixId] = useState<number | null>(null)
  const [gatewayDeliveryId, setGatewayDeliveryId] = useState<number | null>(null)
  const [useImage, setUseImage] = useState(false)
  const [integrationActive, setIntegrationActive] = useState(false)
  const [gatewayActive, setGatewayActive] = useState(false)
  const [termsActive, setTermsActive] = useState(false)
  const [showOutStock, setShowOutStock] = useState(false);
  const [showLayout, setShowLayout] = useState('l1');
  const [showObservation, setShowObservation] = useState(false);
  const [initialStateAppconfig, setInitialStateAppconfig] = useState<null | AppconfigResponseType>(
    null
  )
  const [pluginconfigs, setPluginconfigs] = useState<PluginconfigType[]>([])
  const [agroupCategory, setAgroupCategory] = useState<boolean>(false);
  const [showCategoryImages, setShowCategoryImages] = useState<boolean>(false);
  const [noProductImages, setNoProductImages] = useState<boolean>(false);
  const [allowSellCnpj, setAllowSellCnpj] = useState<boolean>(false);

  const [deliveryFee, setDeliveryFee] = useState<string>('')
  const [minimumValue, setMinimumValue] = useState<string>('')

  const [appType, setAppType] = useState<string>('')
  const [ableUserLocation, setAbleUserLocation] = useState(false);

  const [checkoutMethod, setCheckoutMethod] = useState<string>('')
  const [noSales, setNoSales] = useState(false)

  const [typeImage, setTypeImage] = useState<string>('round')
  const [optionsTypeImage, setOptionTypesImage] = useState<any[]>([{
    select: 'Redondo',
    value: 'round'
  }, {
    select: 'Arredondado',
    value: 'rounded'
  }, {
    select: 'Quadrado',
    value: 'square'
  }])

  const [safeMode, setSafeMode] = useState(false)
  const [timeSafeMode, setTimeSafeMode] = useState(0);

  const [limitCharacteres, setLimitCharacters] = useState(0)
  const [sellerCartExpiration, setSellerCartExpiration] = useState('')
  const [skipEntry, setSkipEntry] = useState(false);
  const [paymentTable, setPaymentTable] = useState(false);

  const [canAlert, setCanAlert] = useState(false)

  const getAppconfig = async () => {

    try {
      setLoading(true)
      const appRes = await GET(`/appconfig/${id}`)

      const pluginconfigRes = await GET(`/pluginconfig`)
      const newPluginconfigs = pluginconfigRes.data.map(
        (pluginconfig: PluginconfigResponseType) => ({
          value: pluginconfig.id,
          select: pluginconfig.name,
          plugintypeId: pluginconfig.plugintypeId,
          plugintemplateId: pluginconfig.plugintemplateId,
        })
      )
      setPluginconfigs([
        {
          value: 0,
          select: 'Selecione um gateway',
          plugintypeId: 14,
          plugintemplateId: 0,
        },
        {
          value: 0,
          select: 'Selecione uma integração',
          plugintypeId: 0,
          plugintemplateId: 0,
        },
        ...newPluginconfigs,
      ])
      const appconfig: AppconfigResponseType = appRes.data[0]
      setInitialStateAppconfig(appconfig)
      setRestrictCep(
        appconfig.content.pdv.restrict_cep ? appconfig.content.pdv.restrict_cep : 'cep'
      )
      setPluginconfigId(appconfig.content.pdv.pluginconfig_id)
      setGatewayId(appconfig.content.pdv.gateway_config)
      setGatewayPixId(
        appconfig.content.pdv.gateway_pix_config ? appconfig.content.pdv.gateway_pix_config : null
      )
      setAgroupCategory(
        appconfig.content.pdv.agroup_category ? appconfig.content.pdv.agroup_category : false
      )
      setShowCategoryImages(
        appconfig.content.pdv.show_category_images ? appconfig.content.pdv.show_category_images : false
      )
      setNoProductImages(
        appconfig.content.pdv.no_product_images ? appconfig.content.pdv.no_product_images : false
      )
      setGatewayDeliveryId(
        appconfig.content.pdv.gateway_delivery_config
          ? appconfig.content.pdv.gateway_delivery_config
          : null
      )
      setUseImage(appconfig.content.pdv.layout.useImage)
      setIntegrationActive(appconfig.content.pdv.integration_active)
      setGatewayActive(appconfig.content.pdv.gateway_active)
      setTermsActive(appconfig.content.pdv.terms_active)
      setShowOutStock(appconfig.content.pdv.show_out_stock);
      setShowLayout(appconfig.content.pdv.show_layout ? appconfig.content.pdv.show_layout : 'l1');
      setDeliveryFee(appconfig.content.pdv.delivery_fee ? appconfig.content.pdv.delivery_fee : '0.00');
      setMinimumValue(appconfig.content.pdv.minimum_value ? appconfig.content.pdv.minimum_value : '0.00');
      setShowObservation(appconfig.content.pdv.show_observation);
      setAllowSellCnpj(appconfig.content.pdv.allowSellCnpj ? appconfig.content.pdv.allowSellCnpj : false)
      setCheckoutMethod(appconfig.content.pdv.seller_checkout ? appconfig.content.pdv.seller_checkout : 1)
      setNoSales(appconfig.content.pdv.no_sales ? appconfig.content.pdv.no_sales : false)
      setSafeMode(appconfig.content.pdv.safe_mode ? appconfig.content.pdv.safe_mode : false)
      setTimeSafeMode((appconfig.content.pdv.safe_mode && appconfig.content.pdv.time_safe_mode) ? appconfig.content.pdv.time_safe_mode : 0)
      setAppType(appconfig.type)

      setTypeImage(appconfig.content.pdv.product_image_type ? appconfig.content.pdv.product_image_type : 'round')

      setLimitCharacters(appconfig.content.pdv.limit_characteres ? appconfig.content.pdv.limit_characteres : 0)
      setSellerCartExpiration(appconfig.content.pdv.seller_cart_expiration_time ? appconfig.content.pdv.seller_cart_expiration_time : '')
      setSkipEntry(appconfig.content.pdv.skip_entry ? appconfig.content.pdv.skip_entry : false)
      setPaymentTable(appconfig.content.pdv.payment_table ? appconfig.content.pdv.payment_table : false)
      setAbleUserLocation(appconfig.content.pdv.able_user_location ? appconfig.content.pdv.able_user_location : false)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const haveBlockIntegrations = async () => {
    try {
      const pluginconfigRes = await GET(`/pluginconfig`)
      let blockItem = {
        block: false,
        name: ''
      }
      pluginconfigRes.data.forEach((pluginconfig: PluginconfigResponseType) => {
        //Integrações bloqueantes: Protheus e Live (TOTVS Varejo)
        if (pluginconfig.plugintemplateId === 104 || pluginconfig.plugintemplateId === 15) {
          blockItem = {
            block: true,
            name: pluginconfig.name
          }
        }
      })
      return blockItem;
    } catch (e) {
      return {
        name: '',
        block: false
      }
    }
  }

  const saveAppconfig = async () => {
    try {
      if (!restrictCep) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const appconfig = initialStateAppconfig
        if (appconfig?.content && appconfig?.content.pdv) {
          appconfig.content.pdv.restrict_cep = restrictCep
          appconfig.content.pdv.pluginconfig_id = pluginconfigId
          appconfig.content.pdv.gateway_config = gatewayId
          appconfig.content.pdv.gateway_pix_config = gatewayPixId
          appconfig.content.pdv.gateway_delivery_config = gatewayDeliveryId
          if (appconfig.content.pdv.layout) {
            appconfig.content.pdv.layout.useImage = useImage
          }
          appconfig.content.pdv.integration_active = integrationActive
          appconfig.content.pdv.gateway_active = gatewayActive
          appconfig.content.pdv.terms_active = termsActive
          appconfig.content.pdv.show_out_stock = showOutStock;
          appconfig.content.pdv.show_layout = showLayout;
          appconfig.content.pdv.show_observation = showObservation;
          appconfig.content.pdv.agroup_category = agroupCategory;
          appconfig.content.pdv.show_category_images = showCategoryImages;
          appconfig.content.pdv.no_product_images = noProductImages;
          appconfig.content.pdv.delivery_fee = deliveryFee;
          appconfig.content.pdv.minimum_value = minimumValue;
          appconfig.content.pdv.seller_checkout = checkoutMethod;
          appconfig.content.pdv.no_sales = noSales;
          appconfig.content.pdv.safe_mode = safeMode;
          appconfig.content.pdv.time_safe_mode = (timeSafeMode && safeMode) ? timeSafeMode : 0;
          appconfig.content.pdv.product_image_type = typeImage;
          appconfig.content.pdv.skip_entry = skipEntry;
          appconfig.content.pdv.payment_table = paymentTable;
          appconfig.content.pdv.able_user_location = ableUserLocation
          appconfig.content.pdv.seller_cart_expiration_time = sellerCartExpiration;

          if (showOutStock) {
            const cancel = await Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: `Você pode possuir integrações que impeçam a compra de produtos sem estoque. Deseja continuar?`,
              showCancelButton: true,
              cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (!result.isConfirmed) {
                return true
              } else {
                return false
              }
            })
            if (cancel) {
              setCanAlert(true)
              setLoading(false)
              return;
            }
          }

          if (allowSellCnpj) {
            //Verifica se tem integrações que bloqueam venda por CNPJ
            const blockIntegration = await haveBlockIntegrations();
            if (blockIntegration.block) {
              const cancel = await Swal.fire({
                icon: 'warning',
                title: 'Atenção!',
                text: `Você possui integrações que podem impossibilitar a venda por CNPJ (${blockIntegration.name}). Deseja continuar?`,
                showCancelButton: true,
                cancelButtonText: 'Cancelar'
              }).then((result) => {
                if (!result.isConfirmed) {
                  return true
                } else {
                  return false
                }
              })
              if (cancel) {
                setCanAlert(true)
                setLoading(false)
                return;
              }
            }
          }

          appconfig.content.pdv.allowSellCnpj = allowSellCnpj

          appconfig.content.pdv.limit_characteres = limitCharacteres ? limitCharacteres : 0
        }
        if (appconfig) {
          const appconfigRes = await PUT(`/appconfig/${id}`, {
            content: appconfig.content,
          })
          setLoading(false)
          if (appconfigRes.success === true) {
            setCanAlert(false)
            Swal.fire({
              icon: 'success',
              title: 'Configuração alterada',
              text: 'Edição realizada com sucesso!',
            }).then(() => navigate('/webapp'))
          } else {
            setCanAlert(true)
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao alterar configuração!',
            })
          }
        } else {
          setCanAlert(true)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Configuração inexistente!',
          })
        }
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar configuração!',
      })
    }
  }

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('webapp-config-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    const configSaved = localStorage.getItem('webapp-config-saved')
    if (configSaved && JSON.parse(configSaved)) loadLocal()
    else getAppconfig()
  }, [])

  useEffect(() => {
    return () => {
      if (canAlert && !window.location.pathname.includes("webapp/configuracoes")) {
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/webapp/configuracoes/${id}`)
          } else {
            localStorage.removeItem('webapp-config-saved')
          }
        })
      } else if (!canAlert) localStorage.removeItem('webapp-config-saved')
    }
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const configSaved = localStorage.getItem('webapp-config-saved')
    if (configSaved && JSON.parse(configSaved)) {
      setLoading(true)
      const jsonConfig = JSON.parse(configSaved);
      setRestrictCep(jsonConfig.restrictCep)
      setPluginconfigId(jsonConfig.pluginconfigId)
      setGatewayId(jsonConfig.gatewayId)
      setGatewayPixId(jsonConfig.gatewayPixId)
      setGatewayDeliveryId(jsonConfig.gatewayDeliveryId)
      setUseImage(jsonConfig.useImage)
      setIntegrationActive(jsonConfig.integrationActive)
      setGatewayActive(jsonConfig.gatewayActive)
      setTermsActive(jsonConfig.termsActive)
      setShowOutStock(jsonConfig.showOutStock)
      setShowLayout(jsonConfig.showLayout)
      setShowObservation(jsonConfig.showObservation)
      setInitialStateAppconfig(jsonConfig.initialStateAppconfig)
      setPluginconfigs(jsonConfig.pluginconfigs)
      setAgroupCategory(jsonConfig.agroupCategory)
      setShowCategoryImages(jsonConfig.showCategoryImages)
      setNoProductImages(jsonConfig.noProductImages)
      setAllowSellCnpj(jsonConfig.allowSellCnpj)
      setDeliveryFee(jsonConfig.deliveryFee)
      setMinimumValue(jsonConfig.minimumValue)
      setAppType(jsonConfig.appType)
      setCheckoutMethod(jsonConfig.checkoutMethod)
      setNoSales(jsonConfig.noSales)
      setTypeImage(jsonConfig.typeImage)
      setOptionTypesImage(jsonConfig.optionsTypeImage)
      setSafeMode(jsonConfig.safeMode)
      setTimeSafeMode(jsonConfig.timeSafeMode)
      setLimitCharacters(jsonConfig.limitCharacteres)
      setSellerCartExpiration(jsonConfig.sellerCartExpiration)
      setSkipEntry(jsonConfig.skipEntry)
      setPaymentTable(jsonConfig.paymentTable)
      localStorage.removeItem('webapp-config-saved')
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }

  const saveLocal = () => {
    localStorage.setItem('webapp-config-saved', JSON.stringify({
      restrictCep,
      pluginconfigId,
      gatewayId,
      gatewayPixId,
      gatewayDeliveryId,
      useImage,
      integrationActive,
      gatewayActive,
      termsActive,
      showOutStock,
      showLayout,
      showObservation,
      initialStateAppconfig,
      pluginconfigs,
      agroupCategory,
      showCategoryImages,
      noProductImages,
      allowSellCnpj,
      deliveryFee,
      minimumValue,
      appType,
      checkoutMethod,
      noSales,
      typeImage,
      optionsTypeImage,
      safeMode,
      timeSafeMode,
      limitCharacteres,
      skipEntry,
      paymentTable
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    restrictCep,
    pluginconfigId,
    gatewayId,
    gatewayPixId,
    gatewayDeliveryId,
    useImage,
    integrationActive,
    gatewayActive,
    termsActive,
    showOutStock,
    showLayout,
    showObservation,
    initialStateAppconfig,
    pluginconfigs,
    agroupCategory,
    showCategoryImages,
    noProductImages,
    allowSellCnpj,
    deliveryFee,
    minimumValue,
    appType,
    checkoutMethod,
    noSales,
    typeImage,
    optionsTypeImage,
    safeMode,
    timeSafeMode,
    limitCharacteres,
    skipEntry,
    paymentTable
  ])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`webapp.settings_webapp`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center gap-5'>
                {/* <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    value={restrictCep}
                    change={(value: string) => {
                      setCanAlert(true)
                      setRestrictCep(value)
                    }}
                    placeholder='Selecione um item'
                    label={i18n.t(`webapp.type_delivery`)}
                    options={[
                      { select: 'Raio', value: 'area' },
                      { select: 'CEP', value: 'cep' },
                      { select: 'Mapa', value: 'mapa' },
                    ]}
                  />
                </div> */}
                {/* <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    placeholder='Selecione um item'
                    label={i18n.t(`webapp.integration`)}
                    value={pluginconfigId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setPluginconfigId(parseInt(value))
                    }}
                    options={pluginconfigs.filter(
                      (pluginconfig: PluginconfigType) => pluginconfig.plugintypeId !== 14
                    )}
                  />
                </div> */}
              </div>
              {/* <div className='d-flex justify-content-between align-items-center gap-5'>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    placeholder='Selecione um item'
                    label={i18n.t(`webapp.payment_method_card`)}
                    value={gatewayId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setGatewayId(parseInt(value))
                    }}
                    options={pluginconfigs.filter(
                      (pluginconfig: PluginconfigType) => pluginconfig.plugintypeId === 14
                    )}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    placeholder='Selecione um item'
                    label={i18n.t(`webapp.payment_method_pix`)}
                    value={gatewayPixId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setGatewayPixId(parseInt(value))
                    }}
                    options={pluginconfigs.filter(
                      (pluginconfig: PluginconfigType) => pluginconfig.plugintypeId === 14
                    )}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    placeholder='Selecione um item'
                    label={i18n.t(`webapp.payment_method_delivery`)}
                    value={gatewayDeliveryId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setGatewayDeliveryId(parseInt(value))
                    }}
                    options={pluginconfigs.filter(
                      (pluginconfig: PluginconfigType) =>
                        pluginconfig.plugintypeId === 14 &&
                        (pluginconfig.plugintemplateId === 44 ||
                          pluginconfig.plugintemplateId === 0)
                    )}
                  />
                </div>
              </div> */}
              {loginInfo.plugintype === 'food' &&
                <>
                  <div className='d-flex justify-content-between align-items-center gap-5'>
                    <div className='flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        placeholder='Selecione um item'
                        label={i18n.t(`webapp.integration`)}
                        value={pluginconfigId}
                        change={(value: string) => {
                          setCanAlert(true)
                          setPluginconfigId(parseInt(value))
                        }}
                        options={pluginconfigs.filter(
                          (pluginconfig: PluginconfigType) => pluginconfig.plugintypeId !== 14
                        )}
                      />
                    </div>
                    <div className='flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        placeholder={i18n.t(`webapp.show_layout`)}
                        label={i18n.t(`webapp.show_layout`)}
                        value={showLayout}
                        change={(value: string) => {
                          setCanAlert(true)
                          setShowLayout(value)
                        }}
                        options={[
                          { value: 'l1', select: 'Legado' },
                          { value: 'l2', select: 'Novo' },
                        ]}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center gap-5'>
                    <div className='flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'money',
                        }}
                        locale="pt-BR"
                        currency='BRL'
                        label={i18n.t(`webapp.withdraw_fee`)}
                        value={deliveryFee}
                        change={(value: string) => {
                          setCanAlert(true)
                          setDeliveryFee(value)
                        }}

                      />
                    </div>
                    <div className='flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'money',
                        }}
                        locale="pt-BR"
                        currency='BRL'
                        label={i18n.t(`webapp.minimum_value`)}
                        value={minimumValue}
                        change={(value: string) => {
                          setCanAlert(true)
                          setMinimumValue(value)
                        }}

                      />

                    </div>

                    {safeMode && (
                      <div className='flex-grow-1'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          minNumber={0}
                          label={i18n.t(`webapp.time_safe_mode`)}
                          value={timeSafeMode}
                          change={(value: string) => {
                            setCanAlert(true)
                            setTimeSafeMode(parseInt(value))
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              }
              {(loginInfo.plugintype !== 'food') && (
                <div className='d-flex justify-content-between align-items-center gap-5'>
                  <div className='flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      placeholder='Selecione um item'
                      label={i18n.t(`webapp.integration`)}
                      value={pluginconfigId}
                      change={(value: string) => {
                        setCanAlert(true)
                        setPluginconfigId(parseInt(value))
                      }}
                      options={pluginconfigs.filter(
                        (pluginconfig: PluginconfigType) => pluginconfig.plugintypeId !== 14
                      )}
                    />
                  </div>
                  <div className='flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'money',
                      }}
                      locale="pt-BR"
                      currency='BRL'
                      label={i18n.t(`webapp.withdraw_fee`)}
                      value={deliveryFee}
                      change={(value: string) => {
                        setCanAlert(true)
                        setDeliveryFee(value)
                      }}

                    />
                  </div>
                  <div className='flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'money',
                      }}
                      locale="pt-BR"
                      currency='BRL'
                      label={i18n.t(`webapp.minimum_value`)}
                      value={minimumValue}
                      change={(value: string) => {
                        setCanAlert(true)
                        setMinimumValue(value)
                      }}

                    />

                  </div>
                  <div className='flex-grow-1'></div>

                </div>
              )

              }
              {(appType === 'seller') && (
                <div className='d-flex justify-content-between align-items-center gap-5'>
                  <div className='flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      placeholder={i18n.t(`webapp.seller_checkout`)}
                      label={i18n.t(`webapp.seller_checkout`)}
                      value={checkoutMethod}
                      change={(value: string) => {
                        setCanAlert(true)
                        setCheckoutMethod(value)
                      }}
                      options={[
                        { value: '1', select: 'Link do Carrinho' },
                        { value: '2', select: 'Link de Pagamento' },
                      ]}
                    />
                  </div>
                  <div className='flex-grow-1'>

                  </div>
                  <div className='flex-grow-1'>

                  </div>
                </div>
              )

              }
              {loginInfo.plugintype === 'food' && showLayout == 'l1' &&
                <div className='d-flex justify-content-between align-items-center gap-5'>
                  <div className='col-3'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      placeholder={i18n.t(`webapp.image_type`)}
                      label={i18n.t(`webapp.image_type`)}
                      value={typeImage}
                      change={(value: string) => {
                        setCanAlert(true)
                        setTypeImage(value)
                      }}
                      options={optionsTypeImage}
                    />
                  </div>
                </div>

              }

              {showObservation &&
                <div className='d-flex justify-content-between align-items-center gap-5'>
                  <div className='col-3'>
                    <Input
                      inputAttr={{
                        type: 'number',
                      }}
                      placeholder={i18n.t(`webapp.limit_characteres`)}
                      label={i18n.t(`webapp.limit_characteres`)}
                      value={limitCharacteres}
                      change={(value: number) => {
                        setCanAlert(true)
                        setLimitCharacters(value)
                      }}
                      minNumber={0}
                      toolTip={true}
                      toolTipIcon={'info-circle'}
                      toolTiptext={String(i18n.t(`webapp.limit_characteres`)).concat('.').concat(i18n.t('webapp.limit_characteres_explanation'))}

                    />
                  </div>
                </div>

              }
              {(loginInfo.plugintype === 'fashion' || loginInfo.plugintype === 'varejo') && !(appType === 'seller') &&
                <div className='d-flex justify-content-between align-items-center gap-5'>
                  <div className='col-3'>
                    <Input
                      inputAttr={{
                        type: 'hour',
                      }}
                      size='small'
                      label={i18n.t(`webapp.seller_cart_limit_time`)}
                      value={sellerCartExpiration}
                      change={(value: string) => {
                        setCanAlert(true)
                        setSellerCartExpiration(value)
                      }}
                      minNumber={0}
                      toolTip={true}
                      toolTipIcon={'info-circle'}
                      toolTiptext={String(i18n.t(`webapp.seller_cart_limit_time_explanation`))}

                    />
                  </div>
                </div>

              }

              <div className='d-flex justify-content-between'>
                <div className='w-50 mt-8'>
                  {/* <>
                  <Input
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      label='Listar produtos com foto'
                      checked={useImage}
                      value={useImage}
                      change={(value: boolean) => setUseImage(value)}
                    />

                    <Input
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      label='Ativar Integração PDV'
                      checked={integrationActive}
                      value={integrationActive}
                      change={(value: boolean) => setIntegrationActive(value)}
                    />

                    <Input
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      label='Ativar Gateway'
                      checked={gatewayActive}
                      value={gatewayActive}
                      change={(value: boolean) => setGatewayActive(value)}
                    />
                  
                  </> */}
                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={i18n.t(`webapp.consent_letter`)}
                    checked={termsActive}
                    value={termsActive}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setTermsActive(value)
                    }}
                  />

                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={i18n.t(`webapp.product_no_stock`)}
                    checked={showOutStock}
                    value={showOutStock}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setShowOutStock(value)
                    }}
                    toolTip={true}
                    toolTipIcon={'info-circle'}
                    toolTiptext={'Para mais informações acesse o link: '}
                    toolTipLink={'https://tdn.totvs.com/pages/viewpage.action?pageId=806772680'}
                  />

                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={i18n.t(`webapp.show_observation`)}
                    checked={showObservation}
                    value={showObservation}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setShowObservation(value)
                    }}
                  />

                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label='Permitir venda por CNPJ'
                    checked={allowSellCnpj}
                    value={allowSellCnpj}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setAllowSellCnpj(value)
                    }}
                  />

                  {loginInfo.plugintype !== 'food' &&
                    <Input
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      label='Agrupar Categorias'
                      checked={agroupCategory}
                      value={agroupCategory}
                      change={(value: boolean) => {
                        setCanAlert(true)
                        setAgroupCategory(value)
                      }}
                    />
                  }

                  {loginInfo.plugintype === 'food' &&
                    <>

                      <Input
                        inputAttr={{
                          type: 'checkbox',
                        }}
                        label='Listar categorias com foto'
                        checked={showCategoryImages}
                        value={showCategoryImages}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          setShowCategoryImages(value)
                        }}
                        toolTip={true}
                        toolTipIcon={'info-circle'}
                        toolTiptext={'Para mais informações acesse o link: '}
                        toolTipLink={'https://tdn.totvs.com/display/public/TChef/API+-+Pedidos+Online%3A+Delivery'}
                      />




                      <Input
                        inputAttr={{
                          type: 'checkbox',
                        }}
                        label={i18n.t(`webapp.safe_mode`)}
                        checked={safeMode}
                        value={safeMode}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          setSafeMode(value)
                        }}
                        toolTip={true}
                        toolTipIcon={'info-circle'}
                        toolTiptext={'Para mais informações acesse o link: '}
                        toolTipLink={'https://tdn.totvs.com/display/public/TChef/API+-+Pedidos+Online%3A+Delivery'}
                      />

                      <Input
                        inputAttr={{
                          type: 'checkbox',
                        }}
                        label={i18n.t(`webapp.payment_table`)}
                        checked={paymentTable}
                        value={paymentTable}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          setPaymentTable(value)
                        }}
                      />
                    </>
                  }

                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={i18n.t(`webapp.no_sales`)}
                    checked={noSales}
                    value={noSales}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setNoSales(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={i18n.t(`webapp.skip_entry`)}
                    checked={skipEntry}
                    value={skipEntry}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setSkipEntry(value)
                    }}
                  />

                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={i18n.t(`webapp.ableUserLocation`)}
                    checked={ableUserLocation}
                    value={ableUserLocation}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setAbleUserLocation(value)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    setCanAlert(false)
                    saveAppconfig()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}



const ConfiguracoesWebapp: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Webapp', path: 'webapp', isActive: true },
          { title: i18n.t(`webapp.settings_webapp`), path: 'webapp/configuracoes', isActive: true },
        ]}
      >
        {i18n.t(`webapp.settings_webapp`)}
      </PageTitle>
      <ConfiguracoesContent />
    </>
  )
}

export { ConfiguracoesWebapp }
