/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType, OrderType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, PUT, POST, DELETE } from '../../../../services/api'
import useModals from '../../../../hooks/useModals'
import { Loading } from '../../../../components/Loading/Loading'
import { Dropdown } from 'react-bootstrap'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type CategoryType = {
  id: number
  parent: string
  code: string
  name: string
  shortname: string
  order: number
  img: string
  status: boolean
  subitems: CategoryType[]
}

type ConfigType = {
  id: number;
  type: string;
  content: {
    orderCategory: string;
  }
}

type PluginconfigType = {
  name: string;
  plugintemplateId: number;
  deleted: string;
}

const CategoriasContent: FC = () => {
  const navigate = useNavigate()
  const { showDeleteModal, showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const [fileToImport, setFileToImport] = useState<File | null>(null)
  const [hasIntegration, setHasIntegration] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [qtdCategorias, setqtdCategorias] = useState(0)

  const [options, setOptions] = useState<any>([
    {
      icon: 'fas fa-pen',
      title: 'Editar categoria',
      action: (item: any) => {
        navigate(`/category/editar/${item.id}`)
      },
    }
  ])

  const verifyChildAndSwitch = (category:CategoryType, Id: number) => {
    category.subitems.forEach((subcategory: CategoryType) => {
      if (subcategory.id === Id) {
        subcategory.status = !subcategory.status
      }
      if(subcategory.subitems && subcategory.subitems.length > 0)
        verifyChildAndSwitch(subcategory, Id)
    })
  }

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchClassification = await PUT(`/classification/${Id}`, {
        status: !status,
      })
      if (switchClassification.success) {
        const newCategories = categories.map((category:CategoryType) => {
          if (category.id === Id) {
            category.status = !status
          }
          if(category.subitems && category.subitems.length > 0)
            verifyChildAndSwitch(category, Id)
          return category
        })
        setCategories(newCategories)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const searchCategoryVerify = (category: CategoryType, search: string) => {
    if (category.name.toLowerCase().includes(search.toLowerCase())) {
      return true
    }
    let haveSubcategorySearch = false
    category.subitems.forEach((subcategory) => {
      if (subcategory.name.toLowerCase().includes(search.toLowerCase())) {
        haveSubcategorySearch = true
      } else {
        haveSubcategorySearch = searchCategoryVerify(subcategory, search)
      }
    })
    return haveSubcategorySearch
  }

  let filterData = (array: any, search: string, searchFields: any[]) => {
    const arrayCopy = JSON.parse(JSON.stringify(array))

    return arrayCopy.filter((item: any) => {
      let found = searchFields.some((field) => {
        return item[field].toLowerCase().indexOf(search.toLowerCase()) > -1
      })

      if (item.subitems.length > 0) {
        item.subitems = filterData(item.subitems, search, searchFields)
      }

      return found || item.subitems.length > 0
    })
  }
  const getConfigOrder = async () => {
    try {
      const res = await GET(`/appconfig`);
      if (res.data && res.data.length > 0) {
        const config: ConfigType = res.data.find((conf: ConfigType) => conf.type === 'qr');
        if (config && config) {
          return config.content.orderCategory
        }
      }
      return 'category'
    } catch (e) {
      return 'category'
    }
  }

  const getCategories = async (take: number = 10, page: number = 1, search: string = '', order: OrderType = {field: 'name', type: 'ASC'}) => {
    try {
      const res = await GET(`/classification/categories?page=${page}&search=${search}&take=${take}&field=${order.field}&order=${order.type}`)
      const resSub = await GET(`/classification/`)




      const newCategories = res.data.map((row: CategoryType) => {
        return {
          id: row.id,
          name: `${row.name}`,
          status: row.status,
          code: row.code,
          parent: row.parent,
          order: row.order
        }
      })




      const newSubcategories = resSub.data.map((row: CategoryType) => {
        return {
          id: row.id,
          name: `${row.name}`,
          status: row.status,
          code: row.code,
          parent: row.parent,
          order: row.order,
        }
      })

      // newSubcategories.sort((a: CategoryType, b: CategoryType) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : ((b.name > a.name) ? -1 : 1)))


      newCategories.forEach((category: CategoryType) => {
        category.subitems = newSubcategories.filter(
          (categoryChild: CategoryType) =>
            categoryChild.parent === category.code && categoryChild.code !== category.code
        )
      })
      newSubcategories.forEach((category: CategoryType) => {
        category.subitems = newSubcategories.filter(
          (categoryChild: CategoryType) =>
            categoryChild.parent === category.code && categoryChild.code !== category.code
        )
      })
      const filterCategories = filterData(newCategories, search, ['name'])
      // const ordenation = await getConfigOrder();
      // if (ordenation === 'order') {
      //   const tempCategories = filterCategories.sort((a: CategoryType, b: CategoryType) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      //   setCategories(tempCategories.sort((a: CategoryType, b: CategoryType) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)))
      // } else {
      //   setCategories(filterCategories.sort((a: CategoryType, b: CategoryType) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)))
      // }
      setCategories(filterCategories)
      setqtdCategorias(resSub.meta.itemCount)

      setMeta(res.meta)
    } catch (e) {
      console.log(e)
    }
  }

  const verifyIntegration = async () => {

    try {
      const res = await GET(`/pluginconfig`);
      const totvsIntegration = res.data.filter((pluginconfig: PluginconfigType) => pluginconfig.plugintemplateId === 23 || pluginconfig.plugintemplateId === 15 || pluginconfig.plugintemplateId === 45 || pluginconfig.plugintemplateId === 48 || pluginconfig.plugintemplateId === 104)
      if (totvsIntegration && totvsIntegration.length > 0) {
        setHasIntegration(true);
      } else {
        setOptions(
          [
            {
              icon: 'fas fa-pen',
              title: 'Editar categoria',
              action: (item: any) => {
                navigate(`/category/editar/${item.id}`)
              },
            },
            {
              icon: 'fas fa-trash',
              action: (item: any) => {
                showDeleteModal('Excluir categoria')
                setId(item.id)
              },
              path: '',
              title: 'Excluir categoria',
            },
          ]
        )
        setHasIntegration(false);
      }
    } catch (e) {
      setHasIntegration(false);
      console.log(e)
    }
  }

  const deleteCategory = async () => {
    try {
      setLoading(true)
      const markRes = await DELETE(`/classification/${id}`)
      if (markRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Categoria deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getCategories())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a categoria!',
        }).then(async () => await getCategories())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a categoria!',
      }).then(async () => await getCategories())
      console.log(e)
    }
  }

  const deleteAllCategories = async () => {
    try {
      setLoading(true)
      const markRes = await POST(`/classification/all`)

      if (markRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Categorias deletadas',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getCategories())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a categoria!',
        }).then(async () => await getCategories())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a categoria!',
      }).then(async () => await getCategories())
      console.log(e)
    }
  }

  const importCategories = async () => {

    try {
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToImport)

        const importRes = await POST('/classification/import', formData)
        setLoading(false)
        if (importRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Importação realizada com sucesso!',
          }).then(async () => await getCategories())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao importar arquivo!',
          }).then(async () => await getCategories())
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getCategories())
    }
    setFileToImport(null)
  }

  async function getAllData() {
    setLoading(true)
    await Promise.all([
      verifyIntegration(),
      getCategories()
    ])

    setLoading(false)
  }

  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Categorias</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listadas as categorias juntamente com suas respectivas ações.
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar categoria'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/category/criar')}
                  btnClass='mb-5 fw-bolder'
                />
                {!hasIntegration && (
                  <Dropdown
                    className='btn btn-primary btn-sm fw-bolder me-5 p-0 mb-5'
                    style={{ marginLeft: '1em' }}
                  >
                    <Dropdown.Toggle variant='default' size='sm' >
                      <i className='bi bi-three-dots fs-4 p-0'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='p-0'>
                      <Dropdown.Item
                        onClick={() => {
                          showWrapperModal('Importar CSV')
                        }}
                        className='p-0 d-flex flex-row justify-content-center'
                      >
                        <div className='btn m-4 d-flex flex-row justify-content-center align-items-center' style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}>
                          <i className="bi bi-box-seam" style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}></i>
                          <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                            Importar CSV
                          </p>
                        </div>
                      </Dropdown.Item>
                      {(qtdCategorias > 1) && (
                        <>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            onClick={() => {
                              Swal.fire({
                                icon: 'warning',
                                title: `Atenção!`,
                                html: `
                              Cuidado ao realizar a exclusão, os dados não poderão ser <b>restaurados!<b/>
                              <div style="margin-top: 8">Digite <b>excluir</b> para confirmar!</div>`,
                                showCloseButton: true,
                                showConfirmButton: true,
                                confirmButtonColor: '#dc3741',
                                confirmButtonText: 'Excluir',
                                showCancelButton: false,
                                input: 'text',
                                inputPlaceholder: 'excluir',
                                preConfirm: (text) => {
                                  setConfirmText(text)
                                },
                              }).then(async (res) => {
                                if (res.isConfirmed && res.value && res.value === 'excluir') {
                                  await deleteAllCategories()
                                } else {
                                  Swal.close()
                                }
                              })
                            }}
                            className='p-0 d-flex flex-row justify-content-center'
                          >
                            <div className='btn m-4 d-flex flex-row justify-content-center align-items-center' style={{ color: '#F64E60', backgroundColor: '#FFE2E5', width: '90%' }}>
                              <i className="bi bi-x" style={{ color: '#F64E60', fontSize: '1.5rem', marginRight: '0.5em' }}></i>
                              <p style={{ color: '#F64E60', fontSize: '1rem' }} className='m-0'>
                                Excluir tudo
                              </p>
                            </div>
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={categories}
                headers={[
                  { key: 'Nome', value: 'name', type: 'default' },
                  { key: 'Código', value: 'code', type: 'default' },
                  { key: 'Order', value: 'order', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: 'Ações', value: 'acoes', type: '', noOrder: true },
                ]}
                options={options}
                color='primary'
                meta={meta}
                onChangeData={async (take, page, search, order) => await getCategories(take, page, search, order)}
                search
              />
            </div>
          </div>
          <ModalDelete
            text="delete_classification_observation"
            confirmAction={() => deleteCategory()}
          />
          <ModalWrapper
            confirmAction={() => importCategories()}
          >

            <div className='flex-grow-1'>
              <p>Atenção</p>
              <p>
                Para importar categorias, a primeira linha do arquivo tem que seguir os seguintes
                padrãos:
              </p>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '30%' }}>
                  <Button
                    text={'Modelo'}
                    color='primary'
                    iconColor='#FFF'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      window.open('/file/modelo-categorias.xlsx', '')
                    }}
                    btnClass='fw-bolder me-5'
                  />
                </div>
              </div>
            </div>
            <br />
            <div className='flex-grow-1 text-center'>
              <Input
                inputAttr={{
                  type: 'file',
                }}
                acceptFile={'.xlsx,.xls'}
                change={(value: BaseSyntheticEvent) =>
                  setFileToImport(value ? value.target.files[0] : null)
                }
              />
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const Categorias: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Categorias', path: 'category', isActive: true },
        ]}
      >
        Categorias
      </PageTitle>
      <CategoriasContent />
    </>
  )
}

export { Categorias }
