const messages= {
  pt: {
    translations: {
      permissions: {
        food: 'Smart Order',
        smartorder: 'Catálogo',
        category: 'Categorias',
        subcategory: 'Subcategorias',
        product: 'Produtos',
        mark: 'Marcas',
        grid: 'Grades',
        variation: 'Variações',
        catalog: 'Catálogos',
        integration: 'Integrações',
        int_pos: 'PDV',
        int_gateway: 'Meio de Pagamento',
        messaging: 'Mensageria',
        sms: 'Status e SMS',
        multichannel: 'Multicanal',
        report: 'Relatórios',
        saleorder: 'Pedidos',
        indicators: 'Indicadores',
        orderReport: 'Pedidos',
        imports: 'Importação de Imagens',
        importProductImage: 'Produtos',
        importProductImageSKU: 'Variações',
        importCategoryImage: 'Categorias',
        customers: 'Clientes',
        customer_report: 'Lista de Clientes',
        measurement_unit: 'Unidades de Medida',
        logs: 'Logs',
        settings: 'Configurações',
        charge_integration: 'Cargas',
        summaryAccess: 'Acessos',
        orders: 'Pedidos',
        support: 'Suporte',
        application: 'Webapp',
        messenger: 'Mensageria',
        others: 'Outros',
        settings_store: 'Configurações da Loja',
        settings_hotel: 'Configurações do Hotel',
        modalities: 'Modalidades',
        delivery_tax: 'Taxa de entrega',
        shifts: 'Turnos',
        payment_plans: 'Planos de Pagamento',
        settings_account: 'Configurações da Conta',
        account: 'Conta',
        groups: 'Grupos',
        users: 'Usuários',
        terms: 'Termos',
        highlights: 'Destaques',
        menu: 'Cardápio',
        productReport: 'Produtos',
        optional: 'Opcionais',
        locations: 'Setores',
        service: 'Serviços',
        service_items: 'Serviços',
        service_category: 'Categorias',
        feed: 'Feed',
        content: 'Conteúdo',
        queue: 'Fila de espera',
        queue_config: 'Configurações',
        queue_manager: 'Gerenciamento',
        checkout: 'Dados do Cliente',
        booking: 'Reserva',
        booking_management: 'Gerenciamento',
        booking_config: 'Configurações',
        booking_environment: 'Ambientes',
        booking_day: 'Reservas do dia',
        booking_report: 'Relatórios',
        liveShop: 'Catálogo ao Vivo',
        icon_library: 'Biblioteca de Ícones',
        stores: 'Lojas/Clientes',
        segments: 'Segmentos',
        payment: 'Pagamentos',
        serviceCategory: 'Categoria do Serviço',
        SDS: 'KDS',
        sds_manage: 'Meus KDS',
        sds_layout: 'Personalização',
        sds_link: 'Configurações',
        sds_display: 'Displays',
        /* Agregador */
        config: 'Configurações',
        config_account: 'Dados da conta',
        config_sector: 'Setor de atuação',
        config_user: 'Usuários',
        config_term: 'Termos',
        subCategory: 'Subcategoria',
        footer: 'Recursos do rodapé',
        footerFeature: 'Habilitar recursos',
        footerContent: 'Feed de recursos',
        footerContentAddEdit: 'Recursos',
        webapp: 'Personalizar',
        webapp_qrcode: 'Gerar QR Code',
        ia_menu: 'Inteligência Artificial',
        ia_config: 'Configurações',
        ia_fit: 'Treinamento',
        publication_highlight_banner: 'Banner Destaque',
        publication: "Publicidade"
      },

      buttons: {
        create: 'Criar',
        back: 'Voltar',
        register_pluginspace: 'Cadastrar Pluginspace',
        access: 'Acessar',
        add_modality: 'Adicionar modalidade',
        add_km: 'Adicionar Km',
        zip_code_list: 'Lista de CEPS',
        add_zip_code: 'Adicionar CEP',
        area: 'Áreas de Frete',
        list_of_negative_renge_zip_code: 'Lista de CEPS negativos',
        add_negative_zip_code: 'Adicionar CEP negativo',
        add_user: 'Adicionar usuário',
        generate_password: 'Gerar senha',
        generate_sku: 'Gerar SKU',
        add_group: 'Adicionar grupo',
        save: 'Salvar',
        add_term: 'Adicionar termo',
        add_integration: 'Adicionar integração',
        filter: 'Filtrar',
        search: 'Procurar',
        register_product: 'Cadastrar Produto',
        choose_files: 'Escolher arquivos',
        add_categories: 'Adicionar categorias',
        add_subcategories: 'Adicionar subcategorias',
        add_brand: 'Adicionar marca',
        close: 'Fechar',
        add_grade: 'Adicionar grade',
        add: 'Adicionar',
        add_variation: 'Adicionar variação',
        confirm_grades: 'Confirmar grades',
        add_catalog: 'Adicionar catálogo',
        generete_qr_code: 'Gerar QR Code',
        personalize: 'Personalizar',
        settings: 'Configurações',
        add_interval: 'Adicionar intervalo',
        add_gateway: 'Adicionar gateway',
        overview: 'Visão geral',
        view: 'Visualizar',
        load_csv: 'Carregar arquivo',
        download_csv: 'Download XLSX Padrão',
        att_page: 'Atualizar página',
        export: 'Exportar',
        add_service: 'Cadastrar Serviço',
        add_environment: 'Adicionar ambiente',
      },

      general: {
        delete_item: 'Deseja deletar este item?',
        delete_product_observation: 'As variações desse produto também serão excluidas, deseja continuar mesmo assim?',
        delete_classification_observation: 'Excluindo essa categoria, todos os produtos relacionados a ela vão para a Geral, deseja continuar?',
        delete: 'Deletar',
        edit: 'Editar',
        attention: 'Atenção',
        actions: 'Ações',
        here_are_listed_the_modalities_along_with_their_respective_actions:
        'Aqui estão listados os itens juntamente com suas respectivas ações',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
        'Preencha as informações abaixo e clique no botão criar para salvar as alterações',
        save_changes_edit:
        'Preencha as informações abaixo e clique no botão salvar para confirmar as alterações',
        active: 'Ativo',
        inactive: 'Inativo',
        showing: 'Mostrando',
        of: 'de',
        to: 'até',
        records: 'registros',
        today: 'Hoje',
        copy: 'Copiar',
        linkS3: 'Link',
        linkedS3: 'Linked',
        downloadS3: 'Download',
        downloadedS3: 'Download Iniciada',
        copied: 'Copiado',
        copy_link: 'Copiar link do vendedor',
        test: 'Testar'
      },

      pluginspaces: {
        hospitality: 'Hotéis',
        food: 'Food',
        healthcare: 'Hospitalidade',
        varejo: 'Varejo',
        fashion: 'Moda',
      },

      dashboard: {
        totals_by_products: 'Totais por produto',
        totals_by_category: 'Totais por categoria',
        last_orders: 'Útimos pedidos',
        products: 'Produtos',
        quantity: 'Quantidade',
        total: 'Total',
        category: 'Categoria',
      },

      modalities: {
        modalities: 'Modalidades',
        here_are_listed_the_modalities_along_with_their_respective_actions:
        'Aqui estão listadas as modalidades juntamente com suas respectivas ações',
        delivery: 'Entrega',
        withdraw: 'Retirada',
        active: 'Ativo',
        inactive: 'Inativo',
        name: 'Nome',
        code: 'Código',
        actions: 'Ações',
        search: 'Procurar',
        type: 'Tipo',
        sector_name: 'Nome do setor',
        table: 'Mesa',
        room: 'Quarto',
        counter: 'Balcão',
        internal: 'Entrega Interna',
      },

      delivery_fee: {
        delivery_fee: 'Taxa de entrega',
        create_delivery_fee: 'Criar taxa de entrega',
        edit_delivery_fee: 'Editar taxa de entrega',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
        'Preencha as informações abaixo e clique no botão criar para salvar as alterações',
        save_changes_edit:
        'Preencha as informações abaixo e clique no botão salvar para confirmar as alterações',
        km_min: 'Km Mínimo',
        km_max: 'Km Máximo',
        value: 'Valor',
        actions: 'Ações',
        patterns: 'Padrões de Importação',
        import_file:
        'Para importar os arquivos a primeira célula do arquivo tem que seguir um dos seguintes padrões:',
        import_delivery: 'Para importar por range: kmmin, kmmax, Value',
        import_cep: 'Para importar CEP: cep_inicio, cep_fim, valor',
        import_cep_negative: 'Para importar CEP negativo: cep_inicio, cep_fim',
        model: 'Modelo',
        continue_import:
        'Todos seus registros serão substituídos, você tem certeza que deseja continuar?',
        cep_delivery: 'CEPs de Entrega',
        cep_info: 'Aqui estão listados os CEP`s de entregas juntamente com suas respectivas ações.',
        cep_start: 'CEP Início',
        cep_end: 'CEP Fim',
        create_cep: 'Criar CEPs de Entrega',
        edit_cep: 'Editar CEPs de Entrega',
        cep_delivery_negative: 'CEPs Negativos de Entrega',
        cep_info_negative:
        'Aqui estão listados os CEP`s negativos de entregas juntamente com suas respectivas ações.',
        create_cep_negative: 'Criar CEP Negativo',
        edit_cep_negative: 'Editar CEP Negativo',
        delete_cep_negative: 'Deletar CEP Negativo',
      },

      user: {
        user: 'Usuários',
        here_are_listed_the_users_along_with_their_respective_actions:
        'Aqui estão listados os usuários juntamente com suas respectivas ações',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
        'Preencha as informações abaixo e clique no botão criar para salvar as alterações',
        save_changes_edit:
        'Preencha as informações abaixo e clique no botão salvar para confirmar as alterações',
        picture: 'Foto',
        name: 'Nome',
        type: 'Tipo',
        registration: 'Cadastro',
        create_user: 'Criar usuários',
        edit_user: 'Editar usuário',
        profile_picture: 'Imagem do perfil',
        password: 'Senha',
        generate_pass: 'Gerar Senha',
        access_level: 'Grupo de acesso',
        select_a_access_level: 'Selecione um grupo de acesso',
        select_type: 'Selecione um tipo',
        active: 'Ativo',
        actions: 'Ações',
        discount_permission: 'Permissão para desconto na venda',
        discount_value: 'Valor do desconto máximo (%)',
        phone: 'Número do telefone'
      },

      groups: {
        groups: 'Grupos',
        here_are_listed_the_groups_along_with_their_respective_actions:
        'Aqui estão listados os grupos juntamente com suas respectivas ações',
        name: 'Nome',
        Integrations: 'Integrações',
        means_of_payment: 'Meio de pagamento',
        products: 'Produtos',
        category: 'Categorias',
        subcategory: 'Subcategoria',
        indicators: 'Indicadores',
        requests: 'Pedidos',
        reports: 'Relatórios',
        messenger: 'Mensageria',
      },

      shifts: {
        shifts: 'Turnos',
        here_are_listed_the_shifts_for_each_day_of_the_week:
        'Aqui estão listados os turnos para cada dia da semana',
        monday: 'Segunda',
        tuesday: 'Terça',
        wednesday: 'Quarta',
        thursday: 'Quinta',
        friday: 'Sexta',
        saturday: 'Sábado',
        sunday: 'Domingo',
        start: 'Início',
        end: 'Fim',
      },

      account: {
        account: 'Conta',
        edit_account: 'Editar conta',
        registration_number: 'CNPJ',
        corporate_name: 'Razão social',
        name_of_the_establishment: 'Nome do estabelecimento',
        subdomain: 'Subdomínio',
        code: 'Código',
        default_language: 'Idioma padão',
        default_currency: 'Moeda padrão',
        login_of_person_in_charge: 'Login do responsável da conta',
        name_of_person_in_charge: 'Nome do responsável',
        address: 'Endereço',
        zip_code: 'CEP',
        number: 'Número',
        neighbourhood: 'Bairro',
        city: 'Cidade',
        state: 'Estado',
        public_place: 'Logradouro',
        address_complement: 'Complemento',
      },

      terms: {
        terms: 'Termos',
        here_are_the_terms_of_acceptance_of_the_sales_platform:
        'Aqui estão listados os termos de aceite da plataforma de vendas',
        create_your_term: 'Crie seu termo',
        term_name: 'Nome do termo',
        valid_until: 'Válido até',
        name: 'Nome',
        expirated: 'Expira',
      },

      payment_plans: {
        payment_plans: 'Planos de pagamento',
        here_are_listed_the_payment_plans_along_with_their_respective_actions:
        'Aqui estão listados os planos de pagamento juntamente com suas respectivas ações',
        create_payment_plans: 'Criar Planos de pagamentos',
        fill_the_information_below_and_click_the_create_button_to_save_the_changes:
        'Preencha as informações abaixo e clique no botão criar para salvar as alterações',
        erp_integration: 'Integração ERP',
        integration_type: 'Tipo de Integração',
        select_an_integration: 'Selecione uma integração',
        card_flag: 'Bandeira do Cartão',
        flag: 'Bandeira',
        type: 'Tipo',
        erp_code: 'Código ERP',
        card_installment: 'Parcelamento',
        payment_plan: 'Plano de pagamento',
        installment: 'Parcela',
        time: 'Prazo',
        credit: 'Crédito',
        debit: 'Débito',
      },

      indicators: {
        indicators: 'Indicadores',
        orders: 'Pedidos',
        filters: 'Filtros',
        first: 'Inicio',
        end: 'Final',
        total_order: 'Total de pedidos',
        revenue: 'Receita',
        orders_by_location: 'Pedidos por modalidade',
        orders_by_month: 'Pedidos por mês',
        orders_by_day: 'Pedidos por dia',
        orders_by_hour: 'Pedidos por hora',
        orders_by_month_sector: 'Pedidos por mês e modalidade',
        orders_by_day_sector: 'Pedidos por dia e modalidade',
        orders_by_hour_sector: 'Pedidos por hora e modalidade',
        product: 'Produtos',
        categories: 'Categorias',
        sales_by_category: 'Vendas por categoria',
        revenue_by_category: 'Receita por categoria',
        average_ticket: 'Ticket médio',
        quantity: 'Quantidade',
        summary: 'Resumo',
        summaryAccess: 'Acessos',
        summaryAccessGroup: 'Áreas acessadas pelo período',
        summaryTimeAccessGroup: 'Total por horário',
        summaryIPAccessGroup: 'Total por usuário',
        summarySellerCart: 'Total de carrinhos criados por vendedor',
      },

      customer_list: {
        customer_list: 'Lista de cliente',
        listed_customers: 'Aqui estão listados os clientes juntamente com suas respectivas ações.',
        select_time: 'Selecione o período',
        name: 'Nome',
        first: 'Início',
        end: 'Fim',
        phone: 'Telefone',
        date: 'Data',
        detail: 'Detalhes do Cliente',
        total_profit: 'Lucro Total',
        total_itens: 'Total de Itens',
        total_orders: 'Total de Pedidos',
        order_between: 'Pedidos entre',
        order_detail: 'Detalhe do pedido',
      },

      smart_order: {
        smart_order: 'Smart Order',
        product: 'Produtos',
        here_are_listed_the_products_along_with_their_respective_actions:
        'Aqui estão listadas os produtos juntamente com suas respectivas ações',
        name: 'Nome',
        code: 'Código',
        crop_image: 'Recortar Imagem',
        product_data: 'Dados do Produto',
        mark: 'Marca',
        measurement_unit: 'Unidade de Medida',
        description: 'Descrição',
        technical_specification: 'Especificação Técnica',
        from: 'de',
        characters: 'caracteres',
        order: 'Ordem',
        product_image: 'Imagens do Produto',
        additional_images: 'Imagens Adicionais',

        categories: 'Categorias',
        here_are_listed_the_categories_along_with_their_respective_actions:
        'Aqui estão listadas as categorias juntamente com suas respectivas ações',
        image: 'Imagem produto',
        image_variation: 'Imagem variação',
        category: 'Categoria',
        category_parent: 'Categoria Pai',
        category_name: 'Nome da categoria',
        category_code: 'Código da categoria',
        perfil_image: 'Imagem do perfil',

        subcategories: 'Subcategorias',
        subcategory: 'Subcategoria',
        here_are_listed_the_subcategories_along_with_their_respective_actionsc:
        'Aqui estão listadas as subcategorias juntamente com suas respectivas ações',
        select_category: 'Selecione uma categoria',

        marks: 'Marcas',
        listened_marks: 'Aqui estão listadas as marcas juntamente com suas respectivas ações',

        measurements_units: 'Unidades de Medidas',
        listened_measurement:
        'Aqui estão listadas as unidades de medidas juntamente com suas respectivas ações',

        grid_products: 'Grade de Produtos',
        listened_grid:
        'Aqui estão listadas as grades de produtos juntamente com suas respectivas ações',
        grid_name: 'Nome da grade',
        variation: 'Variação',
        grid: 'Grade',
        grids: 'Grades',

        variations: 'Variações',
        listened_variations:
        'Aqui estão listadas as variações de produtos juntamente com suas respectivas ações',
        modality: 'Modalidade',
        select_modality: 'Selecione uma modalidade',
        prod: 'Produto',
        select_product: 'Selecione um produto',
        no_variation: 'Produto sem variação',
        variation_product: 'Variação do produto',
        cost_price: 'Preço de Custo',
        sell_price: 'Preço de Venda',
        promotional_price: 'Preço Promocional',
        weight: 'Peso',
        height: 'Altura',
        width: 'Largura',
        depth: 'Profundidade',
        availability: 'Qual a disponibilidade do produto?',
        when_product_stock: 'Quando o produto acabar em estoque?',
        stock: 'Quantidade de produtos em estoque',
        min_stock: 'Estoque mínimo',
        price: 'Preço',

        catalogs: 'Catálogos',
        catalog: 'Catálogo',
        listened_catalogs:
        'Aqui estão listadas as catálogos de produtos juntamente com suas respectivas ações.',
        validity: 'Vigência',
        first: 'Início',
        end: 'Fim',
        search_products: 'Pesquisa de Produtos',

        product_status: 'Status do produto',
        sku: 'SKU/EAN',
        status_variation: 'Status da variação',
        specification: 'Especificação',
        price_promotional: 'Preço com desconto',
        inventory: 'Estoque',
        preparation_time: 'Tempo de preparo',
        service_title: 'Aqui estão listados os serviços juntamente com suas respectivas ações.',

        initial_hour: 'Horário inicial',
        final_hour: 'Horário final',
      },

      webapp: {
        webapp: 'Webapp',
        generate_qrcode: 'Gerar QR Code',
        personalize: 'Personalizar',
        settings: 'Configurações',
        link: 'Link para Webapp',

        webapp_data: 'Dados do Webapp',
        name: 'Nome',
        place: 'Local',
        product_pagination: 'Quantidade de produtos na paginação',
        logo_ninegrid: 'Logo do Ninegrid',
        light: 'Clara',
        dark: 'Escura',
        logo_totvs: 'Logo da TOTVS',
        use_icon_menu: 'Usar ícone no menu do Webapp',
        description: 'Descrição',
        icon: 'Ícone',
        color_setting: 'Configuração de cores',
        background_webapp: 'Fundo do Webapp',
        background_default_webapp: 'Cor Padrão do WebApp',
        background_header: 'Fundo do cabeçalho',
        text_header: 'Texto do cabeçalho',
        icon_header: 'Ícones do cabeçalho',
        background_footer: 'Fundo do rodapé',
        icon_footer: 'Ícones do rodapé',
        highlight_footer: 'Realce do rodapé',
        notification_cart: 'Notificação do carrinho',
        notification_text: 'Texto da notificação',
        home_button: 'Botão de início',
        text_home_button: 'Texto do botão de início',
        title_bar: 'Barra de título',
        text_title_bar: 'Texto da barra de título',
        icon_title_bar: 'Ícones da barra de título',
        text_category: 'Texto de categorias',
        webapp_button: 'Botões do Webapp',
        text_button: 'Texto dos botões',
        background_menu: 'Fundo do menu lateral',
        text_menu: 'Texto do menu lateral',
        order_status: 'Status do pedido',
        order_active_status: 'Status do pedido ativo',
        text_order_active_status: 'Texto do status do pedido',
        info_cart: 'Informativo do carrinho',

        settings_webapp: 'Configurações do Webapp',
        type_delivery: 'Tipo de restrição de entrega',
        integration: 'Integração',
        select_integration: 'Selecione uma integração',
        payment_method_card: 'Meio de pagamento Cartão',
        payment_method_pix: 'Meio de pagamento Pix',
        payment_method_delivery: 'Meio de pagamento na entrega',
        consent_letter: 'Ativar carta de consentimento',
        product_no_stock: 'Venda de produtos sem estoque',
        show_subcategory: 'Exibir subcategoria',
        show_observation: 'Exibir observação',
        use_category_list: 'Exibir subcategoria em lista',
        remove_show_mode: 'Remover modo de exibição',
        no_shadow: 'Não adicionar sombra em categorias',
        is_order_number: 'Ordenar categoria',
        order_number: 'Por ordem',
        order_category: 'Por categoria',
        show_layout: 'Exibir Layout',
        image_type: 'Estilo da imagem do produto',

        withdraw_fee: 'Taxa de Retirada',
        minimum_value: 'Valor Minimo do Pedido',
        seller_checkout: 'Modalidade do Checkout',

        no_sales: 'Não permitir vendas',
        safe_mode: 'Modo de segurança',
        time_safe_mode: 'Tempo de Sessão do Modo de segurança (em minutos)',
        limit_characteres_explanation:
        'Para lojas com integração Wintor ou Protheus, o recomendado é no máximo  50 caracteres.',
        limit_characteres: 'Limite de caracteres',

        skip_entry: 'Pular tela de entrada',
        payment_table: 'Pagamento na mesa',

        slides_homePage: 'Imagens de Destaque HomePage',
        slides_initial: 'Imagens de Destaque Inicial',

        slides_not_found: 'Não foi encontrado nenhuma imagem do slider',

        externalLink: 'Link externo',
        buttonType: 'Tipo do Botão',
        ableUserLocation: 'Permitir busca de endereço por localização',

        seller_cart_limit_time: 'Tempo de expiração do carrinho do vendedor por hora',
        seller_cart_limit_time_explanation: 'Tempo máximo que o cliente pode usar o carrinho do vendedor',
        settings_webapp_aggregator: 'Personalizar',
        generate_qrcode_webapp_aggregator: 'Gerar QR code',
        qrcode_webapp_aggregator: 'Aplicativo Web',
      },

      integration: {
        integration: 'Integração',
        means_of_payment: 'Meio de Pagamento',
        here_are_listed_the_means_of_payment_along_with_their_respective_actions:
        'Aqui estão listados os meios de pagamentos juntamente com suas respectivas ações',
        PDV: 'Integração PDV',
        listed_here_are_the_integrations_along_with_their_respective_actions:
        'Aqui estão listadas as integrações juntamente com suas respectivas ações',
        integration_name: 'Nome da Integração',
        type_of_integration: 'Tipo de Integração',
        actions: 'Actions',
        search: 'Search',
        example: 'Exemplo de Payload',

        loads: 'Cargas',
        name: 'Nome',
        price: 'Preço',
        stock: 'Estoque',
        measurements_units: 'Unidades de Medidas',
        grid: 'Grades',
        classifications: 'Classificadores',
        products: 'Produtos',
        status_sync: 'Sincronismo de Status',
        marks: 'Marcas',
        orders: 'Pedidos',
        trigger_load: 'Acionar Carga',
        first: 'Início',
        end: 'Fim',
        load: 'Carregar',
        messenger: 'Mensageria',
        others: 'Outros',
        test: 'Deseja testar a integração',
        params_test: 'Ver parâmetros dessa integração',
        copy_params: 'Copiar parâmetros',
        result_test: 'Resultados do teste da integração',
        success_1: 'Sucesso! A integração',
        success_2: 'foi bem sucedida.',
        result_log: 'Log de resultado',
        copy_result: 'Copiar resultado',
        search_result: 'Pesquisar nos resultados',
        error_1: 'A integração',
        error_2: 'retornou um erro. Confira o log abaixo para mais informações.'
      },

      reports: {
        orders: 'Pedidos',
        order_report: 'Relatório de Pedidos',
        order_list: 'Lista de Pedidos',
        first: 'Início',
        end: 'Fim',
        firstTime: 'Hora inicial',
        endTime: 'Hora final',
        orders_between: 'Pedidos entre',
        order_number: 'Número do Pedido',
        date: 'Data',
        consumer: 'Consumidor',
        modality: 'Modalidade',
        total_value: 'Valor Total',
        cancel_order: 'Cancelar pedido',

        products: 'Produtos',
        quantity: 'Quantidade',
        product: 'Produto',
        value: 'Valor',
        total: 'Total',
        order_status: 'Status do Pedido',
        payment_method: 'Forma de pagamento',
        billing_address: 'Endereço de cobrança',
        product_value: 'Valor dos produtos',
        discount: 'Desconto',
        order: 'Pedido',

        listened_logs: 'Aqui estão listados os logs juntamente com suas respectivas ações.',
        methods: 'Métodos',
        log_details: 'Detalhes do Log',

        withdraw: 'Retirada',
        delivery: 'Entrega',
        table: 'Mesa',
        responsible_withdraw: 'Responsável pela retirada',
        responsible_delivery: 'Responsável pela recepção',
        address_withdraw: 'Endereço de retirada',
        address_delivery: 'Endereço de entrega',

        seller: 'Vendedor',
        external_code: 'Pedido Externo',
        payment: 'Pagamento',
        internal_delivery: 'Entrega Interna'
      },

      imports: {
        imports: 'Importações',
        import_image_product: 'Importações de Imagens para Produtos',
        import_image_category: 'Importações de Imagens para Categorias',
        listened_import_product:
        'Aqui estão listadas as importações de imagens para produtos juntamente com suas respectivas ações.',
        no_upload_images: 'Não realizar upload de imagens',
        first: 'Início',
        end: 'Fim',
        total_products: 'Total de Produtos',
        sync_products: 'Produtos Sincronizados',
        import_image_sku: 'Importações de Imagens para Variações',
        listened_import_sku:
        'Aqui estão listadas as importações de imagens para variações juntamente com suas respectivas ações.',
        verify_verification: 'Verificação da variação',
        product_code: 'Código do produto',
        code: 'Código',
        total_variations: 'Total de Variações',
        sync_variations: 'Variações Sincronizadas',
        total_categories: 'Total de Categorias',
        sync_categories: 'Categorias Sincronizadas',
        problem_title: 'Imagens com problema',
        images_success: 'imagens foram enviadas com sucesso.',
        images_error: 'imagens apresentaram problema e não foram enviadas.',
        file: 'Arquivo',
        status: 'Status da importação',
        new_import: 'Realizar nova importação',
        import_xls: 'Importar imagens com arquivo XLS',
        import_repo: 'Importar imagens do Google Drive ou Dropbox',
        steps: 'Passo a passo para importação',
        step_1: '1. Coloque todas as imagens em um servidor web público.',
        step_2: '2. Verifique se o servido é público e acessível via web.',
        step_3: '3. Faça o download do nosso arquivo XLSX em branco.',
        step_4: '4. Preencha com os dados do seu banco de imagens.',
        step_5: '5. Faça o upload do arquivo preenchido.',
        step_1_repo:
        '1. Verifique se a pasta da loja está criada como "fotos_loja_<identificador da loja>".',
        step_2_repo: '2. Crie a pasta "produtos" dentro da pasta da sua loja.',
        step_2_repo_sku: '2. Crie a pasta "variacoes" dentro da pasta da sua loja.',
        step_2_repo_category: '2. Crie a pasta "categorias" dentro da pasta da sua loja.',
        step_3_repo: '3. Confirme se o os arquivos estão como "<codigo do produto>.<extensão>".',
        step_3_repo_sku: '3. Confirme se o os arquivos estão como "<sku>.<extensão>".',
        step_3_repo_category:
        '3. Confirme se o os arquivos estão como "<codigo da categoria>.<extensão>".',
        step_4_repo: '4. Caso seja um produto adicional, verificar se possui "_<numero>".',
        step_4_repo_sku:
        '4. Confirme que a variação é uma imagem e se está na extensão JPG ou PNG.',
        step_4_repo_category:
        '4. Confirme que a categoria é uma imagem e se está na extensão JPG ou PNG.',
        step_5_repo: '5. Confirme que o produto é uma imagem e se está na extensão JPG ou PNG.',
        download: 'Baixar o arquivo XLSX em branco',
        download_problems: 'Exportar relatório',
        upload: 'Enviar arquivo',
        upload_anex: 'Anexar arquivo',
        import_dropbox: 'Importar com Dropbox',
        import_drive: 'Importar com Google Drive',
        spreadsheet: 'Planilha',
        gdrive: 'Google Drive',
        dropbox: 'Dropbox',
        type: 'Tipo',
        type_resize: 'Tipo de Resize',
        agroup_color: 'Agrupar fotos por cor'
      },

      sms: {
        sms: 'SMS',
        listened_sms:
        'Aqui estão listadas as mensagens (SMS) juntamente com suas respectivas ações.',
        order: 'Ordem',
        name: 'Nome',
        message: 'Mensagem',
        markers: 'Os marcadores [pedido] e [status] devem estar na mensagem',
        waiting: 'Aguardando',
        opened: 'Em aberto',
        ready: 'Pronto',
        refused: 'Recusado',
      },

      multichannel: {
        multichannel: 'Multicanal',
        listened_multichannel:
        'Aqui estão listadas as configurações de multicanal juntamente com suas respectivas ações.',
      },

      feed: {
        content: 'Conteúdo',
        title: 'Título',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
        'Preencha as informações abaixo e clique no botão criar para salvar as alterações',
      },
      feedCategory: {
        feedCategory: 'Categoria do Conteúdo'
      },

      queue: {
        index: 'Fila de espera',
        config: 'Configurações da fila de espera',
        able: 'Permitir fila de espera',
        link: 'Link da fila de espera:',
        limit_people: 'Número máximo de pessoas',
        limit_people_text: 'Limite da fila de espera',
        limit_time: 'Limite de tempo para exibir alerta',
        limit_time_text: 'Tempo máximo em minutos para considerar atrasado',
        image_title: 'Definição de imagens',
        image_text:
        'O sistema de fila exibe 2 imagens. Faça upload de fotos do seu restaurante, ou selecione fotos padrão abaixo. As fotos enviadas serão ajustadas para o tamanho de 350x250 pixels.',
        image: 'Imagem',
        position: 'Posição',
        time: 'Tempo de espera',
        people: 'pessoas',
        cancel: 'Cancelamento',
        cancel_text: 'Deseja remover essa pessoa da fila de espera?',
        cancel_button: 'Remover',
        confirm: 'Confirmação',
        confirm_text: 'Deseja confirmar a chegada do reservante?',
        confirm_button: 'Confirmar chegada',
        empty: 'Não há lista de espera no momento',
        confirm_all: 'Confirmar todas',
        cancel_all: 'Cancelar todas',
        observation: 'Observação',
        use_terms: 'Ativar termos de consentimento',
        total_people: 'Total de pessoas por grupo',
        total_people_text: 'Limite da fila de espera por grupo',
      },
      booking: {
        index: 'Reserva',
        config: 'Configurações da reserva',
        able: 'Permitir reserva',
        link: 'Link da reserva:',
        image_title: 'Definição de imagens',
        image_text:
        'O sistema de reservas exibe 2 imagens. Faça upload de fotos do seu restaurante, ou selecione fotos padrão abaixo. As fotos enviadas serão ajustadas para o tamanho de 350x250 pixels.',
        image: 'Imagem',
        days_week: 'Dias da semana',
        booking_day: 'Permitir reservas neste dia?',
        booking_day_text: 'Defina abaixo os horários liberados para reservas.',
        booking_day_subtext:
        'Ao selecionar um horário será definida uma janela de 1 hora. Exemplo: ao selecionar 19:00 as pessoas poderão fazer reservas entre 19:00 e 20:00.',
        max_people: 'Número máximo de pessoas por reserva',
        max_people_text: 'É o tamanho máximo de um grupo de pessoas que pode fazer uma reserva',
        expiry_time: 'Tempo máximo até a reserva expirar',
        expiry_time_text: 'Tempo em minutos para aguardar a chegada do reservante',
        day_confirm: 'Exigir confirmação no dia',
        day_confirm_text: 'Usuário precisa confirmar a reserva na data escolhida',
        payment_confirm: 'Exigir pagamento antecipado na reserva',
        value_payment_confirm: 'Valor do pagamento antecipado',
        week: 'Semana',
        month: 'Mês',
        year: 'Ano',
        use_terms: 'Ativar termos de consentimento',
        empty: 'Não há reservas no momento',
        use_time: 'Permitir reserva por período',
        time: 'Duração da reserva (HH:mm)'
      },
      booking_day: {
        booking: 'Reservas do dia',
        date: 'Data',
        people: 'pessoas',
        expiry: 'Expira em',
        cancel_title: 'Cancelamento',
        cancel_subtitle: 'Deseja cancelar essa reserva?',
        cancel_button: 'Cancelar reserva',
        confirm_title: 'Confirmação',
        confirm_subtitle: 'Deseja confirmar a chegada do reservante?',
        confirm_button: 'Confirmar chegada',
      },
      booking_report: {
        report: 'Relatórios',
        search: 'Busca de reservas',
        time: 'Selecione o período',
        date_start: 'Data início',
        date_end: 'Data fim',
        confirm: 'Confirmação',
        noShow: 'No show',
        confirmed: 'Reservas confirmadas',
        canceled: 'Reservas canceladas',
        open: 'Em aberto',
        map: 'Mapa de reservas',
        booking: 'reservas',
      },
      booking_report_management: {
        management: 'Gestão de reservas',
        search: 'Busca de reservas',
        phone: 'Telefone',
        date_start: 'Data início',
        date_end: 'Data fim',
        today: 'Hoje',
        tomorrow: 'Amanhã',
        calendar: 'Calendário',
        see_all: 'Ver todas',
        people: 'pessoas',
        noShow: 'No show',
        confirmed: 'Confirmada',
        canceled: 'Cancelada',
        open: 'Em aberto',
        search_text: 'Resultados pela pesquisa de telefone ou seleção de data',
      },
      environment: {
        map: 'Mapa de ambientes',
        environment: 'Ambiente',
        index: 'Ambientes',
        name: 'Nome do ambiente',
        tables: 'Número de mesas',
        seats_available: 'Total de lugares disponíveis',
        actions: 'Ações',
      },
      week_day: {
        sun: 'Domingo',
        mon: 'Segunda',
        tue: 'Terça',
        wed: 'Quarta',
        thur: 'Quinta',
        fri: 'Sexta',
        sat: 'Sábado',
      },
      week_day_full: {
        sun: 'Domingo',
        mon: 'Segunda-feira',
        tue: 'Terça-feira',
        wed: 'Quarta-feira',
        thur: 'Quinta-feira',
        fri: 'Sexta-feira',
        sat: 'Sábado',
      },
      checkout: {
        title: 'Dados do Cliente',
        here_are_listed_the_modalities_along_with_their_respective_actions:
        'Aqui estão listados os campos necessários para a criação do cliente.',
        active_mandatory: 'Campo Mandatório',
        active_visibility: 'Campo Visível',
      },

      liveShop: {
        liveShop: 'Catálogo ao Vivo',
        liveShop_list:
        'Aqui estão listados os Catálogos ao Vivo juntamente com suas respectivas ações.',
        create: 'Criar Catálogos ao Vivo',
        edit: 'Editar Catálogos ao Vivo',
        dateInit: 'Início',
        hourInit: 'Hora de Início',
        dateEnd: 'Fim',
        hourEnd: 'Hora de Término',
        status: 'Status',
        initDate: 'Data e Hora de Início',
        fimDate: 'Data e Hora de Término',
        name: 'Nome',
        variations: 'Variações do Produto',
        search_products_Attentions:
        'Os Produtos e Variações retornados devem possuir status ativado, possuir preço, estoque e imagem',
        alert_delete: 'Não é possivel remover uma Live quando começou ou foi finalizada!',
        alert_Edit: 'Não é possivel editar uma Live quando começou ou foi finalizada!',
        delete: 'Excluir Catálogo ao Vivo!',
      },

      logs: {
        //PagSeguro
        pagseguroPaymentCodePhone: 'Erro código do telefone Pagseguro',
        pagseguroPaymentErrorCountry: 'Erro do código País Pagseguro',
        pagseguroPaymentErrorEmail: 'Erro do email Pagseguro',
        authenticatePagseguro: 'Autenticação Pagseguro',
        pagseguroPayment: 'Pagamento Pagseguro',
        pagseguroPaymentCatch: 'Pagamento Pagseguro',
        pagseguroPaymentResponse: 'Resposta do pagamento Pagseguro',
        pagseguroPaymentTransactions: 'Transação do pagamento Pagseguro',
        pagseguroReturnPayment: 'Retorno do pagamento Pagseguro',
        paymentPixPagseguro: 'Pagamento Pix Pagseguro',

        pagseguroPaymentGateway: 'Gateway de pagamento Pagseguro',
        getAuthPagseguroEmailSpace: 'Autenticação do Pagseguro por email',
        getOneApplicationClientId: 'Pegar aplicação do cliente Pagseguro',
        createAuthPagseguro: 'Criar autenticação Pagseguro',
        generatePublicKeyPagseguro: 'Gerar chave pública Pagseguro',
        updateCodeRedirectApplication: 'Atualização do Codigo da aplicação Pagseguro',
        deleteAuthPagseguroByEmail: 'Remover autenticação Pagseguro',
        createPluginconfig: 'Configuração do plugin',

        //Totvs pagamento HUB
        refundTotvs: 'Estorno via Totvs',
        refundPayCard: 'Estorno via Totvs por cartão',
        paymentTotvs: 'Pagamento via Totvs',
        paymentPixTotvs: 'Pagamento Pix via Totvs',

        //Card Structure
        cardStructure: 'Estrutura do cartão',
        cardStructureInvalidPlugin: 'Plugin inválido para o gateway',
        cardStructureGatewayError: 'Erro do cartão para o gateway',
        cardStructureData: 'Dados do Cartão',
        cardStructureError: 'Erro dos Dados cartão',
        cardStructureDecrypt: 'Descriptografia do cartão',
        cardStructureFields: 'Campos do Cartão',
        refundCard: 'Estorno do Cartão',

        //Status
        analysisSatusOrder: 'Status do pedido para análise',
        statusPayPix: 'Status Pix',

        //Antifraude
        analyseAntifraud: 'Análise do Antifraude',

        //estoque
        addSkuInventory: 'Adicionar ao estoque',
        subtractSku: 'Remover do estoque',

        // Order-Pedido
        sendOrderIn: 'Envio do pedido',
        cancelOrder: 'cancelar Pedido',
        sendOrder: 'Enviar Pedido',
        sendOrderRefund: 'Estorno do Pedido',
        sendOrderError: 'Erro ao enviar pedido',
        sendOrderResultRefund: 'Resultado do estorno do pedido',
        sendOrderResultData: 'Resultado do envio do pedido',
        sendOrderAntifrud: 'Antifraude no envio do pedido',
        sendOrderPaymentStruct: 'Estrutura do pagamento do pedido',

        sendOrderDescryptor: 'Descriptografia do Pedido',
        sendOrderStatus: 'Status do pedido',
        sendOrderConfigNotFound: 'Configuração do envio do pedido',
        sendOrderMandatoryCustomer: 'Cliente do pedido',
        sendOrderMandatory: 'Estruura mandatória do pedido',
        sendOrderResultGateway: 'Resultado do envio ao gateway',

        //Pre-Order
        preOrderStatusPix: 'Status do Pré pedido via pix',
        processQueuePreOrder: 'Processamento da fila do pré order',
        getAllPreOrderBySpace: 'Pegar todos os pré pedidos por pluginspace',
        sendPreOrder: 'Envio do Pré pedido',
        makeOrderTransparent: 'Verificação pedido',
        statusPreOrder: 'Atualização do Status do Pix do Pré pedido',

        //Totvs Wintor
        buildOrderTotvsWinthor: 'Criar Pedido',
        sendOrderWinthor: 'Envio do pedido para Totvs',
        sendCustomerOrderWinthor: 'Envio do cliente para Totvs',

        //Totvs Varejo
        buildOrderTotvsVarejo: 'Criar Pedido',
        setConfimTicket: 'Confirmação de Ticket',
        SyncOrderLive: 'Sincronização de Pedidos',
        ObterProdutos: 'Obter Produtos',
        taskPrice: 'Carga de Preço',
        calculationPromotion: 'Cálculo de Promoção',
        PayloadCalcPromotion: 'Payload Cálculo de Promoção',

        //Totvs Moda
        changeStatus: 'Troca de Status',
        sendOrderFashion: 'Envio do pedido para Totvs',
        sendCustomerFashion: 'Envio do cliente para Totvs',
        searchPersonTotvsByCPF: 'Pesquisa de Cliente Totvs',
        sendErrorCustomerFashion: 'Erro no envio do Cliente para o Fashion',
        buildCustomerTotvsFashion: 'Criação de cliente na Totvs',
        ResumeSnyOrderFashion: 'Resumo sincronia com o Fashion',
        'changeStatus ': 'Trocar Status',
        buildOrderTotvsFashion: 'Criar Pedido Fashion',

        //Totvs Food
        updateStatusOrderFoodIntegration: 'Troca de Status',
        buildOrderTotvsFood: 'Criar Pedido',
        changeStatusOrderFood: 'Troca de Status',
        sendOrderTotvsFood: 'Envio do pedido para a Totvs',
        confirmMenu: 'Menu Confirmado',
        getMenu: 'Pegar Menu Totvs',

        //Totvs Live
        sendCustomerLive: 'Envio do Cliente Live',
        sendOrderLive: 'Envio Pedido Live',

        //Synkar
        updateStatusOrderSynkar: 'Atualização Pedido Synkar',

        //SDS
        sdsGetOrders: 'Pegar os pedidos SDS',
        sdsOrderIn: 'Envio dos pedidos SDS',
        sdsOrderInResult: 'Envio do pedido ao SDS',
        sdsSyncCardapio: 'Sincronia do cardapio com o SDS',
        'sendOrderfood-sdsOrderIn': 'Erro em enviar pedido SDS',

        //S3
        createRoute: 'Criar Rota S3',
        getStatusfiftythree: 'Status Rota 53',
        updateDistribution: 'Atualiza Distribuição',
        deleteRoute: 'Deleta Rota S3',
        deleteDistribution: 'Deleta Distribuição',

        //Erede
        refundErede: 'Estorno Erede',
        paymentErede: 'Pagamento Erede',

        //Cielo
        refundCielo: 'Estorno Cielo',
        paymentCielo: 'Pagamento Cielo',

        //pagamento offline
        paymentOffline: 'Pagamento na entrega',

        //Geral
        chargeSyncOrderErro: 'Erro na Sincronia de Status',
        chargeSyncOrder: 'Sincronia de Status',
        chargeProductLive: 'Sincronia de Produtos do Live',
        chargeBalanceLive: 'Sincronia de Estoque do Live',
        chargePriceLive: 'Sincronia de Preços do Live',
        chargeProductLiveErro: 'Erro na Sincronia de Produtos do Live',
        chargeBalanceLiveErro: 'Erro na Sincronia de Estoque do Live',
        chargePriceLiveErro: 'Erro na Sincronia de Preços do Live',

        chargeProduct: 'Sincronia de Produtos',
        chargeBalance: 'Sincronia de Estoque',
        chargePrice: 'Sincronia de Preços',
        chargeProductErro: 'Erro na Sincronia de Produtos',
        chargeBalanceErro: 'Erro na Sincronia de Estoque',
        chargePriceErro: 'Erro na Sincronia de Preços',

        taskBalanceIntegration: 'Integração da Carga de estoque',
        taskBalanceCharge: 'Carga de estoque iniciada',
        SnyOrder: 'Sincronismo de status de pedido',
        syncronyzePriceInteration: 'Carga de Preço',
        syncronyzePrice: 'Sincronização de Preço',
        chargeClassification: 'Carga de categoria e subcategoria',
        chargeGrid: 'Carga de grade',
        chargeGridItens: 'Itens de Carga de grade',
        chargeMeasure: 'Carga de unidade de medida',
        deleteAllProducts: 'Deletar todos os Produtos',

        chargeGridToken: 'Token para carga de Grade',
        classificationChargeToken: 'Token para carga de Classificação',
        syncronyzeBalanceToken: 'Token para sincronização de Estoque',
        syncronyzePriceToken: 'Token para sincronização de Preço',
        chargeMeasureToken: 'Token para carga de Unidade de Medida',
        taskClassificationBySpace: 'Carga de Classificação',
        syncStatusOrderPayload: 'Payload de Sincronização de Status do Pedido',
        syncStatusOrder: 'Sincronização de Status do Pedido',
        syncStatusOrderToken: 'Token de Sincronização de Status do Pedido',
        syncronyzeBalancePayload: 'Payload de Sincronização de Estoque',
        syncronyzeBalance: 'Sincronização de Estoque',
        taskBalanceChargeBySpace: 'Carga de Estoque',
        syncronyzePricePayload: 'Payload de Sincronização de Preço',
        priceSkuVariationItens: 'Preço dos Itens da Variação',
        configSyncOrderBySpace: 'Configuração de Sincronização de Pedido',
        configSyncOrder: 'Configuração de Sincronização de Pedido',
        taskGridChargeBySpace: 'Carga de Grade',
        productChargeInternal: 'Carga de Produto',
        taskChargeProductBySpace: 'Carga de Produto',
        skuLocationVariation: 'Sincronismos de Variação por Localização',
        stockSkuVariation: 'Sincronismos de Variação',
        taskPriceChargeBySpace: 'Carga de Preço por Pluginspace',

        automaticChargeBalance: 'Sinc Automático de Estoque',
        automaticChargePrice: 'Sinc Automático de Preço',
        automaticChargeClassification: 'Sinc Automático de Classificação',
        automaticTaskGridCharge: 'Sinc Automático de Grid',
        automaticTaskMeassure: 'Sinc Automático de Unid. Medidas',
        automaticSnyOrder: 'Sinc Automático Status de Pedidos',
        chargeMeasureItens: 'Itens de carga de Unid Medida',
        searchMeasurementAll: 'Carga de unidade de medida',
        taskGridCharge: 'Carga de Grade',
        taskMeassure: 'Carga de unidade de medida',

        getCredential: 'Obter Token',
        LoadPricePerList: 'Carga de Preços',
        processPriceList: 'Processamento da Lista de Preços',
        updateProductKit: 'Atualização de Produtos',
        UpdateProductSingle: 'Atualização de Produtos',
        updateProductStatus: 'Atualização de Status de Produto',
        taskBalanceIntegrationLink: 'Link de integração de estoque',
        taskPriceIntegrationLink: 'Link de integração de preço',
        taskProductIntegrationLink: 'Link de integração de produto',
        token: 'Obtenção de Token',
        automaticChargeProduct: 'Carga automática de productos',


        //Desconto Ninegrid
        calculateDiscount: 'Calculo do desconto no ninegrid',
        sendDiscountNinegrid: 'Desconto Ninegrid',

        //Customer Cart
        updateCustomerCartStatus: 'Update do carrinho do Cliente',

        //Cliente
        createCustomer: 'Criação do Cliente',

        //clearSale
        statusAnalisis: 'Analise do Status',
        antifraudValidation: 'Validação da antifraude',
        clearSale: 'ClearSale Garantido',
        searchOrderStatusClearSale: 'Pesquisa Status ClearSale',
      },

      copy: {
        definition: 'Definição de Pluginspace',
        origin: 'Selecione a Pluginspace de origem',
        destination: 'Selecione a Pluginspace de destino',
        configurations: 'Configurações da cópia',
        store: 'Configurações da Loja',
        deliveryFee: 'Taxa de entrega',
        shifts: 'Turnos',
        paymentPlans: 'Planos de pagamento',
        clientData: 'Dados do Cliente',
        account: 'Configurações da Conta',
        groups: 'Grupos',
        terms: 'Termos',
        webapp: 'Webapp',
        webappConfig: 'Configurações',
        webappPersonalize: 'Personalização',
        integrations: 'Integrações',
        integrationPayment: 'Meios de pagamento',
        integrationPdv: 'PDV',
        integrationMessaging: 'Mensageria',
        integrationOthers: 'Outros',
        button: 'Copiar Pluginspace',
      },

      payment: {
        methods: 'Meios de pagamento',
        method: 'Forma de pagamento',
        gateway: 'Gateway de pagamento',
        card: 'Cartão de crédito',
        debit: 'Cartão de débito',
        food: 'Vale alimentação',
        pix: 'Pix',
        pay_money: 'Dinheiro',
        config: 'Configurações',
        add: 'Configurar meio de pagamento',
        back: 'Voltar para os gateways de pagamento',
        basic_settings: 'Configurações básicas',
        modalities: 'Modalidades onde este meio de pagamento será utilizado',
        add_payment: 'Adicionar forma de pagamento',
        code_erp: 'Código ERP',
        installments: 'Número de parcelas',
        edit_payment: 'Editar forma de pagamento',
        delete: 'Excluir forma de pagamento',
        money: 'Pagamento em dinheiro',
        max_installments: 'Qual o número máximo de parcelas?',
        confirm: 'Confirmar exclusão',
        delete_payment: 'Deseja excluir a forma de pagamento',
        change: 'Deseja realizar esta troca?',
        inative: 'Deseja inativar esta forma de pagamento?',
        inative_change: 'Deseja ativar esta forma de pagamento e inativar a antiga?',
        motive: 'Por favor informe o motivo'
      },

      icon: {
        title: 'Ícones',
        subtitle: 'Aqui estão listados todos os icones da biblioteca'
      },
      freight: {
        title: 'Modalidades de envio',
        map: 'Mapa',
        map_text: 'Opção para pagamento na entrega do pedido',
        range: 'Raio',
        range_text: 'Opção para pagamento na entrega do pedido',
        cep: 'Lista de CEP',
        cep_text: 'Opção para pagamento na entrega do pedido',
        config: 'Configurações',
      },
      chat: {
        you: 'Você',
        now: 'Enviado agora',
        send: 'Enviar',
        type_message: 'Envie uma mensagem...',
        initial_message: 'Bem-vindo(a) ao chat IA da Ninegrid',
        error_message: 'Não consegui encontrar uma resposta para sua mensagem',
        tip_language: 'Responda em português por favor.',
        title_config: 'Configurações de Inteligência Artificial',
        config: 'Configurar Inteligência Artificial',
        fit: 'Treinamento da Inteligência Artificial',
        fit_header: 'Aqui estão listadas as perguntas com as respectivas respostas criadas',
        add_question: 'Adicionar pergunta',
        edit_question: 'Editar pergunta',
        delete_question: 'Deseja excluir a pergunta abaixo?',
        question: 'Pergunta',
        answer: 'Resposta',
        date: 'Data',
        actions: 'Ações',
        active: 'Ativo',
        delete: 'Excluir',
        sync: 'Sincronizar IA'
      },
      sumary_access: {
        cart: 'Carrinho',
        seller: 'Login Vendedor',
        category: "Categorias",
        pdv: 'Detalhes do Produto',
        subcategory: 'Subcategoria',
        product: 'Lista de Produtos',
        home: 'Tela Inicial',
        'search-order': 'Pedidos',
        'store-info': 'Informações da Loja',
        customer: 'Cadastro de cliente',
        receipt: 'Recibo de pagamento',
        order: 'Pagamento',
        card: 'Pagamento Cartão',
        'order-qrcode': 'Pagamento Pix Cliente',
        'success-order': 'Sucesso da Compra',
        'filter/pdv': 'Resultado dos Filtros',
        filter: 'Seleção de Filtros',
        'seller-qrcode': 'Link de Pagamento',
        'vendor-order ': 'Consulta de carrinho Vendedor',
        'finalize-order': 'Resumo da compra',
      }
      
    },
  },
}

export { messages }
