import { BaseSyntheticEvent, FC, MouseEvent, useEffect, useState } from "react"
import { Input } from "../Input/Input"
import { Button } from "../Button/Button"
import { OpenConver, OpenFeatureConver, appConfig } from "../../pages/Agregador/webApp/types/webAppAgregador.types"
import { POST } from "../../services/api"
import uuid from "react-uuid"


type Props = {
  config: appConfig | undefined,
  featureCover: OpenFeatureConver[]
  save: () => Promise<void>
  setConfig: (value: any) => any
  setLoading: (value: any) => any
  nextPage: () => void
  previousPage: () => void

}

const CapaDestaque: FC<Props> = ({ config, featureCover, save, setConfig, setLoading, nextPage, previousPage }) => {
  const [destaqueButtons, setDestaqueButtons] = useState([
    'Configurar Destaques 1',
    'Configurar Destaques 2',
    'Configurar Destaques 3',
    'Configurar Destaques 4',
    'Configurar Destaques 5',
  ])

  const [featureCoverAux, setFeatureCoverAux] = useState<OpenFeatureConver[]>(featureCover);


  const [show, setShow] = useState(0)



  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }


  async function saveImage(image1: any, imageBk: any) {
    try {
      if (image1 && image1 !== imageBk) {
        if (imageBk) {
          deleteImage(imageBk)
        }
        const formData = new FormData()
        formData.append('file', image1)
        formData.append('nameImage', `appconfig-logo-${uuid()}.jpg`)
        formData.append('originalSize', 'true')
        const imageRes = await POST('/upload-images/upload-one', formData)
        return imageRes.success ? imageRes.image : ''
      }
      return image1
    } catch (error) {
      return ''
    }
  }
  function saveImageWithoutSendS3(image1: any, imageBk: any) {
    if (image1 && image1 !== imageBk) {
      return image1
    }
    return imageBk
  }

  async function saveParcial() {
    try {
      if (config) {
        const newFeatureCover = featureCoverAux.map((coverAux) => {
          coverAux.image = saveImageWithoutSendS3(coverAux.image, coverAux.imageFistState)
          coverAux.aggregatorLogo.white = saveImageWithoutSendS3(coverAux.aggregatorLogo.white, coverAux.aggregatorLogo.whiteFistState)
          coverAux.aggregatorLogo.black = saveImageWithoutSendS3(coverAux.aggregatorLogo.black, coverAux.aggregatorLogo.blackFistState)
          return coverAux;
        });

        config.content.featureCover = newFeatureCover

        setConfig(config)

        nextPage()
      }

    } catch (error) {
      console.log(error)
    }
  }

  async function savePartialPreviousPage() {
    try {
      if (config) {
        const newFeatureCover = featureCoverAux.map((coverAux) => {
          coverAux.image = saveImageWithoutSendS3(coverAux.image, coverAux.imageFistState)
          coverAux.imageFistState = coverAux.imageFistState

          coverAux.aggregatorLogo.white = saveImageWithoutSendS3(coverAux.aggregatorLogo.white, coverAux.aggregatorLogo.whiteFistState)

          coverAux.aggregatorLogo.whiteFistState = coverAux.aggregatorLogo.whiteFistState

          coverAux.aggregatorLogo.black = saveImageWithoutSendS3(coverAux.aggregatorLogo.black, coverAux.aggregatorLogo.blackFistState)

          coverAux.aggregatorLogo.blackFistState = coverAux.aggregatorLogo.blackFistState
          return coverAux;
        });

        config.content.featureCover = newFeatureCover

        setConfig(config)

        previousPage()
      }

    } catch (error) {
      console.log(error)
    }
  }



  return (
    <div className="row gap-4">
      <div className='col card' style={{ marginTop: '-1rem' }}>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold mb-1' style={{
              fontSize: '14px',
              lineHeight: "20px",
              color: '#677484'
            }}>Capa de Destaques</span>
            <span
              style={{
                fontSize: '11px',
                lineHeight: "20px",
                color: '#677484'
              }}
            >Aqui você seleciona a capa que deseja configurar  </span>
          </h3>
        </div>
        <div className='card-body d-flex flex-column  gap-4 '>
          <h4>Adicionar Capas de Destaque</h4>
          <div className="d-flex flex-column gap-4 align-items-center justify-content-center">
            {destaqueButtons.map((buttons, index) => (
              <button onClick={() => setShow(index)} style={{
                maxWidth: "90%",
                width: '200px',
                borderRadius: '4px',
                padding: "10px 10px",
                fontSize: "12px",
                fontWeight: "600",
                color: index === show ? '#fff' : '#636363',
                background: index === show ? '#6993FF' : '#F5F5F5',
                borderColor: index === show ? '#6993FF' : "#CCCCCC",
                boxShadow: 'none',
                borderStyle: 'solid'
              }}>
                {buttons}
              </button>
            ))

            }

          </div>
        </div>
      </div>
      <div className='col-9 card' style={{ marginTop: '-1rem' }}>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold mb-1' style={{
              fontSize: '14px',
              lineHeight: "20px",
              color: '#677484'
            }}>Configurar Capa de Destaque {show + 1}</span>
            <span
              style={{
                fontSize: '11px',
                lineHeight: "20px",
                color: '#677484'
              }}
            >Aqui você configura as capas de destaques do seu agregador. </span>
          </h3>
        </div>
        <>
          {destaqueButtons.map((value, index) => (
            <div style={{
              display: `${index === show ? 'block' : 'none'}`
            }} >
              <CapaDestaqueOptions
                openCover={featureCover[index]}
                save={saveParcial}
                setFeatureCoverAux={setFeatureCoverAux} index={index}
                featureCoverAux={featureCoverAux}
                previousPage={savePartialPreviousPage}
              />
            </div>
          ))

          }
        </>
      </div>
    </div>
  )
}

export { CapaDestaque }

type Prosp2 = {
  openCover: OpenFeatureConver | undefined
  setFeatureCoverAux: (value: any) => void
  featureCoverAux: OpenFeatureConver[]
  save: () => Promise<void>
  index: number,
  previousPage: () => void
}

const CapaDestaqueOptions: FC<Prosp2> = ({ openCover, save, index, setFeatureCoverAux, featureCoverAux, previousPage, }) => {
  const [typeLogo, setTypeLogo] = useState(openCover?.aggregatorLogo.type ? openCover?.aggregatorLogo.type  : 'white')
  // colors
  const [backgroundButton, SetBackgroundButton] = useState(openCover?.backgroundButton ? openCover?.backgroundButton : '')
  const [colorTextButton, SetColorTextButton] = useState(openCover?.colorTextButton ? openCover?.colorTextButton : '')


  //shadow
  const [shadow, SetShadow] = useState(openCover?.shadow ? openCover?.shadow : false)
  //logoNinegrid
  const [logoNinegrid, SetLogoNinegrid] = useState(openCover?.ninegridLogo ? openCover?.ninegridLogo : '')
  //Pelicula
  const [film, SetFilm] = useState(openCover?.film ? openCover?.film : false)
  const [filmValue, SetFilmValue] = useState(openCover?.filmValue ? openCover?.filmValue : 0)
  //logo Marca Agregador
  const [useLogo, setUseLogo] = useState(openCover?.useLogo ? openCover?.useLogo : false)
  const [logoAgregator, setLogoAgregator] = useState(openCover?.logoAgregator ? openCover?.logoAgregator : '')

  //imagem da capa
  const [image, setImage] = useState<File | null | string>()
  const [firstStateimage, setFirstStateImage] = useState<null | string>()

  //logoMarca Branca
  const [whiteImage, setWhiteImage] = useState<File | null | string>()
  const [firstStateWhiteImage, setFirstStateWhiteImage] = useState<null | string>()
  //logoMarca Preta
  const [blackImage, setBlackImage] = useState<File | null | string>()
  const [firstStateBlackImage, setFirstStateBlackImage] = useState<null | string>()


  function openImages() {
    try {
      if (openCover && openCover.image && !openCover.image.name) {
        const urlImage = openCover.image.substring(0, 4) === 'http' ?
          openCover.image : openCover.image ? process.env.REACT_APP_AWS_S3_URL + openCover.image : null
  
        setImage(urlImage)
        setFirstStateImage(urlImage)
      } else if (openCover && openCover.image && openCover.image.name) {
        setImage(openCover.image)
        setFirstStateImage(openCover.image)
      }
  
      if (openCover && openCover.aggregatorLogo && openCover.aggregatorLogo.white && !openCover.aggregatorLogo.white.name) {
        const urlImageWhite = openCover.aggregatorLogo.white.substring(0, 4) === 'http' ?
          openCover.aggregatorLogo.white : openCover.aggregatorLogo.white ? process.env.REACT_APP_AWS_S3_URL + openCover.aggregatorLogo.white : null
  
        setWhiteImage(urlImageWhite)
        setFirstStateWhiteImage(urlImageWhite)
      } else if (openCover && openCover.aggregatorLogo && openCover.aggregatorLogo.white && openCover.aggregatorLogo.white.name) {
        setWhiteImage(openCover.aggregatorLogo.white)
        setFirstStateWhiteImage(openCover.aggregatorLogo.white)
      }
  
      if (openCover && openCover.aggregatorLogo && openCover.aggregatorLogo.black && !openCover.aggregatorLogo.black.name) {
        const urlImageBlack = openCover.aggregatorLogo.black.substring(0, 4) === 'http' ?
          openCover.aggregatorLogo.black : openCover.aggregatorLogo.black ? process.env.REACT_APP_AWS_S3_URL + openCover.aggregatorLogo.black : null
        setBlackImage(urlImageBlack)
        setFirstStateBlackImage(urlImageBlack)
      } else if (openCover && openCover.aggregatorLogo && openCover.aggregatorLogo.black && openCover.aggregatorLogo.black.name) {
        setBlackImage(openCover.aggregatorLogo.black)
        setFirstStateBlackImage(openCover.aggregatorLogo.black)
      }
    } catch (error) {
      console.log(error)
    }
  }


  function saveAux() {
    featureCoverAux[index] = {
      backgroundButton: backgroundButton,
      colorTextButton: colorTextButton,
      shadow: shadow,
      ninegridLogo: logoNinegrid,
      image: image,
      imageFistState: firstStateimage,
      film: film,
      filmValue: filmValue,
      aggregatorLogo: {
        white: whiteImage,
        whiteFistState: firstStateWhiteImage,
        black: blackImage,
        blackFistState: firstStateBlackImage,
        type: typeLogo
      },
      colorBallCover: '',
      useLogo: useLogo,
      logoAgregator: logoAgregator
    }
    setFeatureCoverAux(featureCoverAux)
  }

  useEffect(() => {
    openImages()
  }, [])

  useEffect(() => {
    saveAux()
  }, [shadow, logoNinegrid, film, useLogo, colorTextButton, backgroundButton, typeLogo, blackImage, whiteImage, image,logoAgregator])

  return (
    <div className='card-body row gap-5'>
      <div className="col border-end" >
        <div className="d-flex flex-column gap-5 justify-content-center">
          <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Logo marca Destaque</h4>

          <div className="d-flex gap-3">
            <div className="mt-6 ">
              <Input
                inputAttr={{
                  type: 'newRadio',
                }}
                value={'white'}
                checked={typeLogo === 'white'}
                change={(value: boolean) => {
                  if (value) {
                    setTypeLogo('white')

                  }

                }}
              />
            </div>
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="150"
              height="150"
              label={'Versão Branco'}
              value={whiteImage}
              imagemBackgroundDefault={'300x300'}
              change={(value: BaseSyntheticEvent) => {
                setWhiteImage(value ? value.target.files[0] : null)
              }}

            />
          </div>
          <div className="d-flex gap-3">
            <div className="mt-6">
              <Input
                inputAttr={{
                  type: 'newRadio',
                }}
                value={'black'}
                checked={typeLogo === 'black'}
                change={(value: any) => {
                  if (value) {
                    setTypeLogo('black')
                  }
                }}
              />
            </div>
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="150"
              height="150"
              imagemBackgroundDefault={'300x300'}
              label={'Versão Preto'}
              value={blackImage}
              change={(value: BaseSyntheticEvent) => {
                setBlackImage(value ? value.target.files[0] : null)
              }}
            />
          </div>

          <div className="row w-100">
            <div className="d-flex align-items-center justify-content-start col " >

              <span className="form-check" style={{
                fontSize: '9px',
                maxWidth: '150px',
                lineHeight: '9px',
              }} >
                Posicionado no centro da tela – deve ser um png com fundo transparente 300x300.
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-center col " >
            </div>
          </div>
        </div>
      </div>

      <div className="col border-end gap-5" >
        <div className="d-flex flex-column" style={{ gap: '18px' }}>
          <div className="d-flex flex-column">
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Logo do Agregador</h4>
            <Input
              inputAttr={{
                type: 'checkbox',
              }}
              label={'Usar logomarca do Agregador'}
              value={useLogo}
              checked={useLogo}
              change={(value: any) => {
                setUseLogo(value)
              }}
            />

            <Input
              inputAttr={{
                type: 'select',
              }}
              label={''}
              value={logoAgregator}
              options={[
                { select: 'Selecione uma cor da logo', value: '' },
                { select: 'Claro', value: 'white' },
                { select: 'Escuro', value: 'black' },
              ]}
              change={(value: any) => {
                setLogoAgregator(value)
              }}
            />
          </div>
          <div className="d-flex flex-column" style={{ gap: '11px' }}>
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Cor do botão de entrada </h4>
            <div className="d-flex flex-column" style={{ gap: '8px' }}>
              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Fundo do botão de entrar'}
                value={backgroundButton}
                change={(value: any) => {
                  SetBackgroundButton(value)
                }}
              />

              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Texto do botão de entrar'}
                value={colorTextButton}
                change={(value: any) => {
                  SetColorTextButton(value)
                }}
              />

              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label={'Sombra no botão de entrar'}
                value={shadow}
                checked={shadow}
                change={(value: any) => {
                  SetShadow(value)
                }}
              />
            </div>

          </div>
          <div className="d-flex flex-column" style={{ gap: '0px' }}>
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Logo do Ninegrid</h4>
            <Input
              inputAttr={{
                type: 'select',
              }}
              label={''}
              value={logoNinegrid}
              options={[
                { select: 'Selecione uma cor da logo', value: '' },
                { select: 'Claro', value: 'white' },
                { select: 'Escuro', value: 'black' },
              ]}
              change={(value: any) => {
                SetLogoNinegrid(value)
              }}
            />
          </div>
        </div>
      </div>


      <div className="col " >
        <div className="d-flex flex-column gap-4 h-100">
          <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Imagem - Capa de Abertura</h4>
          <div className="d-flex gap-4 align-items-center flex-column flex-exxl-row">
            <Input
              inputAttr={{
                type: 'image',
              }}
              imagemBackgroundDefault={'360x640'}
              height={'350'}
              width={'150'}
              label={''}
              value={image}
              change={(value: BaseSyntheticEvent) => {
                setImage(value ? value.target.files[0] : null)
              }}
            />
            <div className="h-75 d-flex flex-column gap-4 justify-content-start ">
              <div>
                <span style={{ fontSize: '10px' }}>Para obter melhor resultados a
                  imagem deve ter
                  entre <b>100 e 600 kb</b> tamanho <b>340 x 640 </b>px
                </span>

              </div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label={'Pelicula'}
                value={film}
                change={(value: any) => {
                  SetFilm(value)
                }}
              />

              <Input
                inputAttr={{
                  type: 'number',
                }}
                label={''}
                value={filmValue}
                change={(value: any) => {
                  SetFilmValue(value)
                }}
              />

            </div>
          </div>

          <div className="d-flex gap-4 h-100 align-items-end w-100">
            <div className="d-flex w-100" style={{ gap: '20px' }}>
              <Button text={"voltar"} color={"info"} click={() => previousPage()} btnClass="w-100" />
              <Button text={"Próximo"} color={"primary"} click={() => save()} btnClass="w-100" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}