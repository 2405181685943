/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, Fragment} from 'react'
import {toMoney} from '../../utils/formater'

type header = {
  key: string
  value: string
  type: string
  badge?: any
}

type Props = {
  data: any[]
  headers: header[]
  title: string
  tableClass?: string
}

const DashboardTable: FC<Props> = (props) => {
  const {data, headers, title, tableClass} = props

  return (
    <div className={`card w-100 border p-10 ${tableClass}`}>
      <div>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-2 mb-1 text-gray-800'>{title}</span>
        </h3>
      </div>

      {/* begin::Table container */}
      <div className='table-responsive h-400px overflow'>
        {/* begin::Table */}
        {data && data.length > 0 ? (
          <table className='table align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead className='d-flex w-100'>
              <tr className='flex-grow-1 d-flex justify-content-between'>
                {headers.map((header) => (
                  <th className={`w-25 mx-0 fw-bolder fs-7 px-0 text-gray-800`} key={header.value}>
                    {header.key}
                  </th>
                ))}
              </tr>
            </thead>
            {/* end::Table head */}

            {/* begin::Table body */}
            <tbody className='w-100'>
              {data.map((item, indexItem) => (
                <tr
                  className={`flex-grow-1 d-flex justify-content-between ${
                    indexItem % 2 === 0 && 'bg-gray-200'
                  }`}
                  key={indexItem}
                >
                  {headers.map((object) => (
                    <Fragment key={object.value}>
                      <td className='w-25 mx-2 d-flex align-items-center text-capitalize text-break px-0'>
                        {object.type == 'default' && (
                          <span className='text-gray-800 fw-normal'>{item[object.value]}</span>
                        )}
                        {object.type == 'money' && (
                          <span className='fw-bold badge badge-primary text-white'>
                            {toMoney(item[object.value])}
                          </span>
                        )}
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
        ) : (
          <div className='bg-gray-100 text-center fs-4 py-3 flex justify-center items-center opacity-25'>
            Nenhum item encontrado... <i className='fas fa-ghost ms-3 fs-3 mt-10'></i>
          </div>
        )}
        {/* end::Table */}
      </div>
      {/* end::Table container */}
    </div>
  )
}

export {DashboardTable}
