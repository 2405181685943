/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { useNavigate } from 'react-router-dom'
import Chart from 'react-apexcharts'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ItemType = {
    name: string;
    sales: number;
    individual: number;
    accumulated: number;
    type: string;
}

const items: ItemType[] = [
    {
        name: 'Bermuda Fem. Bas. Cobra R136a850 Off.white Gg/xl',
        sales: 2,
        individual: 3.4,
        accumulated: 83.1,
        type: 'B'
    },
    {
        name: 'Bermuda Fem. Cint.alta Tropical E3107a832 Bege Areia M/m',
        sales: 3,
        individual: 5.1,
        accumulated: 71.2,
        type: 'A'
    },
    {
        name: 'Bermuda Inf. Bas. Folhagem Imperial R1 Verde Indochina Gg/xl',
        sales: 3,
        individual: 5.1,
        accumulated: 76.3,
        type: 'A'
    },
    {
        name: 'Bermuda Masc. Bas. Folhagem Imperial R Verde Indochina Gg/xl',
        sales: 1,
        individual: 1.7,
        accumulated: 100,
        type: 'C'
    },
    {
        name: 'Biq. Inf. Faixa C/canal Flor Do Cer Amarelo Do Cerrado Pp/xs',
        sales: 8,
        individual: 13.6,
        accumulated: 52.5,
        type: 'A'
    },
    {
        name: 'Biq. Inf. Faixa C/canal Flor Do Cer Verde Do Cerrado Pp/xs',
        sales: 1,
        individual: 1.7,
        accumulated: 96.6,
        type: 'C'
    },
    {
        name: 'Biq. Inf. Top Listrado Tear B232i820 Rosa Poppy M/m',
        sales: 2,
        individual: 3.4,
        accumulated: 86.4,
        type: 'B'
    },
    {
        name: 'Biq. Inf. Top Manga Amendoas B193i851 Amarelo Mangaba Pp/xs',
        sales: 5,
        individual: 8.5,
        accumulated: 61.0,
        type: 'A'
    },
    {
        name: 'Biquini Cortina',
        sales: 13,
        individual: 22.0,
        accumulated: 22.0,
        type: 'A'
    },
    {
        name: 'Biquíni Calcinha Lacinho Bromélia',
        sales: 1,
        individual: 1.7,
        accumulated: 91.5,
        type: 'B'
    },
    {
        name: 'Bolsa Coqueiral',
        sales: 1,
        individual: 1.7,
        accumulated: 88.1,
        type: 'B'
    },
    {
        name: 'Bolsa Jardim Gérberas - Rosa/caramelo Un',
        sales: 10,
        individual: 16.9,
        accumulated: 39.0,
        type: 'A'
    },
    {
        name: 'Bolsa Utilitária Transparente',
        sales: 2,
        individual: 3.4,
        accumulated: 79.7,
        type: 'A'
    },
    {
        name: 'Capa Longa Palmeira Buriti E3239a869 Azul Pavao G/l',
        sales: 1,
        individual: 1.7,
        accumulated: 94.9,
        type: 'B'
    },
    {
        name: 'Chemise C/ Fendas Jaguatirica E1039a894 Off White Bali M/m',
        sales: 1,
        individual: 1.7,
        accumulated: 98.3,
        type: 'C'
    },
    {
        name: 'Saia Mídi Transpassada Algas',
        sales: 3,
        individual: 5.1,
        accumulated: 66.1,
        type: 'A'
    },
    {
        name: 'Vestido C/bab. Camaleao E2406a891 Preto G/l',
        sales: 1,
        individual: 1.7,
        accumulated: 93.2,
        type: 'B'
    },
    {
        name: 'Vestido Longo Versátil Papoula',
        sales: 1,
        individual: 1.7,
        accumulated: 89.8,
        type: 'B'
    }
];

const PageContent: FC = () => {
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const [iniDate, setIniDate] = useState('')
    const [endDate, setEndDate] = useState('')

    const [totalA, setTotalA] = useState(0)
    const [totalB, setTotalB] = useState(0)
    const [totalC, setTotalC] = useState(0)

    const [proportionProducts, setProportionProducts] = useState<any[]>([])
    const [proportionValues, setProportionValues] = useState<any[]>([])

    useEffect(() => {

        const a = items.filter(c => c.type === 'A')
        const b = items.filter(c => c.type === 'B')
        const c = items.filter(c => c.type === 'C')

        // PROPORTION PRODUCTS
        setProportionProducts([
            {
                category: 'Curva A',
                total: a.length
            },
            {
                category: 'Curva B',
                total: b.length
            },
            {
                category: 'Curva C',
                total: c.length
            }
        ])

        // PROPORTION VALUES
        setProportionValues([
            {
                category: 'Curva A',
                total: a.map(item => item.sales).reduce((prev, curr) => prev + curr, 0)

            },
            {
                category: 'Curva B',
                total: b.map(item => item.sales).reduce((prev, curr) => prev + curr, 0)
            },
            {
                category: 'Curva C',
                total: c.map(item => item.sales).reduce((prev, curr) => prev + curr, 0)
            }
        ])
    }, [])


    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='d-flex justify-content-between gap-5'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12 w-100'>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Filtros</span>
                                    <BreadCrumbs />
                                </h3>
                            </div>
                            <div className='card-body'>
                                <div className='d-flex gap-5'>
                                    <Input
                                        inputAttr={{
                                            type: 'date',
                                        }}
                                        label='Início'
                                        value={iniDate}
                                        change={(value: string) => setIniDate(value)}
                                    />
                                    <Input
                                        inputAttr={{
                                            type: 'date',
                                        }}
                                        label='Fim'
                                        value={endDate}
                                        change={(value: string) => setEndDate(value)}
                                    />
                                    <div className='d-flex align-items-end'>
                                        <Button
                                            text='Filtrar'
                                            color='primary'
                                            size='small'
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => { }}
                                            btnClass='fw-bolder mb-1'
                                            verticalPadding={3}
                                        />
                                        <Button
                                            text='Exportar'
                                            color='primary'
                                            size='small'
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => { }}
                                            btnClass='fw-bolder mb-1 mx-5'
                                            verticalPadding={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between gap-5'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12 w-100'>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Proporção produtos</span>
                                </h3>
                            </div>
                            <div className='d-flex align-items-center w-100 h-100 p-4'>
                                <div className=''>
                                    <Chart
                                        options={{
                                            colors: ['#6F6F6F', '#65BBA9', '#775B4B'],
                                            dataLabels: {
                                                enabled: true
                                            },
                                            legend: {
                                                show: false
                                            }
                                        }}
                                        series={proportionProducts.map((item) => item.total)}
                                        type="donut"
                                    />
                                </div>
                                <div className="w-75">
                                    <Chart
                                        options={{
                                            chart: { id: 'bar-chart' },
                                            xaxis: {
                                                categories: proportionProducts.map((item) => item.category)
                                            },
                                            colors: ['#6F6F6F', '#65BBA9', '#775B4B'],
                                            plotOptions: {
                                                bar: {
                                                    distributed: true,
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true
                                            },
                                            legend: {
                                                show: false
                                            }
                                        }}
                                        series={[
                                            {
                                                name: '',
                                                data: proportionProducts.map((item) => item.total)
                                            }
                                        ]}
                                        type="bar"
                                        height={220}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between gap-5'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12 w-100'>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Proporção valores</span>
                                </h3>
                            </div>
                            <div className='d-flex align-items-center w-100 h-100 p-4'>
                                <div className=''>
                                    <Chart
                                        options={{
                                            colors: ['#6F6F6F', '#65BBA9', '#775B4B'],
                                            dataLabels: {
                                                enabled: true
                                            },
                                            legend: {
                                                show: false
                                            }
                                        }}
                                        series={proportionValues.map((item) => item.total)}
                                        type="donut"
                                    />
                                </div>
                                <div className="w-75">
                                    <Chart
                                        options={{
                                            chart: { id: 'bar-chart' },
                                            xaxis: {
                                                categories: proportionValues.map((item) => item.category)
                                            },
                                            colors: ['#6F6F6F', '#65BBA9', '#775B4B'],
                                            plotOptions: {
                                                bar: {
                                                    distributed: true,
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true
                                            },
                                            legend: {
                                                show: false
                                            }
                                        }}
                                        series={[
                                            {
                                                name: '',
                                                data: proportionValues.map((item) => item.total)
                                            }
                                        ]}
                                        type="bar"
                                        height={220}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between gap-5'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12 w-100'>
                            {/* <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>Proporção valores</span>
                                </h3>
                            </div> */}
                            <div className='card-body'>
                                    <Datatable
                                        data={items}
                                        headers={[
                                            { key: 'name', value: 'name', type: 'default' },
                                            { key: 'sales', value: 'sales', type: 'default' },
                                            { key: 'individual', value: 'individual', type: 'default' },
                                            { key: 'accumulated', value: 'accumulated', type: 'default' },
                                            { key: 'type', value: 'type', type: 'default' }
                                        ]}
                                        color='primary'
                                    />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const Reportabc: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: 'Curva ABC', path: 'reportabc', isActive: true },
                ]}
            >
                Curva ABC
            </PageTitle>
            <PageContent />
        </>
    )
}

export { Reportabc }
