import { FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type PluginspaceType = {
  id: number
  name: string
  type: string
  subdomain: string
  status: string
  active: boolean
  created: Date
  parent: number
}

type CheckedItemType = {
  value: string;
  checked: boolean;
}

type ItensType = {
  type: string;
  checkedItems: CheckedItemType[]
}

type SelectType = {
  value: number;
  label: string;
  parent: number;
}

type ItemResType = {
  item: string;
  success: boolean;
}

const CopyConfigurationsContent: FC = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pluginspaces, setPluginspaces] = useState<PluginspaceType[]>([])
  const [pluginspaceFather, setPluginspaceFather] = useState<SelectType | null>(null)
  const [pluginspaceChild, setPluginspaceChild] = useState<SelectType | null>(null)
  const [checkedItems, setCheckedItems] = useState<ItensType[]>([
    {
      type: 'store',
      checkedItems: [
        {
          value: 'deliveryFee',
          checked: false,
        },
        {
          value: 'shifts',
          checked: false,
        },
        {
          value: 'paymentPlans',
          checked: false,
        },
        {
          value: 'clientData',
          checked: false,
        },
      ]
    },
    {
      type: 'account',
      checkedItems: [
        {
          value: 'groups',
          checked: false,
        },
        {
          value: 'terms',
          checked: false,
        },
      ]
    },
    {
      type: 'webapp',
      checkedItems: [
        {
          value: 'webappConfig',
          checked: false,
        },
        {
          value: 'webappPersonalize',
          checked: false,
        },
      ]
    },
    {
      type: 'integrations',
      checkedItems: [
        {
          value: 'integrationPdv',
          checked: false,
        },
        {
          value: 'integrationPayment',
          checked: false,
        },
        {
          value: 'integrationMessaging',
          checked: false,
        },
        {
          value: 'integrationOthers',
          checked: false,
        },
      ]
    },
  ]);

  const getPluginspaces = async () => {
    try {
      setLoading(true)
      const res = await GET(`/pluginspace`)
      if(res && res.data && res.data.length > 0){
        setPluginspaces(res.data)
        if(id){
          const pg = res.data.find((pluginspace:PluginspaceType) => pluginspace.id === parseInt(id))
          if(pg) setPluginspaceFather({value: pg.id, label: pg.name, parent: pg.parent})
        }
      } else {
        setPluginspaces([])
      }
      setLoading(false)
    } catch (e) {
      setPluginspaces([])
      setLoading(false)
      console.log(e)
    }
  }

  const changeCheckItem = (indexItem:number, indexCheckItem:number, value: boolean) => {
    const newCheck = [...checkedItems]
    newCheck[indexItem].checkedItems[indexCheckItem].checked = value;
    setCheckedItems(newCheck)
  }

  const copyPluginspace = async () => {
    try {
      if(pluginspaceChild && pluginspaceFather){
        let itemsCopy:string[] = [];
        checkedItems.forEach((item) => item.checkedItems.forEach((checkItem) => {
          if(checkItem.checked) itemsCopy.push(checkItem.value)
        }))
        const copyRes = await POST(`/pluginspace/copy-store`, {
          pluginspaceFather: pluginspaceFather.value,
          pluginspaceChild: pluginspaceChild.value,
          itemsCopy
        })
        if(copyRes && copyRes.success && copyRes.data){
          let failedItems:ItemResType[] = copyRes.data.filter((item: ItemResType) => !item.success)
          if(failedItems && failedItems.length > 0){
            Swal.fire({
              icon: 'warning',
              title: 'Falha em copiar alguns items',
              html: `<p>
                Falha ao realizar copia de: <br> ${failedItems.map((fail) => (`- ${i18n.t(`copy.${fail.item}`)}<br/>`))}
                Os outros itens foram atualizados!
              </p>`
            })
            return 
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Cópia realizada com sucesso',
            }).then(() => {
              navigate('/pluginspace')
            })
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Falha ao realizar copia entre lojas!',
          })
          return  
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se escolheu uma Pluginspace de origem e de destino!',
        })
        return
      }
    } catch(e:any) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Falha ao realizar copia entre lojas!',
      })
      return  
    }
  }

  const startCopyPluginspace = () => {
    if(!pluginspaceChild){
      Swal.fire({
        icon: 'error',
        title: 'Campos faltantes',
        text: 'Verifique se escolheu uma Pluginspace de destino!',
      })
      return
    }
    Swal.fire({
      title: "Você tem certeza?",
      html: `
        Isso irá apagar as configurações selecionadas da Pluginspace de destino e serão copiadas da Pluginspace de origem.<br>
        Digite "confirmar" para confirmar.
      `,
      icon: 'warning',
      input: "text",
      inputPlaceholder: 'confirmar',
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: "Confirmar",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if(result.value === 'confirmar'){
        setLoading(true);
        await copyPluginspace();
        setLoading(false)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Texto de confirmação incorreto',
        })
      }
    })
  }

  const filterPluginspaces = () => {
    return pluginspaces.filter((pg) => (pluginspaceFather && pg.id !== pluginspaceFather.value && (pg.parent === pluginspaceFather.value || (pluginspaceFather.parent === pg.parent && pg.parent !== 2))))
  }

  useEffect(() => {
    getPluginspaces()
  }, [])

  return (
    <>
      {loading ? <Loading /> :
      <div className='row g-5 gx-xxl-12'>
        <div className='d-flex justify-content-between flex-wrap'>
          <div style={{flex: 1}}>
            <div className='w-100 card'>
              <div className='card-header' style={{borderBottom: 'none'}}>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`copy.definition`)}</span>
                  <BreadCrumbs />
                </h3>
              </div>
              <div className='px-10 pb-8' >
                <div className="d-flex flex-row align-items-end gap-4 mb-6">
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    disabled={true}
                    search
                    label={i18n.t(`copy.origin`)}
                    options={pluginspaces.map((pg) => ({select: pg.name, value: pg.id}))}
                    value={pluginspaceFather}
                    change={(value: SelectType) => setPluginspaceFather(value)}
                  />
                  <i className={`fa fa-arrow-right fs-4 mb-4`}></i>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    search
                    label={i18n.t(`copy.destination`)}
                    options={filterPluginspaces().map((pg) => ({select: pg.name, value: pg.id}))}
                    value={pluginspaceChild}
                    change={(value: SelectType) => setPluginspaceChild(value)}
                  />
                </div>
                <span className='card-label fw-bolder fs-3 mt-6 mb-4'>{i18n.t(`copy.configurations`)}</span>
                <div className="d-flex flex-column flex-wrap gap-4 mt-4">
                  {checkedItems.map((item, indexItem) => (
                    <div className='d-flex flex-column'>
                      <span className='card-label fw-bolder fs-5'>{i18n.t(`copy.${item.type}`)}</span>
                      {item.checkedItems.map((checkItem, indexCheck) => (
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          label={i18n.t(`copy.${checkItem.value}`)}
                          checked={checkItem.checked}
                          value={checkItem.checked}
                          change={(value: boolean) => changeCheckItem(indexItem, indexCheck, value)}
                        />
                      ))}
                    </div>
                  ))}
                </div>
                <div className="d-flex flex-row w-100 justify-content-end">
                  <Button
                    text={i18n.t(`copy.button`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => startCopyPluginspace()}
                    btnClass='fw-bolder m-9'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
    </>
  )
}

const CopyConfigurations: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Contas', path: 'pluginspace', isActive: true },
        ]}
      >
        Copiar Pluginspace
      </PageTitle>
      <CopyConfigurationsContent />
    </>
  )
}

export { CopyConfigurations }
