import api, { setBearerToken, setBearerTokenAdm } from '../services/api'
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import { parseJwt } from '../utils/utils';

export type DecodedTokenData = {
  id: number;
  email: string;
  plugintype: string;
  name: string;
  pluginspaceId: number;
  pluginspace: string;
  profile: string;
  pluginAdm: number;
}

type LoginData = {
  accessToken: string
  expiratedAt: string
  name: string
  email: string
  plugintype: string
  pluginspaceId: number | null
  pluginspace: string;
  profile: string;
}

type AuthContextData = {
  loginInfo: LoginData
  setLoginInfo: Dispatch<SetStateAction<LoginData>>
  verifyAdm: () => boolean;
  logout: () => void;
  verifyPluginSpace: () => boolean;
}

type AuthContextProvider = {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

const AuthProvider = ({ children }: AuthContextProvider) => {
  const [loginInfo, setLoginInfo] = useState<LoginData>({
    accessToken: '',
    expiratedAt: '',
    name: '',
    email: '',
    plugintype: '',
    pluginspaceId: null,
    pluginspace: '',
    profile: '',
  })

  const logout = () => {
    setBearerToken('')
    setBearerTokenAdm('')
    localStorage.removeItem('loginInfo_ninegrid_adm')
    localStorage.removeItem('loginInfo_ninegrid_access')
    setLoginInfo({
      accessToken: '',
      expiratedAt: '',
      name: '',
      email: '',
      plugintype: '',
      pluginspaceId: null,
      pluginspace: '',
      profile: '',
    })
  }

  const getInitialData = () => {
    let login = localStorage.getItem('loginInfo_ninegrid_access')
    if (!login) login = localStorage.getItem('loginInfo_ninegrid_adm')
    if (login && typeof login === 'string') {
      const data = JSON.parse(login)
      if (process.env.REACT_APP_JWT_SALT && data) {
        const tkNoSalt = data.accessToken.split(process.env.REACT_APP_JWT_SALT).join('');
        const tk: DecodedTokenData | null = parseJwt(tkNoSalt);
        if (tk) {
          setLoginInfo({
            accessToken: data.accessToken,
            expiratedAt: data.expiratedAt,
            name: tk.name,
            email: tk.email,
            plugintype: tk.plugintype,
            pluginspaceId: tk.pluginspaceId,
            pluginspace: tk.pluginspace,
            profile: tk.profile,
          })
        }
      }
    }
  }

  const verifyAdm = () => {
    const login = localStorage.getItem('loginInfo_ninegrid_adm');
    if (!login) return false
    const data: LoginData = JSON.parse(login)
    if (process.env.REACT_APP_JWT_SALT && data) {
      const tkNoSalt = data.accessToken.split(process.env.REACT_APP_JWT_SALT).join('');
      const tk: DecodedTokenData | null = parseJwt(tkNoSalt);
      if (tk?.pluginAdm) {
        return true;
      }
    }
    return false;
  }

  const verifyToken = async () => {
    let login = localStorage.getItem('loginInfo_ninegrid_access')
    if (!login) login = localStorage.getItem('loginInfo_ninegrid_adm')
    if (login && typeof login === 'string') {
      const data: LoginData = JSON.parse(login)

      const isExpired =
        new Date(data.expiratedAt).getTime().toFixed(0) < new Date().getTime().toFixed(0)

      if (isExpired) {
        setLoginInfo({
          accessToken: '',
          expiratedAt: '',
          name: '',
          email: '',
          plugintype: '',
          pluginspaceId: null,
          pluginspace: '',
          profile: '',
        })
        localStorage.removeItem('loginInfo_ninegrid_adm')
        localStorage.removeItem('loginInfo_ninegrid_access')
        setBearerToken('')
        setBearerTokenAdm('')
      }
    }
  }

  const verifyPluginSpace = () => {
    const login = localStorage.getItem('loginInfo_ninegrid_adm');
    if (!login) return false
    const data: LoginData = JSON.parse(login)
    if (process.env.REACT_APP_JWT_SALT && data) {
      const tkNoSalt = data.accessToken.split(process.env.REACT_APP_JWT_SALT).join('');
      const tk: DecodedTokenData | null = parseJwt(tkNoSalt);
      if (tk?.pluginspaceId === 2) {
        return true;
      }
    }
    return false;
  }


  api.interceptors.request.use(
    async (config: any) => {
      if (!config.url.includes('login')) {
        verifyToken()
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    getInitialData()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        loginInfo,
        verifyAdm,
        setLoginInfo,
        logout,
        verifyPluginSpace,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
