import { BaseSyntheticEvent, FC, useEffect, useState } from "react"
import { Button } from "../Button/Button"
import { Input } from "../Input/Input"
import Swal from "sweetalert2"
import { LittleButton } from "../Button/LittleButton"
import usePluginsSpace from "../../hooks/usePluginSpace"
import { ModalWrapper } from '../Modals/General/ModalWrapper/ModalWrapper'
import useModals from "../../hooks/useModals"

type Props = {
  next: () => void
  back: () => void
  data: any
  setData: (value: any) => any
}


type CardProduct = {
  image?: File | null | string | undefined,
  value?: string
  description?: string
}



const ImagensLoja: FC<Props> = ({ next, back, data, setData }) => {
  const [imagePricipal, setImagePrincipal] = useState<File | null | string>();
  const [imageCardOne, setImageCardOne] = useState<File | null | string>();
  const [imageCardTwo, setImageCardTwo] = useState<File | null | string>();
  const [imageCardThree, setImageCardThree] = useState<File | null | string>();
  const [imageCardFour, setImageCardFour] = useState<File | null | string>();
  const [imageCardFive, setImageCardFive] = useState<File | null | string>();
  const [cardsProduct, setCardsProduct] = useState<CardProduct[]>([])

  const [cardProduct, setCardProduct] = useState<CardProduct>({})
  const [cardProductIndex, setCardProductIndex] = useState<number>(0)

  const { pluginSpace } = usePluginsSpace();
  const { showWrapperModal, closeWrapperModal } = useModals()
  const [hasProductCard, setHasProductCard] = useState<boolean>(false)




  function showImages() {
    try {
      if (data.config.images) {
        setImageCardOne(data.config.images[0].image)
        setImageCardTwo(data.config.images[1].image)
        setImageCardThree(data.config.images[2].image)
        setImageCardFour(data.config.images[3].image)
        setImageCardFive(data.config.images[4].image)
      }
  
      if (data.config.product) {
        setCardsProduct(data.config.product)
      }
  
  
      setImagePrincipal(data.image)
  
      if (pluginSpace && pluginSpace.detail && pluginSpace.detail.theme === 2) {
        setHasProductCard(true)
      }
    } catch (error) {
      console.log(error)
    }

  }


  function nextPage() {
    if (!imageCardOne || !imageCardTwo || !imageCardThree || !imageCardFour || !imageCardFive || !imagePricipal) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Verifique os campos faltantes'
      })
      return
    }
    const newData = data

    const images = [{ image: imageCardOne },
    { image: imageCardTwo },
    { image: imageCardThree },
    { image: imageCardFour },
    { image: imageCardFive }]

    newData.config.images = images;
    newData.config.product = cardsProduct;
    newData.image = imagePricipal


    setData(newData);
    next()

  }

  function backPage() {
    const newData = data

    const images = [{ image: imageCardOne },
    { image: imageCardTwo },
    { image: imageCardThree },
    { image: imageCardFour },
    { image: imageCardFive }]

    newData.config.images = images;
    newData.image = imagePricipal


    setData(newData);
    back()
  }

  useEffect(() => {
    showImages()
  }, [])

  return (
    <div className="card mt-5" style={{ padding: '2rem 5rem' }}>
      <div>
        <h3>Imagens da Loja </h3>
        <span>Aqui você configura as imagens da loja .</span>
      </div>

      <div className="row " style={{ marginTop: '14px' }}>
        <div className="col-2 d-flex flex-column border-end" style={{ gap: '16px' }}>
          <span style={{
            color: '#677484',
            fontWeight: '600'
          }}>Imagem  - Card da Loja</span>
          <Input
            inputAttr={{
              type: 'image',
            }}
            imagemBackgroundDefault={'340x340'}          
            width="100"
            height="100"
            value={imagePricipal}
            change={(value: BaseSyntheticEvent) => {
              setImagePrincipal(value ? value.target.files[0] : null)
            }}
          />
        </div>

        <div className="col-7 d-flex flex-column" style={{ gap: '16px' }}>
          <span style={{
            color: '#677484',
            fontWeight: '600'
          }}>Imagem  - Card da Loja</span>

          <div className="d-flex gap-5 justify-content-center flex-wrap">
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="200"
              height="150"
              imagemBackgroundDefault={'640x240'}
              value={imageCardOne}
              change={(value: BaseSyntheticEvent) => {
                setImageCardOne(value ? value.target.files[0] : null)
              }}
            />
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="200"
              height="150"
              imagemBackgroundDefault={'640x240'}
              value={imageCardTwo}
              change={(value: BaseSyntheticEvent) => {
                setImageCardTwo(value ? value.target.files[0] : null)
              }}
            />
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="200"
              height="150"
              imagemBackgroundDefault={'640x240'}
              value={imageCardThree}
              change={(value: BaseSyntheticEvent) => {
                setImageCardThree(value ? value.target.files[0] : null)
              }}
            />
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="200"
              imagemBackgroundDefault={'640x240'}
              height="150"
              value={imageCardFour}
              change={(value: BaseSyntheticEvent) => {
                setImageCardFour(value ? value.target.files[0] : null)
              }}
            />
            <Input
              inputAttr={{
                type: 'image',
              }}
              width="200"
              imagemBackgroundDefault={'640x240'}
              height="150"
              value={imageCardFive}
              change={(value: BaseSyntheticEvent) => {
                setImageCardFive(value ? value.target.files[0] : null)
              }}
            />
          </div>


        </div>
        <div className="col-3 d-flex flex-column justify-content-between"
        >
          <div className="position-relative">

           {!hasProductCard &&  <div className="position-absolute w-100 h-100" style={{
              zIndex: 9999,
              background: 'rgba(250,250,250,0.6)'
            }}>
            </div>
           }
            <span style={{
              color: '#677484',
              fontWeight: '600',
            }}>Imagem  - Card do Produto</span>
            <div className="d-flex gap-5 justify-content-center flex-wrap">
              <Input
                inputAttr={{
                  type: 'image_modal',
                }}
                disabled={hasProductCard}
                width="100"
                height="100"
                imagemBackgroundDefault={'340x340'}
                value={cardsProduct[0] && cardsProduct[0].image ? cardsProduct[0].image : null}
                change={(value: any) => {
                  console.log(value)
                }}
                action={() => {
                  showWrapperModal('Imagem - Card Produto')
                  setCardProduct(cardsProduct[0])
                  setCardProductIndex(0)
                }}
              />
              <Input
                inputAttr={{
                  type: 'image_modal',
                }}
                disabled={hasProductCard}
                width="100"
                height="100"
                imagemBackgroundDefault={'340x340'}
                value={cardsProduct[1] && cardsProduct[1].image ? cardsProduct[1].image : null}
                change={(value: any) => {
                  console.log(value)
                }}
                action={() => {
                  showWrapperModal('Imagem - Card Produto')
                  setCardProduct(cardsProduct[1])
                  setCardProductIndex(1)
                }}
              />
              <Input
                inputAttr={{
                  type: 'image_modal',
                }}
                disabled={hasProductCard}
                width="100"
                height="100"
                imagemBackgroundDefault={'340x340'}
                value={cardsProduct[2] && cardsProduct[2].image ? cardsProduct[2].image : null}
                change={(value: any) => {
                  console.log(value)
                }}
                action={() => {
                  showWrapperModal('Imagem - Card Produto')
                  setCardProduct(cardsProduct[2])
                  setCardProductIndex(2)
                }}
              />
              <Input
                inputAttr={{
                  type: 'image_modal',
                }}
                disabled={hasProductCard}
                width="100"
                height="100"
                imagemBackgroundDefault={'340x340'}
                value={cardsProduct[3] && cardsProduct[3].image ? cardsProduct[3].image : null}
                change={(value: any) => {
                  console.log(value)
                }}
                action={() => {
                  showWrapperModal('Imagem - Card Produto')
                  setCardProduct(cardsProduct[3])
                  setCardProductIndex(3)
                }}
              />
              <Input
                inputAttr={{
                  type: 'image_modal',
                }}
                disabled={hasProductCard}
                width="100"
                height="100"
                imagemBackgroundDefault={'340x340'}
                value={cardsProduct[4] && cardsProduct[4].image ? cardsProduct[4].image : null}
                change={(value: any) => {
                  console.log(value)
                }}
                action={() => {
                  showWrapperModal('Imagem - Card Produto')
                  setCardProduct(cardsProduct[4])
                  setCardProductIndex(4)
                }}
              />
            </div>
          </div>

          <div className="d-flex  gap-5 justify-content-end mt-5">
            <LittleButton text={"voltar"} color={"info"} click={() => backPage()} width="110px" height="35px" />

            <LittleButton text={"Próximo"} color={"primary"} click={() => nextPage()} width="110px" height="35px" />
          </div>
        </div>

      </div>

      <ModalWrapper confirmAction={() => {
        const newCardsProduto = cardsProduct;
        newCardsProduto[cardProductIndex] = cardProduct

        setCardsProduct(newCardsProduto)
        setCardProduct({})

      }}
        maxWidth={true}
        width={50}
      >
        <div className="d-flex  flex-column gap-5 card"
          style={{
            height: '30rem'
          }}>
          <div className="card-body d-flex justify-content-between">
            <div className="d-flex  flex-column gap-5">
              <div>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  value={data.name}
                  disabled={true}
                  change={() => { }}
                />
              </div>
              <Input
                inputAttr={{
                  type: 'textarea',
                }}
                label="Descrição- Resumo sobre o card do produto"
                placeholder="A Descrição deva ter no máximo 80 caracteres."
                maxLength="80"
                rows={5}
                value={cardProduct && cardProduct.description}
                change={(value: string) => {
                  const newCardProduto = { ...cardProduct }
                  newCardProduto.description = value
                  setCardProduct(newCardProduto)
                }}
              />
              <Input
                inputAttr={{
                  type: 'money',
                }}
                currency='BRL'
                locale='pt-BR'
                label="Valor do produto"
                value={cardProduct && cardProduct.value}
                change={(value: string) => {
                  const newCardProduto = { ...cardProduct }
                  newCardProduto.value = value

                  setCardProduct(newCardProduto)

                }}
              />
            </div>

            <Input
              inputAttr={{
                type: 'image',
              }}
              width="250"
              height="250"
              value={cardProduct && cardProduct.image}
              change={(value: BaseSyntheticEvent) => {
                const newCardProduto = { ...cardProduct }
                newCardProduto.image = value ? value.target.files[0] : null;

                setCardProduct(newCardProduto)

              }}
            />

          </div>


        </div>

      </ModalWrapper>

    </div>
  )


}

export { ImagensLoja }