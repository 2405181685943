/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type Props = {
  startDate: string
  setStartDate: React.Dispatch<React.SetStateAction<string>>
  finalDate: string
  setFinalDate: React.Dispatch<React.SetStateAction<string>>
}

const OrdemPedidoContent: FC<Props> = (data) => (
  <>
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Listagem de Pedidos</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              Aqui estão listados os pedidos juntamente com suas respectivas ações.
            </span>
          </h3>
        </div>
        <div className='card-body'>
          <div className='w-100 mb-10'>
            <h4 className='text-gray-800'>Selecione o período</h4>
            <div className='d-flex align-items-end'>
              <Input
                inputAttr={{type: 'date'}}
                label='Início'
                value={data.startDate}
                change={data.setStartDate}
                maxLength={data.finalDate}
              />
              <div className='mx-10'>
                <Input
                  inputAttr={{type: 'date'}}
                  label='Fim'
                  value={data.finalDate}
                  change={data.setFinalDate}
                  minLength={data.startDate}
                />
              </div>
              <Button color='primary' text='Procurar' click={() => {}} btnClass='h-45px' />
            </div>
          </div>
          <Datatable
            data={[
              {
                order: 1661637,
                local: 'Retirar',
                date: '05/05/2022 07:57:04',
                status: 'Aguardando',
                orders: '001 X Costela com molho',
                client: 'Filipe filiperdo@gmail.com (11)980344380',
              },
            ]}
            headers={[
              {key: 'Pedido', value: 'order', type: 'default'},
              {key: 'Local', value: 'local', type: 'default'},
              {key: 'Data', value: 'date', type: 'default'},
              {
                key: 'Status',
                value: 'status',
                type: 'badge',
                badge: {
                  pronto: 'success',
                  cancelado: 'danger',
                  aguardando: 'warning',
                },
              },
              {key: 'Pedidos', value: 'orders', type: 'default'},
              {key: 'Cliente', value: 'client', type: 'default'},
              //{ key: "Ações", value: "acoes", type: ""},
            ]}
            options={
              [
                //{ icon: "fas fa-pen", path: '/grupos/editar' , title: "Editar grupo" },
                //{ icon: "fas fa-trash", action: () => {}, path: '', title: "Excluir grupo" },
              ]
            }
            color='primary'
          />
        </div>
      </div>
    </div>
  </>
)

const OrdemPedido: FC = () => {
  const [startDate, setStartDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Listagem de Pedidos', path: 'smart-order/ordem-pedido', isActive: true},
        ]}
      >
        Listagem de Pedidos
      </PageTitle>
      <OrdemPedidoContent
        startDate={startDate}
        setStartDate={setStartDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
      />
    </>
  )
}

export {OrdemPedido}
