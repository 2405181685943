import { FC, useEffect, useState } from 'react'
import { Button } from '../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Loading } from '../../../components/Loading/Loading'

import { GET, POST } from '../../../services/api'
import { dateBRtoDateUS, toMoney } from '../../../utils/formater'
import useModals from '../../../hooks/useModals'
import moment from 'moment'
import { i18n } from '../../../translate/i18n'
import { Input } from '../../../components/Input/Input'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

const DetalhesClienteContent: FC = () => {
  const dataAtual = new Date()
  const dataAtualArray = dataAtual.toLocaleDateString().split('T')[0].split('/')
  const [startDate, setStartDate] = useState(dateBRtoDateUS(`01/${dataAtualArray[1]}/${dataAtualArray[2]}`))
  const [finalDate, setFinalDate] = useState(dateBRtoDateUS(`${dataAtualArray[0]}/${dataAtualArray[1]}/${dataAtualArray[2]}`))
  const { showWrapperModal } = useModals()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [city, setCity] = useState<string>('')
  const [total, setTotal] = useState<string>('0.00')
  const [totalitems, setTotalItems] = useState<string>('0')
  const [totalorders, setTotalOrders] = useState<string>('0')
  const [orderid, setOrderId] = useState<string>('0')

  const [orders, setOrders] = useState<any[]>([])
  const [smartorder, setSmartOrder] = useState<any[]>([])

  const navigate = useNavigate()

  const getCustomer = async () => {
    try {
      const res = (await GET(`/customer/orders/${atob(id || '')}`)).data[0]
      const address = res.customerAddresses[0]
      setName(res.name)
      setEmail(res.email)
      setState(address.city)
      setAddress(address.street.concat(' - ', address.district))
      setCity(address.city.concat(' - ', address.state))
      setLoadingCustomer(true);
      await getCustomerOrdersInfo(res.id);
      setLoadingCustomer(false);
    } catch (e) {
      console.log(e)
    }
  }

  const getCustomerOrdersInfo = async (id: number) => {
    try {
      const res = (await GET(`/orders/infoCustomer/${id}`)).data

      setTotal(res ? (res.total / 100).toString() : '0')
      setTotalItems(res ? res.items : 0)
      setTotalOrders(res ? res.orders : 0)
    } catch (e) {
      console.log(e)
    }
  }

  const getOrders = async () => {
    try {
      setLoading(true);
      const res = await POST(`/orders/getOrderCustomer?order=ASC`, {
        customerId: parseInt(atob(id || '')),
        startDate: startDate + 'T00:00:00',
        finalDate: finalDate + 'T23:59:59',
      })
      const orders = res.data.map((row: any) => {
        return {
          id: row.id,
          items: row.smartOrder ? row.smartOrder[0].quantity : 0,
          date: moment(row.created).format('DD/MM/YYYY hh:mm'),
          local: row.location.name,
          status: row.status[0].name,
          smartorder: row.smartOrder,
          orderIdExt: row.orderidExt,
        }
      })
      setOrders(orders)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const loadSmartOrder = async (orderId: string) => {
    const listOrder = orders
    const list = listOrder.find((row) => row.id === parseInt(orderId))
    const listsmart = list.smartorder.map((row: any) => {
      return {
        product: row.content.productName,
        quantity: row.quantity,
        price: row.price,
        total: row.quantity * row.price,
      }
    })
    setSmartOrder(listsmart)
  }

  const init = async () => {
    setLoading(true)
    await getCustomer();
    await getOrders();
    setLoading(false);
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card p-5'>
            <div className='card-header'>
              <div className='card-toolbar d-flex justify-content-between w-100 '>
                <h2 className='text-black-700'>Visão Geral</h2>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-arrow-left fs-4'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/customer/report')}
                  btnClass='fw-bolder me-5'
                />
              </div>
            </div>


            <div className='d-flex align-items-center p-10 border-bottom'>
              <div className='d-flex align-items-center justify-content-center rounded-circle bg-primary w-100px h-100px text-white'>
                <p className='display-5 m-0'>{name[0]}</p>
              </div>

              <div className='ms-10 text-muted fw-bold'>
                <h2 className='text-gray-700'>{name}</h2>
                <div className='mb-5'>
                  <span className='text-hover-primary me-12'>
                    <i className='bi bi-envelope fs-4 me-2'></i>
                    {email}
                  </span>
                  <span className='text-hover-primary'>
                    <i className='bi bi-pin-map fs-4 me-2'></i>
                    {state}
                  </span>
                </div>
                <p className='mb-1'>{address}</p>
                <p className='mb-1'>{city}</p>
              </div>
            </div>
            {loadingCustomer ? <Loading /> :
              <div className='d-flex align-items-center justify-content-between p-10'>
                <div className='d-flex align-items-center'>
                  <i className='bi bi-coin display-6 text-muted me-5'></i>
                  <div>
                    <p className='mb-2 fw-bold'>{i18n.t(`customer_list.total_profit`)}</p>
                    <p className='mb-2 fw-bolder fs-4'>{toMoney(total)}</p>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='bi bi-stars display-6 text-muted me-5'></i>
                  <div>
                    <p className='mb-2 fw-bold'>{i18n.t(`customer_list.total_itens`)}</p>
                    <p className='mb-2 fw-bolder fs-4 text-center'>{totalitems}</p>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='bi bi-pie-chart display-6 text-muted me-5'></i>
                  <div>
                    <p className='mb-2 fw-bold'>{i18n.t(`customer_list.total_orders`)}</p>
                    <p className='mb-2 fw-bolder fs-4 text-center'>{totalorders}</p>
                  </div>
                </div>
              </div>
            }
          </div>

          <div className='card pb-5 mt-10'>
            <div className='card-header py-5'>
              <h4 className='text-gray-800'>{i18n.t(`customer_list.select_time`)}</h4>
              <BreadCrumbs />
              <div className='d-flex align-items-end mt-5 flex-row w-100 gap-5'>
                <div className='flex-1'>
                  <Input
                    inputAttr={{ type: 'date' }}
                    label={i18n.t(`customer_list.first`)}
                    defaultValue={startDate}
                    value={startDate}
                    change={setStartDate}
                    maxLength={finalDate}
                  />
                </div>
                <div className='flex-1'>
                  <Input
                    inputAttr={{ type: 'date' }}
                    label={i18n.t(`customer_list.end`)}
                    value={finalDate}
                    change={setFinalDate}
                    minLength={startDate}
                  />
                </div>
                <div className='flex-1'>
                  <Button
                    color='primary'
                    text={i18n.t(`buttons.search`)}
                    click={() => getOrders()}
                    btnClass='float-right'
                  />
                </div>
              </div>
            </div>
            <div className='px-10 py-5'>
              <Datatable
                data={orders}
                headers={[
                  { key: 'Id', value: 'orderIdExt', type: 'default' },
                  { key: 'Itens', value: 'items', type: 'default' },
                  { key: 'Data', value: 'date', type: 'default' },
                  { key: 'Local', value: 'local', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'default',
                  },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: '',
                    isButton: true,
                    textButton: i18n.t(`buttons.view`),
                    action: (item) => {
                      setOrderId(item.id)
                      loadSmartOrder(item.id)
                      showWrapperModal(`${i18n.t(`customer_list.order_detail`)} #${item.id}`)
                    },
                    color: 'primary',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
          <ModalWrapper confirmAction={() => { }} noAction={true}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='flex-grow-1'>
                <table className='table table-bordered'>
                  <thead>
                    <tr>
                      <th className='w-200px'></th>
                      <th className='min-w-100px'></th>
                      <th className='min-w-100px'></th>
                      <th className='min-w-1100px'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {smartorder.map((row) => (
                      <tr>
                        <td className='text-left w-50'>{row.product}</td>
                        <td className='text-right'>{toMoney((row.price / 100).toString())}</td>
                        <td className='text-center'>{row.quantity}</td>
                        <td className='text-right w-100'>
                          {toMoney((row.total / 100).toString())}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const DetalhesCliente: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`customer_list.customer_list`), path: 'customer/report', isActive: true },
          { title: i18n.t(`customer_list.detail`), path: 'customer/report', isActive: true },
        ]}
      >
        {i18n.t(`customer_list.detail`)}
      </PageTitle>
      <DetalhesClienteContent />
    </>
  )
}

export { DetalhesCliente }
