/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Button} from '../../../../components/Button/Button'
import {Loading} from '../../../../components/Loading/Loading'
import {Turno} from '../../../../components/Turno/Turno'
import {GET, POST} from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ShiftTypeResponse = {
  timeStart: string
  timeStart2: string
  timeEnd: string
  timeEnd2: string
  weekday: number
  locationId: number
  checked?: boolean
}

const TurnosContent: FC = () => {
  const navigate = useNavigate()

  const diasSemana = [i18n.t(`shifts.sunday`), i18n.t(`shifts.monday`), i18n.t(`shifts.tuesday`), i18n.t(`shifts.wednesday`), i18n.t(`shifts.thursday`), i18n.t(`shifts.friday`), i18n.t(`shifts.saturday`)]
  const [diaCheck, setDiaCheck] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  // Domingo
  const [inicioTurno01, setInicioTurno01] = useState<string>('')
  const [fimTurno01, setFimTurno01] = useState<string>('')
  const [inicioTurno02, setInicioTurno02] = useState<string>('')
  const [fimTurno02, setFimTurno02] = useState<string>('')
  const [check0, setCheck0] = useState<boolean>(false)

  // Segunda
  const [inicioTurno11, setInicioTurno11] = useState<string>('')
  const [fimTurno11, setFimTurno11] = useState<string>('')
  const [inicioTurno12, setInicioTurno12] = useState<string>('')
  const [fimTurno12, setFimTurno12] = useState<string>('')
  const [check1, setCheck1] = useState<boolean>(false)

  // Terça
  const [inicioTurno21, setInicioTurno21] = useState<string>('')
  const [fimTurno21, setFimTurno21] = useState<string>('')
  const [inicioTurno22, setInicioTurno22] = useState<string>('')
  const [fimTurno22, setFimTurno22] = useState<string>('')
  const [check2, setCheck2] = useState<boolean>(false)

  // Quarta
  const [inicioTurno31, setInicioTurno31] = useState<string>('')
  const [fimTurno31, setFimTurno31] = useState<string>('')
  const [inicioTurno32, setInicioTurno32] = useState<string>('')
  const [fimTurno32, setFimTurno32] = useState<string>('')
  const [check3, setCheck3] = useState<boolean>(false)

  // Quinta
  const [inicioTurno41, setInicioTurno41] = useState<string>('')
  const [fimTurno41, setFimTurno41] = useState<string>('')
  const [inicioTurno42, setInicioTurno42] = useState<string>('')
  const [fimTurno42, setFimTurno42] = useState<string>('')
  const [check4, setCheck4] = useState<boolean>(false)

  // Sexta
  const [inicioTurno51, setInicioTurno51] = useState<string>('')
  const [fimTurno51, setFimTurno51] = useState<string>('')
  const [inicioTurno52, setInicioTurno52] = useState<string>('')
  const [fimTurno52, setFimTurno52] = useState<string>('')
  const [check5, setCheck5] = useState<boolean>(false)

  // Sábado
  const [inicioTurno61, setInicioTurno61] = useState<string>('')
  const [fimTurno61, setFimTurno61] = useState<string>('')
  const [inicioTurno62, setInicioTurno62] = useState<string>('')
  const [fimTurno62, setFimTurno62] = useState<string>('')
  const [check6, setCheck6] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)

  const getShifts = async () => {
    try {
      setLoading(true)
      const shiftRes = await GET('/location-operation')

      console.log(shiftRes)

      shiftRes.data.map((shift: ShiftTypeResponse) => {
        switch (shift.weekday) {
          case 0:
            setInicioTurno01(shift.timeStart)
            setInicioTurno02(shift.timeStart2)
            setFimTurno01(shift.timeEnd)
            setFimTurno02(shift.timeEnd2)
            setCheck0(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 1:
            setInicioTurno11(shift.timeStart)
            setInicioTurno12(shift.timeStart2)
            setFimTurno11(shift.timeEnd)
            setFimTurno12(shift.timeEnd2)
            setCheck1(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 2:
            setInicioTurno21(shift.timeStart)
            setInicioTurno22(shift.timeStart2)
            setFimTurno21(shift.timeEnd)
            setFimTurno22(shift.timeEnd2)
            setCheck2(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 3:
            setInicioTurno31(shift.timeStart)
            setInicioTurno32(shift.timeStart2)
            setFimTurno31(shift.timeEnd)
            setFimTurno32(shift.timeEnd2)
            setCheck3(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 4:
            setInicioTurno41(shift.timeStart)
            setInicioTurno42(shift.timeStart2)
            setFimTurno41(shift.timeEnd)
            setFimTurno42(shift.timeEnd2)
            setCheck4(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 5:
            setInicioTurno51(shift.timeStart)
            setInicioTurno52(shift.timeStart2)
            setFimTurno51(shift.timeEnd)
            setFimTurno52(shift.timeEnd2)
            setCheck5(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 6:
            setInicioTurno61(shift.timeStart)
            setInicioTurno62(shift.timeStart2)
            setFimTurno61(shift.timeEnd)
            setFimTurno62(shift.timeEnd2)
            setCheck6(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
        }
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getShifts()
  }, [])

  const changeShifts = async () => {
    try {
      setLoading(true)
      const locationRes = await GET('/location')
      const location = locationRes.data.find(
        (locationFind: {id: number; type: string}) => locationFind.type === 'address'
      )
      let data: ShiftTypeResponse[] = []
      if (check0) {
        data.push({
          weekday: 0,
          timeStart: inicioTurno01 === '' ? '00:00:00' : inicioTurno01,
          timeStart2: inicioTurno02 === '' ? '00:00:00' : inicioTurno02,
          timeEnd: fimTurno01 === '' ? '00:00:00' : fimTurno01,
          timeEnd2: fimTurno02 === '' ? '00:00:00' : fimTurno02,
          locationId: location.id,
          checked: check0,
        })
      }
      if (check1) {
        data.push({
          weekday: 1,
          timeStart: inicioTurno11 === '' ? '00:00:00' : inicioTurno11,
          timeStart2: inicioTurno12 === '' ? '00:00:00' : inicioTurno12,
          timeEnd: fimTurno11 === '' ? '00:00:00' : fimTurno11,
          timeEnd2: fimTurno12 === '' ? '00:00:00' : fimTurno12,
          locationId: location.id,
          checked: check1,
        })
      }
      if (check2) {
        data.push({
          weekday: 2,
          timeStart: inicioTurno21 === '' ? '00:00:00' : inicioTurno21,
          timeStart2: inicioTurno22 === '' ? '00:00:00' : inicioTurno22,
          timeEnd: fimTurno21 === '' ? '00:00:00' : fimTurno21,
          timeEnd2: fimTurno22 === '' ? '00:00:00' : fimTurno22,
          locationId: location.id,
          checked: check2,
        })
      }
      if (check3) {
        data.push({
          weekday: 3,
          timeStart: inicioTurno31 === '' ? '00:00:00' : inicioTurno31,
          timeStart2: inicioTurno32 === '' ? '00:00:00' : inicioTurno32,
          timeEnd: fimTurno31 === '' ? '00:00:00' : fimTurno31,
          timeEnd2: fimTurno32 === '' ? '00:00:00' : fimTurno32,
          locationId: location.id,
          checked: check3,
        })
      }
      if (check4) {
        data.push({
          weekday: 4,
          timeStart: inicioTurno41 === '' ? '00:00:00' : inicioTurno41,
          timeStart2: inicioTurno42 === '' ? '00:00:00' : inicioTurno42,
          timeEnd: fimTurno41 === '' ? '00:00:00' : fimTurno41,
          timeEnd2: fimTurno42 === '' ? '00:00:00' : fimTurno42,
          locationId: location.id,
          checked: check4,
        })
      }
      if (check5) {
        data.push({
          weekday: 5,
          timeStart: inicioTurno51 === '' ? '00:00:00' : inicioTurno51,
          timeStart2: inicioTurno52 === '' ? '00:00:00' : inicioTurno52,
          timeEnd: fimTurno51 === '' ? '00:00:00' : fimTurno51,
          timeEnd2: fimTurno52 === '' ? '00:00:00' : fimTurno52,
          locationId: location.id,
          checked: check5,
        })
      }
      if (check6) {
        data.push({
          weekday: 6,
          timeStart: inicioTurno61 === '' ? '00:00:00' : inicioTurno61,
          timeStart2: inicioTurno62 === '' ? '00:00:00' : inicioTurno62,
          timeEnd: fimTurno61 === '' ? '00:00:00' : fimTurno61,
          timeEnd2: fimTurno62 === '' ? '00:00:00' : fimTurno62,
          locationId: location.id,
          checked: check6,
        })
      }
      console.log(data)
      const shiftRes = await POST('/location-operation/change-all', {data})
      setLoading(false)
      if (shiftRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Turnos cadastrados',
          text: 'Cadastro realizado com sucesso!',
        }).then(() => navigate('/configuracoes/turnos'))
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao cadastrar turnos!',
        }).then(() => navigate('/configuracoes/turnos'))
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar turnos!',
      }).then(() => navigate('/configuracoes/turnos'))
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`shifts.shifts`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`shifts.here_are_listed_the_shifts_for_each_day_of_the_week`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <Turno
                key={diasSemana[0]}
                dia={diasSemana[0]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno01}
                turnoInicio2={inicioTurno02}
                turnoFim1={fimTurno01}
                turnoFim2={fimTurno02}
                setTurnoInicio1={(value: string) => setInicioTurno01(value)}
                setTurnoInicio2={(value: string) => setInicioTurno02(value)}
                setTurnoFim1={(value: string) => setFimTurno01(value)}
                setTurnoFim2={(value: string) => setFimTurno02(value)}
                setChecked={(value: boolean) => setCheck0(value)}
                checked={check0}
              />

              <Turno
                key={diasSemana[1]}
                dia={diasSemana[1]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno11}
                turnoInicio2={inicioTurno12}
                turnoFim1={fimTurno11}
                turnoFim2={fimTurno12}
                setTurnoInicio1={(value: string) => setInicioTurno11(value)}
                setTurnoInicio2={(value: string) => setInicioTurno12(value)}
                setTurnoFim1={(value: string) => setFimTurno11(value)}
                setTurnoFim2={(value: string) => setFimTurno12(value)}
                setChecked={(value: boolean) => setCheck1(value)}
                checked={check1}
              />

              <Turno
                key={diasSemana[2]}
                dia={diasSemana[2]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno21}
                turnoInicio2={inicioTurno22}
                turnoFim1={fimTurno21}
                turnoFim2={fimTurno22}
                setTurnoInicio1={(value: string) => setInicioTurno21(value)}
                setTurnoInicio2={(value: string) => setInicioTurno22(value)}
                setTurnoFim1={(value: string) => setFimTurno21(value)}
                setTurnoFim2={(value: string) => setFimTurno22(value)}
                setChecked={(value: boolean) => setCheck2(value)}
                checked={check2}
              />

              <Turno
                key={diasSemana[3]}
                dia={diasSemana[3]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno31}
                turnoInicio2={inicioTurno32}
                turnoFim1={fimTurno31}
                turnoFim2={fimTurno32}
                setTurnoInicio1={(value: string) => setInicioTurno31(value)}
                setTurnoInicio2={(value: string) => setInicioTurno32(value)}
                setTurnoFim1={(value: string) => setFimTurno31(value)}
                setTurnoFim2={(value: string) => setFimTurno32(value)}
                setChecked={(value: boolean) => setCheck3(value)}
                checked={check3}
              />

              <Turno
                key={diasSemana[4]}
                dia={diasSemana[4]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno41}
                turnoInicio2={inicioTurno42}
                turnoFim1={fimTurno41}
                turnoFim2={fimTurno42}
                setTurnoInicio1={(value: string) => setInicioTurno41(value)}
                setTurnoInicio2={(value: string) => setInicioTurno42(value)}
                setTurnoFim1={(value: string) => setFimTurno41(value)}
                setTurnoFim2={(value: string) => setFimTurno42(value)}
                setChecked={(value: boolean) => setCheck4(value)}
                checked={check4}
              />

              <Turno
                key={diasSemana[5]}
                dia={diasSemana[5]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno51}
                turnoInicio2={inicioTurno52}
                turnoFim1={fimTurno51}
                turnoFim2={fimTurno52}
                setTurnoInicio1={(value: string) => setInicioTurno51(value)}
                setTurnoInicio2={(value: string) => setInicioTurno52(value)}
                setTurnoFim1={(value: string) => setFimTurno51(value)}
                setTurnoFim2={(value: string) => setFimTurno52(value)}
                setChecked={(value: boolean) => setCheck5(value)}
                checked={check5}
              />

              <Turno
                key={diasSemana[6]}
                dia={diasSemana[6]}
                diaCheckValue={diaCheck}
                turnoInicio1={inicioTurno61}
                turnoInicio2={inicioTurno62}
                turnoFim1={fimTurno61}
                turnoFim2={fimTurno62}
                setTurnoInicio1={(value: string) => setInicioTurno61(value)}
                setTurnoInicio2={(value: string) => setInicioTurno62(value)}
                setTurnoFim1={(value: string) => setFimTurno61(value)}
                setTurnoFim2={(value: string) => setFimTurno62(value)}
                setChecked={(value: boolean) => setCheck6(value)}
                checked={check6}
              />

              <div className='w-100 d-flex justify-content-end align-items-center mt-10'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => changeShifts()}
                  btnClass='fs-6'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Turnos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`shifts.shifts`), path: 'configuracoes/turnos', isActive: true},
        ]}
      >
        {i18n.t(`shifts.shifts`)}
      </PageTitle>
      <TurnosContent />
    </>
  )
}

export {Turnos}
