import { FC, SetStateAction, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import Swal from 'sweetalert2'
import { GET, POST, PUT } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import { usingTotvs } from '../../../../utils/utils'
import { i18n } from '../../../../translate/i18n'
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'


const EditarGradesContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [variation, setVariation] = useState('')
  const [status, setStatus] = useState<boolean>(false)
  const [list, setList] = useState<string[]>([])
  const [disableFieldsTotvs, setDisableFieldTotvs] = useState(false);



  const addList = () => {
    const newList = list;
    newList.push(variation)
    setVariation('');
    setList(newList)
  }

  const removeList = (variationPosition: number) => {
    const newList = list.filter((variationFiltered, index) => variationPosition !== index);
    setVariation('');
    setList(newList);
  }

  const changeValueFromList = (newVariation: string, variationPosition: number) => {
    const newList = list.map((variationFromList, index) => {
      if (index === variationPosition) {
        variationFromList = newVariation;
      }
      return variationFromList;
    })
    setList(newList);
  }

  const editGrid = async () => {
    try {
      if (!name || list.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const gridRes = await PUT(`/grid/${id}`, {
          name: name,
          variation: list,
          status: status
        })
        setLoading(false)
        if (gridRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Grade alterada',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate('/grid'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar a grade!',
          })
        }
      }
      return
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar a grade!',
      })
    }
  }

  const getGrid = async () => {
    try {
      setLoading(true)
      const useTotvs = await usingTotvs();
      if (useTotvs) {
        setDisableFieldTotvs(true);
      }
      const gridRes = await GET(`/grid/${id}`)
      setName(gridRes.data[0].name)
      setStatus(gridRes.data[0].status)
      const listVariation: string[] = Object.entries(JSON.parse(gridRes.data[0].variation)).map((valueVariation: any) => valueVariation[1])
      setList(listVariation)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const onDragEnd = (res: DropResult, list: any, setList: any) => {
    if (!res.destination) return

    const { source, destination } = res;
    const copiedItems = [...list]

    const [removed] = copiedItems.splice(source.index, 1);

    copiedItems.splice(destination.index, 0, removed)

    setList(copiedItems)


  }



  useEffect(() => {
    getGrid();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.grid_products`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='card-body border-bottom'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-5 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'string',
                      }}
                      placeholder={i18n.t(`smart_order.grid_name`)}
                      label={i18n.t(`smart_order.grid_name`)}
                      change={(value: string) => setName(value)}
                      value={name}
                      disabled={disableFieldsTotvs}
                    />
                  </div>
                  <div className='ms-5 flex-grow-1 '>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      value={status}
                      change={setStatus}
                      label={i18n.t(`general.active`)}
                      options={[
                        { select: i18n.t(`general.active`), value: true },
                        { select: i18n.t(`general.inactive`), value: false },
                      ]}
                    />
                  </div>
                </div>
                <div className='form-group mt-5'>
                  <label className='form-label fw-normal'>{i18n.t(`smart_order.variation`)}</label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control fw-normal'
                      placeholder={i18n.t(`smart_order.variation`)}
                      value={variation}
                      onChange={(event) => setVariation(event.target.value)}
                      disabled={disableFieldsTotvs}
                    />
                    <div className='input-group-append'>
                      <button className='btn btn-success' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button' onClick={() => addList()}>
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <DragDropContext onDragEnd={result => onDragEnd(result, list, setList)}>
                  <Droppable droppableId="ROOT" type='group'>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {list.map((variationListed, index) => (
                          <Draggable key={variationListed} draggableId={variationListed} index={index}>
                            {(provided) => (
                              <div className='form-group mt-5' key={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className='input-group ms-3 me-3'>
                                  <button className='btn btn-dark' disabled>
                                    <i className="bi bi-arrows-move"></i>
                                  </button>
                                  <input
                                    type='text'
                                    className='form-control fw-normal'
                                    placeholder={i18n.t(`smart_order.variation`)}
                                    value={variationListed}
                                    onChange={(event) => changeValueFromList(event.target.value, index)}
                                    disabled={disableFieldsTotvs}
                                  />
                                  <div className='input-group-append'>
                                    <button disabled={disableFieldsTotvs} className='btn btn-danger' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button' onClick={() => removeList(index)}>
                                      -
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}

                      </div>
                    )}
                  </Droppable>
                </DragDropContext>



              </div>
              <div className="d-flex w-100">
                <div className="col-6 d-flex justify-content-start">
                  <Button
                    text={i18n.t(`buttons.back`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate(-1)}
                    btnClass='fw-bolder m-9'
                  />
                </div>
                <div className="col-6  d-flex justify-content-end">
                  <Button
                    text={i18n.t(`buttons.save`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => editGrid()}
                    btnClass='fw-bolder m-9'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const EditarGrades: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.grid_products`), path: 'grid', isActive: true },
          { title: i18n.t(`general.edit`), path: 'grid/editar', isActive: true },
        ]}
      >
        {i18n.t(`smart_order.grid_products`)}
      </PageTitle>
      <EditarGradesContent />
    </>
  )
}

export { EditarGrades }
