import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../../services/api'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type PermissionType = {
  id: number;
  name: string;
}

const PageContent: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('user');
  const [email, setEmail] = useState<string>('');
  const [image, setImage] = useState<File | null>(null);
  const [password, setPassword] = useState<string>('');
  const [permissionId, setPermissionId] = useState<number>(0);
  const [permissions, setPermissions] = useState<PermissionType[]>([]);

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Parâmetros da conta</span>
                <BreadCrumbs />
                {/* <span className='text-muted mt-1 fw-bold fs-7'>
                  Preencha as informações abaixo e clique no botão criar para salvar as alterações.
                </span> */}
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className="form-group row">
                <label className="col-lg-3 col-form-label offset-1 text-right" style={{ textAlign: 'right' }}>Bandeiras aceitas:</label>
                <div className="col-lg-6">
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    change={(value: string) => console.log(value)}
                    defaultValue={''}
                    options={[]}
                  />
                  <span className="form-text text-muted">Quais bandeiras devem aparecer no pagamento na entrega.</span>
                </div>
              </div>
              <br />
              <div className="form-group row">
                <label className="col-lg-3 col-form-label offset-1 text-right" style={{ textAlign: 'right' }}>Valor mínimo:</label>
                <div className="col-lg-1 text-center">
                  <span className="switch">
                    <label>
                    <Input
                              value={''}
                              checked={true}
                              change={() =>  {}}
                              inputAttr={{
                                type: 'checkbox',
                              }}
                              checkSwift={true}
                            />
                      <span></span>
                    </label>
                  </span>
                </div>
                <div className="col-lg-5">
                  <input type="number" className="form-control" name="min_value" id="min_value" placeholder="15,00" value="" />
                  <span className=" form-text text-muted">Valor mínimo do pedido. Valores abaixo do definido não serão enviados.</span>
                </div>
              </div>



            </div>
            <div className="d-flex w-100">
              <div className="col-12  d-flex justify-content-end">
                <Button
                  text='Salvar'
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => console.log('OK')}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

const FoodConfigAdicionais: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Conta', path: '#', isActive: true },
          { title: 'Parâmetros da conta', path: '#', isActive: true },
        ]}
      >
        Parâmetros da conta
      </PageTitle>
      <PageContent />
    </>
  )
}

export { FoodConfigAdicionais }
