import { BaseSyntheticEvent, FC, SetStateAction, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import Swal from 'sweetalert2'
import { GET, POST, PUT } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid'
import { removeEspacosEmBranco } from '../../../../utils/formater'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ProductTypeResponse = {
  id: number;
  name: string;
}

type LocationTypeResponse = {
  id: number;
  name: string;
  type?: string;
}

type GridTypeResponse = {
  id: number;
  name: string;
  variation: string;
}

type SelectType = {
  value: string;
  label: string;
}

const CriarVariacoesContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { loginInfo } = useAuth();
  const [status, setStatus] = useState<boolean>(true);
  const [products, setProducts] = useState<ProductTypeResponse[]>([]);
  const [locations, setLocations] = useState<LocationTypeResponse[]>([]);
  const [productId, setProductId] = useState<number>(0)
  const [productSelected, setProductSelected] = useState<SelectType>({value: '0', label: 'Selecione um produto'});
  const [locationId, setLocationId] = useState<number>(0)
  const [selectedGrid, setSelectedGrid] = useState('')
  const [grids, setGrids] = useState<GridTypeResponse[]>([]);
  const [gridList, setGridList] = useState<GridTypeResponse[]>([])
  const [gridConfirmed, setGridConfirmed] = useState(false);

  const [image, setImage] = useState<File | null>(null);
  const [sku, setSku] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [gtin, setGtin] = useState('');
  const [mpn, setMpn] = useState('');
  const [ncm, setNcm] = useState('');
  const [costPrice, setCostPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [promotionalPrice, setPromotionalPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [deep, setDeep] = useState(0);
  const [stockSituation, setStockSituation] = useState(0);
  const [outStockSituation, setOutStockSituation] = useState(0);
  const [inventory, setInventory] = useState(0);
  const [minStock, setMinStock] = useState(0);
  const [gridSelected, setGridSelected] = useState<any>({});

  const [variationNone, setVariationNone] = useState(false);
  const [canAlert, setCanAlert] = useState(false);

  const getLocations = async () => {
    try {
      const locationRes = await GET('/location')
      const newLocations = [{
        id: 0,
        name: i18n.t(`smart_order.select_modality`)
      }]
      locationRes.data.map((location: LocationTypeResponse) => {
        if (location.type !== 'address') {
          newLocations.push({
            id: location.id,
            name: location.name
          });
        }
      })
      setLocations(newLocations);
    } catch (e) {
      console.log(e)
    }
  }

  const getProducts = async () => {
    try {
      const productRes = await GET('/product')
      const newProducts = [{
        id: 0,
        name: i18n.t(`smart_order.select_product`)
      }]
      productRes.data.map((product: ProductTypeResponse) => {
        newProducts.push({
          id: product.id,
          name: product.name
        });
      })
      setProducts(newProducts);
    } catch (e) {
      console.log(e)
    }
  }

  const getGrids = async () => {
    try {
      const gridRes = await GET('/grid')
      const newGrids: GridTypeResponse[] = []
      gridRes.data.map((grid: GridTypeResponse) => {
        newGrids.push({
          id: grid.id,
          name: grid.name,
          variation: grid.variation
        });
      })
      setGrids(newGrids);
    } catch (e) {
      console.log(e)
    }
  }

  const addToGridList = () => {
    const gridFinded = gridList.find((grid) => grid.id === parseInt(selectedGrid))
    if (!gridFinded) {
      const newGridList = gridList;
      const newGrid = grids.find((grid) => grid.id === parseInt(selectedGrid))
      if (newGrid) {
        newGridList.push(newGrid)
        setGridList(newGridList)
        setSelectedGrid('')
      }
    }
  }

  const removeToGridList = (idGrid: number) => {
    const newGridList = gridList.filter((grid) => grid.id !== idGrid);
    setGridList(newGridList)
    setSelectedGrid('')
  }

  const confirmGrid = () => {
    if (!productId || !locationId) {
      Swal.fire({
        icon: 'error',
        title: 'Campos faltantes',
        text: 'Verifique se selecionou produto e modalidade!',
      })
    } else {
      if (gridList.length > 0) {
        setGridConfirmed(true)
        let gridMaked = gridSelected;
        gridList.map((grid) => {
          const gridVariation = Object.entries(JSON.parse(grid.variation))[0];
          gridMaked[grid.name] = gridVariation[1];
        })
        
        setGridSelected(gridMaked);
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Nenhuma grade adicionada',
        })
      }
    }
  }

  const createVariation = async () => {
    try {
      if (!productId || !locationId || !sku || !price) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        setLoading(true)
        let imageRes = {
          success: false,
          image: ''
        }
        
        const variationRes = await POST('/variations', {
          locationId,
          productId,
          sku:removeEspacosEmBranco(sku),
          price,
          status,
          inventory,
          minimumStock: minStock,
          gtin,
          mpn,
          ncm,
          costPrice,
          promotionalPrice,
          width,
          height,
          weight,
          depth: deep,
          referenceCode,
          context: Object.entries(gridSelected).map((variation) => ({
            cod_desc: variation[0],
            value: variation[1]
          })),
          img: imageRes.success ? imageRes.image : ''
        })
        if (image) {
          const formData = new FormData();
          formData.append('file', image);
          formData.append('nameImage', `variation-${uuid()}.jpg`);
          imageRes = await POST('/upload-images/upload-one', formData)
          if (imageRes.success) {
            await PUT(`/variations/${variationRes.dados.id}`, {
              img: imageRes.image
            })
          }
        }

        setLoading(false)
        if (variationRes.success === true) {
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Variação cadastrada',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate(`/variation`))
        } else {
          setCanAlert(true)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar a variação!',
          })
        }
      }
      return
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar a variação!',
      })
    }
  }

  const makeVariationContext = (gridName: string, valueVariation: string) => {
    let gridMaked = gridSelected;
    gridMaked[gridName] = valueVariation;
    setGridSelected(gridMaked);
  }

  const generateSKU = () => {
    const possible = "ABCDEFGHJKLMNPQRSTUVWXYZ23456789";
    let text = ''
    for (var i = 0; i < 9; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    text += productId.toString();
    setSku(text);
  }

  const handleUnload = (e:BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('variation-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    const load = async () => {
      if(loginInfo.plugintype !== 'fashion'){
        setVariationNone(true);
      }
      setLoading(true);
      await getProducts();
      await getLocations();
      await getGrids();
      setLoading(false);
    }

    const variationSaved = localStorage.getItem('variation-saved')
    if(variationSaved && JSON.parse(variationSaved)) loadLocal()
    else load()

  }, [])

  useEffect(() => {
    return () => {
      if(canAlert && !window.location.pathname.includes(`/variation/criar`)){
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/variation/criar`)
          } else {
            localStorage.removeItem('variation-saved')
          }
        })
      } else if(!canAlert) localStorage.removeItem('variation-saved')
    } 
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const variationSaved = localStorage.getItem('variation-saved')
    if(variationSaved && JSON.parse(variationSaved)){
      const jsonVariation = JSON.parse(variationSaved);
      setStatus(jsonVariation.status)
      setProducts(jsonVariation.products)
      setLocations(jsonVariation.locations)
      setProductId(jsonVariation.productId)
      setProductSelected(jsonVariation.productSelected)
      setLocationId(jsonVariation.locationId)
      setSelectedGrid(jsonVariation.selectedGrid)
      setGrids(jsonVariation.grids)
      setGridList(jsonVariation.gridList)
      setGridConfirmed(jsonVariation.gridConfirmed)
      setSku(jsonVariation.sku)
      setReferenceCode(jsonVariation.referenceCode)
      setGtin(jsonVariation.gtin)
      setMpn(jsonVariation.mpn)
      setNcm(jsonVariation.ncm)
      setCostPrice(jsonVariation.costPrice)
      setPrice(jsonVariation.price)
      setPromotionalPrice(jsonVariation.promotionalPrice)
      setWeight(jsonVariation.weight)
      setHeight(jsonVariation.height)
      setWidth(jsonVariation.width)
      setDeep(jsonVariation.deep)
      setStockSituation(jsonVariation.stockSituation)
      setOutStockSituation(jsonVariation.outStockSituation)
      setInventory(jsonVariation.inventory)
      setMinStock(jsonVariation.minStock)
      setGridSelected(jsonVariation.gridSelected)
      setVariationNone(jsonVariation.variationNone)
      if(typeof jsonVariation.image === 'string') setImage(jsonVariation.image)
      localStorage.removeItem('variation-saved')
    }
  }

  const saveLocal = () => {
    localStorage.setItem('variation-saved', JSON.stringify({
      status,
      products,
      locations,
      productId,
      productSelected,
      locationId,
      selectedGrid,
      grids,
      gridList,
      gridConfirmed,
      sku,
      referenceCode,
      gtin,
      mpn,
      ncm,
      costPrice,
      price,
      promotionalPrice,
      weight,
      height,
      width,
      deep,
      stockSituation,
      outStockSituation,
      inventory,
      minStock,
      gridSelected,
      variationNone,
      image
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    status,
    products,
    locations,
    productId,
    productSelected,
    locationId,
    selectedGrid,
    grids,
    gridList,
    gridConfirmed,
    sku,
    referenceCode,
    gtin,
    mpn,
    ncm,
    costPrice,
    price,
    promotionalPrice,
    weight,
    height,
    width,
    deep,
    stockSituation,
    outStockSituation,
    inventory,
    minStock,
    gridSelected,
    variationNone,
    image
  ])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.variation`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='card-body border-bottom'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-5 flex-grow-1 '>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      search
                      value={productSelected}
                      change={(event: SelectType) => {
                        setCanAlert(true)
                        setProductSelected(event)
                        setProductId(parseInt(event.value))
                      }}
                      label={i18n.t(`smart_order.product`)}
                      options={products.map((product) => ({
                        select: product.name,
                        value: product.id
                      }))}
                    />
                  </div>
                  <div className='ms-5 flex-grow-1 '>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      value={locationId}
                      change={setLocationId}
                      label={i18n.t(`smart_order.modality`)}
                      options={locations.map((location) => ({
                        select: location.name,
                        value: location.id
                      }))}
                    />
                  </div>
                </div>
                { !gridConfirmed &&
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-5 flex-grow-1 '>
                    <Input
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      value={variationNone}
                      checked={variationNone}
                      disabled={!(loginInfo.plugintype === 'fashion')}
                      change={(value: boolean) => {
                        setCanAlert(true)
                        setVariationNone(value);
                        setGridList([])
                        setGridSelected({
                          VARIATION: "NONE"
                        })
                      }}
                      label={i18n.t(`smart_order.no_variation`)}
                    />
                  </div>
                </div> }
                { !variationNone &&
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-5 flex-grow-1'>
                    {!gridConfirmed &&
                      <Input
                        inputAttr={{
                          type: 'select-multiple',
                        }}
                        change={setSelectedGrid}
                        label={i18n.t(`smart_order.grids`)}
                        options={grids.map((grid) => ({
                          select: grid.name,
                          value: grid.id
                        }))}
                      />}
                    <div className="d-flex align-items-center gap-3">
                      <Button
                        text={i18n.t(`buttons.add_grade`)}
                        color='primary'
                        size='small'
                        btnAttr={{
                          type: 'button',
                        }}
                        disabled={gridConfirmed}
                        click={() => addToGridList()}
                        btnClass='fw-bolder mt-2 btn-success'
                      />
                      <Button
                        text={i18n.t(`buttons.confirm_grades`)}
                        color='primary'
                        size='small'
                        btnAttr={{
                          type: 'button',
                        }}
                        disabled={gridConfirmed}
                        click={() => confirmGrid()}
                        btnClass='fw-bolder mt-2 btn-success'
                      />
                    </div>
                  </div>
                  <div className='ms-5 flex-grow-1'>
                    {gridList.length > 0 &&
                      <table className='table'>
                        <tbody>
                          {gridList.map((grid) => (
                            <tr key={grid.id}>
                              <td>
                                <Button
                                  text='-'
                                  color='primary'
                                  size='small'
                                  btnAttr={{
                                    type: 'button',
                                  }}
                                  disabled={gridConfirmed}
                                  click={() => removeToGridList(grid.id)}
                                  btnClass='fw-bolder btn-danger me-4 '
                                />
                                {grid.name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    }
                  </div>
                </div>
                }
                {(gridConfirmed || variationNone) && <div className='d-flex justify-content-between'>
                  <div className="w-100">
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='me-5 w-100'>

                        <div className='form-group mt-5'>
                          <label className='form-label fw-normal'>{i18n.t(`smart_order.variation_product`)}</label>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control fw-normal'
                              placeholder={i18n.t(`smart_order.variation`)}
                              value={sku}
                              onChange={(event) => {
                                setCanAlert(true)
                                setSku(event.target.value)
                              }}
                            />
                            <div className='input-group-append'>
                              <button className='btn btn-light' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, zIndex: 0 }} type='button' onClick={() => generateSKU()}>
                                {i18n.t(`buttons.generate_sku`)}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='me-5 w-50'>
                        <Input
                          inputAttr={{
                            type: 'text',
                          }}
                          placeholder={i18n.t(`smart_order.code`)}
                          label='Reference Code'
                          change={(value: string) => {
                            setCanAlert(true)
                            setReferenceCode(value)
                          }}
                          value={referenceCode}
                        />
                      </div>
                      <div className='me-5 w-50'>
                        <Input
                          inputAttr={{
                            type: 'text',
                          }}
                          placeholder='GTIN'
                          label='GTIN'
                          change={(value: string) => {
                            setCanAlert(true)
                            setGtin(value)
                          }}
                          value={gtin}
                        />
                      </div>
                      <div className='me-5 w-50'>
                        <Input
                          inputAttr={{
                            type: 'text',
                          }}
                          placeholder='MPN'
                          label='MPN'
                          change={(value: string) => {
                            setCanAlert(true)
                            setMpn(value)
                          }}
                          value={mpn}
                        />
                      </div>
                      <div className='me-5 w-50'>
                        <Input
                          inputAttr={{
                            type: 'text',
                          }}
                          placeholder='NCM'
                          label='NCM'
                          change={(value: string) => {
                            setCanAlert(true)
                            setNcm(value)
                          }}
                          value={ncm}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'money',
                          }}
                          currency='BRL'
                          locale='pt-BR'
                          label={i18n.t(`smart_order.cost_price`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setCostPrice(value)
                          }}
                          value={costPrice}
                        />
                      </div>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'money',
                          }}
                          currency='BRL'
                          locale='pt-BR'
                          label={i18n.t(`smart_order.sell_price`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setPrice(value)
                          }}
                          value={price}
                        />
                      </div>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'money',
                          }}
                          currency='BRL'
                          locale='pt-BR'
                          label={i18n.t(`smart_order.promotional_price`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setPromotionalPrice(value)
                          }}
                          value={promotionalPrice}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder=''
                          label={i18n.t(`smart_order.weight`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setWeight(value)
                          }}
                          value={weight}
                        />
                      </div>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder=''
                          label={i18n.t(`smart_order.height`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setHeight(value)
                          }}
                          value={height}
                        />
                      </div>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder=''
                          label={i18n.t(`smart_order.width`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setWidth(value)
                          }}
                          value={width}
                        />
                      </div>
                      <div className='me-5 w-100'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder=''
                          label={i18n.t(`smart_order.depth`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setDeep(value)
                          }}
                          value={deep}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <div className='me-5 flex-grow-1 '>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          value={status}
                          change={setStatus}
                          label='Status'
                          options={[
                            { select: i18n.t(`general.active`), value: true },
                            { select: i18n.t(`general.inactive`), value: false },
                          ]}
                        />
                      </div>
                      <div className='me-5 flex-grow-1 '>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          value={stockSituation}
                          change={setStockSituation}
                          label={i18n.t(`smart_order.availability`)}
                          options={[
                            { select: 'Disponibilidade imediata', value: 0 },
                            { select: 'Disponibilidade para 1 dia útil', value: 1 },
                            { select: 'Disponibilidade para 2 dias úteis', value: 2 },
                            { select: 'Disponibilidade para 3 dias úteis', value: 3 },
                            { select: 'Disponibilidade para 4 dias úteis', value: 4 },
                            { select: 'Disponibilidade para 5 dias úteis', value: 5 },
                            { select: 'Disponibilidade para 6 dias úteis', value: 6 },
                            { select: 'Disponibilidade para 7 dias úteis', value: 7 },
                            { select: 'Disponibilidade para 8 dias úteis', value: 8 },
                            { select: 'Disponibilidade para 9 dias úteis', value: 9 },
                            { select: 'Disponibilidade para 10 dias úteis', value: 10 },
                            { select: 'Disponibilidade para 15 dias úteis', value: 15 },
                            { select: 'Disponibilidade para 20 dias úteis', value: 20 },
                            { select: 'Disponibilidade para 25 dias úteis', value: 25 },
                            { select: 'Disponibilidade para 30 dias úteis', value: 30 },
                            { select: 'Disponibilidade para 45 dias úteis', value: 45 },
                            { select: 'Disponibilidade para 60 dias úteis', value: 60 },
                            { select: 'Disponibilidade para 90 dias úteis', value: 90 },
                          ]}
                        />
                      </div>
                      <div className='me-5 flex-grow-1 '>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          value={outStockSituation}
                          change={setOutStockSituation}
                          label={i18n.t(`smart_order.when_product_stock`)}
                          options={[
                            { select: 'Continuar vendendo normalmente', value: 0 },
                            { select: 'Tornar o produto indisponível', value: -1 },
                            { select: 'Mudar a disponibilidade para 1 dia útil', value: 1 },
                            { select: 'Mudar a disponibilidade para 2 dias úteis', value: 2 },
                            { select: 'Mudar a disponibilidade para 3 dias úteis', value: 3 },
                            { select: 'Mudar a disponibilidade para 4 dias úteis', value: 4 },
                            { select: 'Mudar a disponibilidade para 5 dias úteis', value: 5 },
                            { select: 'Mudar a disponibilidade para 6 dias úteis', value: 6 },
                            { select: 'Mudar a disponibilidade para 7 dias úteis', value: 7 },
                            { select: 'Mudar a disponibilidade para 8 dias úteis', value: 8 },
                            { select: 'Mudar a disponibilidade para 9 dias úteis', value: 9 },
                            { select: 'Mudar a disponibilidade para 10 dias úteis', value: 10 },
                            { select: 'Mudar a disponibilidade para 15 dias úteis', value: 15 },
                            { select: 'Mudar a disponibilidade para 20 dias úteis', value: 20 },
                            { select: 'Mudar a disponibilidade para 25 dias úteis', value: 25 },
                            { select: 'Mudar a disponibilidade para 30 dias úteis', value: 30 },
                            { select: 'Mudar a disponibilidade para 45 dias úteis', value: 45 },
                            { select: 'Mudar a disponibilidade para 60 dias úteis', value: 60 },
                            { select: 'Mudar a disponibilidade para 90 dias úteis', value: 90 },
                          ]}
                        />
                      </div>

                    </div>
                    <div className='d-flex align-items-center'>
                      <div className="me-5">
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder='Informe a quantidade'
                          label={i18n.t(`smart_order.stock`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setInventory(value)
                          }}
                          value={inventory}
                        />
                      </div>
                      <div className="me-5">
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder='Informe a quantidade'
                          label={i18n.t(`smart_order.min_stock`)}
                          change={(value: number) => {
                            setCanAlert(true)
                            setMinStock(value)
                          }}
                          value={minStock}
                        />
                      </div>
                    </div>
                    <div className='d-flex align-items-center'>
                      {gridList.map((grid) => (
                        <div className="me-5" key={grid.id}>
                          <Input
                            inputAttr={{
                              type: 'select',
                            }}
                            change={(valueVariation: string) => {
                              setCanAlert(true)
                              makeVariationContext(grid.name, valueVariation)
                            }}
                            label={grid.name}
                            options={Object.entries(JSON.parse(grid.variation)).map((variation: any) => ({
                              select: variation[1],
                              value: variation[1]
                            }))}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      label={i18n.t(`smart_order.image`)}
                      value={image}
                      change={(value: BaseSyntheticEvent) => {
                        setCanAlert(true)
                        setImage(value ? value.target.files[0] : null)
                      }}
                    />
                  </div>
                </div>}
              </div>
              <div className="d-flex w-100">
                <div className="col-6 d-flex justify-content-start">
                  <Button
                    text={i18n.t(`buttons.back`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate(-1)}
                    btnClass='fw-bolder m-9'
                  />
                </div>
                <div className="col-6  d-flex justify-content-end">
                  <Button
                    text={i18n.t(`buttons.create`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      setCanAlert(false)
                      createVariation()
                    }}
                    btnClass='fw-bolder m-9'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CriarVariacoes: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.variations`), path: 'variation', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'variation/criar', isActive: true },
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.variations`)}`}
      </PageTitle>
      <CriarVariacoesContent />
    </>
  )
}

export { CriarVariacoes }
