/* eslint-disable jsx-a11y/anchor-is-valid */
import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type {MetaType} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {useNavigate} from 'react-router-dom'
import useModals from '../../../../hooks/useModals'
import {Loading} from '../../../../components/Loading/Loading'
import {DELETE, GET, POST} from '../../../../services/api'
import Swal from 'sweetalert2'
import {ModalDelete} from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../../components/Input/Input'
import {ModalWarning} from '../../../../components/Modals/General/ModalWarning/ModalWarning'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type DeliveryType = {
  id: number
  minKm: number
  maxKm: number
  value: number
}

const TaxaEntregaContent: FC = () => {
  const {showWrapperModal, showDeleteModal, showWarningModal} = useModals()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [deliveries, setDeliveries] = useState<Array<DeliveryType>>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const [fileToImport, setFileToImport] = useState<File | null>(null)

  const getDeliveries = async () => {
    try {
      setLoading(true)
      const deliveryRes = await GET(`/delivery`)
      const newDeliveries = deliveryRes.data.map((delivery: DeliveryType) => {
        return {
          id: delivery.id,
          minKm: delivery.minKm,
          maxKm: delivery.maxKm,
          value: delivery.value,
        }
      })
      setDeliveries(newDeliveries)
      setMeta(deliveryRes.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteDelivery = async () => {
    try {
      setLoading(true)
      const deliveryRes = await DELETE(`/delivery/${id}`)
      if (deliveryRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Taxa de entrega deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getDeliveries())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar taxa de entrega!',
        }).then(async () => await getDeliveries())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar taxa de entrega!',
      }).then(async () => await getDeliveries())
      console.log(e)
    }
  }

  useEffect(() => {
    getDeliveries()
  }, [])

  const importFile = async () => {
    try {
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToImport)

        const importRes = await POST('/delivery/import', formData)
        setLoading(false)
        if (importRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Importação realizada com sucesso!',
          }).then(async () => await getDeliveries())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao importar arquivo!',
          }).then(async () => await getDeliveries())
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getDeliveries())
    }
    setFileToImport(null)
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`delivery_fee.delivery_fee`)}</span>
                <BreadCrumbs />
              </h3>
              <div className='card-toolbar d-flex justify-content-end w-100 gap-5 '>
                <Button
                  text={i18n.t(`buttons.add_km`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/configuracoes/taxa-entrega/raio/criar')}
                  btnClass='fw-bolder'
                />
                {/* <Button
                  text={i18n.t(`buttons.zip_code_list`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-list fs-4'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/configuracoes/taxa-entrega/cep')}
                  btnClass='fw-bolder'
                />
                <Button
                  text={i18n.t(`buttons.area`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-map fs-4'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/configuracoes/taxa-entrega/map')}
                  btnClass='fw-bolder'
                /> */}
                <Button
                  text=''
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-three-dots fs-4 p-0'
                  size='small'
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => showWrapperModal(i18n.t(`delivery_fee.patterns`))}
                  btnClass='fw-bolder'
                />
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-arrow-left fs-4'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/configuracoes/taxa-entrega')}
                  btnClass='fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={deliveries}
                headers={[
                  {key: i18n.t(`delivery_fee.km_min`), value: 'minKm', type: 'default'},
                  {key: i18n.t(`delivery_fee.km_max`), value: 'maxKm', type: 'default'},
                  {key: i18n.t(`delivery_fee.value`), value: 'value', type: 'money'},
                  {key: i18n.t(`delivery_fee.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      navigate(`/configuracoes/taxa-entrega/raio/editar/${item.id}`)
                    },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`delivery_fee.delivery_fee`)}`,
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`delivery_fee.delivery_fee`)}`)
                      setId(item.id)
                    },
                    title: `${i18n.t(`general.delete`)} ${i18n.t(`delivery_fee.delivery_fee`)}`,
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
          <ModalWarning
            confirmAction={() => importFile()}
            warningMessage={i18n.t(`delivery_fee.continue_import`)}
          />
          <ModalWrapper confirmAction={() => showWarningModal(`${i18n.t(`general.attention`)}!`)}>
            <div className='flex-grow-1'>
              <p>{i18n.t(`general.attention`)}</p>
              <p>
                {i18n.t(`delivery_fee.import_file`)}
              </p>

              <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '50em'
                  }}
                >
                  {i18n.t(`delivery_fee.import_delivery`)}
                </div>
                <div style={{marginLeft: '1em'}}>
                  <Button
                    text={i18n.t(`delivery_fee.model`)}
                    color='primary'
                    iconColor='#FFF'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      window.open('/file/modelo-km07-10-2022.xlsx', '')
                    }}
                    btnClass='fw-bolder me-5'
                  />
                </div>
              </div>
              <br />
            </div>
            <br />
            <div className='flex-grow-1 text-center'>
              <Input
                inputAttr={{
                  type: 'file',
                }}
                acceptFile={'.xlsx,.xls'}
                change={(value: BaseSyntheticEvent) =>
                  setFileToImport(value ? value.target.files[0] : null)
                }
              />
            </div>
          </ModalWrapper>
          <ModalDelete confirmAction={() => deleteDelivery()} />
        </div>
      )}
    </>
  )
}

const TaxaEntrega: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`delivery_fee.delivery_fee`), path: 'configuracoes/taxa-entrega/raio', isActive: true},
        ]}
      >
        {i18n.t(`delivery_fee.delivery_fee`)}
      </PageTitle>
      <TaxaEntregaContent />
    </>
  )
}

export {TaxaEntrega}
