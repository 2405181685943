/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Datatable} from '../../../components/Datatable/Datatable'
import {Button} from '../../../components/Button/Button'
import {Input} from '../../../components/Input/Input'
import {useNavigate} from 'react-router-dom'
import {Loading} from '../../../components/Loading/Loading'
import {DELETE, GET, POST, PUT} from '../../../services/api'
import {formatPhone} from '../../../utils/formater'
import moment from 'moment'
import { i18n } from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type CustomerResponseType = {
  id: number
  name: string
  email: string
  order: string
  phone: string
}

const ListaClientesContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState('')
  const [finalDate, setFinalDate] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [customers, setCustomers] = useState<any[]>([])

  const getClients = async (loading = true) => {
    try {
      setLoading(loading)
      const res = await POST('/customer/filter', {
        initialDate: `${moment().format('YYYY-MM-DD')} 00:00:00`,
        finalDate: `${moment().format('YYYY-MM-DD')} 23:59:59`,
        internal: true
      })
      const customers = res.data.map((row: any) => {
        return {
          id: row.id,
          name: row.name,
          email: row.email,
          phone: (row.customerPhones && row.customerPhones[0]) ? formatPhone(row.customerPhones[0].numberPhone) : '',
          created: moment(row.created).format('DD/MM/YYYY hh:mm'),
        }
      })
      setCustomers(customers)
      setStartDate(moment().format('YYYY-MM-DD'))
      setFinalDate(moment().format('YYYY-MM-DD'))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const loadClients = async () => {
    const data = {}

    if (startDate && finalDate) {
      Object.assign(data, {initialDate: startDate})
      Object.assign(data, {finalDate: finalDate + ' 23:59:59'})
    }

    if (name) Object.assign(data, {name: name})
    if (email) Object.assign(data, {email: email})

    Object.assign(data, {internal: true})

    try {
      setLoading(true)
      const filter = await POST('/customer/filter', data)
      const customers = filter.data.map((row: any) => {
        return {
          id: row.id,
          name: row.name,
          email: row.email,
          phone: (row.customerPhones && row.customerPhones[0]) ? formatPhone(row.customerPhones[0].numberPhone) : '',
          created: moment(row.created).format('DD/MM/YYYY hh:mm'),
        }
      })
      setCustomers(customers)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-12 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`customer_list.customer_list`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`customer_list.listed_customers`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='w-100 mb-10'>
                <h4 className='text-gray-800'>{i18n.t(`customer_list.select_time`)}</h4>
                <div className='d-flex align-items-end mt-5 row'>
                  <div className='col-md-2'>
                    <Input
                      inputAttr={{type: 'date'}}
                      label={i18n.t(`customer_list.first`)}
                      value={startDate}
                      change={setStartDate}
                      maxLength={finalDate}
                    />
                  </div>
                  <div className='col-md-2'>
                    <Input
                      inputAttr={{type: 'date'}}
                      label={i18n.t(`customer_list.end`)}
                      value={finalDate}
                      change={setFinalDate}
                      minLength={startDate}
                    />
                  </div>
                  <div className='col-md-3'>
                    <Input
                      inputAttr={{type: 'default'}}
                      label={i18n.t(`customer_list.name`)}
                      value={name}
                      change={setName}
                      placeholder={i18n.t(`customer_list.name`)}
                    />
                  </div>
                  <div className='col-md-3'>
                    <Input
                      inputAttr={{type: 'email'}}
                      label='Email'
                      value={email}
                      change={setEmail}
                      placeholder='Email'
                    />
                  </div>
                  <div className='col-md-2'>
                    <Button
                      color='primary'
                      text={i18n.t(`buttons.search`)}
                      click={() => loadClients()}
                      btnClass='ms-10 float-right'
                    />
                  </div>
                </div>
              </div>
              <Datatable
                data={customers}
                headers={[
                  {key: i18n.t(`customer_list.name`), value: 'name', type: 'default'},
                  {key: 'Email', value: 'email', type: 'default'},
                  {key: i18n.t(`customer_list.phone`), value: 'phone', type: 'default'},
                  {key: i18n.t(`customer_list.date`), value: 'created', type: 'default'},
                  {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: '',
                    isButton: true,
                    textButton: i18n.t(`buttons.view`),
                    action: (item) => {
                    //navigate(`/customer/report/${btoa(item.id)}`)
                    window.open(`/customer/report/${btoa(item.id)}`)
                    },
                    color: 'primary',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const ListaClientes: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`customer_list.customer_list`), path: 'customer/report', isActive: true},
        ]}
      >
        {i18n.t(`customer_list.customer_list`)}
      </PageTitle>
      <ListaClientesContent />
    </>
  )
}

export {ListaClientes}
