import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import Swal from 'sweetalert2'
import { CardPayment } from '../../../components/CardPayment'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { Input } from '../../../components/Input/Input'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type PluginconfigType = {
  id: number;
  name: string;
  content: any;
  plugintemplateId: number;
  plugintypeId: number;
}

type PaymentType = {
  id: number;
  name: string;
  parameters: string;
  pluginconfig: PluginconfigType;
  description: string | null;
  website: string | null;
  image: string | null;
  ableCredit: boolean | null;
  ableDebit: boolean | null;
  ableAlimentation: boolean | null;
  ablePix: boolean | null;
  ableMoney: boolean | null;
  plans: PlansType[];
}

type AppConfigType = {
  id: number;
  name: string;
  type: string;
  content: {
    pdv: any
  }
}

type PluginsChangeType = {
  now: PaymentType;
  after: PaymentType;
  type: string;
}

type PlansType = {
  id: number;
  gateway: string;
  plugintemplateId: number;
  type: string;
}

type IntegrationResponseType = {
  id: number;
  brand: string;
  cardOperator: number;
  codePay: string;
  nameGateway: string;
  typePay: string;
  pluginconfigId: number;
  plugintemplateId: number;
}

const PaymentsContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal, closeWrapperModal } = useModals()
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<PaymentType[]>([])
  const [appConfig, setAppConfig] = useState<AppConfigType | null>(null);
  const [pluginsToChange, setPluginsToChange] = useState<PluginsChangeType | null>(null);
  const [openModalChange, setOpenModalChange] = useState(false);
  const [openModalMotive, setOpenModalMotive] = useState(false);
  const [isChanging, setIsChanging] = useState(false);
  const [motive, setMotive] = useState('')

  const getPlugintemplates = async () => {
    try {
      const res = await GET('/plugintemplate/payments')
      const appRes = await GET(`/appconfig`)
      const resPluginspace = await GET(`/pluginspace/this`);
      let plans:PlansType[] = [];
      if (resPluginspace.data && resPluginspace.data[0] && resPluginspace.data[0].hash) {
        const resIntegration = await POST(`orders/card-payment-get`, { hash: resPluginspace.data[0].hash });
        if (resIntegration.data && resIntegration.data.data && resIntegration.data.data.length > 0) {
          plans = resIntegration.data.data.map((integration: IntegrationResponseType) => ({
            id: integration.id,
            gateway: integration.nameGateway,
            plugintemplateId: integration.plugintemplateId,
            type: integration.typePay.toLocaleLowerCase(),
          }))
        }
      }
      if (res.success && res.data && res.data.length > 0 && appRes.data && appRes.data.length > 0) {
        const appConfigRes: AppConfigType = appRes.data.find((config: AppConfigType) => config.type === 'qr')
        if (appConfigRes) {
          const adaptedPayments = res.data.map((payment: PaymentType) => {
            //Tratar pagamento na entrega a parte (44)
            if (payment.id === 44) {
              payment.ableCredit = false;
              payment.ableDebit = false;
              payment.ablePix = false;
              payment.ableAlimentation = false;
              payment.ableMoney = false;
              if (payment.pluginconfig && payment.pluginconfig.id === appConfigRes.content.pdv.gateway_delivery_config) {
                if (payment.pluginconfig.content.able === '1') payment.ableCredit = true
                if (payment.pluginconfig.content.ableDebit === '1') payment.ableDebit = true
                if (payment.pluginconfig.content.ablePix === '1') payment.ablePix = true
                if (payment.pluginconfig.content.ableAlimentation === '1') payment.ableAlimentation = true
                if (payment.pluginconfig.content.ableMoney === '1') payment.ableMoney = true
              }
            } else {
              payment.ableAlimentation = null
              payment.ableDebit = null

              //Verifica se tem opção de cartão de crétido e se ta ativo
              if (payment.parameters.includes('able')) {
                if (
                  payment.pluginconfig &&
                  appConfigRes.content.pdv.gateway_config === payment.pluginconfig.id
                ) payment.ableCredit = true
                else payment.ableCredit = false
              } else payment.ableCredit = null

              //Verifica se tem opção de pix
              if (payment.parameters.includes('pix')) {
                if (
                  payment.pluginconfig &&
                  appConfigRes.content.pdv.gateway_pix_config === payment.pluginconfig.id
                ) payment.ablePix = true
                else payment.ablePix = false
              } else payment.ablePix = null
            }
            payment.plans = plans.filter((plan) => plan.plugintemplateId === payment.id)
            return payment
          })
          
          setAppConfig(appConfigRes)
          setPayments(adaptedPayments)
          return
        }
      }
      setPayments([])
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar meios de pagamento.',
      })
      return
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar meios de pagamento.',
      })
    }
  }

  const init = async () => {
    setLoading(true)
    await getPlugintemplates();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])
  
  const verifyPlans = (paymentId: number, itemToActive: string) => {
    const paymentToVerify = payments.find((payment) => payment.id === paymentId)
    if(paymentToVerify && paymentToVerify.plans.length === 0)
      return true

    let qtdPlans = 0;
    if(paymentToVerify){
      
      paymentToVerify.plans.forEach((plan) => {
        if(itemToActive === 'money' && plan.type === 'money') qtdPlans ++;
        else if(itemToActive === 'pix' && plan.type === 'pix') qtdPlans ++;
        else if((itemToActive === 'card' || itemToActive === 'debit' || itemToActive === 'food') && (plan.type === 'debit' || plan.type === 'credit')) qtdPlans++
      })
    }
    return qtdPlans === 0;
  }

  const activeItem = async (paymentId: number, pluginconfigId: number | null, item: string, confirmFirst: boolean, doChange?: boolean) => {
    try {
      const pluginsWillChange = { ...pluginsToChange }
      const motiveToSend = motive.toString();
      
      if(!pluginconfigId){
        Swal.fire({
            icon: 'warning',
            title: 'Impossível ativar meio de pagamento!',
            text: 'Este meio de pagamento não está configurado, portanto não pode ser ativado.',
        })
        return
      }

      

      let failed = false;

      let finded: null | undefined | PaymentType = null
      let goInative = false;
      // Fazer alteração no front e verificar se já tem outro ativo
      const newPayments = payments.map((payment) => {
        if (payment.id === paymentId) {
          switch (item) {
            case 'card':
              if (paymentId !== 44) finded = payments.find((payment) => (payment.ableCredit && payment.id !== paymentId && payment.id !== 44))
              if (!finded) {
                //Verificar se vai ficar inativo
                if (payment.ableCredit && confirmFirst) goInative = true;
                else payment.ableCredit = !payment.ableCredit
              } else if (doChange && pluginsWillChange) {
                //Verificar se vai ficar inativo
                if (pluginsWillChange.now && pluginsWillChange.now.ableCredit && confirmFirst) {
                  goInative = true;
                } else {
                  finded.ableCredit = !finded.ableCredit
                  if (finded.pluginconfig.content.able) finded.pluginconfig.content.able = finded.ableCredit ? '1' : '0'
                  if (finded.pluginconfig.content.ableCard) finded.pluginconfig.content.ableCard = finded.ableCredit ? '1' : '0'
                  payment.ableCredit = !payment.ableCredit
                }
              }
              break;
            case 'debit':
              //Verificar se vai ficar inativo
              if (payment.ableDebit && confirmFirst) goInative = true;
              else payment.ableDebit = !payment.ableDebit
              break;
            case 'food':
              if (payment.ableAlimentation && confirmFirst) goInative = true;
              else payment.ableAlimentation = !payment.ableAlimentation
              break;
            case 'money':
              if (payment.ableMoney && confirmFirst) goInative = true;
              else payment.ableMoney = !payment.ableMoney
              break;
            case 'pix':
              if (paymentId !== 44) finded = payments.find((payment) => (payment.ablePix && payment.id !== paymentId && payment.id !== 44))
              if (!finded) {
                //Verificar se vai ficar inativo
                if (payment.ablePix && confirmFirst) goInative = true;
                else payment.ablePix = !payment.ablePix
              } else if (doChange && pluginsWillChange) {
                //Verificar se vai ficar inativo
                if (pluginsWillChange.now && pluginsWillChange.now.ablePix && confirmFirst) {
                  goInative = true;
                } else {
                  finded.ablePix = !finded.ablePix
                  if (finded.pluginconfig.content.ablePix) finded.pluginconfig.content.ablePix = finded.ablePix ? '1' : '0'
                  if (finded.pluginconfig.content.pix) finded.pluginconfig.content.pix = finded.ablePix ? '1' : '0'
                  payment.ablePix = !payment.ablePix
                }
              }
              break;
          }

            if(confirmFirst) {
              setPluginsToChange({now: finded ? finded : payment, after: payment, type: item})
            }

          if (finded && !doChange) {
            failed = true;
            showWrapperModal('Atenção!')
            setOpenModalChange(true);
            setPluginsToChange({ now: finded, after: payment, type: item })
          }
        }
        return payment
      })

      if(failed) return;
      if(goInative){
        setIsChanging(openModalChange);
        const willChange = openModalChange;
        setOpenModalMotive(true)
        setOpenModalChange(false)
        closeWrapperModal();
        setTimeout(() => {
          showWrapperModal(willChange ? 'Confirmar troca' : 'Confirmar inativação')
        }, 100)
        return
      }
      setPluginsToChange(null)
      setIsChanging(false)
      setOpenModalChange(false)
      setOpenModalMotive(false)
      setMotive('')

      const changeFinded: any = finded
      let newAppConfig = appConfig ? { ...appConfig } : null

      setPayments(newPayments)
      setAppConfig(newAppConfig)
      setOpenModalChange(false)
      setOpenModalMotive(false)
      let actived = false;
      for (let i = 0; i < newPayments.length; i++) {
        const payment = newPayments[i]

        if (payment.id === paymentId) {
          //Alterar gateway_delivery_config se qualquer opção do pagamento na entrega estiver ativo
          if (payment.id === 44 && newAppConfig) {
            if (payment.ableCredit || payment.ableDebit || payment.ablePix || payment.ableAlimentation || payment.ableMoney)
              newAppConfig.content.pdv.gateway_delivery_config = payment.pluginconfig.id
            else newAppConfig.content.pdv.gateway_delivery_config = null
          }

          //Altera campos necessários para atualizar o meio de pagamento
          switch (item) {
            case 'card':
              actived = payment.ableCredit ? true : false;
              if (payment.pluginconfig.content.able)
                payment.pluginconfig.content.able = payment.ableCredit ? '1' : '0'
              if (payment.pluginconfig.content.ableCard)
                payment.pluginconfig.content.ableCard = payment.ableCredit ? '1' : '0'

              if (payment.id !== 44 && newAppConfig)
                newAppConfig.content.pdv.gateway_config = payment.ableCredit ? payment.pluginconfig.id : null
              break;
            case 'debit':
              actived = payment.ableDebit ? true : false;
              payment.pluginconfig.content.ableDebit = payment.ableDebit ? '1' : '0'
              break;
            case 'food':
              actived = payment.ableAlimentation ? true : false;
              payment.pluginconfig.content.ableAlimentation = payment.ableAlimentation ? '1' : '0'
              break;
            case 'money':
              actived = payment.ableMoney ? true : false;
              payment.pluginconfig.content.ableMoney = payment.ableMoney ? '1' : '0'
              break;
            case 'pix':
              actived = payment.ablePix ? true : false;
              if (payment.pluginconfig.content.ablePix || payment.pluginconfig.content.ablePix === '')
                payment.pluginconfig.content.ablePix = payment.ablePix ? '1' : '0'
              if (payment.pluginconfig.content.pix)
                payment.pluginconfig.content.pix = payment.ablePix ? '1' : '0'

              if (payment.id !== 44 && newAppConfig)
                newAppConfig.content.pdv.gateway_pix_config = payment.ablePix ? payment.pluginconfig.id : null
              break;
          }

          if (!confirmFirst) {
            await POST(`/motive-rejected`, {
              motive: motiveToSend,
              typeMotive: 'inactivatingPayment'
            })
          }

          if (newAppConfig) {
            await PUT(`/appconfig/${newAppConfig.id}`, {
              content: newAppConfig.content,
            })
            setAppConfig(newAppConfig)
          }

          await PUT(`/pluginconfig/${payment.pluginconfig.id}`, {
            plugintypeId: payment.pluginconfig.plugintypeId,
            plugintemplateId: payment.pluginconfig.plugintemplateId,
            name: payment.pluginconfig.name,
            content: payment.pluginconfig.content,
          })

          if (changeFinded && doChange) {

            await PUT(`/pluginconfig/${changeFinded.pluginconfig.id}`, {
              plugintypeId: changeFinded.pluginconfig.plugintypeId,
              plugintemplateId: changeFinded.pluginconfig.plugintemplateId,
              name: changeFinded.pluginconfig.name,
              content: changeFinded.pluginconfig.content,
            })
          }
          if(verifyPlans(paymentId, item) && actived){
            Swal.fire({
              icon: 'warning',
              title: 'Atenção!',
              text: 'Esse meio de pagamento não possui planos de pagamento. Deseja configurar?',
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: 'Configurar',
              cancelButtonText: 'Cancelar'
            }).then((resp) => {
              if(resp.isConfirmed) navigate(`/configuracoes/pagamentos/${paymentId}`)
            })
            return
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`payment.methods`)}</span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='card-body d-flex flex-row align-items-center gap-8 flex-wrap'>
              {payments.map((payment) => (
                <CardPayment
                  key={payment.id}
                  id={payment.id}
                  name={payment.name}
                  pluginconfigId={payment.pluginconfig ? payment.pluginconfig.id : null}
                  description={payment.description}
                  website={payment.website}
                  image={payment.image}
                  ableCredit={payment.ableCredit}
                  ableDebit={payment.ableDebit}
                  ableAlimentation={payment.ableAlimentation}
                  ablePix={payment.ablePix}
                  ableMoney={payment.ableMoney}
                  activeItem={activeItem}
                />
              ))}
            </div>
          </div>
            <ModalWrapper 
              noCloseButton={openModalChange} 
              textAction={(openModalChange) ? `Confirmar e ativar ${pluginsToChange?.after.name}` : i18n.t(`general.delete`)} 
              confirmAction={() =>  pluginsToChange ? 
                activeItem(pluginsToChange.after.id, pluginsToChange.after.pluginconfig.id, pluginsToChange.type, openModalChange, true) : 
                {}} 
              maxWidth={true} 
              width={50}
            >
              {openModalChange ? (
                <div className="d-flex flex-column mt-5">
                  {pluginsToChange && (
                    <p>
                      Você solicitou a ativação do gateway de pagamento <b>{pluginsToChange.after.name}</b>. <br />
                      Somente 1 gateway pode ser ativado no webapp. Atualmente o gateway ativado é o <b>{pluginsToChange.now.name}</b>. <br />
                      <br />
                      Deseja prosseguir com a alteração? O gateway anterior será desativado (as configurações continuam salvas, mas ele não será utilizado no webapp).
                    </p>
                  )}
                </div>
              ) : (
                <div className="d-flex flex-column mt-5">
                  <div className='d-flex flex-row w-100 justify-content-center'>
                    <svg width="204" height="197" viewBox="0 0 204 197" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_3074_4190)">
                      <path d="M159.301 0.285833L9.67877 32.3073C3.18191 33.6978 -0.957669 40.0917 0.432766 46.5886L30.4222 186.715C31.8126 193.212 38.2065 197.352 44.7034 195.961L194.325 163.94C200.822 162.549 204.962 156.156 203.571 149.659L173.582 9.53184C172.191 3.03498 165.797 -1.1046 159.301 0.285833Z" fill="#E8EDFC"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M165.34 146.75C167 147.19 168.48 149.15 168.65 151.13C168.82 153.11 167.62 154.36 165.96 153.92C164.3 153.48 162.82 151.52 162.65 149.54C162.48 147.56 163.68 146.31 165.34 146.75Z" fill="#BDD0FB"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M138.12 144.13C140.97 144.88 143.52 148.26 143.82 151.67C144.12 155.08 142.04 157.23 139.19 156.48C136.34 155.73 133.79 152.35 133.49 148.94C133.19 145.53 135.27 143.38 138.12 144.13Z" fill="#BDD0FB"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M166.67 173.59C167.77 173.88 168.75 175.18 168.86 176.49C168.97 177.8 168.18 178.62 167.08 178.34C165.99 178.05 165.01 176.75 164.89 175.44C164.78 174.13 165.57 173.3 166.67 173.59Z" fill="#BDD0FB"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M196.92 139.82C198.02 140.11 199 141.41 199.11 142.72C199.22 144.03 198.43 144.86 197.33 144.57C196.24 144.28 195.26 142.98 195.14 141.68C195.03 140.37 195.82 139.54 196.92 139.83V139.82Z" fill="#BDD0FB"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M155.85 119.09C156.95 119.38 157.93 120.68 158.04 121.99C158.15 123.3 157.36 124.13 156.26 123.84C155.16 123.55 154.18 122.25 154.07 120.94C153.96 119.63 154.75 118.8 155.85 119.09Z" fill="#BDD0FB"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M118.11 146.98C119.21 147.27 120.18 148.57 120.3 149.88C120.42 151.19 119.62 152.02 118.52 151.73C117.42 151.44 116.44 150.14 116.33 148.83C116.22 147.52 117.01 146.69 118.11 146.98Z" fill="#BDD0FB"/>
                      <path d="M91.51 42.4899H69.14C67.6 42.4899 66.36 41.2399 66.36 39.7099C66.36 38.1699 67.61 36.9299 69.14 36.9299H91.51C93.05 36.9299 94.29 38.1799 94.29 39.7099C94.29 41.2499 93.04 42.4899 91.51 42.4899Z" fill="#BDD0FB"/>
                      <path d="M75.03 31.58H3.12998C1.58998 31.58 0.349976 30.33 0.349976 28.8C0.349976 27.26 1.59998 26.02 3.12998 26.02H75.04C76.58 26.02 77.82 27.27 77.82 28.8C77.82 30.34 76.57 31.58 75.04 31.58H75.03Z" fill="#BDD0FB"/>
                      <path d="M59.06 42.4799H11.77C10.23 42.4799 8.98999 41.2299 8.98999 39.6999C8.98999 38.1599 10.24 36.9199 11.77 36.9199H59.06C60.6 36.9199 61.84 38.1699 61.84 39.6999C61.84 41.2399 60.59 42.4799 59.06 42.4799Z" fill="#BDD0FB"/>
                      <path d="M63.23 64.27H11.77C10.23 64.27 8.98999 63.02 8.98999 61.49C8.98999 59.95 10.24 58.71 11.77 58.71H63.23C64.77 58.71 66.01 59.96 66.01 61.49C66.01 63.03 64.76 64.27 63.23 64.27Z" fill="#BDD0FB"/>
                      <path d="M91.7399 53.3701H19.8299C18.2899 53.3701 17.0499 52.1201 17.0499 50.5901C17.0499 49.0501 18.2999 47.8101 19.8299 47.8101H91.7399C93.2799 47.8101 94.5199 49.0601 94.5199 50.5901C94.5199 52.1301 93.2699 53.3701 91.7399 53.3701Z" fill="#BDD0FB"/>
                      <path d="M70.71 138.03H70.63C63.51 137.79 57.92 132.02 57.92 124.89C57.92 122.77 58.44 120.65 59.44 118.77C59.44 118.77 59.5 118.65 59.52 118.63L90.97 64.1499C90.97 64.1499 91.02 64.0699 91.05 64.0199L91.16 63.8699C93.62 60.3099 97.67 58.1899 101.99 58.1899C106.31 58.1899 110.36 60.3099 112.82 63.8699L112.93 64.0199C112.93 64.0199 112.98 64.0999 113.01 64.1399L144.63 118.91C144.63 118.91 144.68 118.99 144.7 119.03L144.77 119.18C145.64 120.97 146.08 122.9 146.08 124.89C146.08 132.02 140.49 137.79 133.36 138.03H70.71Z" fill="#EAB83B"/>
                      <path d="M139.04 121.95L138.51 121.03L108.13 68.41L107.6 67.5C106.37 65.72 104.32 64.55 102 64.55C99.68 64.55 97.62 65.72 96.39 67.5L95.86 68.41L65.27 121.41L65.07 121.75C64.57 122.69 64.29 123.76 64.29 124.91C64.29 128.58 67.21 131.56 70.85 131.69H133.15C136.79 131.57 139.71 128.59 139.71 124.91C139.71 123.85 139.46 122.85 139.03 121.96L139.04 121.95Z" fill="white"/>
                      <path d="M101.99 108.56C98.97 108.56 96.52 106.11 96.52 103.09V87.0598C96.52 84.0398 98.96 81.5898 101.99 81.5898C105.02 81.5898 107.45 84.0398 107.45 87.0598V103.09C107.45 106.11 105 108.56 101.99 108.56Z" fill="#6E80FF"/>
                      <path d="M107.43 115.85C107.43 112.84 105 110.4 101.99 110.4C98.98 110.4 96.54 112.84 96.54 115.85C96.54 118.86 98.98 121.29 101.99 121.29C105 121.29 107.43 118.85 107.43 115.85Z" fill="#6E80FF"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_3074_4190">
                        <rect width="203.68" height="196.23" fill="white" transform="translate(0.160034)" />
                      </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <h2 style={{marginTop: 15, fontWeight: 400, fontSize: '1.2rem', lineHeight: '1.5rem'}}>
                    { isChanging ? `Inativar ${pluginsToChange?.now.name} e ativar ${pluginsToChange?.after.name}` : i18n.t(`payment.inative`)}
                  </h2>
                  <Input
                    inputAttr={{
                      type: 'text',
                    }}
                    placeholder='-'
                    label={i18n.t(`payment.motive`)}
                    value={motive}
                    change={(value:string) => setMotive(value)}
                  />
                </div>
              )}
            </ModalWrapper>
          
        </div>
      )}
    </>
  )
}

const Payments: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`payment.methods`), path: 'configuracoes/pagamentos', isActive: true },
        ]}
      >
        {i18n.t(`payment.methods`)}
      </PageTitle>
      <PaymentsContent />
    </>
  )
}

export { Payments }
