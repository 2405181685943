import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { getGmtStore, usingTotvs } from '../../../../utils/utils'
import useAuth from '../../../../hooks/useAuth'
import { removeEspacosEmBranco } from '../../../../utils/formater'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type CategoryType = {
  id: string
  name: string
  code: string
}

type Check = {
  label: string
  value: string
  checked: boolean
}

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

const EditarCategoriaContent: FC = () => {
  const navigate = useNavigate()
  const { loginInfo } = useAuth();
  const { id } = useParams()
  const [listClassification, setListClassification] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [categoryId, setCategoryId] = useState<any>('')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [order, setOrder] = useState<string>('0')
  const [status, setStatus] = useState<string>('true')
  const [initialHour, setInitialHour] = useState<string | null>(null);
  const [finalHour, setFinalHour] = useState<string | null>(null);

  const [image, setImage] = useState<File | null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | null>(null)
  const [disableFieldsTotvs, setDisableFieldTotvs] = useState(false)

  const generateClassificationTree = (categories: ReturnType[], parent: string | null, name: string): any => {
    const parentCategory = categories.find((category) => category.code === parent);
    if (parentCategory) return `${generateClassificationTree(categories, parentCategory.parent, parentCategory.name)} > ${name}`
    else return name;
  }

  const getCategories = async (loading = true) => {
    try {
      const res = await GET(`/classification`)
      res.data.forEach((row: ReturnType) => {
        if(row.id !== id){
          listClassification.push({
            id: row.id,
            parent: row.parent,
            code: row.code,
            name: generateClassificationTree(res.data, row.parent, row.name),
          })
        }
      })
      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      listClassification.forEach((row: any, idx) => {
        categories.push({
          value: row.code,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getSubCategory = async () => {
    try {
      setLoading(true)
      const res = (await GET(`/classification/${id}`)).data[0]
      const useTotvs = await usingTotvs()
      if (useTotvs) {
        setDisableFieldTotvs(true)
      }
      const cat = listClassification.filter(
        (row) => row.code == res.parent
      )
      if (cat && cat[0]) {
        setCategoryId({
          value: cat[0].code,
          label: cat[0].name
        })
      }
      setName(res.name)
      setCode(res.code)
      setOrder(res.order)
      setStatus(res.status)
      setInitialHour(res.initialHour)
      setFinalHour(res.finalHour)
      setImage(
        res.img.substring(0, 4) === 'http' ? res.img : process.env.REACT_APP_AWS_S3_URL + res.img
      )
      setFirstStateImage(
        res.img.substring(0, 4) === 'http' ? res.img : process.env.REACT_APP_AWS_S3_URL + res.img
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const edit = async () => {
    const categorySelected: any = categoryId;

    try {
      if (!name || !code) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const gmtStore = await getGmtStore();
        let imageRes = {
          success: false,
          image: '',
        }
        let res
        if (image && image !== firstStateImage) {
          if (firstStateImage) {
            await POST('/upload-images/delete-one', {
              url: firstStateImage,
            })
          }
          const formData = new FormData()
          formData.append('file', image)
          formData.append('nameImage', `category-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          imageRes = await POST('/upload-images/upload-one', formData)
          res = await PUT(`/classification/${id}`, {
            name: removeEspacosEmBranco(name),
            shortname: name,
            order: order ?? 0,
            code: removeEspacosEmBranco(code),
            parent: categoryId == '0' || categorySelected.value === '0' ? null : categorySelected.value,
            status: status,
            img: imageRes.success ? imageRes.image : '',
            initialHour: initialHour
              ? (initialHour.includes("+")
                ? initialHour.split("+")[0] + gmtStore
                : (initialHour.includes("-")
                  ? initialHour.split("-")[0] + gmtStore
                  : initialHour + gmtStore))
              : null,
            finalHour: finalHour
              ? (finalHour.includes("+")
                ? finalHour.split("+")[0] + gmtStore
                : (finalHour.includes("-")
                  ? finalHour.split("-")[0] + gmtStore
                  : finalHour + gmtStore))
              : null,
          })
        } else {
          if (!image) {
            res = await PUT(`/classification/${id}`, {
              name,
              shortname: name,
              order: order ?? 0,
              code,
              parent: categoryId == '0' || categorySelected.value === '0' ? null : categorySelected.value,
              status: status,
              img: 'default/no-image.png',
              initialHour: initialHour
                ? (initialHour.includes("+")
                  ? initialHour.split("+")[0] + gmtStore
                  : (initialHour.includes("-")
                    ? initialHour.split("-")[0] + gmtStore
                    : initialHour + gmtStore))
                : null,
              finalHour: finalHour
                ? (finalHour.includes("+")
                  ? finalHour.split("+")[0] + gmtStore
                  : (finalHour.includes("-")
                    ? finalHour.split("-")[0] + gmtStore
                    : finalHour + gmtStore))
                : null,
            })
          } else {
            res = await PUT(`/classification/${id}`, {
              name,
              shortname: name,
              order: order ?? 0,
              code,
              parent: categoryId == '0' || categorySelected.value === '0' ? null : categorySelected.value,
              status: status,
              initialHour: initialHour
                ? (initialHour.includes("+")
                  ? initialHour.split("+")[0] + gmtStore
                  : (initialHour.includes("-")
                    ? initialHour.split("-")[0] + gmtStore
                    : initialHour + gmtStore))
                : null,
              finalHour: finalHour
                ? (finalHour.includes("+")
                  ? finalHour.split("+")[0] + gmtStore
                  : (finalHour.includes("-")
                    ? finalHour.split("-")[0] + gmtStore
                    : finalHour + gmtStore))
                : null,
            })
          }
        }
        setLoading(false)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Categoria atualizada',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate('/category'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao atualizar a categoria!',
          })
        }
        setLoading(false)
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ?? 'Erro ao atualizar a categoria!',
      })
    }
  }

  const validateTime = (value: string) => {
    if (value) {
      value = value.replaceAll('_', '0')
      const values = value.split(':')
      let val1 = parseInt(values[0]) > 23 ? 23 : values[0]
      let val2 = parseInt(values[0]) > 23 || parseInt(values[1]) > 59 ? 59 : values[1]
      let val3 = parseInt(values[0]) > 23 || parseInt(values[2]) > 59 ? 59 : values[2]
      value = `${val1}:${val2}:${val3}`
    }
    return value
  }

  useEffect(() => {
    getCategories()
    getSubCategory()
  }, [])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(
                  `general.edit`
                )} ${i18n.t(`smart_order.category`)}`}</span>

                <BreadCrumbs />

                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body '>
              <div className='row'>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        search
                        label={i18n.t(`smart_order.category_parent`)}
                        options={categories}
                        value={categoryId}
                        change={(value: string) => setCategoryId(value)}
                        disabled={disableFieldsTotvs}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.name`)}
                        label={i18n.t(`smart_order.name`)}
                        value={name}
                        change={(value: string) => setName(value)}
                        disabled={disableFieldsTotvs}
                      />
                    </div>
                    <div className='col-lg-2'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.code`)}
                        label={i18n.t(`smart_order.code`)}
                        value={code}
                        change={(value: string) => {
                          if (value.match('^[a-zA-Z0-9_]*$') != null) {
                            setCode(value)
                          }
                        }}
                        disabled={disableFieldsTotvs}
                      />
                    </div>
                  </div>
                  <div className='row mt-6'>
                    <div className='col-lg-2'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.order`)}
                        label={i18n.t(`smart_order.order`)}
                        value={order}
                        change={(value: string) => setOrder(value)}
                      // disabled={disableFieldsTotvs}
                      />
                    </div>
                    <div className='col-lg-2'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label={i18n.t(`general.active`)}
                        options={[
                          { select: i18n.t(`general.active`), value: 'true' },
                          { select: i18n.t(`general.inactive`), value: 'false' },
                        ]}
                        value={status}
                        change={(value: string) => setStatus(value)}
                      />
                    </div>
                    {(loginInfo.plugintype !== 'fashion' && loginInfo.plugintype !== 'varejo') && (
                      <>
                        <div className="col-lg-2">
                          <Input
                            inputAttr={{
                              type: 'time',
                            }}
                            value={initialHour}
                            change={(value: string) => setInitialHour(value)}
                            placeholder='00:00:00'
                            label={`${i18n.t(`smart_order.initial_hour`)}`}
                            blur={(value: string) => setInitialHour(validateTime(value))}
                          />
                        </div>
                        <div className="col-lg-2">
                          <Input
                            inputAttr={{
                              type: 'time',
                            }}
                            value={finalHour}
                            change={(value: string) => setFinalHour(value)}
                            placeholder='00:00:00'
                            label={`${i18n.t(`smart_order.final_hour`)}`}
                            blur={(value: string) => setFinalHour(validateTime(value))}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className='col-lg-2'>
                  <Input
                    inputAttr={{
                      type: 'image',
                    }}
                    label={i18n.t(`smart_order.image`)}
                    value={image}
                    change={(value: BaseSyntheticEvent) =>
                      setImage(value ? value.target.files[0] : null)
                    }
                  />
                </div>
              </div>
            </div>

            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => edit()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const EditarCategoria: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.categories`), path: 'category', isActive: true },
          { title: i18n.t(`general.edit`), path: 'category/editar', isActive: true },
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.category`)}`}
      </PageTitle>
      <EditarCategoriaContent />
    </>
  )
}

export { EditarCategoria }
