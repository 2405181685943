import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import Swal from 'sweetalert2'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { secondsToHour } from '../../../utils/formater'
import { Button } from '../../../components/Button/Button'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'


type QueueType = {
  id: string;
  position: number;
  minutesOnQueue: string;
  people: number;
  name: string;
  phone: string;
  seconds: number;
}

const QueueContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false);
  const [queues, setQueues] = useState<QueueType[]>([]);
  const [queueSelected, setQueueSelected] = useState<QueueType | null>(null);
  const [limitMinutes, setLimitMinutes] = useState(0);
  const [option, setOption] = useState('');

  const createInterval = (outQueues: QueueType[], limit: number) => {
    setInterval(() => {
      const newQueues = outQueues.map((queue) => {
        queue.seconds++;
        queue.minutesOnQueue = secondsToHour(queue.seconds)
        return queue;
      });
      outQueues = newQueues;
      setQueues(newQueues);
    }, 1000)
  }

  const getQueues = async () => {
    try {
      const res = await GET('/queueReservation/queueConfig')
      if (res.success && res.data && res.data[0] && res.data[0].id && res.data[0].limitTime) {
        const queueConfigId = res.data[0].id;
        setLimitMinutes(res.data[0].limitTime);
        if (queueConfigId) {
          const queuesRes = await GET(`/queueReservation/queue/${queueConfigId}`)
          if (queuesRes && queuesRes.data && queuesRes.data.length > 0) {
            const queuesData = queuesRes.data.map((queue: QueueType) => {
              const seconds = ((parseInt(queue.minutesOnQueue.split(":")[0]) * 3600) + (parseInt(queue.minutesOnQueue.split(":")[1]) * 60) + parseInt(queue.minutesOnQueue.split(":")[2]))
              queue.seconds = seconds;
              return queue
            })
            setQueues(queuesData);
            createInterval(queuesData, res.data[0].limitTime)
          } else {
            setQueues([]);
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Não foi possível encontrar configuração de fila',
          }).then(() => navigate('/queue/config'))
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'É necessário configurar a fila antes de receber os dados',
        }).then(() => navigate('/queue/config'))
      }
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'É necessário configurar a fila antes de receber os dados',
      }).then(() => navigate('/queue/config'))
    }
  }

  const changeQueue = async () => {
    if (queueSelected) {
      const queue = Object.assign(queueSelected);
      const opt = option.toString();
      setQueueSelected(null);
      setOption('');
      try {
        setLoading(true);
        const res = await PUT(`/queueReservation/queue/${queue.id}`, { entryTime: new Date(), finalized: opt })
        setLoading(false)
        if (res && res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Fila alterada!',
            text: opt === 'removed' ? 'Removido da fila com sucesso' : 'Chegada confirmada com sucesso',
          }).then(async () => window.location.reload())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Falha ao alterar fila',
          }).then(async () => window.location.reload())
        }
      } catch (e) {
        setLoading(false);
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Falha ao alterar fila',
        }).then(async () => window.location.reload())
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao alterar fila',
      }).then(async () => window.location.reload())
    }
  }

  const updateAll = async (finalized: string) => {
    try {
      Swal.fire({
        icon: 'warning',
        title: finalized === 'removed' ? 'Você tem certeza que deseja remover todas as pessoas da fila?' : 'Você tem certeza que deseja confirmar todas as pessoas da fila?',
        showCloseButton: true,
        showCancelButton: true,
        reverseButtons: false,
        cancelButtonColor: '#dc3741',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          const res = await POST(`/queueReservation/queue/update-all`, { ids: queues.map((queue) => queue.id), finalized })
          setLoading(false)
          if (res && res.success) {
            Swal.fire({
              icon: 'success',
              title: 'Filas alteradas!',
              text: finalized === 'removed' ? 'Filas removidas com sucesso' : 'Filas confirmadas com sucesso',
            }).then(async () => window.location.reload())
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro!',
              text: 'Falha ao alterar filas',
            }).then(async () => window.location.reload())
          }
        }
      });
    } catch (e) {
      setLoading(false);
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao alterar filas',
      }).then(async () => window.location.reload())
    }
  }

  const init = async () => {
    setLoading(true)
    await getQueues();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`queue.index`)} </span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='card-body d-flex flex-column gap-5'>
              <div className="d-flex flex-row w-full gap-5">
                <Button
                  text={i18n.t(`queue.confirm_all`)}
                  color='primary'
                  iconColor='#FFF'
                  size='small'
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => updateAll('confirmed')}
                  btnClass='fw-bolder'
                />
                <Button
                  text={i18n.t(`queue.cancel_all`)}
                  color='primary'
                  iconColor='#FFF'
                  size='small'
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => updateAll('removed')}
                  btnClass='fw-bolder'
                />
              </div>
              {queues.length > 0 ? queues.map((queue) => (
                <div
                  className="d-flex flex-row px-4 py-6 align-items-center"
                  style={{ border: '1px solid #D3D9E8', borderLeft: '4px solid #D3D9E8', borderRadius: 4 }}
                >
                  <div className="d-flex flex-column w-50 gap-2">
                    <p style={{ color: '#6E7586', margin: 0, marginRight: 8 }}><b style={{ color: '#2A2D36' }}>{i18n.t(`queue.position`)}</b> {queue.position}</p>
                    <p style={
                      (
                        queue.minutesOnQueue.split(":")[0] &&
                        queue.minutesOnQueue.split(":")[1] &&
                        (parseInt(queue.minutesOnQueue.split(":")[0]) * 60 + parseInt(queue.minutesOnQueue.split(":")[1])) >= limitMinutes
                      )
                        ? { color: '#EB5353', margin: 0 }
                        : { color: '#6E7586', margin: 0 }}
                    >
                      <b style={{ color: '#2A2D36', marginRight: 8 }}>{i18n.t(`queue.time`)}</b>
                      {queue.minutesOnQueue}
                    </p>
                    <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{queue.people} {i18n.t(`queue.people`)}</b></p>
                    <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{queue.name}</b></p>
                    <p style={{ color: '#6E7586', margin: 0 }}>{queue.phone}</p>
                  </div>
                  <div className="d-flex flex-column gap-4 w-50">
                    <button
                      onClick={() => {
                        setQueueSelected(queue)
                        setOption('confirmed')
                        showWrapperModal(i18n.t(`queue.confirm`))

                      }}
                      style={{ border: '1px solid #5D8ED8', borderRadius: '4px', backgroundColor: 'transparent', width: 60, height: 40 }}
                    >
                      <i style={{ color: '#5D8ED8' }} className="fa fa-check" aria-hidden="true"></i>
                    </button>

                    <button
                      onClick={() => {
                        setQueueSelected(queue)
                        setOption('removed')
                        showWrapperModal(i18n.t(`queue.cancel`))
                      }}
                      style={{ border: '1px solid #5D8ED8', borderRadius: '4px', backgroundColor: 'transparent', width: 60, height: 40 }}
                    >
                      <i style={{ color: '#5D8ED8' }} className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              )) : (
                <div className="d-flex flex-column w-100 gap-2 align-items-center">
                  <h2 style={{ color: '#2A2D36', fontWeight: '400' }}>{i18n.t(`queue.empty`)} :(</h2>
                </div>
              )}
            </div>
          </div>
          <ModalWrapper
            confirmAction={() => changeQueue()}
            textAction={option === 'removed' ? i18n.t(`queue.cancel_button`) : i18n.t(`queue.confirm_button`)}
          >
            <p className='my-6' style={{ margin: 0, fontWeight: 400, fontSize: 14 }}>{option === 'removed' ? i18n.t(`queue.cancel_text`) : i18n.t(`queue.confirm_text`)}</p>
            {queueSelected && (
              <div
                className="d-flex flex-row px-4 py-6 align-items-center"
                style={{ border: '1px solid #D3D9E8', borderLeft: '4px solid #D3D9E8', borderRadius: 4 }}
              >
                <div className="d-flex flex-column gap-2">
                  <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{queueSelected.name}</b></p>
                  <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{queueSelected.people} {i18n.t(`queue.people`)}</b></p>
                  <p style={{ color: '#6E7586', margin: 0 }}>{queueSelected.phone}</p>
                </div>
              </div>
            )}
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const Queue: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`queue.index`), path: 'queue', isActive: true },
        ]}
      >
        {i18n.t(`queue.index`)}
      </PageTitle>
      <QueueContent />
    </>
  )
}

export { Queue }
