/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, MouseEvent, useEffect, useState, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import { formatCpfCnpj, formatPhone } from '../../../utils/formater'
import moment from 'moment'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import uuid from 'react-uuid'

type Form = {
  hash: string
  name: string
  corporatename: string
  cnpj: string
  appLogin: boolean
  subdomain: string
  image: string
  type: string
  status: boolean
  storeCode: string
  defaultlanguage: string
  defaultcurrency: string
  zipcode: string
  desc: string
  address: {
    street: string
    number: string
    extra: string
    district: string
    city: string
    state: string
    // country: string
  }
}

type User = {
  email: string
  name: string
}
const itemMenu = {
  height: '100%',
  padding: '20px 20px 0',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  borderBottom: '4px solid #FFFFFF',
}
const itemMenuActive = {
  ...itemMenu,
  color: '#6993ff',
  borderBottom: '4px solid #6993ff',
}

const itemMenuInactive = {
  ...itemMenu,
  color: 'black',
  borderBottom: '4px solid #CCCCCC',
  marginLeft: 5,
  marginRight: 5,
}

type themas = {
  value: number
  select: string
}

const ContaContent: FC = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('#tab1')
  const [loading, setLoading] = useState(false)
  const [thema, setThema] = useState<any>();
  const [showThemas, setShowThemas]  = useState<themas[]>([
    {
      value: 1,
      select: 'TEMA 1',
     },
     {
      value: 2,
      select: 'TEMA 2',
     },
     {
      value: 3,
      select: 'TEMA 3',
     }
  ])
  const [desc, setDesc] = useState<any>('');
  const [idDesc, setIdDesc] = useState<any>();
  const [alteraConta, setAlteraConta] = useState(0);
  const [alteraDes, setAlteraDes] = useState(0);

  const [form, setForm] = useState<Form>({
    hash: '',
    name: '',
    corporatename: '',
    cnpj: '',
    subdomain: '',
    image: '',
    type: '',
    status: true,
    appLogin: false,
    storeCode: '',
    defaultlanguage: 'pt',
    defaultcurrency: 'R$',
    zipcode: '',
    desc: '',
    address: {
      street: '',
      number: '',
      extra: '',
      district: '',
      city: '',
      state: '',
    },
  })

  const [id, setId] = useState(0)
  const [image, setImage] = useState<File | null>(null)
  const [imageBackUp,setImageBackUp] = useState();
  
  
  const [user, setUser] = useState<User>({
    email: '',
    name: '',
  })

  const getDescription = async () => {
    let res:any;
    try {
      res = await GET(`/pluginspace-detail-agregator`);
      if(res.success){
        setDesc(res.data.description);

        setThema(res.data.theme)

        setIdDesc(res.data.id);
      }
    } catch (error) {
      if(res === undefined){
        const resCad = await POST(`/pluginspace-detail-agregator`, {
          description: '',
          theme: 1,
        });
        if(!(resCad.success)) console.log(error);
        else {
          setDesc(resCad.data.description);
          setIdDesc(resCad.data.id);
        }
      } else console.log(error);
    }
  }

  const editDescription = async () => {
    try {
      setLoading(true)
      if(idDesc) {
        const res = await PUT(`/pluginspace-detail-agregator/${idDesc}`, {
          description: desc,
          theme: 1,
        });
        setLoading(false)
        return res
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Não foi possível alterar a descrição!',
        })
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
      setLoading(false)
    }
  }

  const getPluginspace = async () => {
    try {
      setLoading(true)
      const res = await GET(`/pluginspace/this`)
      
      if (res && res.data && res.data[0]) {
        const data = res.data[0]
        setId(data.id)
        let _form = {
          hash: data.hash,
          appLogin: data.appLogin,
          name: data.name,
          corporatename: data.corporateName,
          cnpj: data.cnpj,
          subdomain: data.subdomain,
          type: data.type,
          image: data.image,
          status: data.status,
          storeCode: data.storeCode,
          defaultlanguage: data.defaultLanguage,
          defaultcurrency: data.defaultCurrency,
          zipcode: '',
          desc: '',
          address: {
            street: '',
            number: '',
            extra: '',
            district: '',
            city: '',
            state: '',
          },
        }

        setImageBackUp(data.image)
        setImage(data.image)

        if (data.address && data.address.address) {
          _form.zipcode = data.address.cep
          _form.address = data.address.address
        }
        setForm(_form)
        if (data.user) {
          setUser({
            email: data.user.email,
            name: data.user.name,
          })
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }


  
  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }


  async function saveImage(image1: any, imageBk: any) {
    try {
      if (image1 && image1 !== imageBk) {
        if (imageBk) {
          deleteImage(imageBk)
        }
        const formData = new FormData()
        formData.append('file', image1)
        formData.append('nameImage', `logo-marca-${uuid()}.jpg`)
        formData.append('originalSize', 'true')
        const imageRes = await POST('/upload-images/upload-one', formData)
        return imageRes.success ? imageRes.image : ''
      }
      return image1
    } catch (error) {
      return ''
    }
  }

  const update = async () => {
    try {
      setLoading(true)
      let newForm = Object.assign(form)
      newForm.cnpj = formatCpfCnpj(newForm.cnpj)
      newForm.zipcode = newForm.zipcode.replace('-', '')
      newForm.image = await saveImage(image, imageBackUp)
      if (id) {
        const res = await PUT(`/pluginspace/ninegrid/${id}`, newForm)
        setLoading(false)
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Conta alterada!',
            text: 'Sua conta foi editada com sucesso!',
          })
        } else {
          Swal.fire({
            icon: res.typemsg ? res.typemsg : 'error',
            title: (res.typemsg && res.typemsg === 'warning') ? 'Atenção!' : 'Erro',
            text: res.message ? res.message : 'Não foi possível alterar a conta!'
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Não foi possível alterar a conta!',
        })
      }
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: (typeof e === 'string' && e.includes('alterada')) ? 'warning' : 'error',
        title: (typeof e === 'string' && e.includes('alterada')) ? 'Atenção' : 'Erro',
        text: (e.response && e.response.message && e.response.message[0]) ?
          e.response.message[0] :
          (typeof e === 'string' ? e : 'Não foi possível alterar a conta!')
      })
    }
  }


  const verificaUpdate = async () => {
    if(alteraConta === 1 && alteraDes === 1){
      await update();
      await editDescription();
      setAlteraConta(0);
      setAlteraDes(0);
    } else if(alteraConta !== 1 && alteraDes === 1) {
      const res = await editDescription();
      setAlteraDes(0);
      if (res.success) {
        Swal.fire({
          icon: 'success',
          title: 'Descrição alterada!',
          text: 'Sua descrição foi editada com sucesso!',
        })
      } else {
        Swal.fire({
          icon: res.typemsg ? res.typemsg : 'error',
          title: (res.typemsg && res.typemsg === 'warning') ? 'Atenção!' : 'Erro',
          text: res.message ? res.message : 'Não foi possível alterar a descrição!'
        })
      }
    } else if(alteraConta === 1 && alteraDes !== 1){
      await update();
      setAlteraConta(0);
    } else {
      Swal.fire({
        icon: 'success',
        title: 'Conta alterada!',
        text: 'Sua conta foi editada com sucesso!',
      })
    }
  }
 

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    setLoading(true)
    await getPluginspace()
    await getDescription()
    setLoading(false)
  }

  const getCEP = async (value: string) => {
    value = value.replace(/[^\d]/g, '')
    if (value && value.length === 8) {
      try {
        setLoading(true)
        const res = await GET(`https://viacep.com.br/ws/${value}/json/`)
        setForm({
          ...form,
          address: {
            ...form.address,
            street: res.logradouro,
            district: res.bairro,
            city: res.localidade,
            state: res.uf,
          },
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
            <div style={{ flex: 1 }}>
              <div className='w-100 card'>

                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`Dados da empresa`)}</span>
                    <BreadCrumbs />
                    <span className='text-muted mt-1 fw-bold fs-7'>Aqui você configura as principais informações da empresa.</span>
                  </h3>
                </div>
                {/* INFO CONTA */}
                <div className='px-10 pt-2 pb-7'> 
                  <Input
                    inputAttr={{
                      type: 'cnpj',
                    }}
                    disabled
                    placeholder={i18n.t(`account.registration_number`)}
                    label={i18n.t(`account.registration_number`)}
                    value={form.cnpj || ''}
                    change={(value: any) => {
                      setForm({ ...form, cnpj: value });
                      setAlteraConta(1);
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    disabled
                    placeholder={i18n.t(`account.corporate_name`)}
                    label={i18n.t(`account.corporate_name`)}
                    value={form.corporatename || ''}
                    change={(value: any) => {
                      setForm({ ...form, corporatename: value });
                      setAlteraConta(1);
                    }}
                  />
                  <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder={i18n.t(`account.public_place`)}
                      label={i18n.t(`account.public_place`)}
                      value={form.address.street || ''}
                      change={(value: any) => {
                        setForm({ ...form, address: { ...form.address, street: value } });
                        setAlteraConta(1);
                      }}
                    />
                   <div className='d-flex justify-content-between'>
                    <div className='me-3 flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'cep',
                        }}
                        placeholder={i18n.t(`account.zip_code`)}
                        label={i18n.t(`account.zip_code`)}
                        value={form.zipcode || ''}
                        change={(value: any) => {
                          setForm({ ...form, zipcode: value });
                          setAlteraConta(1);
                        }}
                        blur={(value: string) => getCEP(value)}
                      />
                    </div>
                    <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    disabled
                    placeholder={i18n.t(`account.state`)}
                    label={i18n.t(`account.state`)}
                    value={form.address.state || ''}
                    change={(value: any) => {
                      setForm({ ...form, address: { ...form.address, state: value } });
                      setAlteraConta(1);
                    }}
                  />
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div className='me-3 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder={i18n.t(`account.address_complement`)}
                      label={i18n.t(`account.address_complement`)}
                      value={form.address.extra || ''}
                      change={(value: any) => {
                        setForm({ ...form, address: { ...form.address, extra: value } });
                        setAlteraConta(1);
                      }}
                    />
                    </div>
                    <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`account.number`)}
                        label={i18n.t(`account.number`)}
                        minNumber={0}
                        value={form.address.number || ''}
                        change={(value: any) => {
                          setForm({ ...form, address: { ...form.address, number: value } });
                          setAlteraConta(1);
                        }}
                      />
                  </div>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    disabled
                    placeholder={i18n.t(`account.city`)}
                    label={i18n.t(`account.city`)}
                    value={form.address.city || ''}
                    change={(value: any) => {
                      setForm({ ...form, address: { ...form.address, city: value } });
                      setAlteraConta(1);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='justify-content-between' style={{ flex: 1 }}>
              <div className='w-100 card ms-5'>
                <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`Dados do agregador`)}</span>
                    <BreadCrumbs />
                    <span className='text-muted mt-1 fw-bold fs-7'>Aqui você configura as principais informações do seu agregador.</span>
                  </h3>
                </div>
                <div className='px-10 pt-1 pb-7'>
                  <div className="d-flex align-items-center flex-wrap pb-5 border-bottom border-bs-gray-200">
                    <span className='mt-6 pe-3 fw-normal form-label mb-0 w-20'>Tipo do Tema</span>
                    <div className='flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        placeholder={"TEMA 1"}
                        disabled={true}
                        options={showThemas}
                        search={false}
                        value={thema}
                        change={(value: any) => {
                          setThema(value);
                          setAlteraDes(1);
                        }}
                      />
                    </div>
            
                  </div>

                  <div className='pb-6 border-bottom border-bs-gray-200'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder={i18n.t(`account.name_of_the_establishment`)}
                      label={'Nome do agregador'}
                      disabled
                      value={form.name || ''}
                      change={(value: any) => {
                        setForm({ ...form, name: value });
                        setAlteraConta(1);
                      }}
                    />
                  </div>

                  {/* <div className='py-6 border-bottom border-bs-gray-200'>
                    <div className='d-flex justify-content-between'>
                      <div className='pe-8'>
                        <span className='mt-6 fw-normal form-label mb-0'>Logo marca</span>
                        <p className='text-muted mt-1 fw-bold fs-7 pt-5'>É a identificação da empresa, o que você vê no topo do menu lateral do sistema Ninegride Agregador. 
                        A Logo deve ser retangular com formato png (fundo transparente) e medir, pelo menos, 400 × 100 píxeis.</p>
                      </div>
                      <div className=''>
                        <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        value={image}
                        change={(value: BaseSyntheticEvent) =>
                          setImage(value ? value.target.files[0] : null)
                        }
                      />
                      </div>
                    </div>
                  </div> */}

                  <div className='pt-1'>
                  <Input
                    rows={4}
                    inputAttr={{
                      type: 'textarea',
                    }}
                    backgroundColor={'#EEF0F8'}
                    placeholder={'Descrição'}
                    label={'Descrição do Agregador'}
                    value={desc || ''}
                    change={(value: any) => {
                      setDesc(value);
                      setAlteraDes(1);
                    }}
                  />
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
          )}


          <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
          <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={verificaUpdate}
              btnClass='fw-bolder'
            />
          </div>
    </>
  )
}

const DadosConta: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`Dados da conta`), path: '/config/account', isActive: true },
        ]}
      >
        {i18n.t(`Dados da conta`)}
      </PageTitle>
      <ContaContent />
    </>
  )
}

export { DadosConta }
