import { createContext, ReactNode, useEffect, useState } from 'react'
import { GET } from '../services/api'
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';



type LocationType = {
    id: number;
    name: string;
    code: string;
    status: boolean;
    type: string;
    cep: string;
    address: any | null;
};


type PluginSpaceContextData = {
    pluginSpace: any
    getPluginSpace: () => any
    locations: LocationType[]
    getLocations: () => Promise<LocationType[] | any[]>
    menu: MenuType[]
    getMenu: () => Promise<MenuType[] | any[]>
    manual: Manual[]
    integrations: IntegrationType[]
    isProtheus: boolean
    isWintor: boolean
    hasERP: boolean
}

type PluginSpaceContextProvider = {
    children: ReactNode
}


type MenuType = {
    icon: string
    id: number
    name: string
    newIcon: string
    order: number
    parent: number
    route: string
    submenu: SubMenuType[]
    type: string
    permissionParent?: boolean
}

type SubMenuType = {
    id: number
    name: string
    newIcon: string
    order: number
    parent: number
    router: string
}

type Manual = {
    id: number;
    name: string;
    type: string;
    route: string;
    created?: any;
    updated?: any;
}

type IntegrationType = {
    id: number
    name: string
    plugintemplateId: number
}


export const PluginSpaceContext = createContext({} as PluginSpaceContextData)

const PluginSpaceProvider = ({ children }: PluginSpaceContextProvider) => {

    const [pluginSpace, setPluginSpace] = useState<any>(null)
    const [locations, setLocations] = useState<LocationType[]>([])
    const [menu, setMenu] = useState<MenuType[]>([])
    const [manual, setManual] = useState<Manual[]>([])
    const [integrations, setIntegrations] = useState<IntegrationType[]>([])
    const [isWintor, setIsWintor] = useState<boolean>(false)
    const [isProtheus, setIsProtheus] = useState<boolean>(false)
    const [hasERP, setHasERP] = useState<boolean>(false)
    const { loginInfo } = useAuth()


    async function getPluginSpace() {

        if (pluginSpace) {
            return pluginSpace
        }

        const res = await GET(`/pluginspace/this`)

        if (res && res.data && res.data[0]) {
            setPluginSpace(res.data[0])

            return res.data[0]
        }

        return {}
    }

    async function getLocations() {
        if (locations && locations.length > 0) {
            return locations
        }

        const response = await GET(`/location`)

        const newLocations = response.data.map(
            (location: LocationType) => ({
                id: location.id,
                name: location.name,
                code: location.code,
                status: location.status,
                type: location.type,
                cep: location.cep,
                address: location.address
            })
        );
        const newLocationsFiltered = newLocations.filter(
            (loc: LocationType) => loc.status === true
        )
        setLocations(
            newLocationsFiltered
        );


        return newLocationsFiltered

    }

    async function getMenu() {
        try {
            if (menu.length) {
                return menu
            }


            const response = await GET('/pages/buildMenuPage')
            if (response) {
                const menuOrder = response.sort((a: MenuType, b: MenuType) => a.order - b.order)
                setMenu(menuOrder)

                return menuOrder
            }
        } catch (error) {
            console.log(error)
        }
    }


    async function getManual() {
        if (manual.length) {
            return manual
        }

        const response = await GET('manual')

        if (response && response.data) {
            setManual(response.data)
            return response.data
        }

    }

    async function usingTotvs() {

        try {
            const integrationRes = await GET(`/pluginconfig`)

            const data: IntegrationType[] = integrationRes.data

            setIntegrations(data)

            const findWintorIntegration = data.find(el => el.plugintemplateId === 45)

            if (findWintorIntegration) {
                setIsWintor(true)
            }

            const findProtheusIntegration = data.find(el => el.plugintemplateId === 104)

            if (findProtheusIntegration) {
                setIsProtheus(true)
            }

            const totvsIntegration = data.filter(
                (pluginconfig: any) =>
                    pluginconfig.plugintemplateId === 23 ||
                    pluginconfig.plugintemplateId === 15 ||
                    pluginconfig.plugintemplateId === 45 ||
                  // pluginconfig.plugintemplateId === 48 ||
                    pluginconfig.plugintemplateId === 104
            )

            if (totvsIntegration && totvsIntegration.length > 0)
                setHasERP(true)
            else
                setHasERP(false)



        } catch (e) {
            console.log(e)
            return false
        }

    }


    useEffect(() => {
        getPluginSpace()
        getMenu()
        getLocations()
        getManual()
        usingTotvs()
    }, [loginInfo.pluginspaceId])


    return (
        <PluginSpaceContext.Provider
            value={{
                pluginSpace,
                getPluginSpace,
                getLocations,
                locations,
                menu,
                getMenu,
                manual,
                isProtheus,
                isWintor,
                integrations,
                hasERP
            }}
        >
            {children}
        </PluginSpaceContext.Provider>
    )
}

export default PluginSpaceProvider