/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Input } from '../../../components/Input/Input'
import { Button } from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, POST } from '../../../services/api'
import { Loading } from '../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'
import SVG from 'react-inlinesvg';
import uuid from 'react-uuid';
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import usePluginsSpace from '../../../hooks/usePluginSpace'
type classIcon = {
  name: string
  data: Icon[]
}

type Icon = {
  id: number;
  svg: string;
  description: string;
  category: string;
}

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}
/*
AWS.config.update({
  region: 'us-east-1',
  accessKeyId: 'AKIAZR7NNMK4ECMM3DKY',
  secretAccessKey: '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'
});

const s3 = new AWS.S3();*/

const PageContent: FC = () => {
  const navigate = useNavigate()

  const { pluginSpace } = usePluginsSpace()
  const [loading, setLoading] = useState(false)
  const [filterCategoria, setFilterCategoria] = useState<any>()

  const [type, setType] = useState<string>('service')
  const [categories, setCategories] = useState<any[]>([])
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [status, setStatus] = useState<boolean>(true)
  const [description, setDescription] = useState<string>('')

  const [listClassification, setListClassification] = useState<any[]>([])

  //icones
  const [icones, setIcones] = useState<Icon[]>([])
  const [iconesFiltered, setIconesFiltered] = useState<Icon[]>([])
  const [categoryIcons, setCategoryIcons] = useState<any[]>([{
    select: 'Todos',
    value: -1
  }])
  const [categoryIconsSelect, setCategoryIconsSelect] = useState<any>(-1)
  const [icon, setIcon] = useState<string>('')
  const [loadingIcons, setLoadingIcons] = useState(false)


  const convertSvgToPng = () => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (context) {
        const image = new Image();
        image.onload = () => {
          canvas.width = 45;
          canvas.height = 45;
          context.drawImage(image, 0, 0, 45, 45); // Adjust size as needed

          canvas.toBlob(blob => {
            if (blob) {
              resolve(new File([blob], 'image.png', { type: 'image/png' }));
            } else {
              reject(new Error('Failed to convert SVG to PNG'));
            }
          });
        };
        image.src = `data:image/svg+xml;base64,${btoa(icon)}`;
      } else {
        reject(new Error('Canvas context not supported'));
      }
    });
  };
  function changeFilterIcons() {
    setLoadingIcons(true)

    const filtered = icones.filter(icon => {

      if (parseInt(categoryIconsSelect) !== -1) {


        return icon.category === categoryIconsSelect ? true : false

      }
      return true


    })

    setIconesFiltered(filtered)


    setTimeout(() => {
      setLoadingIcons(false)
    }, 1000)
  }



  const generateClassificationTree = (
    categories: ReturnType[],
    parent: string | null,
    name: string
  ): any => {
    const parentCategory = categories.find((category) => category.code === parent)
    if (parentCategory)
      return `${generateClassificationTree(
        categories,
        parentCategory.parent,
        parentCategory.name
      )} > ${name}`
    else return name
  }

  const getClassifications = async () => {
    try {
      const pluginType = getPlugintypeIdByCode('service')
      const res = await POST(`/classification/filter`, {
        plugintypeId: pluginType,
        status: true,
        deleted: 'not show',
      })

      // eslint-disable-next-line array-callback-return
      res.data.map((row: ReturnType) => {
        listClassification.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: generateClassificationTree(res.data, row.parent, row.name),
        })
      })
      const categories = [
        {
          value: null,
          select: 'Selecione',
          key: -1,
        },
      ]
      // eslint-disable-next-line array-callback-return
      listClassification.map((row: any, idx) => {
        categories.push({
          value: row.id,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  async function sendSvgAWS() {
    try {
      let bucket = `img.ninegrid.com`
      const response = await POST('/upload-images/upload-svg', {
        file: icon,
        path: bucket,
        name: `${pluginSpace?.subdomain}/service/product-${uuid()}.svg`
      })
      return response

    } catch (error) {
      return {
        error: error,
        success: false,
        data: {}
      }
    }
  }

  const create = async () => {
    try {
      if (filterCategoria.value === 0 || !name || !code || !icon) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)

        const image = icon
        let imageRes: any = {
          success: false,
          image: '',
        }

        let resProd: any = {
          success: false,
          dados: {},
        }

        if (image) {
          imageRes = await sendSvgAWS()
        }
        // inserir dados da imagem

        if (imageRes.success == true) {
          const dataP = {
            name: name,
            description: description,
            code: code ?? '',
            classificationId: parseInt(filterCategoria.value),
            type: type,
            status: status,
            markId: 0,
            markCode: '',
            measurementUnitId: 0,
            measurementUnitCode: '',
          }
          resProd = await POST('/product', dataP)
          const data = [
            {
              path: imageRes.image,
              productId: resProd.dados.id,
              typeimg: 'mainpicture',
              order: 0,
            }
          ]

          await POST('/productimage/multiple', {
            data,
          })

        }

        if (resProd.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Serviço cadastrado',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/hospitality/service-item'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar o serviço!',
          })
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o serviço!',
      })
    }
  }



  async function getIcones() {
    try {


      const svgCategory = await POST('svgcategory/filter', {
        status: true
      });

      const auxCategory = [{
        select: 'Todos',
        value: -1
      }]

      svgCategory.data.map((el: any) => {
        auxCategory.push({
          select: el.name,
          value: el.id
        }
        )
      })

      setCategoryIcons(auxCategory)



      const res = await GET('svgwebapp')

      if (!res || !res.success) {
        Swal.fire({
          icon: 'error',
          titleText: 'Erro',
          text: 'Erro ao encontrar os tipos'
        })

        return
      }

      setIcones(res.data)
      setIconesFiltered(res.data)



    } catch (error) {
      console.log(error)


      Swal.fire({
        icon: 'error',
        titleText: 'Erro',
        text: 'Erro ao encontrar os tipos'
      })
    }
  }

  async function loadData() {
    setLoading(true)
    await Promise.all([
      getClassifications(),
      getIcones()
    ])
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])


  useEffect(() => {
    changeFilterIcons()
  }, [categoryIconsSelect])


  function daOConsole(id: any) {
    console.log(id)
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <>
            <div className='d-flex justify-content-between'>
              <div className='w-50 me-5'>
                <div className='card pb-5'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Cadastrar Serviço</span>
                      <BreadCrumbs />
                    </h3>
                  </div>
                  <div className='px-10 py-5'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      search
                      placeholder={'Selecione'}
                      label={i18n.t(`smart_order.category_parent`)}
                      options={categories}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='Nome'
                      label='Nome'
                      value={name}
                      change={(value: string) => setName(value)}
                    />

                    <Input
                      inputAttr={{
                        type: 'textarea',
                      }}
                      placeholder='Descrição'
                      label='Descrição'
                      value={description}
                      change={(value: string) => setDescription(value)}
                      rows={3}
                    />

                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='Código'
                      label='Código'
                      value={code}
                      change={(value: string) => setCode(value)}
                    />

                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label='Status'
                      options={[
                        { select: 'Ativo', value: true },
                        { select: 'Inativo', value: false },
                      ]}
                      value={status}
                      change={(value: boolean) => setStatus(value)}
                    />

                  </div>
                </div>
              </div>
              <div className='w-50 card h-100'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Ícones</span>
                  </h3>
                </div>

                <div className="d-flex flex-column overflow-auto  gap-5 p-8" style={{
                  height: '40rem'
                }}>
                  <div>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label='Categoria do Icone'
                      options={categoryIcons}
                      defaultValue={categoryIcons[0]}
                      value={categoryIconsSelect || ''}
                      change={(value: any) => {
                        setCategoryIconsSelect(value)

                      }}
                    />
                  </div>

                  <div className=" d-flex overflow-auto flex-wrap gap-5 justify-content-center">
                    {loadingIcons ? (
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <Loading />
                      </div>
                    ) : (

                      <>
                        {
                          iconesFiltered.map(iconF => (
                            <div className={`d-flex flex-column p-4 justify-content-center align-items-center ${iconF.svg === icon ? 'border border-success border-2' : 'border border-back border-2'}`}
                              onClick={() => {
                                setIcon(iconF.svg)
                              }}
                              style={{
                                width: '20%',

                              }}
                            >
                              <span>{iconF.description}</span>

                              <SVG src={iconF.svg} style={{
                                width: '45px',
                                height: '45px'
                              }} />
                            </div>
                          ))
                        }

                      </>


                    )}
                  </div>


                  <div className="d-flex justify-content-between gap-5 border-top border-black">

                    <div className="d-flex flex-column justify-content-start gap-5 mt-5">
                      <span className='fs-3 fw-bold'>Ícone selecionado</span>
                      {icon &&
                        <div className="p-8 d-flex justify-content-center">
                          <SVG src={icon} style={{
                            width: '45px',
                            height: '45px'
                          }} />
                        </div>

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Button
                text='Voltar'
                color='info'
                size='small'
                horizontalPadding={5}
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  navigate('/product_food')
                }}
                btnClass='fw-bolder me-3'
              />
              <Button
                text='Salvar'
                color='primary'
                size='small'
                horizontalPadding={5}
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  create()
                }}
                btnClass='fw-bolder'
              />
            </div>
          </>
        </div>
      )}
    </>
  )
}

const CriarServico: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Serviços', path: 'service-item', isActive: true },
          { title: 'Criar', path: '#', isActive: true },
        ]}
      >
        Cadastrar Serviço
      </PageTitle>
      <PageContent />
    </>
  )
}

export { CriarServico }
