import { FC, useEffect, useRef, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import { Button } from "../../../components/Button/Button"
import Swal from "sweetalert2"
import { Input } from "../../../components/Input/Input"
import { GET, POST, DELETE } from "../../../services/api"
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import useAuth from '../../../hooks/useAuth'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'

type segmentoAgregador = {
    name: string;
    order: number;
    image: string
    type: string;
    active: boolean;
    id: number;
}

type newSegmento = {
    pluginspaceId: number,
    segment: number,
    id: number,
    name: string
}

type CategoryType = {
    id: number
    parent: string
    code: string
    name: string
    shortname: string
    order: 0
    img: string
    status: true
    subitems: CategoryType[]
  }


const SegmentsCreateContent: FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const { loginInfo } = useAuth();
    const [setor, setSetor] = useState<segmentoAgregador[]>([]);
    const [segmentosPluginSpace, setSegmentosPluginSpace] = useState<any[]>([])
    const [segmentosPluginSpaceDelete, setSegmentosPluginSpaceDelete] = useState<any[]>([])
    const [segmentos, setSegmentos] = useState<any[]>([])

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        await clearVariables()
        await getSegmentosPluginSpace();
        await getSetores();
        setLoading(false);
    };

    const clearVariables = async () => {
        setSegmentosPluginSpaceDelete([]);
    }

    const executeOperation = async () => {
        try {
            setLoading(true);
            const setCriar = segmentosPluginSpace.filter(id => {
                return (!segmentos.includes(id));
            })

            if(setCriar.length !== 0 && segmentosPluginSpaceDelete.length !== 0) {
                const retornoCreate = await createNewSegmento(true);
                const retornoDelete = await deleteNewSegmento(true);
                if(retornoCreate && retornoDelete){
                    Swal.fire({
                        icon: 'success',
                        title: 'Setor modificado',
                        text: 'Operação realizada com sucesso!',
                    }).then(async () => fetchData())
                } else if(retornoDelete === undefined){
                    Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Não é possível desabilitar o setor, pois existe pelo menos uma categoria vinculada.',
                    }).then(async () => fetchData())
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao realizar a operação!',
                    }).then(async () => fetchData())
                }
            } else {
                if(!(setCriar.length === 0)) await createNewSegmento(false);
                if(!(segmentosPluginSpaceDelete.length === 0)) await deleteNewSegmento(false);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const getCategories = async () => {
        setLoading(true)
        try {
          const res = await POST(`/classification/filter`, {
            plugintypeId: getPlugintypeIdByCode('service'),
            deleted: 'not show',
          })
      
          const segmentosPluginSpace = await GET('pluginspacesegment');        
      
          let newSegmento = segmentosPluginSpace.data.filter((segment:newSegmento) => {
              if(segment.pluginspaceId === loginInfo.pluginspaceId) {
                  return segment;
              }
          })

          newSegmento = newSegmento.map((segment:newSegmento) => {
              return segment.id.toString()
            })
            
            const newCategories = res.data.map((row: CategoryType) => {
                
                const code = row.code.split('/');
                if(code.length > 1) console.log(code[1]);
                else return null;
                
                if(newSegmento.includes(code[1])) {
                    return {
                        id: row.id,
                        name: `${row.name}`,
                        status: row.status,
                        code: row.code,
                        order: row.order,
                        category: row.shortname,
                        description: row.parent,
                        img: row.img,
                    }
                }
                return null;
            })
            .filter((category: any) => category !== null);
            
            return newCategories

        } catch (e) {
          console.log(e)
          setLoading(false)
        }
    }

    const verificaExclusao = async (segmentosToSend: any) => {
        try {
            const retornoCategorias = await getCategories();
    
                const categoriaDelete = setor.filter((setor: any) => 
                    segmentosToSend.some((segmento: any) => segmento.segment === setor.id)
                );
    
                const categoriaCadastrada = retornoCategorias.filter((categoria: any) =>
                    categoriaDelete.some((setor: any) => setor.name === categoria.name)
                );
    
                if(categoriaCadastrada.length > 0) return false
                else return true;
        } catch (error) {
            console.log(error); 
        }
    }

    const deleteNewSegmento = async (bothWork: boolean) => {
        try {
            const segmentosToSend = segmentosPluginSpaceDelete.map((segmento) => ({
                segment: segmento,
            }));

            const categoriaAtiva = await verificaExclusao(segmentosToSend);
            
            if(categoriaAtiva) {
                const deletePromises = segmentosToSend.map(async (seg) => {
                    const segmentDeletePromises = segmentos.map(async (segmento) => {
                        if (seg.segment === segmento.segment) {
                            const res = await DELETE(`pluginspacesegment/${segmento.id}`);
                            return res.success;
                        }
                        return false;
                    });
                    
                    const results = await Promise.all(segmentDeletePromises);
                    return results.filter(result => result !== false);
                });
                
                const deleteResults = await Promise.all(deletePromises);
                const flattenedDeleteResults = deleteResults.flat();
                const allDeletesSuccessful = flattenedDeleteResults.every(result => result === true);
    
                if(bothWork && allDeletesSuccessful) return true;
                else if(bothWork && !allDeletesSuccessful) return false;
                else {
                    if (allDeletesSuccessful) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Setor deletado',
                            text: 'Operação realizada com sucesso!',
                        }).then(async () => fetchData())
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro',
                            text: 'Erro ao deletar setor!',
                        }).then(async () => fetchData())
                    }
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Atenção',
                    text: 'Não é possível desabilitar o setor, pois existe pelo menos uma categoria vinculada.',
                }).then(async () => fetchData())
            }
        } catch (error) {
            console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao deletar setor!',
            }).then(async () => fetchData())
        }
    }


    const createNewSegmento = async (bothWork: boolean) => {
        try {
            const setCriar = segmentosPluginSpace.filter(id => {
                return (!segmentos.includes(id));
            })
            const segmentosToSend = setCriar.map((segmento) => ({
                segment: segmento,
            }));

            const createPromises = segmentosToSend.map(async segmento => {
                const res = await POST('pluginspacesegment', {
                    segment: segmento.segment,
                    pluginspaceId: loginInfo.pluginspaceId,
                });
                return res.success;
            });

            const createResults = await Promise.all(createPromises);
            const allCreatesSuccessful = createResults.every(result => result);

            if(bothWork && allCreatesSuccessful) return true;
            else if(bothWork && !allCreatesSuccessful) return false;
            else {
                if (allCreatesSuccessful) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Setor cadastrado',
                        text: 'Operação realizada com sucesso!',
                    }).then(async () => fetchData())
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao cadastrar setor!',
                    }).then(async () => fetchData())
                }
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao cadastrar setor!'
            }).then(async () => fetchData())
        }
    }

    const getSegmentosPluginSpace = async () => {
        try {
            const segmentosPluginSpace = await GET('pluginspacesegment');        

            let newSegmento = segmentosPluginSpace.data.filter((segment:newSegmento) => {
                if(segment.pluginspaceId === loginInfo.pluginspaceId) {
                    return segment;
                }
            })

            newSegmento = newSegmento.map((segment:newSegmento) => {
                return {segment: Number(segment.segment),
                    id: Number(segment.id),
                    name: String (segment.name),
                };
            })


            setSegmentos(newSegmento);

            
        } catch (error) {
            console.log(error);
        }
    }

    const addPermission = (push: boolean, newPermissionId: number) => {
        if (push) {
          const filterOff = segmentosPluginSpaceDelete.filter((permission) => permission !== newPermissionId);
          setSegmentosPluginSpaceDelete(filterOff);
          const newPermissions = segmentosPluginSpace;
          newPermissions.push(newPermissionId);
          setSegmentosPluginSpace(newPermissions);
        } else {
          const bool = segmentosPluginSpace.includes(newPermissionId);
          if(bool) {
            const vetorCopia = segmentosPluginSpace.filter(valor => valor !== newPermissionId);
            setSegmentosPluginSpace(vetorCopia);
          }
          const verificaOff = segmentosPluginSpaceDelete.includes(newPermissionId);
          if(!verificaOff) {
            const newPermissions = segmentosPluginSpaceDelete;
            newPermissions.push(newPermissionId);
            setSegmentosPluginSpaceDelete(newPermissions);
          }
        }
      }

    const getSetores = async () => {
        try {
            setLoading(true);
      
            const setoresRes = await GET(`segment`);

            //console.log(setoresRes);

            let newSetores = setoresRes.data.map((setor: segmentoAgregador) => {
                return {
                    name: setor.name,
                    order: setor.order,
                    image: setor.image,
                    type: setor.type,
                    active: setor.active,
                    id: setor.id,
                }
            });

            newSetores.sort((a: any, b: any) => a.name.localeCompare(b.name));

            // console.log(newSetores);
            
            setSetor(newSetores);
                
            setLoading(false);
          } catch (e) {
            setLoading(false);
            console.log(e)
          }
    }

    return (
        <>
            {loading ? <Loading /> :
                <div className='row g-5 gx-xxl-12'>
                    <div>
                        <div>
                            <div className='w-100 card'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-4 mb-1'>Setor de atuação</span>
                                        <BreadCrumbs />
                                        <span className='text-muted mt-1 fw-bold fs-7'>Lista para selecionar os principais setores que farão parte do Agregador. Preencha as informações abaixo e clique no botão salvar para confirmar as alterações</span>
                                    </h3>
                                </div>
                                <div className='row ps-9 pb-9 pe-9'>
                                    {setor.map((setor) => (
                                        <div className='col-md-3 mt-5'  key={setor.id}>
                                            <Input
                                            inputAttr={{
                                            type: 'checkbox',
                                            }}
                                            placeholder={i18n.t(`${setor.name}`)}
                                            label={i18n.t(`${setor.name}`)}
                                            change={(value: boolean) => addPermission(value, setor.id)}
                                            checked = {segmentos.some(seg => seg.segment === setor.id)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div >
                    <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
                        <Button
                            text='Voltar'
                            color='info'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={() => {
                                Swal.fire({
                                    title: 'Você tem certeza?',
                                    text: 'Os dados que foram digitados serão perdidos.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim, voltar!',
                                    cancelButtonText: 'Não, cancelar!',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate('/pluginspace')
                                    }
                                })
                            }}
                            btnClass='fw-bolder me-3'
                        />
                        <Button
                            text='Salvar'
                            color='primary'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={executeOperation}
                            btnClass='fw-bolder'
                        />
                    </div>
                </div >
            }
        </>


    )
}

const ConfiguracaoSetorDeAtuacao: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`Setor de atuação`), path: '/config/sector', isActive: true },
                  ]}
                >
                  {i18n.t(`Setor de atuação`)}
            </PageTitle>
            <SegmentsCreateContent />
        </>
    )
}

export { ConfiguracaoSetorDeAtuacao }