import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import Swal from 'sweetalert2'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { Input } from '../../../components/Input/Input'
import moment from 'moment'
import { Button } from '../../../components/Button/Button'
import { getResumedDayOfWeek } from '../../../functions/date'
import { formatPhone, telephoneNoMask, toDate } from '../../../utils/formater'
import styles from './report-management.module.css'
import { Calendar, Badge, Whisper, Popover } from 'rsuite'
import './styles.css';
import './calendar.css';
import pt_BR from 'rsuite/locales/pt_BR';
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import useAuth from '../../../hooks/useAuth'

type BookingResponseType = {
  id: string;
  date: string;
  bookingHour: { hour: string };
  people: number;
  name: string;
  phone: string;
  finalized: null | 'confirmed' | 'canceled' | 'noShow';
  bookingConfigId: string;
}

type BookingType = {
  id: string;
  date: string;
  hour: string;
  people: number;
  name: string;
  phone: string;
  finalized: null | 'confirmed' | 'canceled' | 'noShow';
  bookingConfigId: string;
}

type BookingDaysType = {
  quantity: string;
  day: string;
}

const BookingReportManagementContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [today, setToday] = useState('')
  const [tomorrow, setTomorrow] = useState('');
  const [bookingsToday, setBookingsToday] = useState<BookingType[]>([])
  const [bookingsTomorrow, setBookingsTomorrow] = useState<BookingType[]>([])
  const [bookingSelected, setBookingSelected] = useState<BookingType | null>(null);
  const [allBookings, setAllBookings] = useState<BookingDaysType[]>([])
  const [phone, setPhone] = useState('');

  const getBookings = async () => {
    try {
      const res = await GET('/queueReservation/bookingConfig')
      if (res.success && res.data && res.data[0] && res.data[0].id) {
        const dateToday = new Date();
        const dateTomorrow = new Date();
        dateTomorrow.setDate(dateToday.getDate() + 1);
        setToday(getResumedDayOfWeek(dateToday.getDay()))
        setTomorrow(getResumedDayOfWeek(dateTomorrow.getDay()))
        const bookingTodayRes = await POST('/queueReservation/booking/filter', {
          date: dateToday.toISOString().split("T")[0],
          bookingConfigId: res.data[0].id
        })
        if (bookingTodayRes && bookingTodayRes.data) {
          setBookingsToday(bookingTodayRes.data.map((booking: BookingResponseType) => {
            return {
              id: booking.id,
              name: booking.name,
              date: booking.date,
              hour: booking.bookingHour.hour,
              people: booking.people,
              phone: formatPhone(booking.phone),
              finalized: booking.finalized,
              bookingConfigId: booking.bookingConfigId
            }
          }))
        }
        const bookingTomorrowRes = await POST('/queueReservation/booking/filter', {
          date: dateTomorrow.toISOString().split("T")[0],
          bookingConfigId: res.data[0].id
        })
        if (bookingTomorrowRes && bookingTomorrowRes.data) {
          setBookingsTomorrow(bookingTomorrowRes.data.map((booking: BookingResponseType) => {
            return {
              id: booking.id,
              name: booking.name,
              date: booking.date,
              hour: booking.bookingHour.hour,
              people: booking.people,
              phone: formatPhone(booking.phone),
              finalized: booking.finalized
            }
          }))
        }
        const allBookingsRes = await GET(`/queueReservation/booking/days/${res.data[0].id}`)
        if (allBookingsRes && allBookingsRes.data) {
          setAllBookings(allBookingsRes.data)
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Não foi possível encontrar configuração de reserva',
        }).then(() => navigate('/booking/config'))
      }

    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao encontrar reservas, verifique as configurações.',
      }).then(() => navigate('/booking/config'))
    }
  }

  const cancelBooking = async () => {
    if (bookingSelected) {
      const booking = Object.assign(bookingSelected);
      setBookingSelected(null);
      try {
        setLoading(true);
        const res = await PUT(`/queueReservation/booking/${booking.id}`, { finalized: 'canceled', bookingConfigId: booking.bookingConfigId })
        setLoading(false)
        if (res && res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Reserva cancelada!',
          }).then(async () => await init())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Falha ao alterar reserva',
          }).then(async () => await init())
        }
      } catch (e: any) {
        setLoading(false);
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: e ? e : 'Falha ao alterar reserva',
        }).then(async () => await init())
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao alterar reserva',
      }).then(async () => await init())
    }
  }

  const getTodoList = (date: Date) => {
    const dateItem = date.toISOString().split("T")[0];
    if (allBookings.length > 0) {
      let returnItem: any[] = []
      allBookings.forEach((booking) => {
        if (booking.day === dateItem) {
          returnItem = [{ title: `${booking.quantity} reservas` }]
        }
      })
      return returnItem
    } else {
      return []
    }
  }

  const renderCell = (date: any) => {
    const list: any[] = getTodoList(date);

    if (list.length) {

      return (
        <ul className="calendar-todo-list">
          {list.map((item, index) => (
            <li key={index}>
              <p style={{ color: '#3C9EE5', fontSize: 12, textAlign: 'center' }}>{item.title}</p>
            </li>
          ))}

        </ul>
      );
    }
    return null;
  }

  const selectTime = async (time: string, date?: Date) => {
    switch (time) {
      case 'day':
        if (date) {
          const searchObjDay = {
            initialDate: date.toISOString().split("T")[0] + ' 00:00:00',
            finalDate: date.toISOString().split("T")[0] + ' 23:59:59',
            phone: telephoneNoMask(phone)
          }
          navigate(window.btoa(JSON.stringify(searchObjDay)))
        }
        break;
      case 'week':
        const nowDate = new Date();
        const dayWeek = nowDate.getDay();
        const initialDateWeek = new Date();
        initialDateWeek.setDate(nowDate.getDate() - dayWeek);
        const finalDateWeek = new Date();
        finalDateWeek.setDate(nowDate.getDate() + (6 - dayWeek));
        setStartDate(initialDateWeek.toISOString().split("T")[0]);
        setFinalDate(finalDateWeek.toISOString().split("T")[0]);
        const searchObj = {
          initialDate: initialDateWeek.toISOString().split("T")[0],
          finalDate: finalDateWeek.toISOString().split("T")[0],
          phone: telephoneNoMask(phone)
        }
        navigate(window.btoa(JSON.stringify(searchObj)))
        break;
      case 'month':
        const nowMonth = (new Date().getMonth()) + 1;
        const nowYear = new Date().getFullYear();
        const initialDateMonth = `${nowYear}-${nowMonth < 10 ? `0${nowMonth}` : nowMonth}-01`;
        let finalDateMonth = `${nowYear}-${nowMonth + 1 < 10 ? `0${nowMonth + 1}` : nowMonth + 1}-01`;
        if (nowMonth >= 12) {
          finalDateMonth = `${nowYear + 1}-01-01`;
        }
        setStartDate(initialDateMonth);
        setFinalDate(finalDateMonth);
        const searchObjMonth = {
          initialDate: initialDateMonth,
          finalDate: finalDateMonth,
          phone: telephoneNoMask(phone)
        }
        navigate(window.btoa(JSON.stringify(searchObjMonth)))
        break;
      case 'year':
        const year = new Date().getFullYear()
        const initialDateYear = `${year}-01-01`;
        const finalDateYear = `${year + 1}-01-01`;
        setStartDate(initialDateYear);
        setFinalDate(finalDateYear);
        const searchObjYear = {
          initialDate: initialDateYear,
          finalDate: finalDateYear,
          phone: telephoneNoMask(phone)
        }
        navigate(window.btoa(JSON.stringify(searchObjYear)))
        break;
      default:
        await init();
    }
  }

  const init = async () => {
    setLoading(true)
    await getBookings();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='d-flex flex-row gap-5 flex-wrap '>
          <div className="card w-100 d-flex flex-column">
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`booking_report_management.management`)}</span>
                <BreadCrumbs />
              </h3>

            </div>
            <div className="d-flex flex-row justify-content-between align-items-center px-4 py-2" style={{ borderBottom: '1px solid #B5B5C3' }}>
              <p style={{ color: '#434349', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report.time`)}</p>
              <div className="d-flex flex-row gap-6 flex-wrap">
                <Button
                  color='primary'
                  text={i18n.t(`booking.week`)}
                  click={() => selectTime('week')}
                  btnClass='h-45px mt-6'
                />
                <Button
                  color='primary'
                  text={i18n.t(`booking.month`)}
                  click={() => selectTime('month')}
                  btnClass='h-45px mt-6'
                />
                <Button
                  color='primary'
                  text={i18n.t(`booking.year`)}
                  click={() => selectTime('year')}
                  btnClass='h-45px mt-6'
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center px-4 py-2">
              <p style={{ color: '#434349', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report_management.search`)}</p>
              <div className="d-flex flex-row gap-6 flex-wrap">
                <div className="d-flex flex-row gap-2 align-items-center mb-6">
                  <p className='mt-6' style={{ color: '#71718B', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report_management.phone`)}</p>
                  <Input
                    inputAttr={{ type: 'tel' }}
                    value={phone}
                    change={setPhone}
                  />
                </div>
                <div className="d-flex flex-row gap-2 align-items-center mb-6">
                  <p className='mt-6' style={{ color: '#71718B', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report_management.date_start`)}</p>
                  <Input
                    inputAttr={{ type: 'date' }}
                    value={startDate}
                    change={setStartDate}
                    maxLength={finalDate}
                  />
                </div>
                <div className="d-flex flex-row gap-2 align-items-center mb-6">
                  <p className='mt-6' style={{ color: '#71718B', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report_management.date_end`)}</p>
                  <Input
                    inputAttr={{ type: 'date' }}
                    value={finalDate}
                    change={setFinalDate}
                    minLength={startDate}
                  />
                </div>
                <Button
                  color='primary'
                  text={i18n.t(`buttons.search`)}
                  click={() => {
                    const search = window.btoa(JSON.stringify({ initialDate: startDate, finalDate, phone: telephoneNoMask(phone) }))
                    navigate(`${search}`)
                  }}
                  btnClass='h-45px mt-6'
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-row gap-5 w-100">
            <div className='card' style={{ flex: 1 }}>
              <div className='card-body d-flex flex-column gap-5'>
                <h2 style={{ fontWeight: 400, fontSize: 16, color: '#2A2D36' }}>{i18n.t(`booking_report_management.today`)} - {i18n.t(`week_day.${today}`)}</h2>
                <div className={`d-flex flex-column gap-2 p-2 ${styles.bookings}`} style={{ border: '1px solid #EFF0FA', borderRadius: 4 }}>
                  {
                    bookingsToday.map((booking) => (
                      <div className={styles.bookingItem}>
                        <p className={styles.bookingText}>{booking.hour}</p>
                        <p className={styles.bookingText}>{booking.people} {i18n.t(`booking_report_management.people`)}</p>
                        <p className={styles.bookingText}>{booking.name}</p>
                        <p className={styles.bookingText}>{booking.phone}</p>
                        <div className={booking.finalized ? styles[booking.finalized] : styles.open}>{booking.finalized ? i18n.t(`booking_report_management.${booking.finalized}`) : i18n.t(`booking_report_management.open`)}</div>
                        <button
                          onClick={() => {
                            if (booking.finalized !== 'canceled') {
                              setBookingSelected(booking)
                              showWrapperModal(i18n.t(`booking_day.cancel_title`))
                            } else {
                              Swal.fire({
                                icon: 'warning',
                                title: 'Reserva já cancelada!',
                              })
                            }
                          }}
                          style={{ borderRadius: '4px', backgroundColor: '#5D8ED8', width: 30, height: 30, border: 'none' }}
                        >
                          <i style={{ color: '#FFF' }} className="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                    ))
                  }
                </div>
                <div className="d-flex flex-row justify-content-end">
                  <p
                    onClick={() => {
                      const search = window.btoa(JSON.stringify({
                        initialDate: `${new Date().toISOString().split("T")[0]} 00:00:00`,
                        finalDate: `${new Date().toISOString().split("T")[0]} 23:59:59`
                      }))
                      navigate(`${search}`)
                    }}
                    className={styles.linkBooking}
                  >
                    {i18n.t(`booking_report_management.see_all`)}
                  </p>
                </div>
              </div>
            </div>
            <div className='card' style={{ flex: 1 }}>
              <div className='card-body d-flex flex-column gap-5'>
                <h2 style={{ fontWeight: 400, fontSize: 16, color: '#2A2D36' }}>{i18n.t(`booking_report_management.tomorrow`)} - {i18n.t(`week_day.${tomorrow}`)}</h2>
                <div className={`d-flex flex-column gap-2 p-2 ${styles.bookings}`} style={{ border: '1px solid #EFF0FA', borderRadius: 4 }}>
                  {
                    bookingsTomorrow.map((booking) => (
                      <div className={styles.bookingItem}>
                        <p className={styles.bookingText}>{booking.hour}</p>
                        <p className={styles.bookingText}>{booking.people} {i18n.t(`booking_report_management.people`)}</p>
                        <p className={styles.bookingText}>{booking.name}</p>
                        <p className={styles.bookingText}>{booking.phone}</p>
                        <div className={booking.finalized ? styles[booking.finalized] : styles.open}>{booking.finalized ? i18n.t(`booking_report_management.${booking.finalized}`) : i18n.t(`booking_report_management.open`)}</div>
                        <button
                          onClick={() => {
                            if (booking.finalized !== 'canceled') {
                              setBookingSelected(booking)
                              showWrapperModal(i18n.t(`booking_day.cancel_title`))
                            } else {
                              Swal.fire({
                                icon: 'warning',
                                title: 'Reserva já cancelada!',
                              })
                            }
                          }}
                          style={{ borderRadius: '4px', backgroundColor: '#5D8ED8', width: 30, height: 30, border: 'none' }}
                        >
                          <i style={{ color: '#FFF' }} className="fa fa-times" aria-hidden="true"></i>
                        </button>
                      </div>
                    ))
                  }
                </div>
                <div className="d-flex flex-row justify-content-end">
                  <p
                    onClick={() => {
                      const dateToday = new Date();
                      const dateTomorrow = new Date();
                      dateTomorrow.setDate(dateToday.getDate() + 1);
                      const search = window.btoa(JSON.stringify({
                        initialDate: `${dateTomorrow.toISOString().split("T")[0]} 00:00:00`,
                        finalDate: `${dateTomorrow.toISOString().split("T")[0]} 23:59:59`
                      }))
                      navigate(`${search}`)
                    }}
                    className={styles.linkBooking}
                  >
                    {i18n.t(`booking_report_management.see_all`)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row w-100">
            <div className="card w-100">
              <div className='card-body d-flex flex-column gap-5'>
                <Calendar
                  compact
                  renderCell={renderCell}
                  onSelect={(e) => selectTime('day', e)}
                />
              </div>
            </div>
          </div>
          <ModalWrapper
            confirmAction={() => cancelBooking()}
            textAction={i18n.t(`booking_day.cancel_button`)}
          >
            {bookingSelected && (
              <div
                className="d-flex flex-row px-4 py-6 align-items-center"
                style={{ border: '1px solid #D3D9E8', borderLeft: '4px solid #D3D9E8', borderRadius: 4 }}
              >
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row gap-2">
                    <b style={{ color: '#2A2D36', margin: 0 }}>{i18n.t(`booking_day.date`)}</b>
                    <p style={{ color: '#6E7586', margin: 0 }}>{toDate(bookingSelected.date)} {bookingSelected.hour}</p>
                  </div>
                  <b style={{ color: '#2A2D36', marginRight: 0 }}>{bookingSelected.people} {i18n.t(`booking_day.people`)}</b>
                  <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{bookingSelected.name}</b></p>
                  <p style={{ color: '#6E7586', margin: 0 }}>{bookingSelected.phone}</p>
                </div>
              </div>
            )}
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const BookingReportManagement: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`booking_report_management.management`), path: 'booking/management', isActive: true },
        ]}
      >
        {i18n.t(`booking_report_management.management`)}
      </PageTitle>
      <BookingReportManagementContent />
    </>
  )
}

export { BookingReportManagement }
