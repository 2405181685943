import {useState} from 'react'
import {Link} from 'react-router-dom'
import {Input} from '../../../../components/Input/Input'
import {isEmail, isEmpty} from '../../../../utils/validate'
import { POST } from '../../../../services/api'
import styles from './Login.module.css'


export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [invalidText, setInvalidText] = useState<string>('')

  const handleResetPassword = async() => {

    const validate = isEmpty({email})
    const emailValidate = isEmail(email)

    if(validate && emailValidate)
    {
      try {
        setLoading(true)
        setIsSubmitting(true)
         
        const response = await POST('email/resetPassword',{email:email})

        if(response.success)
        {
          setHasErrors(false)
        }else{
          setInvalidText(response.message)
          setIsInvalid(true)
        }

        setIsSubmitting(false)
        setLoading(false)
    } catch (error) {
      setHasErrors(true)
      setLoading(false)
    }
    }else{
      let text = ''
      if (!validate) text = 'Todos os campos devem estar preenchidos!'
      else if (!emailValidate) text = 'Por favor, digite um email válido!'
      setInvalidText(text)
      setIsInvalid(true)
    }

  }

  return (
    <section className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <div className='text-center mb-10'>
        <h1 className='text-gray-800 mb-3 fw-bold'>Recuperar sua senha</h1>

        <div className='text-gray-400 fw-bold fs-6'>
          Informe seu email e você receberá um link para restaurar sua senha
        </div>
      </div>
      {isInvalid && (
        <div className='mb-lg-15 alert alert-danger relative'>
          <i
            className={`bi bi-x-lg cursor-pointer text-danger ${styles.iconClose}`}
            onClick={() => setIsInvalid(false)}
          ></i>
          <div className='alert-text font-weight-bold'>
            <i className='bi bi-exclamation-circle fs-4 text-danger me-3'></i>
            {invalidText}
          </div>
        </div>
      )}

      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            Desculpe, alguns erros foram detectados. Por favor, tente novamente!
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>
            Redefinição de senha enviada. Por favor verifique seu email, e também a sua caixa de span!
          </div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <Input
          inputAttr={{
            type: 'email',
            name: 'email',
            autoComplete: 'off',
          }}
          label='E-mail'
          placeholder='E-mail'
          value={email}
          change={setEmail}
        />
      </div>

      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='button'
          className='btn btn-lg btn-primary fw-bolder me-4'
          onClick={() => handleResetPassword()}
        >
          <span className='indicator-label'>Enviar</span>
          {loading && (
            <span className='indicator-progress'>
              Por favor, espere...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-lg btn-light-primary fw-bolder'
            disabled={isSubmitting}
          >
            Cancelar
          </button>
        </Link>{' '}
      </div>
    </section>
  )
}
