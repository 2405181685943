import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import useModals from '../../../../hooks/useModals'
import {DELETE, GET, POST, PUT} from '../../../../services/api'
import {Loading} from '../../../../components/Loading/Loading'
import {ModalDelete} from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type GridResponseType = {
  id: number;
  name: string;
  status: boolean;
}

const GradesContent: FC = () => {
  const navigate = useNavigate()
  const {showDeleteModal} = useModals()
  const [loading, setLoading] = useState(false)

  const [grids, setGrids] = useState<GridResponseType[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const getGrids = async () => {
    try {
      setLoading(true)
      const res = await GET(`/grid`)
      const news = res.data.map((row: GridResponseType) => {
        return {
          id: row.id,
          name: row.name,
          status: row.status ? 'ativo' : 'inativo',
        }
      })
      setGrids(news)
      setMeta(res.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteGrid = async () => {
    try {
      setLoading(true)
      const gridRes = await DELETE(`/grid/${id}`)
      if (gridRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Grade deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getGrids())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a grade!',
        }).then(async () => await getGrids())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a grade!',
      }).then(async () => await getGrids())
      console.log(e)
    }
  }

  useEffect(() => {
    getGrids()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`smart_order.grid_products`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`smart_order.listened_grid`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_grade`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/grid/criar')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={grids}
                headers={[
                  {key: i18n.t(`smart_order.name`), value: 'name', type: 'default'},
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'badge',
                    badge: {
                      ativo: 'success',
                      inativo: 'danger',
                    },
                  },
                  {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => { navigate(`/grid/editar/${item.id}`) },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.grid`)}`,
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`smart_order.grid`)}`)
                      setId(item.id)
                    },
                    title: `${i18n.t(`general.delete`)} ${i18n.t(`smart_order.grid`)}`,
                  },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalDelete confirmAction={() => deleteGrid()} />
        </div>
      )}
    </>
  )
}

const Grades: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`smart_order.grid_products`), path: 'grid', isActive: true},
        ]}
      >
        {i18n.t(`smart_order.grid_products`)}
      </PageTitle>
      <GradesContent />
    </>
  )
}

export {Grades}
