import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { KTSVG } from '../../../../../_metronic/helpers/components/KTSVG'
import useAuth from '../../../../hooks/useAuth'
import styles from './SidebarPerfil.module.css'

const SidebarPerfil: FC = () => {
  const navigate = useNavigate()
  const { loginInfo, logout, verifyAdm } = useAuth()

  const handleLogout = () => {
    logout()
    navigate('/auth/login')
  }

  const handleProfile = () => { }

  return (
    <div
      id='kt_sidebar_perfil'
      className='bg-white'
      data-kt-drawer='true'
      data-kt-drawer-name='sidebarPerfil'
      data-kt-drawer-activate='true'
      data-kt-drawer-width="{default:'300px', 'md': '375px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_sidebar_perfil_toggle'
      data-kt-drawer-close='#kt_sidebar_perfil_close'
    >
      <div className='card w-100 rounded-0' id='kt_sidebar_perfil_messenger'>
        <div className='p-10 border-bottom' id='kt_sidebar_perfil_messenger_header'>
          <div className='d-flex align-items-center justify-content-between w-100 me-3 fs-2 fw-bolder text-gray-700 mb-2 lh-1'>
            <p className='m-0'>Configurações</p>
            <div
              className='card-toolbar btn btn-sm btn-icon btn-active-light-primary'
              id='kt_sidebar_perfil_close'
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          </div>
          <div className='d-flex mt-5'>
            <div className='d-flex flex-column'>
              <span className='fw-bold fs-5 text-gray-800 text-hover-primary'>
                {loginInfo.pluginspace} -  {loginInfo.pluginspaceId}
              </span>
              <div className='navi mt-0'>
                <a href='#' className='navi-item'>
                  <span className='navi-link p-0 pb-2'>
                    <span className='navi-text text-muted text-hover-primary fs-6'>
                      {loginInfo.email}
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='navi navi-spacer-x-0 py-3 px-10'>
          <div
            onClick={() => handleProfile()}
            className='navi-item text-hover-primary'
            role='button'
          >
            <div className='navi-link d-flex align-items-center my-5'>
              <div className='fw-bold text-primary' onClick={() => navigate(`/configuracoes/conta`)}>Minha Conta</div>
            </div>
          </div>
          {verifyAdm() &&
            <div
              onClick={() => navigate(`/icon`)}
              className='navi-item text-hover-primary'
              role='button'
            >
              <div className='navi-link d-flex align-items-center my-5'>
                <div className='fw-bold text-primary'>Biblioteca de Icones</div>
              </div>
            </div>
          }


          <div
            onClick={() => handleLogout()}
            className='navi-item text-hover-primary'
            role='button'
          >
            <div className='navi-link d-flex align-items-center my-5'>
              <div className='fw-bold text-primary'>Sair</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { SidebarPerfil }
