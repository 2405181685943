/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import useModals from '../../../../hooks/useModals'
import { useNavigate } from 'react-router-dom'
import api, { DELETE, GET, POST, PUT } from '../../../../services/api'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type InstallmentType = {
  installment: number;
  code: string;
}

type PlansType = {
  id: number;
  gateway: string;
  brand: string;
  type: string;
  code: string;
  installments?: InstallmentType[]
}

type PlansResponseType = {
  id: number;
  codePlan: number;
  installment: number;
}

type IntegrationResponseType = {
  id: number;
  brand: string;
  cardOperator: number;
  codePay: string;
  nameGateway: string;
  paymentPlans: PlansResponseType[];
  typePay: string;
}

const PlanosPagamentosContent: FC = () => {
  const {showDeleteModal} = useModals()

  const navigate = useNavigate()
  const {loginInfo} = useAuth();
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState<Array<PlansType>>([]);
  const [id, setId] = useState<number | null>(null);

  const getPlans = async () => {
    try {
      setLoading(true);
      const resPluginspace = await GET(`/pluginspace/this`);
      if(resPluginspace.data && resPluginspace.data[0] && resPluginspace.data[0].hash){
        const resIntegration = await POST(`orders/card-payment-get`, {hash: resPluginspace.data[0].hash});
        const newListPlans: PlansType[] = resIntegration.data.data.map((integrationResponse: IntegrationResponseType) => ({
          id: integrationResponse.id,
          gateway: integrationResponse.nameGateway,
          brand: integrationResponse.brand,
          type: integrationResponse.typePay,
          code: integrationResponse.codePay,
          installments: integrationResponse.paymentPlans.map((planResponse) => ({
            installment: planResponse.installment,
            code: planResponse.codePlan
          }))
        }))
        setPlans(newListPlans);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const deletePlans = async () => {
    try{
      setLoading(true);
      const resPluginspace = await GET(`/pluginspace/this`);
      if(resPluginspace.data && resPluginspace.data[0] && resPluginspace.data[0].hash){
        const resIntegration = await PUT(`orders/card-payment/${id}`, {hash: resPluginspace.data[0].hash, data: {deleted: new Date()}});
        
        if(resIntegration.data.success){
          Swal.fire({
            icon: 'success',
            title: 'Integração deletada',
            text: 'Deleção realizada com sucesso!'
          }).then(async () => await getPlans())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao deletar integração!'
          }).then(async () => await getPlans())
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar integração!'
        }).then(async () => await getPlans())
      }
      setLoading(false);
    } catch(e:any) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Erro ao deletar integração!'
      }).then(async () => await getPlans())
      console.log(e)
    }
  }

  useEffect(() => {
    getPlans();
  }, [])
  return (
    <>
    { loading ? <Loading /> :
      <div className='row g-5 gx-xxl-12'>
        <div className='card card-xxl-stretch mb-5 mb-xl-12'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`payment_plans.payment_plans`)}</span>
              <BreadCrumbs />
              <span className='text-muted mt-1 fw-bold fs-7'>
                {i18n.t(`payment_plans.here_are_listed_the_payment_plans_along_with_their_respective_actions`)}
              </span>
            </h3>
            <div className='card-toolbar'>
              <Button
                text={i18n.t(`buttons.add_integration`)}
                color='primary'
                iconColor='#FFF'
                icon='fas fa-plus'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                  type: 'button',
                }}
                click={() => navigate('/configuracoes/planos-pagamento/criar')}
                btnClass='fw-bolder'
              />
            </div>
          </div>
          <div className='card-body'>
            <Datatable
              data={plans}
              extendData={{header: ['Parcela', 'Código ERP'], value: 'installments', type: 'extend'}}
              headers={[
                {key: 'Gateway', value: 'gateway', type: 'default'},
                {key: i18n.t(`payment_plans.flag`), value: 'brand', type: 'default'},
                {key: i18n.t(`payment_plans.type`), value: 'type', type: 'default'},
                {key: i18n.t(`payment_plans.erp_code`), value: 'code', type: 'default'},
                {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
              ]}
              options={[
                {
                  icon: 'fas fa-pen',
                  action: (item) => {
                    navigate(`/configuracoes/planos-pagamento/editar/${item.id}`)
                  },
                  title: `${i18n.t(`general.edit`)} ${i18n.t(`payment_plans.payment_plans`)}`,
                },
                {icon: 'fas fa-trash', action: (item) => {
                  showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`payment_plans.payment_plans`)}`)
                  setId(item.id);
                }, title: `${i18n.t(`general.delete`)} ${i18n.t(`payment_plans.payment_plans`)}`},
              ]}
              color='primary'
            />
          </div>
        </div>
        <ModalDelete confirmAction={() => deletePlans()} /> 
      </div>}
    </>
  )
}

const PlanosPagamentos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`payment_plans.payment_plans`), path: 'configuracoes/planos-pagamento', isActive: true},
        ]}
      >
        {i18n.t(`payment_plans.payment_plans`)}
      </PageTitle>
      <PlanosPagamentosContent />
    </>
  )
}

export {PlanosPagamentos}
