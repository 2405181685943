import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {DecodedTokenData} from '../../../../context/authContext'
import useAuth from '../../../../hooks/useAuth'
import {POST, setBearerToken, setBearerTokenAdm} from '../../../../services/api'
import { parseJwt } from '../../../../utils/utils'
import {isEmail, isEmpty} from '../../../../utils/validate'
import styles from './Login.module.css'

export function Login() {
  const navigate = useNavigate()
  const {setLoginInfo} = useAuth()

  const [loading, setLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isInvalid, setIsInvalid] = useState<boolean>(false)
  const [invalidText, setInvalidText] = useState<string>('')

  const handleLogin = async () => {
    const validate = isEmpty({email, password})
    const emailValidate = isEmail(email)
    if (validate && emailValidate) {
      setLoading(true)
      setIsSubmitting(true)
      try {
        const response = await POST('/token/login', {email, password})
        setBearerToken(response.accessToken)
        setBearerTokenAdm(response.accessToken)
        localStorage.setItem('loginInfo_ninegrid_adm', JSON.stringify(response))
        if (process.env.REACT_APP_JWT_SALT && response) {
          const tkNoSalt = response.accessToken.split(process.env.REACT_APP_JWT_SALT).join('')
          const tk: DecodedTokenData | null = parseJwt(tkNoSalt)
          if (tk) {
            setLoginInfo({
              accessToken: response.accessToken,
              expiratedAt: response.expiratedAt,
              name: tk.name,
              email: tk.email,
              plugintype: tk.plugintype,
              pluginspaceId: tk.pluginspaceId,
              pluginspace: tk.pluginspace,
              profile: tk.profile,
            })
            navigate('/dashboard')
          }
        }
      } catch (e) {
        console.log('Error', e)
        let text = 'Ocorreu um erro durante o login. Tente novamente!'
        if (e === 'User not found.') text = 'Usuário não encontrado!'
        setInvalidText(text)
        setIsInvalid(true)
      }
      setIsSubmitting(false)
      setLoading(false)
    } else {
      let text = ''
      if (!validate) text = 'Todos os campos devem estar preenchidos!'
      else if (!emailValidate) text = 'Por favor, digite um email válido!'
      setInvalidText(text)
      setIsInvalid(true)
    }
  }

  function loginByEnter(event:any){
    if(event === 'Enter'){
      handleLogin();
    }
  }

  return (
    <section className='form w-100'>
      {isInvalid && (
        <div className='mb-lg-15 alert alert-danger relative'>
          <i
            className={`bi bi-x-lg cursor-pointer text-danger ${styles.iconClose}`}
            onClick={() => setIsInvalid(false)}
          ></i>
          <div className='alert-text font-weight-bold'>
            <i className='bi bi-exclamation-circle fs-4 text-danger me-3'></i>
            {invalidText}
          </div>
        </div>
      )}

      <div className='fv-row mb-10'>
        <Input
          inputAttr={{
            type: 'email',
          }}
          label='E-mail'
          placeholder='E-mail'
          value={email}
          change={setEmail}
        />
      </div>

      <div className='fv-row mb-10'>
        <Input
          inputAttr={{
            type: 'password',
          }}
          label='Senha'
          placeholder='Senha'
          value={password}
          change={setPassword}
          onKeyDown={(event)=>loginByEnter(event)}
          autoComplete='new-password'
        />

        <Input
          inputAttr={{
            type: 'checkbox',
          }}
          label='Permanecer conectado'
        />
      </div>

      <div className='d-flex justify-content-between align-items-center'>
        <span
          className='text-gray-600 text-hover-primary my-3 mr-2'
          role='button'
          onClick={() => navigate('/auth/esqueceu-senha')}
        >
          Esqueceu sua senha?
        </span>

        <div className='text-center text-md-end'>
          <Button
            loading={loading}
            disabled={isSubmitting}
            color='primary'
            text='Entrar'
            btnAttr={{
              type: 'button',
            }}
            click={() => handleLogin()}
            btnClass='w-auto'
          />
        </div>
      </div>
    </section>
  )
}
