import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { getPlugintypeIdByCode } from '../../../../functions/plugintype'
import useAuth from '../../../../hooks/useAuth'
import { POST } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type TemplateResponseType = {
  id: number;
  name: string;
  parameters: any;
  plugintypeId: number;
  typeIntegration: 'payment_method' | 'pdv' | 'messenger' | 'others';
}

type ConfigInputsType = {
  name: string;
  label: string;
}

const CriarPdvContent: FC = () => {
  const navigate = useNavigate()
  const { loginInfo } = useAuth()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const [templates, setTemplates] = useState<TemplateResponseType[]>([])
  const [configInputs, setConfigInputs] = useState<(ConfigInputsType | undefined)[]>([])
  const [templateId, setTemplateId] = useState<number | null>()

  const getTemplates = async () => {
    try {
      setLoading(true)
      let templateRes = await POST('/plugintemplate/filter?take=1000', {})
      const newTemplates: TemplateResponseType[] = [
        { id: 0, name: 'Selecione um template', parameters: null, plugintypeId: 0, typeIntegration: 'others' },
      ]
      const plugincode = getPlugintypeIdByCode(loginInfo.plugintype);
      templateRes.data.forEach((template: TemplateResponseType) => {
        if ((template.plugintypeId === 1 || template.plugintypeId === plugincode) && template.typeIntegration === 'pdv')
          newTemplates.push({
            id: template.id,
            name: template.name,
            plugintypeId: template.plugintypeId,
            typeIntegration: template.typeIntegration,
            parameters: JSON.parse(template.parameters),
          })
      })
      setTemplates(newTemplates)
      setLoading(false)
      console.log(templateRes)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const changeTemplate = (selectedId: number) => {
    setTemplateId(selectedId)
    if (selectedId !== 0) {
      const template = templates.find((templateFinded) => templateFinded.id === selectedId)
      let newInputs: (ConfigInputsType | undefined)[] = Object.entries(
        template?.parameters.config
      ).map((configInput) => {
        if (
          configInput[0] !== undefined &&
          configInput[1] !== undefined &&
          typeof configInput[1] === 'string'
        ) {
          const input: ConfigInputsType = {
            name: configInput[0],
            label: configInput[1],
          }
          return input
        }
      })
      if (loginInfo.plugintype === 'food' && selectedId === 101) {
        newInputs = newInputs.filter((input: any) => input.name !== 'type' ? true : false)
      }

      setConfigInputs(newInputs)
    }
  }

  const createIntegration = async () => {
    if (templateId) {
      const template = templates.find((templateFinded) => templateFinded.id === templateId)
      let integrationContent: any = {}
      Object.entries(template?.parameters.config).map((configInput) => {
        const element: any = document.getElementsByName(configInput[0])[0]

        if (loginInfo.plugintype === 'food' && templateId === 101 && configInput[0] === 'type') {
          integrationContent[configInput[0]] = '1'
        } else {
          integrationContent[configInput[0]] = element.value
        }
      })
      const pluginType = template?.plugintypeId

      try {
        if (!name || !templateId || !pluginType) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!',
          })
        } else {
          setLoading(true)
          const integrationRes = await POST('/pluginconfig', {
            plugintypeId: (template && template.plugintypeId) ? template.plugintypeId : getPlugintypeIdByCode(loginInfo.plugintype),
            plugintemplateId: templateId,
            name: name,
            content: integrationContent,
          })
          setLoading(false)
          if (integrationRes.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Integração cadastrada',
              text: 'Cadastro realizado com sucesso!',
            }).then(() => navigate('/pluginconfig/pdv'))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao cadastrar integração!',
            }).then(() => navigate('/pluginconfig/pdv'))
          }
        }
      } catch (e) {
        setLoading(false)
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao cadastrar integração!',
        }).then(() => navigate('/pluginconfig/pdv'))
      }
    }
  }

  useEffect(() => {
    getTemplates()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`buttons.create`)} ${i18n.t(`integration.PDV`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'text',
                    }}
                    placeholder={i18n.t(`integration.integration_name`)}
                    label={i18n.t(`integration.integration_name`)}
                    change={(value: string) => setName(value)}
                    value={name}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`integration.type_of_integration`)}
                    change={(value: string) => {
                      changeTemplate(parseInt(value))
                    }}
                    options={templates.map((template) => ({
                      value: template.id,
                      select: template.name,
                    }))}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                {configInputs.map((config) => (
                  <div style={{ flex: '40%' }} key={config?.name}>
                    <Input
                      inputAttr={{
                        type: 'text',
                      }}
                      placeholder={config?.label}
                      label={config?.label}
                      name={config?.name}
                      change={() => { }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => createIntegration()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CriarPdv: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`integration.PDV`), path: 'pluginconfig/pdv', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'pluginconfig/pdv/create', isActive: true },
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`integration.PDV`)}`}
      </PageTitle>
      <CriarPdvContent />
    </>
  )
}

export { CriarPdv }
