/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const PageContent: FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [order, setOrder] = useState<number>(0)
  const [alloworder, setAlloworder] = useState<number>(0)
  const [status, setStatus] = useState<boolean>(false)
  const [image, setImage] = useState<File | null>(null)

  const [hours, setHours] = useState<any>([
    {
      status: false, // Domingo
      day: 0,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
    {
      status: false, // Segunda
      day: 1,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
    {
      status: false,
      day: 2,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
    {
      status: false,
      day: 3,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
    {
      status: false,
      day: 4,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
    {
      status: false,
      day: 5,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
    {
      status: false, // Sábado
      day: 6,
      hour1: '',
      hour2: '',
      hour3: '',
      hour4: '',
    },
  ])

  const handleHours = (idx: number, hour: number, value: any) => {
    const newHours = hours
    newHours.forEach(function (e: {
      day: number
      hour1: any
      hour2: any
      hour3: any
      hour4: any
      status: any
    }) {
      if (e.day === idx) {
        if (hour === 1) e.hour1 = value
        if (hour === 2) e.hour2 = value
        if (hour === 3) e.hour3 = value
        if (hour === 4) e.hour4 = value
      }
      if (hour === 0) if (e.day === idx) e.status = value
    })
    setHours(newHours)
  }

  const create = async () => {
    try {
      if (name === '' || code === '' || description === '' || content === '') {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      }

      setLoading(true)
      const data = {
        type: 'internal',
        name: name,
        code: code,
        content: content,
        description: description,
        alloworder: alloworder,
        status: status,
      }
      const res = await POST('/location', data)
      console.log('res', data, res)
      if (res.success === true) {
        console.log('Sucesso', res.dados.id)
        let imageRes = {
          success: false,
          image: '',
        }
        if (image) {
          const nameImg = `hotel-location-${uuid()}.jpg`
          const formData = new FormData()
          formData.append('file', image)
          formData.append('nameImage', nameImg)
          imageRes = await POST('/upload-images/upload-one', formData)
          if (imageRes.success === true) {
            const data = {
              img: nameImg,
            }
            await PUT(`/location/${res.dados.id}`, data)
          }
        }
      }

      // Cadastra os horários
      const newHours = hours.map(
        (row: { day: any; hour1: any; hour2: any; hour3: any; hour4: any; status: any }) => {
          return {
            locationId: res.dados.id,
            weekday: row.day,
            timeStart: row.hour1 === '' ? '00:00:00' : row.hour1,
            timeEnd: row.hour2 === '' ? '00:00:00' : row.hour2,
            timeStart2: row.hour3 === '' ? '00:00:00' : row.hour3,
            timeEnd2: row.hour4 === '' ? '00:00:00' : row.hour4,
            status: row.status,
          }
        }
      )

      await POST('location-operation/multiple', { data: newHours })

      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Setor criado',
        text: 'O setor e seus horários foram criados com sucesso!',
      })
      navigate('/hospitality/configuracoes/setores')
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o setor!',
      })
    }
  }
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)

        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    load()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-content-between'>
            <div className='w-50 me-5'>
              <div className='card pb-5 mb-10'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Dados do Setor</span>
                    <BreadCrumbs />
                  </h3>
                </div>

                <div className='px-10 py-5'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Nome'
                        label='Nome'
                        value={name}
                        change={(value: string) => setName(value)}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Código'
                        label='Código'
                        value={code}
                        change={(value: string) => setCode(value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <Input
                        inputAttr={{
                          type: 'textarea',
                        }}
                        placeholder='Descrição'
                        label='Descrição'
                        value={description}
                        change={(value: string) => setDescription(value)}
                        rows={5}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Pedidos (Habilitar Carrinho)'
                        options={[
                          { select: 'Ativo', value: 1 },
                          { select: 'Inativo', value: 0 },
                        ]}
                        value={alloworder}
                        change={(value: number) => {
                          setAlloworder(value)
                        }}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Status'
                        options={[
                          { select: 'Ativo', value: true },
                          { select: 'Inativo', value: false },
                        ]}
                        value={status}
                        change={(value: boolean) => {
                          setStatus(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.order`)}
                        label={i18n.t(`smart_order.order`)}
                        smalltext='(para exibição no webapp)'
                        value={order}
                        change={(value: number) => setOrder(value)}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Template'
                        options={[
                          { select: 'Sem template', value: '' },
                          { select: 'Restaurante', value: 'restaurant' },
                          { select: 'Quarto', value: 'room' },
                          { select: 'Piscina', value: 'pool' },
                        ]}
                        value={content}
                        change={(value: any) => {
                          setContent(value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='card pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Imagem do Setor</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  <div className='px-10 py-5'>
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      value={image}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='w-50 ms-5'>
              <div className='card pb-5 mb-10'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Horários de Funcionamento</span>
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <div key='domingo'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(0, 0, checked)}
                          label='Domingo'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(0, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(0, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(0, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(0, 4, value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div key='segunda'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(1, 0, checked)}
                          label='Segunda-Feira'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(1, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(1, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(1, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(1, 4, value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div key='terca'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(2, 0, checked)}
                          label='Terça-Feira'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(2, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(2, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(2, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(2, 4, value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div key='quarta'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(3, 0, checked)}
                          label='Quarta-Feira'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(3, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(3, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(3, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(3, 4, value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div key='quinta'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(4, 0, checked)}
                          label='Quinta-Feira'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(4, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(4, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(4, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(4, 4, value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div key='sexta'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(5, 0, checked)}
                          label='Sexta-Feira'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(5, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(5, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(5, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(5, 4, value)}
                        />
                      </div>
                    </div>
                  </div>

                  <div key='sabado'>
                    <div className='row'>
                      <div className='col-lg-12'>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          change={(checked: any) => handleHours(6, 0, checked)}
                          label='Sábado'
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #01'
                          change={(value: string) => handleHours(6, 1, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #01'
                          change={(value: string) => handleHours(6, 2, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Início #02'
                          change={(value: string) => handleHours(6, 3, value)}
                        />
                      </div>
                      <div className='col-lg-3'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          placeholder=''
                          label='Fim #02'
                          change={(value: string) => handleHours(6, 4, value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              text='Voltar'
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/hospitality/configuracoes/setores')
              }}
              btnClass='fw-bolder me-3'
            />
            <Button
              text='Salvar'
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                create()
                console.log('hours', hours)
              }}
              btnClass='fw-bolder'
            />
          </div>
        </div>
      )}
    </>
  )
}

const HospitalityCriarSetor: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Setores', path: 'hospitality/configuracoes/setores', isActive: true },
          { title: 'Criar', path: '#', isActive: true },
        ]}
      >
        Criar Setor
      </PageTitle>
      <PageContent />
    </>
  )
}

export { HospitalityCriarSetor }
