/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import type { MetaType } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, PUT, POST, DELETE } from '../../../services/api'
import useModals from '../../../hooks/useModals'
import { Loading } from '../../../components/Loading/Loading'
import { Dropdown } from 'react-bootstrap'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../components/Input/Input'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import { i18n } from '../../../translate/i18n'
import { Resources } from '../habilitarRecursos'
import SVG from 'react-inlinesvg';
import { toDate } from '../../../utils/formater'

const Content: FC = () => {
  const navigate = useNavigate()
  const { showDeleteModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState<number | null>(null)
  const [resources, setResources] = useState<Resources[]>([])
  const [posts, setPosts] = useState<any[]>([])

  const [resoucesSelected, setResoucesSelected] = useState<any>()

  async function deletePost() {
    try {
      setLoading(true)
      const response = await DELETE(`/post/${id}`)

      setLoading(false)
      if (!response || !response.success) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao deletar',
          text: 'Falha ao deletar conteudo!'
        }).then(async () => {
          setLoading(true)
          await getList()
          setLoading(false)
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Conteudo deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => {
          setLoading(true)
          await getList()
          setLoading(false)
        })
      }


    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao deletar',
        text: 'Falha ao deletar conteudo!'
      }).then(async () => {
        setLoading(true)
        await getList()
        setLoading(false)
      })
    }
  }

  async function switchStatus(id: any, status: any) {
    setLoading(true)
    try {
        await PUT(`/post/${id}`, {
            status: !status,
        })
        await getList()

        setLoading(false)
    } catch (e) {
        setLoading(false)
        console.log(e)
    }
  }
  async function getList() {
    try {
      const posts = await POST('post/filter')

      setPosts(posts.data.map((el: any) => {
        el.date = `De ${toDate(el.dateInit)} a ${toDate(el.dateFin)}`
        return el;
      }))

    } catch (error) {
      console.log(error)
    }
  }

  async function getFooterResouces() {
    try {

      const res = await POST(`/appitems/filter`)
      const aux = res.data.map((el: Resources) => {
        el.icon = el.svgWebApp.svg
        return el
      })

      setResources(aux)

    } catch (error) {
      console.log(error)
    }
  }


  async function getAllData() {
    try {
      setLoading(true)
      await Promise.all([
        getFooterResouces(),
        getList()
      ])
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    getAllData()
  }, [])



  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5 d-flex w-100 '>
              <div className='row w-100'>
                <h3 className='card-title align-items-start flex-column col'>
                  <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`permissions.footerContent`)}</span>
                  <BreadCrumbs />
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    Aqui você configura os Feeds dos Recursos habilitados no Agregador.
                  </span>
                </h3>
                <div className='col-8'>
                  <div className='d-flex w-100 h-100 align-items-center gap-5'>
                    {resources.map(icon => (
                      <div className={`d-flex flex-column justify-content-center align-items-center `}>
                        <div className='w-100 d-flex align-items-center justify-content-center'>
                          <div className={`p-3 rounded border ${resoucesSelected && resoucesSelected.value == icon.id ? 'border-primary' : 'border-dark'}`} >
                            <SVG src={icon.icon} fill={resoucesSelected && resoucesSelected.value == icon.id ? '#6993ff' : ''} style={{
                              width: '45px',
                              height: '45px'
                            }}
                              onClick={() => {
                                if(resoucesSelected && resoucesSelected.value == icon.id){
                                  
                                  setResoucesSelected({
                                    label: 0,
                                    value: 0
                                  })
                                  return
                                }

                                setResoucesSelected({
                                  label: icon.name,
                                  value: icon.id
                                })
                                
                              }}
                            />
                          </div>
                        </div>
                        <span>{icon.name}</span>
                      </div>
                    ))

                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='card-toolbar d-flex w-100 justify-content-end align-items-end  '>
              <Button
                text='Adicionar Conteúdo'
                color='primary'
                iconColor='#FFF'
                icon='fas fa-plus'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                  type: 'button',
                }}
                click={() => navigate('create')}
                btnClass='mb-5 mt-1 fw-bolder'
              />
            </div>
            <div className='card-body'>

              <Datatable
                data={resoucesSelected && resoucesSelected.value ? posts.filter(el => el.classificationId === resoucesSelected.value) : posts}
                headers={[
                  { key: 'Imagem', value: 'img', type: 'image' },
                  { key: 'Ordem', value: 'order', type: 'default' },
                  { key: 'Título', value: 'title', type: 'default' },
                  { key: 'Conteúdo', value: 'content', type: 'html' },
                  { key: 'Data de Publicação', value: 'date', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: 'Ações', value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    title: 'Editar categoria',
                    action: (item) => {
                      navigate(`editar/${item.id}`)
                    },
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal('Excluir conteudo')
                      setId(item.id)
                    },
                    path: '',
                    title: 'Excluir conteudo',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
          <ModalDelete confirmAction={() => deletePost()} />
        </div>

      )}
    </>
  )
}

const FeedConteudosAggregador: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Conteúdos', path: '/aggregator/content/content', isActive: true },
        ]}
      >
        Conteúdos
      </PageTitle>
      <Content />
    </>
  )
}

export { FeedConteudosAggregador }

