import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Button} from '../../../components/Button/Button'
import {Input} from '../../../components/Input/Input'
import {Loading} from '../../../components/Loading/Loading'
import {GET, POST, PUT} from '../../../services/api'
import {i18n} from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import SVG from 'react-inlinesvg';
import useAuth from '../../../hooks/useAuth'
import { Editor } from '@tinymce/tinymce-react'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import usePluginsSpace from '../../../hooks/usePluginSpace'
import { EditorQuill } from '../../../components/EditorQuill/EditorQuill'

type segmentoAgregador = {
  name: string;
  order: number;
  image: string;
  type: string;
  active: boolean;
  id: number;
  desc: string;
  category: string;
}

type newSegmento = {
  pluginspaceId: number,
  segment: number,
  id: number
}

type Icon = {
  id: number;
  svg: string;
  description: string;
  category: string;
}

const Content: FC = () => {
    const navigate = useNavigate()
    const { loginInfo } = useAuth();
    const [loading, setLoading] = useState(false)

    const [descricaoCreate, setDescricaoCreate] = useState<string>('')

    const [setor, setSetor] = useState<segmentoAgregador>()
    const [url, setUrl] = useState<string>('')

    const [setorEdit, setSetorEdit] = useState<string>('')
    const [ordemEdit, setOrdemEdit] = useState<number>(0)
    const [categoriaEdit, setCategoriaEdit] = useState<string>('')
    const [imageEdit, setImageEdit] = useState<string>('')
    const [descEdit, setDescEdit] = useState<string>('')
    const [id, setId] = useState<number>(0)
    const [iconeSelected, setIconeSelected] = useState<any>()
    const [icones, setIcones] = useState<Icon[]>([])
    const [categoryIcons, setCategoryIcons] = useState<any[]>([{
      select: 'Todos',
      value: -1
    }])
    const { showWrapperModal, closeWrapperModal } = useModals()
    const { pluginSpace } = usePluginsSpace()

    const getCategories = async ( search: string = '') => {
      try {
        const res = await POST(`/classification/filter`, {
          plugintypeId: getPlugintypeIdByCode('service'),
          deleted: 'not show',
        })
    
        const segmentosPluginSpace = await GET('pluginspacesegment');        
    
        let newSegmento = segmentosPluginSpace.data.filter((segment:newSegmento) => {
            if(segment.pluginspaceId === loginInfo.pluginspaceId) {
                return segment;
            }
        })
    
        newSegmento = newSegmento.map((segment:newSegmento) => {
            return segment.id.toString()
        })

        const urlTotal = (window.location.href).split('/');
        const urlId = urlTotal[urlTotal.length - 1];
        setUrl(urlId);
        
        const newCategories = res.data.map((row: any) => {
          if(urlId.includes(row.id)) {
            return {
              id: row.id,
              name: row.name,
              code: row.code,
              order: row.order,
              category: row.shortname,
              img: row.img,
              description: row.description,
            }
          }
          return null;
        })
        .filter((category: any) => category !== null);
        setSetor(newCategories[0])

        setCategoriaEdit(newCategories[0].category);
        setOrdemEdit(newCategories[0].order);
        setSetorEdit(newCategories[0].name);
        setId(newCategories[0].id);
        setImageEdit(newCategories[0].img)
        setDescEdit(newCategories[0].description)


      } catch (e) {
        console.log(e)
      }
    }

    async function sendSvgAWS() {
      try {
        let bucket = `img.ninegrid.com`
        const response = await POST('/upload-images/upload-svg', {
          file: iconeSelected.svg,
          path: bucket,
          name: `${pluginSpace!.subdomain}/category/${categoriaEdit}-${uuid()}.svg`
        })
        return response
  
      } catch (error) {
        return {
          error: error,
          success: false,
          data: {}
        }
      }
    }

    const edit = async () => {
      try {
        if (!categoriaEdit) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!',
          })
        } else {
          setLoading(true)

          let res = null
          let imageRes = {
            success: false,
            image: '',
          }

          if(iconeSelected){
            imageRes = await sendSvgAWS()

            if(imageRes.success){
              res = await PUT(`/classification/${url}`, {
                name: setorEdit,
                shortname: categoriaEdit,
                order: ordemEdit,
                img: imageRes.image,
                description: descEdit,
              })
            }
          }else{
            res = await PUT(`/classification/${url}`, {
              name: setorEdit,
              shortname: categoriaEdit,
              order: ordemEdit,
              description: descEdit,
            })
          }
          setLoading(false)
          if (res.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Subcategoria atualizada',
              text: 'Edição realizada com sucesso!',
            }).then(() => navigate('/agregador/subcategory'))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao atualizar a subcategoria!',
            })
          }
          setLoading(false)
        }
      } catch (e: any) {
        setLoading(false)
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: e ?? 'Erro ao atualizar a categoria!',
        })
      }
    }

    async function getIcones() {
      try {
  
        const svgCategory = await POST('svgcategory/filter', {
          status: true
        });
  
        const auxCategory = [{
          select: 'Todos',
          value: -1
        }]
  
        svgCategory.data.map((el: any) => {
          auxCategory.push({
            select: el.name,
            value: el.id
          }
          )
        })
  
        setCategoryIcons(auxCategory)

  
        const res = await GET('svgwebapp')
  
        if (!res || !res.success) {
          Swal.fire({
            icon: 'error',
            titleText: 'Erro',
            text: 'Erro ao encontrar os tipos'
          })
          return
        }
  
        setIcones(res.data)
        // setIconesFiltered(res.data)
  
  
      } catch (error) {
        console.log(error)
  
        Swal.fire({
          icon: 'error',
          titleText: 'Erro',
          text: 'Erro ao encontrar os tipos'
        })
      }
    }

    useEffect(() => {
      setLoading(true);
      getCategories();
      getIcones();
  
      const timer = setTimeout(() => {
        setLoading(false);
      }, 3700);
  
      return () => clearTimeout(timer);
    }, []);
    
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <div className='row g-5 gx-xxl-12'>
            <div className='card card-xxl-stretch mb-5 mb-xl-12'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span>Editar Subcategoria</span>
                  <BreadCrumbs />
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    Preencha as informações abaixo e clique no botão salvar para editar uma subcategoria.
                  </span>
                </h3>
              </div>
              <div className='card-body'>
                <div className='row pb-6'>
                  <div className='col d-flex justify-content-between'>
                    <div className='row'>
                      <div className='col-12 col-md-8 pb-6'>
                        <div className='d-flex gap-2'>
                          <span>Categoria</span>
                          <div className='d-flex gap-2'>
                            <span className='text-muted'>·</span>
                            <span className='text-muted'>Escolha o setor relacionada a nova subcategoria</span>
                          </div>
                        </div>
                        <Input
                          inputAttr={{
                            type: 'default',
                          }}
                          placeholder={'Categoria'}
                          value={setorEdit || ''}
                          disabled={true}
                          change={(value: string) => setSetorEdit(value)}
                        />
                      </div>
                      <div className='col-12'>
                        <div className='d-flex gap-2'>
                            <span>Subcategoria</span>
                            <div className='d-flex gap-2'>
                              <span className='text-muted'>·</span>
                              <span className='text-muted'>Máximo de 15 caracteres</span>
                            </div>
                        </div>
                          <Input
                            inputAttr={{
                              type: 'text',
                            }}
                            placeholder={'Subcategoria'}
                            value={categoriaEdit || ''}
                            change={(value: string) => setCategoriaEdit(value)}
                          />
                      </div>
                    </div>
                    <div>
                      <Input
                          inputAttr={{
                            type: 'icon',
                          }}
                          onAction={() => showWrapperModal('Ícones')}
                          label="Ícone"
                          value={iconeSelected ? iconeSelected : imageEdit}
                          change={(value: any) => {
                            setIconeSelected(value)
                          }}
                        />
                    </div>
                  </div>
                </div>
                <div className='row pb-6'>
                  <div className='col-12 col-md-2'>
                    <div>
                        <span>Ordem</span>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder={''}
                          value={ordemEdit}
                          change={(value: number) => setOrdemEdit(value)}
                        />
                      </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div>
                        <span>Descrição</span>
                        <Input
                          rows={4}
                          inputAttr={{
                            type: 'textarea',
                          }}
                          placeholder={'Descrição'}
                          value={descEdit}
                          change={(value: any) => {
                            setDescEdit(value);
                          }}
                        />
                        {/* <EditorQuill
                        value={descEdit}
                        change={(value: string) => editText(value)}
                      /> */}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
                <Button
                    text='Voltar'
                    color='info'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                        type: 'button',
                    }}
                    click={() => {
                        Swal.fire({
                            title: 'Você tem certeza?',
                            text: 'Os dados que foram digitados serão perdidos.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sim, voltar!',
                            cancelButtonText: 'Não, cancelar!',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(-1)
                            }
                        })
                    }}
                    btnClass='fw-bolder me-3'
                />
                <Button
                    text='Salvar'
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                        type: 'button',
                    }}
                    click={() => edit()}
                    btnClass='fw-bolder'
                />
            </div>
          </div>
        )}
        <ModalWrapper confirmAction={() => {
          }}
            maxWidth={true}
            width={40}
          >
            <div className="d-flex  flex-column gap-5 card"
              style={{
                height: '20rem'
              }}>
              <div className="d-flex justify-content-end mt-5">
              <div className="p-4 border border-primary ">
                {iconeSelected ?   <SVG src={iconeSelected?.svg} style={{
                    width: '45px',
                    height: '45px'
                  }} /> : <img
                  style={{
                    width: '45px',
                    height: '45px'
                  }} 
                  src={imageEdit}
                  alt='Logo pluginspace'
                />

                }
                </div>
              </div>
              <div className="card-body d-flex gap-5 flex-row flex-wrap overflow-auto">
                {icones.map(icon => (
                  <div className={`d-flex flex-column p-4`}

                    onClick={() => setIconeSelected(icon)}>
                    <span>{icon.description}</span>
                    <SVG src={icon.svg} style={{
                      width: '45px',
                      height: '45px'
                    }} />
                  </div>
                ))

                }

              </div>


            </div>

          </ModalWrapper>
      </>
    )
  }

const FeedEditarSubCategoriasAgregador: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`Editar Subcategorias`), path: '/agregador/subcategory/editar/:id', isActive: true},
        ]}
      >
      </PageTitle>
      <Content />
    </>
  )
}

export {FeedEditarSubCategoriasAgregador}
