import React, { FC, useRef, useEffect, useLayoutEffect, forwardRef, useImperativeHandle, useState } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; 


interface Props {
  label?: string;
  disabled?: boolean;
  value?: any;
  change?: (value:any) => void;
  placeholder?: string;
  name?: string;
  focus?: boolean;
  smalltext?: string;
  blur?: () => void;
  onAction?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const Editor = forwardRef(
  ({  defaultValue, onTextChange, onSelectionChange,change, }:any, ref:any) => {
    const containerRef = useRef<any>(null);
    const defaultValueRef = useRef<any>(defaultValue);
    const onTextChangeRef = useRef<any>(onTextChange);
    const onSelectionChangeRef = useRef<any>(onSelectionChange);

   // const changeRef = useRef(change);

    const [editorContent, setEditorContent] = useState<string>('');

    useEffect(() => {
      const container:any = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div'),
      );
      
      const quill = new Quill(editorContainer, {
        theme: 'snow',
      });

      ref.current = quill;

      if (defaultValueRef.current) {
        quill.clipboard.dangerouslyPasteHTML(defaultValueRef.current);
      }


      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      
      quill.on('text-change', (delta, oldDelta, source) => {
        const editor = ref.current;
        if (editor) {
          const content = editor.root.innerHTML;
          change(content);  // Save content to state
          
        }
      });


      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div style={{ height: 200 ,marginBottom:'4rem'}} ref={containerRef}></div>;
  },
);

const Delta = Quill.import('delta');

const EditorQuill: FC<Props> = (props) => {

  const {
    label,
    disabled,
    value,
    change,
    placeholder,
    name,
    focus,
    smalltext,
    blur,
    onAction,
    onKeyDown,
  } = props

  const [range, setRange] = useState();
  const [lastChange, setLastChange] = useState();
  const quillRef = useRef();

  useEffect(()=>{
    console.log(Delta)
  },[lastChange])


  return (
    <Editor
    ref={quillRef}
    defaultValue={value}
    onSelectionChange={setRange}
    onTextChange={setLastChange}
    change={(event:any) => change && change(event)}
  />
  )
}


export { EditorQuill };
