/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  MessageModel,
  UserInfoModel,
} from '../../helpers'
import { i18n } from '../../../app/translate/i18n'
import { POST } from '../../../app/services/api'
import { rtcToText } from '../../../app/utils/formater'

import styles from './ChatInner.module.css'

type Props = {
  isDrawer?: boolean
}

type LocalSavedMessageType = {
  lastMessage: Date,
  messages: MessageModel[],
  sessionId: string;
}

const ChatInner: FC<Props> = ({isDrawer = false}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModel[]>([{
    user: 0,
    type: 'in',
    text: i18n.t(`chat.initial_message`),
    time: new Date().getTime()
  }])
  const [userInfos] = useState<UserInfoModel[]>([
    {
      name: 'Ninegrid',
      avatar: 'avatars/300-6.jpg',
    },
    {
      name: i18n.t(`chat.you`),
      avatar: 'avatars/300-6.jpg',
    }
  ])

  const [loading, setLoading] = useState(false);
  
  const [sessionId, setSessionId] = useState('');

  const [messageLoading, setMessageLoading] = useState<MessageModel>({
    user: 0,
    type: 'in',
    text: '...',
  })

  const textbox = useRef(null);

  const sendMessage = (type: 'in' | 'out', user: number, messageReceived?: string) => {
    const newMessages = [...messages]
    const newMessage: MessageModel = {
      user: user,
      type: type,
      text: messageReceived ? messageReceived : message,
      time: new Date().getTime(),
    }
    newMessages.push(newMessage)
    setMessages(newMessages)
    toggleChatUpdate()
    if(type === 'out'){
      sendMessageNinegridIA(message, newMessages)
      setMessage('')
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      if(message) sendMessage('out', 1)
    }
  }

  const getTimeDifference = (timeMessage: number) => {
    const dateMsg = new Date(timeMessage);
    const today = new Date()
    const timeNow = today.getTime();
    const timeMinutes = Math.trunc((timeNow - timeMessage)/60000)
    return  timeMinutes <= 0 ? 
              i18n.t(`chat.now`) : 
              today.toLocaleDateString() === dateMsg.toLocaleDateString() ? 
                dateMsg.toLocaleTimeString().substring(0, 5) : 
                `${dateMsg.toLocaleDateString()} ${dateMsg.toLocaleTimeString().substring(0, 5)}`
  }

  const animateMessageLoading = () => {
    const now = new Date().getTime() / 300;
    switch(Math.trunc(now % 3)) {
      case 0:
        setMessageLoading({
          user: 0,
          type: 'in',
          text: '.'
        });
        break;
      case 1:
        setMessageLoading({
          user: 0,
          type: 'in',
          text: '..'
        });
        break;
      case 2:
        setMessageLoading({
          user: 0,
          type: 'in',
          text: '...'
        });
        break;
      default:
        setMessageLoading({
          user: 0,
          type: 'in',
          text: '...'
        });
        break;
    }
  }

  

  const sendMessageNinegridIA = async (messageToSend: string, newMessages: MessageModel[]) => {
    try {
      setLoading(true)
      let messageLanguage = i18n.t(`chat.tip_language`)
      const res = await POST(`/chatIA/question`, {
        inputText: messageToSend + ' ' + messageLanguage,
        sessionId
      })
      setLoading(false)
      let newSessionId = sessionId
      let messageIA = ''
      if (res.success && res.data.response && res.data.completion && res.data.response.sessionId) {
        messageIA = res.data.completion
        setSessionId(res.data.response.sessionId)
        newSessionId = res.data.response.sessionId
      } else {
        messageIA = i18n.t(`chat.error_message`)
      }
      const newMessage: MessageModel = {
        user: 0,
        type: 'in',
        text: messageIA,
        time: new Date().getTime(),
      }
      newMessages.push(newMessage)
      setMessages(newMessages)
      saveLocalMessages(newMessages, newSessionId)
      toggleChatUpdate()
      scrollToBottm()
    } catch(e) {
      console.log(e)
      setLoading(false)
      const newMessage: MessageModel = {
        user: 0,
        type: 'in',
        text: i18n.t(`chat.error_message`),
        time: new Date().getTime(),
      }
      newMessages.push(newMessage)
      setMessages(newMessages)
      saveLocalMessages(newMessages, sessionId)
      toggleChatUpdate()
    }
  }

  const toggleChatUpdate = () => {
    toggleChatUpdateFlat(!chatUpdateFlag)
    setTimeout(() => {
      toggleChatUpdateFlat((flag) => !flag)
    }, 1000)
  }

  const saveLocalMessages = (newMessages: MessageModel[], newSessionId: string) => {
    localStorage.setItem(window.btoa('messagesIA'), window.btoa(JSON.stringify({
      lastMessage: new Date(),
      messages: newMessages,
      sessionId: newSessionId
    })))
  }

  const adjustHeightTextArea = () => {
    if(textbox && textbox.current){
      const styleTextbox:any = textbox.current
      if(styleTextbox.scrollHeight <= 130){
        styleTextbox.style.height = "inherit";
        styleTextbox.style.height = `${styleTextbox.scrollHeight}px`;
        const divChat = document.getElementById('chat-scroll');
        const divFooter = document.getElementById('kt_drawer_chat_messenger_footer');
        if(divChat && divFooter){
          divChat.style.height = `${80 - ((styleTextbox.scrollHeight - 50)/7)}%`
          divChat.style.paddingBottom = `calc(${20 + ((styleTextbox.scrollHeight - 50)/7)}% + ${window.innerHeight/12}px)`
          divFooter.style.height = `calc(${20 + ((styleTextbox.scrollHeight - 50)/7)}% - 2.5em)`
        }
      }
    }
  }

  const scrollToBottm = () => {
    const divChat = document.getElementById('kt_drawer_chat')
    if(divChat){
      divChat.scrollTo(0, divChat.scrollHeight)
    }
  }

  useEffect(() => {
    const messagesIALocal = localStorage.getItem(window.btoa('messagesIA'))
    
    if(messagesIALocal){
      const messageLocalSaved:LocalSavedMessageType = JSON.parse(window.atob(messagesIALocal));
      
      if(
        messageLocalSaved && 
        messageLocalSaved.lastMessage && 
        messageLocalSaved.messages && 
        messageLocalSaved.sessionId
      ){
        if((new Date().getTime() - new Date(messageLocalSaved.lastMessage).getTime()) < (1000 * 60 * 60 * 24)){
          setMessages(messageLocalSaved.messages)
          setSessionId(messageLocalSaved.sessionId)
        } else {
          localStorage.removeItem(window.btoa('messagesIA'))
        }
      }
    }
    setInterval(() => {
      animateMessageLoading()
    }, 300)
  }, [])

  useEffect(() => {
    scrollToBottm()
  },[messages])

  return (
    <div
      className={styles.chatBody}
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        id='chat-scroll'
        className={styles.chatInner}
        style={{paddingBottom: `calc(20% + ${window.innerHeight/12}px)`}}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {
          [...messages, loading ? messageLoading : null].map((message, index) => {
            if(message){
              const userInfo = userInfos[message.user]
              const state = message.type === 'in' ? 'info' : 'primary'
              const templateAttr = {}
              if (message.template) {
                Object.defineProperty(templateAttr, 'data-kt-element', {
                  value: `template-${message.type}`,
                })
              }
              const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
                message.type === 'in' ? 'start' : 'end'
              } mb-10`
              return (
                <div
                  key={`message${index}`}
                  className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                  {...templateAttr}
                >
                  <div
                    className={clsx(
                      'd-flex flex-column align-items',
                      `align-items-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                  >
                    <div className='d-flex align-items-center mb-2'>
                      {message.type === 'in' ? (
                        <>
                          {/* <div className='symbol  symbol-35px symbol-circle '>
                            <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} />
                          </div> */}
                          <div className='ms-3'>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                            >
                              {userInfo.name}
                            </a>
                            <span className='text-muted fs-7 mb-1'>{message.time ? getTimeDifference(message.time) : ''}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='me-3'>
                            <span className='text-muted fs-7 mb-1'>{message.time ? getTimeDifference(message.time) : ''}</span>
                            <a
                              href='#'
                              className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                            >
                              {i18n.t(`chat.you`)}
                            </a>
                          </div>
                          {/* <div className='symbol  symbol-35px symbol-circle '>
                            <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} />
                          </div> */}
                        </>
                      )}
                    </div>

                    <div
                      className={clsx(
                        'p-5 rounded',
                        `bg-light-${state}`,
                        'text-dark fw-bold mw-lg-400px',
                        `text-${message.type === 'in' ? 'start' : 'end'}`
                      )}
                      data-kt-element='message-text'
                      dangerouslySetInnerHTML={{__html: rtcToText(message.text)}}
                    ></div>
                  </div>
                </div>
              )
            }
          })}
      </div>

      <div
        className={styles.footerChat}
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='d-flex flex-stack gap-2 justify-content-between flex-row align-items-end' style={{width: '100%'}}>
          <textarea
            ref={textbox}
            style={{resize: 'none'}}
            className={styles.chatText}
            data-kt-element='input'
            placeholder={i18n.t(`chat.type_message`)}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
              adjustHeightTextArea();
            }}
            onKeyDown={onEnterPress}
          ></textarea>
          <button
            style={{padding: '0.75em 1.5em'}}
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={() => {if(message) sendMessage('out', 1)}}
          >
            {i18n.t(`chat.send`)}
          </button>
        </div>
      </div>
    </div>
  )
}

export {ChatInner}
