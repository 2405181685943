import { createContext, ReactNode, useState } from 'react'

type TextModalProps = {
  display: boolean
  title: string
  text: string
}

type WrapperModalProps = {
  display: boolean
  title: string
}

type DeleteModalProps = {
  display: boolean
  title: string
}

type WarningModalProps = {
  display: boolean
  title: string
}

type ImagemCroppedModalProps = {
  display: boolean
}



type GlobalModalsContextData = {
  textModal: TextModalProps
  showTextModal: (title: string, text: string) => void
  closeTextModal: () => void
  wrapperModal: WrapperModalProps
  showWrapperModal: (text: string) => void
  closeWrapperModal: () => void
  deleteModal: DeleteModalProps
  showDeleteModal: (text: string) => void
  closeDeleteModal: () => void
  warningModal: WarningModalProps
  showWarningModal: (text: string) => void
  closeWarningModal: () => void

  imageCroppedModal: ImagemCroppedModalProps
  showImageCropped :()=> void
  closeImageCropped :()=> void
}

type GlobalModalsContextProvider = {
  children: ReactNode
}

export const GlobalModalsContext = createContext({} as GlobalModalsContextData)

const GlobalModalsProvider = ({ children }: GlobalModalsContextProvider) => {
  const [textModal, setTextModal] = useState<TextModalProps>({
    display: false,
    title: '',
    text: '',
  })
  const closeTextModal = () => {
    setTextModal({
      display: false,
      title: '',
      text: '',
    })
  }
  const showTextModal = (title: string, text: string) => {
    setTextModal({
      display: true,
      title: title,
      text: text,
    })
  }

  const [wrapperModal, setWrapperModal] = useState<WrapperModalProps>({
    display: false,
    title: '',
  })
  const closeWrapperModal = () => {
    setWrapperModal({
      display: false,
      title: '',
    })
  }
  const showWrapperModal = (title: string) => {
    setWrapperModal({
      display: true,
      title: title,
    })
  }

  const [deleteModal, setDeleteModal] = useState<DeleteModalProps>({
    display: false,
    title: '',
  })
  const closeDeleteModal = () => {
    setDeleteModal({
      display: false,
      title: '',
    })
  }
  const showDeleteModal = (title: string) => {
    setDeleteModal({
      display: true,
      title: title,
    })
  }

  const [warningModal, setWarningModal] = useState<WarningModalProps>({
    display: false,
    title: '',
  })
  const closeWarningModal = () => {
    setWarningModal({
      display: false,
      title: '',
    })
  }
  const showWarningModal = (title: string) => {
    setWarningModal({
      display: true,
      title: title,
    })
  }

  const [imageCroppedModal, setImageCroppedModal] = useState<ImagemCroppedModalProps>({
    display: false,
  })

  const closeImageCropped = ()=>{
    setImageCroppedModal({
      display:false
    })
  }

  const showImageCropped = ()=>{
    setImageCroppedModal({
      display:true
    })
  }


  return (
    <GlobalModalsContext.Provider
      value={{
        textModal,
        closeTextModal,
        showTextModal,
        wrapperModal,
        showWrapperModal,
        closeWrapperModal,
        deleteModal,
        showDeleteModal,
        closeDeleteModal,
        warningModal,
        showWarningModal,
        closeWarningModal,
        imageCroppedModal,
        showImageCropped,
        closeImageCropped,
      }}
    >
      {children}
    </GlobalModalsContext.Provider>
  )
}

export default GlobalModalsProvider
