import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import {DELETE, GET, POST, PUT} from '../../../../services/api'
import {Loading} from '../../../../components/Loading/Loading'
import {ModalDelete} from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {i18n} from '../../../../translate/i18n'
import {ListIcons} from './ListIcons'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const PageContent: FC = () => {
  const navigate = useNavigate()
  const {showWrapperModal, showDeleteModal} = useModals()

  const [listCategories, setListCategories] = useState<any[]>([])
  const [listSVG, setListSVG] = useState<any[]>([])
  const [categories, setCategories] = useState<any[]>([])
  const [categoryId, setCategoryId] = useState<string>('0')
  const [category, setCategory] = useState<string>('')
  const [name, setName] = useState<string>('')

  const [loading, setLoading] = useState(false)
  const [fileToImport, setFileToImport] = useState<File | null>(null)

  const getCategories = async (loading = true) => {
    try {
      setLoading(true)
      const res = await GET(`/svgwebapp/categories`)
      const listCategories = res.data.map((row: any) => {
        return {
          category: row.category,
        }
      })
      setListCategories(listCategories)
      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      listCategories.map((row: any, idx: any) => {
        categories.push({
          value: row.category,
          select: row.category,
          key: idx,
        })
      })
      setCategories(categories)
      await getSVG()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getSVG = async (loading = true) => {
    try {
      setLoading(true)
      const res = await GET(`/svgwebapp`)
      const listSVG = res.data.map((row: any) => {
        return {
          id: row.id,
          svg: row.svg,
          category: row.category,
        }
      })
      setListSVG(listSVG)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const create = async () => {
    try {
      if (name === '' || categoryId === '0') {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado a categoria ou informado o nome do ícone',
        })
        return
      }
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        const fileReader = new FileReader()
        fileReader.readAsText(fileToImport)
        const svg = await fileToImport.text()

        console.log(name, categoryId, svg)
        const res = await POST('/svgwebapp', {
          description: name,
          category: category,
          svg: svg,
        })
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Ícone',
            text: 'Ícone adicionado com sucesso',
          })
          setName('')
          setCategoryId('0')
          await getCategories()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Não foi possível adicionar o ícone.',
          })
        }
      }
    } catch (error) {}
  }

  useEffect(() => {
    getCategories()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Biblioteca de Ícones</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listados ícones que podem ser utilizados no sistema.
                </span>
              </h3>
              <div className='card-toolbar'></div>
            </div>
            <div className='card-body'>
              <div className='px-10 py-5'>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className='col-lg-4'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label={'Categoria'}
                      options={categories}
                      value={categoryId}
                      change={(value: any) => {
                        setCategoryId(value)
                        setCategory(value.value)
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div className='col-lg-4'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='Nome'
                      label='Nome'
                      change={(value: string) => setName(value)}
                    />
                  </div>
                </div>
                <br />
                <div className='flex-grow-1 text-center'>
                  <Input
                    inputAttr={{
                      type: 'file',
                    }}
                    change={(value: BaseSyntheticEvent) => {
                      setFileToImport(value ? value.target.files[0] : null)
                    }}
                    acceptFile={'.svg'}
                  />
                </div>
                <br />
                <div className='flex-grow-1 text-center'>
                  <div className='col-lg-4'></div>
                  <Button
                    text='Adicionar'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-plus'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => create()}
                    btnClass='mb-5 fw-bolder'
                  />
                </div>
              </div>
              <hr />

              <div className='card pb-5'>
                <div className='row'>
                  {listCategories.map((row: any) => (
                    <>
                      <div
                        className='text-center'
                        style={{
                          backgroundColor: '#cfcfcf',
                          marginTop: '5px',
                          marginBottom: '12px',
                          paddingTop: '6px',
                        }}
                      >
                        <h4>{row.category}</h4>
                      </div>
                      <div className='d-flex justify-content-left flex-wrap'>
                        <ListIcons icons={listSVG} category={row.category} />
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const HospitalityIcones: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {
            title: 'Biblioteca de Ícones',
            path: './hospitality/configuracoes/icones',
            isActive: true,
          },
        ]}
      >
        Biblioteca de Ícones
      </PageTitle>
      <PageContent />
    </>
  )
}

export {HospitalityIcones}
