import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT, } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import Swal from 'sweetalert2'

import styles from './createConfig.module.css'

import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type PluginconfigType = {
  id: number;
  name: string;
  content: any;
  plugintemplateId: number;
  plugintypeId: number;
}

type TemplateType = {
  id: number;
  name: string;
  image: string | null;
  parameters: string;
  plugintypeId: number;
  pluginconfig: PluginconfigType;
  able: boolean | null;
}

type ConfigInputsType = {
  name: string;
  label: string;
  value: string;
}

const ConfigIAEditContent: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [IAConfig, setIAConfig] = useState<TemplateType | null>();
  const [configInputs, setConfigInputs] = useState<(ConfigInputsType | undefined)[]>([])

  const getPlugintemplate = async () => {
    try {
      const res = await GET(`/plugintemplate/ia/${id}`)
      if (res.success && res.data && res.data.length > 0) {
        setIAConfig(res.data[0])
        const newInputs: (ConfigInputsType | undefined)[] = Object.entries(
          JSON.parse(res.data[0].parameters).config
        ).map((configInput) => {
          if (
            configInput[0] !== undefined &&
            configInput[1] !== undefined &&
            typeof configInput[1] === 'string'
          ) {
            let value = ''
            if (res.data[0] && res.data[0].pluginconfig)
              value = res.data[0].pluginconfig.content[configInput[0]] ? res.data[0].pluginconfig.content[configInput[0]] : ''
            const input: ConfigInputsType = {
              name: configInput[0],
              label: configInput[1],
              value
            }
            return input
          }
        })
        setConfigInputs(newInputs.filter((input) => input))
        return
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar configuração de inteligência artificial.',
      }).then(() => navigate(-1))
      return
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar configuração de inteligência artificial.',
      }).then(() => navigate(-1))
    }
  }

  const init = async () => {
    setLoading(true)
    await getPlugintemplate();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])

  const editConfigIA = async () => {
    if (IAConfig) {
      let integrationContent: any = {}
      configInputs.forEach((config) => {
        if (config) {
          integrationContent[config.name] = config.value
        }
      })
      try {
        setLoading(true)
        const integrationRes = await PUT(`/pluginconfig/${IAConfig.pluginconfig.id}`, {
          plugintypeId: IAConfig.plugintypeId,
          plugintemplateId: parseInt(id ? id : '0'),
          name: IAConfig.name,
          content: integrationContent,
          locationsId: []
        })
        setLoading(false)
        if (integrationRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Integração atualizada',
            text: 'Edição realizada com sucesso!'
          }).then(() => navigate(`/ia/config/${id}`))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao atualizar integração!'
          })
        }
      } catch (e) {
        setLoading(false)
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao cadastrar integração!',
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Falha ao encontrar configuração!',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`chat.config`)}</span>
                <BreadCrumbs />
              </h3>
            </div>
            {IAConfig && (
              <div className='card-body d-flex flex-column gap-5' >
                <div className={styles.goBack}>
                  {IAConfig.image && (<img src={IAConfig.image} alt={IAConfig.name} />)}
                 
                </div>
                <div className={styles.form}>
                  <h2>{i18n.t(`payment.basic_settings`)}</h2>
                  {configInputs.filter((input) => input && !input.name.includes('able') && !input.name.includes('modelId') && !input.name.includes('agentId')).map((config) => (
                    <div key={config?.name}>
                      <Input
                        inputAttr={{
                          type: 'text',
                        }}
                        disabled
                        placeholder={config?.label}
                        label={config?.label}
                        name={config?.name}
                        value={config?.value}
                        change={() => { }}
                      />
                    </div>
                  ))}
                </div>
                <div className='d-flex w-100 justify-content-end gap-2'>
                  <Button
                    text={i18n.t(`buttons.back`)}
                    color='info'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      navigate('/ia/config')
                    }}
                    btnClass='fw-bolder mt-4'
                  />
                  <Button
                    text={i18n.t(`buttons.save`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={async () => await editConfigIA()}
                    btnClass='fw-bolder mt-4'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const ConfigIAEdit: FC = () => {
  const { id } = useParams();

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`ia.title_config`), path: 'ia/config', isActive: true },
          { title: i18n.t(`ia.config`), path: `ia/config/${id}`, isActive: true },
        ]}
      >
        {i18n.t(`ia.config`)}
      </PageTitle>
      <ConfigIAEditContent />
    </>
  )
}

export { ConfigIAEdit }
