import { FC, useEffect } from 'react'
import styles from './ButtonChatIA.module.css'

type PropsButtonChatIA = {
  onClick: () => void
}

export const ButtonChatIA: FC<PropsButtonChatIA> = ({onClick}) => {
  return (
    <div className={styles.buttonChatIA} id='button-chat-ia' onClick={onClick}>
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.375 10.8125C5.08594 10.8125 5.6875 11.4141 5.6875 12.125V12.5625L7.65625 11.0859C7.875 10.9219 8.14844 10.8125 8.44922 10.8125H12.25C12.4688 10.8125 12.6875 10.6211 12.6875 10.375V2.5C12.6875 2.28125 12.4688 2.0625 12.25 2.0625H1.75C1.50391 2.0625 1.3125 2.28125 1.3125 2.5V10.375C1.3125 10.6211 1.50391 10.8125 1.75 10.8125H4.375ZM5.6875 14.2031L5.66016 14.2305L5.52344 14.3125L5.05859 14.668C4.92188 14.7773 4.75781 14.7773 4.59375 14.7227C4.45703 14.6406 4.375 14.5039 4.375 14.3125V13.7383V13.5742V13.5469V13.4375V12.125H3.0625H1.75C0.765625 12.125 0 11.3594 0 10.375V2.5C0 1.54297 0.765625 0.75 1.75 0.75H12.25C13.207 0.75 14 1.54297 14 2.5V10.375C14 11.3594 13.207 12.125 12.25 12.125H8.44922L5.6875 14.2031Z" fill="white"/>
        </svg>
    </div>
  )
}
