import { FC, useEffect, useRef, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import { Button } from "../../../components/Button/Button"
import SVG from 'react-inlinesvg';
import Swal from "sweetalert2"
import { Input } from "../../../components/Input/Input"
import { GET, POST } from "../../../services/api"
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

// Configurações . Setor de atuação


type segmentoAgregador = {
    name: string;
    order: number;
    image: string
    type: string;
    active: boolean;
}

type classIcon = {
    name: string
    data: Icon[]
}

type Icon = {
    id: number;
    svg: string;
    description: string;
    category: string;
}




const SegmentsCreateContent: FC = () => {
    const navigate = useNavigate()
    const [types, setTypes] = useState<any[]>([{
        select: 'Selecione',
        value: '',
    }, {
        select: 'hospitality',
        value: 'hospitality',
    },
    {
        select: 'healthcare',
        value: 'healthcare',
    },

    {
        select: 'varejo',
        value: 'varejo',
    },
    {
        select: 'fashion',
        value: 'fashion',
    },
    {
        select: 'service',
        value: 'service',
    },
    {
        select: 'housegarden',
        value: 'housegarden',
    }])
    const [loading, setLoading] = useState(false)
    const [loadingIcons, setLoadingIcons] = useState(false)
    const [form, setForm] = useState<segmentoAgregador>({
        name: '',
        order: 0,
        image: '',
        type: '',
        active: true,
    }
    )
    const [icones, setIcones] = useState<Icon[]>([])
    const [setor, setSetor] = useState<segmentoAgregador[]>([]);
    const [iconesFiltered, setIconesFiltered] = useState<Icon[]>([])
    const imageRef = useRef<HTMLImageElement>(null);
    const [categoryIcons, setCategoryIcons] = useState<any[]>([{
        select: 'Todos',
        value: -1
    }])
    const [categoryIconsSelect, setCategoryIconsSelect] = useState<any>(-1)

    const [icon, setIcon] = useState<string>('')


    const convertSvgToPng = () => {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (context) {
                const image = new Image();
                image.onload = () => {
                    canvas.width = 45;
                    canvas.height = 45;
                    context.drawImage(image, 0, 0, 45, 45); // Adjust size as needed

                    canvas.toBlob(blob => {
                        if (blob) {
                            resolve(new File([blob], 'image.png', { type: 'image/png' }));
                        } else {
                            reject(new Error('Failed to convert SVG to PNG'));
                        }
                    });
                };
                image.src = `data:image/svg+xml;base64,${btoa(icon)}`;
            } else {
                reject(new Error('Canvas context not supported'));
            }
        });
    };


    async function create() {
        if (form.name === ''
            || form.type === ''
        ) {
            Swal.fire(
                {
                    icon: 'warning',
                    title: 'Atenção, campos faltando',
                    text: 'Verifique os campos novamente'
                }
            )
            return
        }

        try {
            setLoading(true)

            const image = await convertSvgToPng()
                .then((file: any) => {
                    return file; // You can now use this File object as needed
                })
                .catch(error => {
                    return null
                });;

            if (!image) {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                    }
                )
                setLoading(false)
                return
            }
            const formData = new FormData()
            formData.append('file', image)
            formData.append('nameImage', `svg/${form.name.toLowerCase().trim()}.png`)
            formData.append('originalSize', 'true')
            const imageRes = await POST('/upload-images/upload-one', formData)
            const urlImage = imageRes.success ? imageRes.image.split('https://s3.amazonaws.com/img.ninegrid.com/')[1] : ''



            if (!imageRes.success) {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                    }
                )
                setLoading(false)
                return
            }


            //const urlImage = String('https://s3.amazonaws.com/img.ninegrid.com/netsegteste/aggregator/svg/cftv%20%26%20video-monitoramento.png').split('https://s3.amazonaws.com/img.ninegrid.com/')[1]
            const urlSliced = urlImage.split('/')
            form.image = `${urlSliced[2]}/${urlSliced[3]}`


            const response = await POST('segment', form)

            if (!response || !response.success) {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                    }
                )
                setLoading(false)
                return
            }



            Swal.fire(
                {
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Cadastro do segmento realizado com sucesso'
                }
            )




            setLoading(false)

            navigate('/segmentos')
        } catch (error) {
            setLoading(false)
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Erro',
                    text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                }
            )


        }





    }

    async function getIcones() {
        try {
            setLoading(true)

            const svgCategory = await POST('svgcategory/filter', {
                status: true
            });

            const auxCategory = [{
                select: 'Todos',
                value: -1
            }]

            svgCategory.data.map((el: any) => {
                auxCategory.push({
                    select: el.name,
                    value: el.id
                }
                )
            })

            setCategoryIcons(auxCategory)



            const res = await GET('svgwebapp')

            if (!res || !res.success) {
                Swal.fire({
                    icon: 'error',
                    titleText: 'Erro',
                    text: 'Erro ao encontrar os tipos'
                })
                setLoading(false)
                return
            }

            setIcones(res.data)
            setIconesFiltered(res.data)

            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)

            Swal.fire({
                icon: 'error',
                titleText: 'Erro',
                text: 'Erro ao encontrar os tipos'
            })
        }
    }

    function changeFilterIcons() {
        setLoadingIcons(true)
        const filtered = icones.filter(icon => {

            if (categoryIconsSelect !== -1) {


                return icon.category === categoryIconsSelect ? true : false

            }
            return true


        })

        setIconesFiltered(filtered)


        setTimeout(() => {
            setLoadingIcons(false)
        }, 1000)
    }

    function svgToPng(svgString: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const image = new Image();
            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx!.drawImage(image, 0, 0);

                // Convert canvas to Blob
                canvas.toBlob(blob => {
                    if (blob) {
                        const pngFile = new File([blob], form.name.toLowerCase().trim(), { type: 'image/png' });
                        resolve(pngFile);
                    } else {
                        reject(new Error('Failed to convert SVG to PNG File'));
                    }
                }, 'image/png');
            };

            image.src = 'data:image/svg+xml;base64,' + btoa(svgString);
        });

    }
    useEffect(() => {
        getIcones()
        getSetores();
    }, [])

    const getSetores = async () => {
        try {
            setLoading(true);
      
            const setoresRes = await GET('segment');

            // console.log(setoresRes);

            let newSetores = setoresRes.data.map((setor: segmentoAgregador) => {
                return {
                    name: setor.name,
                    order: setor.order,
                    image: setor.image,
                    type: setor.type,
                    active: setor.active
                }
            });

            // console.log(newSetores);
            
            setSetor(newSetores);
            
            
            setLoading(false);
          } catch (e) {
            setLoading(false);
            console.log(e)
          }
    }



    return (
        <>
            {loading ? <Loading /> :
                <div className='row g-5 gx-xxl-12'>
                    <div>
                        <div>
                            <div className='w-100 card'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-4 mb-1'>Setor de atuação</span>
                                        <BreadCrumbs />
                                        <span className='text-muted mt-1 fw-bold fs-7'>Lista para selecionar os principais setores que farão parte do Agregador. Preencha as informações abaixo e clique no botão salvar para confirmar as alterações</span>
                                    </h3>
                                </div>
                                <div className='row ps-9 pb-9 pe-9'>
                                    {setor.map((setor) => (
                                        <div className='col-md-3 mt-5'  key={setor.name}>
                                            <Input
                                            inputAttr={{
                                            type: 'checkbox',
                                            }}
                                            placeholder={i18n.t(`${setor.name}`)}
                                            label={i18n.t(`${setor.name}`)}
                                            change={(value: boolean) => setor.active = value}
                                            // checked={permissions.includes(setorInside.id) ? true : false}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="d-flex w-100 justify-content-end"> 
                        <Button
                            text='Voltar'
                            color='info'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={() => {
                                Swal.fire({
                                    title: 'Você tem certeza?',
                                    text: 'Os dados que foram digitados serão perdidos.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim, voltar!',
                                    cancelButtonText: 'Não, cancelar!',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate('/config/sector')
                                    }
                                })
                            }}
                            btnClass='fw-bolder me-3'
                        />
                        <Button
                            text='Salvar'
                            color='primary'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={create}
                            btnClass='fw-bolder'
                        />
                    </div>
                </div >
            }
        </>


    )
}

const SegmentosCreateAgregador: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`Setor de atuação`), path: '/config/sector', isActive: true },
                  ]}
                >
                  {i18n.t(`Setor de atuação`)}
            </PageTitle>
            <SegmentsCreateContent />
        </>
    )
}

export { SegmentosCreateAgregador }