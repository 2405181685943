/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {POST_ADM} from '../../../../services/api'
import {Loading} from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../../hooks/useModals'
import {Input} from '../../../../components/Input/Input'
import moment from 'moment'
import useAuth from '../../../../hooks/useAuth'
import {i18n} from '../../../../translate/i18n'
import {verifyTotvsVarejo, verifyWinthor} from '../../../../functions/integrations'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ChargeType = {
  id: number
  name: string
  type: string
}

type SendChargeType = {
  pluginspaceId: number | null;
  preDate?: string;
  postDate?: string;
}

const ChargesContent: FC = () => {
  const {showWrapperModal} = useModals()
  const {loginInfo} = useAuth()
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [charges, setCharges] = useState<Array<ChargeType>>([])
  const [chargeType, setChargeType] = useState('')
  const [winthor, setWinthor] = useState(false)
  const [totvsVarejo, setTotvsVarejo] = useState(false)

  const chargeItem = async (type?: string) => {
    try {
      setLoading(true)
      let chargeUrl = 'charge'
      if (totvsVarejo) chargeUrl = 'chargeLive'
      if (winthor) chargeUrl = 'chargeWinthor'
      if (!type) type = chargeType;
      let sendObj:SendChargeType = {
        pluginspaceId: loginInfo.pluginspaceId,
        preDate: startDate + ' 00:00:00',
        postDate: finalDate + ' 23:59:59',
      }
      if(totvsVarejo){
        sendObj = {
          pluginspaceId: loginInfo.pluginspaceId,
        }
      }
      const chargeRes = await POST_ADM(`/${chargeUrl}/${type}`, sendObj)
      setChargeType('')
      if (chargeRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Carga iniciada!',
          text: 'Isso pode demorar alguns minutos',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao acionar carga!',
        })
      }

      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: (e && (e.includes('expirou') || e.includes('minutos'))) ? 'warning' : 'error',
        title: (e && (e.includes('expirou') || e.includes('minutos'))) ? 'Atenção!' : 'Erro',
        text: e ? e : 'Erro ao acionar carga!',
      })
      console.log(e)
    }
  }

  

  const loadItems = async () => {
    setLoading(true)
    const winthorActive = await verifyWinthor()
    const totvsVarejoActive = await verifyTotvsVarejo();
    setWinthor(winthorActive)
    setTotvsVarejo(totvsVarejoActive)
    if (totvsVarejoActive) {
      setCharges([
        {
          id: 1,
          name: i18n.t(`integration.price`),
          type: 'price',
        },
        {
          id: 2,
          name: i18n.t(`integration.stock`),
          type: 'balance',
        },
        {
          id: 3,
          name: i18n.t(`integration.products`),
          type: 'product',
        },
        {
          id: 4,
          name: i18n.t(`integration.status_sync`),
          type: 'syncOrder',
        },
      ])
    } else if(winthorActive){
      setCharges([
        {
          id: 1,
          name: i18n.t(`integration.stock`),
          type: 'stocks',
        },
        {
          id: 2,
          name: i18n.t(`integration.classifications`),
          type: 'classifications',
        },
        {
          id: 3,
          name: i18n.t(`integration.products`),
          type: 'products',
        },
        {
          id: 4,
          name: i18n.t(`integration.price`),
          type: 'prices',
        },
        {
          id: 5,
          name: i18n.t(`integration.marks`),
          type: 'marks',
        },
        {
          id: 6,
          name: i18n.t(`integration.orders`),
          type: 'orders',
        },
      ])
    }else {
      setCharges([
        {
          id: 1,
          name: i18n.t(`integration.price`),
          type: 'price',
        },
        {
          id: 2,
          name: i18n.t(`integration.stock`),
          type: 'balance',
        },
        {
          id: 3,
          name: i18n.t(`integration.measurements_units`),
          type: 'measure',
        },
        {
          id: 4,
          name: i18n.t(`integration.grid`),
          type: 'grid',
        },
        {
          id: 5,
          name: i18n.t(`integration.classifications`),
          type: 'classification',
        },
        {
          id: 6,
          name: i18n.t(`integration.products`),
          type: 'product',
        },
        {
          id: 7,
          name: i18n.t(`integration.status_sync`),
          type: 'syncOrder',
        }
      ])
    }
    setLoading(false)
  }

  useEffect(() => {
    loadItems()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  {i18n.t(`integration.loads`)}
                </span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='card-body'>
              <Datatable
                data={charges}
                headers={[
                  {key: i18n.t(`integration.name`), value: 'name', type: 'default'},
                  {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: '',
                    isButton: true,
                    textButton: i18n.t(`integration.load`),
                    action: (item) => {
                      setChargeType(item.type)
                      if(totvsVarejo){
                        Swal.fire({
                          icon: 'warning',
                          title: 'Deseja realizar a carga?',
                          showCloseButton: true,
                          showCancelButton: true,
                          cancelButtonColor: '#dc3741',
                          confirmButtonText: 'Confirmar',
                          cancelButtonText: 'Cancelar',
                        }).then(async (res) => {
                          if (res.isConfirmed) {
                            setLoading(true)
                            await chargeItem(item.type);
                            setLoading(false)
                          }
                        })
                      } else {
                        showWrapperModal(i18n.t(`integration.trigger_load`))
                      }
                    },
                    color: 'info',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalWrapper confirmAction={() => chargeItem()}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='mx-5 flex-grow-1'>
                <Input
                  inputAttr={{type: 'date'}}
                  label={i18n.t(`integration.first`)}
                  value={startDate}
                  change={setStartDate}
                  maxLength={finalDate}
                />
              </div>
              <div className='mx-5 flex-grow-1'>
                <Input
                  inputAttr={{type: 'date'}}
                  label={i18n.t(`integration.end`)}
                  value={finalDate}
                  change={setFinalDate}
                  minLength={startDate}
                />
              </div>
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const Charges: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            title: i18n.t(`integration.loads`),
            path: 'pluginconfig/charge_integration',
            isActive: true,
          },
        ]}
      >
        {i18n.t(`integration.loads`)}
      </PageTitle>
      <ChargesContent />
    </>
  )
}

export {Charges}
