import { FC, ReactNode } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import useModals from '../../../../hooks/useModals'
import { i18n } from '../../../../translate/i18n'
import styles from './ModalWrapper.module.css'

type Props = {
  children: ReactNode
  confirmAction: () => void
  noAction?: boolean
  textAction?: string
  confirmButtomColor?: string
  width?: number
  height?: number
  zIndex?: number
  shouldCloseModal?: boolean
  maxWidth?: boolean
  noCloseButton?: boolean
  noCloseOverlay?: boolean
}

const ModalWrapper: FC<Props> = ({ children, confirmAction, noAction = false, textAction, width, height, confirmButtomColor, shouldCloseModal, maxWidth, noCloseButton, zIndex, noCloseOverlay }) => {
  const { wrapperModal, closeWrapperModal } = useModals()

  return (
    <>
      {wrapperModal.display && (
        <>
          <div onClick={noCloseOverlay ? () => {} : () => closeWrapperModal()} className={styles.background}></div>
          <div className={`${styles.modalCustom} ${maxWidth ? '' : 'modal-dialog'}`} style={{ width: width ? `${width}%` : '60%', zIndex: zIndex ? zIndex : '' }} >
            <div className={`${styles.modalContent} modal-content`} style={height ? { height: `${height}em` } : {}}>
              <div className='modal-header'>
                <h5 className='modal-title'>{wrapperModal.title}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  onClick={() => closeWrapperModal()}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
              <div className={`${noCloseButton ? 'pb-0' : ''} modal-body text-break pt-0`}>{children}</div>
              <div 
                style={noCloseButton ? {border: 'none'} : {}}
                className={`${noCloseButton ? 'modal-footer pt-1 border-none' : 'modal-footer mt-4'} d-flex align-items-center`}
              >
                {noAction === false && (
                  <>
                    <button
                      type='button'
                      className={`btn btn-${confirmButtomColor ? confirmButtomColor : 'primary'} ${noCloseButton ? 'w-100' : 'me-3'}`}
                      onClick={() => {
                        confirmAction()
                        if (shouldCloseModal !== undefined) {

                          if (shouldCloseModal) {
                            closeWrapperModal()
                          }

                        } else {
                          closeWrapperModal()
                        }

                      }}
                    >
                      {textAction ?? i18n.t(`buttons.save`)}
                    </button>
                  </>
                )}
                {!noCloseButton && (
                  <button
                    type='button'
                    className='btn btn-light ms-3'
                    onClick={() => closeWrapperModal()}
                  >
                    {i18n.t(`buttons.close`)}
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { ModalWrapper }
