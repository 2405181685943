import { FC, ReactNode, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import useModals from '../../../../hooks/useModals'
import { i18n } from '../../../../translate/i18n'
import styles from './ModalDelete.module.css'

type Props = {
  confirmAction: () => void
  title?: string
  text?: string
  icon?: JSX.Element
  onClose?: () => void
}

const ModalDelete: FC<Props> = ({ confirmAction, title, text, icon, onClose }) => {
  const { deleteModal, closeDeleteModal } = useModals()
  const [canCloseFunction, setCanCloseFunction] = useState(false);

  useEffect(() => {
    if(deleteModal.display && !canCloseFunction) setCanCloseFunction(true)
    if(!deleteModal.display && onClose && canCloseFunction){
      onClose();
    }
  }, [deleteModal.display])

  return (
    <>
      {deleteModal.display && (
        <>
          <div onClick={() => closeDeleteModal()} className={styles.background}></div>
          <div className={`${styles.modalCustom} modal-dialog w-40`}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{deleteModal.title}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  onClick={() => closeDeleteModal()}
                >
                  <i className="fa fa-times" aria-hidden="true"></i>
                </div>
              </div>
              <div className='modal-body text-break pt-0'>
                <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-2 px-10'>
                  {icon ? icon : (
                    <KTSVG
                      path='/media/icons/duotune/general/gen044.svg'
                      className={`svg-icon ${styles.svgIconFull}`}
                    />
                  )}
                  <h2 className={`mt-2 ${styles.modalText}`}>
                    {title ? title : i18n.t(`general.delete_item`)}
                  </h2>

                  {text &&
                    <span
                      className={`mt-2 ${styles.modalTextComment} text-center w-75`}
                    >{i18n.t(`general.${text}`)}</span>

                  }
                </div>
              </div>
              <div className='modal-footer d-flex align-items-center mt-6 justify-content-center'>
                <button
                  type='button'
                  className='btn btn-danger me-3'
                  onClick={() => {
                    confirmAction()
                    closeDeleteModal()
                  }}
                >
                  {i18n.t(`general.delete`)}
                </button>
                <button
                  type='button'
                  className='btn btn-light ms-3'
                  onClick={() => closeDeleteModal()}
                >
                  {i18n.t(`buttons.close`)}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { ModalDelete }
