import { FC, ReactNode } from 'react'
import useModals from '../../../hooks/useModals'
import { i18n } from '../../../translate/i18n'
import styles from './modalImageCrop.module.css'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";


type Props = {
    imageCrop: any
    setCropper: (value
        : any) => void
    getCropData: () => void
}

const ModalImagemCropped: FC<Props> = ({ imageCrop, setCropper, getCropData }) => {
    const { imageCroppedModal, closeImageCropped } = useModals()

    const cropImage = () => {

        getCropData()
        closeImageCropped()
    }


    return (
        <>
            {imageCroppedModal.display && (
                <>
                    <div onClick={() => closeImageCropped()} className={styles.background}></div>
                    <div className={`${styles.modalCustom} modal-dialog`} style={{ width: '60%' }} >
                        <div className={`${styles.modalContent} modal-content`} style={{ height: '70rem' }}>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Crop your image</h5>
                                <div
                                    className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                                    onClick={() => closeImageCropped()}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div className='modal-body text-break pt-4 d-flex justify-content-center'>
                                <div>
                                    <Cropper
                                        src={imageCrop ? imageCrop : ''}
                                        style={{ height: 200, width: 200 }}
                                        initialAspectRatio={4 / 3}
                                        minCropBoxHeight={100}
                                        minCropBoxWidth={100}
                                        guides={false}
                                        checkOrientation={false}
                                        onInitialized={(instance: any) => {
                                            setCropper(instance);
                                        }}
                                    />

                                    <div className='d-flex justify-content-center'>
                                        <button className='btn btn-dark p-2 mt-2' onClick={cropImage}>{i18n.t(`smart_order.crop_image`)}</button>
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer d-flex align-items-center mt-4'>

                                <button
                                    type='button'
                                    className='btn btn-light ms-3'
                                    onClick={() => closeImageCropped()}
                                >
                                    {i18n.t(`buttons.close`)}
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )

            }

        </>
    )
}

export { ModalImagemCropped }