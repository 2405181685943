/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent, MouseEvent } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType, OrderType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, PUT, DELETE, POST } from '../../../../services/api'
import useModals from '../../../../hooks/useModals'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import { Dropdown } from 'react-bootstrap'
import { i18n } from '../../../../translate/i18n'
import './index.css'
import { usingSDS } from '../../../../utils/utils'
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ImageType = {
  path: string
  imgsuffix: string
  productId: number
  typeimg: string
  order: number
}

type categories = {
  value: string
  select: string
}

type filter = {
  order?: OrderType
  classificationId?: string
  status?: boolean
  productTimer?: boolean
  type?: string
}

const PageContent: FC = () => {
  const { loginInfo } = useAuth()
  const navigate = useNavigate()
  const { showDeleteModal, showWrapperModal, closeWrapperModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const [confirmText, setConfirmText] = useState('')
  const [action, setAction] = useState<'deleteAllProduct' | 'importProduct' | 'alterLote' | 'sincSDS'>(
    'deleteAllProduct'
  )
  const [fileToImport, setFileToImport] = useState<File | null>(null)
  const [hasMenu, setHasMenu] = useState(false)

  const [wrongText, setWrongText] = useState(false)

  const [header, setHeader] = useState()

  const [filterTempo, setFilterTempo] = useState<any>({
    label: 'Ambos',
    value: 2,
  })
  const [filterStatus, setFilterStatus] = useState<any>({
    label: 'Ambos',
    value: 2,
  })
  const [filterCategoria, setFilterCategoria] = useState<any>({
    label: 'Todos',
    value: 0,
  })
  const [categoria, setCategoria] = useState<categories[]>([])

  const [hasSDS, setHasSDS] = useState(false)

  const [TypeModal, setTypeModal] = useState('')

  const [changeTimeLote, setChangeTimeLote] = useState('')

  const [changeStatusLote, setChangeStatusLote] = useState(false)

  const [qtdSelected, setQtdSelected] = useState(0)

  const [options, setOptions] = useState([
    {
      icon: 'fas fa-pen',
      title: 'Editar categoria',
      action: (item: any) => {
        navigate(`/product_food/editar/${item.id}`)
      },
    },
  ])

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchProduct = await PUT(`/product/${Id}`, {
        status: !status,
      })
      if (switchProduct.success) {
        const newProducts = products.map((product) => {
          if (product.id === Id) {
            product.status = !status
          }
          return product
        })
        setProducts(newProducts)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getProducts = async (take: number = 10, page: number = 1, search: string = '') => {
    try {
      const res = await GET(`/product?page=${page}&take=${take}&search=${search}`)
      const newProducts = res.data.map((row: any) => {
        let imgProduct = ''
        if (row.images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
          let imgFind = row.images.find((img: ImageType) => img.typeimg === 'imgsmall')
          if (imgFind) {
            imgProduct =
              imgFind.path.substring(7, imgFind.path.length) + '/' ===
                process.env.REACT_APP_AWS_S3_URL.substring(7, process.env.REACT_APP_AWS_S3_URL.length)
                ? imgFind.path + '/' + imgFind.imgsuffix
                : imgFind.path
          }
        }
        return {
          id: row.id,
          code: row.code,
          order: row.order ?? 0,
          img: imgProduct,
          name: row.name,
          category: row.classification && row.classification.name,
          averageTime: row.productTimer ? row.productTimer.averageTime : '00:00:00',
          status: row.status,
        }
      })
      setProducts(newProducts)
      setMeta(res.meta)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteProduct = async () => {
    try {
      setLoading(true)
      const markRes = await DELETE(`/product/${id}`)
      if (markRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Produto deletado',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getProducts())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o produto!',
        }).then(async () => await getProducts())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o produto!',
      }).then(async () => await getProducts())
      console.log(e)
    }
  }

  const deleteAllProduct = async () => {
    if (confirmText !== 'excluir') {
      setConfirmText('')
      setWrongText(true)
    } else {
      closeWrapperModal()
      try {
        setLoading(true)
        const res = await DELETE('product-food/delete-all')
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Produtos deletados',
            text: 'Deleção realizada com sucesso!',
          }).then(async () => await getProducts())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao deletar o produtos!',
          }).then(async () => await getProducts())
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o produto!',
        }).then(async () => await getProducts())
        console.log(e)
      }
    }

  }

  const getHasMenu = async () => {
    try {
      const resMenu = await POST('order-food/has-menu')
      if (resMenu) {
        setHasMenu(resMenu.success)

        if (!resMenu.success) {
          setOptions([
            {
              icon: 'fas fa-pen',
              title: 'Editar categoria',
              action: (item) => {
                navigate(`/product_food/editar/${item.id}`)
              },
            },
            {
              icon: 'fas fa-trash',
              action: (item) => {
                showDeleteModal('Excluir produto')
                setId(item.id)
              },
              title: 'Excluir produto',
            }
          ])
        }


      }
    } catch (error) {
      console.log(error)
    }
  }

  const getMenu = async () => {
    try {
      setLoading(true)
      const resMenu = await POST('order-food/get-menu')
      if (resMenu) {
        if (resMenu.success && resMenu.dados) {
          Swal.fire({
            icon: 'warning',
            title:
              products.length === 0
                ? `Importar ${resMenu.dados.Produtos.length} produto(s)?`
                : `Atualizar ${resMenu.dados.Produtos.length} produto(s)?`,
            html:
              products.length === 0
                ? `<p style="font-weight: 500">Seus produtos e adicionais serão importados do PDV (Combos e Kits não serão importados).</p>`
                : `<p style="font-weight: 500">Seus produtos serão atualizados de acordo com os dados que estão no PDV.</p>`,
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonColor: '#dc3741',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
          }).then(async (res) => {
            if (res.isConfirmed) {
              setLoading(true)
              const resConfirm = await POST('order-food/confirm-menu')
              if (resConfirm) {
                await getProducts()
                Swal.fire({
                  icon: 'success',
                  title: `${products.length === 0 ? 'Obter' : 'Atualizar'} cardápio`,
                  text: `Cardápio ${products.length === 0 ? 'cadastrado' : 'atualizar'
                    } com sucesso!`,
                })
              }
              setLoading(false)
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text:
              resMenu.message ||
              `Erro ao ${products.length === 0 ? 'Obter' : 'Atualizar'} cardápio!`,
          })
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: `Erro ao ${products.length === 0 ? 'Obter' : 'Atualizar'} cardápio!`,
      })
    } finally {
      setLoading(false)
    }
  }

  const getSDS = async () => {
    try {
      const response = await usingSDS()

      setHasSDS(response)

    } catch (error) {
      console.log(error)
    }
  }

  const syncSDS = async () => {
    try {
      POST('product-timer/syncSDS')

      Swal.fire({
        icon: 'info',
        title: 'Sincronismo',
        text: 'Sincronismo iniciado com sucesso.',
      })
    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao tentar sincronizar com o sds',
      })
    }
  }

  const importFile = async () => {
    try {
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToImport)

        const importRes = await POST('/product/import', formData)
        setLoading(false)
        if (importRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Importação realizada com sucesso!',
          }).then(async () => await getProducts())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao importar arquivo!',
          }).then(async () => await getProducts())
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getProducts())
    }
    setFileToImport(null)
  }

  const changeAverageTime = async (item: any, valueChange: string) => {
    let objectToChange: any = {
      averageTime: item[valueChange],
      code: item['code'],
      name: item['name'],
      productId: item['id'],
    }
    objectToChange[valueChange] = item[valueChange]

    try {
      setLoading(true)
      const createUpdateProductTimer = await POST(`/product-timer`, objectToChange)

      if (!createUpdateProductTimer || !createUpdateProductTimer.success) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao tentar atualizar o tempo do produto',
        })
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Atualização concluida',
          text: 'Atualização do tempo do produto feita com sucesso',
        })
      }
      console.log('e')
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao tentar atualizar o tempo do produto',
      }).then(async () => await getProducts())
    }
  }

  const getCategories = async () => {
    try {
      const resCategorias = await POST(`/classification/filter`, {
        status: true,
        /*O Campo abaixo só é utilizando para o Hotel, 
        nessa rota a troca do pluginType dele ocorre usando esse campo 
        */
        plugintypeId: 7
      })

      const categories = resCategorias.data.map((el: any) => {
        return {
          value: el.id,
          select: el.name,
        }
      })

      categories.push({
        select: 'TODOS',
        value: 0,
      })

      categories.sort((a: any, b: any) =>
        a.select.toUpperCase() > b.select.toUpperCase()
          ? 1
          : a.select.toUpperCase() < b.select.toUpperCase()
            ? -1
            : 0
      )

      setCategoria(categories)

    } catch (error) {
      console.log(error)
    }
  }

  const filterProdutos = async (take: number = 100, page: number = 1, search: string = '', order: OrderType = {field: 'name', type: 'ASC'}) => {
    if (!filterCategoria || !filterStatus || !filterTempo) {
      Swal.fire({
        icon: 'error',
        title: 'Filtros não selecionados',
        text: 'Selecione corretamente os filtros',
      })
    } else {
      try {
        setQtdSelected(0)

        const newStatus = filterStatus.value == '1' ? true : false
        const timerStatus = filterTempo.value == '1' ? true : false

        const filter: filter = {
          status: newStatus,
          classificationId: filterCategoria.value,
          productTimer: timerStatus,
          order
        }

        filterStatus.value == '2' ? delete filter['status'] : (filter['status'] = filter['status'])

        filterTempo.value == '2'
          ? delete filter['productTimer']
          : (filter['productTimer'] = filter['productTimer'])

        filterCategoria.value === 0
          ? delete filter['classificationId']
          : (filter['classificationId'] = filter['classificationId'])

        const filteredProducts = await POST(
          `product/filter?page=${page}&take=${take}&search=${search}`,
          filter
        )

        const newProducts = filteredProducts.data.map((row: any) => {
          let imgProduct = ''
          if (row.images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
            let imgFind = row.images.find((img: ImageType) => img.typeimg === 'imgsmall')
            if (imgFind) {
              imgProduct =
                imgFind.path.substring(7, imgFind.path.length) + '/' ===
                  process.env.REACT_APP_AWS_S3_URL.substring(
                    7,
                    process.env.REACT_APP_AWS_S3_URL.length
                  )
                  ? imgFind.path + '/' + imgFind.imgsuffix
                  : imgFind.path
            }
          }
          return {
            id: row.id,
            selected: false,
            code: row.code,
            order: row.order ?? 0,
            img: imgProduct,
            name: row.name,
            category: row.classification && row.classification.name,
            averageTime: row.productTimer ? row.productTimer.averageTime : '00:00:00',
            status: row.status,
          }
        })
        setProducts(newProducts)
        setMeta(filteredProducts.meta)
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao filtrar os produto',
        }).then(async () => await getProducts())
      }
    }
  }

  const changeInLote = async (typeModal: string) => {
    try {
      const productsToUpdate = products.filter((el) => el.selected === true)

      setLoading(true)
      if (typeModal == 'Status') {
        const switchProduct = await PUT(`/product/massiveUpdate`, {
          status: changeStatusLote,
          data: productsToUpdate,
        })
        setChangeStatusLote(false)
        if (switchProduct.success) {
          const newProducts = products.map((product) => {
            if (product.selected) {
              product.status = changeStatusLote
            }
            product.selected = false
            return product
          })
          setProducts(newProducts)
        }
      } else {
        const prodToUpdate = productsToUpdate.map((el) => {
          return {
            name: el.name,
            code: el.code,
            productId: el.id,
          }
        })

        const switchProduct = await PUT(`/product-timer/massiveUpdate`, {
          time: changeTimeLote,
          data: prodToUpdate,
        })
        if (switchProduct.success) {
          const newProducts = products.map((product) => {
            if (product.selected) {
              product.averageTime = changeTimeLote
            }
            product.selected = false
            return product
          })
          setProducts(newProducts)
        }
      }

      setQtdSelected(0)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao atualiar os produto',
      })
    }
  }

  const switchSelected = async (Id: number, status: boolean) => {
    try {
      const newProducts = products.map((el) => {
        if (el.id === Id) {
          el.selected = !el.selected
        }

        return el
      })

      const filterdArray = newProducts.filter((el) => el.selected == true)

      setQtdSelected(filterdArray.length)

      setProducts(newProducts)
    } catch (e) {
      console.log(e)
    }
  }

  function selecteAll() {
    try {
      setLoading(true)
      const newProducts = products.map((el) => {
        el.selected = !el.selected

        return el
      })
      setProducts(newProducts)

      const filterdArray = newProducts.filter((el) => el.selected == true)

      setQtdSelected(filterdArray.length)

      setTimeout(() => {
        setLoading(false)
      }, 1)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  async function modalFuctions(action: 'deleteAllProduct' | 'importProduct' | 'alterLote' | 'sincSDS') {

    switch (action) {
      case 'deleteAllProduct':
        return deleteAllProduct()
      case 'importProduct':
        return importFile()
      case 'alterLote':
        return changeInLote(TypeModal)
      case 'sincSDS':
        return syncSDS()
      default:
        return null
    }
  }

  async function init() {
    setLoading(true)
    await getCategories()
    await getHasMenu()
    await getSDS()
    await filterProdutos()
    setLoading(false)
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Produtos</span>
                <BreadCrumbs />
                {/* <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listadas os produtos juntamente com suas respectivas ações.
                </span> */}
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Cadastrar Produto'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() =>
                    navigate(
                      loginInfo.plugintype === 'food'
                        ? '/product_food/criar'
                        : '/product_hospitality/criar'
                    )
                  }
                  btnClass='mb-0 fw-bolder'
                />

                <Dropdown
                  className='btn btn-primary btn-sm fw-bolder me-5 p-0'
                  style={{ marginLeft: '1em' }}
                >
                  <Dropdown.Toggle variant='default' size='sm'>
                    <i className='bi bi-three-dots fs-4 p-0'></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-0'>
                    {hasMenu && (
                      <>
                        <Dropdown.Item
                          onClick={getMenu}
                          className='p-0 d-flex flex-row justify-content-center'
                        >
                          <div
                            className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                            style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                          >
                            {products.length === 0 ? (
                              <i
                                className='bi bi-download'
                                style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}
                              ></i>
                            ) : (
                              <i
                                className='bi bi-arrow-clockwise'
                                style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}
                              ></i>
                            )}
                            <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                              {`${products.length === 0 ? 'Obter' : 'Atualizar'} cardápio`}
                            </p>
                          </div>
                        </Dropdown.Item>
                      </>
                    )}
                    {!hasMenu && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            setAction('importProduct')
                            showWrapperModal('Importar CSV')
                          }}
                          className='p-0 d-flex flex-row justify-content-center'
                        >
                          <div
                            className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                            style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                          >
                            <i
                              className='bi bi-box-seam'
                              style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}
                            ></i>
                            <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                              Importar CSV
                            </p>
                          </div>
                        </Dropdown.Item>
                      </>
                    )}
                    {hasSDS && (
                      <>
                        <Dropdown.Item
                          onClick={() => {
                            setAction('sincSDS')
                            showWrapperModal(`Sincronizar produtos SDS`)
                            /*
                            Swal.fire({
                              title: "Sincronizar Produtos com o SDS",
                              text: `Esse processo pode demorar dependendo da quantidade de produtos.
                               Para verificar o andamento do sincronismo, observar as informações no LOG`,
                              icon: "info",
                              showCancelButton: true,
                              confirmButtonText: "Sincronizar!",
                              cancelButtonText: "Cancelar!",
                              reverseButtons: true,
                              customClass: {
                                confirmButton: "btn btn-success",
                                cancelButton: "btn btn-danger"
                              },
                              buttonsStyling: false
                            }).then((result) => {
                              if (result.isConfirmed) {
                                Swal.fire({
                                  title: "Deleted!",
                                  text: "Your file has been deleted.",
                                  icon: "success"
                                });
                              }
                            });
                            */

                            //syncSDS()
                          }}
                          className='p-0 d-flex flex-row justify-content-center'
                        >
                          <div
                            className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                            style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                          >
                            <i
                              className='bi bi-arrow-repeat'
                              style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}
                            ></i>
                            <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                              Sincronizar SDS
                            </p>
                          </div>
                        </Dropdown.Item>
                      </>
                    )}
                    {products.length > 0 && (
                      <>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => {
                            setAction('deleteAllProduct')
                            showWrapperModal('Deletar todos os produtos')

                            /*
                            Swal.fire({
                              icon: 'warning',
                              title: `Atenção!`,
                              html: `
                              Cuidado ao realizar a exclusão, os dados não poderão ser <b>restaurados!<b/>
                              <div style="margin-top: 8">Digite <b>excluir</b> para confirmar!</div>
                              ${wrongText ? '<div style="margin-top: 8">Digite <b>excluir</b> para confirmar!</div>' : ''}
                              `,
                              showCloseButton: true,
                              showConfirmButton: true,
                              confirmButtonColor: '#dc3741',
                              confirmButtonText: 'Excluir',
                              showCancelButton: false,
                              input: 'text',
                              inputPlaceholder: 'excluir',
                              preConfirm: (text) => {
                                setConfirmText(text)
                              },
                            }).then(async (res) => {
                              if (res.isConfirmed && res.value && res.value === 'excluir') {
                                await deleteAllProduct()
                              } else {
                                Swal.fire({
                                  icon: 'warning',
                                  title: `Atenção!`,
                                  html: `
                                  Cuidado ao realizar a exclusão, os dados não poderão ser <b>restaurados!<b/>
                                  <div style="margin-top: 8">Digite <b>excluir</b> para confirmar!</div>
                                  ${wrongText ? '<div style="margin-top: 8">Digite <b>excluir</b> para confirmar!</div>' : ''}
                                  `,
                                  showCloseButton: true,
                                  showConfirmButton: true,
                                  confirmButtonColor: '#dc3741',
                                  confirmButtonText: 'Excluir',
                                  showCancelButton: false,
                                  input: 'text',
                                  inputPlaceholder: 'excluir',
                                  preConfirm: (text) => {
                                    setConfirmText(text)
                                  },
                                }).then(async (res) => {
                                  if (res.isConfirmed && res.value && res.value === 'excluir') {
                                    await deleteAllProduct()
                                  }
                                })
                              }
                            })*/
                          }}
                          className='p-0 d-flex flex-row justify-content-center'
                        >
                          <div
                            className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                            style={{ color: '#F64E60', backgroundColor: '#FFE2E5', width: '90%' }}
                          >
                            <i
                              className='bi bi-x'
                              style={{ color: '#F64E60', fontSize: '1.5rem', marginRight: '0.5em' }}
                            ></i>
                            <p style={{ color: '#F64E60', fontSize: '1rem' }} className='m-0'>
                              Excluir tudo
                            </p>
                          </div>
                        </Dropdown.Item>
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='d-flex w-50 '>
                  <div className=' align-items-end  justify-content-start me-5 flex-grow-1'>
                    <span className='align-self-start'>Categorias</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={categoria}
                      placeholder={'Categoria'}
                      search={true}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                  </div>

                  <div className=' flex-grow-1 align-items-center me-5 justify-content-between'>
                    <span className='align-self-start'>Status</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={[
                        {
                          value: 1,
                          select: 'Ativo',
                        },
                        {
                          value: 0,
                          select: 'Inativo',
                        },
                        {
                          value: 2,
                          select: 'Ambos',
                        },
                      ]}
                      value={filterStatus}
                      placeholder={'Status do Produto'}
                      search={true}
                      change={(value: any) => setFilterStatus(value)}
                    />
                  </div>

                  {hasSDS && (
                    <div className='align-items-center me-5 flex-grow-1'>
                      <span className='align-self-start'>Tempo</span>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        options={[
                          {
                            value: 1,
                            select: 'Sim',
                          },
                          {
                            value: 0,
                            select: 'Não',
                          },
                          {
                            value: 2,
                            select: 'Ambos',
                          },
                        ]}
                        placeholder={'Tempo'}
                        value={filterTempo}
                        search={true}
                        change={(value: any) => setFilterTempo(value)}
                      />
                    </div>
                  )}
                </div>

                <div
                  className='d-flex align-items-end w-50 justify-content-end h-100'
                  style={{
                    marginTop: '48px',
                  }}
                >
                  <Button
                    text='Filtrar'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-filter'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={async () => {
                      setLoading(true);
                      await filterProdutos()
                      setLoading(false);
                    }}
                    btnClass='mb-0 fw-bolder'
                  />
                </div>
              </div>
              <div className='pt-4'>
                <Dropdown className='btn btn-primary btn-sm fw-bolder me-5 p-0'>
                  {qtdSelected === 0 ? (
                    <Dropdown.Toggle variant='sm' disabled>
                      <span style={{ color: '#FFF', width: '90%' }}>
                        Alterar em Lote ({qtdSelected})
                      </span>
                    </Dropdown.Toggle>
                  ) : (
                    <Dropdown.Toggle variant='sm'>
                      <span style={{ color: '#FFF', width: '90%' }}>
                        Alterar em Lote ({qtdSelected})
                      </span>
                    </Dropdown.Toggle>
                  )}
                  <Dropdown.Menu className='p-0'>
                    <Dropdown.Item
                      size='sm'
                      onClick={() => {
                        setAction('alterLote')
                        setTypeModal('Status')
                        showWrapperModal('Alterar Produtos')
                      }}
                      className='p-0 d-flex flex-row justify-content-center'
                    >
                      <div
                        className='btn m-2  btn-sm d-flex flex-row justify-content-center align-items-center'
                        style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                      >
                        <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                          Status
                        </p>
                      </div>
                    </Dropdown.Item>
                    {hasSDS && (
                      <Dropdown.Item
                        size='sm'
                        onClick={() => {
                          setAction('alterLote')
                          setTypeModal('Time')
                          showWrapperModal('Alterar Produtos')
                        }}
                        className='p-0 d-flex flex-row justify-content-center'
                      >
                        <div
                          className='btn btn-sm m-2 d-flex flex-row justify-content-center align-items-center'
                          style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                        >
                          <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                            Tempo de Produção
                          </p>
                        </div>
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  text={'Selecionar todos'}
                  color='primary'
                  iconColor='#FFF'
                  size='small'
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    selecteAll()
                  }}
                  btnClass='fw-bolder me-5'
                />
              </div>

              <Datatable
                data={products}
                headers={[
                  {
                    key: '',
                    value: 'selected',
                    type: 'checkBox',
                    action: (item: any) => switchSelected(item.id, item.status),
                    size: 'sm',
                  },
                  { key: 'Código', value: 'code', type: 'default' },
                  { key: 'Ordem', value: 'order', type: 'default' },
                  { key: 'Imagem', value: 'img', type: 'image', noOrder: true },
                  { key: 'Nome', value: 'name', type: 'default' },
                  { key: 'Categoria', value: 'category', type: 'default', noOrder: true },
                  {
                    key: 'Tempo de Produção',
                    value: 'averageTime',
                    type: 'input-time',
                    action: (item: any) => changeAverageTime(item, 'averageTime'),
                    show: hasSDS,
                    noOrder: true
                  },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item: any) => switchStatus(item.id, item.status),
                  },
                  { key: 'Ações', value: 'acoes', type: '', noOrder: true },
                ]}
                options={options}
                color='primary'
                meta={meta}
                onChangeData={async (take, page, search, order) =>
                  await filterProdutos(take, page, search, order)
                }
                search
              />
            </div>
          </div>
          <ModalDelete confirmAction={() => deleteProduct()} />
          <ModalWrapper
            confirmAction={() => modalFuctions(action)
            }
            textAction={action === 'deleteAllProduct' ? 'Excluir' : action === 'sincSDS' ? 'Sincronizar' : undefined}
            confirmButtomColor={action === 'deleteAllProduct' ? "danger" : ''}
            shouldCloseModal={action === 'deleteAllProduct' ? false : true}
          >
            {action === 'deleteAllProduct' && (
              <div className='mt-2 w-100 d-flex flex-column'>
                <div className='d-flex justify-content-center p-8 text-yellow-200'>
                  <i className="bi bi-exclamation-circle"
                    style={{
                      fontSize: "5rem",
                      color: '#f1bd00'
                    }}></i>
                </div>
                <span className='w-100 text-center'> Cuidado ao realizar a exclusão, os dados não poderão ser <b>restaurados!</b></span>

                <div className="mt-4">  <span className='w-100 text-center'>Digite <b>excluir</b> para confirmar!</span></div>

                {wrongText ? <div className="mt-1">  <span className='w-100 text-center text-danger'>*Texto incorreto</span></div>
                  : ''}



                <div className='row'>
                  <div className='col-12'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='excluir'
                      value={confirmText}
                      change={(value: string) => {
                        setConfirmText(value)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {action === 'importProduct' && (
              <>
                <div className='flex-grow-1'>
                  <p>{i18n.t(`general.attention`)}</p>
                  <p>
                    Para importar produtos a primeira célula do arquivo tem que seguir o seguinte
                    padrõe:
                  </p>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        width: '70%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                      }}
                    >
                      Para importar produtos: codigo, nome, descricao, subcategoria, ordem, preco
                    </div>
                    <div style={{ width: '30%' }}>
                      <Button
                        text={'Modelo'}
                        color='primary'
                        iconColor='#FFF'
                        size='small'
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          window.open('/file/modelo-produtos.xlsx', '')
                        }}
                        btnClass='fw-bolder me-5'
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className='flex-grow-1 text-center'>
                  <Input
                    inputAttr={{
                      type: 'file',
                    }}
                    acceptFile={'.xlsx,.xls'}
                    change={(value: BaseSyntheticEvent) =>
                      setFileToImport(value ? value.target.files[0] : null)
                    }
                  />
                </div>
              </>
            )}
            {action === 'alterLote' && (
              <>
                <div className='mt-1'>
                  <span>Os itens abaixo serão alterados</span>
                </div>
                <div
                  className='d-flex flex-column overflow-scroll'
                  style={{
                    height: '8rem',
                    border: '1px solid #949392',
                  }}
                >
                  {products.map((elemento) => {
                    if (elemento.selected === true) {
                      return (
                        <span className='ms-2'>
                          {elemento.code} - {elemento.name}
                        </span>
                      )
                    }
                  })}
                </div>

                <div>
                  {TypeModal === 'Status' ? (
                    <>
                      <div className='d-flex flex-column mt-2'>
                        <span>Status</span>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          checkSwift={true}
                          value={changeStatusLote}
                          change={(value: boolean) => {
                            setChangeStatusLote(value)
                            console.log(value)
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='d-flex flex-column mt-2'>
                        <span>Tempo máximo de produção (Minutos)</span>
                        <div className='d-flex'>
                          <Input
                            inputAttr={{
                              type: 'time',
                            }}
                            placeholder='00:00:00'
                            value={changeTimeLote}
                            change={(value: string) => setChangeTimeLote(value)}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            {action === 'sincSDS' && (
              <div className='mt-2 w-100 d-flex flex-column'>
                <div className='d-flex justify-content-center p-8 text-yellow-200'>
                  <i className="bi bi-exclamation-circle"
                    style={{
                      fontSize: "5rem",
                      color: '#f1bd00'
                    }}></i>
                </div>
                <div className="mt-4">  <span className='w-100 text-center'>Esse processo pode demorar dependendo da quantidade de produtos.
                  Para verificar o andamento do sincronismo, observar as informações na página de Suporte na aba LOG </span></div>
              </div>
            )

            }
          </ModalWrapper>
        </div >
      )}
    </>
  )
}

const Produtos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Produtos', path: 'product_food', isActive: true },
        ]}
      >
        Produtos
      </PageTitle>
      <PageContent />
    </>
  )
}

export { Produtos }
