import { Editor } from '@tinymce/tinymce-react'
import { BaseSyntheticEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import useAuth from '../../../../hooks/useAuth'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../../hooks/useModals'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type AppconfigResponseType = {
  name: string
  locationId: number
  info: string
  color: string
  background: null | string
  banner: null | string
  img: null | string
  icon: null | string
  content: {
    noShadow?: boolean
    qtdProductsPagination: number
    useLogoMenu: boolean
    colorLogo?: string
    useLogoTotvs?: boolean
    pdv: any
    orderCategory: string
    useCategoryList?: boolean
  }
}

type AppconfigResponseSendType = {
  name?: string
  locationId?: number
  info?: string
  background?: null | string
  banner?: null | string
  img?: null | string
  icon?: null | string,
  color?: string
  content?: {
    noShadow?: boolean
    qtdProductsPagination?: number
    useLogoMenu?: boolean
    colorLogo?: string
    useLogoTotvs?: boolean
    pdv?: any
    orderCategory?: string
    webappcolor?: {
      backgroundbuttonhome?: string
      backgroundcart?: string
      backgroundfooter?: string
      backgroundheader?: string
      backgroundmenu?: string
      backgroundstatus?: string
      backgroundstatusactive?: string
      backgroundtitlebar?: string
      backgroundwebapp?: string
      fontbutton?: string
      fontbuttonhome?: string
      fontcart?: string
      fontcategory?: string
      fontheader?: string
      fontinfocart?: string
      fontmenu?: string
      fontstatus?: string
      fonttitlebar?: string
      highlightfooter?: string
      iconsfooter?: string
      iconsheader?: string
      icontitlebar?: string
      webappbutton?: string
    }
    useCategoryList?: boolean
  }
}

type LocationResponseType = {
  id: number
  name: string
  type: string
}


type SlidersImage = {
  image: File,
  order: number
}


type ImageSliderAggregator = {
  id: number;
  pluginspaceId: number;
  aggregatorId: number;
  path: string;
  imgSuffix: string;
  order: number;
  typeImg: string;
  created: string;
  updated: string;
  deleted?: any;
}

const EditarWebappContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal, closeWrapperModal, showDeleteModal } = useModals()
  const { id } = useParams()
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [locationId, setLocationId] = useState<null | number>(null)
  const [info, setInfo] = useState('')
  const [background, setBackground] = useState<File | null | string>()
  const [banner, setBanner] = useState<File | null | string>()
  const [img, setImg] = useState<File | null | string>()
  const [icon, setIcon] = useState<File | null | string>()
  const [firstStateBackground, setFirstStateBackground] = useState<null | string>()
  const [firstStateBanner, setFirstStateBanner] = useState<null | string>()
  const [firstStateImg, setFirstStateImg] = useState<null | string>()
  const [firstStateIcon, setFirstStateIcon] = useState<null | string>()
  const [defaultColor, setDefaultColor] = useState<string>()

  const [qtdProductsPagination, setQtdProductsPagination] = useState(10)
  const [useLogoMenu, setUseLogoMenu] = useState(false)
  const [colorLogo, setColorLogo] = useState('black')
  const [useLogoTotvs, setUseLogoTotvs] = useState(false)
  const [backgroundbuttonhome, setBackgroundbuttonhome] = useState<string>('')
  const [backgroundcart, setBackgroundcart] = useState<string>('')
  const [backgroundfooter, setBackgroundfooter] = useState<string>('')
  const [backgroundheader, setBackgroundheader] = useState<string>('')
  const [backgroundmenu, setBackgroundmenu] = useState<string>('')
  const [backgroundstatus, setBackgroundstatus] = useState<string>('')
  const [backgroundstatusactive, setBackgroundstatusactive] = useState<string>('')
  const [backgroundtitlebar, setBackgroundtitlebar] = useState<string>('')
  const [backgroundwebapp, setBackgroundwebapp] = useState<string>('')
  const [fontbutton, setFontbutton] = useState<string>('')
  const [fontbuttonhome, setFontbuttonhome] = useState<string>('')
  const [fontcart, setFontcart] = useState<string>('')
  const [fontcategory, setFontcategory] = useState<string>('')
  const [fontheader, setFontheader] = useState<string>('')
  const [fontinfocart, setFontinfocart] = useState<string>('')
  const [fontmenu, setFontmenu] = useState<string>('')
  const [fontstatus, setFontstatus] = useState<string>('')
  const [fonttitlebar, setFonttitlebar] = useState<string>('')
  const [highlightfooter, setHighlightfooter] = useState<string>('')
  const [iconsfooter, setIconsfooter] = useState<string>('')
  const [iconsheader, setIconsheader] = useState<string>('')
  const [icontitlebar, setIcontitlebar] = useState<string>('')
  const [webappbutton, setWebappbutton] = useState<string>('')
  const [pdv, setPdv] = useState<any>()
  const [useCategoryList, setUseCategoryList] = useState(false)
  const [noShadow, setNoShadow] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [orderCategory, setOrderCategory] = useState('category')

  const [canAlert, setCanAlert] = useState(false);

  /* Campos utilizados para o slider do agregador */

  const [slidesImageAggregator, setSlidesImageAggregator] = useState<any>()
  const [slidesImageAggregatorOrder, setSlidesImageAggregatorOrder] = useState<number>(0)

  const [slidesImageAggregatorPreview, setSlidesImageAggregatorPreview] = useState<SlidersImage[]>([])

  const [ImageSlidesAggregator, setImageSlidesAggregator] = useState<ImageSliderAggregator[]>([])

  const [idImageSlider, setIdImageSlider] = useState<number>(0)

  const getAppconfig = async () => {
    try {
      setLoading(true)
      const appRes = await GET(`/appconfig/${id}`)
      const appconfig: AppconfigResponseType = appRes.data[0]
      setName(appconfig.name)
      setLocationId(appconfig.locationId)
      setInfo(appconfig.info)

      setDefaultColor(appconfig.color)

      setBackground(
        appconfig.background && appconfig.background.substring(0, 4) === 'http'
          ? appconfig.background
          : appconfig.background
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.background
            : null
      )
      setBanner(
        appconfig.banner && appconfig.banner.substring(0, 4) === 'http'
          ? appconfig.banner
          : appconfig.banner
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.banner
            : null
      )
      setImg(
        appconfig.img && appconfig.img.substring(0, 4) === 'http'
          ? appconfig.img
          : appconfig.img
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.img
            : null
      )
      setIcon(
        appconfig.icon && appconfig.icon.substring(0, 4) === 'http'
          ? appconfig.icon
          : appconfig.icon
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.icon
            : null
      )
      setFirstStateBackground(
        appconfig.background && appconfig.background.substring(0, 4) === 'http'
          ? appconfig.background
          : appconfig.background
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.background
            : null
      )
      setFirstStateBanner(
        appconfig.banner && appconfig.banner.substring(0, 4) === 'http'
          ? appconfig.banner
          : appconfig.banner
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.banner
            : null
      )
      setFirstStateImg(
        appconfig.img && appconfig.img.substring(0, 4) === 'http'
          ? appconfig.img
          : appconfig.img
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.img
            : null
      )
      setFirstStateIcon(
        appconfig.icon && appconfig.icon.substring(0, 4) === 'http'
          ? appconfig.icon
          : appconfig.icon
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.icon
            : null
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }

  const saveAppconfig = async () => {
    try {
      if (!name || !locationId) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        setLoading(true)

        let appconfigData: AppconfigResponseSendType = {
          name,
          info,
          locationId,
          color: defaultColor
        }
        if (img && img !== firstStateImg) {
          if (firstStateImg) deleteImage(firstStateImg)
          const formData = new FormData()
          formData.append('file', img)
          formData.append('nameImage', `appconfig-logo-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.img = imageRes.success ? imageRes.image : ''
        }
        if (!img || img === '') {
          appconfigData.img = img
        }

        if (icon && icon !== firstStateIcon) {
          if (firstStateIcon) deleteImage(firstStateIcon)
          const formData = new FormData()
          formData.append('file', icon)
          formData.append('nameImage', `appconfig-icon-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.icon = imageRes.success ? imageRes.image : ''
        }
        if (!icon || icon === '') {
          appconfigData.icon = icon
        }

        if (banner && banner !== firstStateBanner) {
          if (firstStateBanner) deleteImage(firstStateBanner)
          const formData = new FormData()
          formData.append('file', banner)
          formData.append('nameImage', `appconfig-banner-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.banner = imageRes.success ? imageRes.image : ''
        }
        if (!banner || banner === '') {
          appconfigData.banner = banner
        }

        if (background && background !== firstStateBackground) {
          if (firstStateBackground) deleteImage(firstStateBackground)
          const formData = new FormData()
          formData.append('file', background)
          formData.append('nameImage', `appconfig-background-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.background = imageRes.success ? imageRes.image : ''
        }
        if (!background || background === '') {
          appconfigData.background = background
        }
        const appconfigRes = await PUT(`/appconfig/${id}`, appconfigData)
        setLoading(false)
        if (appconfigRes.success === true) {
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Configuração alterada',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate('/webapp'))
        } else {
          setCanAlert(true)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar configuração!',
          })
        }
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e && typeof (e) === 'string' ? e : 'Erro ao alterar configuração!',
      })
    }
  }

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('webapp-personalize-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    const configSaved = localStorage.getItem('webapp-personalize-saved')
    if (configSaved && JSON.parse(configSaved)) loadLocal()
    else getAppconfig()
  }, [])

  useEffect(() => {
    return () => {
      if (canAlert && !window.location.pathname.includes(`/webapp/editar/${id}`)) {
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/webapp/editar/${id}`)
          } else {
            localStorage.removeItem('webapp-personalize-saved')
          }
        })
      } else if (!canAlert) localStorage.removeItem('webapp-personalize-saved')
    }
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const webappSaved = localStorage.getItem('webapp-personalize-saved')
    if (webappSaved && JSON.parse(webappSaved)) {
      setLoading(true)
      const jsonWebapp = JSON.parse(webappSaved);
      setName(jsonWebapp.name)
      setLocationId(jsonWebapp.locationId)
      setInfo(jsonWebapp.info)
      if (typeof jsonWebapp.background === 'string') setBackground(jsonWebapp.background)
      if (typeof jsonWebapp.banner === 'string') setBanner(jsonWebapp.banner)
      if (typeof jsonWebapp.img === 'string') setImg(jsonWebapp.img)
      if (typeof jsonWebapp.icon === 'string') setIcon(jsonWebapp.icon)
      if (typeof jsonWebapp.firstStateBackground === 'string') setFirstStateBackground(jsonWebapp.firstStateBackground)
      if (typeof jsonWebapp.firstStateBanner === 'string') setFirstStateBanner(jsonWebapp.firstStateBanner)
      if (typeof jsonWebapp.firstStateImg === 'string') setFirstStateImg(jsonWebapp.firstStateImg)
      if (typeof jsonWebapp.firstStateIcon === 'string') setFirstStateIcon(jsonWebapp.firstStateIcon)
      setQtdProductsPagination(jsonWebapp.qtdProductsPagination)
      setUseLogoMenu(jsonWebapp.useLogoMenu)
      setColorLogo(jsonWebapp.colorLogo)
      setUseLogoTotvs(jsonWebapp.useLogoTotvs)
      setBackgroundbuttonhome(jsonWebapp.backgroundbuttonhome)
      setBackgroundcart(jsonWebapp.backgroundcart)
      setBackgroundfooter(jsonWebapp.backgroundfooter)
      setBackgroundheader(jsonWebapp.backgroundheader)
      setBackgroundmenu(jsonWebapp.backgroundmenu)
      setBackgroundstatus(jsonWebapp.backgroundstatus)
      setBackgroundstatusactive(jsonWebapp.backgroundstatusactive)
      setBackgroundtitlebar(jsonWebapp.backgroundtitlebar)
      setBackgroundwebapp(jsonWebapp.backgroundwebapp)
      setFontbutton(jsonWebapp.fontbutton)
      setFontbuttonhome(jsonWebapp.fontbuttonhome)
      setFontcart(jsonWebapp.fontcart)
      setFontcategory(jsonWebapp.fontcategory)
      setFontheader(jsonWebapp.fontheader)
      setFontinfocart(jsonWebapp.fontinfocart)
      setFontmenu(jsonWebapp.fontmenu)
      setFontstatus(jsonWebapp.fontstatus)
      setFonttitlebar(jsonWebapp.fonttitlebar)
      setHighlightfooter(jsonWebapp.highlightfooter)
      setIconsfooter(jsonWebapp.iconsfooter)
      setIconsheader(jsonWebapp.iconsheader)
      setIcontitlebar(jsonWebapp.icontitlebar)
      setWebappbutton(jsonWebapp.webappbutton)
      setPdv(jsonWebapp.pdv)
      setUseCategoryList(jsonWebapp.useCategoryList)
      setNoShadow(jsonWebapp.noShadow)
      setOrderCategory(jsonWebapp.orderCategory)
      localStorage.removeItem('webapp-personalize-saved')
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }

  const saveLocal = () => {
    localStorage.setItem('webapp-personalize-saved', JSON.stringify({
      name,
      locationId,
      info,
      background,
      banner,
      img,
      icon,
      firstStateBackground,
      firstStateBanner,
      firstStateImg,
      firstStateIcon,
      qtdProductsPagination,
      useLogoMenu,
      colorLogo,
      useLogoTotvs,
      backgroundbuttonhome,
      backgroundcart,
      backgroundfooter,
      backgroundheader,
      backgroundmenu,
      backgroundstatus,
      backgroundstatusactive,
      backgroundtitlebar,
      backgroundwebapp,
      fontbutton,
      fontbuttonhome,
      fontcart,
      fontcategory,
      fontheader,
      fontinfocart,
      fontmenu,
      fontstatus,
      fonttitlebar,
      highlightfooter,
      iconsfooter,
      iconsheader,
      icontitlebar,
      webappbutton,
      pdv,
      useCategoryList,
      noShadow,
      orderCategory,
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    name,
    locationId,
    info,
    background,
    banner,
    img,
    icon,
    firstStateBackground,
    firstStateBanner,
    firstStateImg,
    firstStateIcon,
    qtdProductsPagination,
    useLogoMenu,
    colorLogo,
    useLogoTotvs,
    backgroundbuttonhome,
    backgroundcart,
    backgroundfooter,
    backgroundheader,
    backgroundmenu,
    backgroundstatus,
    backgroundstatusactive,
    backgroundtitlebar,
    backgroundwebapp,
    fontbutton,
    fontbuttonhome,
    fontcart,
    fontcategory,
    fontheader,
    fontinfocart,
    fontmenu,
    fontstatus,
    fonttitlebar,
    highlightfooter,
    iconsfooter,
    iconsheader,
    icontitlebar,
    webappbutton,
    pdv,
    useCategoryList,
    noShadow,
    orderCategory,
  ])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex flex-row justify-content-between flex-wrap gap-5 '>
            <div className='card' style={{ flex: 1, minWidth: '30em' }}>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>
                    {i18n.t(`webapp.webapp_data`)}
                    Hotel Porra
                  </span>

                  <BreadCrumbs />
                </h3>
              </div>
              <div className='px-10 py-5'>
                <div className='w-100 flex-wrap gap-5'>
                  <div className='d-flex justify-content-between align-items-center gap-5'>
                    <div className='w-100'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`webapp.name`)}
                        label={i18n.t(`webapp.name`)}
                        value={name}
                        change={(value: string) => {
                          setCanAlert(true)
                          setName(value)
                        }}
                      />
                    </div>
                    <div className='w-100'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={i18n.t(`webapp.background_default_webapp`)}
                        value={defaultColor}
                        change={(value: string) => {
                          setCanAlert(true)
                          setDefaultColor(value)
                        }
                        }
                      />
                    </div>
                  </div>

                  <div className='d-flex  justify-content-between align-items-start mt-6  gap-5'>
                    <div className='justify-content-start d-flex w-100'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label='Background WebApp'
                        value={background}
                        change={(value: BaseSyntheticEvent) => {
                          setCanAlert(true)
                          setBackground(value ? value.target.files[0] : null)
                        }}
                      />
                    </div>
                    <div className='justify-content-start d-flex w-100'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label='Logo Webapp'
                        value={img}
                        change={(value: BaseSyntheticEvent) => {
                          setCanAlert(true)
                          setImg(value ? value.target.files[0] : null)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end mt-10'>
            <Button
              text={i18n.t(`buttons.back`)}
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/webapp')
              }}
              btnClass='fw-bolder me-3'
            />
            <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                setCanAlert(false)
                saveAppconfig()
              }}
              btnClass='fw-bolder'
            />
          </div>
        </div >
      )}
    </>
  )
}

const EditarWebappHotel: FC = () => {
  return (
    <>
      <EditarWebappContent />
    </>
  )
}

export { EditarWebappHotel }
