import {useState} from 'react'
import * as Yup from 'yup'
import {useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {Button} from '../../../components/Button/Button'
import {Input} from '../../../components/Input/Input'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string>('admin@demo.com')
  const [password, setPassword] = useState<string>('demo')
  const {saveAuth, setCurrentUser} = useAuth()

  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/*
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Entrar no Ninegrid</h1>
        <div className='text-gray-400 fw-bold fs-4'>
          Novo aqui?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Criar conta
          </Link>
        </div>
      </div>
      */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <Input
          getFieldProps={{...formik.getFieldProps('email')}}
          inputAttr={{
            type: 'email',
            name: 'email',
            autoComplete: 'off',
          }}
          label='E-mail'
          placeholder='E-mail'
          value={email}
          change={setEmail}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <Input
          getFieldProps={{...formik.getFieldProps('password')}}
          inputAttr={{
            type: 'password',
            name: 'password',
            autoComplete: 'off',
          }}
          label='Senha'
          placeholder='Senha'
          value={password}
          change={setPassword}
        />

        <Input
          inputAttr={{
            type: 'checkbox',
          }}
          label='Permanecer conectado'
        />
      </div>
      {/* end::Form group */}

      <div className='d-flex justify-content-between align-items-center'>
        <span
          className='text-gray-600 text-hover-primary my-3 mr-2'
          role='button'
          onClick={() => navigate('/auth/esqueceu-senha')}
        >
          Esqueceu sua senha?
        </span>

        <div className='text-center text-md-end'>
          <Button
            loading={loading}
            disabled={formik.isSubmitting || !formik.isValid}
            color='primary'
            text='Entrar'
            btnAttr={{
              type: 'submit',
              name: 'submit',
            }}
            click={() => {}}
            btnClass='w-auto'
          />
        </div>
      </div>
    </form>
  )
}
