import { FC, useEffect, useState } from "react"
import { POST, DELETE, PUT } from '../../../services/api'
import Swal from 'sweetalert2'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import { i18n } from '../../../translate/i18n'
import { useNavigate } from "react-router-dom"
import { Datatable } from "../../../components/Datatable/Datatable"
import { Button } from "../../../components/Button/Button"
import { Loading } from "../../../components/Loading/Loading"
import useModals from "../../../hooks/useModals"
import { ModalDelete } from "../../../components/Modals/General/ModalDelete/ModalDelete"


export type Resources = {
  id: number,
  name: string
  icon: string;
  svg: null;
  auth: number;
  routeType: string;
  routeContent: string;
  status: boolean;
  background: null;
  color: null;
  order: number;
  appConfigId: number;
  pluginspaceId: number;
  idSvgwebApp: number;
  svgWebApp: SvgWebApp
}
type SvgWebApp = {
  id: number,
  name: string
  svg: string
}


const IndexHabilitarRecursos: FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const [resources, setResources] = useState<Resources[]>([])
  const { showDeleteModal } = useModals()
  const [id, setId] = useState<number | null>(null)

  const switchStatus = async (Id: number, status: boolean) => {
    setLoading(true)
        try {
            const switchStatusStore = await PUT(`/appitems/${Id}`, {
              status: !status,
            })
            await getFooterResouces();

            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
  }

  async function getFooterResouces() {
    try {
      setLoading(true)
      const res = await POST(`/appitems/filter`)

      const dataResources:any = res.data.map((el: Resources) => {

        el.icon = el.svgWebApp.svg


        return el
      })

      setResources(dataResources)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  async function deletePost() {
    try {
      setLoading(true)
      const response = await DELETE(`/appitems/${id}`)

      setLoading(false)
      if (!response || !response.success) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao deletar',
          text: 'Falha ao deletar Recurso!'
        }).then(async () => {
          setLoading(true)
          await getFooterResouces()
          setLoading(false)
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Recurso deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => {
          setLoading(true)
          await getFooterResouces()
          setLoading(false)
        })
      }


    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao deletar',
        text: 'Falha ao deletar Recurso!'
      }).then(async () => {
        setLoading(true)
        await getFooterResouces()
        setLoading(false)
      })
    }
  }

  useEffect(() => {
    getFooterResouces()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`permissions.footerContentAddEdit`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui você configura e habilita os recursos do rodapé do Agregador.
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar Recursos'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    if (resources.length < 4) {
                      navigate('create')
                    } else {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: 'Recursos atingiram limite máximo! Para criar mais um é necessário deletar um recurso'
                      })
                    }
                  }}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-column'>

                <Datatable
                  data={resources}
                  headers={[
                    { key: 'Icone', value: 'icon', type: 'icon' },
                    { key: 'Ordem', value: 'order', type: 'default' },
                    { key: 'Título', value: 'name', type: 'default' },
                    { key: 'Descriçao', value: 'routeContent', type: 'html' },
                    {
                      key: 'Status',
                      value: 'status',
                      type: 'check',
                      action: (item: any) => switchStatus(item.id, item.status),
                    },
                    { key: 'Ações', value: 'acoes', type: '' },
                  ]}
                  options={[
                    {
                      icon: 'fas fa-pen',
                      title: 'Editar categoria',
                      action: (item: any) => {
                        navigate(`editar/${item.id}`)
                      },
                    },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal('Excluir Recurso')
                      setId(item.id)
                    },
                    path: '',
                    title: 'Excluir Recurso',
                  },
                  ]}
                  color='primary'
                />
              </div>
            </div>
           <ModalDelete confirmAction={() => deletePost()} /> 
          </div>
        </div>

      )}
    </>
  )
}

export { IndexHabilitarRecursos }