import { FC, useEffect, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import { Datatable } from "../../../components/Datatable/Datatable"
import { Button } from "../../../components/Button/Button"
import SVG from 'react-inlinesvg';
import { DELETE, GET } from "../../../services/api"
import Swal from "sweetalert2"
import useModals from "../../../hooks/useModals"
import { ModalDelete } from "../../../components/Modals/General/ModalDelete/ModalDelete"

const SegmentsContent: FC = () => {
    const navigate = useNavigate()
    const [data, setData] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const [segmentos, setSegmentos] = useState<any[]>([])
    const [id, setId] = useState<number>()
    const { showDeleteModal } = useModals()



    async function getSegmento() {
        try {
            setLoading(true)

            const seg = await GET('segment');

            if (seg.data) {
                setSegmentos(seg.data.map((el: any) => {
                    return { ...el, status: el.active ? 'Ativado' : 'Desativado' }
                }))
            }


            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }


    const deleteSegmento = async () => {
        try {
            setLoading(true)
            const gridRes = await DELETE(`/segment/${id}`)
            if (gridRes.success === true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Catálogo deletada',
                    text: 'Deleção realizada com sucesso!',
                }).then(async () => await getSegmento())
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao deletar o segmento!',
                }).then(async () => await getSegmento())
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao deletar o segmento!',
            }).then(async () => await getSegmento())
            console.log(e)
        }
    }


    useEffect(() => {
        getSegmento()
    }, [])


    return (<>
        {loading ? (
            <Loading />
        ) : (
            <div className='row g-5 gx-xxl-12'>
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                    <div className='card-header py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                            <span className='card-label fw-bolder fs-3 mb-1'>Segmentos</span>
                            <span className='text-muted mt-1 fw-bold fs-7'>
                                Aqui estão listadas os segmentos do agregador juntamente com suas respectivas ações.
                            </span>
                        </h3>
                        <div className='card-toolbar'>

                            <Button
                                text='Cadastrar Segmentos'
                                color='primary'
                                iconColor='#FFF'
                                icon='fas fa-plus'
                                size='small'
                                horizontalPadding={6}
                                btnAttr={{
                                    type: 'button',
                                }}
                                click={() => navigate('/segmentos/criar')}
                                btnClass='mb-5 fw-bolder'
                            />

                        </div>

                    </div>
                    <div className='card-body'>
                        <Datatable
                            data={segmentos}
                            headers={
                                [
                                    { key: 'Id', value: 'id', type: 'default' },
                                    { key: 'Nome', value: 'name', type: 'default' },
                                    { key: 'Ordem', value: 'order', type: 'default' },
                                    { key: 'Status', value: 'status', type: 'default' },
                                    { key: 'Ações', value: 'acoes', type: '' },
                                ]
                            }
                            options={
                                [
                                    {
                                        icon: 'fas fa-pen',
                                        action: (item) => {
                                            navigate(`/segmentos/editar/${item.id}`)
                                        },
                                        title: 'Editar',
                                    },
                                    {
                                        icon: 'fas fa-trash', action: (item) => {
                                            showDeleteModal(`${i18n.t(`general.delete`)} segmento`)
                                            setId(item.id)

                                        },
                                    },
                                    //{ icon: "fas fa-trash", action: () => {}, path: '', title: "Excluir modalidade" },
                                ]
                            }
                            color='primary'
                        />
                    </div>
                </div >
                <ModalDelete confirmAction={() => deleteSegmento()} />
            </div >
        )}

    </>)
}

const SegmentsAgregador: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: `${i18n.t(`permissions.segments`)}`, path: '/segmentos', isActive: false },
                ]}
            >
                {i18n.t(`permissions.segments`)}
            </PageTitle>
            <SegmentsContent />
        </>
    )
}

export { SegmentsAgregador }
