/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {useNavigate} from 'react-router-dom'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const OpcionaisContent: FC = () => {
  const navigate = useNavigate()

  const [data, setData] = useState<any>([
    {name: 'Gelo', code: 1, status: 'ativo'},
    {name: 'Limão espremido', code: 2, status: 'ativo'},
  ])
  const [header, setHeader] = useState<any>([
    {key: 'Nome', value: 'name', type: 'default'},
    {key: 'Código', value: 'code', type: 'default'},
    {
      key: 'Status',
      value: 'status',
      type: 'badge',
      badge: {
        ativo: 'success',
        inativo: 'danger',
      },
    },
    {key: 'Ações', value: 'acoes', type: ''},
  ])
  const [subtitle, setSubtitle] = useState<string>('Acompanhamentos')
  const [textBtn, setTextBtn] = useState<string>('acompanhamento')

  const dummyData = {
    agrupamentos: [
      {name: 'Gelo', code: 1, status: 'ativo'},
      {name: 'Limão espremido', code: 2, status: 'ativo'},
    ],
    extras: [
      {name: 'Farofa', code: 10, status: 'ativo', value: 5},
      {name: 'Limão', code: 12, status: 'ativo', value: 2},
    ],
    itensCombo: [
      {name: 'Pizza Marguerita', code: 15, status: 'inativo', value: 35},
      {name: 'Pizza Portuguesa', code: 21, status: 'ativo', value: 42},
    ],
  }

  const changeData = (item: string) => {
    if (item === 'acompanhamento') {
      setData(dummyData.agrupamentos)
      setHeader([
        {key: 'Nome', value: 'name', type: 'default'},
        {key: 'Código', value: 'code', type: 'default'},
        {
          key: 'Status',
          value: 'status',
          type: 'badge',
          badge: {
            ativo: 'success',
            inativo: 'danger',
          },
        },
        {key: 'Ações', value: 'acoes', type: ''},
      ])
      setTextBtn('acompanhamento')
      setSubtitle('Acompanhamentos')
    } else {
      setData(item === 'extra' ? dummyData.extras : dummyData.itensCombo)
      setTextBtn(item === 'extra' ? 'extra' : 'item de combo')
      setSubtitle(item === 'extra' ? 'Extras' : 'Itens de Combo')
      setHeader([
        {key: 'Nome', value: 'name', type: 'default'},
        {key: 'Código', value: 'code', type: 'default'},
        {
          key: 'Status',
          value: 'status',
          type: 'badge',
          badge: {
            ativo: 'success',
            inativo: 'danger',
          },
        },
        {key: 'Preço', value: 'value', type: 'money'},
        {key: 'Ações', value: 'acoes', type: ''},
      ])
    }
  }

  const handleCreate = (text: string) => {
    if (text === 'acompanhamento') navigate('/smart-order/opcionais/acompanhamentos/criar')
    else if (text === 'item de combo') navigate('/smart-order/opcionais/itens-de-combo/criar')
    else if (text === 'extra') navigate('/smart-order/opcionais/extras/criar')
  }

  return (
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Opcionais</span>
            <BreadCrumbs />
            {/* <span className='text-muted mt-1 fw-bold fs-7'>
              Aqui estão listados os opcionais juntamente com suas respectivas ações.
            </span> */}
          </h3>
        </div>
        <div className='d-flex align-items-center border-bottom'>
          <p
            onClick={() => changeData('acompanhamento')}
            className={`border-bottom border-hover-primary text-hover-primary ms-9 mb-0 py-3 ${
              subtitle === 'Acompanhamentos' ? 'text-primary border-primary' : 'text-gray-600'
            } cursor-pointer`}
          >
            Acompanhamentos
          </p>
          <p
            onClick={() => changeData('extra')}
            className={`border-bottom border-hover-primary text-hover-primary ms-9 mb-0 py-3 ${
              subtitle === 'Extras' ? 'text-primary border-primary' : 'text-gray-600'
            } cursor-pointer`}
          >
            Extras
          </p>
          <p
            onClick={() => changeData('combo')}
            className={`border-bottom border-hover-primary text-hover-primary ms-9 mb-0 py-3 ${
              subtitle === 'Itens de Combo' ? 'text-primary border-primary' : 'text-gray-600'
            } cursor-pointer`}
          >
            Itens de Combo
          </p>
        </div>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center mb-5'>
            <span className='card-label fw-bolder fs-3 mb-1'>{subtitle}</span>
            <div className='card-toolbar'>
              <Button
                text={`Adicionar ${textBtn}`}
                color='primary'
                iconColor='#FFF'
                icon='fas fa-plus'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                  type: 'button',
                }}
                click={() => handleCreate(textBtn)}
                btnClass='mb-5 fw-bolder'
              />
            </div>
          </div>
          <Datatable
            data={data}
            headers={header}
            options={[
              {
                icon: 'fas fa-pen',
                title: 'Editar categoria',
                action: (item) => {
                  navigate(`/smart-order/opcionais/${
                    textBtn === 'item de combo'
                      ? 'itens-de-combo'
                      : `${textBtn === 'extra' ? 'extras' : 'acompanhamentos'}`
                  }/editar`)
                },
              },
              {icon: 'fas fa-trash', action: () => {}, path: '', title: 'Excluir item'},
            ]}
            color='primary'
          />
        </div>
      </div>
    </div>
  )
}

const Opcionais: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Opcionais', path: '#', isActive: true},
        ]}
      >
        Opcionais
      </PageTitle>
      <OpcionaisContent />
    </>
  )
}

export {Opcionais}
