import { getPlugintypeIdByCode } from '../functions/plugintype'
import useAuth from '../hooks/useAuth'
import { GET, POST } from '../services/api'
import { saveAs } from 'file-saver';
import XLSX from 'sheetjs-style';

type IntegrationType = {
  id: number
  name: string
  plugintemplateId: number
}

export const getCurrentMonth = () => {
  const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]
  const currentMonth = new Date().getMonth()
  return months[currentMonth]
}

export const parseJwt = (token: string) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const usingTotvs = async () => {
  try {
    const integrationRes = await GET(`/pluginconfig`)
    const totvsIntegration = integrationRes.data.find(
      (integration: IntegrationType) =>
        integration.plugintemplateId === 23 ||
        integration.plugintemplateId === 15 ||
        integration.plugintemplateId === 45
    )
    if (
      (totvsIntegration && totvsIntegration.content && totvsIntegration.content.able === '1') ||
      totvsIntegration.plugintemplateId === 45
    ) {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.log(e)
    return false
  }
}

export const usingSynkar = async () => {
  try {
    const integrationRes = await GET(`/pluginconfig`)
    const totvsIntegration = integrationRes.data.find(
      (integration: IntegrationType) =>
        integration.plugintemplateId === 108
    )
    if (totvsIntegration && totvsIntegration.content && totvsIntegration.content.able === '1') {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.log(e)
    return false
  }
}

export const getGmtStore = async () => {
  try {
    const res = await GET(`/pluginspace/this`)
    console.log(res)
    if (res && res.data && res.data[0] && res.data[0].gmt) {
      const pluginspace = res.data[0];
      // return `${(pluginspace.gmt.split(":")[0] > 10 || pluginspace.gmt.split(":")[0] < -10) 
      //   ? pluginspace.gmt.split(":")[0] : `0${pluginspace.gmt.split(":")[0]}`}:${pluginspace.gmt.split(":")[1]}`
      return pluginspace.gmt
    } else {
      return '+00:00'
    }
  } catch (e) {
    return '+00:00'
  }
}

export const usingSDS = async () => {
  try {
    const integrationRes = await GET(`/pluginconfig`)
    const totvsIntegration = integrationRes.data.find(
      (integration: IntegrationType) =>
        integration.plugintemplateId === 101
    )
    if (
      (totvsIntegration && totvsIntegration.content && totvsIntegration.content.able === '1') ||
      totvsIntegration.plugintemplateId === 45
    ) {
      return true
    } else {
      return false
    }
  } catch (e) {
    console.log(e)
    return false
  }
}

export const exportExcel  = (arrayToDownload: Array<any>) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const ws = XLSX.utils.json_to_sheet(arrayToDownload);
  const wb = { Sheets: {data: ws}, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
  const data = new Blob([excelBuffer], {type: fileType})
  saveAs(data, 'log_images')
}
