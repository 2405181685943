import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST, } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import Swal from 'sweetalert2'

import styles from './createConfig.module.css'

import { Input } from '../../../components/Input/Input'
import { Button } from '../../../components/Button/Button'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type PluginconfigType = {
  id: number;
  name: string;
  content: any;
  plugintemplateId: number;
  plugintypeId: number;
}

type PaymentType = {
  id: number;
  name: string;
  image: string | null;
  parameters: string;
  plugintypeId: number;
  pluginconfig: PluginconfigType;
  ableCredit: boolean | null;
  ableDebit: boolean | null;
  ableAlimentation: boolean | null;
  ablePix: boolean | null;
}

type ConfigInputsType = {
  name: string;
  label: string;
}

type ModalityType = {
  id: number;
  code: string;
  name: string;
  status: string;
  type: string;
  checked: boolean;
}


const PaymentCreateConfigContent: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState<PaymentType | null>();
  const [configInputs, setConfigInputs] = useState<(ConfigInputsType | undefined)[]>([])
  const [modalities, setModalities] = useState<ModalityType[]>([])

  const getPlugintemplate = async () => {
    try {
      const res = await GET(`/plugintemplate/payments/${id}`)
      if (res.success && res.data && res.data.length > 0) {
        setPayment(res.data[0])
        const newInputs: (ConfigInputsType | undefined)[] = Object.entries(
          JSON.parse(res.data[0].parameters).config
        ).map((configInput) => {
          if (
            configInput[0] !== undefined &&
            configInput[1] !== undefined &&
            typeof configInput[1] === 'string'
          ) {
            const input: ConfigInputsType = {
              name: configInput[0],
              label: configInput[1],
            }
            return input
          }
        })
        setConfigInputs(newInputs.filter((input) => input))

        const modalitiesRes = await GET('/location')
        if (modalitiesRes.data && modalitiesRes.data.length > 0) {
          const filterModalities = modalitiesRes.data.filter((modality: ModalityType) => modality.type !== 'address');
          const newModalities = filterModalities.map((modality: ModalityType) => {
            return {
              id: modality.id,
              code: modality.code,
              name: modality.name,
              status: modality.status,
              type: modality.type,
              checked: false
            }
          })
          setModalities(newModalities);
        }
        return
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar meio de pagamento.',
      }).then(() => navigate(-1))
      return
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar meio de pagamento.',
      }).then(() => navigate(-1))
    }
  }

  const init = async () => {
    setLoading(true)
    await getPlugintemplate();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])

  const createPaymentConfig = async () => {
    if (payment) {
      let integrationContent: any = {}
      configInputs.forEach((config) => {
        if (config) {
          if (!config.name.includes('able') && config.name !== 'pix') {
            const element: any = document.getElementsByName(config.name)[0]
            integrationContent[config.name] = element.value
          } else {
            integrationContent[config.name] = '0'
          }
        }
      })
      try {
        setLoading(true)
        const integrationRes = await POST('/pluginconfig', {
          plugintypeId: payment.plugintypeId,
          plugintemplateId: parseInt(id ? id : '0'),
          name: payment.name,
          content: integrationContent,
          locationsId: modalities.filter((modality) => modality.checked).map((modality) => modality.id)
        })
        setLoading(false)
        if (integrationRes.success === true && integrationRes.dados && integrationRes.dados.clientIdPagseguro) {
          localStorage.setItem('clientIdPagseguro', integrationRes.dados.clientIdPagseguro)
          localStorage.setItem('emailPagseguro', integrationRes.dados.content.email)
          localStorage.setItem('pluginspaceId', integrationRes.dados.pluginspaceId)
          Swal.fire({
            icon: 'success',
            title: 'Integração cadastrada',
            text: 'Cadastro realizado com sucesso!'
          }).then(() => (integrationRes.dados.content.environment && integrationRes.dados.content.environment === '1') ?
            window.location.href = `https://connect.pagseguro.uol.com.br/oauth2/authorize?response_type=code&client_id=${integrationRes.dados.clientIdPagseguro}&redirect_uri=${integrationRes.dados.content.redirect_uri}&scope=payments.read+payments.create+payments.refund&state=xyz` :
            window.location.href = `https://connect.sandbox.pagseguro.uol.com.br/oauth2/authorize?response_type=code&client_id=${integrationRes.dados.clientIdPagseguro}&redirect_uri=${integrationRes.dados.content.redirect_uri}&scope=payments.read+payments.create+payments.refund&state=xyz`
          )
        } else if (integrationRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Integração cadastrada',
            text: 'Cadastro realizado com sucesso!'
          }).then(() => navigate(`/configuracoes/pagamentos/${id}`))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar integração!'
          })
        }
      } catch (e) {
        setLoading(false)
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao cadastrar integração!',
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Falha ao encontrar meio de pagamento!',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`payment.add`)}</span>
                <BreadCrumbs />
              </h3>
            </div>
            {payment && (
              <div className='card-body d-flex flex-column gap-5' >
                <div className={styles.goBack}>
                  {payment.image && (<img src={payment.image} alt={payment.name} />)}
                </div>
                <div className={styles.form}>
                  <h2>{i18n.t(`payment.basic_settings`)}</h2>
                  {configInputs.filter((input) => input && !input.name.includes('able') && input.name !== 'pix').map((config) => (
                    <div key={config?.name}>
                      <Input
                        inputAttr={{
                          type: 'text',
                        }}
                        placeholder={config?.label}
                        label={config?.label}
                        name={config?.name}
                        change={() => { }}
                      />
                    </div>
                  ))}
                </div>
                <div className={styles.formModalities}>
                  <h2>{i18n.t(`payment.modalities`)}</h2>
                  {modalities.map((modality) => (
                    <Input
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      key={modality.id}
                      label={modality.name}
                      checked={modality.checked}
                      value={modality.checked}
                      change={(value: boolean) => {
                        setModalities(modalities.map((modalityToChange) => {
                          if (modalityToChange.id === modality.id) modality.checked = value;
                          return modalityToChange
                        }))
                      }}
                    />
                  ))}
                </div>
                <div className='d-flex w-100 justify-content-end gap-2'>
                  <Button
                    text={i18n.t(`buttons.back`)}
                    color='info'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      navigate('/configuracoes/pagamentos')
                    }}
                    btnClass='fw-bolder mt-4'
                  />
                  <Button
                    text={i18n.t(`buttons.save`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={async () => await createPaymentConfig()}
                    btnClass='fw-bolder mt-4'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const PaymentCreateConfig: FC = () => {
  const { id } = useParams();

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`payment.methods`), path: 'configuracoes/pagamentos', isActive: true },
          { title: i18n.t(`payment.add`), path: `configuracoes/pagamentos/${id}`, isActive: true },
        ]}
      >
        {i18n.t(`payment.add`)}
      </PageTitle>
      <PaymentCreateConfigContent />
    </>
  )
}

export { PaymentCreateConfig }
