/* eslint-disable array-callback-return */
import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {Loading} from '../../../../components/Loading/Loading'
import {POST, PUT} from '../../../../services/api'
import {i18n} from '../../../../translate/i18n'
import {Editor} from '@tinymce/tinymce-react'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

const Content: FC = () => {
  const navigate = useNavigate()

  const {id} = useParams()

  const [listClassification, setListClassification] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [categoryId, setCategoryId] = useState<any>('0')
  const [title, setTitle] = useState<string>('')
  const [order, setOrder] = useState<string>('0')

  const [content, setContent] = useState('')

  const [image, setImage] = useState<File | null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | null>(null)

  const generateClassificationTree = (
    categories: ReturnType[],
    parent: string | null,
    name: string
  ): any => {
    const parentCategory = categories.find((category) => category.code === parent)
    if (parentCategory)
      return `${generateClassificationTree(
        categories,
        parentCategory.parent,
        parentCategory.name
      )} > ${name}`
    else return name
  }

  const getCategories = async () => {
    setLoading(true)
    try {
      const res = await POST(`/classification/filter`, {
        plugintypeId: 10,
        status:true,
        deleted: 'not show',
      })
      res.data.map((row: ReturnType) => {
        listClassification.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: generateClassificationTree(res.data, row.parent, row.name),
        })
      })
      const categories = [
        {
          value: 0,
          select: 'Selecione',
          key: -1,
        },
      ]
      listClassification.map((row: any, idx) => {
        categories.push({
          value: row.id,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }


  useEffect(() => {
    getCategories()
  }, [])

  const create = async () => {
    const categorySelected: any = categoryId
    try {
      if (!title) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        let imageRes = {
          success: false,
          image: '',
        }
        const res = await POST('/post', {
          classificationId: categorySelected.value,
          title,
          content,
          order: order ?? 0,
          status:true,
          img: imageRes.success ? imageRes.image : 'default/no-image.png',
        })
        if (image) {
          const formData = new FormData()
          formData.append('file', image)
          formData.append('nameImage', `post-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          imageRes = await POST('/upload-images/upload-one', formData)
          if (imageRes.success) {
            await PUT(`/post/${res.data.id}`, {
              img: imageRes.image,
            })
          }
        }

        setLoading(false)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Conteúdo cadastrado',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/hospitality/content/content'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar a categoria!',
          })
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ?? 'Erro ao cadastrar categoria!',
      })
    }
  }
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(
                  `buttons.create`
                )} ${i18n.t(`feed.content`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(
                    `feed.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`
                  )}
                </span>
              </h3>
            </div>
            <div className='card-body '>
              <div className='row'>
                <div className='col-lg-10'>
                  <div className='row'>
                    <div className='col-lg-4'  style={{zIndex: 4}}>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        search
                        label={i18n.t(`smart_order.category`)}
                        options={categories}
                        value={categoryId}
                        change={(value: string) => {
                          setCategoryId(value)
                          console.log(categoryId)
                        }}
                      />
                    </div>
                    <div className='col-lg-10'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`feed.title`)}
                        label={i18n.t(`feed.title`)}
                        value={title}
                        change={(value: string) => setTitle(value)}
                      />
                    </div>
                    <div className='col-lg-2'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.order`)}
                        label={i18n.t(`smart_order.order`)}
                        value={order}
                        change={(value: string) => setOrder(value)}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-2'>
                  <Input
                    inputAttr={{
                      type: 'image',
                    }}
                    label={i18n.t(`smart_order.image`)}
                    value={image}
                    change={(value: BaseSyntheticEvent) =>
                      setImage(value ? value.target.files[0] : null)
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                    <div className='w-100'>
                      <div className='mt-6'>
                        <label className='form-label fw-normal'>Conteúdo</label>
                        <Editor
                          apiKey={process.env.REACT_APP_TINY_KEY}
                          onEditorChange={(value: string) => setContent(value)}
                          value={content}
                          init={{
                            height: 200,
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount',
                            ],
                            toolbar:
                              'undo redo | formatselect | ' +
                              'bold italic forecolor backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help',
                            content_style:
                              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    create()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const FeedCriarConteudo: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`feed.content`), path: 'hospitality/content/content', isActive: true},
          {
            title: i18n.t(`buttons.create`),
            path: 'hospitality/content/content/create/0',
            isActive: true,
          },
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`feed.content`)}`}
      </PageTitle>
      <Content />
    </>
  )
}

export {FeedCriarConteudo}
