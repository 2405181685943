import {useContext} from 'react'
import {GlobalModalsContext} from '../context/globalModalsContext'

const useModals = () => {
  const values = useContext(GlobalModalsContext)

  return {...values}
}

export default useModals
