import {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {Button} from '../../../Button/Button'

type Item = {
  name: string
  language: string
}

type Props = {
  data: Item[]
}

const LanguageList: FC<Props> = (props) => {
  const {data} = props
  const [emptyData, setEmptyData] = useState<boolean>(false)

  useEffect(() => {
    if (data.length > 0) setEmptyData(false)
    else setEmptyData(true)
  }, [])

  const changeLanguage = (language: string) => {
    switch(language){
      case 'pt':
        localStorage.setItem('i18nextLng', 'pt-BR');
        break;
      case 'en':
        localStorage.setItem('i18nextLng', 'en-US');
        break;
      case 'es':
        localStorage.setItem('i18nextLng', 'es-ES');
        break;
      default:
        localStorage.setItem('i18nextLng', 'pt-BR');
        break;
    } 
    window.location = window.location;
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-175px mt-5 p-0'
      data-kt-menu='true'
      data-kt-menu-overflow='true'
    >
      {!emptyData ? (
        <ul className='px-0 py-4 m-0'>
          {data.map((item) => (
            <li key={item.name} className='d-flex'>
              <p
                onClick={() => changeLanguage(item.language)}
                className='fs-7 w-100 m-0 text-gray-700 fw-bold px-4 py-3 bg-hover-light text-hover-primary'
              >
                <img
                  className='h-20px w-20px rounded cursor-pointer me-4'
                  src={`https://staging.ninegrid.com.br/public/novo/media/svg/flags/${item.language}.svg`}
                  alt=''
                />
                {item.name}
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <div className='p-4'>
          <h3 className='text-gray-600 fs-6 mb-2'>Nenhum idioma encontrado</h3>
        </div>
      )}
    </div>
  )
}

export {LanguageList}
