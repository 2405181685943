/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useState, useEffect, ChangeEvent } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import useAuth from '../../../../hooks/useAuth'
import { usingTotvs } from '../../../../utils/utils'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { ModalImagemCropped } from '../../../../components/Modals/ImagemCropped/ImagemCropped'
import useModals from '../../../../hooks/useModals'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ProductType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

type ImageType = {
  id: number
  productId: string
  path: string
  imgsuffix: string
  typeimg: string
}

type ImageCreateType = {
  path: string
  productId?: string
  typeimg: string
  order: number
}

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}



const EditarProdutosContent: FC = () => {
  const navigate = useNavigate()
  const { showImageCropped } = useModals()
  const { loginInfo } = useAuth();
  const { id } = useParams()
  const [listClassification, setListClassification] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [subcategoryId, setSubCategoryId] = useState<any>('0')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>()
  const [description, setDescription] = useState<string>('')
  const [technicalSpecification, setTechnicalSpecification] = useState<string>('')
  const [marks, setMarks] = useState<any[]>([])
  const [markId, setMarkId] = useState<string>('0')
  const [measurements, setMeasurements] = useState<any[]>([])
  const [measurementId, setMeasurementId] = useState<string>('')
  const [order, setOrder] = useState<string>('0')
  const [status, setStatus] = useState<boolean>(true)
  const [image, setImage] = useState<File | string | null>(null)

  const [imageCrop, setImageCrop] = useState<string | null>('')
  const [isCropped, setCropper] = useState<any>(null)

  const [firstStateImage, setFirstStateImage] = useState<File | string | null>(null)
  const [imageId, setImageId] = useState<Number>(0)
  const [imagesProducts, setImagesProducts] = useState<ImageType[]>([])
  const [extraImages, setExtraImages] = useState<FileList>()
  const [extraImagesUrl, setExtraImagesUrl] = useState<ImageType[]>([])
  const [deletedImages, setDeletedImages] = useState<number[]>([])
  const [disableFieldsTotvs, setDisableFieldTotvs] = useState(false);

  const [canAlert, setCanAlert] = useState(false);

  const generateClassificationTree = (categories: ReturnType[], parent: string | null, name: string): any => {
    const parentCategory = categories.find((category) => category.code === parent);
    if (parentCategory) return `${generateClassificationTree(categories, parentCategory.parent, parentCategory.name)} > ${name}`
    else return name;
  }

  const getCategories = async () => {
    try {
      const res = await GET('/classification')
      const newList = listClassification
      res.data.map((row: ProductType) => {
        newList.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: generateClassificationTree(res.data, row.parent, row.name),
        })
      })
      setListClassification(newList)
      // const cat = newList.filter(function (row) {
      //   return row.parent === null || row.parent == ''
      // })

      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      newList.map((row: any, idx) => {
        categories.push({
          value: row.id,
          select: row.name,
          key: idx,
        })
      })

      setCategories(categories)

    } catch (e) {
      console.log(e)
    }
  }

  const getMarks = async () => {
    try {
      const res = await GET('/mark')
      const marks = [
        {
          value: '0',
          select: 'Selecione',
        },
      ]
      res.data.map((row: ProductType) => {
        marks.push({
          value: row.id,
          select: row.name,
        })
      })
      setMarks(marks)
    } catch (e) {
      console.log(e)
    }
  }

  const getMeasurements = async () => {
    try {
      const res = await GET('/measurement')
      const measurements = [
        {
          value: '0',
          select: 'Selecione',
        },
      ]
      res.data.map((row: ProductType) => {
        measurements.push({
          value: row.id,
          select: row.description,
        })
      })
      setMeasurements(measurements)
    } catch (e) {
      console.log(e)
    }
  }

  const getProduct = async () => {
    setLoading(true)
    try {
      const useTotvs = await usingTotvs();
      if (useTotvs) {
        setDisableFieldTotvs(true);
      }
      await getCategories()
      await getMarks()
      await getMeasurements()
      await GET(`/product/${id}`).then(async (res) => {
        const cat = listClassification.filter(
          (row) => parseInt(row.id) == parseInt(res.data[0].classificationId)
        )
        if (cat && cat[0]) {
          setSubCategoryId({
            value: cat[0].id,
            label: cat[0].name
          })
        }
        setMarkId(res.data[0].markId)
        setMeasurementId(res.data[0].measurementUnitId)
        setName(res.data[0].name)
        setCode(res.data[0].code)
        setDescription(res.data[0].description ? res.data[0].description : '')
        setStatus(res.data[0].status)
        setOrder(res.data[0].order ?? '0')
        setImagesProducts(res.data[0].images)
        setTechnicalSpecification(res.data[0].technicalSpecification ? res.data[0].technicalSpecification : '')
        let urlProductImage = ''
        const imgProduct = res.data[0].images.find(
          (image: ImageType) => image.typeimg === 'imgsmall'
        )
        if (res.data[0].images && res.data[0].images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
          if (imgProduct.path) {
            urlProductImage =
              imgProduct.path.substring(7, imgProduct.path.length) + '/' ===
                process.env.REACT_APP_AWS_S3_URL.substring(7, process.env.REACT_APP_AWS_S3_URL.length)
                ? imgProduct.path + '/' + imgProduct.imgsuffix
                : imgProduct.path
          }
        }
        const imagesAdd = res.data[0].images.filter((image: ImageType) => image.typeimg === 'image')

        setExtraImagesUrl(
          imagesAdd.map((image: ImageType) => {
            if (image && image.path && process.env.REACT_APP_AWS_S3_URL) {
              image.path =
                image.path.substring(7, image.path.length) + '/' ===
                  process.env.REACT_APP_AWS_S3_URL.substring(
                    7,
                    process.env.REACT_APP_AWS_S3_URL.length
                  )
                  ? image.path + '/' + image.imgsuffix
                  : image.path
            }
            return image
          })
        )
        setFirstStateImage(urlProductImage)
        setImage(urlProductImage)
        setImageId(res.data[0].images[0] ? res.data[0].images[0].id : null)
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const editProduct = async () => {
    const subcategorySelected: any = subcategoryId;
    try {
      if (
        !disableFieldsTotvs && (
          subcategoryId == '0' ||
          subcategorySelected.value === '0' ||
          !name ||
          !description ||
          markId == '0' ||
          measurementId == '0')
      ) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        setLoading(true)
        const data = {
          name: name,
          description: description,
          order: parseInt(order),
          code: code ?? '',
          classificationId: parseInt(subcategorySelected.value),
          type: 'product',
          status: status,
          measurementUnitId: parseInt(measurementId),
          parent: '',
          markId: parseInt(markId),
          technicalSpecification: technicalSpecification,
        }
        const resProd = await PUT(`/product/${id}`, data)
        const newDeletedImages = Object.assign(deletedImages)
        let dataImages: ImageCreateType[] = []
        if (resProd.success === true) {
          let imageRes = {
            success: false,
            image: '',
          }
          if (image && image !== firstStateImage) {
            if (firstStateImage) {
              await POST('/upload-images/delete-one', {
                url: firstStateImage
              })
            }
            const formData = new FormData()
            formData.append('file', image)
            formData.append('nameImage', `product-${uuid()}.jpg`)
            imageRes = await POST('/upload-images/upload-one', formData)
            imagesProducts.map((image) => {
              if (image.typeimg !== 'image') {
                deletedImages.push(image.id)
              }
            })
            dataImages = [
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'imgsmall',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'mainpicture',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'thumbpicture',
                order: 0,
              },
            ]
          } else if (!image) {
            imagesProducts.map((image) => {
              if (image.typeimg !== 'image') {
                deletedImages.push(image.id)
              }
            })
          }
          if (extraImages) {
            await Promise.all(
              Array.from(extraImages).map(async (image) => {
                let imageExtraRes = {
                  success: false,
                  image: '',
                }
                const formData = new FormData()
                formData.append('file', image)
                formData.append('nameImage', `product-${uuid()}.jpg`)
                imageExtraRes = await POST('/upload-images/upload-one', formData)
                if (imageExtraRes.success == true) {
                  dataImages.push({
                    path: imageExtraRes.image,
                    productId: id,
                    typeimg: 'image',
                    order: 0,
                  })
                }
              })
            )
          }
          await POST('/productimage/multiple', {
            data: dataImages,
          })
          if (newDeletedImages && newDeletedImages.length > 0) {
            await POST(`productimage/delete-multiple`, {
              data: newDeletedImages,
            })
          }

          setLoading(false)
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Produto atualizado',
            text: 'Produto atualizado com sucesso!',
          }).then(() => navigate('/product_fashion'))
        } else {
          setCanAlert(true)
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar o produto!',
          })
        }
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o produto!',
      })
    }
  }

  function deleteImages(id: number) {
    const newImagesUrls = extraImagesUrl.filter((image) => image.id !== id)
    const newDeletedImages = Object.assign(deletedImages)
    newDeletedImages.push(id)
    setDeletedImages(newDeletedImages)
    setExtraImagesUrl(newImagesUrls)
  }

  const getCropData = async () => {
    if (isCropped) {
      const file = await fetch(isCropped.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "newAvatar.png", { type: "image/png" });
        });

      setImage(file)
      setImageCrop('')
    }
  };

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('product-edited-saved')
  }

  const getImagetoCrop = (e: any) => {
    if (e?.target.files) {
      showImageCropped()
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    const productSaved = localStorage.getItem('product-edited-saved')
    if (productSaved && JSON.parse(productSaved)) loadLocal()
    else getProduct()
  }, [])

  useEffect(() => {
    return () => {
      if (canAlert && !window.location.pathname.includes(`/${loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion'}/editar/${id}`)) {
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/${loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion'}/editar/${id}`)
          } else {
            localStorage.removeItem('product-edited-saved')
          }
        })
      } else if (!canAlert) localStorage.removeItem('product-edited-saved')
    }
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const prouctSaved = localStorage.getItem('product-edited-saved')
    if (prouctSaved && JSON.parse(prouctSaved)) {
      const jsonProduct = JSON.parse(prouctSaved);
      setListClassification(jsonProduct.listClassification)
      setCategories(jsonProduct.categories)
      setSubCategoryId(jsonProduct.subcategoryId)
      setName(jsonProduct.name)
      setCode(jsonProduct.code)
      setDescription(jsonProduct.description)
      setTechnicalSpecification(jsonProduct.technicalSpecification)
      setMarks(jsonProduct.marks)
      setMarkId(jsonProduct.markId)
      setMeasurements(jsonProduct.measurements)
      setMeasurementId(jsonProduct.measurementId)
      setOrder(jsonProduct.order)
      setStatus(jsonProduct.status)
      if (typeof jsonProduct.image === 'string') setImage(jsonProduct.image)
      if (typeof jsonProduct.firstStateImage === 'string') setFirstStateImage(firstStateImage)
      setExtraImagesUrl(jsonProduct.extraImagesUrl)
      localStorage.removeItem('product-edited-saved')
    }
  }

  const saveLocal = () => {
    localStorage.setItem('product-edited-saved', JSON.stringify({
      listClassification,
      categories,
      subcategoryId,
      name,
      code,
      description,
      technicalSpecification,
      marks,
      markId,
      measurements,
      measurementId,
      order,
      status,
      image,
      firstStateImage,
      extraImagesUrl
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    listClassification,
    categories,
    subcategoryId,
    name,
    code,
    description,
    technicalSpecification,
    marks,
    markId,
    measurements,
    measurementId,
    order,
    status,
    image,
    firstStateImage,
    extraImagesUrl
  ])

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-content-between flex-wrap w-100 gap-5'>
            <div className='me-5' style={{ flex: 1 }}>
              <div className='card pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`smart_order.product_data`)}</span>
                    <BreadCrumbs />
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    search
                    label={i18n.t(`smart_order.category`)}
                    options={categories}
                    value={subcategoryId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setSubCategoryId(value)
                    }}
                    disabled={disableFieldsTotvs}
                  />
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`smart_order.mark`)}
                    options={marks}
                    value={markId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setMarkId(value)
                    }}
                    disabled={disableFieldsTotvs}
                  />
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`smart_order.measurement_unit`)}
                    options={measurements}
                    value={measurementId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setMeasurementId(value)
                    }}
                    disabled={disableFieldsTotvs}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.name`)}
                    label={i18n.t(`smart_order.name`)}
                    value={name}
                    change={(value: string) => {
                      setCanAlert(true)
                      setName(value)
                    }}
                    disabled={disableFieldsTotvs}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.code`)}
                    label={i18n.t(`smart_order.code`)}
                    value={code}
                    change={(value: string) => {
                      setCanAlert(true)
                      setCode(value)
                    }}
                    disabled={disableFieldsTotvs}
                  />

                  <Input
                    inputAttr={{
                      type: 'textarea',
                    }}
                    placeholder={i18n.t(`smart_order.description`)}
                    label={i18n.t(`smart_order.description`)}
                    value={description}
                    change={(value: string) => {
                      setCanAlert(true)
                      setDescription(value)
                    }}
                    rows={5}
                    maxCaracteres={415}
                    disabled={disableFieldsTotvs}
                  />
                  <p className='mt-2'>
                    <small>{description.length} {i18n.t(`smart_order.from`)} 415 {i18n.t(`smart_order.characters`)}</small>
                  </p>

                  <Input
                    inputAttr={{
                      type: 'textarea',
                    }}
                    placeholder={i18n.t(`smart_order.technical_specification`)}
                    label={i18n.t(`smart_order.technical_specification`)}
                    value={technicalSpecification}
                    change={(value: string) => {
                      setCanAlert(true)
                      setTechnicalSpecification(value)
                    }}
                    rows={5}
                    maxCaracteres={415}
                    disabled={disableFieldsTotvs}
                  />
                  <p className='mt-2'>
                    <small>{technicalSpecification.length} {i18n.t(`smart_order.from`)} 415 {i18n.t(`smart_order.characters`)}</small>
                  </p>

                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Status'
                    options={[
                      { select: i18n.t(`general.active`), value: true },
                      { select: i18n.t(`general.inactive`), value: false },
                    ]}
                    value={status}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setStatus(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.order`)}
                    label={i18n.t(`smart_order.order`)}
                    smalltext='(para exibição no webapp)'
                    value={order}
                    change={(value: string) => {
                      setCanAlert(true)
                      setOrder(value)
                    }}
                    disabled={disableFieldsTotvs}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className='card pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`smart_order.product_image`)}</span>
                  
                  </h3>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  <div className='px-10 py-5'>
                    {/*
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      value={image}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                    */
                      <>
                        <Input
                          inputAttr={{
                            type: 'image',
                          }}
                          value={image}
                          change={(value: BaseSyntheticEvent) => {
                            setCanAlert(true)
                            setImage(value ? value.target.files[0] : null)
                            if (value) {
                              getImagetoCrop(value ? value : null)
                            } else {
                              setImageCrop('')
                              getImagetoCrop(null)
                            }
                          }}
                        />
                      </>
                    }
                  </div>
                </div>
              </div>

              <div className='card mt-4 pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`smart_order.additional_images`)}</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  <div className='px-10 py-5'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      multiple
                      onChange={(e) => {
                        if (
                          e.target.files &&
                          extraImagesUrl &&
                          e.target.files.length + extraImagesUrl?.length <= 3
                        ) {
                          setCanAlert(true)
                          setExtraImages(e.target.files)
                        } else {
                          e.target.value = ''
                          Swal.fire({
                            icon: 'warning',
                            title: 'Atenção!',
                            text: 'O limite de imagens adicionais é de no máximo 3 arquivos.',
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  {extraImagesUrl?.map((image) => {
                    return (
                      <div className='px-10 py-5'>
                        <div className=' mt-1 w-150px'>
                          <div className='w-150px h-150px rounded'>
                            <img className='w-100 h-100' src={image.path} />
                          </div>
                        </div>
                        <span
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            marginLeft: '8em',
                            marginTop: '-2em',
                            width: '25px !important',
                            height: '25px !important',
                          }}
                          className='btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow'
                          data-action='remove'
                          data-toggle='tooltip'
                          title='Remover'
                          onClick={() => deleteImages(image.id)}
                        >
                          <i className='bi bi-x text-muted fs-1'></i>
                        </span>
                      </div>
                    )
                  })}
                  {extraImages &&
                    Array.from(extraImages).map((image) => {
                      return (
                        <div className='px-10 py-5'>
                          <div className=' mt-1 w-150px'>
                            <div className='w-150px h-150px rounded'>
                              <img className='w-100 h-100' src={URL.createObjectURL(image)} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
            <Button
              text={i18n.t(`buttons.back`)}
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/product_fashion')
              }}
              btnClass='fw-bolder me-3'
            />
            <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                setCanAlert(false)
                editProduct()
              }}
              btnClass='fw-bolder'
            />
          </div>
        </div>
      )}
      <ModalImagemCropped

        imageCrop={imageCrop}
        setCropper={setCropper}
        getCropData={getCropData}
      />
    </>
  )
}

const EditarProdutos: FC = () => {
  const { loginInfo } = useAuth();
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.product`), path: loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion', isActive: true },
          { title: i18n.t(`general.edit`), path: loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion', isActive: true },
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.product`)}`}
      </PageTitle>
      <EditarProdutosContent />
    </>
  )
}

export { EditarProdutos }
