import { FC, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../../components/Button/Button"
import { Input } from "../../../components/Input/Input"
import { Loading } from "../../../components/Loading/Loading"
import { GET, POST } from "../../../services/api"
import Swal from "sweetalert2"
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"





type Form = {
	name: string
	login?: string
	password?: string
	cardTypeId: number
	type: string
	standardTimeOrder: number
	productSector: ProductSectorForm[]
	timeManagement: boolean
	useTimer: boolean
	timeRequest: number
	layout: number
	orderLimit: number
	statusChange: boolean
}

type ProductSectorForm = {
	checked: boolean
	"productSectorId": number
}

type Card = {
	id: number,
	name: string
	content: any
}

type Layout = {
	value: number,
	select: string
}


const SdsCreateManagement: FC = () => {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [form, setForm] = useState<Form>({
		name: '',
		login: 'sds123',
		password: 'sds123',
		cardTypeId: 1,
		type: '0',
		orderLimit: 50,
		standardTimeOrder: 0,
		productSector: [],
		timeManagement: false,
		timeRequest: 10,
		useTimer: true,
		statusChange: false,
		layout: 1
	})

	const [sectors, setSector] = useState<any>([])
	const [cards, setCards] = useState<Card[]>([
		{ id: 1, name: 'Default', content: {} }
	])
	const [layout, setLayout] = useState<Layout[]>([
		{
			value: 1,
			select: '2 x 1'
		},
		{
			value: 2,
			select: '2 x 2'
		},
		{
			value: 3,
			select: '3 x 1'
		},
		{
			value: 4,
			select: '3 x 2'
		},
		{
			value: 5,
			select: '4 x 1'
		},
		{
			value: 6,
			select: '4 x 2'
		},
		{
			value: 7,
			select: '5 x 1'
		},
		{
			value: 8,
			select: '5 x 2'
		},
		{
			value: 9,
			select: '6 x 1'
		},
		{
			value: 10,
			select: '6 x 2'
		}
	])
	const [status, setStatus] = useState<any>([
		{
			select: 'Não Trocar',
			value: false
		},
		{
			select: 'Trocar',
			value: true
		}
	])
	const [layoutSelected, setLayoutSelected] = useState('2x1')



	const onChangeType = (value: any) => {
		const type = value
		const obj = { ...form }
		if (type === 2 || type === '2') {
			obj.timeManagement = true
		} else {
			obj.timeManagement = false
		}

		if (type === 3 || type === '3') {
			obj.useTimer = false
		} else {
			obj.useTimer = true
		}
		obj.type = type
		setForm(obj)


	}
	function changeLayoutSelected() {

		const s = layout.find((el: any) => el.value === form.layout)

		setLayoutSelected(s!.select.replace(/\s/g, ''))
	}

	const getProdutioncSector = async () => {
		try {

			const response = await GET('sds_integration/get_all_sectors')

			response.data.sort((a: any, b: any) => a.id > b.id ? 1 : (a.id < b.id ? - 1 : 0))


			setSector(response.data)

			const geralSEctor = response.data.filter((ps: any) => ps.name === 'GERAL' || ps.name === 'Geral')

			if (geralSEctor[0]) {
				geralSEctor[0].productSectorId = geralSEctor[0].id

				setForm({ ...form, productSector: geralSEctor })
			}

		} catch (e) {

			Swal.fire({
				icon: 'error',
				title: 'Erro',
				text: 'Erro  ao recuperar  setores de Produção!',
			})
		}
	}

	const getLayouts = async () => {
		try {
			const response = await GET('sds_integration/get_all_layouts')

			const layouts: any[] = [
				{ id: 1, name: 'Default', type: '"dynamic"' }
			]

			response.data.map((el: any) => {
				layouts.push({
					id: el.id,
					name: el.name,
					type: el.type
				})
			})
			setCards(layouts)
		} catch (error) {

		}

	}

	async function getAllData() {
		setLoading(true)
		await Promise.all([
			getLayouts(),
			getProdutioncSector()
		])
		setLoading(false)
	}

	useEffect(() => {
		getAllData()
	}, [])

	useEffect(() => {
		changeLayoutSelected()
	}, [form.layout])

	const TimeManagement = () => {
		const obj = { ...form }

		if (form.type === '2') {
			obj.timeManagement = true
		} else {
			obj.timeManagement = false
		}

		if (form.type === '3') {
			obj.useTimer = false
		} else {
			obj.useTimer = true
		}

		setForm(obj)


	}
	const create = async () => {
		TimeManagement()
		try {
			setLoading(true)
			if (
				form.name === '' ||
				form.type === '0' ||
				form.standardTimeOrder === 0
			) {

				Swal.fire({
					icon: 'error',
					title: 'Campos faltantes',
					text: 'Verifique se preencheu todos os campos!',
				})
				setLoading(false)
			} else {
				const response = await POST('sds_integration/create_sds', form)

				if (response.statusCode === 400 || !response.success) {
					Swal.fire({
						icon: 'error',
						title: 'Erro',
						text: 'Erro ao cadastrar o SDS!',
					})

				} else {
					Swal.fire({
						icon: 'success',
						title: 'SDS cadastrado',
						text: 'Cadastro realizado com sucesso!',
					}).then(() => navigate('/sds/manage'))

				}

				setLoading(false)
			}

		} catch (e) {
			setLoading(false)

			Swal.fire({
				icon: 'error',
				title: 'Erro',
				text: 'Erro ao cadastrar o SDS!',
			})
		}
	}

	return (
		<>
			{loading ? (
				<Loading />
			) : (
				<div className='row g-5 gx-xxl-12'>
					<div className='d-flex justify-content-between'>
						<div className='w-100'>
							<div className='w-100 card'>
								<div className='card-header py-5'>
									<h3 className='card-title align-items-start flex-column'>
										<span className='card-label fw-bolder fs-3 mb-1'>Criar SDS</span>
										<BreadCrumbs />
									</h3>
								</div>
								<div className='card-body'>
									<div className='px-10 py-5'>
										<Input
											inputAttr={{
												type: 'default',
											}}
											placeholder='Nome dos SDS'
											label='Nome'
											value={form.name || ''}
											change={(value: any) => setForm({ ...form, name: value })}
										/>

										<br />



										<div className='row'>
											<div className='col-md-6' >
												<Input
													inputAttr={{
														type: 'select',
													}}
													label='Escolha o Setor de Produção'
													options={sectors.map((setor: any) => {
														return { select: setor.name, value: setor.id }
													})}

													value={form.productSector[0]?.productSectorId || null}
													change={(value: any) => {
														const sector = sectors.filter((setor: any) => setor.id === parseInt(value))
														setForm({ ...form, productSector: sector })
													}}
												/>
											</div>
											<div className='col-md-6' >
												<Input
													inputAttr={{
														type: 'select',
													}}
													label='Escolha o Tempo da Busca de Pedidos'
													options={[
														{ select: '30 seg', value: 30 },
														{ select: '25 seg', value: 25 },
														{ select: '20 seg', value: 20 },
														{ select: '15 seg', value: 15 },
														{ select: '10 seg', value: 10 },
														{ select: '5 seg', value: 5 },

													]}
													value={form.timeRequest || 10}
													change={(value: any) => setForm({ ...form, timeRequest: parseInt(value) })}
												/>
											</div>
										</div>
										<div className='row'>
											<div className='col-md-6'>
												<Input
													inputAttr={{
														type: 'select',
													}}
													label='Gestão de tempo'
													options={[
														{ select: 'Selecione', value: '' },
														{ select: 'Utilizar tempo por produto da integração', value: '1' },
														{ select: 'Utilizar um padrão por pedido', value: '2' },
														{ select: 'Não Utilizar Tempo', value: '3' },
													]}
													value={form.type || ''}
													change={(value: any) =>
														onChangeType(value)
													}
												/>
											</div>
											<div className='col-md-6'>
												<Input
													inputAttr={{
														type: 'number',
													}}
													placeholder='Tempo padrão por pedido (minutos)'
													label='Tempo padrão por pedido (minutos) *'
													value={form.standardTimeOrder || ''}
													change={(value: string) => setForm({ ...form, standardTimeOrder: parseInt(value) })}
												/>
											</div>
										</div>

										<div className='row'>
											<div className='col-md-6' >
												<Input
													inputAttr={{
														type: 'select',
													}}
													label='Escolha da Personalização'
													options={cards.map((el: any) => ({
														value: el.id,
														select: el.name,
													}))}
													value={form.cardTypeId || ''}
													change={(value: any) => setForm({ ...form, cardTypeId: parseInt(value) })}
												/>
											</div>
											<div className='col-md-6' >
												<Input
													inputAttr={{
														type: 'select',
													}}
													label='Escolha do Layout'
													options={layout}
													value={form.layout || 1}
													change={(value: any) => setForm({ ...form, layout: parseInt(value) })}
												/>
											</div>
										</div>


										<div className='row'>
											<div className='col-md-6'>
												<Input
													inputAttr={{
														type: 'select',
													}}
													label='Trocar de status automaticamente'
													options={status}
													value={form.statusChange || ''}
													change={(value: any) =>
														setForm({ ...form, statusChange: (/true/).test(value) })
													}
												/>
											</div>

											{form.layout && layout &&
												<div className='col-md-6'>
													<div className='d-flex flex-column mt-4 ps-4 pe-12'>
														<label className='form-label fw-normal'>
															{`Exemplo do Layout selecionado: ${layoutSelected.split('x')[0]} colunas e ${layoutSelected.split('x')[1]} linhas`}
														</label>
														<img src={`/layout-sds/${layoutSelected}.png`} style={{
															height: '15rem',
															width: '15rem',
														}} />
													</div>
												</div>

											}
										</div>
										<br />



										<div className='row'>
											<div className='col-12 d-flex justify-content-end'>
												<Button
													text='Salvar'
													color='primary'
													size='small'
													horizontalPadding={5}
													btnAttr={{
														type: 'button',
													}}
													click={create}
													btnClass='fw-bolder'
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}


export { SdsCreateManagement }
