import {FC, useMemo, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {
  GoogleMap,
  useJsApiLoader,
  DrawingManager,
  Polygon,
  OverlayView,
} from '@react-google-maps/api'
import {Datatable} from '../../../../../components/Datatable/Datatable'
import type {MetaType} from '../../../../../components/Datatable/Datatable'
import {Input} from '../../../../../components/Input/Input'
import {Button} from '../../../../../components/Button/Button'
import {toMoney} from '../../../../../utils/formater'
import {DELETE, GET, POST, PUT} from '../../../../../services/api'
import {Loading} from '../../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import useModals from '../../../../../hooks/useModals'
import { ModalDelete } from '../../../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../../../components/BreadCrumbs/breadCrumbrs'
import { i18n } from '../../../../../translate/i18n'
import { useNavigate } from 'react-router-dom'

const libraries: ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[] = [
  'drawing',
]

const PageContent: FC = () => {
  const [loading, setLoading] = useState(false)
  const {showDeleteModal, showWrapperModal} = useModals()
  const navigate = useNavigate();
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>()
  const [vertices, setVertices] = useState<any[]>([])
  const [verticesText, setVerticesText] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState<Number | null>(null)
  const [assortment, setAssortment] = useState<Number>(0)
  const [areas, setAreas] = useState<any[]>([])
  const [center, setCenter] = useState([-22.910279297298477, -43.18973555700653])

  const {isLoaded} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_MAPS_KEY ? process.env.REACT_APP_MAPS_KEY : '',
    libraries: libraries,
  })

  useEffect(() => {
    getAreas()
  }, [])

  const getPluginspace = async () => {
    try {
      const res = await GET(`/pluginspace/this`)
      if (res && res.data && res.data[0]) {
        const data = res.data[0]
        if(data && data.address && data.address.lat && data.address.lng){
          setCenter([parseFloat(data.address.lat), parseFloat(data.address.lng)])
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAreas = async () => {
    try {
      setLoading(true)
      await getPluginspace();
      const res = await GET(`/delivery-area`)
      await POST(`/count-service`, {service: 'map'})
      setAreas(res.data)
      setMeta(res.meta)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const deleteArea = async () => {
    try {
      setLoading(true)
      const markRes = await DELETE(`/delivery-area/${id}`)
      cleanArea();
      if (markRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Area deletado',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getAreas())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o area!',
        }).then(async () => await getAreas())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o area!',
      }).then(async () => await getAreas())
      console.log(e)
    }
  }

  const createArea = async () => {
    try {
      if (!name || !price || price === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const data = {
          name,
          price,
          assortment,
          deliveryAreaVertices: vertices
        }
        await POST(`/delivery-area`, data)
        cleanArea();
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Area cadastra',
          text: 'Cadastro realizado com sucesso!',
        }).then(async () => await getAreas())
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar area!',
      })
    }
  }

  const editArea = async () => {
    try {
      if (!name || !price || price === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const data = {
          name,
          price,
          assortment,
        }
        await PUT(`/delivery-area/${id}`, data)
        cleanArea();
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Area atualizada',
          text: 'Area atualizada com sucesso!',
        }).then(async () => await getAreas())
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao atualizar area!',
      })
    }
  }

  const selectArea = (item: any) => {
    setId(item.id)
    setName(item.name)
    setPrice(item.price)
    setAssortment(item.assortment)
    let _vertices: any[] = []
    let _verticesText = ''
    item.vertices.map((coord: any) => {
      _vertices.push(coord.vertices)
      _verticesText += (_verticesText ? ',' : '') + `(${coord.vertices.lat},${coord.vertices.lng})`
    })
    setVertices(_vertices)
    setVerticesText(_verticesText)
  }

  const cleanArea = () => {
    setId(null)
    setName('')
    setPrice(null)
    setAssortment(0)
    setVertices([])
    setVerticesText('')
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Áreas de Frete</span>
                <BreadCrumbs />
                {/* <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listadas as taxas de entregas juntamente com suas respectivas ações.
                </span> */}
              </h3>
              <div className='card-toolbar d-flex justify-content-end w-100 gap-5 '>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-arrow-left fs-4'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/configuracoes/taxa-entrega')}
                  btnClass='fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: '450px',
                  }}
                  center={new google.maps.LatLng(center[0], center[1])}
                  zoom={15}
                  mapTypeId={google.maps.MapTypeId.ROADMAP}
                  onUnmount={(map) => {
                    // do your stuff before map is unmounted
                  }}
                >
                  <DrawingManager
                    drawingMode={google.maps.drawing.OverlayType.POLYGON}
                    onPolygonComplete={(poly) => {
                      let _vertices: any[] = []
                      let _verticesText = ''
                      const polyArray = poly.getPath().getArray()
                      polyArray.map((item) => {
                        _vertices.push({lat: item.lat(), lng: item.lng()})
                        _verticesText +=
                          (_verticesText ? ',' : '') + `(${item.lat()},${item.lng()})`
                      })
                      setId(null)
                      setName('')
                      setPrice(null)
                      setAssortment(0)
                      setVertices(_vertices)
                      setVerticesText(_verticesText)
                    }}
                    options={{
                      drawingControl: true,
                      drawingControlOptions: {
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
                        position: google.maps.ControlPosition.TOP_CENTER,
                      },
                    }}
                  />
                  {areas.map((item, index) => {
                    var bounds = new google.maps.LatLngBounds()
                    item.vertices.map((coord: any) => {
                      bounds.extend(coord.vertices)
                    })
                    return (
                      <>
                        <Polygon
                          key={`polygon-${index}`}
                          paths={item.vertices.map((c: {vertices: any}) => c.vertices)}
                          options={{
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                            fillOpacity: 0.35,
                          }}
                          onClick={() => {
                            selectArea(item)
                          }}
                        />
                        <OverlayView
                          position={bounds.getCenter()}
                          mapPaneName={OverlayView.FLOAT_PANE}
                        >
                          <div
                            style={{
                              background: `#203254`,
                              padding: `7px 12px`,
                              color: `white`,
                              borderRadius: '4px',
                            }}
                          >
                            {`${item.name} - ${toMoney('' + item.price)}`}
                          </div>
                        </OverlayView>
                      </>
                    )
                  })}
                </GoogleMap>
              ) : (
                <></>
              )}
              <br />
              <div>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Coordenadas'
                  label='Coordenadas'
                  value={verticesText}
                  disabled={true}
                />
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Nome da area'
                  label='Nome da area'
                  value={name}
                  change={(value: string) => setName(value)}
                />
                <Input
                  inputAttr={{
                    type: 'money',
                  }}
                  currency='BRL'
                  locale='pt-BR'
                  placeholder='Informe o valor do frete'
                  label='Informe o valor do frete'
                  value={price}
                  change={(value: number) => {
                    setPrice(value)
                  }}
                />
                <br />

                <Button
                  text='Salvar'
                  color='primary'
                  size='small'
                  horizontalPadding={5}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    id ? editArea() : createArea()
                  }}
                  btnClass='fw-bolder'
                />
                <Button
                  text='Limpar'
                  color='default'
                  size='small'
                  horizontalPadding={5}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => cleanArea()}
                  btnClass='fw-bolder'
                />
              </div>
              <br />
              <div>
                <Datatable
                  data={areas}
                  headers={[
                    {key: 'assortment', value: 'assortment', type: 'default'},
                    {key: 'name', value: 'name', type: 'default'},
                    {key: 'price', value: 'price', type: 'money'},
                    {key: 'Ações', value: 'acoes', type: ''},
                  ]}
                  options={[
                    {
                      icon: 'fas fa-pen',
                      action: (item) => {
                        selectArea(item)
                      },
                      title: 'Editar',
                    },
                    {
                      icon: 'fas fa-trash',
                      action: (item) => {
                        showDeleteModal('Excluir area')
                        setId(item.id)
                      },
                      path: '',
                      title: 'Excluir produto',
                    },
                  ]}
                  color='primary'
                />
              </div>
            </div>
          </div>
          <ModalDelete confirmAction={() => deleteArea()} />`
        </div>
      )}
    </>
  )
}

const RangeMap: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Taxa de entrega', path: 'configuracoes/taxa-entrega', isActive: true},
          {title: 'Áreas de Frete', path: '#', isActive: true},
        ]}
      >
        Áreas de Frete
      </PageTitle>
      <PageContent />
    </>
  )
}

export {RangeMap}

