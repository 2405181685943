import {FC, useEffect, useState} from 'react'
import {Input} from '../Input/Input'
import moment from 'moment'
import {check} from 'prettier'
import { i18n } from '../../translate/i18n'

type Props = {
  dia: string
  diaCheckValue: any
  turnoInicio1: any
  setTurnoInicio1: (value: string) => void
  setTurnoInicio2: (value: string) => void
  setTurnoFim1: (value: string) => void
  setTurnoFim2: (value: string) => void
  setChecked: (value: boolean) => void
  turnoFim1: any
  turnoInicio2: any
  turnoFim2: any
  checked?: boolean
}

const Turno: FC<Props> = (props) => {
  const {
    dia,
    diaCheckValue,
    turnoInicio1,
    turnoInicio2,
    turnoFim1,
    turnoFim2,
    setTurnoInicio1,
    setTurnoInicio2,
    setTurnoFim1,
    setTurnoFim2,
    setChecked,
    checked,
  } = props

  const [isChecked, setIsChecked] = useState<boolean>(false)

  const validate = (value: string) => {
    if (value) {
      value = value.replaceAll('_', '0')
      const values = value.split(':')
      let val1 = parseInt(values[0]) > 23 ? 23 : values[0]
      let val2 = parseInt(values[0]) > 23 || parseInt(values[1]) > 59 ? 59 : values[1]
      let val3 = parseInt(values[0]) > 23 || parseInt(values[2]) > 59 ? 59 : values[2]
      value = `${val1}:${val2}:${val3}`
    }
    return value
  }

  useEffect(() => {
    if (props.checked) setChecked(props.checked)
  }, [])

  return (
    <div className='w-100'>
      <Input
        inputAttr={{
          type: 'checkbox',
        }}
        value={diaCheckValue}
        checked={checked}
        label={dia}
        checkSwift={false}
        change={(value: boolean) => setChecked(value)}
      />

      <div className='d-flex justify-content-between align-items-center w-100 my-8 gap-5 flex-wrap'>
        <div style={{flex: 1}}>
          <Input
            inputAttr={{
              type: 'time',
            }}
            value={turnoInicio1}
            change={(value: string) => setTurnoInicio1(value)}
            placeholder='00:00:00'
            label={`${i18n.t(`shifts.start`)} #01`}
            blur={(value: string) => setTurnoInicio1(validate(value))}
            disabled={!checked}
          />
        </div>
        <div style={{flex: 1}}>
          <Input
            inputAttr={{
              type: 'time',
            }}
            value={turnoFim1}
            change={(value: string) => setTurnoFim1(value)}
            placeholder='00:00:00'
            label={`${i18n.t(`shifts.end`)} #01`}
            blur={(value: string) => setTurnoFim1(validate(value))}
            disabled={!checked}
          />
        </div>
        <div style={{flex: 1}}>
          <Input
            inputAttr={{
              type: 'time',
            }}
            value={turnoInicio2}
            change={(value: string) => setTurnoInicio2(value)}
            placeholder='00:00:00'
            label={`${i18n.t(`shifts.start`)} #02`}
            blur={(value: string) => setTurnoInicio2(validate(value))}
            disabled={!checked}
          />
        </div>
        <div style={{flex: 1}}>
          <Input
            inputAttr={{
              type: 'time',
            }}
            value={turnoFim2}
            change={(value: string) => setTurnoFim2(value)}
            placeholder='00:00:00'
            label={`${i18n.t(`shifts.end`)} #02`}
            blur={(value: string) => setTurnoFim2(validate(value))}
            disabled={!checked}
          />
        </div>
      </div>
    </div>
  )
}

export {Turno}
