/* eslint-disable array-callback-return */
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import { Editor } from '@tinymce/tinymce-react'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import { toDate } from '../../../utils/formater'
import { EditorQuill } from '../../../components/EditorQuill/EditorQuill'

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

const Content: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [loading, setLoading] = useState(false)
  const [resources, setResources] = useState<any[]>([])
  const [resourcesId, setResourcesId] = useState<any>('0')
  const [title, setTitle] = useState<string>('')
  const [order, setOrder] = useState<string>('0')

  const [content, setContent] = useState('')

  const [status, setStatus] = useState<boolean>(true)
  const [buttonSaibaMais, setButtonSaibaMais] = useState<boolean>(true)

  const [dateInit, setDateInit] = useState<string>()
  const [dateFin, setDateFin] = useState<string>()


  const [link, setLink] = useState('')

  const [image, setImage] = useState<File |string| null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | null>(null)

  async function getFooterResouces() {
    try {
      setLoading(true)
      const res = await POST(`/appitems/filter`)

      const data  = res.data.map((el: any) => {

        return {
          value: el.id,
          select: el.name
        }
      })


      setResources(data)
      return data
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getContent = async () => {
    if (id !== '0') {
      try {
        const res = await POST(`/post/filter`, {
          id: id,
        })
        if (Object.getOwnPropertyDescriptor(res, 'data') === undefined) {
          return false;
        }
        const resources = await getFooterResouces()
        const data = res.data[0];
        setTitle(data.title)
        setOrder(data.order)
        setContent(data.content)
        setDateInit(data.dateInit.split('T')[0])
        setDateFin(data.dateFin.split('T')[0])
        setButtonSaibaMais(data.showButton)
        setLink(data.link)

        const aux = resources.find((el:any)=> el.value == data.classificationId)

        setResourcesId({
          label:aux.select,
          value:aux.value
        })

        const image = data.img

        setImage(
          image.substring(0, 4) === 'http'
            ? image
            : process.env.REACT_APP_AWS_S3_URL + image
        )

        setFirstStateImage(
          image.substring(0, 4) === 'http'
            ? image
            : process.env.REACT_APP_AWS_S3_URL + image
        )
      } catch (e) {
        setLoading(false)
        console.log(e)
      }
    }
  }

 async  function getAllData(){
    setLoading(true)
    await  getFooterResouces()
    setLoading(false)
    setLoading(true)
    await  getContent()
    setLoading(false)
    
  }

  useEffect(() => {
    getAllData()
   
  }, [])

  const update = async () => {
    const categorySelected: any = resourcesId
    try {
      if (!title) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        let imageRes = {
          success: false,
          image: '',
        }

        let res

        const data = {
          id: id,
          classificationId: categorySelected.value,
          title,
          content,
          order: order ?? 0,
          status: true,
          path: link,
          img: image,
          showButton: buttonSaibaMais,
          dateFin: `${dateFin} 23:59:59`,
          dateInit: `${dateInit} 00:00:00`
        }

        if (image && image !== firstStateImage) {
          if (firstStateImage) {
            await POST('/upload-images/delete-one', {
              url: firstStateImage,
            })
          }
          const formData = new FormData()
          formData.append('file', image)
          formData.append('nameImage', `post-${uuid()}.jpg`)
          imageRes = await POST('/upload-images/upload-one', formData)
          data.img = imageRes.success ? imageRes.image : 'default/default-user.jpg'
          res = await PUT(`/post/${id}`, data)
        } else {
          if (!image) {
            if (firstStateImage) {
              await POST('/upload-images/delete-one', {
                url: firstStateImage,
              })
            }
            data.img = 'default/default-user.jpg'
            res = await PUT(`/post/${id}`, data)
          } else {
            data.img = image

            res = await PUT(`/post/${id}`, data)
          }
        }

        setLoading(false)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Conteúdo editado',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate(-1))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao editar Conteúdo!',
          })
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ?? 'Erro ao editar Conteúdo!',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(
                  `buttons.create`
                )} ${i18n.t(`permissions.footerContent`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(
                    `feed.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`
                  )}
                </span>
              </h3>
            </div>
            <div className='card-body '>
              <div className='row'>
                <div className='col-lg-9'>
                  <div className='row'>
                    <div className='col-lg-4' style={{ zIndex: 4 }}>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        search
                        label={'Feed'}
                        options={resources}
                        value={resourcesId}
                        change={(value: string) => {
                          setResourcesId(value)
                        }}
                      />
                    </div>
                    <div className='col-lg-10'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`feed.title`)}
                        label={i18n.t(`feed.title`)}
                        value={title}
                        maxCaracteres={15}
                        change={(value: string) => setTitle(value)}
                      />
                    </div>

                  </div>
                </div>
                <div className='col-lg-3'>
                  <Input
                    inputAttr={{
                      type: 'image',
                    }}
                    label={i18n.t(`smart_order.image`)}
                    value={image}
                    width=''
                    change={(value: BaseSyntheticEvent) =>
                      setImage(value ? value.target.files[0] : null)
                    }
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4 d-flex gap-2 align-items-end'>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.order`)}
                    label={i18n.t(`smart_order.order`)}
                    value={order}
                    change={(value: string) => setOrder(value)}
                  />
                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    placeholder={'Ativo'}
                    label={'Ativo'}
                    value={status}
                    checkSwift={true}
                    change={(value: boolean) => setStatus(value)}
                    checked={status}
                  />
                </div>

              </div>

              <div className='row '>
                <div className='d-flex gap-4'>


                  <div
                    className='d-flex mt-6'
                  >
                    <svg width="50" height="50" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M16.068 22.9828H1V1.93848H25.1313V13.5402" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M2.36096 6.86523H23.7703" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M18.5581 13.0538V10.0127H21.5493" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M11.5663 13.0538V10.0127H14.5575" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M4.58191 13.0538V10.0127H7.57308" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M11.5663 19.835V16.7939H14.5575" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M4.58191 19.835V16.7939H7.57308" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M6.68323 0V3.885" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M6.68323 0V3.885" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M19.448 0V3.885" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M19.448 0V3.885" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M25.1311 32.0005C20.2406 32.0005 16.2623 27.9558 16.2623 22.9836C16.2623 18.0115 20.2406 13.9668 25.1311 13.9668C30.0217 13.9668 34 18.0115 34 22.9836C34 27.9558 30.0217 32.0005 25.1311 32.0005Z" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M27.898 20.171L25.1312 23.5238L21.1829 18.9697" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M25.1311 28.5488V29.0126" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M25.1311 16.9551V17.4188" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M30.605 22.9834H31.0537" stroke="#677484" stroke-miterlimit="10" />
                      <path d="M19.2012 22.9834H19.6573" stroke="#677484" stroke-miterlimit="10" />
                    </svg>


                  </div>

                  <div className='d-flex gap-2'>
                    <Input
                      inputAttr={{
                        type: 'date',
                      }}
                      label={'Data de inicio'}
                      value={dateInit}
                      change={(value: string) =>
                        setDateInit(value)
                      }
                    />
                    <Input
                      inputAttr={{
                        type: 'date',
                      }}
                      label={'Data de fim'}
                      value={dateFin}
                      change={(value: string) =>
                        setDateFin(value)
                      }
                    />
                  </div>
                </div>
              </div>


              <div className='row' >
                <div className='col-lg-12'>
                  <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                    <div className='w-100'>
                      <div className='mt-6'>
                        <label className='form-label fw-normal'>Conteúdo</label>
                        <EditorQuill
                        value={content}
                        change={(value) => {
                          setContent(value)
                        }}
                      />
                     
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='d-flex align-items-end gap-3'>

                <Input
                  inputAttr={{
                    type: 'checkbox',
                  }}
                  placeholder={'Botão de saiba mais'}
                  label={'Botão de saiba mais'}
                  value={buttonSaibaMais}
                  change={(value: boolean) => setButtonSaibaMais(value)}
                  checked={buttonSaibaMais}
                />

                <div className='d-flex align-items-end gap-2'>
                  <span
                    className='fw-normal text-gray-800'
                    style={{
                      fontSize: '13px',

                    }}>Link: </span>

                  <div style={{ position: 'relative', display: 'inline-block' }}>
                    <i className="bi bi-link-45deg"
                      style={{
                        position: 'absolute',
                        left: '3px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        pointerEvents: 'none'
                      }}></i>
                    <input
                      style={{
                        borderRadius: '6px',
                        borderWidth: '1px',
                        height: '20px',
                        paddingLeft: '15px' // Adiciona espaço para o ícone
                      }}
                      type="text"
                      value={link}
                      onChange={(event) => setLink(event.target.value)}
                    />
                  </div>

                </div>

              </div>
            </div>

            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    update()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div >
      )}
    </>
  )
}

const FeedEditarConteudoAggregador: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`feed.content`), path: 'hospitality/content/content', isActive: true },
          { title: i18n.t(`general.edit`), path: 'hospitality/content/content/editar', isActive: true }
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`feed.content`)}`}
      </PageTitle>
      <Content />
    </>
  )
}

export { FeedEditarConteudoAggregador }
