import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import useModals from '../../../../hooks/useModals'
import { DELETE, GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { Input } from '../../../../components/Input/Input'
import { usingTotvs } from '../../../../utils/utils'
import { Dropdown } from 'react-bootstrap'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type VariationType = {
  id: number;
  sku: string;
  name: string;
  specification: string;
  inventory: number;
  price: number;
  status: boolean;
}


type VariationResponseType = {
  id: number;
  sku: string;
  name: string;
  classificationName: string;
  context: string;
  imgs: string;
  inventory: number;
  price: number;
  promotionalPrice: number;
  productStatus: boolean;
  variationStatus: boolean;
}

type LocationType = {
  id: number;
  name: string;
  type: string;
  status: boolean;
}

type SelectType = {
  value: number;
  select: string;
}

const VariacoesContent: FC = () => {
  const navigate = useNavigate()
  const { showDeleteModal, showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false)

  const [variations, setVariations] = useState<VariationType[]>([])
  const [variationId, setVariationId] = useState<number | null>(null)
  const [locationId, setLocationId] = useState(-1);
  const [locations, setLocations] = useState<SelectType[]>([]);

  const [disableFieldsTotvs, setDisableFieldTotvs] = useState(false);
  const [fileToImport, setFileToImport] = useState<File | null>(null)

  const getVariations = async (locationIdSend: number) => {
    try {

      const useTotvs = await usingTotvs();
      if (useTotvs) {
        setDisableFieldTotvs(true);
      }



      const res = await POST(`/product/with-variations`, {
        haveImage: -1,
        havePrice: -1,
        productStatus: -1,
        variationStatus: -1,
        haveInventory: -1,
        classificationId: -3,
        locationId: locationIdSend ? locationIdSend : -1
      })
      const newVariations = res.data.map((row: VariationResponseType) => {
        let specification = `<div class='d-flex flex-column'>`
        if (!row.context.includes('VARIATION') && !row.context.includes('NONE')) {
          const contextFormated = JSON.parse(row.context);
          if (contextFormated) {
            Object.entries(contextFormated).map((item) => {
              specification += `<p>${item[0]}: ${item[1]}</p>`
            })
          }
        }
        specification += '</div>'
        return {
          id: row.id,
          sku: row.sku,
          name: row.name,
          specification: specification,
          inventory: row.inventory,
          price: row.price,
          status: row.variationStatus,
        }
      })
      setVariations(newVariations)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteVariation = async () => {
    try {
      setLoading(true)
      const variationRes = await DELETE(`/variations/${variationId}`)
      if (variationRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Variação deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getVariations(locationId))
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a variação!',
        }).then(async () => await getVariations(locationId))
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a variação!',
      }).then(async () => await getVariations(locationId))
      console.log(e)
    }
  }

  const switchStatus = async (id: number, status: boolean) => {
    try {
      const switchVariation = await PUT(`/variations/${id}`, {
        status: !status,
      })
      if (switchVariation.success) {
        const newVariations = variations.map((variation) => {
          if (variation.id === id) {
            variation.status = !status
          }
          return variation
        })
        setVariations(newVariations)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const changeVariation = async (item: any, valueChange: string) => {
    let objectToChange: any = {}
    objectToChange[valueChange] = item[valueChange];
    try {
      const switchVariation = await PUT(`/variations/${item.id}`, objectToChange)
      if (switchVariation.success) {
        const newVariations = variations.map((variation: any) => {
          if (variation.id === item.id) {
            variation[valueChange] = item[valueChange];
          }
          return variation
        })
        setVariations(newVariations)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getLocations = async () => {
    try {
      const res = await GET(`/location`)
      const newLocations: LocationType[] = [{
        id: -1,
        name: 'Todas',
        type: '',
        status: true
      }];
      res.data.forEach((location: LocationType) => {
        if (location.type !== 'address') {
          newLocations.push(location)
        }
      })
      setLocations(newLocations.map((loc) => ({
        value: loc.id,
        select: loc.name
      })))
      const locationActive = newLocations.find((loc) => loc.status === true && loc.id > 0)
      if(locationActive){
        setLocationId(locationActive.id)
        await getVariations(locationActive.id);
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const init = async () => {
    setLoading(true);
    await getLocations();
    setLoading(false);
  }

  const importFile = async () => {
    try {
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToImport)

        const importRes = await POST('/product/import_minimum_stock', formData)
        setLoading(false)
        if (importRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Importação realizada com sucesso!',
          }).then(async () => {
            await getLocations();
            await getVariations(locationId)
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao importar arquivo!',
          }).then(async () => {
            await getLocations();
            await getVariations(locationId)
          })
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => {
        await getLocations();
        await getVariations(locationId)
      })
    }
    setFileToImport(null)
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`smart_order.variations`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`smart_order.listened_variations`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_variation`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/variation/criar')}
                  btnClass='mb-5 fw-bolder'
                />

                <Dropdown
                  className='btn btn-primary btn-sm fw-bolder me-5 p-0 mb-5 dropdown '
                  style={{ marginLeft: '1em' }}
                >
                  <Dropdown.Toggle variant='default' size='sm'>
                    <i className='bi bi-three-dots fs-4 p-0'></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-0'>
                    <Dropdown.Item
                      onClick={() => {
                        showWrapperModal('Importar CSV')
                      }}
                      className='p-0 d-flex flex-row justify-content-center'
                    >
                      <div
                        className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                        style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                      >
                        <i
                          className='bi bi-box-seam'
                          style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}
                        ></i>
                        <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                          Importar CSV
                        </p>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu >
                </Dropdown>

              </div>
            </div>
            <div className='card-body'>
              <div className='w-25'>
                <Input
                  inputAttr={{
                    type: 'select',
                  }}
                  value={locationId}
                  change={async (value: number) => {
                    setLocationId(value)
                    setLoading(true);
                    await getVariations(value);
                    setLoading(false);
                  }}
                  label={i18n.t(`smart_order.select_modality`)}
                  options={locations}
                />
              </div>
              <Datatable
                data={variations}
                headers={[
                  { key: i18n.t(`smart_order.sku`), value: 'sku', type: 'default' },
                  { key: i18n.t(`smart_order.name`), value: 'name', type: 'default' },
                  { key: i18n.t(`smart_order.specification`), value: 'specification', type: 'html' },
                  {
                    key: i18n.t(`smart_order.price`),
                    value: 'price',
                    type: 'input-money',
                    disable: disableFieldsTotvs,
                    action: (item) => changeVariation(item, 'price'),
                  },
                  {
                    key: i18n.t(`smart_order.inventory`),
                    value: 'inventory',
                    type: 'input-number',
                    disable: disableFieldsTotvs,
                    action: (item) => changeVariation(item, 'inventory'),
                  },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => { navigate(`/variation/editar/${item.id}`) },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.variation`)}`,
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`smart_order.variation`)}`)
                      setVariationId(item.id)
                    },
                    title: `${i18n.t(`general.delete`)} ${i18n.t(`smart_order.variation`)}`,
                  },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalWrapper
            confirmAction={() =>
              importFile()
            }
            textAction={'Confirmar'}
          >

            <div className='flex-grow-1'>
              <p>{i18n.t(`general.attention`)}</p>
              <p>
                Para importar os estoques mínimos, basta preencher o modelo abaixo com os seguintes padrões:
              </p>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  sku/ean, estoque mínimo , largura, altura, profundidade, peso
                </div>
                <div style={{ width: '30%' }}>
                  <Button
                    text={'Modelo'}
                    color='primary'
                    iconColor='#FFF'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      window.open('/file/estoque_minimo_exemplo.xlsx', '')
                    }}
                    btnClass='fw-bolder me-5'
                  />
                </div>
              </div>
            </div>
            <br />
            <div className='flex-grow-1 text-center'>
              <Input
                inputAttr={{
                  type: 'file',
                }}
                acceptFile={'.xlsx,.xls'}
                change={(value: BaseSyntheticEvent) =>
                  setFileToImport(value ? value.target.files[0] : null)
                }
              />
            </div>



          </ModalWrapper>

          <ModalDelete confirmAction={() => deleteVariation()} />
        </div>
      )}
    </>
  )
}

const Variacoes: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.variations`), path: 'variation', isActive: true },
        ]}
      >
        {i18n.t(`smart_order.variations`)}
      </PageTitle>
      <VariacoesContent />
    </>
  )
}

export { Variacoes }
