import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { LineChart } from '../../../components/Charts/LineChart/LineChart'
import { DashboardTable } from '../../../components/Datatable/DashboardTable'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import { getCurrentMonth } from '../../../utils/utils'
import { Input } from '../../../components/Input/Input'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import { Editor } from '@tinymce/tinymce-react'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'


const QueueConfigContent: FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState('');
    const [able, setAble] = useState(false);
    const [limitPeople, setLimitPeople] = useState(0);
    const [totalPerGroup, setTotalPerGroup] = useState(0)
    const [limitTime, setLimitTime] = useState(0);
    const [image1, setImage1] = useState<File | null | string>(null)
    const [firstStateImage1, setFirstStateImage1] = useState<File | null | string>(null)
    const [image2, setImage2] = useState<File | null | string>(null)
    const [firstStateImage2, setFirstStateImage2] = useState<File | null | string>(null)
    const [selectedImages, setSelectedImages] = useState<Array<string>>([]);
    const [observation, setObservation] = useState('');
    const [termsActive, setTermsActive] = useState(false);

    const changeQueueConfig = async () => {
        try {
            setLoading(true)
            const res = await POST('/queueReservation/queueConfig', {
                able,
                limitTime,
                limitPeople,
                observation,
                useTerms: termsActive,
                limitPeopleGroup: totalPerGroup
            })
            if (res && res.data && res.data) {
                const queueConfig = res.data
                let imagesQueue = [];

                if (image1 && image1 !== firstStateImage1) {
                    if (firstStateImage1 && !firstStateImage1.toString().includes('img.ninegrid.com/exemplo/')) {
                        await POST('/upload-images/delete-one', {
                            url: firstStateImage1,
                        })
                    }
                    const formData = new FormData()
                    formData.append('file', image1)
                    formData.append('nameImage', `queue-config-${uuid()}.jpg`)
                    formData.append('originalSize', 'true')
                    const imageRes = await POST('/upload-images/upload-one', formData)
                    if (imageRes && imageRes.image) {
                        imagesQueue.push(imageRes.image);
                    }
                } else if (image1 && image1 === firstStateImage1) {
                    imagesQueue.push(image1)
                }
                if (selectedImages[0] && imagesQueue.length < 2) {
                    imagesQueue.push(selectedImages[0])
                }
                if (image2 && image2 !== firstStateImage2) {
                    if (firstStateImage2 && !firstStateImage2.toString().includes('img.ninegrid.com/exemplo/')) {
                        await POST('/upload-images/delete-one', {
                            url: firstStateImage2,
                        })
                    }
                    const formData = new FormData()
                    formData.append('file', image2)
                    formData.append('nameImage', `queue-config-${uuid()}.jpg`)
                    formData.append('originalSize', 'true')
                    const imageRes = await POST('/upload-images/upload-one', formData)
                    if (imageRes && imageRes.image) {
                        imagesQueue.push(imageRes.image);
                    }
                } else if (image2 && image2 === firstStateImage2) {
                    imagesQueue.push(image2)
                }
                if (selectedImages[1] && imagesQueue.length < 2) {
                    imagesQueue.push(selectedImages[1])
                }
                await POST('/queueReservation/queueImage', {
                    images: imagesQueue,
                    queueConfigId: queueConfig.id
                })
                setLoading(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Configuração realizada!',
                }).then(async () => await init())
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao configurar fila!',
                })
            }
        } catch (e) {
            setLoading(false)
            console.log(e)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao configurar fila!',
            })
        }
    }

    const getQueueConfig = async () => {
        try {
            const res = await GET('/queueReservation/queueConfig')
            if (res && res.success && res.data && res.data.length > 0) {
                const queueConfig = res.data[0];
                setAble(queueConfig.able)
                setLimitPeople(queueConfig.limitPeople)
                setLimitTime(queueConfig.limitTime)
                setTotalPerGroup(queueConfig.limitPeopleGroup)
                setObservation(queueConfig.observation)
                setTermsActive(queueConfig.useTerms)
                setImage1(null);
                setImage2(null);
                setFirstStateImage1(null);
                setFirstStateImage2(null);
                if (queueConfig.images) {
                    let urls = [];
                    const images = queueConfig.images.sort((a: any, b: any) => (parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0))
                    if (images[0]) {
                        if (images[0].url.includes('img.ninegrid.com/exemplo/')) {
                            urls.push(images[0].url);
                        } else {
                            setImage1(images[0].url)
                            setFirstStateImage1(images[0].url)
                        }
                    } else {
                        setImage1(null)
                        setFirstStateImage1(null)
                    }
                    if (images[1]) {
                        if (images[1].url.includes('img.ninegrid.com/exemplo/')) {
                            urls.push(images[1].url);
                        } else {
                            setImage2(images[1].url)
                            setFirstStateImage2(images[1].url)
                        }
                    } else {
                        setImage2(null)
                        setFirstStateImage2(null)
                    }
                    setSelectedImages(urls)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getLink = async () => {
        const appRes = await GET('/appconfig');
        if (appRes && appRes.data) {
            const webapp = appRes.data.find((app: any) => app.type === 'qr');
            let url = '';
            if (webapp) {
                if (process.env.REACT_APP_PROD === '1') {
                    url =
                        'https://webapp' +
                        '.ninegrid.com.br/' +
                        window.btoa(webapp.pluginSpace.subdomain) +
                        '/queue'
                } else {
                    url =
                        process.env.REACT_APP_WEBAPP_URL +
                        '/' +
                        window.btoa(webapp.pluginSpace.subdomain) +
                        '/queue'
                }
            }
            setLink(url)
        }
    }

    const init = async () => {
        setLoading(true)
        await getLink();
        await getQueueConfig();
        setLoading(false)
    }

    const selectImage = (url: string) => {
        if (selectedImages.includes(url)) {
            setSelectedImages(selectedImages.filter((i) => i !== url));
        } else {
            if (selectedImages.length < 2) {
                const newSelecteds = selectedImages.map((i) => i);
                newSelecteds.push(url);
                setSelectedImages(newSelecteds);
            }
        }
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                        <div className='card-header py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`queue.config`)} </span>
                                <BreadCrumbs />
                            </h3>
                        </div>
                        <div className='card-body'>
                            <div className="d-flex flex-row gap-2 align-items-end">
                                <span className='fw-bolder fs-6 mb-1'>{i18n.t(`queue.able`)}</span>
                                <Input
                                    checkSwift
                                    inputAttr={{
                                        type: 'checkbox',
                                    }}
                                    checked={able}
                                    value={able}
                                    change={(value: boolean) => setAble(value)}
                                />
                                <span className='fw-bolder fs-6 mb-1 ml-2'>{i18n.t(`queue.link`)}</span>
                                <a target='_blank' className='fw-normal fs-6 mb-1' href={link}>{link}</a>
                            </div>
                            <div className="d-flex flex-column gap-2" style={{ maxWidth: '22em' }}>
                                <div className="d-flex flex-column">
                                    <Input
                                        inputAttr={{
                                            type: 'number',
                                        }}
                                        label={i18n.t(`queue.limit_people`)}
                                        value={limitPeople}
                                        change={(value: number) => setLimitPeople(value)}
                                    />
                                    <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`queue.limit_people_text`)}</span>
                                </div>
                                <div className="d-flex flex-column">
                                    <Input
                                        inputAttr={{
                                            type: 'number',
                                        }}
                                        label={i18n.t(`queue.total_people`)}
                                        value={totalPerGroup}
                                        change={(value: number) => setTotalPerGroup(value)}
                                    />
                                    <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`queue.total_people_text`)}</span>
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <Input
                                        inputAttr={{
                                            type: 'number',
                                        }}
                                        label={i18n.t(`queue.limit_time`)}
                                        value={limitTime}
                                        change={(value: number) => setLimitTime(value)}
                                    />
                                    <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`queue.limit_time_text`)}</span>
                                </div>
                                <div className="d-flex flex-column w-100">
                                    <Input
                                        inputAttr={{
                                            type: 'checkbox',
                                        }}
                                        label={i18n.t(`queue.use_terms`)}
                                        checked={termsActive}
                                        value={termsActive}
                                        change={(value: boolean) => setTermsActive(value)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mt-4">
                                <label className='form-label fw-normal'>
                                    {i18n.t(`queue.observation`)}
                                </label>
                                <Editor
                                    apiKey={process.env.REACT_APP_TINY_KEY}
                                    onEditorChange={(value: string) => setObservation(value)}
                                    value={observation}
                                    init={{
                                        height: 500,
                                        menubar: false,
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount',
                                        ],
                                        toolbar:
                                            'undo redo | formatselect | ' +
                                            'bold italic forecolor backcolor | alignleft aligncenter ' +
                                            'alignright alignjustify | bullist numlist outdent indent | ' +
                                            'removeformat | help',
                                        content_style:
                                            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                />
                            </div>
                            <div className="d-flex flex-column gap-2 mt-4">
                                <span className='fw-bolder fs-6 mb-1'>{i18n.t(`queue.image_title`)}</span>
                                <span className='fw-normal text-muted fs-7 mb-1 '>{i18n.t(`queue.image_text`)}</span>
                                <div className="d-flex flex-row gap-8">
                                    <Input
                                        inputAttr={{
                                            type: 'image',
                                        }}
                                        label={`${i18n.t(`queue.image`)} 1`}
                                        value={image1}
                                        change={(value: BaseSyntheticEvent) =>
                                            setImage1(value ? value.target.files[0] : null)
                                        }
                                    />
                                    <Input
                                        inputAttr={{
                                            type: 'image',
                                        }}
                                        label={`${i18n.t(`queue.image`)} 2`}
                                        value={image2}
                                        change={(value: BaseSyntheticEvent) =>
                                            setImage2(value ? value.target.files[0] : null)
                                        }
                                    />
                                </div>
                                <div className="d-flex flex-row flex-wrap gap-4 mt-6" style={{ width: '90%' }}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                        <div
                                            className='w-175px h-125px'
                                            style={selectedImages.includes(`https://s3.amazonaws.com/img.ninegrid.com/exemplo/${item}.png`) ? { border: '8px solid #5D8ED8' } : {}}
                                            onClick={() => selectImage(`https://s3.amazonaws.com/img.ninegrid.com/exemplo/${item}.png`)}
                                        >

                                            <img
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                src={`https://s3.amazonaws.com/img.ninegrid.com/exemplo/${item}.png`}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex flex-row justify-content-end">
                                    <Button
                                        text={i18n.t(`buttons.save`)}
                                        color='primary'
                                        size='small'
                                        horizontalPadding={6}
                                        btnAttr={{
                                            type: 'button',
                                        }}
                                        click={() => changeQueueConfig()}
                                        btnClass='fw-bolder m-9'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const QueueConfig: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`queue.config`), path: 'queue/config', isActive: true },
                ]}
            >
                {i18n.t(`queue.config`)}
            </PageTitle>
            <QueueConfigContent />
        </>
    )
}

export { QueueConfig }
