import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST } from '../../../../services/api'

import { i18n } from '../../../../translate/i18n';
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import usePluginsSpace from '../../../../hooks/usePluginSpace'

type PagesType = {
  id: number;
  name: string;
  type: string;
  menu: number;
  parent: number;
  children: PagesType[];
}

const CriarGrupoContent: FC = () => {

  const navigate = useNavigate();
  const { integrations } = usePluginsSpace()
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [pages, setPages] = useState<PagesType[]>([]);
  const [permissions, setPermissions] = useState<number[]>([]);

  const createGroup = async () => {
    try {
      if (!name || permissions.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const permissionsToSend = permissions.map((permission) => {
          return {
            pageId: permission
          }
        })
        const groupRes = await POST('/permission-group', {
          name,
          allowDelete: 0,
          permissions: permissionsToSend
        })
        setLoading(false);
        if (groupRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Grupo cadastrado',
            text: 'Cadastro realizado com sucesso!'
          }).then(() => navigate('/configuracoes/grupos'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar grupo!'
          })
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar grupo!'
      })
    }
  }

  const verifyIfIsNotChild = (page: PagesType, pages: PagesType[]) => {
    let isNotChild = true;
    pages.forEach((pageInside) => {
      pageInside.children.forEach((child) => {
        if (child.id === page.id) {
          isNotChild = false
        }
      })
    })
    return isNotChild
  }

  const getPages = async () => {
    try {
      setLoading(true);
      const hasSDS = integrations.find(el => el.plugintemplateId === 101)

      const pagesRes = await POST('/pages/build-group', { menu: 1, type: loginInfo.plugintype });
      let newPages = pagesRes.data.map((page: PagesType) => {
        page.children.forEach((pageC) => {
          const name = i18n.t(`permissions.${pageC.name}`) ? i18n.t(`permissions.${pageC.name}`) : pageC.name;
          if (name) {
            pageC.name = name
          }
        })
        return {
          id: page.id,
          name: page.name,
          type: page.type,
          menu: page.menu,
          children: page.children
        }
      })


      
      if (!hasSDS || hasSDS===undefined) {

        newPages = newPages.filter((page: PagesType) => {
          return page.name.toLowerCase().includes('sds') ? false : true
        })
      }


      setPages(newPages.filter((page: PagesType) => verifyIfIsNotChild(page, newPages)));
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const addPermission = (push: boolean, newPermissionId: number) => {
    if (push) {
      const newPermissions = permissions;
      newPermissions.push(newPermissionId);
      setPermissions(newPermissions);
    } else {
      const filterPermissions = permissions.filter((permission) => permission !== newPermissionId);
      setPermissions(filterPermissions);
    }
  }

  useEffect(() => {
    getPages();
  }, [])

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`buttons.create`)} ${i18n.t(`groups.groups`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center mb-4'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'text',
                    }}
                    placeholder={i18n.t(`groups.name`)}
                    label={i18n.t(`groups.name`)}
                    change={(value: string) => setName(value)}
                    value={name}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-start flex-wrap gap-3'>
                {pages.map((page) => (
                  <div className='d-flex flex-column mt-4' style={{ flex: '1 0 26%' }}>
                    <span className='card-label fw-bolder fs-3 mb-1 mt-2'>{i18n.t(`permissions.${page.name}`)}</span>
                    {page.children.length > 0 ?
                      page.children.map((pageInside) => (
                        <div style={{ flex: '20%' }} key={pageInside.id}>
                          <Input
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            placeholder={pageInside.name}
                            label={pageInside.name}
                            change={(value: boolean) => addPermission(value, pageInside.id)}
                          />
                        </div>
                      ))
                      :
                      <div style={{ flex: '20%' }} key={page.id}>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          placeholder={i18n.t(`permissions.${page.name}`)}
                          label={i18n.t(`permissions.${page.name}`)}
                          change={(value: boolean) => addPermission(value, page.id)}
                        />
                      </div>
                    }
                  </div>
                ))}
              </div>
            </div>
            <div className='col-12 d-flex justify-content-end '>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='info'

                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                  width="100px"
                />
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  width="100px"
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    createGroup()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
          </div>
        </div>}
    </>
  )
}

const CriarGrupos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`groups.groups`), path: 'configuracoes/grupos', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'configuracoes/grupos/criar', isActive: true },
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`groups.groups`)}`}
      </PageTitle>
      <CriarGrupoContent />
    </>
  )
}

export { CriarGrupos }
