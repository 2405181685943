/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { useNavigate } from 'react-router-dom'
import { LineChart } from '../../../../components/Charts/LineChart/LineChart'
import Chart from 'react-apexcharts'
import { GET } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { floatToPrice } from '../../../../functions/price'
import { Button } from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type MonthItemsResponseType = {
    month: string;
    total: number;
}

type CategoryResponseType = {
    id: number;
    name: string;
}

type CategorySalesResponseType = {
    name: string;
    sellers: number;
    total: number;
}

const ProdutosContent: FC = () => {
    const navigate = useNavigate()
    const [categories, setCategories] = useState<CategoryResponseType[]>([]);
    const [categorySelected, setCategorySelected] = useState(0);
    const [startDate, setStartDate] = useState('')
    const [finalDate, setFinalDate] = useState('')

    const [loading, setLoading] = useState(false)
    const [categorySales, setCategorySales] = useState<CategorySalesResponseType[]>([])
    const [totalValuesByMonth, setTotalValuesByMonth] = useState<MonthItemsResponseType[]>([]);

    const getItems = async () => {
        try {
            setLoading(true)
            const categoryRes = await GET('/dashboard/seller-category')
            const allMonthRes = await GET('/dashboard/seller-all-month?')
            const categoriesRes = await GET('/classification')
            setLoading(false)
            setCategorySales(categoryRes.data)
            setCategories([{ id: 0, name: 'Selecione uma categoria' }, ...categoriesRes.data])
            setTotalValuesByMonth(allMonthRes.data.map((monthItem: MonthItemsResponseType) => ({
                month: monthItem.month,
                total: monthItem.total.toFixed(2)
            })))
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const getItemsFiltered = async (once?: boolean) => {
        try {
            setLoading(true)
            const categoryRes = await GET(`/dashboard/seller-category?category=${categorySelected}&startDate=${startDate}&finalDate=${finalDate}`)
            const allMonthRes = await GET(`/dashboard/seller-all-month?category=${categorySelected}&startDate=${startDate}&finalDate=${finalDate}`)
            const categoriesRes = await GET('/classification')
            setLoading(false)
            setCategorySales(categoryRes.data)
            setCategories([{ id: 0, name: 'Selecione uma categoria' }, ...categoriesRes.data])
            setTotalValuesByMonth(allMonthRes.data.map((monthItem: MonthItemsResponseType) => ({
                month: monthItem.month,
                total: monthItem.total.toFixed(2)
            })))
            if (!once) {
                await getItemsFiltered(true);
            }
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    useEffect(() => {
        getItems()
    }, [])

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='d-flex justify-content-between gap-5'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12 w-100'>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.product`)}</span>
                                    <BreadCrumbs />
                                </h3>
                            </div>
                            <div className='card-body'>
                                <div>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.filters`)}</span>
                                    </h3>
                                </div>
                                <div className='d-flex gap-5 flex-wrap'>
                                    <Input
                                        inputAttr={{
                                            type: 'select',
                                        }}
                                        placeholder='Selecione um tipo'
                                        label={i18n.t(`indicators.categories`)}
                                        options={categories.map((category) => ({
                                            select: category.name,
                                            value: category.id
                                        }))}
                                        value={categorySelected}
                                        change={(value: string) => setCategorySelected(parseInt(value))}
                                    />
                                    <Input
                                        inputAttr={{
                                            type: 'date',
                                        }}
                                        label={i18n.t(`indicators.first`)}
                                        value={startDate}
                                        change={(value: string) => setStartDate(value)}
                                    />
                                    <Input
                                        inputAttr={{
                                            type: 'date',
                                        }}
                                        label={i18n.t(`indicators.end`)}
                                        value={finalDate}
                                        change={(value: string) => setFinalDate(value)}
                                    />
                                    <div className='d-flex align-items-end'>
                                        <Button
                                            text={i18n.t(`buttons.filter`)}
                                            color='primary'
                                            size='small'
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => getItemsFiltered()}
                                            btnClass='fw-bolder btn-info mb-1'
                                            verticalPadding={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between flex-wrap gap-5'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12' style={{ flex: 1, minWidth: '20em' }}>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.revenue`)}</span>
                                </h3>
                            </div>
                            <div className='card-body'>
                                {totalValuesByMonth.length > 0 &&
                                    <LineChart
                                        nameValue='Receita'
                                        data={totalValuesByMonth.map((monthItem) => monthItem.total)}
                                        options={totalValuesByMonth.map((monthItem) => monthItem.month)}
                                        height={220}
                                        isMoney
                                    />
                                }
                            </div>
                        </div>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12' style={{ flex: 1, minWidth: '20em' }}>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.sales_by_category`)}</span>
                                </h3>
                            </div>
                            <div className='card-body'>
                                <Chart options={{ labels: categorySales.map((sale) => (sale.name)) }} series={categorySales.map((sale) => (parseFloat(sale.total.toFixed(2))))} type="donut" height={220} />
                            </div>
                        </div>

                    </div>
                    <div className='d-flex justify-content-between gap-5 flex-wrap'>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12' style={{ flex: 1, minWidth: '10em' }}>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.revenue_by_category`)}</span>
                                </h3>
                            </div>
                            <div className='card-body'>
                                {categorySales.map((sale, index) => (
                                    <div className='d-flex justify-content-between' key={index}>
                                        <div className="w-50">
                                            <p>{sale.name}</p>
                                        </div>
                                        <div className="w-50">
                                            <b>{floatToPrice(sale.total)}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12' style={{ flex: 1, minWidth: '10em' }}>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.average_ticket`)}</span>
                                </h3>
                            </div>
                            <div className='card-body'>
                                {categorySales.map((sale, index) => (
                                    <div className='d-flex justify-content-between' key={index}>
                                        <div className="w-50">
                                            <p>{sale.name}</p>
                                        </div>
                                        <div className="w-50">
                                            <b>{floatToPrice(sale.total / sale.sellers)}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='card card-xxl-stretch mb-5 mb-xl-12' style={{ flex: 1, minWidth: '10em' }}>
                            <div className='card-header'>
                                <h3 className='card-title align-items-start flex-column'>
                                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`indicators.quantity`)}</span>
                                </h3>
                            </div>
                            <div className='card-body'>
                                {categorySales.map((sale, index) => (
                                    <div className='d-flex justify-content-between' key={index}>
                                        <div className="w-50">
                                            <p>{sale.name}</p>
                                        </div>
                                        <div className="w-50">
                                            <b>{sale.sellers}</b>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

const ProdutosIndicadores: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`indicators.product`), path: 'indicadores/chartproducts', isActive: true },
                ]}
            >
                {i18n.t(`indicators.product`)}
            </PageTitle>
            <ProdutosContent />
        </>
    )
}

export { ProdutosIndicadores }
