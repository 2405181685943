import { FC, useEffect, useState } from "react"

import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"
import { i18n } from "../../../translate/i18n"
import { GET } from "../../../services/api"
import { appConfig } from "./types/webAppAgregador.types"
import useAuth from "../../../hooks/useAuth"
import QRCode from "react-qr-code"
import { Loading } from "../../../components/Loading/Loading"

const WebAppAgregadorQrcode: FC = () => {
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false)
  const [config, setConfig] = useState<appConfig>();
  const [webapps, setWebapps] = useState<any[]>([])

  const getApps = async () => {
    try {
      setLoading(true)
      const appRes = await GET('/appconfig')



      const newWebapps: any[] = appRes.data
        .filter((webapp: any) => webapp.type === 'qr')
        .map((webapp: any) => {
          let url = ''
          let hash = ''
          if (loginInfo.plugintype === 'hospitality') {
            url =
              'https://' +
              webapp.pluginSpace.subdomain +
              '.ninegrid.com.br'
            hash = webapp.hash
          }
          else if (loginInfo.plugintype === 'aggregator') {
            url =
              process.env.REACT_APP_AGGREGATORAPP_URL +
              '/' +
              window.btoa(webapp.pluginSpace.subdomain)
          }
          else if (process.env.REACT_APP_PROD === '1') {
            const host = window.location.hostname.split('.')[0]
            const prefix = process.env.REACT_APP_WEBAPP_URL?.split('//')[0]
            const domain = process.env.REACT_APP_WEBAPP_URL?.split('//')[1]
            // url = `${prefix}//${host}.${domain}/${window.btoa(webapp.pluginspace[0].subdomain)}${(webapp.type === 'seller' ? '/seller' : '')}`
            url =
              'https://webapp' +
              // webapp.pluginSpace.subdomain +
              '.ninegrid.com.br/' +
              window.btoa(webapp.pluginSpace.subdomain) +
              (webapp.type === 'seller' ? '/seller' : '')
          } else {
            url =
              process.env.REACT_APP_WEBAPP_URL +
              '/' +
              window.btoa(webapp.pluginSpace.subdomain) +
              (webapp.type === 'seller' ? '/seller' : '')
          }
          return {
            id: webapp.id,
            title: webapp.name,
            webappLink: url,
            type: webapp.type
          }
        })
      setWebapps(newWebapps)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getApps()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`webapp.qrcode_webapp_aggregator`)}</span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='w-100 h-100'>
              <div className='card w-100 h-100'>
                <div className='d-flex flex-column justify-content-start h-100 w-50' >
                  <div className="d-flex " style={{margin:'30px'}}>
                    {webapps[0] &&
                      <div className="d-flex flex-column align-items-center" style={{padding:'10px'}}>
                        <QRCode value={webapps[0] ? webapps[0].webappLink : null} size={200} />
                        <a className='text-decoration-underline mt-2' href={webapps[0] ? webapps[0].webappLink : null} target="_blank">
                          {i18n.t(`webapp.link`)}
                        </a>
                      </div>

                    }
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>




      )
      }
    </>
  )
}

export { WebAppAgregadorQrcode }