import { FC, useState } from "react"
import { Input } from "../Input/Input"
import { Button } from "../Button/Button"
import { GET } from "../../services/api"
import { lojaAgregador } from "../../pages/Agregador/lojas/create"
import Swal from "sweetalert2"
import { cpfToNumber } from "../../utils/formater"
import { LittleButton } from "../Button/LittleButton"

type Props = {
  next: () => void
  back: () => void
  data: lojaAgregador
  setData: (value: any) => any
}

type address = {
  street: string
  country: string
  number: string
  extra: string
  district: string
  city: string
  state: string
}



const DadosLoja: FC<Props> = ({ next, back, data, setData }) => {

  const [address, setAddress] = useState<address>(data.config?.address)
  const [zipCode, setZipCode] = useState<string>(cpfToNumber(data.zipCode.toString()))
  const [corporateName, setCorporateName] = useState<string>(data.corporateName ? data.corporateName : data.corporatename)
  const [cnpj, setCnpj] = useState<string>(data.cnpj)

  const getCEP = async (value: string) => {
    value = value.replace(/[^\d]/g, "")
    if (value && value.length === 8) {
      try {
        //setLoading(true)
        const res = await GET(`https://viacep.com.br/ws/${value}/json/`)
        setAddress({ ...address, street: res.logradouro, district: res.bairro, city: res.localidade, state: res.uf, country: 'BR' })

      } catch (error) {

        console.log(error)
      }
    }
  }



  function nextPage() {
    if (!address || !zipCode || !corporateName || !cnpj || !address.number) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Verifique os campos faltantes'
      })
      return
    }
    const newData = data

    newData.config.address = address;
    newData.corporatename = corporateName
    newData.corporateName = corporateName
    newData.cnpj = cnpj
    newData.zipCode = zipCode

    setData(newData);
    next()

  }

  function backPage() {

    const newData = data

    newData.config.address = address;
    newData.corporatename = corporateName
    newData.cnpj = cnpj
    newData.zipCode = zipCode

    setData(newData);
    back()

  }


  return (
    <div className="card mt-5" style={{ padding: '2rem 5rem' }}>
      <div>
        <h3>Dados da Loja</h3>
        <span>Aqui você informações os dados da Loja.</span>
      </div>

      <div className="row">
        <div className="col d-flex flex-column border-end" >
          <Input
            inputAttr={{
              type: 'cnpj',
            }}
            label={'Cnpj'}
            value={cnpj}
            change={(value: any) => {
              setCnpj(value)
            }}
          />

          <Input
            inputAttr={{
              type: 'cep',
            }}
            label={'Cep'}
            options={[]}
            value={zipCode}
            change={(value: any) => {
              setZipCode(cpfToNumber(value))
            }}
            blur={(value: string) => getCEP(value)}
          />

          <Input
            inputAttr={{
              type: 'default',
            }}
            label={'Estado'}
            placeholder="Estado"
            disabled
            value={address?.state}
            change={(value: any) => {
              setAddress({ ...address, state: value })
            }}
          />

        </div>

        <div className="col d-flex flex-column">
          <Input
            inputAttr={{
              type: 'default',
            }}
            label={'Nome da Loja ·  deve ser a razão social '}

            value={corporateName}
            change={(value: any) => {
              setCorporateName(value)
            }}
          />

          <Input
            inputAttr={{
              type: 'default',
            }}
            label={'Pais'}
            disabled
            value={address?.country}
            change={(value: any) => {
              setAddress({ ...address, country: value })
            }}
          />

          <Input
            inputAttr={{
              type: 'default',
            }}
            label={'Cidade'}
            disabled
            placeholder="Cidade"
            value={address?.city}
            change={(value: any) => {
              setAddress({ ...address, city: value })
            }}
          />

        </div>

      </div>
      <div className="row ">
        <div className="col-11">
          <Input
            inputAttr={{
              type: 'default',
            }}

            label={'Endereço'}
            value={address?.street}
            change={(value: any) => {
              setAddress({ ...address, street: value })
            }}
          />
        </div>
        <div className="col-1">
          <Input
            inputAttr={{
              type: 'default',
            }}

            label={'Numero'}
            value={address?.number}
            change={(value: any) => {
              setAddress({ ...address, number: value })
            }}
          />
        </div>


      </div>
      <div className="row">
        <Input
          inputAttr={{
            type: 'default',
          }}

          label={'Complemento'}
          value={address?.extra}
          change={(value: any) => {
            setAddress({ ...address, extra: value })
          }}
        />


      </div>
      <div className="d-flex  gap-5 justify-content-end mt-5">
        <LittleButton text={"voltar"} color={"info"} click={() => backPage()} width="110px" height="35px" />

        <LittleButton text={"Próximo"} color={"primary"} click={() => nextPage()} width="110px" height="35px" />


      </div>
    </div>
  )


}

export { DadosLoja }