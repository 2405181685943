import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { Dashboard } from '../pages/Commons/Dashboard'
import { Pluginspace } from '../pages/Commons/Pluginspace'
import { Modalidades } from '../pages/Commons/Configuracoes/Modalidades'
import { TaxaEntrega } from '../pages/Commons/Configuracoes/TaxaEntrega'
import { Usuarios } from '../pages/Commons/Configuracoes/Usuarios'
import { Grupos } from '../pages/Commons/Configuracoes/Grupos'
import { CriarGrupos } from '../pages/Commons/Configuracoes/Grupos/create'
import { EditarGrupos } from '../pages/Commons/Configuracoes/Grupos/update'
import { OrdemPedido } from '../pages/Food/SmartOrder/OrdemPedido'
import { Kds } from '../pages/Food/SmartOrder/Kds'
import { Produtos } from '../pages/Commons/SmartOrder/Produtos'
import { MeioPagamento } from '../pages/Commons/Integracoes/MeioPagamento'
import { CriarMeioPagamento } from '../pages/Commons/Integracoes/MeioPagamento/create'
import { EditarMeioPagamento } from '../pages/Commons/Integracoes/MeioPagamento/update'
import { Pdv } from '../pages/Commons/Integracoes/Pdv'
import { CriarPdv } from '../pages/Commons/Integracoes/Pdv/create'
import { EditarPdv } from '../pages/Commons/Integracoes/Pdv/update'
import { Sms } from '../pages/Commons/Mensageria/Sms'
import { Multicanal } from '../pages/Commons/Mensageria/Multicanais'
import { CriarMulticanal } from '../pages/Commons/Mensageria/Multicanais/create'
import { EditarMulticanal } from '../pages/Commons/Mensageria/Multicanais/update'
import { ListaClientes } from '../pages/Commons/ListaClientes'
import { Opcionais } from '../pages/Food/SmartOrder/Opcionais'
import { Turnos } from '../pages/Commons/Configuracoes/Turnos'
import { Conta } from '../pages/Commons/Configuracoes/Conta'
import { EditarStatus } from '../pages/Commons/Mensageria/Sms/update'
import { CriarTaxaEntrega } from '../pages/Commons/Configuracoes/TaxaEntrega/create'
import { EditarTaxaEntrega } from '../pages/Commons/Configuracoes/TaxaEntrega/update'
import { CriarUsuarios } from '../pages/Commons/Configuracoes/Usuarios/create'
import { EditarUsuarios } from '../pages/Commons/Configuracoes/Usuarios/update'
import { Cep } from '../pages/Commons/Configuracoes/TaxaEntrega/Cep'
import { CriarCep } from '../pages/Commons/Configuracoes/TaxaEntrega/Cep/create'
import { EditarCep } from '../pages/Commons/Configuracoes/TaxaEntrega/Cep/update'
import { CepNegativo } from '../pages/Commons/Configuracoes/TaxaEntrega/Cep/CepNegativo'
import { CriarCepNegativo } from '../pages/Commons/Configuracoes/TaxaEntrega/Cep/CepNegativo/create'
import { EditarCepNegativo } from '../pages/Commons/Configuracoes/TaxaEntrega/Cep/CepNegativo/update'
import { Pedidos } from '../pages/Commons/Relatorios/Pedidos'
import { Transacoes } from '../pages/Commons/Relatorios/Transacoes'
import { TempoProducao } from '../pages/Commons/Relatorios/TempoProducao'
import { EditarCategoria } from '../pages/Commons/SmartOrder/Categorias/update'
import { CriarOpcionais } from '../pages/Food/SmartOrder/Opcionais/create'
import { EditarOpcionais } from '../pages/Food/SmartOrder/Opcionais/update'
import { CriarProdutos } from '../pages/Commons/SmartOrder/Produtos/create'
import { EditarProdutos } from '../pages/Commons/SmartOrder/Produtos/update'
import { DetalhesCliente } from '../pages/Commons/ListaClientes/update'
import { CriarPluginspace } from '../pages/Commons/Pluginspace/create'
import { Webapp } from '../pages/Commons/Webapp'
import { EditarWebapp } from '../pages/Commons/Webapp/update'
import { ConfiguracoesWebapp } from '../pages/Commons/Webapp/configuracoes'
import { Termos } from '../pages/Commons/Configuracoes/Termos'
import { CriarTermos } from '../pages/Commons/Configuracoes/Termos/create'
import { EditarTermos } from '../pages/Commons/Configuracoes/Termos/update'
import { Marcas } from '../pages/Food/SmartOrder/Marcas'
import { UnidadesMedida } from '../pages/Food/SmartOrder/UnidadesMedida'
import { Grades } from '../pages/Food/SmartOrder/Grades'
import { CriarGrades } from '../pages/Food/SmartOrder/Grades/create'
import { EditarGrades } from '../pages/Food/SmartOrder/Grades/update'
import { Variacoes } from '../pages/Food/SmartOrder/Variacoes'
import { CriarVariacoes } from '../pages/Food/SmartOrder/Variacoes/create'
import { EditarVariacoes } from '../pages/Food/SmartOrder/Variacoes/update'
import { PedidosIndicadores } from '../pages/Commons/Indicadores/Pedidos'
import { ProdutosIndicadores } from '../pages/Commons/Indicadores/Produtos'
import { AcessosIndicadores } from '../pages/Commons/Indicadores/Acessos'
import { ImportacoesImagensSKU } from '../pages/Commons/Importacoes/ImagensSKU'
import { ImportacoesImagensProdutos } from '../pages/Commons/Importacoes/ImagensProdutos'
import { Catalogos } from '../pages/Food/SmartOrder/Catalogos'
import { CriarCatalogos } from '../pages/Food/SmartOrder/Catalogos/create'
import { EditarCatalogos } from '../pages/Food/SmartOrder/Catalogos/update'
import { EditarPluginspace } from '../pages/Commons/Pluginspace/update'

import { FoodConfigAdicionais } from '../pages/Food/Configuracoes/Adicionais'

import { Produtos as FoodProdutos } from '../pages/Food/SmartOrder/Produtos'
import { CriarProdutos as FoodCriarProdutos } from '../pages/Food/SmartOrder/Produtos/create'
import { EditarProdutos as FoodEditarProdutos } from '../pages/Food/SmartOrder/Produtos/update'

import { RangeMap } from '../pages/Commons/Configuracoes/TaxaEntrega/RangeMap'

import { Reportabc } from '../pages/Commons/Indicators/ReportABC'
import { PlanosPagamentos } from '../pages/Commons/Configuracoes/PlanosPagamento'
import { CriarPlanosPagamentos } from '../pages/Commons/Configuracoes/PlanosPagamento/create'
import { EditarPlanosPagamentos } from '../pages/Commons/Configuracoes/PlanosPagamento/update'
import { PedidosView } from '../pages/Commons/Relatorios/Pedidos/view'
import { GenerateQRCodeWebapp } from '../pages/Commons/Webapp/generateQRCode'
import { Logs } from '../pages/Commons/Relatorios/Logs'
import { Categorias } from '../pages/Commons/SmartOrder/Categorias'
import { Charges } from '../pages/Commons/Integracoes/Cargas'
import { CriarCategoria } from '../pages/Commons/SmartOrder/Categorias/create'
import { Mensageria } from '../pages/Commons/Integracoes/Mensageria'
import { CriarMensageria } from '../pages/Commons/Integracoes/Mensageria/create'
import { EditarMensageria } from '../pages/Commons/Integracoes/Mensageria/update'
import { Outros } from '../pages/Commons/Integracoes/Outros'
import { CriarOutros } from '../pages/Commons/Integracoes/Outros/create'
import { EditarOutros } from '../pages/Commons/Integracoes/Outros/update'
import { ProdutosRelatorios } from '../pages/Commons/Produtos'

import { HospitalitySetores } from '../pages/Hospitality/Configuracoes/Setores'
import { HospitalityCriarSetor } from '../pages/Hospitality/Configuracoes/Setores/create'
import { HospitalityEditarSetor } from '../pages/Hospitality/Configuracoes/Setores/update'

import { ListarServicos } from '../pages/Commons/Servicos/listar'
import { CriarServico } from '../pages/Commons/Servicos/create'
import { EditarServico } from '../pages/Commons/Servicos/update'
import { FeedCategorias } from '../pages/Hospitality/Content/Category'
import { FeedCriarCategorias } from '../pages/Hospitality/Content/Category/create'
import { FeedEditarCategorias } from '../pages/Hospitality/Content/Category/update'
import { FeedConteudos } from '../pages/Hospitality/Content/Content'
import { FeedCriarConteudo } from '../pages/Hospitality/Content/Content/create'
import { QueueConfig } from '../pages/Commons/Queue/config'
import { Queue } from '../pages/Commons/Queue'
import { ConfiguracaoCheckout } from '../pages/Commons/Configuracoes/ConfiguracaoCheckout'
import { BookingConfig } from '../pages/Commons/Booking/config'
import { BookingEnvironment } from '../pages/Commons/Booking/environment'
import { BookingDay } from '../pages/Commons/Booking/day'
import { BookingReport } from '../pages/Commons/Booking/report'
import { BookingReportManagement } from '../pages/Commons/Booking/report-management'
import { BookingReportManagementDetail } from '../pages/Commons/Booking/report-management-detail'
import { DetalhesImportacaoImagem } from '../pages/Commons/Importacoes/Detalhes'
import { LiveShop } from '../pages/Commons/Liveshop'
import { CriarLiveShop } from '../pages/Commons/Liveshop/create'
import { EditarLiveShop } from '../pages/Commons/Liveshop/edit'
import { ImportacoesImagensCategorias } from '../pages/Commons/Importacoes/ImagensCategorias'
import { CopyConfigurations } from '../pages/Commons/CopyConfigurations/configurations'

import { HospitalityIcones } from '../pages/Hospitality/Configuracoes/Icones'

import { Categorias as ServiceCategory } from '../pages/Commons/Servicos/Category'
import { CriarCategorias as ServiceCategoryCreate } from '../pages/Commons/Servicos/Category/create'
import { EditarCategorias as ServiceCategoryUpdate } from '../pages/Commons/Servicos/Category/update'
import PluginSpaceProvider from '../context/pluginSpaceContext'
import { FeedEditarConteudo } from '../pages/Hospitality/Content/Content/edit'
import { LojasAgregador } from '../pages/Agregador/lojas'
import { LojasCreateAgregador } from '../pages/Agregador/lojas/create'
import { LojasEditarAgregador } from '../pages/Agregador/lojas/editar'
import { SegmentsAgregador } from '../pages/Agregador/segmentos'
import { SegmentosCreateAgregador } from '../pages/Agregador/segmentos/create'
import { ConfiguracaoSetorDeAtuacao } from '../pages/Agregador/configuracoes/setorAtuacao'
import { DadosConta } from '../pages/Agregador/configuracoes/dadosConta'
import { FeedCriarCategoriasAgregador } from '../pages/Agregador/category/create'
import { FeedEditarCategoriasAgregador } from '../pages/Agregador/category/update'
import { SegmentsEditarAgregador } from '../pages/Agregador/segmentos/editar'
import {CategoriasAgregador}  from '../pages/Agregador/category/index'
import {SubCategoriasAgregador} from '../pages/Agregador/subCategory/index'
import {FeedCriarSubCategoriasAgregador} from '../pages/Agregador/subCategory/create'
import{FeedEditarSubCategoriasAgregador} from '../pages/Agregador/subCategory/update'
import{PublicidadeAgregador} from '../pages/Agregador/publicidade/index'
import { CriarPublicidadeAgregador } from '../pages/Agregador/publicidade/create'
import { Payments } from '../pages/Commons/Payments'
import { PaymentConfig } from '../pages/Commons/Payments/config'
import { PaymentCreateConfig } from '../pages/Commons/Payments/createConfig'
import { PaymentEditConfig } from '../pages/Commons/Payments/editConfig'
import { Icon } from '../pages/Commons/icones'
import { FeedEditarConteudoAggregador } from '../pages/Agregador/feedRecursos/edit'
import { FeedConteudosAggregador } from '../pages/Agregador/feedRecursos'
import { FeedCriaConteudoAggregador } from '../pages/Agregador/feedRecursos/create'
import { FluxoFrete } from '../pages/Commons/Configuracoes/TaxaEntrega/geral'
import useAuth from '../hooks/useAuth'
import { EditarWebappHotel } from '../pages/Commons/Webapp/Hotel/updateHotel'
import { SdsManagement } from '../pages/SDS/SdsManagement'
import { SdsCreateManagement } from '../pages/SDS/SdsManagement/create'
import { LayoutSDS } from '../pages/SDS/layout'
import { CreateLayoutSDS } from '../pages/SDS/layout/create'
import { WebAppAgregador } from '../pages/Agregador/webApp/webAppAgregador'
import { WebAppAgregadorQrcode } from '../pages/Agregador/webApp/webAppAgregadorQrcode'
import { EditHabilitarRecursos } from '../pages/Agregador/habilitarRecursos/edit'
import { IndexHabilitarRecursos } from '../pages/Agregador/habilitarRecursos'
import { CreateHabilitarRecursos } from '../pages/Agregador/habilitarRecursos/create'
import { IAConfig } from '../pages/Commons/IA/Config/index'
import { IACreateConfig } from '../pages/Commons/IA/Config/createConfig'
import { IAConfigItem } from '../pages/Commons/IA/Config/config'
import { ConfigIAEdit } from '../pages/Commons/IA/Config/editConfig'
import { Fit } from '../pages/Commons/IA/Treinamento'
import { EditarPublicidadeAgregador } from '../pages/Agregador/publicidade/update'
import { SdsCreateManagementHospitality } from '../pages/SDS/SdsManagementHospitality/create'
import { SdsManagementHospitality } from '../pages/SDS/SdsManagementHospitality'

const PrivateRoutes = () => {
  const { loginInfo } = useAuth()
  return (
    <PluginSpaceProvider>
      <Routes>
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to='/dashboard' />} />
          {/* Pages */}
          <Route path='pluginspace' element={<Pluginspace />} />
          <Route path='pluginspace/criar' element={<CriarPluginspace />} />
          <Route path='pluginspace/editar/:id' element={<EditarPluginspace />} />
          <Route path='dashboard' element={<Dashboard />} />
          <Route path='pluginspace/copy-configurations/:id' element={<CopyConfigurations />} />
          {/* Configurações */}
          <Route path='configuracoes'>
            <Route path='modalidades' element={<Modalidades />} />

            <Route path='taxa-entrega' element={<FluxoFrete />} />
            <Route path='taxa-entrega/raio' element={<TaxaEntrega />} />
            <Route path='taxa-entrega/raio/criar' element={<CriarTaxaEntrega />} />
            <Route path='taxa-entrega/raio/editar/:id' element={<EditarTaxaEntrega />} />
            <Route path='taxa-entrega/cep' element={<Cep />} />
            <Route path='taxa-entrega/cep/criar' element={<CriarCep />} />
            <Route path='taxa-entrega/cep/editar/:id' element={<EditarCep />} />
            <Route path='taxa-entrega/cep/negativo' element={<CepNegativo />} />
            <Route path='taxa-entrega/cep/negativo/criar' element={<CriarCepNegativo />} />
            <Route path='taxa-entrega/cep/negativo/editar/:id' element={<EditarCepNegativo />} />
            <Route path='taxa-entrega/map' element={<RangeMap />} />

            <Route path='usuarios' element={<Usuarios />} />
            <Route path='usuarios/criar' element={<CriarUsuarios />} />
            <Route path='usuarios/editar/:id' element={<EditarUsuarios />} />

            <Route path='grupos' element={<Grupos />} />
            <Route path='grupos/criar' element={<CriarGrupos />} />
            <Route path='grupos/editar/:id' element={<EditarGrupos />} />

            <Route path='planos-pagamento' element={<PlanosPagamentos />} />
            <Route path='planos-pagamento/criar' element={<CriarPlanosPagamentos />} />
            <Route path='planos-pagamento/editar/:id' element={<EditarPlanosPagamentos />} />

            <Route path='pagamentos' element={<Payments />} />
            <Route path='pagamentos/criar/:id' element={<PaymentCreateConfig />} />
            <Route path='pagamentos/edit/:id' element={<PaymentEditConfig />} />
            <Route path='pagamentos/:id' element={<PaymentConfig />} />

            <Route path='turnos' element={<Turnos />} />
            <Route path='conta' element={<Conta />} />
            <Route path='termos' element={<Termos />} />
            <Route path='termos/criar' element={<CriarTermos />} />
            <Route path='termos/editar/:id' element={<EditarTermos />} />

            <Route path='checkout' element={<ConfiguracaoCheckout />} />
          </Route>
          {/* Clientes */}
          <Route path='customer'>
            <Route path='report' element={<ListaClientes />} />
            <Route path='report/:id' element={<DetalhesCliente />} />
          </Route>
          {/* Indicadores */}
          <Route path=''>
            <Route path='chartorders' element={<PedidosIndicadores />} />
            <Route path='chartproducts' element={<ProdutosIndicadores />} />
            <Route path='summary_access' element={<AcessosIndicadores />} />
          </Route>
          {/* Smart Order */}
          <Route path=''>
            <Route path='smart-order/ordem-pedido' element={<OrdemPedido />} />
            <Route path='smart-order/kds' element={<Kds />} />

            <Route path='product_fashion' element={<Produtos />} />
            <Route path='product_fashion/criar' element={<CriarProdutos />} />
            <Route path='product_fashion/editar/:id' element={<EditarProdutos />} />

            {/* <Route path='hospitality/product' element={<ProdutosHospitality />} />
          <Route path='hospitality/product/criar' element={<CriarProdutosHospitality />} />
          <Route path='hospitality/product/editar/:id' element={<EditarProdutos />} /> */}

            <Route path='product_varejo' element={<Produtos />} />
            <Route path='product_varejo/criar' element={<CriarProdutos />} />
            <Route path='product_varejo/editar/:id' element={<EditarProdutos />} />

            <Route path='smart-order/opcionais' element={<Opcionais />} />
            <Route path='smart-order/opcionais/:type/criar' element={<CriarOpcionais />} />
            <Route path='smart-order/opcionais/:type/editar' element={<EditarOpcionais />} />

            <Route path='category' element={<Categorias />} />
            <Route path='category/criar' element={<CriarCategoria />} />
            <Route path='category/editar/:id' element={<EditarCategoria />} />

            <Route path='smart-order/tds' element={<></>} />

            <Route path='mark' element={<Marcas />} />

            <Route path='variation' element={<Variacoes />} />
            <Route path='variation/criar' element={<CriarVariacoes />} />
            <Route path='variation/editar/:variationId' element={<EditarVariacoes />} />

            <Route path='grid' element={<Grades />} />
            <Route path='grid/criar' element={<CriarGrades />} />
            <Route path='grid/editar/:id' element={<EditarGrades />} />

            <Route path='measurement_unit' element={<UnidadesMedida />} />

            <Route path='catalog' element={<Catalogos />} />
            <Route path='catalog/criar' element={<CriarCatalogos />} />
            <Route path='catalog/editar/:id' element={<EditarCatalogos />} />
          </Route>
          {/* Webapp */}
          <Route path='webapp' element={<Webapp />} />
          <Route path='webapp/editar/:id' element={loginInfo.plugintype !== 'hospitality' ? <EditarWebapp /> : <EditarWebappHotel />} />
          <Route path='webapp/configuracoes/:id' element={<ConfiguracoesWebapp />} />
          {/* Integrações */}
          <Route path='pluginconfig'>
            <Route path='gateway' element={<MeioPagamento />} />
            <Route path='gateway/create' element={<CriarMeioPagamento />} />
            <Route path='gateway/edit/:id' element={<EditarMeioPagamento />} />

            <Route path='pdv' element={<Pdv />} />
            <Route path='pdv/create' element={<CriarPdv />} />
            <Route path='pdv/edit/:id' element={<EditarPdv />} />

            <Route path='messenger' element={<Mensageria />} />
            <Route path='messenger/create' element={<CriarMensageria />} />
            <Route path='messenger/edit/:id' element={<EditarMensageria />} />

            <Route path='others' element={<Outros />} />
            <Route path='others/create' element={<CriarOutros />} />
            <Route path='others/edit/:id' element={<EditarOutros />} />

            <Route path='charge_integration' element={<Charges />} />
          </Route>
          {/* Relatórios */}
          <Route path='order'>
            <Route path='saleorder' element={<Pedidos />} />
            <Route path='logs' element={<Logs />} />
            <Route path='saleorder/:id' element={<PedidosView />} />
            <Route path='transacoes' element={<Transacoes />} />
            <Route path='tempo-producao' element={<TempoProducao />} />
          </Route>
          <Route path='report/product_report' element={<ProdutosRelatorios />} />
          {/* Mensageria */}
          <Route path=''>
            <Route path='message' element={<Sms />} />
            <Route path='message/edit/:id' element={<EditarStatus />} />

            <Route path='multichannel' element={<Multicanal />} />
            <Route path='multichannel/criar/' element={<CriarMulticanal />} />
            <Route path='multichannel/editar/:id' element={<EditarMulticanal />} />
          </Route>
          {/* Importações */}
          <Route path='product_fashion'>
            <Route path='prepare_import_image_product_sku' element={<ImportacoesImagensSKU />} />
            <Route path='prepare_import_image_product' element={<ImportacoesImagensProdutos />} />
            <Route path='prepare_import_image_categories' element={<ImportacoesImagensCategorias />} />
            <Route path='prepare_import_image_product/:id' element={<DetalhesImportacaoImagem />} />
          </Route>

          <Route path='product_varejo'>
            <Route path='prepare_import_image_product' element={<ImportacoesImagensProdutos />} />
            <Route path='prepare_import_image_categories' element={<ImportacoesImagensCategorias />} />
            <Route path='prepare_import_image_product/:id' element={<DetalhesImportacaoImagem />} />
          </Route>

          <Route path='product_food'>
            <Route path='prepare_import_image_product' element={<ImportacoesImagensProdutos />} />
            <Route path='prepare_import_image_categories' element={<ImportacoesImagensCategorias />} />
            <Route path='prepare_import_image_product/:id' element={<DetalhesImportacaoImagem />} />
          </Route>
          <Route path='configuracoes/adicionais' element={<FoodConfigAdicionais />} />
          {/* FOOD */}
          <Route path='product_food'>
            <Route path='' element={<FoodProdutos />} />
            <Route path='criar' element={<FoodCriarProdutos />} />
            <Route path='editar/:id' element={<FoodEditarProdutos />} />
          </Route>
          {/* ******* */}
          {/* FOOD HOSPITALITY */}
          <Route path='product_hospitality'>
            <Route path='' element={<FoodProdutos />} />
            <Route path='criar' element={<FoodCriarProdutos />} />
            <Route path='editar/:id' element={<FoodEditarProdutos />} />
          </Route>
          {/* ******* */}
          {/* SETORES HOSPITALITY */}
          <Route path='hospitality/configuracoes/setores' element={<HospitalitySetores />} />
          <Route path='hospitality/configuracoes/setores/criar' element={<HospitalityCriarSetor />} />
          <Route path='hospitality/configuracoes/setores/editar/:id' element={<HospitalityEditarSetor />} />
          {/* ******* */}
          <Route path='hospitality/configuracoes/icones' element={<HospitalityIcones />} />


          {/* Serviços */}
          <Route path='hospitality/service-item'>
            <Route path='' element={<ListarServicos />} />
            <Route path='create' element={<CriarServico />} />
            <Route path='update/:id' element={<EditarServico />} />
          </Route>
          -
          <Route path=''>
            <Route path='hospitality/service-category' element={<ServiceCategory />} />
            <Route path='hospitality/service-category/create' element={<ServiceCategoryCreate />} />
            <Route
              path='hospitality/service-category/update/:id'
              element={<ServiceCategoryUpdate />}
            />
          </Route>
          {/* Feed */}
          <Route path=''>
            <Route path='hospitality/content/category' element={<FeedCategorias />} />
            <Route path='hospitality/content/category/criar' element={<FeedCriarCategorias />} />
            <Route path='hospitality/content/category/editar/:id' element={<FeedEditarCategorias />} />

            <Route path='hospitality/content/content' element={<FeedConteudos />} />
            <Route path='hospitality/content/content/create' element={<FeedCriarConteudo />} />
            <Route path='hospitality/content/content/editar/:id' element={<FeedEditarConteudo />} />
          </Route>
          {/* Queue */}
          <Route path='queue'>
            <Route path='config' element={<QueueConfig />} />
            <Route path='manage' element={<Queue />} />
          </Route>
          {/* Booking */}
          <Route path='booking'>
            <Route path='config' element={<BookingConfig />} />
            <Route path='environment' element={<BookingEnvironment />} />
            <Route path='day' element={<BookingDay />} />
            <Route path='report' element={<BookingReport />} />
            <Route path='management' element={<BookingReportManagement />} />
            <Route path='management/:search' element={<BookingReportManagementDetail />} />
          </Route>
          <Route path='reportabc' element={<Reportabc />} />

          {/* Live Shop */}
          <Route path=''>
            <Route path='live_shop' element={<LiveShop />} />
            <Route path='live_shop/criar' element={<CriarLiveShop />} />
            <Route path='live_shop/editar/:id' element={<EditarLiveShop />} />
          </Route>


          {/* Novas Rotas do Agregador */}
          <Route path='config'>
            <Route path='account' element={<DadosConta />} />
            <Route path='sector' element={<ConfiguracaoSetorDeAtuacao />} />
            
            {/*Configuracao dos usuarios */}
            <Route path='user' element={<Usuarios />} />
            <Route path='user/criar' element={<CriarUsuarios />} />
            <Route path='user/editar/:id' element={<EditarUsuarios />} />

            <Route path='term' element={<Termos />} />
            <Route path='term/criar' element={<CriarTermos />} />
            <Route path='term/editar/:id' element={<EditarTermos />} />
          </Route>






          {/* Rotas do Agregador Lojas*/}
          <Route path=''>
            <Route path='segmentos' element={<SegmentsAgregador />} />
            <Route path='segmentos/criar' element={<SegmentosCreateAgregador />} />
            <Route path='segmentos/editar/:id' element={<SegmentsEditarAgregador />} />
          </Route>
          <Route path=''>
          <Route path='webapp/personalize' element={ <WebAppAgregador/>} />
          <Route path='webapp/qrcode' element={ <WebAppAgregadorQrcode/>} />
          </Route>


          {/* Rotas do Agregador Segmentos*/}
          <Route path=''>
            <Route path='stores' element={<LojasAgregador />} />
            <Route path='stores/criar' element={<LojasCreateAgregador />} />
            <Route path='stores/editar/:id' element={<LojasEditarAgregador />} />
          </Route>
          <Route path='aggregator/'>
            <Route path='content' element={<FeedConteudosAggregador />} />
            <Route path='content/create' element={<FeedCriaConteudoAggregador />} />
            <Route path='content/editar/:id' element={<FeedEditarConteudoAggregador />} />
          </Route>
          
          {/* Rotas do Agregador Segmentos*/}

          <Route path='footer/'>
            <Route path='feature' element={<IndexHabilitarRecursos />} />
            <Route path='feature/create' element={<CreateHabilitarRecursos />} />
            <Route path='feature/editar/:id' element={<EditHabilitarRecursos />} />
          </Route>

          <Route path='footer/'>
            <Route path='content' element={<FeedConteudosAggregador />} />
            <Route path='content/create' element={<FeedCriaConteudoAggregador />} />
            <Route path='content/editar/:id' element={<FeedEditarConteudoAggregador />} />
          </Route>


          {/* Rotas para a biblioteca de icones  */}
          <Route path=''>
            <Route path='icon' element={<Icon />} />
            {/*
            <Route path='icon/categoria' element={<LojasAgregador />} />
            <Route path='icon/categoria/criar' element={<LojasCreateAgregador />} />
            <Route path='icon/categoria/editar/:id' element={<LojasEditarAgregador />} />

          */}
          </Route>

          {/* Rota Categoria */}

          <Route path='agregador/category'>
          <Route path='' element={<CategoriasAgregador />} />
            <Route path='create' element={<FeedCriarCategoriasAgregador />} />
            <Route path='editar/:id' element={<FeedEditarCategoriasAgregador />} />
          </Route>

          {/* Rota Subcategoria */}

          <Route path='agregador/subcategory'>
          <Route path='' element={<SubCategoriasAgregador />} />
            <Route path='create' element={<FeedCriarSubCategoriasAgregador />} />
            <Route path='editar/:id' element={<FeedEditarSubCategoriasAgregador />} />
          </Route>

          {/* Rota Publicidade */}

          <Route path='/agregador/publicidade'>
          <Route path='' element={<PublicidadeAgregador/>} />
            <Route path='create' element={<CriarPublicidadeAgregador />} />
            <Route path='editar/:id' element={<EditarPublicidadeAgregador />} />
          </Route>

          {/* Rotas do SDS */}

          <Route path='sds'>
            <Route path='manage' element={<SdsManagement />} />
            <Route path='manage/create' element={<SdsCreateManagement />} />

            <Route path='layout' element={<LayoutSDS />} />
            <Route path='layout/create' element={<CreateLayoutSDS />} />


            <Route path='manage-hospitality' element={<SdsManagementHospitality />} />
            <Route path='manage-hospitality/create' element={<SdsCreateManagementHospitality />} />
            

          </Route>

          {/* IA */}
          <Route path='ia'>
            <Route path='config' element={<IAConfig />} />
            <Route path='config/:id' element={<IAConfigItem />} />
            <Route path='config/criar/:id' element={<IACreateConfig />} />
            <Route path='config/edit/:id' element={<ConfigIAEdit />} />
            <Route path='fit' element={<Fit />} />
          </Route>

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>

        <Route path='webapp/generate-qr-code/:data' element={<GenerateQRCodeWebapp />} />
      </Routes>
    </PluginSpaceProvider>
  )
}

export { PrivateRoutes }
