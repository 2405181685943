import { copyFile } from 'fs';
import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import usePluginsSpace from '../../../../app/hooks/usePluginSpace';
import useAuth from '../../../../app/hooks/useAuth';


function AlertStatusPluginSpace() {
    const [show, setShow] = useState(false);
    const { loginInfo } = useAuth()
    const { getPluginSpace } = usePluginsSpace()

    async function showAlert() {
        const pluginSpace = await getPluginSpace()

        setShow(!pluginSpace.status)

    }


    useEffect(() => {
        showAlert()
    }, [loginInfo.pluginspaceId])

    return (
        <>
            {show && <Alert variant="warning fixed-top d-flex justify-content-center rounded-0" onClose={() => setShow(false)} dismissible>
                <span className='text-center'>
                    Sua conta se encontra desativada, entre em contato com o responsável pela sua conta!
                </span>

            </Alert>}
        </>
    );

}

export { AlertStatusPluginSpace };