/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import { GET, POST } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { CSVLink } from 'react-csv'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import { getPlugintypeIdByCode } from '../../../../functions/plugintype'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ImportationType = {
  id: number
  timeStart: string
  timeEnd: string
  totalProducts: number
  synchronizedProducts: number
  errorsProducts: string
  type: string | null;
  typeItems: string;
}

const ImportacoesImagensCategoriasContent: FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [importations, setImportations] = useState<ImportationType[]>([])

  // const [fileToImport, setFileToImport] = useState<File | null>(null)
  // const [noUpload, setNoUpload] = useState(false)
  const { loginInfo } = useAuth()
  const [haveDropbox, setHaveDropbox] = useState(false);
  const [resizeType,] = useState('resize');

  const verifyDropbox = async (type: string) => {
    let plugintemplate = 0;
    switch(getPlugintypeIdByCode(type)){
      case 15:
        plugintemplate = 109;
        break;
      case 17:
        plugintemplate = 110;
        break;
      case 18:
        plugintemplate = 111;
        break
    }
    if(plugintemplate){
      const pluginconfigRes = await POST('pluginconfig/filter', {plugintemplateId: plugintemplate})
      if(pluginconfigRes.data && pluginconfigRes.data[0]) setHaveDropbox(true);
      else setHaveDropbox(false);
    } else {
      setHaveDropbox(false);
    }
  }

  const getImportations = async () => {
    try {
      setLoading(true)
      const resPluginspace = await GET(`pluginspace/${loginInfo.pluginspaceId}`) 
      await verifyDropbox(resPluginspace.data[0].type);
      const gmt = resPluginspace.data[0].gmt

      const importationRes = await GET('/upload-images-status/ninegrid')

      const newImportations = importationRes.data.map((importation: ImportationType) => (
        {

          id: importation.id,
          timeStart: importation.timeStart,
          timeEnd: importation.timeEnd === null ? null : importation.timeEnd,
          totalProducts: importation.totalProducts === null ? '-' : importation.totalProducts,
          synchronizedProducts: importation.synchronizedProducts === null ? '-' : importation.synchronizedProducts,
          errorsProducts: importation.totalProducts === null ? '-' : `<b style="color: #DD4A25">${importation.totalProducts - importation.synchronizedProducts}</b>`,
          type: importation.type ? i18n.t(`imports.${importation.type}`) : '-',
          typeItems: importation.typeItems
      })).filter((importation:ImportationType) => importation.typeItems === 'categories')
      setImportations(newImportations)
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : '',
      }).then(() => navigate('/pluginconfig/others'))
    }
  }

  const initiateRepositoryGdrive = async () => {
    try {
      
      setLoading(true)

      const importRes = await POST('/upload-images-status/repository/gdrive-categories', {
        timeStart: new Date(),
        description: 'Upload Iniciado',
        resizeType,
      })

      setLoading(false)
      if (importRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Importação realizada com sucesso!',
        }).then(async () => await getImportations())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao importar imagens!',
        }).then(async () => await getImportations())
      }
      
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getImportations())
    }
  }

  const initiateRepositoryDropbox = async () => {
    try {
      if(!haveDropbox){
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'É necessário configurar o plugin para utilizar o Dropbox!',
        }).then(async () => await getImportations())
        return
      }

      setLoading(true)

      const importRes = await POST('/upload-images-status/repository/dropbox-categories', {
        timeStart: new Date(),
        description: 'Upload Iniciado',
        resizeType
      })

      setLoading(false)
      if (importRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Importação realizada com sucesso!',
        }).then(async () => await getImportations())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao importar imagens!',
        }).then(async () => await getImportations())
      }
      
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getImportations())
    }
  }

  const confirmAction = async (type:string) => {
    let text = ''
    switch(type){
      case 'drive':
        text = 'Ao confirmar, iremos ler todos os arquivos disponíveis no drive configurado, você tem certeza?';
        break;
      case 'dropbox':
        text = 'Ao confirmar, iremos ler todos os arquivos disponíveis no dropbox configurado, você tem certeza?'
        break;
      default:
        text = 'Ao confirmar, iremos ler todos os arquivos disponíveis na planilha, você tem certeza?'
    }
    return await Swal.fire({
      icon: 'warning',
      title: 'Processamento de imagens',
      text,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonColor: '#dc3741',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then(async (res) => {
      return res.isConfirmed
    })
  }

  useEffect(() => {
    getImportations()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div style={{border: 'none'}} className='card-header   py-5 d-flex flex-row justify-content-between align-items-center'>
              <span className='card-label fw-bolder fs-3 mb-1'>
                {i18n.t(`imports.new_import`)}
                <BreadCrumbs />
              </span>
              <div className='d-flex flex-row flex-grow-1 justify-content-end'>
                <Button
                  text={i18n.t(`buttons.att_page`)}
                  color='primary'
                  size='small'
                  icon='bi bi-arrow-repeat'
                  iconColor='white'
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => getImportations()}
                  btnClass='fw-bolder'
                />
              </div>
            </div>
            <div style={{border: 'none'}} className='card-header d-flex flex-row justify-content-between align-items-start'>
              <div className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-4 mt-3 mb-3'>
                  {i18n.t(`imports.import_repo`)}
                </span>   
                <span className='card-label fw-bolder fs-5 mt-1 mb-3'>
                  {i18n.t(`imports.steps`)}
                </span>
                <span style={{ marginLeft: 10 }} className='text-muted mt-1 fw-bold fs-7'>
                  <p className='mb-0 mt-2'>{i18n.t(`imports.step_1_repo`)}</p>
                  <p className='mb-0 mt-2'>{i18n.t(`imports.step_2_repo_category`)}</p>
                  <p className='mb-0 mt-2'>{i18n.t(`imports.step_3_repo_category`)}</p>
                  <p className='mb-0 mt-2'>{i18n.t(`imports.step_4_repo_category`)}</p>
                </span>
                <div className='d-flex flex-row w-100 mt-5 gap-5'>
                  <button 
                    onClick={async () => {if(await confirmAction('dropbox')) await initiateRepositoryDropbox()}}
                    className='w-100 btn btn-outline-primary border border-primary fw-bolder btn-sm px-6'
                  >
                      <img style={{width: 20, height: 20, marginRight: 10}} src='/media/icons/upload/Dropbox_Icon.png' alt="Dropbox Icon" />
                      <span className='indicator-label'>{i18n.t(`imports.import_dropbox`)}</span>
                  </button>
                  <button 
                    onClick={async () => {if(await confirmAction('drive')) await initiateRepositoryGdrive()}}
                    className='w-100 btn btn-outline-danger border border-danger fw-bolder btn-sm px-6'
                  >
                      <img style={{width: 20, height: 20, marginRight: 10}} src='/media/icons/upload/Google_Drive_Icon.png' alt="Dropbox Icon" />
                      <span className='indicator-label'>{i18n.t(`imports.import_drive`)}</span>
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="card-body flex flex-row align-items-center" style={{width: '30rem'}}>
              <Input
                inputAttr={{
                  type: 'select',
                }}
                label={i18n.t(`imports.type_resize`)}
                options={[{select: 'Crop', value: 'crop'}, {select: 'Resize', value: 'resize'}, {select: 'Nenhum', value: 'none'}]}
                value={resizeType}
                change={(value: string) => {
                  setResizeType(value)
                }}
              />
            </div> */}
            <div className='card-body'>
              <div className='mt-2'>
                <Datatable
                  data={importations}
                  headers={[
                    { key: i18n.t(`imports.first`), value: 'timeStart', type: 'datetime' },
                    { key: i18n.t(`imports.end`), value: 'timeEnd', type: 'datetime' },
                    { key: i18n.t(`imports.total_categories`), value: 'totalProducts', type: 'default' },
                    { key: i18n.t(`imports.sync_categories`), value: 'synchronizedProducts', type: 'default' },
                    { key: i18n.t(`imports.problem_title`), value: 'errorsProducts', type: 'html' },
                    { key: i18n.t(`imports.type`), value: 'type', type: 'default' },
                    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                  ]}
                  color='primary'
                  options={[
                    {
                      icon: 'fas fa-eye',
                      action: (item) => { 
                        navigate(`${window.location.pathname.split("prepare_import_image")[0]}prepare_import_image_product/${item.id}`) 
                      },
                      title: `${i18n.t(`buttons.view`)}`,
                    },

                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const ImportacoesImagensCategorias: FC = () => {
  const { loginInfo } = useAuth();
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`imports.imports`), path: loginInfo.plugintype === 'varejo' ? 'product_varejo/prepare_import_image_categories' : 'product_fashion/prepare_import_image_categories', isActive: true },
        ]}
      >
        {i18n.t(`imports.import_image_category`)}
      </PageTitle>
      <ImportacoesImagensCategoriasContent />
    </>
  )
}

export { ImportacoesImagensCategorias }
