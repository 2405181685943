import { useContext } from 'react'
import { PluginSpaceContext } from '../context/pluginSpaceContext'

const usePluginsSpace = () => {
    const values = useContext(PluginSpaceContext)

    return { ...values }
}

export default usePluginsSpace
