import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Button} from '../../../components/Button/Button'
import {Input} from '../../../components/Input/Input'
import {Loading} from '../../../components/Loading/Loading'
import {GET, POST, PUT} from '../../../services/api'
import {i18n} from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import SVG from 'react-inlinesvg';
import useAuth from '../../../hooks/useAuth'
import { Editor } from '@tinymce/tinymce-react'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from "../../../hooks/useModals"
import usePluginsSpace from '../../../hooks/usePluginSpace'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'
import { EditorQuill } from '../../../components/EditorQuill/EditorQuill'

type segmentoAgregador = {
  name: string;
  order: number;
  image: string
  type: string;
  active: boolean;
  id: number;
}

type newSegmento = {
  pluginspaceId: number,
  segment: number,
  id: number
}

type mostraSetor = {
  value: string
  select: string
}

type Icon = {
  id: number;
  svg: string;
  description: string;
  category: string;
}

type CategoryType = {
  id: number
  parent: string
  code: string
  name: string
  shortname: string
  order: 0
  img: string
  status: true
  subitems: CategoryType[]
}

const Content: FC = () => {
    const navigate = useNavigate()
    const { loginInfo } = useAuth();
    const [loading, setLoading] = useState(false)
    const { pluginSpace } = usePluginsSpace()

    const [setorCreate, setSetorCreate] = useState<string>('')
    const [categoriaCreate, setCategoriaCreate] = useState<string>('')
    const [ordemCreate, setOrdemCreate] = useState<number>(0)
    const [descricaoCreate, setDescricaoCreate] = useState<string>('')

    const [mostraSetor, setMostraSetor] = useState<mostraSetor[]>([])
    const [setor, setSetor] = useState<segmentoAgregador[]>([]);
    const [segmentos, setSegmentos] = useState<any[]>([])

    const [categoryIcons, setCategoryIcons] = useState<any[]>([{
      select: 'Todos',
      value: -1
    }])
    const [icones, setIcones] = useState<Icon[]>([])
    const [iconeSelected, setIconeSelected] = useState<any>()

    const { showWrapperModal, closeWrapperModal } = useModals()

    const editText =async(value: string) => {
      
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = value;
      
      const reetiraTexto = tempDiv.innerText;
      
      setDescricaoCreate(reetiraTexto);
    }
  
    const create = async () => {
      try {
        if (!setorCreate || !categoriaCreate || !iconeSelected) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!',
          })
        } else {
          
          setLoading(true)
          const setorCode = Object.values(setorCreate);
          
          
          let imageRes = {
            success: false,
            image: '',
          }

          imageRes = await sendSvgAWS()

          let res;

          if(imageRes.success) {
              res = await POST('/classification', {
              type: 'aggregator',
              order: ordemCreate,
              name: setorCode[1],
              parent: setorCode[0],
              shortname: categoriaCreate,
              code: uuid().slice(0,40),
              status: true,
              img: imageRes.image,
              description: descricaoCreate,
            })
          }
  
          setLoading(false)
          if (res.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Subcategoria cadastrada',
              text: 'Cadastro realizado com sucesso!',
            }).then(() => navigate('/agregador/subcategory'))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao cadastrar a subcategoria!',
            })
          }
        }
      } catch (e: any) {
        setLoading(false)
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: e ?? 'Erro ao cadastrar subcategoria!',
        })
      }
    }

    const getCategories = async () => {
      setLoading(true)
      try {
        const res = await POST(`/classification/filter`, {
          plugintypeId: getPlugintypeIdByCode('service'),
          deleted: 'not show',
        })
    
        const segmentosPluginSpace = await GET('pluginspacesegment');        
    
        let newSegmento = segmentosPluginSpace.data.filter((segment:newSegmento) => {
            if(segment.pluginspaceId === loginInfo.pluginspaceId) {
                return segment;
            }
        })
    
        newSegmento = newSegmento.map((segment:newSegmento) => {
            return segment.id.toString()
        })
        
        const newCategories = res.data.filter((row: CategoryType)=> row.parent === null).map((row: CategoryType) => {
          return {
            id: row.id,
            name: `${row.name}`,
            status: row.status,
            code: row.code,
            order: row.order,
            category: row.shortname,
            img: row.img,
          }
        })

        const showCategorias = newCategories.map((categoria: any, i:any) => {
          return {
            select: categoria.category,
            value: categoria.code,
          }
        })

        setMostraSetor(showCategorias);

        setLoading(false)

      } catch (e) {
        console.log(e)
        setLoading(false)
      }
    }

    async function getIcones() {
      try {
        setLoading(true)
  
        const svgCategory = await POST('svgcategory/filter', {
          status: true
        });
  
        const auxCategory = [{
          select: 'Todos',
          value: -1
        }]
  
        svgCategory.data.map((el: any) => {
          auxCategory.push({
            select: el.name,
            value: el.id
          }
          )
        })
  
        setCategoryIcons(auxCategory)

  
        const res = await GET('svgwebapp')
  
        if (!res || !res.success) {
          Swal.fire({
            icon: 'error',
            titleText: 'Erro',
            text: 'Erro ao encontrar os tipos'
          })
          setLoading(false)
          return
        }
  
        setIcones(res.data)
        // setIconesFiltered(res.data)
  
        setLoading(false)
  
      } catch (error) {
        console.log(error)
        setLoading(false)
  
        Swal.fire({
          icon: 'error',
          titleText: 'Erro',
          text: 'Erro ao encontrar os tipos'
        })
      }
    }

    async function sendSvgAWS() {
      try {
        let bucket = `img.ninegrid.com`
        const response = await POST('/upload-images/upload-svg', {
          file: iconeSelected.svg,
          path: bucket,
          name: `${pluginSpace!.subdomain}/category/${categoriaCreate}-${uuid()}.svg`
        })
        return response
  
      } catch (error) {
        return {
          error: error,
          success: false,
          data: {}
        }
      }
    }

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        await getCategories();
        await getIcones();
        setLoading(false);
    };
    
    return (
      <>
        {loading ? (
          <Loading />
        ) : (
          <div className='row g-5 gx-xxl-12'>
            <div className='card card-xxl-stretch mb-5 mb-xl-12'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Criar Subcategoria</span>
                  <BreadCrumbs />
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    Preencha as informações abaixo e clique no botão salvar para criar uma nova subcategoria.
                  </span>
                </h3>
              </div>
              <div className='card-body'>
                <div className='row pb-6'>
                  <div className='col d-flex justify-content-between'>
                    <div className='row'>
                      <div className='col-12 col-md-8 pb-6' style={{ zIndex: 500 }}>
                        <div className='d-flex gap-2'>
                          <span>Categoria</span>
                          <div className='d-flex gap-2'>
                            <span className='text-muted'>·</span>
                            <span className='text-muted'>Escolha a categoria relacionada à nova subcategoria</span>
                          </div>
                        </div>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          options={mostraSetor}
                          placeholder={'Categoria'}
                          search={true}
                          value={setorCreate}
                          change={(value: any) => {
                            setSetorCreate(value);                            
                          }}
                        />
                      </div>
                      <div className='col-12'>
                        <div className='d-flex gap-2'>
                            <span>Subcategoria</span>
                            <div className='d-flex gap-2'>
                              <span className='text-muted'>·</span>
                              <span className='text-muted'>Máximo de 15 caracteres</span>
                            </div>
                        </div>
                          <Input
                            inputAttr={{
                              type: 'text',
                            }}
                            placeholder={'Subcategoria'}
                            value={categoriaCreate}
                            change={(value: string) => setCategoriaCreate(value.substring(0, 15))}
                          />
                      </div>
                    </div>
                    <div>
                      <Input
                        inputAttr={{
                          type: 'icon',
                        }}
                        onAction={() => showWrapperModal('Ícones')}
                        label="Ícone"
                        value={iconeSelected}
                        change={(value: any) => {
                          setIconeSelected(value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='row pb-6'>
                  <div className='col-12 col-md-2'>
                    <div>
                        <span>Ordem</span>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder={'0'}
                          value={ordemCreate}
                          change={(value: number) => setOrdemCreate(value)}
                        />
                      </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col'>
                    <div>
                        <span>Descrição</span>
                        <Input
                          rows={4}
                          inputAttr={{
                            type: 'textarea',
                          }}
                          placeholder={'Descrição'}
                          value={descricaoCreate}
                          change={(value: any) => {
                            setDescricaoCreate(value);
                          }}
                        />
                        {/* <EditorQuill
                        value={descricaoCreate}
                        change={(value: string) => editText(value)}
                      /> */}
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
                <Button
                    text='Voltar'
                    color='info'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                        type: 'button',
                    }}
                    click={() => {
                        Swal.fire({
                            title: 'Você tem certeza?',
                            text: 'Os dados que foram digitados serão perdidos.',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Sim, voltar!',
                            cancelButtonText: 'Não, cancelar!',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate(-1)
                            }
                        })
                    }}
                    btnClass='fw-bolder me-3'
                />
                <Button
                    text='Salvar'
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                        type: 'button',
                    }}
                    click={() => create()}
                    btnClass='fw-bolder'
                />
            </div>
          </div>
        )}
        <ModalWrapper confirmAction={() => {
          }}
            maxWidth={true}
            width={40}
          >
            <div className="d-flex  flex-column gap-5 card"
              style={{
                height: '20rem'
              }}>
              <div className="d-flex justify-content-end mt-5">
                <div className="p-4 border border-primary ">
                  <SVG src={iconeSelected?.svg} style={{
                    width: '45px',
                    height: '45px'
                  }} />
                </div>
              </div>
              <div className="card-body d-flex gap-5 flex-row flex-wrap overflow-auto">
                {icones.map(icon => (
                  <div className={`d-flex flex-column p-4`}

                    onClick={() => setIconeSelected(icon)}>
                    <span>{icon.description}</span>
                    <SVG src={icon.svg} style={{
                      width: '45px',
                      height: '45px'
                    }} />
                  </div>
                ))

                }

              </div>


            </div>

          </ModalWrapper>
      </>
    )
  }
  
  const FeedCriarSubCategoriasAgregador: FC = () => {
    return (
      <>
        <PageTitle
          breadcrumbs={[
            {title: 'Dashboard', path: 'dashboard', isActive: true},
            {title: `Criar Subcategoria`, path: '/agregador/subcategory/create', isActive: true},
          ]}
        >
        </PageTitle>
        <Content />
      </>
    )
  }
  
  export {FeedCriarSubCategoriasAgregador}