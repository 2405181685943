import { FC, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import Swal from 'sweetalert2'
import { POST } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { Editor } from '@tinymce/tinymce-react'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const CriarTermosContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [expired, setExpired] = useState('')
  const [consent, setConsent] = useState('')

  const createConsent = async () => {
    try {
      if (!name || !expired || !consent) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const consentRes = await POST('/consent', {
          name: name,
          content: consent,
          expirated: expired,
          active: true,
        })
        setLoading(false)
        if (consentRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Termo cadastrado',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/configuracoes/termos'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar o termo!',
          })
        }
      }
      return
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o termo!',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`terms.terms`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>{i18n.t(`terms.create_your_term`)}</span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='card-body border-bottom'>
                <div className='d-flex justify-content-between align-items-center flex-wrap gap-5'>
                  <div className='flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'string',
                      }}
                      placeholder={i18n.t(`terms.term_name`)}
                      label={i18n.t(`terms.term_name`)}
                      change={(value: string) => setName(value)}
                      value={name}
                    />
                  </div>
                  <div className='flex-grow-1 '>
                    <Input
                      inputAttr={{
                        type: 'date',
                      }}
                      placeholder='Validade'
                      label={i18n.t(`terms.valid_until`)}
                      value={expired}
                      change={(value: string) => {
                        setExpired(value)
                        console.log(value)
                      }}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-5 flex-grow-1'>
                    <div className="mt-6">
                      <label className='form-label fw-normal'>{i18n.t(`terms.terms`).substring(0, i18n.t(`terms.terms`).length - 1)}</label>
                      <Input
                        rows={4}
                        inputAttr={{
                          type: 'textarea',
                        }}
                        placeholder={'Descrição'}
                        value={consent || ''}
                        change={(value: any) => {
                          setConsent(value)
                        }}
                      />
                      {/* <Editor
                        apiKey={process.env.REACT_APP_TINY_KEY}
                        onEditorChange={(value: string) => setConsent(value)}
                        value={consent}
                        initialValue=""
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                          ],
                          toolbar: 'undo redo | formatselect | ' +
                            'bold italic backcolor | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | ' +
                            'removeformat | help',
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex w-100">
                <div className="col-6 d-flex justify-content-start">
                  <Button
                    text={i18n.t(`buttons.back`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate(-1)}
                    btnClass='fw-bolder m-9'
                  />
                </div>
                <div className="col-6  d-flex justify-content-end">
                  <Button
                    text={i18n.t(`buttons.create`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => createConsent()}
                    btnClass='fw-bolder m-9'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CriarTermos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`terms.terms`), path: 'configuracoes/termos', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'configuracoes/termos/criar', isActive: true },
        ]}
      >
        {i18n.t(`terms.terms`)}
      </PageTitle>
      <CriarTermosContent />
    </>
  )
}

export { CriarTermos }
