import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FC, useState } from 'react'
import InputMask from 'react-input-mask'
import styles from './Input.module.css'
import IntlCurrencyInput from 'react-intl-currency-input'
import Select from 'react-select'
import { MyTooltip } from '../ToolTip/ToolTip';
import { removeEspacosEmBranco } from '../../utils/formater';
import SVG from 'react-inlinesvg';

type Props = {
  icon?: JSX.Element;
  label?: string
  getFieldProps?: any
  inputAttr?: any
  checked?: boolean
  disabled?: boolean
  checkSwift?: boolean
  options?: any
  value?: any
  change?: any
  placeholder?: string
  maxLength?: string
  minLength?: string
  minNumber?: number
  maxNumber?: number
  rows?: number
  maxCaracteres?: number
  currency?: string
  locale?: string
  isSecure?: boolean
  autoComplete?: string
  name?: string
  defaultValue?: string
  focus?: boolean
  smalltext?: string
  acceptFile?: string
  blur?: (value: string) => void
  onKeyDown?: (value: string) => void
  onAction?: () => void
  search?: boolean
  textCenter?: boolean
  size?: string
  id?: string,
  toolTip?: boolean,
  toolTiptext?: string
  toolTipIcon?: string
  toolTipLink?: string
  imagemBackgroundDefault?: string
  height?: string
  width?: string
  vertical?: boolean
  backgroundColor?: string
  idInput?: string
  alarm?: boolean
  alarmText?: string
  action?: () => void
}

const Input: FC<Props> = (props) => {
  const {
    icon,
    disabled,
    label,
    getFieldProps,
    inputAttr,
    checked,
    options,
    value,
    change,
    placeholder,
    minLength,
    maxLength,
    checkSwift,
    minNumber,
    maxNumber,
    rows,
    maxCaracteres,
    currency,
    locale,
    isSecure = true,
    autoComplete,
    name,
    defaultValue,
    focus,
    smalltext,
    acceptFile,
    blur,
    onKeyDown,
    search,
    textCenter,
    size,
    id,
    toolTip,
    toolTipIcon,
    toolTiptext,
    toolTipLink,
    imagemBackgroundDefault,
    height,
    width,
    vertical,
    onAction,
    backgroundColor,
    idInput,
    alarm,
    alarmText,
    action,
  } = props

  const [hiddenPassword, setHiddenPassword] = useState<boolean>(isSecure)

  switch (inputAttr.type) {
    case 'radio':
      return (
        <div>
          <label className='d-flex align-items-center'>
            <input {...getFieldProps} {...inputAttr} checked={checked} onChange={(event) => change(event.target.checked)} />
            {icon}
            <span className='btn btn-sm btn-color-muted btn-active btn-active-primary ps-2 pe-4 py-0'>
              {label}
            </span>
          </label>
        </div>
      )
    case 'newRadio':
      return (
        <div>
          <div className='mt-4'>
            <label
              className={`form-check d-flex align-items-center`}
            >
              <input
                {...getFieldProps}
                {...inputAttr}
                type='radio'
                className={`${styles.form_check_input}`}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={(event) => change(event.target.checked)}
              />
              <span className='px-2 py-0 form-check-label'>
                {label}
              </span>
            </label>
          </div>
        </div>
      )
    case 'checkbox':
      return (
        <div className='mt-4'>
          <label
            className={`form-check ${checkSwift && 'form-switch form-check-custom form-check-solid'
              } ${size && `switch-${size}`}
              ${(vertical != undefined && vertical) ? 'd-flex  align-items-start flex-column-reverse gap-3' : ''}
              `}
          >
            <input
              {...getFieldProps}
              {...inputAttr}
              className={`form-check-input ${(vertical != undefined && vertical) ? 'ms-2' : ''}`}
              value={value}
              defaultChecked={checked}
              disabled={disabled}
              onChange={(event) => change(event.target.checked)}
            />
            <span
              className={`form-check-label ${checkSwift ? `fw-normal text-gray-800  ${(vertical != undefined && vertical) ? '' : 'ms-5'}` : 'fw-normal text-gray-800 ms-1'
                } ${toolTip ? 'd-flex' : ''}`}
            >
              {label}
              {toolTip && (
                <MyTooltip text={toolTiptext!}
                  icon={toolTipIcon}
                  link={toolTipLink}
                />
              )

              }

            </span>
          </label>
        </div>
      )
    case 'checkbox':
      return (
        <div className='mt-4'>
          <label
            className={`form-check ${checkSwift && 'form-switch form-check-custom form-check-solid'
              } ${size && `switch-${size}`}
              ${(vertical != undefined && vertical) ? 'd-flex  align-items-start flex-column-reverse gap-3' : ''}
              `}
          >
            <input
              {...getFieldProps}
              {...inputAttr}
              className={`form-check-input ${(vertical != undefined && vertical) ? 'ms-2' : ''}`}
              value={value}
              defaultChecked={checked}
              disabled={disabled}
              onChange={(event) => change(event.target.checked)}
            />
            <span
              className={`form-check-label ${checkSwift ? `fw-normal text-gray-800  ${(vertical != undefined && vertical) ? '' : 'ms-5'}` : 'fw-normal text-gray-800 ms-1'
                } ${toolTip ? 'd-flex' : ''}`}
            >
              {label}
              {toolTip && (
                <MyTooltip text={toolTiptext!}
                  icon={toolTipIcon}
                  link={toolTipLink}
                />
              )

              }

            </span>
          </label>
        </div>
      )
    case 'select':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div>
            {search ? (
              <Select
                styles={{
                  control: (provided: any, state: any) => ({
                    ...provided,
                    padding: 2,
                    border: '1px solid #e4e6ef',
                  }),
                }}
                value={value}
                onChange={(event) => change(event)}
                placeholder={placeholder ? placeholder : 'Selecione uma opção'}
                options={options.map((option: any) => ({
                  value: option.value,
                  label: option.select,
                }))}
                isDisabled={disabled ? disabled : false}
              />
            ) : (
              <select
                className='form-select fw-normal ps-4 pe-12 border border-hover-primary'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={value}
                defaultValue={defaultValue}
                disabled={disabled ? disabled : false}
                onChange={(event) => change(event.target.value)}
              >
                {options.map((option: any) => (
                  <option key={option.key ? option.key : option.value} value={option.value}>
                    {option.select}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      )

    case 'select_small':
      return (
        <div className={`${vertical? 'd-flex gap-2 align-items-center mt-2' : ''}`}>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div>
            {search ? (
              <Select
                styles={{
                  control: (provided: any, state: any) => ({
                    ...provided,
                    padding: 2,
                    border: '1px solid #e4e6ef',
                  }),
                }}
                value={value}
                onChange={(event) => change(event)}
                placeholder={placeholder ? placeholder : 'Selecione uma opção'}
                options={options.map((option: any) => ({
                  value: option.value,
                  label: option.select,
                }))}
                isDisabled={disabled ? disabled : false}
              />
            ) : (
              <select
                className='form-select fw-normal ps-4 pe-12 border border-hover-primary'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={value}
                defaultValue={defaultValue}
                disabled={disabled ? disabled : false}
                onChange={(event) => change(event.target.value)}
              >
                {options.map((option: any) => (
                  <option key={option.key ? option.key : option.value} value={option.value}>
                    {option.select}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      )
    case 'select-publicidade':
      return (
        <div>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div>
            {search ? (
              <Select
                styles={{
                  control: (provided: any, state: any) => ({
                    ...provided,
                    padding: 2,
                    border: '1px solid #e4e6ef',
                  }),
                }}
                value={value}
                onChange={(event) => change(event)}
                placeholder={placeholder ? placeholder : 'Selecione uma opção'}
                options={options.map((option: any) => ({
                  value: option.value,
                  label: option.select,
                }))}
                isDisabled={disabled ? disabled : false}
              />
            ) : (
              <select
                className='form-select fw-normal ps-4 pe-12 border border-hover-primary'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                value={value}
                defaultValue={defaultValue}
                disabled={disabled ? disabled : false}
                onChange={(event) => change(event.target.value)}
              >
                {options.map((option: any) => (
                  <option key={option.key ? option.key : option.value} value={option.value}>
                    {option.select}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>
      )
    case 'select-agregador':
      return (
        <div className='mt-6 d-flex gap-2 align-items-center'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div>
            <select
              className={`form-select ${styles.form_select_little}`}

              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={value}
              defaultValue={defaultValue}
              disabled={disabled ? disabled : false}
              onChange={(event) => change(event.target.value)}
            >
              {options.map((option: any) => (
                <option key={option.key ? option.key : option.value} value={option.value}>
                  {option.select}
                </option>
              ))}
            </select>
          </div>
        </div>
      )
    case 'select-multiple':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div>
            <select
              className='form-select fw-normal ps-4 pe-12 border border-hover-primary'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              value={value}
              onChange={(event) => change(event.target.value)}
              multiple
            >
              {options.map((option: any) => (
                <option key={option.value} value={option.value}>
                  {option.select}
                </option>
              ))}
            </select>
          </div>
        </div>
      )
    case 'search':
      return (
        <div className='d-flex align-items-center border border-hover-primary px-3 rounded mt-6'>
          <i className='fas fa-search'></i>
          <input
            className='border-0 w-100 form-control'
            type='text'
            placeholder={placeholder}
            value={value}
            onBlur={(event: any) => blur && blur(event.target.value)}
            onChange={(event) => change(event.target.value)}
            onKeyDown={(event) => onKeyDown && onKeyDown(event.key)}
          />
        </div>
      )
    case 'date':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary px-3 rounded'>
            <input
              className='border-0 w-100 form-control fw-normal'
              type='date'
              placeholder={placeholder}
              value={value}
              onChange={(event) => change(event.target.value)}
              min={minLength}
              max={maxLength}
            />
          </div>
        </div>
      )
    case 'email':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <input
              className='border-0 w-100 form-control fw-normal'
              type='email'
              placeholder={placeholder}
              value={value}
              onChange={(event) => change(removeEspacosEmBranco(event.target.value))}
            />
          </div>
        </div>
      )
    case 'number':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <input
              className={
                textCenter
                  ? 'border-0 w-100 form-control fw-normal text-center'
                  : 'border-0 w-100 form-control fw-normal'
              }
              type='number'
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onBlur={(event: any) => blur && blur(event.target.value)}
              onChange={(event) => {
                if (minNumber && !maxNumber && minNumber <= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (maxNumber && !minNumber && maxNumber >= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (maxNumber && minNumber && maxNumber >= parseInt(event.target.value) && minNumber <= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (!maxNumber && !maxNumber) {
                  change(event.target.value)
                }
                else if (!event.target.value) {
                  change(event.target.value)
                }
              }}
              max={maxNumber}
              min={minNumber}
            />
            {toolTip && (
              <MyTooltip text={toolTiptext!}
                icon={toolTipIcon}
                link={toolTipLink}
              />
            )

            }
          </div>
        </div>
      )
    case 'number-agregador':
      return (
        <div className={`${vertical ? 'd-flex align-items-center gap-4' : ''}`}>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <input
              className={
                textCenter
                  ? 'border-0  form-control fw-normal text-center'
                  : 'border-0  form-control fw-normal'
              }
              style={{
                height: height ? `${height}px` : '',
                width: width ? `${width}px` : ''
              }}
              type='number'
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onBlur={(event: any) => blur && blur(event.target.value)}
              onChange={(event) => {
                if (minNumber && !maxNumber && minNumber <= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (maxNumber && !minNumber && maxNumber >= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (maxNumber && minNumber && maxNumber >= parseInt(event.target.value) && minNumber <= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (!maxNumber && !maxNumber) {
                  change(event.target.value)
                }
                else if (!event.target.value) {
                  change(event.target.value)
                }
              }}
              max={maxNumber}
              min={minNumber}
            />
            {toolTip && (
              <MyTooltip text={toolTiptext!}
                icon={toolTipIcon}
                link={toolTipLink}
              />
            )

            }
          </div>
        </div>
      )
    case 'smallNumber':
      return (
        <div className={`${height ? `h-${height}px` : ''} ${width ? `w-${width}px` : ''}`}>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <input
              className={
                textCenter
                  ? 'border-0 w-100 fw-normal text-center form-control'
                  : 'border-0 w-100  fw-normal form-control'
              }
              style={{
                height: height ? `${height}px` : '',
                width: width ? `${width}px` : ''
              }}
              type='number'
              disabled={disabled}
              placeholder={placeholder}
              value={value}
              onBlur={(event: any) => blur && blur(event.target.value)}
              onChange={(event) => {
                if (minNumber && !maxNumber && minNumber <= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (maxNumber && !minNumber && maxNumber >= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (maxNumber && minNumber && maxNumber >= parseInt(event.target.value) && minNumber <= parseInt(event.target.value)) {
                  change(event.target.value)
                }
                else if (!maxNumber && !maxNumber) {
                  change(event.target.value)
                }
                else if (!event.target.value) {
                  change(event.target.value)
                }
              }}
              max={maxNumber}
              min={minNumber}
            />
            {toolTip && (
              <MyTooltip text={toolTiptext!}
                icon={toolTipIcon}
                link={toolTipLink}
              />
            )

            }
          </div>
        </div>
      )
    case 'tel':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex flex-grow-1 align-items-center border border-hover-primary rounded'>
            <InputMask
              className='border-0 w-100 form-control fw-normal'
              mask='(99) 99999-9999'
              placeholder={placeholder}
              value={value}
              onChange={(event: any) => change(event.target.value)}
            />
          </div>
        </div>
      )
    case 'money':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex flex-grow-1 align-items-center border border-hover-primary rounded'>
            <IntlCurrencyInput
              className='border-0 w-100 form-control fw-normal'
              currency={currency}
              config={{
                locale: locale,
                formats: {
                  number: {
                    BRL: {
                      style: 'currency',
                      currency: currency,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              onChange={(event: any, value: number, maskedValue: string) => change(value)}
              value={value}
              onBlur={(event: any) => blur && blur(event.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      )
    case 'time':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className={`d-flex align-items-center border border-hover-primary rounded ${size ? (size == 'sm' ? 'w-50' : 'w-75') : ''} ${size ? (size == 'sm' ? 'px-1' : '') : 'px-3'}`}>
            <InputMask
              className={`border-0 ${size ? (size == 'sm' ? 'w-75' : 'w-100') : 'w-100'} form-control fw-normal text-center`}
              mask='99:99:99'
              placeholder={placeholder}
              value={value}
              onChange={(event: any) => change(event.target.value)}
              onBlur={(event: any) => blur && blur(event.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      )
    case 'hour':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className={`d-flex align-items-center border border-hover-primary rounded ${size ? (size == 'sm' ? 'w-50' : 'w-75') : ''} ${size ? (size == 'sm' ? 'px-1' : '') : 'px-3'}`}>
            <InputMask
              className={`border-0 ${size ? (size == 'sm' ? 'w-75' : 'w-100') : 'w-100'} form-control fw-normal text-center`}
              mask='99:99'
              placeholder={placeholder}
              value={value}
              onChange={(event: any) => change(event.target.value)}
              onBlur={(event: any) => blur && blur(event.target.value)}
              disabled={disabled}
            />
            {toolTip && (
              <MyTooltip text={toolTiptext!}
                icon={toolTipIcon}
                link={toolTipLink}
              />
            )

            }
          </div>
        </div>
      )
    case 'cnpj':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <InputMask
              className='border-0 w-100 form-control fw-normal'
              mask='99.999.999/9999-99'
              placeholder={placeholder}
              value={value}
              onChange={(event: any) => change(event.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      )
    case 'cep':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <InputMask
              className='border-0 w-100 form-control fw-normal'
              mask='99999-999'
              placeholder={placeholder}
              value={value}
              onChange={(event: any) => change(event.target.value)}
              onBlur={(event: any) => blur && blur(event.target.value)}
            />
          </div>
        </div>
      )
    case 'cpf':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <InputMask
              className='border-0 w-100 form-control fw-normal'
              mask='999.999.999-99'
              placeholder={placeholder}
              value={value}
              onChange={(event: any) => change(event.target.value)}
              onBlur={(event: any) => blur && blur(event.target.value)}
            />
          </div>
        </div>
      )
    case 'color':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex min-w-150px align-items-center border border-hover-primary rounded'>
            <input
              className='border-0 w-100 form-control fw-normal'
              type='color'
              value={value}
              onChange={(event) => change(event.target.value)}
            />
          </div>
        </div>
      )

    case 'smallColor':
      return (
        <div className='mt-1'>
          {label && <label className='form-label' style={{ fontSize: '11px', lineHeight: "9px" }}>{label}</label>}
          <div className='d-flex min-w-150px align-items-center border border-hover-primary rounded'>
            <input
              className='border-0 w-100 form-control fw-normal'
              type='color'
              value={value}
              onChange={(event) => change(event.target.value)}
            />
          </div>
        </div>
      )
    case 'textarea':
      return (
        <div className='mt-6'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex min-w-150px align-items-center border border-hover-primary rounded'>
            <textarea
              className='border-0 w-100 form-control fw-normal'
              style={{
                background: backgroundColor,
              }}
              value={value}
              placeholder={placeholder}
              maxLength={maxCaracteres}
              rows={rows}
              disabled={disabled}
              onChange={(event) => change(event.target.value)}
              id={`${idInput ? idInput : label}`}
            ></textarea>
          </div>
        </div>
      )
    case 'password':
      return (
        <div className='mt-6 flex-grow-1'>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div className='d-flex align-items-center border border-hover-primary rounded'>
            <input
              className='border-0 w-100 form-control fw-normal'
              type={hiddenPassword ? 'password' : 'default'}
              placeholder={placeholder}
              value={value}
              onChange={(event) => change(removeEspacosEmBranco(event.target.value))}
              disabled={disabled}
              autoComplete={autoComplete}
              onKeyDown={(event) => onKeyDown && onKeyDown(event.key)}
            />
            {hiddenPassword ? (
              <i
                style={{ color: '#ccc', fontSize: 20, paddingRight: 12 }}
                className='bi bi-eye-fill cursor-pointer text-hover-primary'
                onClick={() => setHiddenPassword(!hiddenPassword)}
              ></i>
            ) : (
              <i
                style={{ color: '#ccc', fontSize: 20, paddingRight: 12 }}
                className='bi bi-eye-slash-fill cursor-pointer text-hover-primary'
                onClick={() => setHiddenPassword(!hiddenPassword)}
              ></i>
            )}
          </div>
        </div>
      )
    case 'file':
      return (
        <div>
          {id ?
            <input
              id={id}
              type='file'
              name='file'
              accept={acceptFile ? acceptFile : ''}
              onChange={(event) => change(event)}
              value={value}
            />
            :
            <input
              type='file'
              name='file'
              accept={acceptFile ? acceptFile : ''}
              onChange={(event) => change(event)}
              value={value}
            />
          }
        </div>
      )
    case 'image':
      return (
        <div>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div
            className={`${styles.image_container} mt-1 ${width ? `w-${width}px` : 'w-150px'} ${value ? '' : styles.bgImgContainer
              }`}
          >
            <div className={`${width ? `w-${width}px` : 'w-150px'} ${height ? `h-${height}px` : 'h-150px'} rounded`}>
              <img
                className='w-100 h-100 rounded'
                src={
                  value
                    ? typeof value === 'string'
                      ? value
                      : URL.createObjectURL(value)
                    : (imagemBackgroundDefault ? `https://via.placeholder.com/${imagemBackgroundDefault}` : `https://via.placeholder.com/640x640`)
                }
                alt='Logo pluginspace'
              />
            </div>
            <label
              className={`${styles.icon_edit} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
              data-action='change'
              data-toggle='tooltip'
              title='Trocar'
            >
              <i className='fa fa-pen icon-sm text-muted'></i>
              <input
                type='file'
                accept='.jpg, .jpeg, .png, .gif'
                name='file'
                disabled={disabled}
                className={`${styles.input_edit} custom-file-input opacity-0 w-0 h-0`}
                onChange={(event) => change(event)}
              />
            </label>
            <span
              className={`${styles.icon_remove} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
              data-action='remove'
              data-toggle='tooltip'
              title='Remover'

              onClick={() => change(null)}
            >
              <i className='bi bi-x text-muted fs-1'></i>
            </span>
          </div>
        </div>
      )

    case 'image_modal':
      return (
        <div>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div
            className={`${styles.image_container} mt-1 ${width ? `w-${width}px` : 'w-150px'} ${value ? '' : styles.bgImgContainer
              }`}
          >
            <div className={`${width ? `w-${width}px` : 'w-150px'} ${height ? `h-${height}px` : 'h-150px'} rounded`}>
              <img
                className='w-100 h-100 rounded'
                src={
                  value
                    ? typeof value === 'string'
                      ? value
                      : URL.createObjectURL(value)
                    : (imagemBackgroundDefault ? `https://via.placeholder.com/${imagemBackgroundDefault}` : 'https://via.placeholder.com/640x640')
                }
                alt='Logo pluginspace'
              />
            </div>
            <label
              className={`${styles.icon_edit} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
              data-action='change'
              data-toggle='tooltip'
              title='Trocar'
              onClick={() => action && action()}
            >
              <i className='fa fa-pen icon-sm text-muted'></i>

            </label>
            <span
              className={`${styles.icon_remove} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
              data-action='remove'
              data-toggle='tooltip'
              title='Remover'

              onClick={() => change(null)}
            >
              <i className='bi bi-x text-muted fs-1'></i>
            </span>
          </div>
        </div>
      )
    case 'image-agregador':
      return (
        <div>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div
            className={`${styles.image_container} mt-1 ${width ? `w-${width}px` : 'w-150px'} ${value ? '' : styles.bgImgContainer
              }`}
          >
            <div className={`${width ? `w-${width}px` : 'w-150px'} ${height ? `h-${height}px` : 'h-150px'} rounded`}>
              <img
                className='w-100 h-100 rounded'
                src={
                  value
                    ? typeof value === 'string'
                      ? value
                      : URL.createObjectURL(value)
                    : (imagemBackgroundDefault ? `https://via.placeholder.com/${imagemBackgroundDefault}` : 'https://via.placeholder.com/640x640')
                }
                alt='Logo pluginspace'
              />
            </div>
            {/* <label
            className={`${styles.icon_edit} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
            data-action='change'
            data-toggle='tooltip'
            title='Trocar'
          >
            <i className='fa fa-pen icon-sm text-muted'></i>
            <input
              type='file'
              accept='.jpg, .jpeg, .png, .gif'
              name='file'
              disabled={disabled}
              className={`${styles.input_edit} custom-file-input opacity-0 w-0 h-0`}
              onChange={(event) => change(event)}
            />
          </label> */}
          </div>
        </div>
      )
    case 'icon':
      return (
        <div>
          {label && <label className='form-label fw-normal'>{label}</label>}
          <div
            className={`${styles.image_container} mt-1 w-150px ${value ? '' : styles.bgImgContainer
              }`}
          >
            <div className={`${width ? `w-${width}px` : 'w-150px'} ${height ? `h-${height}px` : 'h-150px'} rounded`}>
              {(value && value.svg) ?
                <SVG src={value?.svg} style={{
                  width: '140px',
                  height: '140px'
                }} /> : <img
                  className='w-100 h-100 rounded'
                  src={
                    value
                      ? typeof value === 'string'
                        ? value
                        : URL.createObjectURL(value)
                      : (imagemBackgroundDefault ? `https://via.placeholder.com/${imagemBackgroundDefault}` : 'https://via.placeholder.com/640x640')
                  }
                  alt='Logo pluginspace'
                />

              }

            </div>
            <label
              className={`${styles.icon_edit} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
              data-action='change'
              data-toggle='tooltip'
              title='Trocar'
            >
              <i className='fa fa-pen icon-sm text-muted' onClick={() => { onAction && onAction() }} ></i>
            </label>
            <span
              className={`${styles.icon_remove} btn btn-xs btn-icon rounded-circle btn-white btn-hover-text-primary shadow`}
              data-action='remove'
              data-toggle='tooltip'
              title='Remover'
              onClick={() => change(null)}
            >
              <i className='bi bi-x text-muted fs-1'></i>
            </span>
          </div>
        </div>
      )
    case 'link':
      return (
        <div className='d-flex gap-2 mt-2'>
          {label && <span style={{
            color: '#888C9F',
            opacity: '50%'
          }}>{label}</span>}
          <div style={{ position: 'relative', display: 'inline-block', gap: '4px' }}>

            <i className="bi bi-link-45deg"
              style={{
                position: 'absolute',
                left: '3px',
                top: '50%',
                transform: 'translateY(-50%)',
                pointerEvents: 'none'
              }}></i>
            <input
              style={{
                borderRadius: '6px',
                borderWidth: '1px',
                height: '20px',
                borderColor: '#D8D8D8',
                borderStyle: 'solid',
                paddingLeft: '18px',
                outline: 'none',
              }}
              type="text"
              value={value}
              onChange={(event) => change(event.target.value)}
              onBlur={(event: any) => blur && blur(event.target.value)}
            />
          </div>
        </div>
      )

    case 'link-agregador':
      return (
        <div className='d-flex gap-2 mt-4 align-itens-center'>
          {label && <span style={{
            color: '#888C9F',
            opacity: '50%'
          }}>{label}</span>}
          <div style={{ position: 'relative', display: 'inline-block', gap: '4px' }}>

            <i className="bi bi-link-45deg"
              style={{
                position: 'absolute',
                left: '3px',
                top: '50%',
                transform: 'translateY(-50%)',
                pointerEvents: 'none'
              }}></i>
            <input
              style={{
                borderRadius: '6px',
                borderWidth: '1px',
                height: '30px',
                width: '400px',
                borderColor: '#D8D8D8',
                borderStyle: 'solid',
                paddingLeft: '18px',
                outline: 'none',
              }}
              type="text"
              value={value}
              onChange={(event) => change(event.target.value)}
              onBlur={(event: any) => blur && blur(event.target.value)}
            />
          </div>
        </div>
      )
    default:
      return (
        <div className='mt-6 flex-grow-1'>
          {label && (
            <label className='form-label fw-normal'>
              {label} <small>{smalltext}</small>
            </label>
          )}
          <div
            className='d-flex align-items-center border-hover-primary rounded'
            style={alarm ? { border: '1px solid #d90429' } : { border: '1px solid #eff2f5' }}
          >
            <input
              className={
                textCenter
                  ? 'border-0 w-100 form-control fw-normal text-center'
                  : 'border-0 w-100 form-control fw-normal'
              }
              type='text'
              placeholder={placeholder}
              value={value}
              maxLength={maxCaracteres}
              onChange={(event) => change(event.target.value)}
              disabled={disabled}
              name={name}
              defaultValue={defaultValue}
              autoFocus={focus}
              onBlur={(event: any) => blur && blur(event.target.value)}
            />
          </div>
          {alarm && (
            <p className='mt-1 text-danger'>{alarmText ? alarmText : 'Campo faltante'}</p>
          )}
        </div>
      )
  }
}

export { Input }
