import { FC, useEffect, useState } from "react"

import { Link, useLocation } from "react-router-dom";
import { i18n } from "../../translate/i18n";
import usePluginsSpace from "../../hooks/usePluginSpace";

type router = {
  title: string
  path: string
}

type BreadCrumbs = {
  router: router[]
}

const rotas = [
  { name: 'auth_redirect', path: '/auth/*' },
  { name: 'Contas', path: '/pluginspace' },
  { name: 'Criar Conta', path: '/pluginspace/criar' },
  { name: 'Editar Conta', path: '/pluginspace/editar/:id' },
  { name: 'dashboard', path: '/dashboard' },
  { name: 'Copiar Configuração', path: '/pluginspace/copy-configurations/:id' },
  { name: i18n.t(`modalities.modalities`), path: '/configuracoes/modalidades' },
  { name: i18n.t(`delivery_fee.delivery_fee`), path: '/configuracoes/taxa-entrega' },
  { name: i18n.t(`delivery_fee.create_delivery_fee`), path: '/configuracoes/taxa-entrega/criar' },
  { name: i18n.t(`delivery_fee.edit_delivery_fee`), path: '/configuracoes/taxa-entrega/editar/:id' },
  { name: i18n.t(`delivery_fee.cep_delivery`), path: '/configuracoes/taxa-entrega/cep' },
  { name: i18n.t(`delivery_fee.create_cep`), path: '/configuracoes/taxa-entrega/cep/criar' },
  { name: i18n.t(`delivery_fee.edit_cep`), path: '/configuracoes/taxa-entrega/cep/editar/:id' },
  { name: i18n.t(`delivery_fee.cep_delivery_negative`), path: '/configuracoes/taxa-entrega/cep/negativo' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`delivery_fee.cep_delivery_negative`)}`, path: '/configuracoes/taxa-entrega/cep/negativo/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`delivery_fee.cep_delivery_negative`)} `, path: '/configuracoes/taxa-entrega/cep/negativo/editar/:id' },
  { name: 'Áreas de Frete', path: '/configuracoes/taxa-entrega/map' },
  { name: `${i18n.t(`user.user`)}`, path: '/configuracoes/usuarios' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`user.user`)}`, path: '/configuracoes/usuarios/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`user.user`)}`, path: '/configuracoes/usuarios/editar/:id' },
  { name: i18n.t(`groups.groups`), path: '/configuracoes/grupos' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`groups.groups`)}`, path: '/configuracoes/grupos/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`groups.groups`)}`, path: '/configuracoes/grupos/editar/:id' },
  { name: i18n.t(`payment_plans.payment_plans`), path: '/configuracoes/planos-pagamento' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`payment_plans.payment_plans`)}`, path: '/configuracoes/planos-pagamento/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`payment_plans.payment_plans`)}`, path: '/configuracoes/planos-pagamento/editar/:id' },
  { name: i18n.t(`payment.methods`), path: '/configuracoes/pagamentos' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`payment.methods`)}`, path: '/configuracoes/pagamentos/criar/:id' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`payment.methods`)}`, path: '/configuracoes/pagamentos/edit/:id' },
  // { name: `${i18n.t(`general.edit`)} ${i18n.t(`payment.methods`)}`, path: '/configuracoes/pagamentos/:id' },
  { name: i18n.t(`shifts.shifts`), path: '/configuracoes/turnos' },
  { name: i18n.t(`account.account`), path: '/configuracoes/conta' },
  { name: i18n.t(`terms.terms`), path: '/configuracoes/termos' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`terms.terms`)}`, path: '/configuracoes/termos/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`terms.terms`)}`, path: '/configuracoes/termos/editar/:id' },
  { name: i18n.t(`checkout.title`), path: '/configuracoes/checkout' },
  { name: i18n.t(`customer_list.customer_list`), path: '/customer/report' },
  { name: i18n.t(`customer_list.detail`), path: '/customer/report/:id' },
  { name: i18n.t(`indicators.orders`), path: '/chartorders' },
  { name: i18n.t(`indicators.product`), path: '/chartproducts' },
  { name: i18n.t(`indicators.summaryAccess`), path: '/summary_access' },
  { name: 'Listagem de Pedidos', path: '/smart-order/ordem-pedido' },
  { name: 'Kitchen Display System', path: '/smart-order/kds' },

  { name: i18n.t(`smart_order.product`), path: '/product_fashion' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.product`)}`, path: '/product_fashion/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.product`)}`, path: '/product_fashion/editar/:id' },
  { name: i18n.t(`smart_order.product`), path: '/product_varejo' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.product`)}`, path: '/product_varejo/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.product`)}`, path: '/product_varejo/editar/:id' },

  { name: 'Opcionais', path: '/smart-order/opcionais' },
  { name: 'Criar Opcionais', path: '/smart-order/opcionais/:type/criar' },
  { name: 'Editar Opcionais', path: '/smart-order/opcionais/:type/editar' },
  { name: i18n.t(`smart_order.categories`), path: '/category' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.categories`)}`, path: '/category/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.categories`)}`, path: '/category/editar/:id' },
  { name: i18n.t(`smart_order.marks`), path: '/mark' },
  { name: i18n.t(`smart_order.variations`), path: '/variation' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.variations`)}`, path: '/variation/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.variations`)}`, path: '/variation/editar/:variationId' },
  { name: i18n.t(`smart_order.grid_products`), path: '/grid' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.grid_products`)}`, path: '/grid/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.grid_products`)}`, path: '/grid/editar/:id' },
  { name: i18n.t(`smart_order.measurements_units`), path: '/measurement_unit' },
  { name: i18n.t(`smart_order.catalogs`), path: '/catalog' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.catalogs`)}`, path: '/catalog/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.catalogs`)}`, path: '/catalog/editar/:id' },

  { name: 'Webapp', path: '/webapp' },
  { name: `${i18n.t(`general.edit`)} Webapp`, path: '/webapp/editar/:id' },
  { name: i18n.t(`webapp.settings_webapp`), path: '/webapp/configuracoes/:id' },
  { name: i18n.t(`webapp.settings_webapp_aggregator`), path: '/webapp/personalize' },
  { name: i18n.t(`webapp.generate_qrcode_webapp_aggregator`), path: '/webapp/qrcode' },

  { name: i18n.t(`integration.means_of_payment`), path: '/pluginconfig/gateway' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`webapp.settings_webapp`)}`, path: '/pluginconfig/gateway/create' },
  { name: `${i18n.t(`general.edit`)}  ${i18n.t(`webapp.settings_webapp`)}`, path: '/pluginconfig/gateway/edit/:id' },
  //{ name: 'pluginconfig_pdv', path: '/pluginconfig/pdv' },
  { name: i18n.t(`integration.PDV`), path: '/pluginconfig/pdv' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`integration.PDV`)}`, path: '/pluginconfig/pdv/create' },
  { name: `${i18n.t(`general.edit`)}  ${i18n.t(`integration.PDV`)}`, path: '/pluginconfig/pdv/edit/:id' },

  { name: `${i18n.t(`integration.messenger`)}`, path: '/pluginconfig/messenger' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`integration.messenger`)}`, path: '/pluginconfig/messenger/create' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`integration.messenger`)}`, path: '/pluginconfig/messenger/edit/:id' },

  { name: `${i18n.t(`integration.others`)}`, path: '/pluginconfig/others' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`integration.others`)}`, path: '/pluginconfig/others/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`integration.others`)}`, path: '/pluginconfig/others/edit/:id' },
  { name: i18n.t(`integration.loads`), path: '/pluginconfig/charge_integration' },

  //{ name: 'order', path: '/order' },
  { name: i18n.t(`reports.order_report`), path: '/order/saleorder' },
  { name: 'Logs', path: '/order/logs' },
  { name: i18n.t(`reports.order_report`), path: '/order/saleorder/:id' },
  { name: 'Relatório de Transações', path: '/order/transacoes' },
  { name: 'Relatório de Tempo por Produto', path: '/order/tempo-producao' },

  { name: i18n.t(`smart_order.product`), path: '/report/product_report' },
  { name: i18n.t(`sms.message`), path: '/message' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`sms.message`)}`, path: '/message/edit/:id' },
  { name: i18n.t(`multichannel.multichannel`), path: '/multichannel' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`multichannel.multichannel`)}`, path: '/multichannel/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`multichannel.multichannel`)}`, path: '/multichannel/editar/:id' },

  { name: i18n.t(`imports.import_image_sku`), path: '/product_fashion/prepare_import_image_product_sku' },
  { name: i18n.t(`imports.import_image_product`), path: '/product_fashion/prepare_import_image_product' },
  { name: i18n.t(`imports.import_image_category`), path: '/product_fashion/prepare_import_image_categories' },
  { name: i18n.t(`imports.problem_title`), path: '/product_fashion/prepare_import_image_product/:id' },

  { name: i18n.t(`imports.import_image_product`), path: '/product_varejo/prepare_import_image_product' },
  { name: i18n.t(`imports.import_image_category`), path: '/product_varejo/prepare_import_image_categories' },
  { name: i18n.t(`imports.problem_title`), path: '/product_varejo/prepare_import_image_product/:id' },

  { name: i18n.t(`imports.import_image_product`), path: '/product_food/prepare_import_image_product' },
  { name: i18n.t(`imports.import_image_category`), path: '/product_food/prepare_import_image_categories' },
  { name: i18n.t(`imports.problem_title`), path: '/product_food/prepare_import_image_product/:id' },

  { name: 'Parâmetros da conta', path: '/configuracoes/adicionais' },
  { name: 'Produtos', path: '/product_food' },
  { name: 'Criar Produtos', path: '/product_food/criar' },
  { name: 'Editar Produtos', path: '/product_food/editar/:id' },
  { name: 'Produtos', path: '/product_hospitality' },
  { name: 'Criar Produtos', path: '/product_hospitality/criar' },
  { name: 'Editar Produtos', path: '/product_hospitality/editar/:id' },

  { name: i18n.t(`permissions.locations`), path: '/hospitality/configuracoes/setores' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.locations`)}`, path: '/hospitality/configuracoes/setores/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`permissions.locations`)}`, path: '/hospitality/configuracoes/setores/editar/:id' },

  { name: 'Ícones', path: '/hospitality/configuracoes/icones' },

  { name: i18n.t(`permissions.service`), path: '/hospitality/service-item' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.service`)}`, path: '/hospitality/service-item/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`permissions.service`)}`, path: '/hospitality/service-item/update/:id' },

  { name: i18n.t(`permissions.serviceCategory`), path: '/hospitality/service-category' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.serviceCategory`)}`, path: '/hospitality/service-category/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`permissions.serviceCategory`)}`, path: '/hospitality/service-category/update/:id' },
  { name: i18n.t(`feedCategory.feedCategory`), path: '/hospitality/content/category' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`feed.content`)}`, path: '/hospitality/content/category/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`feed.content`)}`, path: '/hospitality/content/category/editar/:id' },

  { name: i18n.t(`feed.content`), path: '/hospitality/content/content' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`feed.content`)}`, path: '/hospitality/content/content/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`feed.content`)}`, path: '/hospitality/content/content/editar/:id' },

  { name: i18n.t(`queue.config`), path: '/queue/config' },
  { name: i18n.t(`queue.index`), path: '/queue/manage' },
  { name: i18n.t(`booking.config`), path: '/booking/config' },
  { name: i18n.t(`environment.map`), path: '/booking/environment' },
  { name: i18n.t(`booking_day.booking`), path: '/booking/day' },
  { name: i18n.t(`booking_report.report`), path: '/booking/report' },
  { name: i18n.t(`booking_report_management.management`), path: '/booking/management' },
  { name: i18n.t(`booking_report_management.management`), path: '/booking/management/:search' },

  { name: 'Relatório ABC', path: '/reportabc' },

  { name: i18n.t(`liveShop.liveShop`), path: '/live_shop' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`liveShop.liveShop`)}`, path: '/live_shop/criar' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`liveShop.liveShop`)}`, path: '/live_shop/editar/:id' },

  { name: 'Segmentos', path: '/segmentos' },
  { name: 'Criar Segmento', path: '/segmentos/criar' },
  { name: 'Editar Segmento', path: '/segmentos/editar/:id' },

  { name: 'Lojas/Clientes', path: '/stores' },
  { name: 'Criar Loja', path: '/stores/criar' },
  { name: 'Editar Loja', path: '/stores/editar/:id' },

  { name: i18n.t(`feed.content`), path: '/aggregator/content' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`feed.content`)}`, path: '/aggregator/content/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`feed.content`)}`, path: '/aggregator/content/editar/:id' },

  { name: i18n.t(`icon.title`), path: '/icon' },
  { name: 'Categoria de Ícone', path: '/icon/categoria' },
  { name: 'Criar Categoria de Ícone', path: '/icon/categoria/criar' },
  { name: 'Editar Categoria de Ícone', path: '/icon/categoria/editar/:id' },


  { name: i18n.t(`permissions.sds_display`), path: '/sds/manage-hospitality' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.sds_display`) }`, path: '/sds/manage-hospitality/create' },
  { name: i18n.t(`permissions.sds_manage`), path: '/sds/manage' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.sds_manage`) }`, path: '/sds/manage/create' },
  { name: i18n.t(`permissions.sds_layout`), path: '/sds/layout' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.sds_layout`)}`, path: '/sds/layout/create' },

  { name: `Usuarios`, path: '/config/user' },

  { name: `Setor de atuação`, path: '/config/sector'},

  { name: `Termos`, path: '/config/term'},

  { name: `Dados da conta`, path: '/config/account'},

  { name: `Categorias`, path: '/agregador/category'},
  { name: `Criar Categoria`, path: '/agregador/category/create'},
  { name: `Editar Categoria`, path: '/agregador/category/update/:id'},

  { name: `Subcategoria`, path: '/agregador/subcategory'},
  { name: `Criar Subcategoria`, path: '/agregador/subcategory/create'},
  { name: `Editar Subcategoria`, path: '/agregador/subcategory/editar/:id'},

  { name: `Publicidade`, path: '/agregador/publicidade'},
  { name: `Criar Publicidade`, path: '/agregador/publicidade/create'},
  { name: `Editar Publicidade`, path: '/agregador/publicidade/update'},

  { name: `${i18n.t(`permissions.footerFeature`)}`, path: '/footer/feature' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.footerContentAddEdit`)}`, path: '/footer/feature/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`permissions.footerContentAddEdit`)}`, path: '/footer/feature/edit/:id' },

  { name: `${i18n.t(`permissions.footerContent`)}`, path: '/footer/content' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.footerContent`)}`, path: '/footer/content/create' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`permissions.footerContent`)}`, path: '/footer/content/edit/:id' },

  { name: i18n.t(`chat.title_config`), path: '/ia/config' },
  { name: `${i18n.t(`buttons.create`)} ${i18n.t(`chat.title_config`)}`, path: '/ia/config/criar/:id' },
  { name: `${i18n.t(`general.edit`)} ${i18n.t(`chat.title_config`)}`, path: '/ia/config/edit/:id' },

  { name: i18n.t(`chat.fit`), path: '/ia/fit' },

]
const BreadCrumbs: FC<any> = () => {



  const { menu } = usePluginsSpace()


  const [crumbs, setCrumbs] = useState<{
    name: string;
    path: string;
  }[]>([]);
  const location = useLocation()

  function removerDuplicatasPorNome(array: any[]): any[] {
    const mapa: Map<string, any> = new Map();

    // Preenche o mapa com os objetos únicos, usando a propriedade 'nome' como chave
    for (const objeto of array) {
      mapa.set(objeto.name, objeto);
    }

    // Retorna os valores do mapa como um array, que contém apenas objetos únicos
    return Array.from(mapa.values());
  }

  function changeCrumbsByPath() {
    /*
      Remover rota da dashboard
      Caso for um submenu apresentar o menu pai sem o link
    */

    const rotasFiltradas = rotas.filter(route => {
      // Verifica se o caminho da rota corresponde exatamente ao caminho atual
      if (route.path === location.pathname) {
        return true;
      }

      // Verifica se o caminho da rota é o início do caminho atual
      if (location.pathname.includes(route.path + '/') && !location.pathname.includes('import')) {
        return true;
      }

      if (route.path.includes('webapp/configuracoes') && location.pathname.includes('webapp/configuracoes')) {
        return true
      }

      if (route.path.includes('webapp/personalize') && location.pathname.includes('webapp/personalize')) {
        return true
      }

      if (route.path.includes('webapp/qrcode') && location.pathname.includes('webapp/qrcode')) {
        return true
      }

      if (location.pathname.includes('webapp/editar') && route.path.includes('webapp/editar')) {
        return true
      }

      // Verifica se o caminho da rota é uma rota dinâmica e corresponde ao início do caminho atual
      if (route.path.includes('/:') && location.pathname.includes(route.path + '/')) {
        const staticPart = route.path.split('/:')[0];

        const hasSomethingAfter = route.path.split('/:')[1].split('/')

        if (location.pathname.includes(staticPart)) {
          if (hasSomethingAfter.length > 1) {
            return location.pathname.includes(hasSomethingAfter[hasSomethingAfter.length - 1]) ? true : false
          }

          return true;
        }
      }

      if (route.path.includes('/:') && !location.pathname.includes(route.path)) {
        const staticPart = route.path.split('/:')[0];

        const hasSomethingAfter = route.path.split('/:')[1].split('/')

        if (location.pathname.includes(staticPart)) {
          if (hasSomethingAfter.length > 1) {
            return location.pathname.includes(hasSomethingAfter[hasSomethingAfter.length - 1]) ? true : false
          }

          return true;
        }
      }
      return false;
    });

    const crumbs = rotasFiltradas
      .map(({ path, ...rest }) => {
        const hasParams = path.includes(':');
        const newPath = hasParams
          ? Object.keys(location.pathname).length
            ? Object.keys(location.pathname).reduce(
              (path, param: any) =>
                path.replace(`:${param}`, location.pathname[param]),
              path
            )
            : path
          : path;

        return {
          path: newPath,
          ...rest
        };
      });
    // recuperar o menu lateral
    // achar qual que é o pai que  esta no crumbsa e add

    let submenuTitle = ''
    menu.map(menu => {

      const find = menu.submenu.find(subM => subM.router.includes(crumbs[0].path))

      if (find && !submenuTitle) {
        submenuTitle = menu.name
      }


    })

    if (submenuTitle) {
      crumbs.unshift({
        name: i18n.t(`permissions.${submenuTitle}`),
        path: ""
      })
    }

    if(crumbs[0].path.includes('service-item') || crumbs[0].path.includes('category-item') || crumbs[0].path.includes('manage-hospitality')){
      crumbs.unshift({
        name: i18n.t(`permissions.service`),
        path: ""
      })
    }


    setCrumbs(removerDuplicatasPorNome(crumbs))

  }

  useEffect(() => {
    changeCrumbsByPath()
  }, [location, menu])



  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <div>
      <ol className="breadcrumb  breadcrumb-dot text-muted fw-bold mb-3 "
        style={{
          fontSize: '12px',

        }}>
        {crumbs.map(({ name, path }: any, key: number) => (
          <li className={`breadcrumb-item`} >
            <span key={key}>
              {name}
            </span>
          </li>


        ))
        }
        {/*router.map((r, index) => (

          <li className={`breadcrumb-item`}
            style={{
              cursor: index !== router.length - 1 ? 'pointer' : ''
            }}

            <span className={` ${index === router.length - 1 ? 'text-muted' : ''}`}>
              {r.title}
            </span>
          </li>

        ))
          */}
      </ol>
    </div >
  )
}

export { BreadCrumbs, rotas }