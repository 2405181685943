/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, BaseSyntheticEvent} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type {MetaType} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {useNavigate} from 'react-router-dom'
import {GET, PUT, POST, DELETE} from '../../../../services/api'
import useModals from '../../../../hooks/useModals'
import {Loading} from '../../../../components/Loading/Loading'
import {Dropdown} from 'react-bootstrap'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../../components/Input/Input'
import Swal from 'sweetalert2'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
//import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'

type CategoryType = {
  id: number
  parent: string
  code: string
  name: string
  shortname: string
  order: 0
  img: string
  status: true
  subitems: CategoryType[]
}

const Content: FC = () => {
  const navigate = useNavigate()
  const {showWrapperModal} = useModals()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const [fileToImport, setFileToImport] = useState<File | null>(null)
  const [hasIntegration, setHasIntegration] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [qtdCategorias, setqtdCategorias] = useState(0)

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchClassification = await PUT(`/classification/${Id}`, {
        status: !status,
      })
      if (switchClassification.success) {
        const newCategories = categories.map((category) => {
          if (category.id === id) {
            category.status = !status
          }
          return category
        })
        setCategories(newCategories)
      }
    } catch (e) {
      console.log(e)
    }
    init()
  }

  let filterData = (array: any, search: string, searchFields: any[]) => {
    const arrayCopy = JSON.parse(JSON.stringify(array))

    return arrayCopy.filter((item: any) => {
      let found = searchFields.some((field) => {
        return item[field].toLowerCase().indexOf(search.toLowerCase()) > -1
      })
      return found
    })
  }

  const getCategories = async (search: string = '') => {
    try {
      setLoading(true)
      const res = await POST(`/classification/filter`, {
        plugintypeId: 10,
        deleted: 'not show',
      })
      const newCategories = res.data.map((row: CategoryType) => {
        return {
          id: row.id,
          name: `${row.name}`,
          status: row.status,
          code: row.code,
          parent: row.parent,
        }
      })
      const filterCategories = filterData(newCategories, search, ['name'])
      setCategories(
        filterCategories.sort((a: CategoryType, b: CategoryType) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      )
      setqtdCategorias(res.meta.itemCount)
      setMeta(res.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteCategory = async () => {
    try {
      setLoading(true)
      const res = await DELETE(`/classification/${id}`)
      if (res.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Categoria deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getCategories())
      } else {
        console.log(res)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a categoria!',
        }).then(async () => await getCategories())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a categoria!',
      }).then(async () => await getCategories())
      console.log(e)
    }
  }

  const showDeleteModal = () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'A categoria será deletada e não será possível restaurar!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#7e8299',
      cancelButtonText: 'fechar!',
      confirmButtonText: 'Deletar!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory()
      }
    })
  }

  function init() {
    getCategories()
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Categorias de Conteúdo</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listadas as categorias de conteúdo juntamente com suas respectivas
                  ações.
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/hospitality/content/category/criar')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={categories}
                headers={[
                  {key: 'Nome', value: 'name', type: 'default'},
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  {key: 'Ações', value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    title: 'Editar categoria',
                    action: (item) => {
                      navigate(`/hospitality/content/category/editar/${item.id}`)
                    },
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal()
                      setId(item.id)
                    },
                    path: '',
                    title: 'Excluir categoria',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const FeedCategorias: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Categorias', path: 'category', isActive: true},
        ]}
      >
        Categorias
      </PageTitle>
      <Content />
    </>
  )
}

export {FeedCategorias}
