/* eslint-disable no-mixed-operators */
/* eslint-disable array-callback-return */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import { GET, POST } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import moment from 'moment'
import JsonFormatter from 'react-json-formatter'
import { i18n } from '../../../../translate/i18n'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import axios from 'axios';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2'

type LogResponseType = {
  id: number;
  method: string;
  error: any;
  date: string;
}

type SelectType = {
  select: string;
  value: string;
}


const LogsContent: FC = () => {
  const { showWrapperModal, closeWrapperModal } = useModals()
  const [logs, setLogs] = useState<LogResponseType[]>([])
  const [id, setId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [methods, setMethods] = useState<SelectType[]>([{ value: 'all', select: 'Todos' }]);
  const [method, setMethod] = useState('all');
  const [methodsArray, setMethodsArray] = useState<SelectType[]>([]);
  const [gmt, setGmt] = useState<string>('');
  const [limitDate, setlimitDate] = useState<string>();
  const { loginInfo } = useAuth()

  //Datatable
  const [copied, setCopied] = useState(false)
  const [linked, setLinked] = useState(false)
  const [header, setHeader] = useState<any>([
    { key: 'ID', value: 'id', type: 'default' },
    { key: i18n.t(`reports.methods`), value: 'method', type: 'default' },
    { key: i18n.t(`reports.date`), value: 'date', type: 'datetime', isDate: true },
    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
  ])
  const [options, setOptions] = useState<any>([
    {
      icon: 'fas fa-eye',
      action: (item: any) => {
        setId(item.id)
        showWrapperModal(i18n.t(`reports.log_details`))
      },
      title: i18n.t(`reports.log_details`),
    },
  ])

  const closeModal = () => {
    closeWrapperModal();
  };

  const tratarData = (hora: string, data: string) => {
    const dataTransform = new Date(data);
    let startDateTransform = data;
    let horaInitTransform = hora.slice(0, 2);
    let verificaDigito = parseInt(horaInitTransform);
    const gmtParse = parseInt(gmt);

    if(gmtParse < 0){ //Tratar 23
      if(verificaDigito < 21) {
        verificaDigito-=gmtParse;
        horaInitTransform = (verificaDigito.toString().padStart(2, '0')) + hora.substring(2);
      } else {
        switch(horaInitTransform) {
          case '21':
            horaInitTransform = '00';
            break;
          case '22':
            horaInitTransform = '01';
            break;
          case '23':
            horaInitTransform = '02';
            break;
        }

        dataTransform.setDate(dataTransform.getDate() + 2);
        if (dataTransform.getDate() === 1) dataTransform.setMonth(dataTransform.getMonth() + 1);
        if (dataTransform.getMonth() === 0) dataTransform.setFullYear(dataTransform.getFullYear() + 1);
        startDateTransform = `${dataTransform.getFullYear()}-${((dataTransform.getMonth() + 1)).toString().padStart(2, '0')}-${dataTransform.getDate().toString().padStart(2, '0')}`;
        horaInitTransform = horaInitTransform + hora.substring(2);
      }
    } else if(gmtParse > 0) { //Tratar 00
      if(verificaDigito > 2) {
        verificaDigito-=gmtParse;
        horaInitTransform = (verificaDigito.toString().padStart(2, '0')) + hora.substring(2);
      } else {
        switch(horaInitTransform) {
          case '00':
            horaInitTransform = '21';
            break;
          case '01':
            horaInitTransform = '22';
            break;
          case '02':
            horaInitTransform = '23';
            break;
        }

        dataTransform.setDate(dataTransform.getDate() - 2);
        if (dataTransform.getDate() === 1) dataTransform.setMonth(dataTransform.getMonth() - 1);
        if (dataTransform.getMonth() === 0) dataTransform.setFullYear(dataTransform.getFullYear() - 1);
        startDateTransform = `${dataTransform.getFullYear()}-${((dataTransform.getMonth() - 1)).toString().padStart(2, '0')}-${dataTransform.getDate().toString().padStart(2, '0')}`;
        horaInitTransform = horaInitTransform + hora.substring(2);
      }
    } else {
      horaInitTransform = hora;
      startDateTransform = data;
    }
    return [horaInitTransform, startDateTransform];
  }

  const [hourInit, setHourInit] = useState<string>('00:00:00')
  const [hourFin, setHourFin] = useState<string>('23:59:59')

  const getLogs = async () => {
    try {
      setLoading(true);

      const resInitial = tratarData(hourInit, startDate);
      const hourInitTransform = resInitial[0];
      const startDateTransform = resInitial[1];

      const resFinal = tratarData(hourFin, finalDate);
      const hourFinalTransform = resFinal[0];
      const finalDateTransform = resFinal[1];

      /**
       * localize em methodsArray usando o method e
       * monte um array de string com valores encontrado
       **/
      let logsRes;
      if(method === 'all' || method === 'Todos'){
        logsRes = await POST('/logs/filter', {
          startDate: startDateTransform + ` ${hourInitTransform}`,
          finalDate: finalDateTransform + ` ${hourFinalTransform}`,
        })
      } else {
        const itens = methodsArray && Object.getOwnPropertyDescriptor(methodsArray, method)?.value;
        if(itens) {
          const methodArray = itens.map((item: { value: string }) => item.value);
          logsRes = await POST('/logs/filter', {
            methodArray,
            startDate: startDateTransform + ` ${hourInitTransform}`,
            finalDate: finalDateTransform + ` ${hourFinalTransform}`,
          })
        } else {
          logsRes = [];
        }
      }
      /* const logsRes = await POST('/logs/filter', {
        method,
        startDate: startDateTransform + ` ${hourInitTransform}`,
        finalDate: finalDateTransform + ` ${hourFinalTransform}`,
      })
      */
     
      const newLogs = logsRes.data[0].map((log: LogResponseType) => {

        const dateFormated = new Date(log.date)

        dateFormated.setHours(dateFormated.getHours() + parseInt(gmt));

        // Formatar a data com o GMT especificado
        const formattedDate = dateFormated.toISOString();

        return {
          id: log.id,
          method: i18n.t(`logs.${log.method}`),
          error: log.error,
          date: formattedDate
        }
      })
      setLogs(newLogs);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const getGMT = async () => {
    try {
      setLoading(true);

      const resGMT = await GET(`pluginspace/${loginInfo.pluginspaceId}`)

      const gmt = resGMT.data[0].gmt 

      setGmt(gmt)
      
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error)
    }
  }

  function getLimitDate() {
    const dataAtual = new Date();

    // Subtrair 7 dias
    const dataMenosSeteDias = new Date(dataAtual);
    dataMenosSeteDias.setDate(dataAtual.getDate() - 7);

    // Obter ano, mês e dia
    const ano = dataMenosSeteDias.getFullYear();
    const mes = (dataMenosSeteDias.getMonth() + 1).toString().padStart(2, '0'); // Mês é base 0
    const dia = dataMenosSeteDias.getDate().toString().padStart(2, '0');

    // Formatar a data como 'yyyy-MM-dd'
    const dataFormatada = `${ano}-${mes}-${dia}`;
    
    setlimitDate(dataFormatada)
  }

  async function getFilterLogs() {
    try {

      setLoading(true)

      const response = await GET('logs/getAllLogsGroups');
      let newMethods = [{ value: '', select: '' }];

      if (response.success) {
        response.data.map((methodItem: { method: string }) => {
          newMethods.push({ value: methodItem.method, select: i18n.t(`logs.${methodItem.method}`) });
        });
      }

      /**
       * Ordenação dos métodos 
       */
      newMethods = newMethods.filter(item => item.select !== '');
      if(newMethods.length > 0) {
        newMethods.sort((a: SelectType, b: SelectType) => {
          if(a.select < b.select) {
            return -1;
          }
          if(a.select > b.select) {
            return 1;
          }
          return 0;
        });
      }
      /**
       * Crie um JSON apartir do newMethods onde deverá usar como
       * chave o select dentro desse JSON e dentro da chave value
       * deverá ser um objeto do tipo string com os valores do select
       * o Json deve ficar assim: {"select": {"value": {valores do select aqui}}}
       */
      newMethods = [{ value: 'Todos', select: 'Todos' }, ...newMethods];
      let arrayMethods = newMethods.reduce((acc: any, methodItem: { value: string; select: string }) => {
        if (!acc[methodItem.select]) {
          acc[methodItem.select] = [];
        }
        acc[methodItem.select].push({ value: methodItem.value });
        return acc;
      }, {});
        const jsonMethods = { select: arrayMethods }
        console.log(jsonMethods)
        //Carrega o Array para uso posterior
        setMethodsArray(arrayMethods);

      /**
       * O value agora no array newMethods deverá incluir o select como sendo também o 
       * valor do select
       */
      newMethods.forEach((methodItem: { value: string; select: string }) => {
        methodItem.value = methodItem.select;
      });
      newMethods = newMethods.filter((item, index, self) =>
        index === self.findIndex((t) => (
          t.select === item.select && t.value === item.value
        ))
      );
     
      setMethods(newMethods);

      setLoading(false)

    } catch (error) {
      setLoading(false)
      return error


    }
  }

  

  useEffect(() => {
    getGMT()
    getLimitDate()
    getFilterLogs()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!copied) {
      return
    }

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [copied])

  useEffect(() => {
    if (!linked) {
      return
    }

    setTimeout(() => {
      setLinked(false)
    }, 1500)
  }, [linked])
 
  const handleClick = async () => {
    const error = logs.find((log) => log.id === id)?.error;
    const method = logs.find((log) => log.id === id)?.method;
    let url;
    let methodName;
    try {
        url = JSON.parse(error)?.url;
        methodName = method;
    } catch {
        url = error;
    }
    if (url) {
        try {
            const response = await axios.get(url, { responseType: 'blob' });
            const blob = new Blob([response.data], { type: 'application/octet-stream' });
            saveAs(blob, `${methodName}-${id}.csv`); // nome de arquivo
            if(response.data.size === 0) {
              closeModal();
              Swal.fire({
                title: 'Success!',
                icon: 'success',
                text: 'Download efetuado!',
                confirmButtonText: 'OK'
              });
            }
        } catch (error) {
          if (axios.isAxiosError(error)) {
              closeModal();
              Swal.fire({
              icon: 'warning',
              title: 'Atenção',
              text: 'O arquivo não se encontra mais disponível!',
          })
          return
          }
        }
    }
};

 const jsonStyle = {
    propertyStyle: { color: 'grey' },
    stringStyle: { color: 'white' },
    numberStyle: { color: 'white' },
    booleanStyle: { color: 'white' },
    style: { color: 'white' }
  }

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Logs</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`reports.listened_logs`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-end flex-wrap gap-5'>
                <div className='w-25'>
                  <Input
                    inputAttr={{ type: 'select' }}
                    label={i18n.t(`reports.methods`)}
                    value={method}
                    change={setMethod}
                    options={methods}
                  />
                </div>
                <div className='d-flex justify-content-between align-items-center gap-2'>
                  <Input
                    inputAttr={{ type: 'date' }}
                    label={i18n.t(`reports.first`)}
                    value={startDate}
                    change={setStartDate}
                    maxLength={finalDate}
                    minLength={limitDate}
                  />
                  <Input
                    inputAttr={{ type: 'time' }}
                    label={i18n.t(`reports.firstTime`)}
                    value={hourInit}
                    change={setHourInit}
                    size='md'
                  />
                </div>
                <div className='d-flex justify-content-between align-items-center gap-2'>
                  <Input
                    inputAttr={{ type: 'date' }}
                    label={i18n.t(`reports.end`)}
                    value={finalDate}
                    change={setFinalDate}
                    minLength={startDate}
                  />
                  <Input
                    inputAttr={{ type: 'time' }}
                    label={i18n.t(`reports.endTime`)}
                    value={hourFin}
                    change={setHourFin}
                    size='md'
                  />
                </div>
                <Button
                  color='primary'
                  text={i18n.t(`buttons.search`)}
                  click={() => getLogs()}
                  btnClass='h-45px'
                />
              </div>
              <Datatable
                data={logs}
                headers={header}
                options={options}
                color='primary'
              />
            </div>
          </div>
          <ModalWrapper confirmAction={() => { }} noAction>
            <div className='d-flex' style={{ backgroundColor: 'black', overflowY: 'scroll', height: '40em' }}>
              <JsonFormatter json={logs.find((log) => log.id === id)?.error} tabWith={4} jsonStyle={jsonStyle} />
            </div>
            <div className='d-flex justify-content-center mt-4'>
              <CopyToClipboard text={logs.find((log) => log.id === id)?.error} onCopy={() => setCopied(true)}>
                <button className='btn btn-success w-100'>{copied ? i18n.t(`general.copied`) : i18n.t(`general.copy`)}</button>
              </CopyToClipboard>
            </div>
            {(logs.find((log) => log.id === id)?.method === 'Link de integração de estoque' ||
              logs.find((log) => log.id === id)?.method === 'Link de integração de preço' ||
              logs.find((log) => log.id === id)?.method === 'Link de integração de produto') && (
              <div className='d-flex justify-content-center mt-4'>
                <button className='btn btn-info w-100' onClick={handleClick}>
                  {linked ? i18n.t(`general.downloadedS3`) : i18n.t(`general.downloadS3`)}
                </button>
              </div>
            )}
          </ModalWrapper>
        </div>}
    </>
  )
}

const Logs: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Logs', path: 'order/logs', isActive: true },
        ]}
      >
        Logs
      </PageTitle>
      <LogsContent />
    </>
  )
}

export { Logs }

