import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { POST } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type TemplateResponseType = {
  id: number;
  name: string;
  parameters: any;
}

type ConfigInputsType = {
  name: string;
  label: string;
}

const CriarMeioPagamentoContent: FC = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [templates, setTemplates] = useState<TemplateResponseType[]>([]);
  const [configInputs, setConfigInputs] = useState<(ConfigInputsType | undefined)[]>([]);
  const [templateId, setTemplateId] = useState<number | null>();

  const getTemplates = async () => {
    try {
      setLoading(true);
      const templateRes = await POST('/plugintemplate/filter', { plugintypeId: 14 })
      const newTemplates: TemplateResponseType[] = [{ id: 0, name: 'Selecione um template', parameters: null }]
      templateRes.data.filter((template: TemplateResponseType)=> template.id !== 12 ).map((template: TemplateResponseType) => {
        newTemplates.push({
          id: template.id,
          name: template.name,
          parameters: JSON.parse(template.parameters)
        })
      })
      setTemplates(newTemplates)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const changeTemplate = (selectedId: number) => {
    setTemplateId(selectedId)
    if (selectedId !== 0) {
      const template = templates.find((templateFinded) => templateFinded.id === selectedId);
      const newInputs: (ConfigInputsType | undefined)[] = Object.entries(template?.parameters.config).map((configInput) => {
        if (configInput[0] !== undefined && configInput[1] !== undefined && typeof (configInput[1]) === 'string') {
          const input: ConfigInputsType = {
            name: configInput[0],
            label: configInput[1]
          }
          return input
        }
      })
      setConfigInputs(newInputs)
    }
  }

  const createIntegration = async () => {
    if (templateId) {
      const template = templates.find((templateFinded) => templateFinded.id === templateId);
      let integrationContent: any = {}
      Object.entries(template?.parameters.config).map((configInput) => {
        const element: any = document.getElementsByName(configInput[0])[0];
        integrationContent[configInput[0]] = element.value;
      })
      try {
        if (!name || !templateId) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!'
          })
        } else {
          setLoading(true);
          const integrationRes = await POST('/pluginconfig', {
            plugintypeId: 14,
            plugintemplateId: templateId,
            name: name,
            content: integrationContent
          })
          setLoading(false);
          if (integrationRes.success === true && integrationRes.dados && integrationRes.dados.clientIdPagseguro) {
            localStorage.setItem('clientIdPagseguro', integrationRes.dados.clientIdPagseguro)
            localStorage.setItem('emailPagseguro', integrationRes.dados.content.email)
            localStorage.setItem('pluginspaceId', integrationRes.dados.pluginspaceId)
            Swal.fire({
              icon: 'success',
              title: 'Integração cadastrada',
              text: 'Cadastro realizado com sucesso!'
            }).then(() => (integrationRes.dados.content.environment && integrationRes.dados.content.environment === '1') ? 
              window.location.href =`https://connect.pagseguro.uol.com.br/oauth2/authorize?response_type=code&client_id=${integrationRes.dados.clientIdPagseguro}&redirect_uri=${integrationRes.dados.content.redirect_uri}&scope=payments.read+payments.create+payments.refund&state=xyz` :
              window.location.href = `https://connect.sandbox.pagseguro.uol.com.br/oauth2/authorize?response_type=code&client_id=${integrationRes.dados.clientIdPagseguro}&redirect_uri=${integrationRes.dados.content.redirect_uri}&scope=payments.read+payments.create+payments.refund&state=xyz`
            )
          } else if (integrationRes.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Integração cadastrada',
              text: 'Cadastro realizado com sucesso!'
            }).then(() => navigate('/pluginconfig/gateway'))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao cadastrar integração!'
            })
          }
        }
      } catch (e) {
        setLoading(false);
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao cadastrar integração!'
        })
      }
    }
  }

  useEffect(() => {
    getTemplates();
  }, [])

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`buttons.create`)} ${i18n.t(`integration.means_of_payment`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'text',
                    }}
                    placeholder={i18n.t(`integration.integration_name`)}
                    label={i18n.t(`integration.integration_name`)}
                    change={(value: string) => setName(value)}
                    value={name}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`integration.type_of_integration`)}
                    change={(value: string) => { changeTemplate(parseInt(value)) }}
                    options={templates.map((template) => ({
                      value: template.id,
                      select: template.name
                    }))}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                {configInputs.map((config) => (
                  <div style={{ flex: '40%' }} key={config?.name}>
                    <Input
                      inputAttr={{
                        type: 'text',
                      }}
                      placeholder={config?.label}
                      label={config?.label}
                      name={config?.name}
                      change={() => { }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="col-6 d-flex justify-content-start">
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className="col-6  d-flex justify-content-end">
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => createIntegration()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

const CriarMeioPagamento: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`integration.means_of_payment`), path: 'pluginconfig/gateway', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'pluginconfig/gateway/create', isActive: true },
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`integration.means_of_payment`)}`}
      </PageTitle>
      <CriarMeioPagamentoContent />
    </>
  )
}

export { CriarMeioPagamento }
