import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loading } from '../../../components/Loading/Loading'
import { GET, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import Swal from 'sweetalert2'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { secondsToHour, toHour } from '../../../utils/formater'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type BookingType = {
  id: number;
  date: string;
  expiresIn: string;
  people: number;
  name: string;
  email: string;
  phone: string;
  environment: string;
  alarm: boolean;
  secondsToExpires: number;
  bookingConfigId: string;
}

const BookingDayContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState<BookingType[]>([]);
  const [bookingSelected, setBookingSelected] = useState<BookingType | null>(null);

  const [option, setOption] = useState('');

  const createInterval = (outBookings: BookingType[], limit: number) => {
    setInterval(() => {
      const newBookings = outBookings.map((bk) => {
        bk.secondsToExpires--;
        bk.alarm = (bk.secondsToExpires / 60) < limit;
        return bk;
      });
      outBookings = newBookings;
      setBookings(newBookings);
    }, 1000)
  }

  const getBookings = async () => {
    try {
      const res = await GET('/queueReservation/bookingConfig')
      if (res.success && res.data && res.data[0] && res.data[0].id && res.data[0].expiryTime) {
        const bookingConfigId = res.data[0].id;
        const limit = res.data[0].expiryTime;
        if (bookingConfigId) {
          const bookingRes = await GET(`/queueReservation/booking/today/${bookingConfigId}`)
          if (bookingRes.data && bookingRes.data.length > 0) {
            const bks = bookingRes.data.map((bk: BookingType) => {
              const seconds = ((parseInt(bk.expiresIn.split(":")[0]) * 60) + parseInt(bk.expiresIn.split(":")[1]))
              bk.secondsToExpires = seconds;
              bk.alarm = (seconds / 60) < limit;
              return bk
            })
            setBookings(bks)
            createInterval(bks, limit)

          } else {
            setBookings([])
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Não foi possível encontrar configuração de reserva',
          }).then(() => navigate('/booking/config'))
        }
      }
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao encontrar reservas, verifique as configurações.',
      }).then(() => navigate('/booking/config'))
    }
  }

  const changeBooking = async () => {
    if (bookingSelected) {
      const booking = Object.assign(bookingSelected);
      const opt = option.toString();
      setBookingSelected(null);
      setOption('');
      try {
        setLoading(true);
        const res = await PUT(`/queueReservation/booking/${booking.id}`, { finalized: opt, bookingConfigId: booking.bookingConfigId })
        setLoading(false)
        if (res && res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Reserva alterada!',
          }).then(async () => await init())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro!',
            text: 'Falha ao alterar reserva',
          }).then(async () => await init())
        }
      } catch (e: any) {
        setLoading(false);
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: e ? e : 'Falha ao alterar reserva',
        }).then(async () => await init())
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao alterar reserva',
      }).then(async () => await init())
    }
  }

  const init = async () => {
    setLoading(true)
    await getBookings();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`booking_day.booking`)}</span>
                <BreadCrumbs />
              </h3>

            </div>
            <div className='card-body d-flex flex-column gap-5'>
              {bookings.length > 0 ? bookings.map((booking) => (
                <div
                  className="d-flex flex-row px-4 py-6 align-items-center"
                  style={{ border: '1px solid #D3D9E8', borderLeft: '4px solid #D3D9E8', borderRadius: 4 }}
                >
                  <div className="d-flex flex-column w-50 gap-2">
                    <div className="d-flex flex-row gap-2">
                      <b style={{ color: '#2A2D36', margin: 0 }}>{i18n.t(`booking_day.date`)}:</b>
                      <p style={{ color: '#6E7586', margin: 0 }}>{booking.date}</p>
                    </div>
                    <div className="d-flex flex-row gap-2 align-items-center">
                      <i style={{ color: '#2A2D36' }} className="fas fa-clock"></i>
                      <p style={booking.alarm ? { color: '#FABA5B', margin: 0 } : { color: '#2A2D36', margin: 0 }}>{secondsToHour(booking.secondsToExpires)}</p>
                    </div>
                    <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36', marginRight: 0 }}>{i18n.t(`environment.environment`)}:</b> {booking.environment}</p>
                    <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36', marginRight: 0 }}>{i18n.t(`dashboard.quantity`)}:</b> {booking.people} {i18n.t(`booking_day.people`)}</p>
                    <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{i18n.t(`user.name`)}:</b> {booking.name}</p>
                    <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{i18n.t(`booking_report_management.phone`)}: </b>{booking.phone}</p>
                  </div>
                  <div className="d-flex flex-column gap-4 w-50">
                    <button
                      onClick={() => {
                        setBookingSelected(booking)
                        setOption('confirmed')
                        showWrapperModal(i18n.t(`booking_day.confirm_title`))

                      }}
                      style={{ border: '1px solid #5D8ED8', borderRadius: '4px', backgroundColor: 'transparent', width: 60, height: 40 }}
                    >
                      <i style={{ color: '#5D8ED8' }} className="fa fa-check" aria-hidden="true"></i>
                    </button>

                    <button
                      onClick={() => {
                        setBookingSelected(booking)
                        setOption('canceled')
                        showWrapperModal(i18n.t(`booking_day.cancel_title`))
                      }}
                      style={{ border: '1px solid #5D8ED8', borderRadius: '4px', backgroundColor: 'transparent', width: 60, height: 40 }}
                    >
                      <i style={{ color: '#5D8ED8' }} className="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              )) :
                <div className="d-flex flex-column w-100 gap-2 align-items-center">
                  <h2 style={{ color: '#2A2D36', fontWeight: '400' }}>{i18n.t(`booking.empty`)} :(</h2>
                </div>}
            </div>
          </div>
          <ModalWrapper
            confirmAction={() => changeBooking()}
            textAction={option === 'canceled' ? i18n.t(`booking_day.cancel_button`) : i18n.t(`booking_day.confirm_button`)}
          >
            {bookingSelected && (
              <div
                className="d-flex flex-row px-4 py-6 align-items-center"
                style={{ border: '1px solid #D3D9E8', borderLeft: '4px solid #D3D9E8', borderRadius: 4 }}
              >
                <div className="d-flex flex-column gap-2">
                  <div className="d-flex flex-row gap-2">
                    <b style={{ color: '#2A2D36', margin: 0 }}>{i18n.t(`booking_day.date`)}</b>
                    <p style={{ color: '#6E7586', margin: 0 }}>{bookingSelected.date}</p>
                  </div>
                  <b style={{ color: '#2A2D36', marginRight: 0 }}>{bookingSelected.people} {i18n.t(`booking_day.people`)}</b>
                  <p style={{ color: '#6E7586', margin: 0 }}><b style={{ color: '#2A2D36' }}>{bookingSelected.name}</b></p>
                  <p style={{ color: '#6E7586', margin: 0 }}>{bookingSelected.phone}</p>
                </div>
              </div>
            )}
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const BookingDay: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`booking_day.booking`), path: 'booking/day', isActive: true },
        ]}
      >
        {i18n.t(`booking_day.booking`)}
      </PageTitle>
      <BookingDayContent />
    </>
  )
}

export { BookingDay }
