/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import useModals from '../../../../hooks/useModals'
import { useNavigate } from 'react-router-dom'
import { DELETE, GET } from '../../../../services/api'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type GroupType = {
  id: number;
  name: string;
}

const GrupoContent: FC = () => {
  const {showDeleteModal} = useModals()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<Array<GroupType>>([]);
  const [id, setId] = useState<number | null>(null);
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const getGroups = async () => {
    setLoading(true);
    try {
      const groupRes = await GET(`/permission-group`)
      const newGroups = groupRes.data.map((group:GroupType) => {
        return {
          id: group.id,
          name: group.name
        }
      })
      setGroups(newGroups);
      setMeta(groupRes.meta)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const deleteGroups = async () => {
    try{
      setLoading(true);
      const usersRes = await DELETE(`/permission-group/${id}`);
      if(usersRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'Grupo deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getGroups())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar grupo!'
        }).then(async () => await getGroups())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar grupo!'
      }).then(async () => await getGroups())
      console.log(e)
    }
  }

  useEffect(() => {
    getGroups();
  }, [])
  return (
    <>
    { loading ? <Loading /> :
      <div className='row g-5 gx-xxl-12'>
        <div className='card card-xxl-stretch mb-5 mb-xl-12'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`groups.groups`)}</span>
              <BreadCrumbs />
              <span className='text-muted mt-1 fw-bold fs-7'>
                {i18n.t(`groups.here_are_listed_the_groups_along_with_their_respective_actions`)}
              </span>
            </h3>
            <div className='card-toolbar'>
              <Button
                text={i18n.t(`buttons.add_group`)}
                color='primary'
                iconColor='#FFF'
                icon='fas fa-plus'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                  type: 'button',
                }}
                click={() => navigate('/configuracoes/grupos/criar')}
                btnClass='fw-bolder'
              />
            </div>
          </div>
          <div className='card-body'>
            <Datatable
              data={groups}
              headers={[
                {key: i18n.t(`groups.name`), value: 'name', type: 'default'},
                {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
              ]}
              options={[
                {
                  icon: 'fas fa-pen',
                  action: (item) => {
                    navigate(`/configuracoes/grupos/editar/${item.id}`)
                  },
                  title: `${i18n.t(`general.edit`)} ${i18n.t(`groups.groups`)}`,
                },
                {icon: 'fas fa-trash', action: (item) => {
                  showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`groups.groups`)}`)
                  setId(item.id);
                }, title: `${i18n.t(`general.delete`)} ${i18n.t(`groups.groups`)}`},
              ]}
              color='primary'
            />
          </div>
        </div>
        <ModalDelete confirmAction={() => deleteGroups()} /> 
      </div>}
    </>
  )
}

const Grupos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`groups.groups`), path: 'configuracoes/grupos', isActive: true},
        ]}
      >
        {i18n.t(`groups.groups`)}
      </PageTitle>
      <GrupoContent />
    </>
  )
}

export {Grupos}
