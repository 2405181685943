import axios from 'axios'

let loginData = localStorage.getItem('loginInfo_ninegrid_access')
if(!loginData) loginData = localStorage.getItem('loginInfo_ninegrid_adm')

let loginDataAdm = localStorage.getItem('loginInfo_ninegrid_adm')

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization:
      loginData && typeof loginData === 'string'
        ? `Bearer ${JSON.parse(loginData).accessToken}`
        : '',
  },
})

const apiAdm = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization:
      loginDataAdm && typeof loginDataAdm === 'string'
        ? `Bearer ${JSON.parse(loginDataAdm).accessToken}`
        : '',
  },
})

export default api

const setBearerToken = async (token: string) => {
  api.defaults.headers.Authorization = `Bearer ${token}`
}

const setBearerTokenAdm = async (token: string) => {
  apiAdm.defaults.headers.Authorization = `Bearer ${token}`
}

const GET = (url: string | void) =>
  api
    .get(url || '')
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

const GET_ADM = (url: string | void) =>
  apiAdm
    .get(url || '')
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

const POST = (url: string | void, params: object | Array<any | void> | void) =>
  api
    .post(url || '', params)
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

  const PATCH = (url: string | void, params: object | Array<any | void> | void) =>
    api
      .patch(url || '', params)
      .then((res) => res.data)
      .then((res) => {
        return res
      })
      .catch((e) => {
        throw e.response.data.message
      })

const POST_ADM = (url: string | void, params: object | Array<any | void> | void) =>
  apiAdm
    .post(url || '', params)
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

const PUT = (url: string | void, params: object | Array<any> | void) =>
  api
    .put(url || '', params)
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

const PUT_ADM = (url: string | void, params: object | Array<any> | void) =>
  apiAdm
    .put(url || '', params)
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

const DELETE = (url: string | void) =>
  api
    .delete(url || '')
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

const DELETE_ADM = (url: string | void) =>
  apiAdm
    .delete(url || '')
    .then((res) => res.data)
    .then((res) => {
      return res
    })
    .catch((e) => {
      throw e.response.data.message
    })

export {GET, POST, PUT, DELETE, PATCH, GET_ADM, POST_ADM, PUT_ADM, DELETE_ADM, setBearerToken, setBearerTokenAdm}
