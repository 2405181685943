import {FC, useEffect, useState} from 'react'
import JsonFormatter from 'react-json-formatter'
import {useNavigate, useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {Loading} from '../../../../components/Loading/Loading'
import {getPlugintypeIdByCode} from '../../../../functions/plugintype'
import useAuth from '../../../../hooks/useAuth'
import {GET, POST, PUT} from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ConfigInputsType = {
  name: string
  label: string
  value?: string
}

type TemplateResponseType = {
  id: number;
  name: string;
  parameters: any;
  plugintypeId: number;
  typeIntegration: 'payment_method' | 'pdv' | 'messenger' | 'others';
}


const EditarMensageriaContent: FC = () => {
  const navigate = useNavigate()
  const {loginInfo} = useAuth()
  const {id} = useParams()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const [templates, setTemplates] = useState<TemplateResponseType[]>([])
  const [configInputs, setConfigInputs] = useState<(ConfigInputsType | undefined)[]>([])
  const [templateId, setTemplateId] = useState<number | null>()
  const [jsonContent, setJsonContent] = useState<string | null>(null)

  const jsonStyle = {
    propertyStyle: {color: 'grey'},
    stringStyle: {color: 'white'},
    numberStyle: {color: 'white'},
    booleanStyle: {color: 'white'},
    style: {color: 'white'},
  }

  const getIntegration = async () => {
    try {
      const newTemplates = await getTemplates()
      setLoading(true)
      const integrationRes = await GET(`/pluginconfig/${id}`)
      setName(integrationRes.data[0].name)
      await changeTemplate(
        integrationRes.data[0].plugintemplateId,
        integrationRes.data[0].content,
        newTemplates
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getDto = async (templateId: number) => {
    try {
      if (!loading) setLoading(true)
      const schemaRes = await POST('/schemasFashion', {
        pluginconfigId: parseInt(id ? id : '0'),
        plugintemplateId: templateId,
      })
      if (schemaRes.filter) {
        setJsonContent(JSON.stringify(schemaRes))
      } else {
        setJsonContent(null)
      }
      setLoading(false)
    } catch (e) {
      console.log(e)
      setJsonContent(null)
      setLoading(false)
    }
  }

  const getTemplates = async () => {
    try {
      setLoading(true)
      let templateRes = await POST('/plugintemplate/filter?take=1000', {})
      const newTemplates: TemplateResponseType[] = [
        {id: 0, name: 'Selecione um template', parameters: null, plugintypeId: 0, typeIntegration: 'others'},
      ]
      const plugincode = getPlugintypeIdByCode(loginInfo.plugintype);
      templateRes.data.forEach((template: TemplateResponseType) => {
        if((template.plugintypeId === 1 || template.plugintypeId === plugincode) && template.typeIntegration === 'messenger')
          newTemplates.push({
            id: template.id,
            name: template.name,
            plugintypeId: template.plugintypeId,
            typeIntegration: template.typeIntegration,
            parameters: JSON.parse(template.parameters),
          })
      })
      setTemplates(newTemplates)
      setLoading(false)
      return newTemplates
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const changeTemplate = async (
    selectedId: number,
    configuration?: any,
    newTemplates?: TemplateResponseType[]
  ) => {
    setTemplateId(selectedId)
    if (selectedId !== 0) {
      await getDto(selectedId)
      let template
      if (templates.length === 0 && newTemplates) {
        template = newTemplates.find((templateFinded) => templateFinded.id === selectedId)
      } else {
        template = templates.find((templateFinded) => templateFinded.id === selectedId)
      }
      const newInputs: (ConfigInputsType | undefined)[] = Object.entries(
        template?.parameters.config
      ).map((configInput) => {
        if (
          configInput[0] !== undefined &&
          configInput[1] !== undefined &&
          typeof configInput[1] === 'string'
        ) {
          const input: ConfigInputsType = {
            name: configInput[0],
            label: configInput[1],
            value:
              configuration && configuration[configInput[0]] ? configuration[configInput[0]] : '',
          }
          return input
        }
      })

      setConfigInputs(newInputs)
    }
  }

  const editIntegration = async () => {
    if (templateId) {
      const template = templates.find((templateFinded) => templateFinded.id === templateId)
      let integrationContent: any = {}
      Object.entries(template?.parameters.config).map((configInput) => {
        const element: any = document.getElementsByName(configInput[0])[0]
        integrationContent[configInput[0]] = element.value
      })
      try {
        if (!name || !templateId) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!',
          })
        } else {
          setLoading(true)
          const integrationRes = await PUT(`/pluginconfig/${id}`, {
            plugintypeId: getPlugintypeIdByCode(loginInfo.plugintype),
            plugintemplateId: templateId,
            name: name,
            content: integrationContent,
          })
          setLoading(false)
          if (integrationRes.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Integração alterada',
              text: 'Edição realizada com sucesso!',
            }).then(() => navigate('/pluginconfig/messenger'))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao alterar integração!',
            })
          }
        }
      } catch (e) {
        setLoading(false)
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao alterar integração!',
        })
      }
    }
  }

  useEffect(() => {
    getIntegration()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`general.edit`)} ${i18n.t(`integration.messenger`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'text',
                    }}
                    placeholder={i18n.t(`integration.integration_name`)}
                    label={i18n.t(`integration.integration_name`)}
                    change={(value: string) => setName(value)}
                    value={name}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`integration.type_of_integration`)}
                    change={async (value: string) => {
                      await changeTemplate(parseInt(value))
                    }}
                    options={templates.map((template) => ({
                      value: template.id,
                      select: template.name,
                    }))}
                    value={templateId}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                {configInputs.map((config) => (
                  <div style={{flex: '40%'}} key={config?.name}>
                    <Input
                      inputAttr={{
                        type: 'text',
                      }}
                      placeholder={config?.label}
                      label={config?.label}
                      name={config?.name}
                      defaultValue={config?.value}
                      change={() => {}}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => editIntegration()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
          {jsonContent && (
            <div className='card card-xxl-stretch mb-5 mb-xl-12'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`integration.example`)}</span>
                </h3>
              </div>
              <div className='card-body border-bottom'>
                <div
                  className='d-flex'
                  style={{backgroundColor: 'black', overflowY: 'scroll', height: '40em'}}
                >
                  <JsonFormatter json={jsonContent} tabWith={4} jsonStyle={jsonStyle} />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

const EditarMensageria: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`integration.messenger`), path: 'pluginconfig/messenger', isActive: true},
          {title: i18n.t(`general.edit`), path: 'pluginconfig/messenger/edit', isActive: true},
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`integration.messenger`)}`}
      </PageTitle>
      <EditarMensageriaContent />
    </>
  )
}

export {EditarMensageria}
