/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { POST, PUT, GET } from '../../../../services/api'
import { numberToCpf, toDate, toDateTime, toMoney } from '../../../../utils/formater'
import moment from 'moment'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import useModals from '../../../../hooks/useModals'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { KTSVG } from '../../../../../_metronic/helpers'
import useAuth from '../../../../hooks/useAuth'
import { getPlugintypeIdByCode } from '../../../../functions/plugintype'
import { i18n } from '../../../../translate/i18n'
import { centsToFloat, floatToPrice } from '../../../../functions/price'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type CustomerAddressType = {
  city: string
  district: string
  state: string
  street: string
  zipCode: string
  number: string
  extra: string
}

type CustomerType = {
  id: number
  name: string
  doc: string
  birthDate: string
  customerAddresses: CustomerAddressType[]
}

type LocationType = {
  id: number
  name: string
  code: string
}

type StatusType = {
  id: number
  name: string
  slug: string
  order: number
}

type VariationType = {
  id: number
  context: string
}

type AdditionalType = {
  code: string;
  name: string;
  price: number;
  quantity: number;
}

type FractionalType = {
  name: string;
}

type ProductContentType = {
  productName: string
  observation?: string
  additionals: AdditionalType[]
  fractionated: FractionalType[]
}

type ProductType = {
  id: number
  sku: string
  quantity: number
  price: number
  discount: string
  skuLocalVariation: VariationType
  variationHtml?: string
  additionalHtml?: string
  fractionalHtml?: string
  content: ProductContentType
}
type PaymentGatewayType = {
  nsu: string
}

type GatewayType = {
  bin: string
  end: string
  flagIdentifier: string
  authorization: string
  nsu: string
  pluginconfig_id: number
  payment: PaymentGatewayType
  orderIdExt: string
  cardTicket: string
}

type OtherPersonType = {
  name: string;
  cpf: string;
}

type OrderAddressType = {
  address: string
  city: string
  district: string
  name: string
  number: string
  complement?: string
  state?: string
  cep?: string
  otherPersonToWithdrawal?: OtherPersonType
}

type ContentOrderType = {
  code: string
  info: string
  customer: string
  phoneSms: string
  dataGateway: GatewayType
  orderAddress: OrderAddressType
  externalCodeERP: string
}

type OrderStatusType = {
  id: number
  created: string
  statusId: number
  status?: string
  order?: number
  color?: string
}

type OrderResponseType = {
  id: number
  deliveryTax?: string
  account?: string
  orderidExt: string
  customer: CustomerType
  created: string
  location: LocationType
  status: StatusType[]
  content: ContentOrderType
  smartOrder: ProductType[]
  orderStatus: OrderStatusType[]
  total: number
  totalWithoutDiscount: number
  statusId: number
  observation: string
  customerCart?: {
    seller: {
      name: string
      email: string
      cpf: string
    }
  }
}

type ClearsaleResponseType = {
  code: string;
  status: string;
  score: number;
}

const PedidosViewContent: FC = () => {
  const navigate = useNavigate()
  const { wrapperModal, showWrapperModal } = useModals()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState<OrderResponseType | null>(null)
  const [statusSelected, setStatusSelected] = useState(0)
  const { loginInfo } = useAuth()
  const [clearsaleOrder, setClearsaleOrder] = useState<ClearsaleResponseType | null>(null)


  const getClearsaleOrder = async (newOrder: OrderResponseType) => {
    try {

      setLoading(true);
      const resClearsale = await POST('analyzeorder/searchOrderStatusClearSale', {
        orderId: parseInt(id ? id : '0'),
        order: newOrder.orderidExt ? newOrder.orderidExt : '',
        pluginspaceId: loginInfo.pluginspaceId,
        plugintypeId: getPlugintypeIdByCode(loginInfo.plugintype)

      })
      setLoading(false);

      if (resClearsale.success) {
        setClearsaleOrder(resClearsale.data);
      } else {
        setClearsaleOrder(null)
      }
    } catch (e) {
      setLoading(false);
      setClearsaleOrder(null)
      console.log(e)
    }
  }

  const getOrder = async () => {
    try {
      wrapperModal.title = 'Alterar status do pedido'
      setLoading(true)
      const resOrders = loginInfo.plugintype === 'hospitality' ? await POST('/orders/get-order-all-info-hotel', { orderId: id }) : await POST('/orders/get-order-all-info', { orderId: id })

      const resGMT = await GET(`pluginspace/${loginInfo.pluginspaceId}`)

      const gmt = resGMT.data[0].gmt

      let newOrder: OrderResponseType = resOrders.data[0]
      let total = 0
      let totalWithoutDiscount = 0
      setStatusSelected(newOrder.statusId)
      await getClearsaleOrder(newOrder);
      newOrder.smartOrder.map((product) => {
        if (product.skuLocalVariation) {
          const html = Object.entries(JSON.parse(product.skuLocalVariation.context)).map(
            (variation: any) => {
              if (variation[0].toLocaleLowerCase() !== 'variation' && variation[1].toLocaleLowerCase() !== 'none') {
                return `<p class='mb-0'>${variation[0]}: ${variation[1]}</p>`
              } else {
                return ``
              }
            }
          )
          product.variationHtml = html.join('')
        }

        if (product.content && product.content.additionals && product.content.additionals.length > 0) {
          let htmlAdditional = ''
          product.content.additionals.forEach((additional) => {
            htmlAdditional += `<p class='mb-0'>${additional.quantity}X ${additional.name} (${floatToPrice(centsToFloat(additional.price))})</p>`
            product.additionalHtml = htmlAdditional;
            product.price += additional.price * additional.quantity
          })
        }

        if (product.content && product.content.fractionated && product.content.fractionated.length > 0) {
          let htmlFractional = ''
          product.content.fractionated.forEach((fractional) => {
            htmlFractional += `<p class='mb-0'>${fractional.name}</p>`
            product.fractionalHtml = htmlFractional;
          })
        }

        total += product.price * product.quantity
        totalWithoutDiscount += (parseFloat(product.discount) + product.price) * product.quantity
      })

      newOrder.total = total
      newOrder.totalWithoutDiscount = totalWithoutDiscount
      newOrder.orderStatus.map((status, index) => {
        if (index > 0) {
          const dateFormated = new Date(status.created)

          dateFormated.setHours(dateFormated.getHours() + parseInt(gmt));

          // Formatar a data com o GMT especificado
          status.created = dateFormated.toISOString();
        }

        const findedStatus = newOrder.status.find((st) => st.id === status.statusId)
        if (findedStatus) {
          status.status = findedStatus.name
          status.order = findedStatus.order
          switch (findedStatus.order) {
            case 1:
              status.color = '#4DD0E1'
              break
            case 2:
              status.color = '#8D6E63'
              break
            case 3:
              status.color = '#7E57C2'
              break
            case 4:
              status.color = '#2E7D32'
              break
            default:
              status.color = '#AD1457'
              break
          }
        }
      })
      setOrder(newOrder)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const saveOrder = async () => {
    try {
      if (order?.statusId !== statusSelected) {
        setLoading(true)
        const findedStatus = order?.status.find((st) => st.id === statusSelected)
        const resOrders = await PUT(`/orders/change-status/${id}`, {
          statusId: statusSelected,
          dataGateway: order?.content.dataGateway,
          gatewayId: order?.content.dataGateway.pluginconfig_id,
          statusSlug: findedStatus ? findedStatus.slug : '',
        })
        setLoading(false)
        if (resOrders.success) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Pedido atualizado com sucesso!',
          }).then(async () => await getOrder())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Falha ao atualizar status do pedido.',
          })
        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Esse status já está atualizado.',
        })
      }
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Falha ao atualizar status do pedido.',
      })
      console.log(e)
    }
  }

  useEffect(() => {
    getOrder()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          {order && (
            <div className='d-flex flex-row justify-content-between gap-10 flex-wrap'>
              <div className='d-flex flex-column' style={{ flex: 1 }}>
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.consumer`)}</span>
                      <BreadCrumbs />
                    </h3>
                  </div>
                  <div className='card-body'>
                    {order.customer ?
                      <>
                        <div>
                          <p className='mb-0'>
                            <b>{order?.customer.name}</b>
                          </p>
                          {order?.customer.doc && (
                            <p className='mb-0'>CPF: {numberToCpf(order.customer.doc)}</p>
                          )}
                          {order?.customer.birthDate && (
                            <p className='mb-0'>
                              Data de nascimento: {toDate(order.customer.birthDate)}
                            </p>
                          )}
                          {order?.content.externalCodeERP && (
                            <p className='mb-0'>
                              {i18n.t(`reports.external_code`)}: {order.content.externalCodeERP}
                            </p>
                          )}
                        </div>

                        {order.content && order?.content.code === 'delivery' && order.content.orderAddress && (
                          <div className='mt-8'>
                            <p className='mb-0'>
                              <b>Endereço de entrega</b>
                            </p>
                            <p className='mb-0'>
                              {`${order.content.orderAddress.address} ${order.content.orderAddress.number
                                ? ', ' + order.content.orderAddress.number
                                : ''
                                }`}
                            </p>
                            <p className='mb-0'>
                              {order.content.orderAddress.cep &&
                                `
                                        ${order.content.orderAddress.city} 
                                        ${order.content.orderAddress.state
                                  ? ' - ' + order.content.orderAddress.state
                                  : ''
                                }
                                        ${order.content.orderAddress.cep.substring(0, 5) +
                                '-' +
                                order.content.orderAddress.cep.substring(5, 8)
                                }
                                        `}
                            </p>
                          </div>
                        )}
                        {order.content && order?.content.code !== 'internal-delivery' && (
                          <div className='mt-8'>
                            <p className='mb-0'>
                              <b>{i18n.t(`reports.billing_address`)}</b>
                            </p>
                            {order.customer &&
                              <>
                                <p className='mb-0'>
                                  {`${order.customer.customerAddresses[0].street} ${order.customer.customerAddresses[0].number
                                    ? ', ' + order.customer.customerAddresses[0].number
                                    : ''
                                    }`}
                                </p>

                                <p className='mb-0'>
                                  {order.customer.customerAddresses[0].zipCode &&
                                    `
                                          ${order.customer.customerAddresses[0].city} 
                                          ${order.customer.customerAddresses[0].state
                                      ? ' - ' + order.customer.customerAddresses[0].state
                                      : ''
                                    }
                                          ${order.customer.customerAddresses[0].zipCode.substring(0, 5) +
                                    '-' +
                                    order.customer.customerAddresses[0].zipCode.substring(5, 8)
                                    }
                                          `}
                                </p>
                              </>
                            }
                          </div>
                        )}
                      </> :
                      (
                        <>
                          {order.account ?
                            <>
                              <p className='mb-0'>
                                Consumidor do quarto {order.account}
                              </p>

                            </> :
                            <>
                              <p className='mb-0'>
                                Consumidor inexistente, pedido feito na mesa ou comanda.
                              </p>
                            </>
                          }
                        </>
                      )
                    }
                  </div>
                </div>
                {order?.customerCart?.seller &&
                  <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.seller`)}</span>
                      </h3>
                    </div>
                    <div className='card-body'>
                      <div>
                        <p className='mb-0'>
                          <b>{order?.customerCart?.seller.name}</b>
                        </p>
                        {order?.customerCart?.seller.email && (
                          <p className='mb-0'>
                            Email: {order.customerCart?.seller.email}
                          </p>
                        )}
                        {order?.customerCart?.seller.cpf && (
                          <p className='mb-0'>CPF: {numberToCpf(order.customerCart?.seller.cpf)}</p>
                        )}

                      </div>
                    </div>
                  </div>
                }

                {order.content && (
                  <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.modality`)}:
                          {i18n.t(`reports.${order.content.code.replace('-', '_')}`)}
                        </span>
                      </h3>
                    </div>
                    <div className='card-body'>
                      <div>
                        <p className='mb-0'>
                          <b>{order.content.code === 'withdraw' || order.content.code === 'table' ? i18n.t(`reports.responsible_withdraw`) : i18n.t(`reports.responsible_delivery`)}</b>
                        </p>
                        {order.content.orderAddress && order.content.orderAddress.otherPersonToWithdrawal ? (
                          <>
                            <p className='mb-0'>{order.content.orderAddress.otherPersonToWithdrawal.name}</p>
                            <p className='mb-0'>CPF: {order.content.orderAddress.otherPersonToWithdrawal.cpf}</p>
                          </>
                        ) : (
                          <>
                            <p className='mb-0'>
                              {(order.customer && order.customer.name) && order.customer.name}
                            </p>
                            <p className='mb-0'>CPF: {(order.customer && order.customer.doc) && numberToCpf(order.customer.doc)}</p>
                          </>
                        )}
                      </div>
                      {order.content && order.content.orderAddress && (
                        <div className='mt-8'>
                          <p className='mb-0'>
                            <b>{order.content.code === 'withdraw' || order.content.code === 'table' ? i18n.t(`reports.address_withdraw`) : i18n.t(`reports.address_delivery`)}</b>
                          </p>
                          {order.content.code === 'internal-delivery' ? (
                            <p className='mb-0'>
                              {`${order.content.orderAddress.complement
                                ? order.content.orderAddress.complement
                                : ''
                                }`}
                            </p>
                          ) : (
                            <>
                              <p className='mb-0'>
                                {`${order.content.orderAddress.address} ${order.content.orderAddress.number
                                  ? ', ' + order.content.orderAddress.number
                                  : ''
                                  }`}
                              </p>
                              <p className='mb-0'>
                                {order.content.orderAddress.cep &&
                                  `
                                          ${order.content.orderAddress.city} 
                                          ${order.content.orderAddress.state
                                    ? ' - ' + order.content.orderAddress.state
                                    : ''
                                  }
                                          ${order.content.orderAddress.cep.substring(0, 5) +
                                  '-' +
                                  order.content.orderAddress.cep.substring(5, 8)
                                  }
                                          `}
                              </p>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.products`)}</span>
                    </h3>
                  </div>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-3'>
                        <b>{i18n.t(`reports.quantity`)}</b>
                      </div>
                      <div className='col-5'>
                        <b>{i18n.t(`reports.product`)}</b>
                      </div>
                      <div className='col-2'>
                        <b>{i18n.t(`reports.value`)}</b>
                      </div>
                      <div className='col-2'>
                        <b>{i18n.t(`reports.total`)}</b>
                      </div>
                    </div>
                    {order.smartOrder.map((product) => (
                      <>
                        <div className='row mt-4'>
                          <div className='col-3'>
                            <p className='mb-0'>{product.quantity}</p>
                          </div>
                          <div className='col-5'>
                            <p className='mb-0'>
                              #{product.sku} {product.content.productName}
                            </p>
                            {product.variationHtml && (
                              <div dangerouslySetInnerHTML={{ __html: product.variationHtml }} />
                            )}
                            {product.additionalHtml && (
                              <div dangerouslySetInnerHTML={{ __html: product.additionalHtml }} />
                            )}
                            {product.fractionalHtml && (
                              <div dangerouslySetInnerHTML={{ __html: product.fractionalHtml }} />
                            )}
                            {product.content.observation && (
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '2px'
                                }}
                              >
                                <span className=''
                                  style={{
                                    fontSize: '11px',
                                    gap: '2px'
                                  }}
                                >Obs:
                                </span>
                                <span
                                  style={{
                                    fontSize: '11px',
                                    textDecoration: 'underline'
                                  }}>
                                  {product.content.observation}
                                </span>
                              </div>
                            )

                            }
                          </div>
                          <div className='col-2'>
                            <p className='mb-0'>
                              {toMoney((product.price / 100).toFixed(2).toString())}
                            </p>
                            {parseFloat(product.discount) ? (
                              <p className='mb-0' style={{ textDecoration: 'line-through' }}>
                                {toMoney(
                                  ((parseFloat(product.discount) + product.price) / 100)
                                    .toFixed(2)
                                    .toString()
                                )}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className='col-2'>
                            <p className='mb-0'>
                              {toMoney(
                                ((product.price * product.quantity) / 100).toFixed(2).toString()
                              )}
                            </p>
                            {parseFloat(product.discount) ? (
                              <p className='mb-0' style={{ textDecoration: 'line-through' }}>
                                {toMoney(
                                  (
                                    ((parseFloat(product.discount) + product.price) *
                                      product.quantity) /
                                    100
                                  )
                                    .toFixed(2)
                                    .toString()
                                )}
                              </p>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                    <div className='row mt-4'>
                      <div className='col-3'></div>
                      <div className='col-5'></div>
                      <div className='col-2'>
                        <p className='mb-0'>{i18n.t(`reports.product_value`)}</p>
                      </div>
                      <div className='col-2'>
                        <p className='mb-0'>
                          {toMoney((order.totalWithoutDiscount / 100).toFixed(2).toString())}
                        </p>
                      </div>
                    </div>
                    {order.content && order?.content.code === 'delivery' && order.deliveryTax && (
                      <div className='row mt-4'>
                        <div className='col-3'></div>
                        <div className='col-5'></div>
                        <div className='col-2'>
                          <p className='mb-0'>Frete</p>
                        </div>
                        <div className='col-2'>
                          <p className='mb-0'>
                            {toMoney((parseFloat(order.deliveryTax) / 100).toFixed(2).toString())}
                          </p>
                        </div>
                      </div>
                    )}

                    <div className='row mt-4'>
                      <div className='col-3'></div>
                      <div className='col-5'></div>
                      <div className='col-2'>
                        <p className='mb-0'>{i18n.t(`reports.discount`)}</p>
                      </div>
                      <div className='col-2'>
                        <p className='mb-0'>
                          {toMoney(
                            ((order.totalWithoutDiscount - order.total) / 100).toFixed(2).toString()
                          )}
                        </p>
                      </div>
                    </div>

                    <div className='row mt-4'>
                      <div className='col-3'></div>
                      <div className='col-5'></div>
                      <div className='col-2'>
                        <b className='mb-0'>Total</b>
                      </div>
                      <div className='col-2'>
                        <p className='mb-0'>
                          {toMoney(
                            (
                              (order.total +
                                parseFloat(order.deliveryTax ? order.deliveryTax : '0')) /
                              100
                            )
                              .toFixed(2)
                              .toString()
                          )}
                        </p>
                      </div>
                    </div>

                    <div className='row mt-2'>
                      <p><b>Observação: </b> {order.observation}</p>
                    </div>

                  </div>
                </div>
              </div>
              <div className='d-flex flex-column' style={{ flex: 1 }}>
                <div className='card mb-5 mb-xl-12'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`general.actions`)}</span>
                    </h3>
                  </div>
                  <div className='card-body'>
                    <div className='d-flex flex-row gap-4 align-items-end'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label={i18n.t(`reports.order_status`)}
                        options={order.status.map((status) => ({
                          value: status.id,
                          select: status.name,
                        }))}
                        value={statusSelected}
                        change={(value: string) => setStatusSelected(parseInt(value))}
                      />
                      <Button
                        color='primary'
                        text={i18n.t(`buttons.save`)}
                        disabled={!order.content ? true : false}
                        btnClass='h-40px d-flex flex-row align-items-center'
                        click={() => {
                          if (!order.content) {
                            Swal.fire({
                              icon: 'warning',
                              title: 'Atenção!',
                              text: 'Impossível alterar status desse pedido',
                            })
                          } else if (order?.statusId && statusSelected < order?.statusId) {
                            showWrapperModal('Alterar status do pedido')
                          } else {
                            saveOrder()
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.order_status`)}</span>
                    </h3>
                  </div>
                  <div className='card-body d-flex flex-column-reverse'>
                    {order.content ? order.orderStatus.map((orderSt) => (
                      <div className='d-flex flex-row align-items-center mt-4'>
                        <div className='col-3'>
                          <p className='mb-0'>{toDateTime(orderSt.created)}</p>
                        </div>
                        <div
                          style={{ fontSize: 12, color: orderSt.color }}
                          className='d-flex flex-row align-items-center'
                        >
                          <div
                            className='d-flex flex-row align-items-center justify-content-center'
                            style={{
                              border: `1px solid ${orderSt.color}`,
                              borderRadius: '100%',
                              width: '16px',
                              height: '16px',
                              textAlign: 'center',
                            }}
                          >
                            {orderSt.order}
                          </div>
                          <p className='mb-0' style={{ marginLeft: 5 }}>
                            {orderSt.status}
                          </p>
                        </div>
                      </div>
                    )) :
                      <p className='mb-0'>
                        Pedido finalizado em mesa ou comanda.
                      </p>
                    }
                  </div>
                </div>
                {clearsaleOrder &&
                  <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bolder fs-3 mb-1'>Status da ClearSale</span>
                      </h3>
                    </div>
                    <div className='card-body'>
                      {clearsaleOrder.code &&
                        <div>
                          <p className='mb-0'>
                            <b>Code:</b>{` ${clearsaleOrder.code}`}
                          </p>
                        </div>
                      }
                      {clearsaleOrder.status &&
                        <div className='mt-2'>
                          <p className='mb-0'>
                            <b>Status:</b>{` ${clearsaleOrder.status}`}
                          </p>
                        </div>
                      }
                      {clearsaleOrder.score &&
                        <div className='mt-2'>
                          <p className='mb-0'>
                            <b>Score:</b>{` ${clearsaleOrder.score}`}
                          </p>
                        </div>
                      }
                      <div className='d-flex flex-row gap-4 align-items-end mt-4'>
                        <Button
                          color='primary'
                          text='Atualizar Pedido'
                          btnClass='h-40px d-flex flex-row align-items-center'
                          click={async () => await getClearsaleOrder(order)}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.payment_method`)}</span>
                    </h3>
                  </div>
                  <div className='card-body'>
                    {order.content ?
                      <>
                        {order.content && order.content.dataGateway && order.content.dataGateway.flagIdentifier === 'payOffline' && (
                          <div>
                            <b>Pagamento na Entrega</b>
                            <p className='mb-0'>
                              Pedido <b>{order.content.dataGateway.orderIdExt}</b>
                            </p>
                          </div>
                        )}

                        {order.content && order.content.dataGateway && order.content.dataGateway.flagIdentifier === 'pix' && (
                          <div>
                            <b>Pagamento via PIX</b>
                            <p className='mb-0'>
                              Pedido <b>{order.content.dataGateway.orderIdExt}</b>
                            </p>
                          </div>
                        )}

                        {order.content && order.content.dataGateway && order.content.dataGateway.flagIdentifier !== 'pix' &&
                          order.content.dataGateway.flagIdentifier !== 'payOffline' && (
                            <div>
                              <b>Cartão de crédito</b>
                              <p className='mb-0'>
                                Cartão {order.content.dataGateway.cardTicket}
                                {order.content.dataGateway.flagIdentifier ? '' : ''}
                              </p>
                            </div>
                          )}

                        {order.content && order.content.dataGateway && (
                          <div className='mt-4'>
                            <p className='mb-0'>
                              <b>NSU:</b>{' '}
                              {order.content.dataGateway.nsu
                                ? order.content.dataGateway.nsu
                                : order.content.dataGateway.payment.nsu}
                            </p>
                            {order.content.dataGateway.authorization && (
                              <p className='mb-0'>
                                <b>Código de autorização:</b> {order.content.dataGateway.authorization}
                              </p>
                            )}
                          </div>
                        )}
                      </>
                      :
                      <p className='mb-0'>
                        Forma de pagamento inexistente, pedido feito na mesa ou comanda.
                      </p>
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
          <ModalWrapper confirmAction={() => saveOrder()}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='modal-body text-break pt-0'>
                <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-2 px-10'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className={`svg-icon`}
                    svgClassName={`h-100px w-100px`}
                  />
                  <p className='mt-2'>O status desejado é anterior ao que ele já está.</p>
                  <p>Deseja mesmo alterar este item?</p>
                </div>
              </div>
            </div>
          </ModalWrapper>
          <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
            <Button
              text={i18n.t(`buttons.back`)}
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/order/saleorder')
              }}
              btnClass='fw-bolder me-3'
            />
          </div>
        </div >
      )}
    </>
  )
}

const PedidosView: FC = () => {
  const { id } = useParams()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`reports.order_report`), path: 'order/saleorder', isActive: true },
          { title: `${i18n.t(`reports.order`)} #${id}`, path: `order/saleorder/${id}`, isActive: true },
        ]}
      >
        {i18n.t(`reports.order_report`)}
      </PageTitle>
      <PedidosViewContent />
    </>
  )
}

export { PedidosView }
