/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, POST } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'

import Swal from 'sweetalert2'
import { floatToPrice } from '../../../../functions/price'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { getGmtStore } from '../../../../utils/utils'
import { ModalImagemCropped } from '../../../../components/Modals/ImagemCropped/ImagemCropped'
import useModals from '../../../../hooks/useModals'
import { removeEspacosEmBranco } from '../../../../utils/formater'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ProductType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

type SelectType = {
  value: string
  select: string
}

type ProductFractionedType = {
  id: string
  code: string
  name: string
  price: number
}

const itemMenu = {
  height: '100%',
  padding: '20px 20px 0',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  borderBottom: '4px solid #FFFFFF',
}

const itemMenuActive = {
  ...itemMenu,
  color: '#6993ff',
  borderBottom: '4px solid #6993ff',
}

const itemMenuInactive = {
  ...itemMenu,
  color: 'black',
  borderBottom: '4px solid #CCCCCC',
  marginLeft: 5,
  marginRight: 5,
}


const PageContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal, showImageCropped } = useModals()

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('#tab1')

  const [type, setType] = useState<string>('product')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [status, setStatus] = useState<boolean>(true)
  const [description, setDescription] = useState<string>('')

  const [listClassification, setListClassification] = useState<any[]>([])

  const [categoryId, setCategoryId] = useState<string>('0')
  const [categories, setCategories] = useState<any[]>([])
  const [subcategoryId, setSubCategoryId] = useState<string>('0')
  const [subcategories, setSubCategories] = useState<any[]>([])

  const [image, setImage] = useState<File | null>(null)

  const [imageCrop, setImageCrop] = useState<string | null>('')
  const [isCropped, setCropper] = useState<any>(null)

  const [markId, setMarkId] = useState<string>('0')
  const [locations, setLocations] = useState<any[]>([])
  const [measurementId, setMeasurementId] = useState<string>('0')
  const [order, setOrder] = useState<string>('0')
  const [extraImages, setExtraImages] = useState<FileList>()

  const [pricing, setPricing] = useState<null | string>('proportional')
  const [qtdProductsFractioned, setQtdProductsFractioned] = useState(1)
  const [showPriceFractioned, setShowPriceFractioned] = useState(false)
  const [sellAsFractioned, setSellAsFractioned] = useState(false)
  const [productsFractioned, setProductsFractioned] = useState<ProductFractionedType[]>([])
  const [searchProducts, setSearchProducts] = useState<ProductFractionedType[]>([])
  const [allProductsFractioned, setAllProductsFractioned] = useState<ProductFractionedType[]>([])

  const [initialHour, setInitialHour] = useState<null | string>(null)
  const [finalHour, setFinalHour] = useState<null | string>(null)

  type OptionalType = {
    name: string
    type: string
    items: {
      name: string
    }[]
  }

  const [optionals, setOptionals] = useState<OptionalType[]>([])

  const [optionalName, setOptionalName] = useState<string>('')
  const [optionalType, setOptionalType] = useState<string>('')

  const [canAlert, setCanAlert] = useState(false);

  const getLocations = async () => {
    try {
      const res = await GET('/location')
      let _locations: { id: number; code: string; name: string; price: number; status: boolean }[] =
        []
      res.data
        .filter((c: any) => c.type === 'internal')
        .map((item: any) => {
          _locations.push({
            id: item.id,
            code: item.code,
            name: item.name,
            price: 0,
            status: true,
          })
        })
      setLocations(_locations)
    } catch (e) {
      console.log(e)
    }
  }

  const getCategories = async () => {
    try {
      const res = await POST(`/classification/filter`, {
        status: true,
        /*O Campo abaixo só é utilizando para o Hotel, 
        nessa rota a troca do pluginType dele ocorre usando esse campo 
        */
        plugintypeId: 7
      })
      const newList = listClassification
      res.data.map((row: ProductType) => {
        newList.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: row.name,
        })
      })
      setListClassification(newList)
      const cat = newList.filter(function (row) {
        return row.parent === null || row.parent == ''
      })

      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      cat
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
        .map((row: any, idx) => {
          categories.push({
            value: row.code,
            select: row.name,
            key: idx,
          })
        })
      setCategories(categories)
      setSubCategories([
        {
          value: '0',
          select: 'Selecione',
        },
      ])
    } catch (e) {
      console.log(e)
    }
  }

  const getSubCategories = async (code: string) => {
    const subcategories = [
      {
        value: '0',
        select: 'Selecione',
      },
    ]
    const sub = listClassification.filter(function (row) {
      return row.parent == code
    })

    sub
      .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
      .map((row: any) => {
        subcategories.push({
          value: row.id,
          select: row.name,
        })
      })
    setSubCategories(subcategories)
  }

  const getProducts = async () => {
    try {
      const res = await GET('/product/fractioned')
      const newList: ProductFractionedType[] = []
      res.data.map((row: any) => {
        newList.push({
          id: row.id,
          name: row.name,
          code: row.code,
          price: row.variations[0].price,
        })
      })
      setAllProductsFractioned(newList)
    } catch (e) {
      console.log(e)
    }
  }

  const createProduct = async () => {
    try {
      if (categoryId === '0' || subcategoryId === '0' || !name) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        setLoading(true)
        let variations: any[] = []
        locations.map((location, i) => {
          return variations.push({
            locationId: location.id,
            productId: 0,
            sku: `${code}-${location.id}`,
            price: location.price,
            status: location.status,
            inventory: location.inventory ? location.inventory : 0,
            minimumStock: 0,
            gtin: undefined,
            mpn: undefined,
            ncm: undefined,
            costPrice: 0,
            promotionalPrice: 0,
            width: 0,
            height: 0,
            weight: 0,
            depth: 0,
            referenceCode: code,
            context: { VARIATION: 'NONE' },
            img: '',
          })
        })
        const gmtStore = await getGmtStore()
        const data = {
          name: removeEspacosEmBranco(name),
          description: description,
          order: parseInt(order),
          code: code ?? '',
          classificationId: parseInt(subcategoryId),
          classificationCode: '',
          type: type,
          status: status,
          measurementUnitId: parseInt(measurementId),
          measurementUnitCode: '',
          parent: '',
          markId: parseInt(markId),
          markCode: '',
          typeFractional: type === 'fractional' ? pricing : null,
          quantityFractional: type === 'fractional' ? qtdProductsFractioned : null,
          sellAsFractional: type !== 'fractional' ? sellAsFractioned : false,
          variations,
          fractions: productsFractioned.map((prod) => ({
            productFractionalId: prod.id,
          })),
          showFractionalPrice: type === 'fractional' ? showPriceFractioned : false,
          initialHour: initialHour
            ? initialHour.includes('+')
              ? initialHour.split('+')[0] + gmtStore
              : initialHour.includes('-')
                ? initialHour.split('-')[0] + gmtStore
                : initialHour + gmtStore
            : null,
          finalHour: finalHour
            ? finalHour.includes('+')
              ? finalHour.split('+')[0] + gmtStore
              : finalHour.includes('-')
                ? finalHour.split('-')[0] + gmtStore
                : finalHour + gmtStore
            : null,
        }

        const resProd = await POST('/product', data)
        if (resProd.success === true) {
          let imageRes = {
            success: false,
            image: '',
          }
          if (image) {
            const formData = new FormData()
            formData.append('file', image)
            formData.append('nameImage', `product-${uuid()}.jpg`)
            imageRes = await POST('/upload-images/upload-one', formData)
          }
          if (imageRes.success === true) {
            const data = [
              {
                path: imageRes.image,
                productId: resProd.dados.id,
                typeimg: 'imgsmall',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: resProd.dados.id,
                typeimg: 'mainpicture',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: resProd.dados.id,
                typeimg: 'thumbpicture',
                order: 0,
              },
            ]
            if (extraImages) {
              await Promise.all(
                Array.from(extraImages).map(async (image, index) => {
                  let imageExtraRes = {
                    success: false,
                    image: '',
                  }
                  const formData = new FormData()
                  formData.append('file', image)
                  formData.append('nameImage', `product-${uuid()}.jpg`)
                  imageExtraRes = await POST('/upload-images/upload-one', formData)
                  if (imageExtraRes.success === true) {
                    data.push({
                      path: imageExtraRes.image,
                      productId: resProd.dados.id,
                      typeimg: 'image',
                      order: 0,
                    })
                  }
                })
              )
            }
            await POST('/productimage/multiple', {
              data,
            })
          }
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Produto cadastrado',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/product_food'))
        } else {
          setCanAlert(true)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar o produto!!!',
          })
        }
        setLoading(false)
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o produto!',
      })
    }
  }
  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        await getCategories()
        await getLocations()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }    
    const productFood = localStorage.getItem('product-food-saved')
    if(productFood && JSON.parse(productFood)) loadLocal()
    else load()
  }, [])

  useEffect(() => {
    return () => {
      if(canAlert && (!window.location.pathname.includes('/product_food/criar') && !window.location.pathname.includes('/product_hospitality/criar'))){
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate('/product_food/criar')
          } else {
            localStorage.removeItem('product-food-saved')
          }
        })
      } else if(!canAlert) localStorage.removeItem('product-food-saved')
    } 
  }, [window.location.pathname, canAlert])

  const addOptional = () => {
    if (optionalName && optionalType) {
      let _optionals = [...optionals]
      _optionals.push({
        name: optionalName,
        type: optionalType,
        items: [],
      })
      setOptionals(_optionals)
      setOptionalName('')
      setOptionalType('')
    }
  }

  const removeOptional = (index: number) => {
    let _optionals = [...optionals]
    if (_optionals[index]) {
      _optionals.splice(index, 1)
      setOptionals(_optionals)
    }
  }

  const addOptionalItem = (index: number) => {
    let _optionals = [...optionals]
    _optionals[index].items.push({ name: '' })
    setOptionals(_optionals)
  }

  const removeProductFractioned = (idDeleted: string) => {
    const newProductsFractioned = [...productsFractioned]
    setProductsFractioned(newProductsFractioned.filter((p) => p.id !== idDeleted))
  }
  const addProductFractioned = () => {
    const newProductsFractioned = [...productsFractioned]
    searchProducts.forEach((product) => {
      if(!newProductsFractioned.includes(product)) newProductsFractioned.push(product);
    })
    setProductsFractioned(newProductsFractioned)
  }

  const getCropData = async () => {
    if (isCropped) {
      const file = await fetch(isCropped.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], 'newAvatar.png', { type: 'image/png' })
        })

      setImage(file)
      setImageCrop('')
    }
  }

  const getImagetoCrop = (e: any) => {
    if (e?.target.files) {
      showImageCropped()
      setImageCrop(URL.createObjectURL(e.target.files[0]))
    }
  }

  const validateTime = (value: string) => {
    if (value) {
      value = value.replaceAll('_', '0')
      const values = value.split(':')
      let val1 = parseInt(values[0]) > 23 ? 23 : values[0]
      let val2 = parseInt(values[0]) > 23 || parseInt(values[1]) > 59 ? 59 : values[1]
      let val3 = parseInt(values[0]) > 23 || parseInt(values[2]) > 59 ? 59 : values[2]
      value = `${val1}:${val2}:${val3}`
    }
    return value
  }

  const loadLocal = () => {
    const productFood = localStorage.getItem('product-food-saved')
    if(productFood && JSON.parse(productFood)){
      const jsonProductFood = JSON.parse(productFood);
      setActiveTab(jsonProductFood.activeTab)
      setType(jsonProductFood.type)
      setName(jsonProductFood.name)
      setCode(jsonProductFood.code)
      setStatus(jsonProductFood.status)
      setDescription(jsonProductFood.description)
      setListClassification(jsonProductFood.listClassification)
      setCategoryId(jsonProductFood.categoryId)
      setCategories(jsonProductFood.categories)
      setSubCategoryId(jsonProductFood.subcategoryId)
      setSubCategories(jsonProductFood.subcategories)
      setMarkId(jsonProductFood.markId)
      setLocations(jsonProductFood.locations)
      setMeasurementId(jsonProductFood.measurementId)
      setOrder(jsonProductFood.order)
      setPricing(jsonProductFood.pricing)
      setQtdProductsFractioned(jsonProductFood.qtdProductsFractioned)
      setShowPriceFractioned(jsonProductFood.showPriceFractioned)
      setSellAsFractioned(jsonProductFood.sellAsFractioned)
      setProductsFractioned(jsonProductFood.productsFractioned)
      setSearchProducts(jsonProductFood.searchProducts)
      setAllProductsFractioned(jsonProductFood.allProductsFractioned)
      setInitialHour(jsonProductFood.initialHour)
      setFinalHour(jsonProductFood.finalHour)
      localStorage.removeItem('product-food-saved')
    }
  }

  const saveLocal = () => {
    localStorage.setItem('product-food-saved', JSON.stringify({
      activeTab,
      type,
      name,
      code,
      status,
      description,
      listClassification,
      categoryId,
      categories,
      subcategoryId,
      subcategories,
      markId,
      locations,
      measurementId,
      order,
      pricing,
      qtdProductsFractioned,
      showPriceFractioned,
      sellAsFractioned,
      productsFractioned,
      searchProducts,
      allProductsFractioned,
      initialHour,
      finalHour
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    activeTab,
    type,
    name,
    code,
    status,
    description,
    listClassification,
    categoryId,
    categories,
    subcategoryId,
    subcategories,
    markId,
    locations,
    measurementId,
    order,
    pricing,
    qtdProductsFractioned,
    showPriceFractioned,
    sellAsFractioned,
    productsFractioned,
    searchProducts,
    allProductsFractioned,
    initialHour,
    finalHour
  ])

  const handleUnload = (e:BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('product-food-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-content-between'>
            <div className='w-100'>
              <div className='card'>
                <div className='d-flex' style={{ padding: '0 2.25rem', height: 75 }}>
                  <div
                    style={activeTab === '#tab1' ? itemMenuActive : itemMenuInactive}
                    onClick={() => setActiveTab('#tab1')}
                  >
                    <span>Informações básicas</span>
                  </div>
                  {/* <div style={activeTab === '#tab2' ? itemMenuActive : itemMenuInactive} onClick={() => setActiveTab('#tab2')}>
                    <span>Opcionais e extras</span>
                  </div> */}
                  {type === 'fractional' && (
                    <div
                      style={activeTab === '#tab3' ? itemMenuActive : itemMenuInactive}
                      onClick={async () => {
                        setActiveTab('#tab3')
                        if(!productsFractioned || productsFractioned.length === 0) {
                          setLoading(true)
                          await getProducts()
                          setLoading(false)
                        }
                      }}
                    >
                      <span>Produto fracionado</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {activeTab === '#tab1' && (
            <>
              <div className='d-flex justify-content-between flex-wrap w-100 gap-5'>
                <div style={{ flex: 1 }}>
                  <div className='card pb-5 h-100'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-4 mb-1'>Informações básicas</span>
                        <BreadCrumbs />
                      </h3>
                    </div>
                    <div className='px-10 py-5'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Tipo'
                        options={[
                          { select: 'Adicional', value: 'optional' },
                          { select: 'Produto', value: 'product' },
                          { select: 'Produto e Adicional', value: 'product_optional' },
                          { select: 'Fracionado', value: 'fractional' },
                        ]}
                        value={type}
                        change={(value: string) => {
                          setCanAlert(true)
                          setType(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Nome'
                        label='Nome'
                        value={name}
                        change={(value: string) => {
                          setCanAlert(true)
                          setName(value)
                        }}
                      />

                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Código'
                        label='Código'
                        value={code}
                        change={(value: string) => {
                          setCanAlert(true)
                          setCode(value)
                        }}
                      />

                      {type !== 'fractional' && (
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          checked={sellAsFractioned}
                          change={(value: boolean) => {
                            setCanAlert(true)
                            setSellAsFractioned(value)
                          }}
                          label='Vender como fracionado'
                        />
                      )}

                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Status'
                        options={[
                          { select: 'Ativo', value: true },
                          { select: 'Inativo', value: false },
                        ]}
                        value={status}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          setStatus(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'textarea',
                        }}
                        placeholder='Descrição'
                        label='Descrição'
                        value={description}
                        change={(value: string) => {
                          setCanAlert(true)
                          setDescription(value)}
                        }
                        rows={5}
                      />
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.order`)}
                        label={i18n.t(`smart_order.order`)}
                        smalltext='(para exibição no webapp)'
                        value={order}
                        change={(value: string) => {
                          setCanAlert(true)
                          setOrder(value)}
                        }
                      />
                      <div className='mt-6'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          value={initialHour}
                          change={(value: string) => {
                            setCanAlert(true)
                            setInitialHour(value)}
                          }
                          placeholder='00:00:00'
                          label={`${i18n.t(`smart_order.initial_hour`)}`}
                          blur={(value: string) => setInitialHour(validateTime(value))}
                        />
                      </div>
                      <div className='mt-6'>
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          value={finalHour}
                          change={(value: string) => {
                            setCanAlert(true)
                            setFinalHour(value)}
                          }
                          placeholder='00:00:00'
                          label={`${i18n.t(`smart_order.final_hour`)}`}
                          blur={(value: string) => setFinalHour(validateTime(value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ flex: 1 }}>
                  {type === 'fractional' ? (
                    <div className='card pb-5 mb-10'>
                      <div className='card-header py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-4 mb-1'>Configurações</span>
                        </h3>
                      </div>
                      <div className='px-10 py-5'>
                        <div className='row'>
                          <div className='col-lg-8'>
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              value={pricing}
                              label='Definir precificação'
                              options={[
                                { select: 'Cobrar valor proporcional', value: 'proportional' },
                                { select: 'Cobrar valor maior', value: 'biggest' },
                              ]}
                              change={(value: string | null) => {
                                setPricing(value)
                              }}
                            />
                            <p
                              style={{
                                fontSize: 12,
                                fontWeight: 300,
                                color: '#333333',
                                marginTop: 4,
                                marginLeft: 2,
                              }}
                            >
                              Cobrar valor proporcional / Cobrar valor maior
                            </p>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-8'>
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              value={qtdProductsFractioned}
                              label='Quantidade máxima de produtos'
                              options={[
                                { select: '1', value: 1 },
                                { select: '2', value: 2 },
                                { select: '3', value: 3 },
                                { select: '4', value: 4 },
                              ]}
                              change={(value: number) => {
                                setCanAlert(true)
                                setQtdProductsFractioned(value)
                              }}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-lg-12'>
                            <Input
                              inputAttr={{
                                type: 'checkbox',
                              }}
                              checked={showPriceFractioned}
                              change={(value: boolean) => {
                                setCanAlert(true)
                                setShowPriceFractioned(value)
                              }}
                              label='Exibir valor do fracionado'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className='card pb-5 mb-10'>
                      <div className='card-header py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-4 mb-1'>Preços</span>
                        </h3>
                      </div>
                      <div className='px-10 py-5'>
                        {locations.map((item, i) => (
                          <div key={`location-${i}`} className='row'>
                            <div className='col-lg-4'>
                              <Input
                                inputAttr={{
                                  type: 'select',
                                }}
                                value={item.status}
                                label='Status'
                                options={[
                                  { select: 'Ativo', value: true },
                                  { select: 'Inativo', value: false },
                                ]}
                                change={(value: boolean) => {
                                  setCanAlert(true)
                                  let _locations = [...locations]
                                  _locations[i].status = value
                                  setLocations(_locations)
                                }}
                              />
                            </div>
                            <div className='col-lg-4'>
                              <Input
                                inputAttr={{
                                  type: 'money',
                                }}
                                currency='BRL'
                                locale='pt-BR'
                                placeholder={item.name}
                                label={item.name}
                                value={item.price}
                                change={(value: number) => {
                                  setCanAlert(true)
                                  let _optionals = [...locations]
                                  _optionals[i].price = value ? value : 0
                                  setLocations(_optionals)
                                }}
                              />
                            </div>
                            <div className='col-lg-4'>
                              <Input
                                inputAttr={{
                                  type: 'number',
                                }}
                                placeholder='Estoque'
                                label='Estoque'
                                value={item.inventory}
                                change={(value: string) => {
                                  setCanAlert(true)
                                  let _optionals = [...locations]
                                  _optionals[i].inventory = value ? parseInt(value) : 0
                                  setLocations(_optionals)
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <div className='card pb-5 mb-10'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-4 mb-1'>Categorias</span>
                      </h3>
                    </div>
                    <div className='px-10 py-5'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Categoria'
                        options={categories}
                        value={categoryId}
                        change={(value: string) => {
                          setCategoryId(value)
                          getSubCategories(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Subcategoria'
                        options={subcategories}
                        value={subcategoryId}
                        change={(value: string) => {
                          setCanAlert(true)
                          setSubCategoryId(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='card pb-5'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-4 mb-1'>Imagens do produto</span>
                      </h3>
                    </div>
                    <div className='d-flex justify-content-center w-100'>
                      <div className='px-10 py-5'>
                        {
                          /*
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      value={image}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                    */
                          <>
                            <Input
                              inputAttr={{
                                type: 'image',
                              }}
                              value={image}
                              change={(value: BaseSyntheticEvent) => {
                                setCanAlert(true)
                                setImage(value ? value.target.files[0] : null)
                                if (value) {
                                  getImagetoCrop(value ? value : null)
                                } else {
                                  setImageCrop('')
                                  getImagetoCrop(null)
                                }
                              }}
                            />
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  text='Voltar'
                  color='info'
                  size='small'
                  horizontalPadding={5}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    navigate('/product_food')
                  }}
                  btnClass='fw-bolder me-3'
                />
                <Button
                  text='Salvar'
                  color='primary'
                  size='small'
                  horizontalPadding={5}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    setCanAlert(false)
                    createProduct()
                  }}
                  btnClass='fw-bolder'
                />
              </div>
            </>
          )}

          {activeTab === '#tab2' && (
            <div className='d-flex justify-content-between'>
              <div className='w-100 me-5'>
                <div className='card pb-5'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Lista de Opcionais</span>
                    </h3>
                  </div>
                  <div>
                    {optionals.map((optional, i) => (
                      <div
                        key={`option-${i}`}
                        className={'px-10'}
                        style={{ borderBottom: '1px solid #eff2f5', paddingBottom: 20 }}
                      >
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='flex-grow-1'>
                            <Input
                              inputAttr={{
                                type: 'default',
                              }}
                              label='Nome lista'
                              value={optional.name}
                              change={(value: string) => {
                                let _optionals = [...optionals]
                                _optionals[i].name = value
                                setOptionals(_optionals)
                              }}
                            />
                          </div>
                          <div className='mx-5 flex-grow-1'>
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              label='Tipo'
                              options={[
                                { select: 'Selecione', value: '' },
                                { select: 'Extras', value: 'E' },
                                { select: 'Acompanhamentos', value: 'A' },
                              ]}
                              value={optional.type}
                              change={(value: string) => {
                                let _optionals = [...optionals]
                                _optionals[i].type = value
                                setOptionals(_optionals)
                              }}
                            />
                          </div>
                        </div>
                        {optional.items.length > 0 && (
                          <div>
                            {optional.items.map((item, j) => (
                              <Input
                                key={`optional-item${j}`}
                                inputAttr={{
                                  type: 'default',
                                }}
                                value={item.name}
                                change={(value: string) => {
                                  let _optionals = [...optionals]
                                  let _items = [..._optionals[i].items]
                                  _items[j].name = value
                                  _optionals[i].items = _items
                                  setOptionals(_optionals)
                                }}
                              />
                            ))}
                          </div>
                        )}
                        <div className=' d-flex justify-content-end py-6'>
                          <Button
                            text='Excluir lista'
                            color='danger'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                              type: 'button',
                            }}
                            click={() => {
                              removeOptional(i)
                            }}
                            btnClass='fw-bolder me-3'
                          />
                          <Button
                            text='Adicionar produto'
                            color='primary'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                              type: 'button',
                            }}
                            click={() => {
                              addOptionalItem(i)
                            }}
                            btnClass='fw-bolder'
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className='card-footer'>
                    <div className='d-flex justify-content-between'>
                      <div className='flex-grow-1'>
                        <Input
                          inputAttr={{
                            type: 'default',
                          }}
                          label='Nome lista'
                          value={optionalName}
                          change={(value: string) => {
                            setOptionalName(value)
                          }}
                        />
                      </div>
                      <div className='mx-5 flex-grow-1'>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          label='Tipo'
                          options={[
                            { select: 'Selecione', value: '' },
                            { select: 'Extras', value: 'E' },
                            { select: 'Acompanhamentos', value: 'A' },
                          ]}
                          value={optionalType}
                          change={(value: string) => {
                            setOptionalType(value)
                          }}
                        />
                      </div>
                    </div>
                    <div className=' d-flex justify-content-end py-6'>
                      <Button
                        text='Adicionar lista'
                        color='primary'
                        size='small'
                        horizontalPadding={5}
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          addOptional()
                        }}
                        btnClass='fw-bolder'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === '#tab3' && (
            <div className='d-flex justify-content-between'>
              <div className='w-100 me-5'>
                <div className='card pb-5'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Produto fracionado</span>
                    </h3>
                  </div>
                  <span
                    className='py-5'
                    style={{ padding: '0 2.25rem', color: '#9FA3B5', fontSize: 12, fontWeight: 400 }}
                  >
                    Crie listas com produtos adicionais e defina como eles serão exibidos junto ao
                    produto principal.
                  </span>
                  <div className='card-footer' style={{ borderTop: 'none' }}>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <h2 style={{ fontSize: 14, fontWeight: 600 }}>Produtos</h2>
                      {productsFractioned.map((product) => (
                        <div className='row' style={{ width: '70%' }}>
                          <div className='col col-lg-4'>
                            <span>{product.code} - {product.name}</span>
                          </div>
                          <div className='col col-lg-4'>
                            <span>{floatToPrice(product.price)}</span>
                          </div>
                          <div className='col col-lg-4'>
                            <span style={{ cursor: 'pointer' }}>
                              <i
                                className='fas fa-trash'
                                onClick={() => removeProductFractioned(product.id)}
                              />
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className=' d-flex justify-content-end py-6'>
                      <Button
                        text='Adicionar produto'
                        color='primary'
                        size='small'
                        horizontalPadding={5}
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          setSearchProducts([])
                          showWrapperModal('Adicionar produto')
                        }}
                        btnClass='fw-bolder'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <ModalWrapper
            confirmAction={() => addProductFractioned()}
            width={50}
            textAction='Adicionar'
            height={30}
          >
            <div className='d-flex flex-column justify-content-between align-items-center'>
              <div className='flex-grow-1' style={{width: '90%'}}>
                <Input
                  inputAttr={{
                    type: 'select',
                  }}
                  search
                  placeholder={'Comece a digitar para procurar um produto'}
                  options={
                    allProductsFractioned
                      .filter((product) => !productsFractioned.includes(product))
                      .filter((product) => !searchProducts.includes(product))
                      .map((product) => ({ value: product.id, select: `${product.code} - ${product.name}` }))
                  }
                  value={null}
                  change={(selectedProduct: SelectType) => {
                    const productToAdd = allProductsFractioned.find((product) => product.id === selectedProduct.value)
                    if (productToAdd) {
                      const newSearchProducts = [...searchProducts];
                      if (!newSearchProducts.includes(productToAdd)) {
                        newSearchProducts.push(productToAdd);
                        setSearchProducts(newSearchProducts)
                      }
                    }
                  }}
                />
              </div>
              <div className='flex-grow-1 mt-4' style={{ width: '90%' }}>
                {searchProducts.map((product) => (
                  <div className="d-flex flex-row align-items-center">
                    <span
                      className={`form-check-label fw-normal text-gray-800 ms-1`}
                    >
                      {product.name}
                    </span>
                    <i 
                      style={{marginLeft: '1rem', cursor: 'pointer'}} 
                      className='fas fa-times'
                      onClick={() => {
                        setSearchProducts(searchProducts.filter((productFilter) => product.id !== productFilter.id))
                      }}
                    >  
                    </i>
                  </div>
                ))}
              </div>
            </div>
          </ModalWrapper>
        </div>
      )}

      <ModalImagemCropped imageCrop={imageCrop} setCropper={setCropper} getCropData={getCropData} />
    </>
  )
}

const CriarProdutos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Produtos', path: 'product_food', isActive: true },
          { title: 'Criar', path: '#', isActive: true },
        ]}
      >
        Criar Produto
      </PageTitle>
      <PageContent />
    </>
  )
}

export { CriarProdutos }
