import clsx from 'clsx'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../app/components/Button/Button'
import { ConfigurationsList } from '../../../../app/components/Modals/Topbar/ConfigurationsList/ConfigurationsList'
import { LanguageList } from '../../../../app/components/Modals/Topbar/LanguageList/LanguageList'
import { PluginspaceList } from '../../../../app/components/Modals/Topbar/PluginspaceList/PluginspaceList'
import useAuth from '../../../../app/hooks/useAuth'
import styles from './Header.module.css'
import { ManualList } from '../../../../app/components/Modals/Topbar/ManualList/ManualList'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

const Topbar: FC = () => {
  const navigate = useNavigate()
  const { loginInfo, verifyAdm } = useAuth();

  const getLanguage = () => {
    const language = localStorage.getItem('i18nextLng');
    switch (language) {
      case 'pt-BR':
        return (<img
          className='h-20px w-20px rounded cursor-pointer'
          src='https://staging.ninegrid.com.br/public/novo/media/svg/flags/br.svg'
          alt=''
        />)
      case 'en-US':
        return (<img
          className='h-20px w-20px rounded cursor-pointer'
          src='https://staging.ninegrid.com.br/public/novo/media/svg/flags/en.svg'
          alt=''
        />)
      case 'es-ES':
        return (<img
          className='h-20px w-20px rounded cursor-pointer'
          src='https://staging.ninegrid.com.br/public/novo/media/svg/flags/es.svg'
          alt=''
        />)
      default:
        return (<img
          className='h-20px w-20px rounded cursor-pointer'
          src='https://staging.ninegrid.com.br/public/novo/media/svg/flags/br.svg'
          alt=''
        />)
    }
  }

  return (
    <div className='d-flex align-items-center justify-content-between flex-shrink-1'>
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <Button
            text='Configurações'
            color='info'
            iconColor='#FFF'
            icon='fas fa-bars'
            size='small'
            horizontalPadding={4}
            verticalPadding={3}
            btnAttr={{
              type: 'button',
            }}
            click={() => {}}
            uppercase={true}
            btnClass='me-2 fs-thin'
          />
        </div>
        <ConfigurationsList
          data={[
            {name: 'Biblioteca de ícones', link: '/#'},
            {name: 'Biblioteca de ícones', link: '/#'},
            {name: 'Biblioteca de ícones', link: '/#'},
          ]}
        />
      </div> */}

      {verifyAdm() &&
        <Button
          text='Pluginspaces'
          color='info'
          iconColor='#FFF'
          icon='fas fa-store'
          size='small'
          horizontalPadding={4}
          verticalPadding={3}
          btnAttr={{
            type: 'button',
          }}
          click={() => navigate('/pluginspace')}
          uppercase={true}
          btnClass='mx-4 fs-thin'
        />
      }

      <Button
        text={loginInfo.pluginspace}
        color='primary'
        iconColor='#FFF'
        icon='fas fa-home'
        size='small'
        horizontalPadding={4}
        verticalPadding={3}
        btnAttr={{
          type: 'button',
        }}
        click={() => navigate('/dashboard')}
        uppercase={true}
        btnClass='mx-4 fs-thin'
      />



      {/*
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <Button 
            text="Ads Calçados"
            color="secondary"
            iconColor='#FFF'
            icon='fas fa-home'
            size='small'
            horizontalPadding={4}
            verticalPadding={3}
            btnAttr={{
              type: 'button'
            }}
            click={() => {}}
            uppercase={true}
            btnClass="me-2 fs-thin"
          />
        </div>
        <PluginspaceList data={[
          { color: "#000", name: "ADS Calçados" },
          { color: "#f00", name: "Bar do Adao" },
          { color: "#0f0", name: "Baroba" },
          { color: "#0ff", name: "Athuz" },
          { color: "#b0c", name: "Teste" },
          { color: "#ff3", name: "Bar do ze" },
        ]} />
      </div>
      */}

      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          data-kt-menu-trigger='hover'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          className='bg-hover-light w-40px h-40px rounded cursor-pointer d-flex align-items-center justify-content-center'
        >
          {getLanguage()}

        </div>
        <LanguageList
          data={[
            { name: 'Inglês', language: 'en' },
            { name: 'Espanhol', language: 'es' },
            { name: 'Português', language: 'br' },
          ]}
        />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          data-kt-menu-trigger='hover'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          className='bg-hover-light w-40px h-40px rounded cursor-pointer d-flex align-items-center justify-content-center'
        >

          <i className="bi bi-question-circle fs-2 text-gray-700"></i>
        </div>
        <ManualList />
      </div>


      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_sidebar_perfil_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          <div
            className='btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2 bg-hover-light'
            id='kt_quick_user_toggle'
          >
            <span className='text-muted fw-bold fs-base d-none d-md-inline me-1'>Olá,</span>
            <span className='text-gray-600 fw-bolder fs-base d-none d-md-inline me-3'>{loginInfo.name}</span>
          </div>
        </div>
        {/* end::Toggle */}
      </div>
      {/* end::User */}
    </div>
  )
}

export { Topbar }
