/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import type { MetaType } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, PUT, POST, DELETE } from '../../../services/api'
import useModals from '../../../hooks/useModals'
import { Loading } from '../../../components/Loading/Loading'
import { Dropdown } from 'react-bootstrap'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../components/Input/Input'
import Swal from 'sweetalert2'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import useAuth from '../../../hooks/useAuth'
//import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import uuid from "react-uuid"

type CategoryType = {
  id: number
  parent: string
  code: string
  name: string
  shortname: string
  order: 0
  img: string
  status: true
  description: string
  subitems: CategoryType[]
}

type segmentoAgregador = {
    name: string;
    order: number;
    image: string
    type: string;
    active: boolean;
}

type newSegmento = {
  pluginspaceId: number,
  segment: number,
  id: number
}

type categories = {
  value: number
  select: string
}

const Content: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const [fileToImport, setFileToImport] = useState<File | null>(null)
  const [hasIntegration, setHasIntegration] = useState(false)
  const [showCategoriesFiltro, setShowCategoriesFiltro] = useState<any[]>([])
  const [qtdCategorias, setqtdCategorias] = useState(0)
  const { loginInfo } = useAuth();
  const [categoriaFiltro, setCategoriaFiltro]  = useState<categories[]>([])
  const [filterCategoria, setFilterCategoria] = useState(
    {
      value: -1,
      label: 'Todas',
    },
  );
  

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const newCategorias = [...categories]
      newCategorias.forEach((categoria: any) => {
        if(categoria.id === Id) categoria.status = !categoria.status;
      })
      setCategories(newCategorias);
        const switchClassification = await PUT(`/classification/${Id}`, {
          status: !status,
      })
    } catch (e) {
      console.log(e)
    }
  }

  let filterData = (array: any, search: string, searchFields: any[]) => {
    const arrayCopy = JSON.parse(JSON.stringify(array))

    return arrayCopy.filter((item: any) => {
      let found = searchFields.some((field) => {
        return item[field].toLowerCase().indexOf(search.toLowerCase()) > -1
      })
      return found
    })
  }

  const getCategories = async ( search: string = '') => {
    try {
      const res = await POST(`/classification/filter`, {
        plugintypeId: getPlugintypeIdByCode('service'),
        deleted: 'not show',
      })

      const segmentosPluginSpace = await GET('pluginspacesegment');        

      let newSegmento = segmentosPluginSpace.data.filter((segment:newSegmento) => {
          if(segment.pluginspaceId === loginInfo.pluginspaceId) {
              return segment;
          }
        })
        
      newSegmento = newSegmento.map((segment:newSegmento) => {
          return segment.id.toString()
      })

      const newCategories = res.data.filter((row: CategoryType)=> row.parent === null).map((row: CategoryType) => {
        return {
          id: row.id,
          name: `${row.name}`,
          status: row.status,
          code: row.code,
          order: row.order,
          category: row.shortname,
          description: row.description,
          img: row.img,
        }
      })
      .filter((category: any) => category !== null);

      setShowCategoriesFiltro(newCategories);

      let filtroSelect: any = [];

      filtroSelect.unshift({
        value: -1,
        select: "Todas",
      })

      newCategories.forEach((categoria: any, index: number) => {
         filtroSelect.push({
          value: filtroSelect.length,
          select: categoria.name,
         })
      })

      //  Filtro para retirar duplicados

      const selectUnicos = new Set<string>();
      const objetosUnicos = filtroSelect.filter((obj: any)=> {
      if (!selectUnicos.has(obj.select)) {
          selectUnicos.add(obj.select);
            return true;
          }
            return false;
      });

      // 

      setCategoriaFiltro(objetosUnicos);


      const filterCategories = filterData(newCategories, search, ['name'])
      setCategories(
        filterCategories.sort((a: CategoryType, b: CategoryType) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        )
      )
      setqtdCategorias(res.meta.itemCount)
      setMeta(res.meta)
    } catch (e) {
      console.log(e)
    }
  }

  const deleteCategory = async () => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a categoria!',
      }).then(async () => await init())
    } else {
      try {
        setLoading(true)
        await edit(id);
        const res = await DELETE(`/classification/${id}`)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Categoria deletada',
            text: 'Deleção realizada com sucesso!',
          }).then(async () => await init())
        } else {
          console.log(res)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao deletar a categoria!',
          }).then(async () => await init())
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a categoria!',
        }).then(async () => await init())
        console.log(e)
      }
    }


  }

  const edit = async (id: any) => {
    try {
      await PUT(`/classification/${id}`, {
        code: uuid(),
      })
    } catch (error) {
      console.log(error);
    }
  }

  const showDeleteModal = () => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'A categoria será deletada e não será possível restaurar!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#7e8299',
      cancelButtonText: 'fechar!',
      confirmButtonText: 'Deletar!',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCategory()
      }
    })
  }

  const filterSetor = async (filtro: any) => {
    try {
      if(filtro.value === -1){
        setCategories([...showCategoriesFiltro])
      } else {
        const filtrados = showCategoriesFiltro.map(setor => {
          if(setor.name === filtro.label) return setor;
          else return null
        }).filter((setor: any) => setor !== null);
  
        setCategories(filtrados);
        
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    init()
  }, [])

  const init = async() => {
    setLoading(true)
    await getCategories();
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      showDeleteModal()
    }
  }, [id])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Categorias</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                    Aqui você configura as categorias do seu Agregador.
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/agregador/category/create')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-start pe-9 ps-9 pt-8 pb-8'>
              <div className=' align-items-end  justify-content-start me-5 flex-grow-1'>
                      <span className='align-self-start'>Setor</span>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        options={categoriaFiltro}
                        placeholder={'Categoria'}
                        search={true}
                        value={filterCategoria}
                        change={(value: any) => {
                          setFilterCategoria(value);
                        }}
                      />
              </div>

              <div
                    className='d-flex align-items-end w-50 justify-content-end h-100'
                    style={{
                      marginTop: '48px',
                    }}
              >
                    <Button
                      text='Filtrar'
                      color='primary'
                      iconColor='#FFF'
                      icon='fas fa-filter'
                      size='small'
                      horizontalPadding={6}
                      btnAttr={{
                        type: 'button',
                      }}
                      click={async () => {
                        setLoading(true);
                        await filterSetor(filterCategoria)
                        setLoading(false);
                      }}
                      btnClass='mb-0 fw-bolder'
                    />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={categories}
                headers={[
                  { key: 'Ícone', value: 'img', type: 'image', noOrder: true },
                  { key: 'Ordem', value: 'order', type: 'default' },
                  { key: 'Setor', value: 'name', type: 'default' },
                  { key: 'Categoria', value: 'category', type: 'default' },
                  // { key: 'Descrição', value: 'description', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => {
                      Swal.fire({
                          title: 'Você tem certeza?',
                          text: 'O status será alterado.',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: '#3085d6',
                          cancelButtonColor: '#d33',
                          confirmButtonText: 'Sim, alterar!',
                          cancelButtonText: 'Não, cancelar!',
                      }).then((result) => {
                          if (result.isConfirmed) {
                            switchStatus(item.id, item.status)
                          } else {
                            init();
                          }
                      })
                    },
                  },
                  { key: 'Ações', value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    title: 'Editar categoria',
                    action: (item) => {
                      navigate(`/agregador/category/editar/${item.id}`)
                    },
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      setId(item.id)

                    },
                    path: '',
                    title: 'Excluir categoria',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CategoriasAgregador: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Categorias', path: '/agregador/category', isActive: true},
        ]}
      >
        Categorias
      </PageTitle>
      <Content />
    </>
  )
}

export { CategoriasAgregador }
