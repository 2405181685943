import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { LineChart } from '../../../components/Charts/LineChart/LineChart'
import { DashboardTable } from '../../../components/Datatable/DashboardTable'
import { Loading } from '../../../components/Loading/Loading'
import { GET } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import { getCurrentMonth } from '../../../utils/utils'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type MonthItemsResponseType = {
  month: string
  total: number
}

const DashboardContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [category, setCategory] = useState([])
  const [products, setProducts] = useState([])
  const [sellersByMonth, setSellersByMonth] = useState(0)
  const [totalValuesByMonth, setTotalValuesByMonth] = useState<MonthItemsResponseType[]>([])

  const initializeDashboard = async () => {
    try {
      setLoading(true)
      const categoryRes = await GET('/dashboard/seller-category')
      const productsRes = await GET('/dashboard/seller-products')
      const monthRes = await GET('/dashboard/seller-month')
      const allMonthRes = await GET('/dashboard/seller-all-month')
      setLoading(false)
      setCategory(categoryRes.data)
      setProducts(productsRes.data)
      setSellersByMonth(monthRes.data.sellers)
      setTotalValuesByMonth(
        allMonthRes.data.map((monthItem: MonthItemsResponseType) => ({
          month: monthItem.month,
          total: monthItem.total.toFixed(2),
        }))
      )
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    initializeDashboard()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Dashboard</span>

                <BreadCrumbs />

                <span className='fw-normal bg-primary rounded fs-8 text-white py-1 px-2 my-2 fw-bold'>
                  {`Total de pedidos de ${getCurrentMonth()}: ${sellersByMonth}`}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='d-flex flex-row justify-content-between flex-wrap gap-5'>
                <div style={{ flex: 1 }}>
                  <DashboardTable
                    data={products}
                    headers={[
                      { key: i18n.t(`dashboard.products`), value: 'name', type: 'default' },
                      { key: i18n.t(`dashboard.quantity`), value: 'sellers', type: 'default' },
                      { key: i18n.t(`dashboard.total`), value: 'total', type: 'money' },
                    ]}
                    title={i18n.t(`dashboard.totals_by_products`)}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <DashboardTable
                    data={category}
                    headers={[
                      { key: i18n.t(`dashboard.category`), value: 'name', type: 'default' },
                      { key: i18n.t(`dashboard.quantity`), value: 'sellers', type: 'default' },
                      { key: i18n.t(`dashboard.total`), value: 'total', type: 'money' },
                    ]}
                    title={i18n.t(`dashboard.totals_by_category`)}
                  />
                </div>
              </div>

              <div className='card w-100 border p-10 mt-10'>
                <div className='d-flex justify-content-between'>
                  <h3 className='card-title fw-bold fs-2 mb-1 text-gray-800'>{i18n.t(`dashboard.last_orders`)}</h3>
                  <Button
                    text={i18n.t(`buttons.overview`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate('/chartorders')}
                  />
                </div>
                {totalValuesByMonth.length > 0 && (
                  <LineChart
                    isMoney
                    nameValue='Total'
                    data={totalValuesByMonth.map((monthItem) => monthItem.total)}
                    options={totalValuesByMonth.map((monthItem) => monthItem.month)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Dashboard: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[{ title: 'Dashboard', path: 'dashboard', isActive: true }]}>
        {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      </PageTitle>
      <DashboardContent />
    </>
  )
}

export { Dashboard }
