import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import {DELETE, GET, PATCH, POST, PUT} from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import { useNavigate } from 'react-router-dom'
import { ModalWarning } from '../../../../components/Modals/General/ModalWarning/ModalWarning'

type QuestionResponseType = {
    id: number;
    prompt: string;
    completion: string;
    created: string;
    status: boolean;
}

type QuestionType = {
    id: number;
    prompt: string;
    completion: string;
    created: string;
    status: boolean;
}

type PluginconfigType = {
  id: number;
  name: string;
  content: any;
  plugintemplateId: number;
  plugintypeId: number;
}

type TemplateType = {
  id: number;
  name: string;
  image: string | null;
  parameters: string;
  plugintypeId: number;
  pluginconfig: PluginconfigType;
  typeIntegration: string;
  able: boolean | null;
}

const FitContent: FC = () => {
  const navigate = useNavigate();
  const {showWrapperModal, showWarningModal} = useModals()

  const [questions, setQuestions] = useState<QuestionType[]>([])
  const [config, setConfig] = useState<PluginconfigType | null>(null)
  const [id, setId] = useState<number | null>(null);
  const [question, setQuestion] = useState<string>('')
  const [answer, setAnswer] = useState<string>('')
  const [agentName, setAgentName] = useState('')
  const [action, setAction] = useState<'creating' | 'editing' | 'deleting'>('creating');
  const [loading, setLoading] = useState(false);

  const getQuestions = async () => {
    try {
      const questionRes = await GET('/knowledge-base/listAllQuestionsAnswers')
      const newQuestions = questionRes.data.map((question:QuestionResponseType) => {
        return {
            id: question.id,
            prompt: question.prompt,
            completion: question.completion,
            created: question.created,
            status: question.status,
        }
      })
      setQuestions(newQuestions);
    } catch (e) {
      setQuestions([]);
      console.log(e)
    }
  }

  const createQuestion = async () => {
    try{
      if(!answer || !question) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const questionRes = await POST('/knowledge-base/createQuestionsAnswers', {
          prompt: question,
          completion: answer,
          status: true,
        });
        if(questionRes.success === true){
          Swal.fire({
            icon: 'success',
            title: 'Treinamento cadastrado',
            text: 'Cadastro realizado com sucesso!'
          }).then(async () => await init())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar treinamento!'
          }).then(async () => await init())
        }
        setLoading(false);
      }
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar treinamento!'
      }).then(async () => await init())
      console.log(e)
    }
  }

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const newQuestions = questions.map((question)=> {
        if(question.id === Id){
          question.status = !status;
        }
        return question;
      })
      setQuestions(newQuestions)
      await PATCH(`/knowledge-base/updateQuestionsAnswers/${Id}`, {
        status: !status,
      })
    } catch (e) {
      console.log(e)
    }
  }
  
  const editQuestions = async () => {
    try{
      if(!answer || !question) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const questionsRes = await PATCH(`/knowledge-base/updateQuestionsAnswers/${id}`, {
          prompt: question,
          completion: answer,
        });
        if(questionsRes.success === true){
          Swal.fire({
            icon: 'success',
            title: 'Treinamento alterado',
            text: 'Edição realizada com sucesso!'
          }).then(async () => await init())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar treinamento!'
          }).then(async () => await init())
        }
        setLoading(false);
      }
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar treinamento!'
      }).then(async () => await init())
      console.log(e)
    }
  }

  const deleteQuestion = async () => {
    try{
      setLoading(true);
      const questionRes = await DELETE(`/knowledge-base/removeQuestionsAnswers/${id}`);
      if(questionRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'Treinamento deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await init())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar treinamento!'
        }).then(async () => await init())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar treinamento!'
      }).then(async () => await init())
      console.log(e)
    }
  }

  const adjustHeightQuestion = () => {
    const textbox = document.getElementById('question-modal')
    if(textbox){
      if(textbox.scrollHeight <= 130){
        textbox.style.height = "inherit";
        textbox.style.height = `${textbox.scrollHeight}px`;
      }
    }
  }

  const adjustHeightAnswer = () => {
    const textbox = document.getElementById('answer-modal')
    if(textbox){
      if(textbox.scrollHeight <= 180){
        textbox.style.height = "inherit";
        textbox.style.height = `${textbox.scrollHeight}px`;
      }
    }
  }

  const verifyPlugins = async () => {
    const templateRes = await GET('/plugintemplate/ia')
    let hasPlugin = false
    if(templateRes && templateRes.data){
      templateRes.data.forEach((template: TemplateType) => {
        if(template.pluginconfig){
          if(template.pluginconfig.content && template.pluginconfig.content.able && template.pluginconfig.content.able !== '0'){
            setAgentName(template.pluginconfig.content.agentName)
            setConfig(template.pluginconfig)
            hasPlugin = true;
          }
        }
      })
    }
    return hasPlugin
    
  }

  const syncIA = async () => {
    try {
      setLoading(true);
      const resAgent = await GET(`agent-ia/listAgentNIS/${agentName}`)
      if(!resAgent.agentId){
        setLoading(false)  
        Swal.fire({
          icon: 'error',
          title: 'Erro ao sincronizar',
          text: 'Falha ao recuperar o agente',
        })
        return;
      }
      const resSync = await GET(`/knowledge-base/syncKBAgentAWS/${resAgent.agentId}`)
      setLoading(false)
      if(resSync && (resSync.knowledgeBaseId || (resSync.data && resSync.data.knowledgeBaseId))){
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Agente sincronizado com sucesso',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao sincronizar',
          text: 'Falha ao sincronizar agente de IA',
        })  
      }
    } catch(e:any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro ao sincronizar',
        text: e ? e : 'Falha ao sincronizar agente de IA',
      })
    }
  }

  const init = async () => {
    setLoading(true);
    const hasPlugin = await verifyPlugins();
    if(!hasPlugin){
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'É necessário estar com um plugin de IA habilitado',
      }).then(() => navigate('/ia/config'))
    }
    await getQuestions();
    setLoading(false);
  }

  useEffect(() => {
    adjustHeightQuestion()
  }, [question])

  useEffect(() => {
    adjustHeightAnswer()
  }, [answer])

  useEffect(() => {
    init()
  }, [])

  return (
    <>
    { loading ? <Loading /> :
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`chat.fit`)}</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              {i18n.t(`chat.fit_header`)}
            </span>
          </h3>
          <div className='card-toolbar gap-2'>
            <Button
              text={i18n.t(`chat.add_question`)}
              color='primary'
              iconColor='#FFF'
              icon='fas fa-plus'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                showWrapperModal(i18n.t(`chat.add_question`))
                setQuestion('');
                setAnswer('');
                setId(null);
                setAction('creating')
              }}
              btnClass='mb-5 fw-bolder'
            />
            <Button
              text={i18n.t(`chat.sync`)}
              color='primary'
              iconColor='#FFF'
              icon='fas fa-cloud'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={async () => await syncIA()}
              btnClass='mb-5 fw-bolder'
            />
          </div>
        </div>
        <div className='card-body'>
          <Datatable
            data={questions}
            headers={[
            //   {key: 'Id', value: 'id', type: 'default'},
              {key: i18n.t(`chat.question`), value: 'prompt', type: 'default', noLimit: true, noOrder: true },
              {key: i18n.t(`chat.answer`), value: 'completion', type: 'default', noLimit: true, noOrder: true},
              {key: i18n.t(`chat.date`), value: 'created', type: 'datetime'},
              {
                key: i18n.t(`chat.active`),
                value: 'status',
                type: 'check',
                action: (item) => switchStatus(item.id, item.status),
                noOrder: true,
                
              },
              {key: i18n.t(`chat.actions`), value: 'acoes', type: '', noOrder: true},
            ]}
            options={[
              {
                icon: 'fas fa-pen',
                action: (item) => {
                  showWrapperModal(`${i18n.t(`chat.edit_question`)}`)
                  setAction('editing')
                  setId(item.id);
                  setQuestion(item.prompt)
                  setAnswer(item.completion)
                },
                title: `${i18n.t(`chat.edit_question`)}`,
              },
              {icon: 'fas fa-trash', action: (item) => {
                showWrapperModal(`${i18n.t(`chat.delete_question`)}`)
                setAction('deleting')
                setId(item.id);
                setQuestion(item.prompt)
                setAnswer(item.completion)
              }, title: `${i18n.t(`chat.delete_question`)}`},
            ]}
            color='primary'
          />
        </div>
      </div>

      <ModalWrapper 
        textAction={action === 'deleting' ? i18n.t(`chat.delete`) : i18n.t(`buttons.save`)}
        confirmAction={
          action === 'creating' ? 
            () => createQuestion() : 
            action === 'editing' ? 
              () => editQuestions() :
              () => deleteQuestion()}
      >
        {action === 'deleting' ? (
          <div className='w-100 d-flex flex-column'>
            <div className="w-100 d-flex flex-row justify-content-center">
              <svg width="204" height="197" viewBox="0 0 204 197" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3409_3720)">
                <path d="M159.301 0.286077L9.67877 32.3076C3.18191 33.698 -0.957669 40.0919 0.432766 46.5888L30.4222 186.716C31.8126 193.212 38.2065 197.352 44.7034 195.962L194.325 163.94C200.822 162.55 204.962 156.156 203.571 149.659L173.582 9.53208C172.191 3.03522 165.797 -1.10436 159.301 0.286077Z" fill="#E8EDFC"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M165.34 146.75C167 147.19 168.48 149.15 168.65 151.13C168.82 153.11 167.62 154.36 165.96 153.92C164.3 153.48 162.82 151.52 162.65 149.54C162.48 147.56 163.68 146.31 165.34 146.75Z" fill="#BDD0FB"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M138.12 144.13C140.97 144.88 143.52 148.26 143.82 151.67C144.12 155.08 142.04 157.23 139.19 156.48C136.34 155.73 133.79 152.35 133.49 148.94C133.19 145.53 135.27 143.38 138.12 144.13Z" fill="#BDD0FB"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M166.67 173.59C167.77 173.88 168.75 175.18 168.86 176.49C168.97 177.8 168.18 178.62 167.08 178.34C165.99 178.05 165.01 176.75 164.89 175.44C164.78 174.13 165.57 173.3 166.67 173.59Z" fill="#BDD0FB"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M196.92 139.82C198.02 140.11 199 141.41 199.11 142.72C199.22 144.03 198.43 144.86 197.33 144.57C196.24 144.28 195.26 142.98 195.14 141.68C195.03 140.37 195.82 139.54 196.92 139.83V139.82Z" fill="#BDD0FB"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M155.85 119.09C156.95 119.38 157.93 120.68 158.04 121.99C158.15 123.3 157.36 124.13 156.26 123.84C155.16 123.55 154.18 122.25 154.07 120.94C153.96 119.63 154.75 118.8 155.85 119.09Z" fill="#BDD0FB"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M118.11 146.98C119.21 147.27 120.18 148.57 120.3 149.88C120.42 151.19 119.62 152.02 118.52 151.73C117.42 151.44 116.44 150.14 116.33 148.83C116.22 147.52 117.01 146.69 118.11 146.98Z" fill="#BDD0FB"/>
                <path d="M91.5099 42.4897H69.1399C67.5999 42.4897 66.3599 41.2397 66.3599 39.7097C66.3599 38.1697 67.6099 36.9297 69.1399 36.9297H91.5099C93.0499 36.9297 94.2899 38.1797 94.2899 39.7097C94.2899 41.2497 93.0399 42.4897 91.5099 42.4897Z" fill="#BDD0FB"/>
                <path d="M75.03 31.5795H3.12998C1.58998 31.5795 0.349976 30.3295 0.349976 28.7995C0.349976 27.2595 1.59998 26.0195 3.12998 26.0195H75.04C76.58 26.0195 77.82 27.2695 77.82 28.7995C77.82 30.3395 76.57 31.5795 75.04 31.5795H75.03Z" fill="#BDD0FB"/>
                <path d="M59.06 42.4799H11.77C10.23 42.4799 8.98999 41.2299 8.98999 39.6999C8.98999 38.1599 10.24 36.9199 11.77 36.9199H59.06C60.6 36.9199 61.84 38.1699 61.84 39.6999C61.84 41.2399 60.59 42.4799 59.06 42.4799Z" fill="#BDD0FB"/>
                <path d="M63.23 64.27H11.77C10.23 64.27 8.98999 63.02 8.98999 61.49C8.98999 59.95 10.24 58.71 11.77 58.71H63.23C64.77 58.71 66.01 59.96 66.01 61.49C66.01 63.03 64.76 64.27 63.23 64.27Z" fill="#BDD0FB"/>
                <path d="M91.7401 53.3696H19.83C18.29 53.3696 17.05 52.1196 17.05 50.5896C17.05 49.0496 18.3 47.8096 19.83 47.8096H91.7401C93.2801 47.8096 94.5201 49.0596 94.5201 50.5896C94.5201 52.1296 93.2701 53.3696 91.7401 53.3696Z" fill="#BDD0FB"/>
                <path d="M70.7099 138.03H70.6299C63.5099 137.79 57.9199 132.02 57.9199 124.89C57.9199 122.77 58.4399 120.65 59.4399 118.77C59.4399 118.77 59.4999 118.65 59.5199 118.63L90.9699 64.1504C90.9699 64.1504 91.0199 64.0704 91.0499 64.0204L91.1599 63.8704C93.6199 60.3104 97.6699 58.1904 101.99 58.1904C106.31 58.1904 110.36 60.3104 112.82 63.8704L112.93 64.0204C112.93 64.0204 112.98 64.1004 113.01 64.1404L144.63 118.91C144.63 118.91 144.68 118.99 144.7 119.03L144.77 119.18C145.64 120.97 146.08 122.9 146.08 124.89C146.08 132.02 140.49 137.79 133.36 138.03H70.7099Z" fill="#EAB83B"/>
                <path d="M139.04 121.951L138.51 121.031L108.13 68.4108L107.6 67.5008C106.37 65.7208 104.32 64.5508 102 64.5508C99.68 64.5508 97.62 65.7208 96.39 67.5008L95.86 68.4108L65.27 121.411L65.07 121.751C64.57 122.691 64.29 123.761 64.29 124.911C64.29 128.581 67.21 131.561 70.85 131.691H133.15C136.79 131.571 139.71 128.591 139.71 124.911C139.71 123.851 139.46 122.851 139.03 121.961L139.04 121.951Z" fill="white"/>
                <path d="M101.99 108.561C98.97 108.561 96.52 106.111 96.52 103.091V87.0608C96.52 84.0408 98.96 81.5908 101.99 81.5908C105.02 81.5908 107.45 84.0408 107.45 87.0608V103.091C107.45 106.111 105 108.561 101.99 108.561Z" fill="#6E80FF"/>
                <path d="M107.43 115.85C107.43 112.84 105 110.4 101.99 110.4C98.98 110.4 96.54 112.84 96.54 115.85C96.54 118.86 98.98 121.29 101.99 121.29C105 121.29 107.43 118.85 107.43 115.85Z" fill="#6E80FF"/>
                </g>
                <defs>
                <clipPath id="clip0_3409_3720">
                <rect width="203.68" height="196.23" fill="white" transform="translate(0.160034)"/>
                </clipPath>
                </defs>
              </svg>
            </div>
            <div className='w-100 d-flex flex-column align-items-start mt-4 gap-2'>
              <b>{question}</b>
              <p>{answer}</p>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column flex-wrap gap-5'>
            <div className='flex-grow-1'>
              <Input
                idInput='question-modal'
                inputAttr={{
                  type: 'textarea',
                }}
                value={question}
                change={setQuestion}
                placeholder={i18n.t(`chat.question`)}
                label={i18n.t(`chat.question`)}
                rows={2}
              />
            </div>
            <div className='flex-grow-1'>
              <Input
                idInput='answer-modal'
                inputAttr={{
                  type: 'textarea',
                }}
                rows={5}
                value={answer}
                change={setAnswer}
                placeholder={i18n.t(`chat.answer`)}
                label={i18n.t(`chat.answer`)}
              />
            </div>
          </div>
        )}
      </ModalWrapper>
    </div>}
    </>
  )
}

const Fit: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`chat.ia`), path: 'ia/fit', isActive: true},
        ]}
      >
        {i18n.t(`chat.fit`)}
      </PageTitle>
      <FitContent />
    </>
  )
}

export {Fit}
