/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Input } from '../../../components/Input/Input'
import { Button } from '../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { PUT, GET, POST } from '../../../services/api'
import { Loading } from '../../../components/Loading/Loading'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'

import SVG from 'react-inlinesvg';
import uuid from 'react-uuid';
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import usePluginsSpace from '../../../hooks/usePluginSpace'


type classIcon = {
  name: string
  data: Icon[]
}

type Icon = {
  id: number;
  svg: string;
  description: string;
  category: string;
}

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}



const PageContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { pluginSpace } = usePluginsSpace()
  const [loading, setLoading] = useState(false)

  const [type, setType] = useState<string>('service')
  const [categoryId, setCategoryId] = useState<any>('')
  const [categories, setCategories] = useState<any[]>([])
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [status, setStatus] = useState<boolean>(true)
  const [description, setDescription] = useState<string>('')

  const [filterCategoria, setFilterCategoria] = useState<any>()

  const [listClassification, setListClassification] = useState<any[]>([])
  //dados e funções sobre os icones
  const [icones, setIcones] = useState<Icon[]>([])
  const [iconesFiltered, setIconesFiltered] = useState<Icon[]>([])
  const [categoryIcons, setCategoryIcons] = useState<any[]>([{
    select: 'Todos',
    value: -1
  }])
  const [categoryIconsSelect, setCategoryIconsSelect] = useState<any>(-1)
  const [icon, setIcon] = useState<string>('')
  const [image, setImage] = useState<string>('')
  const [imageId, setImageId] = useState<Number>(0)
  const [loadingIcons, setLoadingIcons] = useState(false)
  const [newDeletedImages, SetNewDeletedImages] = useState<number[]>([])



  const convertSvgToPng = () => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      if (context) {
        const image = new Image();
        image.onload = () => {
          canvas.width = 45;
          canvas.height = 45;
          context.drawImage(image, 0, 0, 45, 45); // Adjust size as needed

          canvas.toBlob(blob => {
            if (blob) {
              resolve(new File([blob], 'image.png', { type: 'image/png' }));
            } else {
              reject(new Error('Failed to convert SVG to PNG'));
            }
          });
        };
        image.src = `data:image/svg+xml;base64,${btoa(icon)}`;
      } else {
        reject(new Error('Canvas context not supported'));
      }
    });
  };
  function changeFilterIcons() {
    setLoadingIcons(true)
    const filtered = icones.filter(icon => {

      if (parseInt(categoryIconsSelect) !== -1) {


        return icon.category === categoryIconsSelect ? true : false

      }
      return true


    })

    setIconesFiltered(filtered)


    setTimeout(() => {
      setLoadingIcons(false)
    }, 1000)
  }


  const generateClassificationTree = (
    categories: ReturnType[],
    parent: string | null,
    name: string
  ): any => {
    const parentCategory = categories.find((category) => category.code === parent)
    if (parentCategory)
      return `${generateClassificationTree(
        categories,
        parentCategory.parent,
        parentCategory.name
      )} > ${name}`
    else return name
  }


  const getClassifications = async (loading = true) => {
    try {
      const res = await POST(`/classification/filter`, {
        plugintypeId: getPlugintypeIdByCode('service'),
        status: true,
        deleted: 'not show',
      })

      // eslint-disable-next-line array-callback-return
      res.data.map((row: ReturnType) => {
        listClassification.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: generateClassificationTree(res.data, row.parent, row.name),
        })
      })
      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      // eslint-disable-next-line array-callback-return
      listClassification.map((row: any, idx) => {
        categories.push({
          value: row.id,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const get = async () => {
    try {
      setLoading(true)

      const res = await GET(`/product/${id}`)
      res.data.map(async (row: any) => {
        const resC = (
          await POST(`/classification/filter`, { id: row.classificationId, type: 'service' })
        ).data

        const image = row.images[0]

        if (image) {
          setImage(`${image.path}/${image.imgsuffix}`)
          setImageId(image.id)
        }

        const idsDelete = row.images.map((i: any) => i.id)

        SetNewDeletedImages(idsDelete)


        const cat = resC.filter((row2: any) => row2.id == row.classificationId)
        setFilterCategoria({
          value: cat[0].id,
          label: cat[0].name,
        })
        setName(row.name)
        setDescription(row.description)
        setCode(row.code)
        setStatus(row.status)
      })

      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }


  async function sendSvgAWS() {
    try {
      let bucket = `img.ninegrid.com`
      const response = await POST('/upload-images/upload-svg', {
        file: icon,
        path: bucket,
        name: `${pluginSpace?.subdomain}/service/product-${uuid()}.svg`
      })
      return response

    } catch (error) {
      return {
        error: error,
        success: false,
        data: {}
      }
    }
  }

  const update = async () => {
    try {
      console.log(categoryId)
      if (categoryId.value === '0' || !name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)

        const data = {
          name: name,
          description: description,
          code: code ?? '',
          classificationId: parseInt(filterCategoria.value),
          type: type,
          status: status,
          markId: 0,
          markCode: '',
          measurementUnitId: 0,
        }
        const resProd = await PUT(`/product/${id}`, data)
        if (icon) {

          const newimage = icon

          let imageRes = {
            success: false,
            image: '',
          }

          imageRes = await sendSvgAWS()

          if (newimage) {
            const formData = new FormData()
            formData.append('file', newimage)
            formData.append('nameImage', `product-${uuid()}.jpg`)
            formData.append('originalSize', 'true')
            imageRes = await POST('/upload-images/upload-one', formData)
          }

          if (imageRes.success == true) {
            const data = [
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'mainpicture',
                order: 0,
              }
            ]

            await POST('/productimage/multiple', {
              data,
            })
            if (newDeletedImages && newDeletedImages.length > 0) {
              await POST(`productimage/delete-multiple`, {
                data: newDeletedImages,
              })
            }

          }


        }
        if (resProd.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Serviço editado',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate('/hospitality/service-item'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao atualizar o serviço!',
          })
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao atualizar o serviço!',
      })
    }
  }

  async function getIcones() {
    try {
      setLoading(true)

      const svgCategory = await POST('svgcategory/filter', {
        status: true
      });

      const auxCategory = [{
        select: 'Todos',
        value: -1
      }]

      svgCategory.data.map((el: any) => {
        auxCategory.push({
          select: el.name,
          value: el.id
        }
        )
      })

      setCategoryIcons(auxCategory)



      const res = await GET('svgwebapp')

      if (!res || !res.success) {
        Swal.fire({
          icon: 'error',
          titleText: 'Erro',
          text: 'Erro ao encontrar os tipos'
        })
        setLoading(false)
        return
      }

      setIcones(res.data)
      setIconesFiltered(res.data)

      setLoading(false)

    } catch (error) {
      console.log(error)
      setLoading(false)

      Swal.fire({
        icon: 'error',
        titleText: 'Erro',
        text: 'Erro ao encontrar os tipos'
      })
    }
  }

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        await Promise.all([
          await getClassifications(),
          await get(),
          await getIcones()
        ])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    load()
  }, [])
  useEffect(() => {
    changeFilterIcons()
  }, [categoryIconsSelect])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <>
            <div className='d-flex justify-content-between'>
              <div className='w-50 me-5'>
                <div className='card pb-5'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Editar Serviço</span>
                      <BreadCrumbs />
                    </h3>
                  </div>
                  <div className='px-10 py-5'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      search
                      placeholder={'Selecione'}
                      label={i18n.t(`smart_order.category_parent`)}
                      options={categories}
                      defaultValue={categoryIcons[0]}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='Nome'
                      label='Nome'
                      value={name}
                      change={(value: string) => setName(value)}
                    />

                    <Input
                      inputAttr={{
                        type: 'textarea',
                      }}
                      placeholder='Descrição'
                      label='Descrição'
                      value={description}
                      change={(value: string) => setDescription(value)}
                      rows={5}
                    />

                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='Código'
                      label='Código'
                      value={code}
                      change={(value: string) => setCode(value)}
                    />

                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label='Status'
                      options={[
                        { select: 'Ativo', value: true },
                        { select: 'Inativo', value: false },
                      ]}
                      value={status}
                      change={(value: boolean) => setStatus(value)}
                    />
                  </div>
                </div>
              </div>
              <div className='w-50 card h-100 '>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Ícones</span>
                  </h3>
                </div>

                <div className="d-flex flex-column overflow-auto  gap-5 p-8 w-100" style={{
                  height: '40rem'
                }}>
                  <div>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label='Categoria do Icone'
                      options={categoryIcons}
                      value={categoryIconsSelect || ''}
                      change={(value: any) => {
                        setCategoryIconsSelect(value)
                      }}
                    />
                  </div>

                  <div className=" d-flex overflow-auto flex-wrap gap-5 justify-content-center w-100">
                    {loadingIcons ? (
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <Loading />
                      </div>
                    ) : (

                      <>
                        {
                          iconesFiltered.map(iconF => (
                            <div className={`d-flex flex-column p-4 justify-content-center align-items-center ${iconF.svg === icon ? 'border border-success border-2' : 'border border-back border-2'}`}
                              onClick={() => {
                                setIcon(iconF.svg)
                              }}
                              style={{
                                width: '20%',

                              }}
                            >
                              <span>{iconF.description}</span>
                              <SVG src={iconF.svg} style={{
                                width: '45px',
                                height: '45px'
                              }} />
                            </div>
                          ))
                        }

                      </>


                    )}
                  </div>


                  <div className="d-flex justify-content-between gap-5 border-top border-black">

                    <div className="d-flex flex-column justify-content-start gap-5 mt-5">
                      <span className='fs-3 fw-bold'>Ícone selecionado</span>
                      {icon &&
                        <div className="p-8">
                          <SVG src={icon} style={{
                            width: '45px',
                            height: 'auto'
                          }} />
                        </div>

                      }
                      {!icon &&
                        <div className="p-8">
                          <img src={image} style={{
                            width: '45px',
                            height: '45px'
                          }} />
                        </div>

                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                text='Voltar'
                color='info'
                size='small'
                horizontalPadding={5}
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  navigate('/hospitality/service-item')
                }}
                btnClass='fw-bolder me-3'
              />
              <Button
                text='Salvar'
                color='primary'
                size='small'
                horizontalPadding={5}
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  update()
                }}
                btnClass='fw-bolder'
              />
            </div>
          </>
        </div>
      )}
    </>
  )
}

const EditarServico: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Serviços', path: 'service-item', isActive: true },
          { title: 'Editar', path: '#', isActive: true },
        ]}
      >
        Atualizar Serviço
      </PageTitle>
      <PageContent />
    </>
  )
}

export { EditarServico }
