import {FC, ReactNode} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import useModals from '../../../../hooks/useModals'
import styles from './ModalWarning.module.css'

type Props = {
  confirmAction: () => void
  warningMessage?: string;
  warningButton?: string;
}

const ModalWarning: FC<Props> = ({confirmAction, warningButton, warningMessage}) => {
  const {warningModal, closeWarningModal} = useModals()

  return (
    <>
      {warningModal.display && (
        <>
          <div onClick={() => closeWarningModal()} className={styles.background}></div>
          <div className={`${styles.modalCustom} modal-dialog w-40`}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{warningModal.title}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  onClick={() => closeWarningModal()}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body text-break pt-0'>
                <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-2 px-10'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className={`svg-icon ${styles.svgIconFull}`}
                  />
                  <h2 className={`mt-2 ${styles.modalText}`}>{warningMessage ? warningMessage : 'Tem certeza?'}</h2>
                </div>
              </div>
              <div className='modal-footer d-flex align-items-center mt-6 justify-content-center'>
                <button
                  type='button'
                  className={'btn btn-primary me-3'}
                  onClick={() => {
                    confirmAction()
                    closeWarningModal()
                  }}
                >
                  {warningButton ? warningButton : 'Salvar'}
                </button>
                <button
                  type='button'
                  className='btn btn-light ms-3'
                  onClick={() => closeWarningModal()}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {ModalWarning}
