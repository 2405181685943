/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType, OrderType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, PUT, DELETE, POST } from '../../../../services/api'
import useModals from '../../../../hooks/useModals'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import { Dropdown } from 'react-bootstrap'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ProductType = {
  id: number
  selected: boolean
  name: string
  images: Array<string>
  code: string
  order: string
  status: boolean
}

type ImageType = {
  path: string
  imgsuffix: string
  productId: number
  typeimg: string
  order: number
}

type PluginconfigType = {
  name: string
  plugintemplateId: number
  deleted: string
}

type categories = {
  value: string
  select: string
}

type filter = {
  classificationId?: string
  status?: boolean
  order?: OrderType
}

const ProdutosContent: FC = () => {
  const navigate = useNavigate()
  const { loginInfo } = useAuth()
  const { showDeleteModal, showWrapperModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<ProductType[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const [fileToImport, setFileToImport] = useState<File | null>(null)
  const [hasIntegration, setHasIntegration] = useState(false)
  const [confirmText, setConfirmText] = useState('')
  const [categoria, setCategoria] = useState<categories[]>([])
  const [qtdSelected, setQtdSelected] = useState(0)
  const [action, setAction] = useState<'alterLote' | 'importProduct'>('importProduct')
  const [changeStatusLote, setChangeStatusLote] = useState(false)
  const [TypeModal, setTypeModal] = useState('')
  const [options, setOptions] = useState([
    {
      icon: 'fas fa-pen',
      title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.product`)}`,
      action: (item: any) => {
        navigate(
          loginInfo.plugintype === 'varejo'
            ? `/product_varejo/editar/${item.id}`
            : `/product_fashion/editar/${item.id}`
        )
      },
    }
  ])

  const [filterStatus, setFilterStatus] = useState<any>({
    label: 'Ambos',
    value: 2,
  })
  const [filterCategoria, setFilterCategoria] = useState<any>({
    label: 'GERAL',
    value: 0,
  })

  useEffect(() => {
    init()
    //getProducts();
  }, [])

  const init = async () => {
    setLoading(true);
    await filterProdutos();
    setLoading(false)
  }

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchProduct = await PUT(`/product/${Id}`, {
        status: `${!status}`,
      })
      if (switchProduct.success) {
        const newProducts = products.map((product) => {
          if (product.id === Id) {
            product.status = !status
          }
          return product
        })
        setProducts(newProducts)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getCategories = async () => {
    try {
      setLoading(true)
      const resCategorias = await POST(`/classification/filter`, { status: true })

      const categories = resCategorias.data.map((el: any) => {
        return {
          value: el.id,
          select: el.name,
        }
      })

      categories.sort((a: any, b: any) =>
        a.select.toUpperCase() > b.select.toUpperCase()
          ? 1
          : a.select.toUpperCase() < b.select.toUpperCase()
            ? -1
            : 0
      )
      setCategoria(categories)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const getProducts = async (take: number = 10, page: number = 1, search: string = '') => {
    try {
      const res = await GET(`/product?page=${page}&take=${take}&search=${search}`)
      const newProducts = res.data.map((row: any) => {
        let imgProduct = ''
        if (row.images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
          let imgFind = row.images.find((img: ImageType) => img.typeimg === 'imgsmall')
          if (imgFind) {
            imgProduct =
              imgFind.path.substring(7, imgFind.path.length) + '/' ===
                process.env.REACT_APP_AWS_S3_URL.substring(7, process.env.REACT_APP_AWS_S3_URL.length)
                ? imgFind.path + '/' + imgFind.imgsuffix
                : imgFind.path
          }
        }
        return {
          id: row.id ? row.id : '',
          selected: false,
          order: row.order ?? 0,
          img: imgProduct ? imgProduct : '',
          name: row.name ? row.name : '',
          code: row.code,
          category: row.classification && row.classification.name ? row.classification.name : '',
          status: row.status ? row.status : false,
        }
      })
      setProducts(newProducts)
      setMeta(res.meta)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const verifyIntegration = async () => {
    try {
      setLoading(true)
      const res = await GET(`/pluginconfig`)
      const totvsIntegration = res.data.filter(
        (pluginconfig: PluginconfigType) =>
          pluginconfig.plugintemplateId === 23 ||
          pluginconfig.plugintemplateId === 15 ||
          pluginconfig.plugintemplateId === 45 ||
          pluginconfig.plugintemplateId === 48 ||
          pluginconfig.plugintemplateId === 104
      )

      if (totvsIntegration && totvsIntegration.length > 0) {
        setHasIntegration(true)
      } else {
        setHasIntegration(false)
        setOptions([
          {
            icon: 'fas fa-pen',
            title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.product`)}`,
            action: (item: any) => {
              navigate(
                loginInfo.plugintype === 'varejo'
                  ? `/product_varejo/editar/${item.id}`
                  : `/product_fashion/editar/${item.id}`
              )
            },
          },
          {
            icon: 'fas fa-trash',
            action: (item) => {
              showDeleteModal('Excluir produto')
              setId(item.id)
            },
            title: 'Excluir produto',
          },
        ])

      }
      setLoading(false)
    } catch (e) {
      setHasIntegration(false)
      setLoading(false)
      console.log(e)
    }
  }

  const importProducts = async () => {
    try {
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToImport)

        const importRes = await POST('/product/import', formData)
        setLoading(false)
        if (importRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Importação realizada com sucesso!',
          }).then(async () => await getProducts())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao importar arquivo!',
          }).then(async () => await getProducts())
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getProducts())
    }
    setFileToImport(null)
  }

  const deleteProduct = async () => {
    try {
      setLoading(true)
      const markRes = await DELETE(`/product/${id}`)
      if (markRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Produto deletado',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getProducts())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o produto!',
        }).then(async () => await getProducts())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o produto!',
      }).then(async () => await getProducts())
      console.log(e)
    }
  }

  const deleteAllProduct = async () => {
    try {
      setLoading(true)
      const res = await POST('product/delete-all')
      if (res.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Produtos deletados',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getProducts())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o produtos!',
        }).then(async () => await getProducts())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o produto!',
      }).then(async () => await getProducts())
      console.log(e)
    }
  }

  const switchSelected = async (Id: number, status: boolean) => {
    try {
      const newProducts = products.map((el) => {
        if (el.id === Id) {
          el.selected = !el.selected
        }

        return el
      })

      const filterdArray = newProducts.filter((el) => el.selected == true)

      setQtdSelected(filterdArray.length)

      setProducts(newProducts)
    } catch (e) {
      console.log(e)
    }
  }

  function selecteAll() {
    try {
      setLoading(true)
      const newProducts = products.map((el) => {
        el.selected = !el.selected

        return el
      })
      setProducts(newProducts)

      const filterdArray = newProducts.filter((el) => el.selected == true)

      setQtdSelected(filterdArray.length)

      setTimeout(() => {
        setLoading(false)
      }, 1)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const filterProdutos = async (take: number = 100, page: number = 1, search: string = '', order: OrderType = {field: 'name', type: 'ASC'}) => {
    if (!filterCategoria || !filterStatus) {
      Swal.fire({
        icon: 'error',
        title: 'Filtros não selecionados',
        text: 'Selecione corretamente os filtros',
      })
    } else {
      try {

        setQtdSelected(0)

        const newStatus = filterStatus.value == '1' ? true : false

        const filter: filter = {
          status: newStatus,
          classificationId: filterCategoria.value,
          order
        }

        filterStatus.value == '2' ? delete filter['status'] : (filter['status'] = filter['status'])

        filterCategoria.label.toUpperCase() === 'GERAL'
          ? delete filter['classificationId']
          : (filter['classificationId'] = filter['classificationId'])

        const filteredProducts = await POST(
          `product/filter?page=${page}&take=${take}&search=${search}`,
          filter
        )

        const newProducts = filteredProducts.data.map((row: any) => {
          let imgProduct = ''
          if (row.images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
            let imgFind = row.images.find((img: ImageType) => img.typeimg === 'imgsmall')
            if (imgFind) {
              imgProduct =
                imgFind.path.substring(7, imgFind.path.length) + '/' ===
                  process.env.REACT_APP_AWS_S3_URL.substring(
                    7,
                    process.env.REACT_APP_AWS_S3_URL.length
                  )
                  ? imgFind.path + '/' + imgFind.imgsuffix
                  : imgFind.path
            }
          }
          return {
            id: row.id,
            selected: false,
            code: row.code,
            order: row.order ?? 0,
            img: imgProduct,
            name: row.name,
            category: row.classification && row.classification.name,
            averageTime: row.productTimer ? row.productTimer.averageTime : '00:00:00',
            status: row.status,
          }
        })
        setProducts(newProducts)
        setMeta(filteredProducts.meta)

      } catch (error) {
        setLoading(false)
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao filtrar os produto',
        }).then(async () => await getProducts())
      }
    }
  }

  const changeInLote = async (typeModal: string) => {
    try {
      const productsToUpdate = products.filter((el) => el.selected === true)

      setLoading(true)
      if (typeModal == 'Status') {
        const switchProduct = await PUT(`/product/massiveUpdate`, {
          status: changeStatusLote,
          data: productsToUpdate,
        })
        setChangeStatusLote(false)
        if (switchProduct.success) {
          const newProducts = products.map((product) => {
            if (product.selected) {
              product.status = changeStatusLote
            }

            product.selected = false
            return product
          })
          setProducts(newProducts)
          setQtdSelected(0)
        }
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao atualiar os produto',
      })
    }
  }

  useEffect(() => {
    verifyIntegration()
    getCategories()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  {i18n.t(`smart_order.product`)}
                </span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(
                    `smart_order.here_are_listed_the_products_along_with_their_respective_actions`
                  )}
                </span>
              </h3>
              <div className='card-toolbar'>
                {/* <Button
                  text='Migração'
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-arrow-left-right'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {}}
                  btnClass='mb-5 fw-bolder me-5'
                /> */}
                <Button
                  text={i18n.t(`buttons.register_product`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    navigate(
                      loginInfo.plugintype === 'varejo'
                        ? '/product_varejo/criar'
                        : '/product_fashion/criar'
                    )
                  }}
                  btnClass='mb-5 fw-bolder'
                />
                {!hasIntegration && (
                  <Dropdown
                    className='btn btn-primary btn-sm fw-bolder me-5 p-0 mb-5'
                    style={{ marginLeft: '1em' }}
                  >
                    <Dropdown.Toggle variant='default' size='sm'>
                      <i className='bi bi-three-dots fs-4 p-0'></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='p-0'>
                      <Dropdown.Item
                        onClick={() => {
                          showWrapperModal('Importar planilha')
                        }}
                        className='p-0 d-flex flex-row justify-content-center'
                      >
                        <div
                          className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                          style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                        >
                          <i
                            className='bi bi-box-seam'
                            style={{ color: '#6993FF', fontSize: '1.5rem', marginRight: '0.5em' }}
                          ></i>
                          <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                            Importar planilha
                          </p>
                        </div>
                      </Dropdown.Item>

                      {products.length > 0 && (
                        <>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            onClick={() => {
                              Swal.fire({
                                icon: 'warning',
                                title: `Atenção!`,
                                html: `
                              Cuidado ao realizar a exclusão, os dados não poderão ser <b>restaurados!<b/>
                              <div style="margin-top: 8">Digite <b>excluir</b> para confirmar!</div>`,
                                showCloseButton: true,
                                showConfirmButton: true,
                                confirmButtonColor: '#dc3741',
                                confirmButtonText: 'Excluir',
                                showCancelButton: false,
                                input: 'text',
                                inputPlaceholder: 'excluir',
                                preConfirm: (text) => {
                                  setConfirmText(text)
                                },
                              }).then(async (res) => {
                                if (res.isConfirmed && res.value && res.value === 'excluir') {
                                  await deleteAllProduct()
                                } else {
                                  Swal.close()
                                }
                              })
                            }}
                            className='p-0 d-flex flex-row justify-content-center'
                          >
                            <div
                              className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                              style={{ color: '#F64E60', backgroundColor: '#FFE2E5', width: '90%' }}
                            >
                              <i
                                className='bi bi-x'
                                style={{ color: '#F64E60', fontSize: '1.5rem', marginRight: '0.5em' }}
                              ></i>
                              <p style={{ color: '#F64E60', fontSize: '1rem' }} className='m-0'>
                                Excluir tudo
                              </p>
                            </div>
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='d-flex w-50 '>
                  <div className='flex-grow-1 align-items-end  justify-content-start me-5'>
                    <span className='align-self-start'>Categorias</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={categoria}
                      placeholder={'Categoria'}
                      search={true}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                  </div>

                  <div className='align-items-center me-5 justify-content-between flex-grow-1'>
                    <span className='align-self-start'>Status</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={[
                        {
                          value: 1,
                          select: 'Ativo',
                        },
                        {
                          value: 0,
                          select: 'Inativo',
                        },
                        {
                          value: 2,
                          select: 'Ambos',
                        },
                      ]}
                      value={filterStatus}
                      placeholder={'Status do Produto'}
                      search={true}
                      change={(value: any) => setFilterStatus(value)}
                    />
                  </div>
                </div>

                <div
                  className='d-flex align-items-end w-50 justify-content-end h-100'
                  style={{
                    marginTop: '48px',
                  }}
                >
                  <Button
                    text='Filtrar'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-filter'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={async() => {
                      setLoading(true)
                      await filterProdutos()
                      setLoading(false)
                    }}
                    btnClass='mb-0 fw-bolder'
                  />
                </div>
              </div>
              <div className='pt-4'>
                <Dropdown className='btn btn-primary btn-sm fw-bolder me-5 p-0'>
                  {qtdSelected === 0 ? (
                    <Dropdown.Toggle variant='sm' disabled>
                      <span style={{ color: '#FFF', width: '90%' }}>
                        Alterar em Lote ({qtdSelected})
                      </span>
                    </Dropdown.Toggle>
                  ) : (
                    <Dropdown.Toggle variant='sm'>
                      <span style={{ color: '#FFF', width: '90%' }}>
                        Alterar em Lote ({qtdSelected})
                      </span>
                    </Dropdown.Toggle>
                  )}
                  <Dropdown.Menu className='p-0'>
                    <Dropdown.Item
                      size='sm'
                      onClick={() => {
                        setAction('alterLote')
                        setTypeModal('Status')
                        showWrapperModal('Alterar Produtos')
                      }}
                      className='p-0 d-flex flex-row justify-content-center'
                    >
                      <div
                        className='btn m-2  btn-sm d-flex flex-row justify-content-center align-items-center'
                        style={{ color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%' }}
                      >
                        <p style={{ color: '#6993FF', fontSize: '1rem' }} className='m-0'>
                          Status
                        </p>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Button
                  text={'Selecionar todos'}
                  color='primary'
                  iconColor='#FFF'
                  size='small'
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    selecteAll()
                  }}
                  btnClass='fw-bolder me-5'
                />
              </div>

              <Datatable
                data={products}
                headers={[
                  {
                    key: 'Selecionado',
                    value: 'selected',
                    type: 'checkBox',
                    action: (item) => switchSelected(item.id, item.status),
                  },
                  { key: i18n.t(`smart_order.code`), value: 'code', type: 'default' },
                  { key: i18n.t(`smart_order.image`), value: 'img', type: 'image', noOrder: true },
                  { key: i18n.t(`smart_order.name`), value: 'name', type: 'default', },
                  { key: i18n.t(`smart_order.category`), value: 'category', type: 'default', noOrder: true },
                  {
                    key: i18n.t(`general.active`),
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '', noOrder: true },
                ]}
                options={options}
                color='primary'
                meta={meta}
                onChangeData={async (take, page, search, order) => await filterProdutos(take, page, search, order)}
                search
              />
            </div>
          </div>
          <ModalDelete confirmAction={() => deleteProduct()}  
            text='delete_product_observation'
          />
          <ModalWrapper
            confirmAction={() =>
              action === 'importProduct' ? importProducts() : changeInLote(TypeModal)
            }
          >
            {action === 'importProduct' && (
              <>
                <div className='flex-grow-1'>
                  <p>{i18n.t(`general.attention`)}</p>
                  <p>
                    Para importar produtos, a primeira linha do arquivo tem que seguir os seguintes
                    padrões:
                  </p>
                  <div
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '50em',
                      }}
                    >
                      Para importar apenas produtos: categoria, nome, codigo, descricao,
                      especificacao_tecnica, status, ordem
                    </div>
                    <div style={{ marginLeft: '1em' }}>
                      <Button
                        text={'Modelo'}
                        color='primary'
                        iconColor='#FFF'
                        size='small'
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          window.open('/file/modelo-produtos-simples.xlsx', '')
                        }}
                        btnClass='fw-bolder me-5'
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '1em',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '50em',
                      }}
                    >
                      Para importar produtos com variações únicas: categoria, nome, codigo,
                      descricao, especificacao_tecnica, status, ordem, preco, preco_promocional,
                      estoque
                    </div>
                    <div style={{ marginLeft: '1em' }}>
                      <Button
                        text={'Modelo'}
                        color='primary'
                        iconColor='#FFF'
                        size='small'
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          window.open('/file/modelo-produtos-variacao-unica.xlsx', '')
                        }}
                        btnClass='fw-bolder me-5'
                      />
                    </div>
                  </div>
                </div>
                <br />
                <div className='flex-grow-1 text-center'>
                  <Input
                    inputAttr={{
                      type: 'file',
                    }}
                    acceptFile={'.xlsx,.xls'}
                    change={(value: BaseSyntheticEvent) =>
                      setFileToImport(value ? value.target.files[0] : null)
                    }
                  />
                </div>
              </>
            )}

            {action === 'alterLote' && (
              <>
                <div className='mt-1'>
                  <span>Os itens abaixo serão alterados</span>
                </div>
                <div
                  className='d-flex flex-column overflow-scroll'
                  style={{
                    height: '8rem',
                    border: '1px solid #949392',
                  }}
                >
                  {products.map((elemento) => {
                    if (elemento.selected === true) {
                      return (
                        <span className='ms-2'>
                          {elemento.code} - {elemento.name}
                        </span>
                      )
                    }
                  })}
                </div>

                <div>
                  {TypeModal === 'Status' && (
                    <>
                      <div className='d-flex flex-column mt-2'>
                        <span>Status</span>
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          checkSwift={true}
                          value={changeStatusLote}
                          change={(value: boolean) => {
                            setChangeStatusLote(value)
                            console.log(value)
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const Produtos: FC = () => {
  const { loginInfo } = useAuth()
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          {
            title: i18n.t(`smart_order.product`),
            path: loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion',
            isActive: true,
          },
        ]}
      >
        {i18n.t(`smart_order.product`)}
      </PageTitle>
      <ProdutosContent />
    </>
  )
}

export { Produtos }
