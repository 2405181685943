import {FC, MouseEventHandler} from 'react'

type Props = {
  disabled?: boolean
  loading?: boolean
  icon?: string
  btnClass?: string
  btnAttr?: object
  text: string
  size?: string
  outline?: boolean
  color: string
  iconColor?: string
  horizontalPadding?: number
  verticalPadding?: number
  click: MouseEventHandler<HTMLButtonElement>
  width?: string
  height?: string
  uppercase?: boolean
  title?: string
}

const LittleButton: FC<Props> = (props) => {
  const {
    disabled,
    loading,
    icon,
    btnClass,
    btnAttr,
    text,
    size,
    outline,
    color,
    horizontalPadding,
    verticalPadding,
    iconColor,
    click,
    width,
    height,
    uppercase,
    title,
  } = props

  let adapted = ''
  if (!iconColor?.includes('#')) {
    if (iconColor === 'success') adapted = '#50cd89'
    else if (iconColor === 'primary') adapted = '#6993FF'
    else if (iconColor === 'danger') adapted = '#f1416c'
    else if (iconColor === 'info') adapted = '#8950fc'
  }

  return (
    <button
      {...btnAttr}
      title={title}
      className={
        `btn ${outline ? `btn-outline-${color} border border-${color}` : `btn-${color}`}`
      }
      style={{
        width :width,
        height :height,
        padding:'0px'
      }}
      disabled={disabled}
      onClick={click}
    >
      <span className=''>{text} </span>
    </button>
  )
}

export {LittleButton}
