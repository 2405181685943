import { FC, useState } from "react"

import Loading from "react-loading"
import { useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import useAuth from "../../../hooks/useAuth"
import { POST } from "../../../services/api"
import { Input } from "../../../components/Input/Input"
import { Button } from "../../../components/Button/Button"
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"
import { i18n } from "../../../translate/i18n"


type Form = {
  name: string
  type: string
  storeId: number
  parameters: {
    borderColor: string,
    averageColor: string,
    lateColor: string,
    fontSize: number,
    background: string,
    header: string,
    cardBody: string,
    cardHeader: string,
    button: string,
    fontColor: string
  }
}

type FontSize = {
  select: string,
  value: number
}

const CreateLayoutSDS: FC = () => {
  const navigate = useNavigate()
  const { loginInfo } = useAuth()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState<Form>({
    name: '',
    type: 'default',
    parameters: {
      borderColor: '',
      averageColor: '',
      lateColor: '',
      fontSize: 1,
      background: '',
      header: '',
      cardBody: '',
      button: '',
      cardHeader: '',
      fontColor: '',
    },
    storeId: 0
  })




  /* Definição */
  const [header, setHeader] = useState<string>(''); // cor do Cabeçalho
  const [background, setBackground] = useState<string>('');// cor do fundo do sds
  const [fontSize, setFontSize] = useState<number>(10)// tamanho das fontes
  const [fontSizeOptions, setFontSizeOptions] = useState<FontSize[]>([{
    select: 'Regular - 16px',
    'value': 1,
  }, {
    select: 'Médio - 20px',
    'value': 2,
  }, {
    select: 'Grande - 22px',
    'value': 3,
  }, {
    select: 'Extra Grande - 26px',
    'value': 4,
  }])

  const [lateColor, setLateColor] = useState<string>('');// cor quando chega a 30 %
  const [averageColor, setAverageColor] = useState<string>('');// cor quando o pedido está atrasado
  const [borderColor, setBorderColor] = useState<string>('');// cor da borda quando o pedido está selecionado
  const [button, setButton] = useState<string>('');// cor do botao do header
  const [cardHeader, setCardHeader] = useState<string>('');// cor do cabeça~ho do pedido
  const [cardBody, setCardBody] = useState<string>('');// cor do corpo do pedido
  const [fontColor, setFontColor] = useState<string>('');// cor do da font do SDS




  const create = async () => {
    form.parameters = {
      borderColor: borderColor,
      averageColor: averageColor,
      lateColor: lateColor,
      fontSize: fontSize,
      background: background,
      header: header,
      button: button,
      cardHeader: cardHeader,
      cardBody: cardBody,
      fontColor: fontColor,
    }

    try {
      setLoading(true)
      if (form.name === '' || form.type === '' || borderColor === ''
        || lateColor === '' || averageColor === ''
        || background === '' || header == '' || button == '' || cardHeader == '' || cardBody == '' || fontColor == ''


      ) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        const response = await POST(`sds_integration/create_layout`, form)

        if (response.success) {
          Swal.fire({
            icon: 'success',
            title: 'Personalização cadastrado',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/sds/layout'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastar!',
          })

        }





      }
      setLoading(false)
    } catch (e) {
      setLoading(false)

      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar a Personalização!',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-content-between'>
            <div className='w-100'>
              <div className='w-100 card'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`permissions.sds_layout`)}</span>
                    <BreadCrumbs />
                  </h3>
                </div>
                <div className='px-10 card-body'>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder='Nome do Card'
                    label='Nome *'
                    value={form.name || ''}
                    change={(value: string) => setForm({ ...form, name: value })}
                  />
                  <div className='row'>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do cabeçalho do SDS *'}
                        value={header || ''}
                        change={(value: string) => setHeader(value.toString())}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do fundo do SDS *'}
                        value={background || ''}
                        change={(value: string) => setBackground(value)}
                      />
                    </div>

                  </div>


                  <div className='row'>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do Cabeçalho do pedido *'}
                        value={cardHeader || ''}
                        change={(value: string) => setCardHeader(value.toString())}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do Fundo do pedido *'}
                        value={cardBody || ''}
                        change={(value: string) => setCardBody(value)}
                      />
                    </div>

                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do tempo de aleta de 30% *'}
                        value={averageColor || ''}
                        change={(value: string) => setAverageColor(value)}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do tempo de alerta atrasado *'}
                        value={lateColor || ''}
                        change={(value: string) => setLateColor(value)}
                      />
                    </div>

                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor do pedido selecionado *'}
                        value={borderColor || ''}
                        change={(value: string) => setBorderColor(value)}
                      />
                    </div>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor dos botões gerais do SDS *'}
                        value={button || ''}
                        change={(value: string) => setButton(value)}
                      />
                    </div>

                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'color',
                        }}
                        label={'Cor das letras do SDS *'}
                        value={fontColor || ''}
                        change={(value: string) => setFontColor(value)}
                      />
                    </div>

                    <div className='col-md-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label={'Tamanho da fonte *'}
                        value={fontSize || 1}
                        options={fontSizeOptions}
                        change={(value: number) => setFontSize(value)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className='row'>
                    <div className='col-12 d-flex justify-content-end'>
                      <Button
                        text='Salvar'
                        color='primary'
                        size='small'
                        horizontalPadding={5}
                        btnAttr={{
                          type: 'button',
                        }}
                        click={create}
                        btnClass='fw-bolder'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}


export { CreateLayoutSDS }