/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import moment from 'moment'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import useModals from '../../../hooks/useModals'
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'


const LiveShopContent: FC<any> = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [liveShops, setLiveShops] = useState<any[]>([])
    const [deleteLive, setDeleteLive] = useState<any>()
    const { showDeleteModal } = useModals()


    const getLiveShops = async () => {
        try {
            setLoading(true)
            const liveShopsRes = await POST('livemeta/filter');

            const liveShop = liveShopsRes.data.map((live: any) => {
                const schema = { ...live, canDelete: false }

                schema.canDelete = verifyCanDelete(live.initialDate)

                return schema
            })

            setLiveShops(liveShop)


            setLoading(false)
        } catch (e: any) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: e ? e : 'Não foi possivel encontrar Lives para essa loja.',
            })
            console.log(e)
        }
    }

    const verifyCanDelete = (dateInit: Date) => {
        try {
            const today = new Date().getTime()
            const initD = new Date(dateInit).getTime()


            if (today < initD) {
                return true
            }
            return false
        } catch (error) {
            return false
        }

    }

    const changeStatus = async (id: number, value: boolean) => {
        try {


            const live = liveShops.find((live) =>
                live.id === id)


            const obj = { ...live }

            delete obj.id
            delete obj.canDelete


            const switchLive = await PUT(`/livemeta/${id}`, {
                ...obj,
                status: !value,
            })
            if (switchLive.success) {
                const newLiveShops = liveShops.map((live) => {
                    if (live.id === id) {
                        live.status = !value
                    }
                    return live
                })
                setLiveShops(newLiveShops)
            }


        } catch (e: any) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: e ? e : 'Não foi possivel mudar o status',
            })
            console.log(e)
        }
    }

    const liveDelete = async () => {
        try {
            setLoading(true)

            await DELETE(`/livemeta/${deleteLive.id}`)
            await getLiveShops()

            setLoading(false)
        } catch (e) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Não foi possivel deletar a Live.',
            })
        }
    }


    useEffect(() => {
        getLiveShops()
    }, [])

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-12 gx-xxl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                        <div className='card-header py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`liveShop.liveShop`)}</span>
                                <BreadCrumbs />
                                <span className='text-muted mt-1 fw-bold fs-7'>
                                    {i18n.t(`liveShop.liveShop_list`)}
                                </span>
                            </h3>
                            <div className='d-flex justify-content-center align-items-end'>
                                <Button
                                    text={i18n.t(`liveShop.create`)}
                                    color='primary'
                                    iconColor='#FFF'
                                    icon='fas fa-plus'
                                    size='small'
                                    horizontalPadding={6}
                                    btnAttr={{
                                        type: 'button',
                                    }}
                                    click={() => navigate('/live_shop/criar')}
                                    btnClass='mb-0 fw-bolder'
                                />
                            </div>
                        </div>
                        <div className='card-body'>
                            <Datatable
                                data={liveShops}
                                headers={[
                                    { key: i18n.t(`liveShop.name`), value: 'name', type: 'default' },
                                    { key: i18n.t(`liveShop.initDate`), value: 'initialDate', type: 'datetime' },
                                    { key: i18n.t(`liveShop.fimDate`), value: 'finalDate', type: 'datetime' },
                                    { key: i18n.t(`liveShop.status`), value: 'status', type: 'check', action: (item: any) => changeStatus(item.id, item.status), },
                                    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                                ]}
                                options={[
                                    {
                                        icon: 'fas fa-pen',
                                        title: `${i18n.t(`general.edit`)}`,
                                        action: (item) => {
                                            navigate(`/live_shop/editar/${item.id}`)
                                        },
                                        color: 'primary',
                                    },
                                    {
                                        icon: 'fas fa-trash',
                                        action: (item) => {

                                            if (item.canDelete) {
                                                showDeleteModal(`${i18n.t(`liveShop.delete`)}`)
                                                setDeleteLive(item)
                                            } else {
                                                Swal.fire({
                                                    icon: 'warning',
                                                    title: `${i18n.t(`general.attention`)}`,
                                                    text: `${i18n.t(`liveShop.alert_delete`)}`,
                                                })
                                            }



                                        },
                                        title: 'adicionar',
                                    },
                                ]}
                                color='primary'
                            />
                        </div>
                    </div>
                    <ModalDelete confirmAction={() => liveDelete()} />
                </div>

            )
            }
        </>
    )
}

const LiveShop: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`liveShop.liveShop`), path: 'live_shop', isActive: true },
                ]}
            >
                {i18n.t(`liveShop.liveShop`)}
            </PageTitle>
            <LiveShopContent />
        </>
    )
}

export { LiveShop }
