import { FC } from 'react'
import QRCode from 'react-qr-code'
import { WebappButtonType } from '../../pages/Commons/Webapp'
import { i18n } from '../../translate/i18n'
import { Button } from '../Button/Button'

type Props = {
  title: string
  webappLink: string
  buttons: WebappButtonType[]
  showQrCode: boolean
}

const WebappInfo: FC<Props> = (props) => {
  const { title, webappLink, buttons, showQrCode = true } = props

  return (
    <div className='w-100 d-flex justify-content-between align-items-center mb-10 flex-wrap gap-10'>
      <div className='border rounded p-8' style={{ flex: 1 }}>
        <div>
          <h2 className='fs-3 text-gray-800'>{title}</h2>
          <div className='d-flex flex-wrap'>
            {buttons.map((btn, index) => (
              <Button
                key={`${btn.text} - ${index}`}
                icon={btn.icon}
                iconColor={btn.color}
                text={btn.text}
                click={() => btn.action()}
                color={btn.color}
                size='small'
                horizontalPadding={4}
                btnClass={`me-10 mt-5 bg-light-${btn.color} text-${btn.color} text-hover-white`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center justify-content-center'>
        {showQrCode && (
          <>
            <QRCode value={webappLink} size={180} />
            <a className='text-decoration-underline mt-2' href={webappLink} target="_blank">
              {i18n.t(`webapp.link`)}
            </a>
          </>
        )}

      </div>
    </div>
  )
}

const WebAppInfoHotel: FC<Props> = (props) => {
  const { title, webappLink, buttons, showQrCode = true } = props

  return (
    <div className='w-100 d-flex justify-content-between align-items-center mb-10 flex-wrap gap-10'>
      <div className='border rounded p-8' style={{ flex: 1 }}>
        <div>
          <h2 className='fs-3 text-gray-800'>{title}</h2>
          <div className='d-flex flex-column'>
            {buttons.map((btn, index) => (
              <Button
                key={`${btn.text} - ${index}`}
                icon={btn.icon}
                iconColor={btn.color}
                text={btn.text}
                click={() => btn.action()}
                color={btn.color}
                size='small'
                horizontalPadding={4}
                btnClass={`me-10 mt-5 bg-light-${btn.color} text-${btn.color} text-hover-white`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className='d-flex flex-column align-items-center justify-content-center w-100 gap-5'>
        {showQrCode && (
          <>
            <QRCode value={webappLink} size={180} />
            <a className='text-decoration-underline mt-2' href={webappLink} target="_blank">
              {i18n.t(`webapp.link`)}
            </a>
          </>
        )}

      </div>
    </div>
  )
}

export { WebappInfo, WebAppInfoHotel }
