import { FC, useEffect, useState } from "react"
import { PageTitle } from '../../../../../_metronic/layout/core'
import { i18n } from "../../../../translate/i18n"
import { Loading } from '../../../../components/Loading/Loading'
import { Datatable } from "../../../../components/Datatable/Datatable"
import { GET, POST } from "../../../../services/api"
import { Button } from '../../../../components/Button/Button'
import { BreadCrumbs } from "../../../../components/BreadCrumbs/breadCrumbrs"

type configResponse = {
    id: number,
    pluginType: string
    plugintemplateId: number,
    pluginspaceId: number
    content: string
}

type configField = {
    visible: boolean
    mandatory: boolean
    name: string
    idContent: number
}



const ConfiguracaoCheckoutContent: FC = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState<configField[]>([])
    const [dataRaw, setDataRaw] = useState<configResponse[]>([])
    const regexPattern = new RegExp("true");


    const getConfig = async () => {
        try {
            setLoading(true)
            const response = await GET('fieldconfig');

            setDataRaw(response.data)



            const data: any = [];

            response.data.map((config: configResponse) => {
                Object.entries(config.content).map((fieldConfig: any) => {
                    data.push({
                        visible: regexPattern.test(fieldConfig[1].visible),
                        name: fieldConfig[1].name,
                        mandatory: regexPattern.test(fieldConfig[1].mandatory),
                        idContent: config.id
                    })
                })
            })


            setData(data)


            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    const switchMandatoryStatus = async (configId: number, fieldName: string, value: boolean) => {

        try {

            setLoading(true)
            const newDataRaw = dataRaw.map((config: configResponse) => {
                Object.entries(config.content).map((fieldConfig: any) => {
                    if (fieldConfig[1].name === fieldName && config.id === configId) {
                        fieldConfig[1]['mandatory'] = '' + !value
                    }

                    return fieldConfig[1]
                })

                return config
            })

            setDataRaw(newDataRaw)

            await POST('fieldconfig', newDataRaw[0]);

            const newData = data.map((config: configField) => {

                if (config.idContent === configId && config.name === fieldName) {
                    config.mandatory = !value
                }
                return config;
            })

            setData(newData)

            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    const switchVisibleStatus = async (configId: number, fieldName: string, value: boolean) => {
        try {


            setLoading(true)
            const newDataRaw = dataRaw.map((config: configResponse) => {
                Object.entries(config.content).map((fieldConfig: any) => {
                    if (fieldConfig[1].name === fieldName && config.id === configId) {


                        fieldConfig[1]['visible'] = '' + !value
                    }

                    return fieldConfig[1]
                })

                return config
            })

            setDataRaw(newDataRaw)


            await POST('fieldconfig', newDataRaw[0]);


            const newData = data.map((config: configField) => {

                if (config.idContent === configId && config.name === fieldName) {
                    config.visible = !value
                }
                return config;
            })

            setData(newData)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }


    }


    useEffect(() => {
        getConfig()
    }, [])

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                        <div className='card-header py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`checkout.title`)}</span>
                                <BreadCrumbs />
                                <span className='text-muted mt-1 fw-bold fs-7'>
                                    {i18n.t(`checkout.here_are_listed_the_modalities_along_with_their_respective_actions`)}
                                </span>
                            </h3>
                            {/*
                          
                            <div className='card-toolbar'>
                                <Button
                                    text={'Salvar'}
                                    color='primary'
                                    iconColor='#FFF'
                                    icon='fas fa-plus'
                                    size='small'
                                    horizontalPadding={6}
                                    btnAttr={{
                                        type: 'button',
                                    }}
                                    click={() => console.log(dataRaw)}
                                    btnClass='fw-bolder'
                                />
                            </div>
                          */

                            }
                        </div>
                        <div className='card-body'>
                            <Datatable
                                data={data}
                                headers={[
                                    { key: i18n.t(`sms.name`), value: 'name', type: 'default' },
                                    {
                                        key: `${i18n.t(`checkout.active_mandatory`)}`,
                                        value: 'mandatory',
                                        type: 'check',
                                        action: (item) => {
                                            switchMandatoryStatus(item.idContent, item.name, item.mandatory)
                                        },
                                    },
                                    {
                                        key: `${i18n.t(`checkout.active_visibility`)}`,
                                        value: 'visible',
                                        type: 'check',
                                        action: (item) => {
                                            switchVisibleStatus(item.idContent, item.name, item.visible)
                                        },
                                    }
                                ]}
                                color='primary'
                            />
                        </div>
                    </div>
                </div>
            )
            }
        </>
    )
}

const ConfiguracaoCheckout: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`account.edit_account`), path: 'configuracoes/checkout', isActive: true },
                ]}
            >
                {i18n.t(`account.account`)}
            </PageTitle>
            <ConfiguracaoCheckoutContent />
        </>
    )
}

export { ConfiguracaoCheckout }