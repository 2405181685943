import { FC } from "react"
import SVG from 'react-inlinesvg';
import { Input } from "../Input/Input";





type ButtomSvgWebApp = {
  id: number;
  name: string;
  icon: string;
  svg?: any;
  auth: number;
  routeType: string;
  routeContent: string;
  status: boolean;
  background?: any;
  color?: any;
  order: number;
  appConfigId: number;
  pluginspaceId: number;
  idSvgwebApp: number;
  svgWebApp?: SvgWebApp;
  svgWebApp2?: string
}

type SvgWebApp = {
  id: number;
  svg: string;
  description: string;
  category: string;
}

type Props = {
  value: ButtomSvgWebApp,
  action?: () => void
  updateStatus?: () => void

  edit?: () => void

  deleteItem?: () => void
}


const AppButton: FC<Props> = ({ value, action, updateStatus, edit, deleteItem }) => {

  return (
    <>
      <div className='w-25 d-flex justify-content-center align-items-center'>
        <span>{value.order}</span>
      </div>
      <div className='w-25 d-flex justify-content-center align-items-center ' onClick={action}>
        {value.svgWebApp2 ? (<SVG src={value.svgWebApp2} style={{
          width: '35px',
          height: '35px'
        }} />) : (<>
          <i className='bi bi-card-image' style={{
            fontSize: '35px',
            height: '35px'
          }}

          />
        </>)

        }
      </div>
      <div className='w-25 d-flex justify-content-center align-items-center '>
        <span>{value.name}</span>
      </div>
      <div className='w-25 d-flex justify-content-center align-items-center '>
        <span>{value.routeType}</span>

      </div>
      <div className='w-25 d-flex justify-content-center align-items-center '>
        <Input
          value={value.status ? '' : ''}
          checked={value.status}
          change={() => (updateStatus ? updateStatus() : {})}
          inputAttr={{
            type: 'checkbox',
          }}
          checkSwift={true}
        />
      </div>
      <div className='w-25 d-flex justify-content-center align-items-center gap-5'>
        <>
          {edit ? (

            <i
              className="fas fa-pen"
              style={{
                fontSize: '16px'
              }}
              onClick={edit}
            />
          ) : (<></>)

          }
          {deleteItem ? (

            <i
              className="fas fa-trash"
              style={{
                fontSize: '16px'
              }}
              onClick={deleteItem}
            />
          ) : (<></>)

          }
        </>
      </div>
    </>
  )
}

export { AppButton }