/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, MouseEvent, useEffect, useState, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import { formatCpfCnpj, formatPhone } from '../../../utils/formater'
import moment from 'moment'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import uuid from 'react-uuid'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'
import useAuth from '../../../hooks/useAuth'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import usePluginsSpace from '../../../hooks/usePluginSpace'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

type mostraSetor = {
    value: string
    select: string
}

type Form = {
    order: number,
    name: string,
    id: number,
    path: string,
    imgSuffix: string,
    typeImg: string,
    startDate: string,
    endDate: string, 
    status: boolean,
    classificationId: string,
    date: string,
    subCategoria: string,
    link: string,
    linkStatus: boolean,
}

type store = {
    id: number;
    pluginspaceId: number;
    aggregatorId: number;
    classificationId: number;
    order: number;
    name: string;
    description: string;
    image: string;
    link: string;
    type: string;
    active: boolean;
    exclusive: boolean;
    exclusivo?: string;
    instagram?: any;
    phoneWhatsapp: string;
    latitude: string;
    longitude: string;
    zipCode: number;
    created: string;
    updated: string;
    status?: string
    subdomain?: string
}

const ContaContent: FC = () => {
  const navigate = useNavigate()
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false)
  const [showCategorias, setShowCategorias] = useState<mostraSetor[]>([])
  const [showSubCategorias, setShowSubCategorias] = useState<mostraSetor[]>([])
  const [saveSubcategoria, setSaveSubcategoria] = useState<mostraSetor[]>([])
  const [categoriaCreate, setCategoriaCreate] = useState<string>('')
  const [subCategoriaCreate, setSubCategoriaCreate] = useState<any>(null);
  const [lojaCreate, setLojaCreate] = useState<any>(null);
  const [showStore, setShowStore] = useState<mostraSetor[]>([])
  const [allStores, setAllStores] = useState<store[]>([])
  const [ordemCreate, setOrdemCreate] = useState<number>(0);
  const [status, setStatus] = useState<any>(false);
  const [statusLink, setStatusLink] = useState<any>(false);
  const [dateInit, setDateInit] = useState<any>(null);
  const [dateFin, setDateFin] = useState<any>(null);
  const [lojaLink, changeLojaLink] = useState<any>(null);
  const [hourInit, setHourInit] = useState<string>(' 00:00:00');
  const [imagePricipal, setImagePricipal] = useState<any>(null)
  const [showImages, setShowImages] = useState<any>([])
  const { showWrapperModal, closeWrapperModal } = useModals()
  const { pluginSpace } = usePluginsSpace()
  const [lojasPubli, setLojasPubli] = useState<any[]>([])
  const [url, setUrl] = useState<any[]>([])
  const [imageId, setImageId] = useState<any[]>([])

  const getCategories = async (id: number) => {
    try {
        const res = await POST(`/classification/filter`, {
        plugintypeId: getPlugintypeIdByCode('service'),
        deleted: 'not show',
        })

        let subCategorys =  res.data.filter((classification:any) => classification.parent !== null)
        await safeSubcategory(subCategorys);

        const showCategorias = subCategorys.map((categoria: any, i:any) => {
            return {
            select: categoria.name,
            value: categoria.id,
            }
        })

        const subCategoryCreate = subCategorys.filter((subCat: any) => {
            const getCategoria = subCat.parent.split("/");
            if(Number(getCategoria[1]) === id) return true;
        })

        const subCategoryCreateFilter = subCategoryCreate.map((categoria: any, i:any) => {
            return {
            label: categoria.name,
            value: categoria.id,
            }
        })

        setCategoriaCreate(subCategoryCreateFilter[0]);

        const selectUnicos = new Set<string>();
        const showCategoriasUnicas = showCategorias.filter((obj: any)=> {
        if (!selectUnicos.has(obj.select)) {
            selectUnicos.add(obj.select);
            return true;
        }
        return false;
        });

        setShowCategorias(showCategoriasUnicas)
        // setCategoriaCreate()
        
    } catch (e) {
      console.log(e)
    }
  }

  const safeSubcategory = async (subCat: any) => {
    setSaveSubcategoria(subCat);
  }

  const selectSubCategory = async (nameCategory: any) => {
    try {
        const showSubCategory = saveSubcategoria.filter(item => {
            const itemValues = Object.values(item);
            return itemValues.includes(nameCategory);
        });
    
        const showCategoriasFilter = showSubCategory.map((subCategoria: any) => {
            return {
            select: subCategoria.shortname,
            value: subCategoria.id,
            }
        })
    
        setShowSubCategorias(showCategoriasFilter);
    } catch (error) {
        console.log(error);
    }
  }

  async function getData(valueId: any, flag: any, lojaSelect: any) {
    try {
        const res = await GET('pluginspaceaggregator')
        if (res.success) {
            const data: store[] = res.data

            const showLojas = data.filter(item => {
                const itemValues = Object.values(item);
                return itemValues.includes(Number(valueId));
            });

            if(showLojas.length === 0) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Atenção',
                    text: 'Não há nenhuma loja cadastrada para essa subcategoria.',
                })
            } else {
                const showLojasFilter = showLojas.map((subCategoria: any) => {
                    return {
                    select: subCategoria.name,
                    value: subCategoria.id,
                    }
                })

                setShowStore(showLojasFilter)
                setAllStores(data)

                if(flag === true) {
                    await getImageStore(lojaSelect, data);
                }
            }
        }
    } catch (error) {
        Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao recuperar lojas',
        })
        console.log(error)
    }
    }

    useEffect(() => {
        init();
      }, []);

      const init = async () => {
        setLoading(true)
        await getPluginspace()
        setLoading(false)
      }

      const getPluginspace = async () => {
        try {
            setLoading(true)
            const urlTotal = (window.location.href).split('/');
            const urlId: any = urlTotal[urlTotal.length - 1];
            setUrl(urlId);

            const res = await GET(`/publication-aggregator/${urlId}`)
            const lojasPubli = res.data[0];

            const parentId = lojasPubli.classification.parent.split('/')[1]
            await getCategories(Number(parentId));

            const subCategoria = {
                label: lojasPubli.classification.shortname,
                value: lojasPubli.classification.id,
            }
            setSubCategoriaCreate(subCategoria)

            const lojaSelect =  {
                label: lojasPubli.store.name,
                value: lojasPubli.store.id,
            }
            setLojaCreate(lojaSelect);
            await getData(subCategoria.value, true, lojaSelect);

            const orderSelect = lojasPubli.order
            setOrdemCreate(orderSelect);

            const orderStatusSelect = lojasPubli.status
            setStatus(orderStatusSelect)

            const [yearStart, monthStart, dayStart] = lojasPubli.startDate.split('T')[0].split('-');
            const formattedDateStart = `${yearStart}-${monthStart}-${dayStart}`;
            setDateInit(formattedDateStart)

            const [yearEnd, monthEnd, dayEnd] = lojasPubli.endDate.split('T')[0].split('-');
            const formattedDateEnd = `${yearEnd}-${monthEnd}-${dayEnd}`;
            setDateFin(formattedDateEnd)

            const linkLojaStatus = lojasPubli.statusLink
            setStatusLink(linkLojaStatus)

            const linkText = lojasPubli.link
            changeLojaLink(linkText)

            const image = lojasPubli.image.path + lojasPubli.image.imgSuffix;
            setImagePricipal(image)
            setImageId(lojasPubli.imageId)

            setLojasPubli(lojasPubli);
            
            setLoading(false)
        } catch (e) {
          setLoading(false)
          console.log(e)
        }
      }

    const quebrarURL = async (url: any) => {
        const dividirUrl = url.split("https://s3.amazonaws.com/img.ninegrid.com/");
        
        return dividirUrl[1];
    }   

    const imageSelect = async () => {
        try {
            // showImages.map((image: any) => console.log(image.image))
    
            showWrapperModal('Imagens - Banner da loja')
        } catch (error) {
            console.log(error);   
        }
    }

    const imageUploadSelect = async (event: any) => {
        try {
            const file = event.target.files[0];
            const formData = new FormData()
            const name = (file.name).slice(0, 3) || 'image';
            formData.append('file', file)
            formData.append('nameImage', `store-${name}-${uuid()}.jpg`)
            formData.append('originalSize', 'true')
            const imageRes = await POST('/upload-images/upload-one', formData)
            if(imageRes.success) sendNewImage(imageRes.image)
            
        } catch (error) {
            console.log(error);   
        }
    }

    async function sendNewImage(image1: any) {
        try {
            const resUrl = await quebrarURL(image1);
            
            const imageRes = await POST('pluginspaceimages', {
                aggregatorId: pluginSpace?.id,
                pluginspaceId: Number(subCategoriaCreate.value),
                order: ordemCreate,
                path: 'https://s3.amazonaws.com/img.ninegrid.com/',
                imgSuffix: resUrl,
                typeImg: 'image',
            })

            setImageId(imageRes.data.data.id);

            setImagePricipal(image1);
            
        } catch (error) {
            return ''
        }
    }

    const getImageStore = async (store: any, data: any) => {
        try {
            const idStore = store.value;
            let getImageStore;
            
            if(data != false){
                const getStore: any = data.filter((storeCmp: any) => storeCmp.id === idStore)
                getImageStore = getStore[0].config.images;
            }
            else {
            
                const getStore: any = allStores.filter((storeCmp: any) => storeCmp.id === idStore)
                getImageStore = getStore[0].config.images;
            }

            setShowImages(getImageStore);
        } catch (error) {
            console.log(error);
            
        }
    }

    const update = async () => {
        try {
            if (!dateInit || !dateFin || !lojaCreate.value || !subCategoriaCreate.value || !imagePricipal) {
                Swal.fire({
                  icon: 'error',
                  title: 'Campos faltantes',
                  text: 'Verifique se preencheu todos os campos!',
                })
            } else {
                
                const res = await PUT(`publication-aggregator/${url}`, {
                    order: ordemCreate,
                    classificationId: subCategoriaCreate.value,
                    startDate: dateInit + hourInit,
                    endDate: dateFin + hourInit,
                    status: status,
                    storeId: lojaCreate.value,
                    statusLink: statusLink,
                    link: lojaLink || '',
                    imageId: imageId,
                })

                if (res.success === true) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Publicidade modificada',
                      text: 'Modificado com sucesso!',
                    }).then(() => navigate('/agregador/publicidade'))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao modificar a publicidade!',
                    })
                }
            }
        } catch (error) {
            console.log(error); 
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao modificar a publicidade!',
            })
        }
    }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="card py-4 px-11">
            <div className='row'>
                <div>
                    <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>Editar Banner Destaque</span>
                    <BreadCrumbs />
                    <span className='text-muted mt-1 fw-bold fs-7'>Preencha as informações abaixo e clique no botão salvar para editar um novo banner.</span>
                    </h3>
                </div>
                <div className="col d-flex flex-column border-end mb-8"> 
                    <div className='d-flex gap-12'>
                        <div className='w-50 mt-6'>
                            <div>
                                <label className="form-label fw-normal">Categoria</label>
                                <Input
                                inputAttr={{
                                    type: 'select-publicidade',
                                }}
                                options={showCategorias}
                                placeholder={'Categoria'}
                                search={true}
                                value={categoriaCreate}
                                change={(value: any) => {
                                    setCategoriaCreate(value);
                                    setSubCategoriaCreate('');
                                    setLojaCreate('');
                                    setShowStore([]);
                                    selectSubCategory(value.label);
                                    setImagePricipal(''); 
                                }}
                                />
                            </div>
                        </div>
                        <div className='w-50 mt-6'>
                            <div>
                                <label className="form-label fw-normal">Subcategoria</label>
                                <Input
                                inputAttr={{
                                    type: 'select-publicidade',
                                }}
                                options={showSubCategorias}
                                placeholder={'Subcategoria'}
                                search={true}
                                value={subCategoriaCreate}
                                change={(value: any) => {
                                    setSubCategoriaCreate(value);
                                    getData(value.value, false, '');
                                    setImagePricipal('');
                                }}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='mt-6'>
                            <label className="form-label fw-normal">Nome da Loja</label>
                            <Input
                                inputAttr={{
                                    type: 'select-publicidade',
                                }}
                                options={showStore}
                                placeholder={'Loja'}
                                search={true}
                                value={lojaCreate}
                                change={(value: any) => {
                                    setLojaCreate(value);
                                    getImageStore(value, false);
                                    setImagePricipal('');               
                                }}
                            />
                        </div>
                    </div>
                    <div className='mt-9 d-flex gap-12'>
                        <div  className='d-flex gap-2'>
                            <div className='align-self-center'>
                                <label>Ordem</label>
                            </div>
                            <Input
                            inputAttr={{
                                type: 'number-agregador',
                            }}
                            placeholder={'0'}
                            value={ordemCreate}
                            change={(value: number) => setOrdemCreate(value)}
                            />
                        </div>
                        <div className='d-flex'>
                            <Input
                                inputAttr={{
                                type: 'checkbox',
                                }}
                                search
                                label={'Ativo'}
                                value={status}
                                checked={status}
                                checkSwift={true}
                                change={(value: any) => {
                                setStatus(value)
                                }}
                            />
                        </div>
                    </div>
                    <div className='d-flex gap-6 mt-2'>
                        <div className='d-flex flex-column justify-content-center'>
                        <img
                            src={toAbsoluteUrl('/media/svg/aggregator/calendario.svg')}
                            alt=''
                            className='h-42px'
                        />
                        </div>
                        <div className='d-flex gap-6 mt-5'>
                            <Input
                                inputAttr={{
                                    type: 'date',
                                }}
                                label={'Data de inicio'}
                                value={dateInit}
                                change={(value: string) =>
                                    setDateInit(value)
                                }
                            />
                            <Input
                                inputAttr={{
                                    type: 'date',
                                }}
                                label={'Data de fim'}
                                value={dateFin}
                                change={(value: string) =>
                                    setDateFin(value)
                                }
                            />
                        </div>
                    </div>
                    <div className='mt-8 d-flex align-itens-center justify-content-between flex-wrap'>
                        <div className='d-flex align-itens-end'>
                            <div>
                                <Input
                                    inputAttr={{
                                        type: 'checkbox',
                                        }}
                                        search
                                        value={statusLink}
                                        checked={statusLink}
                                        label='Link para loja'
                                        change={(value: any) => {
                                            setStatusLink(value)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='d-flex align-itens-end gap-3'>
                            <Input
                                inputAttr={{
                                type: 'link-agregador',
                                }}
                                value={lojaLink}
                                label='Link:'
                                change={(value: any) => {
                                    changeLojaLink(value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="col d-flex flex-column " style={{ gap: '62px' }}> 
                    <div className='d-flex flex-cloumn justify-content-center'>
                        <div  className='d-flex flex-column gap-6'>
                            <div onClick={imageSelect} style={{cursor: 'pointer', fontSize: '1.4rem'}}>
                                <div className='d-flex gap-8 align-items-center'>
                                    <img
                                        src={toAbsoluteUrl('/media/svg/aggregator/image.svg')}
                                        alt=''
                                        className='h-42px'
                                    />
                                    <span>Usar imagens de bannes cadastrados no loja</span>
                                </div>
                            </div>
                            <div style={{cursor: 'pointer'}}>
                                <div className='d-flex gap-8 align-items-center'>
                                    <img
                                        src={toAbsoluteUrl('/media/svg/aggregator/upload.svg')}
                                        alt=''
                                        className='h-42px'
                                    />
                                    <input
                                        type='file'
                                        accept='.jpg, .jpeg, .png, .gif'
                                        name='file'
                                        onChange={(event) => imageUploadSelect(event)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-cloumn justify-content-center'>
                        <Input
                            inputAttr={{
                            type: 'image-agregador',
                            }}
                            width="400"
                            height="250"
                            value={imagePricipal}
                        />
                    </div>
                </div>
            </div>
        </div>
          )}


          <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
          <Button
                text={i18n.t(`buttons.back`)}
                color='info'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                    type: 'button',
                }}
                click={() => navigate(-1)}
                btnClass='fw-bolder m-9'
            />
          <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => update()}
              btnClass='fw-bolder'
            />
          </div>
          <ModalWrapper confirmAction={() => {
          }}
            maxWidth={true}
            width={40}
          >
            <div className="d-flex  flex-column gap-5 card"
              style={{
                height: '20rem'
              }}>
              <div className="card-body d-flex gap-5 flex-row flex-wrap overflow-auto justify-content-center">
                {
                    showImages.map((image: any)=> (
                    <div className={`d-flex flex-column p-4 border border-2 ${image.image === imagePricipal ? 'border-primary' : 'border-none'} cursor-pointer`}
                        onClick={() => sendNewImage(image.image)}>
                        <img src={image.image} style={{ width: '150px', height: '150px' }}/>
                    </div>
                    ))
                }
              </div>
            </div>

          </ModalWrapper>
    </>
  )
}

const EditarPublicidadeAgregador: FC = () => {
    return (
      <>
        <PageTitle
          breadcrumbs={[
            { title: 'Dashboard', path: 'dashboard', isActive: true },
            { title: i18n.t(`Editar publicidade agregador`), path: '/agregador/publicidade/update', isActive: true },
          ]}
        >
          {i18n.t(`Editar publicidade agregador`)}
        </PageTitle>
        <ContaContent />
      </>
    )
  }
  
  export { EditarPublicidadeAgregador }
  