import {FC} from 'react'
import styles from './index.module.css'
import { i18n } from '../../translate/i18n';

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

type CardIAType = {
    name: string;
    templateId: number;
    description: string;
    image: string | null;
    able: boolean;
    hasConfig: boolean;
    activeItem: (plugin: number) => Promise<void>
}

const CardIA: FC<CardIAType> = ({name, templateId, description, image, able, hasConfig, activeItem}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.cardItem} >
        <div>
            <div className={styles.cardImage}>
                {image ? <img src={image} alt={name} /> : <p className='d-flex flex-row gap-2 mb-0 align-items-center'><i className="fa fa-money-bill" style={{ color: '#E19C15' }}></i>{name}</p>}
            </div>
            <div className={styles.cardDescription}>
                <h4>{name}</h4>
                {description && (
                    <p>{description}</p>
                )}
            </div>
        </div>
        <div className='d-flex flex-column gap-2'>
            <div className={styles.cardIA}>
                <button onClick={async () => await activeItem(templateId)} className={able ? styles.cardActive : styles.cardInactive}>{able ? 'Ativo' : 'Inativo'}</button>
            </div>
            <div className={styles.cardConfig}>
                <button onClick={() => navigate(hasConfig ? `/ia/config/${templateId}` : `/ia/config/criar/${templateId}`)}>
                    {i18n.t(`payment.config`)}
                </button>
            </div>
        </div>
    </div>
  )
}

export {CardIA}
