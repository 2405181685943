/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import useModals from '../../../../hooks/useModals'
import { useNavigate } from 'react-router-dom'
import { DELETE, POST } from '../../../../services/api'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { getPlugintypeIdByCode } from '../../../../functions/plugintype'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type IntegrationType = {
  id: number;
  name: string;
  type: string;
  templateType: string;
  typeId: number;
}

type IntegrationResponseType = {
  id: number;
  name: string;
  plugintemplateId: {
    id: number;
    name: string;
    parameters: string;
    plugintypeId: number;
    typeIntegration: string;
  }
}


const OutrosContent: FC = () => {

  const {showDeleteModal} = useModals()
  const {loginInfo} = useAuth();

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState<Array<IntegrationType>>([]);
  const [id, setId] = useState<number | null>(null);
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const getIntegrations = async (take: number = 10, page: number = 1) => {
    try {
      setLoading(true);
      const integrationRes = await POST(`/pluginconfig/filter`, {})
      const newIntegrations:IntegrationType[] = integrationRes.data.map((pluginconfig:IntegrationResponseType) => {
        return {
          id: pluginconfig.id,
          name: pluginconfig.name,
          type: pluginconfig.plugintemplateId.name,
          typeId: pluginconfig.plugintemplateId.plugintypeId,
          templateType: pluginconfig.plugintemplateId.typeIntegration
        }
      })
      let integrationsGet:IntegrationType[] = []
      const plugincode = getPlugintypeIdByCode(loginInfo.plugintype);
      newIntegrations.forEach((integration) => {
        if(integration.templateType === 'others' && (integration.typeId === plugincode || integration.typeId === 1)){
          integrationsGet.push(integration)
        }
      })
      setIntegrations(integrationsGet);
      setMeta(integrationRes.meta)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const deleteIntegration = async () => {
    try{
      setLoading(true);
      const integrationRes = await DELETE(`/pluginconfig/${id}`);
      if(integrationRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'Integração deletada',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getIntegrations())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar integração!'
        }).then(async () => await getIntegrations())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar integração!'
      }).then(async () => await getIntegrations())
      console.log(e)
    }
  }

  useEffect(() => {
    getIntegrations();
  }, [])

  return (
    <>
      { loading ? <Loading /> :
      <div className='row g-5 gx-xxl-12'>
        <div className='card card-xxl-stretch mb-5 mb-xl-12'>
          <div className='card-header py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`integration.others`)}</span>
              <BreadCrumbs />
              <span className='text-muted mt-1 fw-bold fs-7'>
                {i18n.t(`integration.listed_here_are_the_integrations_along_with_their_respective_actions`)}
              </span>
            </h3>
            <div className='card-toolbar'>
              <Button
                text={i18n.t(`buttons.add_integration`)}
                color='primary'
                iconColor='#FFF'
                icon='fas fa-plus'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                  type: 'button',
                }}
                click={() => navigate('/pluginconfig/others/create')}
                btnClass='mb-5 fw-bolder'
              />
            </div>
          </div>
          <div className='card-body'>
            <Datatable
              data={integrations}
              headers={[
                {key: i18n.t(`integration.integration_name`), value: 'name', type: 'default'},
                {key: i18n.t(`integration.type_of_integration`), value: 'type', type: 'default'},
                {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
              ]}
              options={[
                {
                  icon: 'fas fa-pen',
                  action: (item) => {
                    navigate(`/pluginconfig/others/edit/${item.id}`)
                  },
                  title: `${i18n.t(`general.edit`)} ${i18n.t(`integration.integration`)}`,
                },
                {icon: 'fas fa-trash', action: (item) => {
                  showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`integration.integration`)}`)
                  setId(item.id);
                }, title: `${i18n.t(`general.delete`)} ${i18n.t(`integration.integration`)}`},
              ]}
              color='primary'
            />
          </div>
        </div>
        <ModalDelete confirmAction={() => deleteIntegration()} /> 
      </div>}
    </>
  )
}

const Outros: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`integration.others`), path: 'pluginconfig/others', isActive: true},
        ]}
      >
        {i18n.t(`integration.others`)}
      </PageTitle>
      <OutrosContent />
    </>
  )
}

export {Outros}
