/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, MouseEvent, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../../services/api'
import { formatCpfCnpj, formatPhone } from '../../../../utils/formater'
import moment from 'moment'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type Form = {
  hash: string
  name: string
  corporatename: string
  cnpj: string
  appLogin: boolean
  subdomain: string
  type: string
  status: boolean
  storeCode: string
  defaultlanguage: string
  defaultcurrency: string
  zipcode: string
  emailContact: string
  phoneContact: string
  address: {
    street: string
    number: string
    extra: string
    district: string
    city: string
    state: string
  }
}

type User = {
  email: string
  name: string
}
const itemMenu = {
  height: '100%',
  padding: '20px 20px 0',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  borderBottom: '4px solid #FFFFFF',
}
const itemMenuActive = {
  ...itemMenu,
  color: '#6993ff',
  borderBottom: '4px solid #6993ff',
}

const itemMenuInactive = {
  ...itemMenu,
  color: 'black',
  borderBottom: '4px solid #CCCCCC',
  marginLeft: 5,
  marginRight: 5,
}

const ContaContent: FC = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('#tab1')
  const [redeSocialOption, setRedeSocialOption] = useState([
    {
      select: 'Selecione',
      value: ''
    },
    {
      select: 'Facebook',
      value: 'FACEBOOK'
    },
    {
      select: 'Instagram',
      value: 'INSTAGRAM'
    },
    {
      select: 'TikTok',
      value: 'TICTOC'
    },
    {
      select: 'Site',
      value: 'SITE'
    }
  ])
  const [redeSocialType, setRedeSocialType] = useState('')
  const [redeSocialNome, setRedeSocialNome] = useState('')
  const [redeSocialUrl, setRedeSocialUrl] = useState('')

  const [pluginspaceSocialMedias, setPluginspaceSocialMedias] = useState<any>([])

  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState<Form>({
    hash: '',
    name: '',
    corporatename: '',
    cnpj: '',
    subdomain: '',
    type: '',
    status: true,
    appLogin: false,
    storeCode: '',
    defaultlanguage: 'pt',
    defaultcurrency: 'R$',
    emailContact: '',
    phoneContact: '',
    zipcode: '',
    address: {
      street: '',
      number: '',
      extra: '',
      district: '',
      city: '',
      state: '',
    },
  })

  const [id, setId] = useState(0)

  const [user, setUser] = useState<User>({
    email: '',
    name: '',
  })

  const getPluginspace = async () => {
    try {
      setLoading(true)
      const res = await GET(`/pluginspace/this`)
      if (res && res.data && res.data[0]) {
        const data = res.data[0]
        setId(data.id)
        let _form = {
          hash: data.hash,
          appLogin: data.appLogin,
          name: data.name,
          corporatename: data.corporateName,
          cnpj: data.cnpj,
          subdomain: data.subdomain,
          type: data.type,
          status: data.status,
          storeCode: data.storeCode,
          defaultlanguage: data.defaultLanguage,
          defaultcurrency: data.defaultCurrency,
          phoneContact: data.phoneContact ?data.phoneContact : '',
          emailContact: data.emailContact ?data.emailContact : '' ,
          zipcode: '',
          address: {
            street: '',
            number: '',
            extra: '',
            district: '',
            city: '',
            state: '',
          },
        }
        if (data.address && data.address.address) {
          _form.zipcode = data.address.cep
          _form.address = data.address.address
        }
        setForm(_form)
        if (data.user) {
          setUser({
            email: data.user.email,
            name: data.user.name,
          })
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const update = async () => {
    try {
      let newForm = Object.assign(form)
      newForm.cnpj = formatCpfCnpj(newForm.cnpj)
      newForm.zipcode = newForm.zipcode.replace('-', '')
      setLoading(true)
      if (id) {
        const res = await PUT(`/pluginspace/ninegrid/${id}`, newForm)
        setLoading(false)
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: 'Conta alterada!',
            text: 'Sua conta foi editada com sucesso!',
          })
        } else {
          Swal.fire({
            icon: res.typemsg ? res.typemsg : 'error',
            title: (res.typemsg && res.typemsg === 'warning') ? 'Atenção!' : 'Erro',
            text: res.message ? res.message : 'Não foi possível alterar a conta!'
          })
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Não foi possível alterar a conta!',
        })
      }
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: (typeof e === 'string' && e.includes('alterada')) ? 'warning' : 'error',
        title: (typeof e === 'string' && e.includes('alterada')) ? 'Atenção' : 'Erro',
        text: (e.response && e.response.message && e.response.message[0]) ?
          e.response.message[0] :
          (typeof e === 'string' ? e : 'Não foi possível alterar a conta!')
      })
    }
  }

  async function getAllSocialMedia() {
    try {
      const res = await GET('/socialnetwork')

      if (res.data) {
        setPluginspaceSocialMedias(res.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPluginspace()
    getAllSocialMedia()
  }, [])

  const getCEP = async (value: string) => {
    value = value.replace(/[^\d]/g, '')
    if (value && value.length === 8) {
      try {
        setLoading(true)
        const res = await GET(`https://viacep.com.br/ws/${value}/json/`)
        setForm({
          ...form,
          address: {
            ...form.address,
            street: res.logradouro,
            district: res.bairro,
            city: res.localidade,
            state: res.uf,
          },
        })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }

  function updateTypeInArray(id: number, value: string) {
    const newArray = pluginspaceSocialMedias.map((sm: any) => {

      if (id === sm.id) {
        sm.type = value;
      }

      return sm
    })
    setPluginspaceSocialMedias(newArray)
  }

  function updateUrlInArray(id: number, value: string) {
    const newArray = pluginspaceSocialMedias.map((sm: any) => {

      if (id === sm.id) {
        sm.url = value;
      }

      return sm
    })
    setPluginspaceSocialMedias(newArray)
  }

  function updateNameInArray(id: number, value: string) {
    const newArray = pluginspaceSocialMedias.map((sm: any) => {

      if (id === sm.id) {
        sm.name = value;
      }

      return sm
    })
    setPluginspaceSocialMedias(newArray)
  }


  async function editSocialMedia(id: number) {
    try {
      setLoading(true)
      const data = pluginspaceSocialMedias.find((el: any) => el.id === id)

      delete data.id

      const response = await PUT(`/socialnetwork/${id}`, data)

      setLoading(false)
      if (response.success) {
        await getAllSocialMedia()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso'
        })
      }

      setLoading(false)
    } catch (error) {
      Swal.fire({
        icon:'error',
        title: 'Erro',
        text:'Erro ao editar Rede social'
      })
      console.log(error)
      return null
    }
  }
  async function deleteSocialMedia(id: number) {
    try {
      setLoading(true)
      const response = await DELETE(`/socialnetwork/${id}`)
      setLoading(false)
      if (response.success) {
        await getAllSocialMedia()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso'
        })
      }

      setLoading(false)
    } catch (error) {
      Swal.fire({
        icon:'error',
        title: 'Erro',
        text:'Erro ao deletar Rede social'
      })
      console.log(error)
      return null
    }
  }

  async function createSocialMedia(){
    try {
      if(  redeSocialNome === '' || redeSocialType === '' || redeSocialUrl === ''){
        Swal.fire({
          icon:'warning',
          title: 'Antenção',
          text:'Revise os campos'
        })
        return 
      }

      setLoading(true)
      const data = {
        name: redeSocialNome,
        type: redeSocialType,
        url : redeSocialUrl
      }

      const response = await POST('/socialnetwork',data)

      setLoading(false)
      if(response.success){
        setRedeSocialNome('')
        setRedeSocialType('')
        setRedeSocialUrl('')
        await getAllSocialMedia()

        Swal.fire({
          icon:'success',
          title: 'Sucesso',
          text:'Rede social criada'
        })
      }else{
        Swal.fire({
          icon:'error',
          title: 'Erro',
          text:'Erro ao criar Rede social'
        })
      }


    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon:'error',
        title: 'Erro',
        text:'Erro ao criar Rede social'
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='w-100'>
            <div className='card'>
              <div className='d-flex' style={{ padding: '0 2.25rem', height: 75 }}>
                <div
                  style={activeTab === '#tab1' ? itemMenuActive : itemMenuInactive}
                  onClick={() => setActiveTab('#tab1')}
                >
                  <span>Informações básicas</span>
                </div>
                <div
                  style={activeTab === '#tab2' ? itemMenuActive : itemMenuInactive}
                  onClick={() => setActiveTab('#tab2')}
                >
                  <span>Redes Socias</span>
                </div>
              </div>
            </div>
          </div>
          {activeTab === '#tab1' && (<div className='d-flex justify-content-between flex-wrap gap-5'>
            <div style={{ flex: 1 }}>
              <div className='w-100 card'>

                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`account.edit_account`)}</span>
                    <BreadCrumbs />
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <Input
                    inputAttr={{
                      type: 'cnpj',
                    }}
                    disabled
                    placeholder={i18n.t(`account.registration_number`)}
                    label={i18n.t(`account.registration_number`)}
                    value={form.cnpj || ''}
                    change={(value: any) => setForm({ ...form, cnpj: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    disabled
                    placeholder={i18n.t(`account.corporate_name`)}
                    label={i18n.t(`account.corporate_name`)}
                    value={form.corporatename || ''}
                    change={(value: any) => setForm({ ...form, corporatename: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.name_of_the_establishment`)}
                    label={i18n.t(`account.name_of_the_establishment`)}
                    value={form.name || ''}
                    change={(value: any) => setForm({ ...form, name: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.subdomain`)}
                    label={`${i18n.t(`account.subdomain`)} ( subdomínio.ninegrid.com.br )`}
                    value={form.subdomain || ''}
                    change={(value: any) => setForm({ ...form, subdomain: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label='Login no WebApp'
                    change={(value: boolean) => setForm({ ...form, appLogin: value })}
                    value={form.appLogin}
                    checked={form.appLogin}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.code`)}
                    label={i18n.t(`account.code`)}
                    value={form.storeCode || ''}
                    change={(value: any) => setForm({ ...form, storeCode: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`account.default_language`)}
                    options={[{ select: 'Português', value: 'pt' }]}
                    value={form.defaultlanguage || ''}
                    change={(value: any) => setForm({ ...form, defaultlanguage: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`account.default_currency`)}
                    options={[{ select: 'Real', value: 'R$' }]}
                    value={form.defaultcurrency || ''}
                    change={(value: any) => setForm({ ...form, defaultcurrency: value })}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    disabled
                    placeholder='Hash'
                    label='Hash'
                    value={form.hash || ''}
                  />
                   <Input
                    inputAttr={{
                      type: 'email',
                    }}
                    placeholder='Email de contato'
                    label='Email de contato'
                    value={form.emailContact || ''}
                    change={(value: any) => setForm({ ...form, emailContact: value })}
                  />
                   <Input
                    inputAttr={{
                      type: 'tel',
                    }}
                    placeholder='Número de contato'
                    label='Número de contato'
                    value={form.phoneContact || ''}
                    change={(value: any) => setForm({ ...form, phoneContact: value })}
                  />
                </div>
              </div>
            </div>
            <div className='justify-content-between' style={{ flex: 1 }}>
              <div className='w-100 card ms-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>
                      {i18n.t(`account.login_of_person_in_charge`)}
                    </span>
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.name_of_person_in_charge`)}
                    label={i18n.t(`account.name_of_person_in_charge`)}
                    disabled={true}
                    value={user.name}
                  />

                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder='Login'
                    label='Login'
                    disabled={true}
                    value={user.email}
                  />
                </div>
              </div>
              <br />
              <div className='w-100 card ms-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`account.address`)}</span>
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <div className='d-flex justify-content-between'>
                    <div className='me-3 flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'cep',
                        }}
                        placeholder={i18n.t(`account.zip_code`)}
                        label={i18n.t(`account.zip_code`)}
                        value={form.zipcode || ''}
                        change={(value: any) => setForm({ ...form, zipcode: value })}
                        blur={(value: string) => getCEP(value)}
                      />
                    </div>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder={i18n.t(`account.public_place`)}
                      label={i18n.t(`account.public_place`)}
                      value={form.address.street || ''}
                      change={(value: any) =>
                        setForm({ ...form, address: { ...form.address, street: value } })
                      }
                    />
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div className='me-3 flex-grow-1'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`account.number`)}
                        label={i18n.t(`account.number`)}
                        minNumber={0}
                        value={form.address.number || ''}
                        change={(value: any) =>
                          setForm({ ...form, address: { ...form.address, number: value } })
                        }
                      />
                    </div>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder={i18n.t(`account.address_complement`)}
                      label={i18n.t(`account.address_complement`)}
                      value={form.address.extra || ''}
                      change={(value: any) =>
                        setForm({ ...form, address: { ...form.address, extra: value } })
                      }
                    />
                  </div>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.neighbourhood`)}
                    label={i18n.t(`account.neighbourhood`)}
                    value={form.address.district || ''}
                    change={(value: any) =>
                      setForm({ ...form, address: { ...form.address, district: value } })
                    }
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.city`)}
                    label={i18n.t(`account.city`)}
                    value={form.address.city || ''}
                    change={(value: any) =>
                      setForm({ ...form, address: { ...form.address, city: value } })
                    }
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`account.state`)}
                    label={i18n.t(`account.state`)}
                    value={form.address.state || ''}
                    change={(value: any) =>
                      setForm({ ...form, address: { ...form.address, state: value } })
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          )}

          {activeTab === '#tab2' && (
            <div className='d-flex justify-content-between flex-wrap gap-5'>
              <div style={{ flex: 1 }}>
                <div className='w-100 card'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Redes Socias</span>
                      <BreadCrumbs />
                    </h3>
                  </div>
                  <div className='card-body'>
                    <div className='d-flex gap-5'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        label='Nome'
                        value={redeSocialNome}
                        change={(value: any) => {

                          setRedeSocialNome(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        label='url'
                        value={redeSocialUrl}
                        change={(value: string) => {
                          setRedeSocialUrl(value)
                        }}
                      />
                    </div>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label='Rede Social'
                      options={redeSocialOption}
                      value={redeSocialType}
                      change={(value: string) => {
                        setRedeSocialType(value)
                      }}
                    />
                    <div className='d-flex justify-content-end mt-3'>
                      <Button text={'Criar'} color={'primary'} click={() => createSocialMedia()} size='small' />
                    </div>
                  </div>
                </div>
                <div className='w-100 card mt-5'>
                  <div className='card-body d-flex gap-5'>
                    {pluginspaceSocialMedias.map((sm: any) => (
                      <div className='card p-4 border border-primary'>
                        <Input
                          inputAttr={{
                            type: 'default',
                          }}
                          label='Nome'
                          value={sm.name}
                          change={(value: any) => {

                            updateNameInArray(sm.id, value)
                          }}
                        />
                        <Input
                          inputAttr={{
                            type: 'default',
                          }}
                          label='url'
                          value={sm.url}
                          change={(value: string) => {
                            updateUrlInArray(sm.id, value)
                          }}
                        />
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          label='Rede Social'
                          options={redeSocialOption}
                          value={sm.type}
                          change={(value: string) => {
                            updateTypeInArray(sm.id, value)
                          }}
                        />

                        <div className='d-flex justify-content-between gap-5 mt-2'>
                          <Button text={'Delete'} color={'danger'} click={() => Swal.fire({
                            icon: 'question',
                            title: 'Atenção',
                            text: 'Deseja realmente deletar essa Rede?',
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sim!"
                          }).then((result) => {
                            if (result.isConfirmed) {
                              deleteSocialMedia(sm.id)
                            }
                          })

                          } size='small' />
                          <Button text={'Editar'} color={'warning'} click={() => editSocialMedia(sm.id)} size='small' />
                        </div>
                      </div>
                    ))

                    }

                  </div>
                </div>
              </div>
            </div>



          )}
          <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
            <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={update}
              btnClass='fw-bolder'
            />
          </div>
        </div>
      )}
    </>
  )
}

const Conta: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`account.edit_account`), path: 'configuracoes/conta', isActive: true },
        ]}
      >
        {i18n.t(`account.account`)}
      </PageTitle>
      <ContaContent />
    </>
  )
}

export { Conta }
