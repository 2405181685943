import {useState} from 'react'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import {Input} from '../../../components/Input/Input'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState<string>('admin@demo.com')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const formik = useFormik({
    initialValues: {email},
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        console.log(values)
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-gray-800 mb-3 fw-bold'>Recuperar sua senha</h1>

          <div className='text-gray-400 fw-bold fs-6'>
            Informe seu email e você receberá um link para restaurar sua senha
          </div>
        </div>

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              Desculpe, alguns erros foram detectados. Por favor, tente novamente!
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-success p-8 rounded'>
            <div className='text-success'>
              Redefinição de senha enviada. Por favor verifique seu email!
            </div>
          </div>
        )}

        <div className='fv-row mb-10'>
          <Input
            getFieldProps={{...formik.getFieldProps('email')}}
            inputAttr={{
              type: 'email',
              name: 'email',
              autoComplete: 'off',
            }}
            label='E-mail'
            placeholder='E-mail'
            value={email}
            change={setEmail}
          />
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
          >
            <span className='indicator-label'>Enviar</span>
            {loading && (
              <span className='indicator-progress'>
                Por favor, espere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Cancelar
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
