import {useEffect, useState} from 'react'
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import { Loading } from '../../../components/Loading/Loading'
import { POST } from '../../../services/api';

const RedirectLayout = () => {
  
    const [loading, setLoading] = useState(true);
    const [success, setSucess] = useState(true);
    const {search} = useLocation();
    const navigate = useNavigate();

  useEffect(() => {
    const codeItem = search.split("&")[0].split("=")
    const code = codeItem.length > 1 ? codeItem[1] : ''
    const pluginspaceId = localStorage.getItem('pluginspaceId');
    const email = localStorage.getItem('emailPagseguro');
    const clientId = localStorage.getItem('clientIdPagseguro');
    if(code === '' || !pluginspaceId || !email || !clientId){
      setSucess(false);
    } else {
      sendCodePagseguro(code, pluginspaceId, email, clientId)
    }
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  const sendCodePagseguro = async (code: string, pluginspaceId: string, email: string, clientId: string) => {
    try{
      //const response = await POST('/pagseguro/exchangeAuthCodeForToken', {
        const response = await POST('/pagseguro/updateCodeRedirectApplication', {
        code,
        client_id: clientId,
        email,
        pluginspaceId: parseInt(pluginspaceId)
      })
      setLoading(false)
      if(response.success){
        setSucess(true);  
      } else {
        setSucess(false)
      }
      setTimeout(() => {
        navigate('/pluginconfig/gateway')
      }, 5000)
    } catch(e) {
      setLoading(false)
      setSucess(false);
      console.log(e)
    }
  }

  return (
    <div className='d-flex flex-column flex-md-row h-100'>
      <div
        className='w-md-50 d-flex align-items-center justify-content-center'
        style={{
          backgroundColor: '#1e1e2d',
        }}
      >
        <img
          alt='Logo'
          src={'/ninegrid/login/login_bg.png'}
          className='w-100 w-md-75 p-10 p-md-0'
        />
      </div>

      <div className='w-md-50 d-flex flex-md-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='bg-white w-100 w-lg-75 p-md-10 p-lg-15 mx-auto d-flex flex-md-center flex-column'>
        { loading ?
          <Loading />
          :
          (success ?
            <div className='d-flex flex-md-center flex-column'>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#0F9D58" className="bi bi-check" viewBox="0 0 16 16">
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                </svg>
                <h2>Integração realizada com sucesso</h2>
            </div>
            :
            <div className='d-flex flex-md-center flex-column'>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="#DB4437" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                <h2>Erro ao realizar integração</h2>
            </div>
          )
        }
        </div>
      </div>
    </div>
  )
}

const RedirectPage = () => (
  <Routes>
    <Route path='pagseguro' element={<RedirectLayout />} />
  </Routes>
)

export {RedirectPage}
