import { FC, useEffect, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"
import { i18n } from "../../../translate/i18n"
import { CapaAbertura } from "../../../components/WebAppAgregador/CapaAbertura"
import { CapaDestaque } from "../../../components/WebAppAgregador/CapaDestaque"
import { VisualAgregador } from "../../../components/WebAppAgregador/VisualAgregador"
import { GET, POST, PUT } from "../../../services/api"
import { Content, appConfig } from "./types/webAppAgregador.types"
import Swal from "sweetalert2"
import uuid from "react-uuid"

const itemMenu = {
  height: '100%',
  padding: '20px 20px 0',
  alignItems: 'center',
  display: 'flex',
 // cursor: 'pointer',
  color: '#677484',
  borderBottom: '4px solid #FFFFFF',
}

const itemMenuActive = {
  ...itemMenu,
  borderBottom: '4px solid #6993ff',
}

const itemMenuInactive = {
  ...itemMenu,
  borderBottom: '4px solid #CCCCCC',
}


const WebAppAgregador: FC<any> = () => {
  const [activeTab, setActiveTab] = useState('#tab1')
  const [loading, setLoading] = useState(false)
  const [config, setConfig] = useState<appConfig>();


  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }



  async function saveImage(image1: any, imageBk: any) {
    try {
      if (image1 && image1 !== imageBk) {
        if (imageBk) {
          deleteImage(imageBk)
        }
        const formData = new FormData()
        formData.append('file', image1)
        formData.append('nameImage', `appconfig-logo-${uuid()}.jpg`)
        formData.append('originalSize', 'true')
        const imageRes = await POST('/upload-images/upload-one', formData)
        return imageRes.success ? imageRes.image : ''
      }
      return image1
    } catch (error) {
      return ''
    }
  }


  async function getConfig() {
    try {
      setLoading(true)
      const appRes = await GET(`/appconfig`)

      const app: appConfig = appRes.data.find((el: any) => el.type === 'qr')

      if (!app.content.featureCover) {
        app.content.featureCover = [
          {
            backgroundButton: '',
            colorTextButton: '',
            colorBallCover: '',
            shadow: false,
            ninegridLogo: '',
            image: '',
            film: false,
            filmValue: 0,
            aggregatorLogo: {
              white: '',
              black: '',
              type: ''
            },
            useLogo: false,
            logoAgregator: ""
          },
          {
            backgroundButton: '',
            colorTextButton: '',
            colorBallCover: '',
            shadow: false,
            ninegridLogo: '',
            image: '',
            film: false,
            filmValue: 0,
            aggregatorLogo: {
              white: '',
              black: '',
              type: ''
            },
            useLogo: false,
            logoAgregator: ""
          },
          {
            backgroundButton: '',
            colorTextButton: '',
            colorBallCover: '',
            shadow: false,
            ninegridLogo: '',
            image: '',
            film: false,
            filmValue: 0,
            aggregatorLogo: {
              white: '',
              black: '',
              type: ''
            },
            useLogo: false,
            logoAgregator: ""
          },
          {
            backgroundButton: '',
            colorTextButton: '',
            colorBallCover: '',
            shadow: false,
            ninegridLogo: '',
            image: '',
            film: false,
            filmValue: 0,
            aggregatorLogo: {
              white: '',
              black: '',
              type: ''
            },
            useLogo: false,
            logoAgregator: ""
          },
          {
            backgroundButton: '',
            colorTextButton: '',
            colorBallCover: '',
            shadow: false,
            ninegridLogo: '',
            image: '',
            film: false,
            filmValue: 0,
            aggregatorLogo: {
              white: '',
              black: '',
              type: ''
            },
            useLogo: false,
            logoAgregator: ""
          }
        ]
      }

      setConfig(app)

      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }



  async function saveImagesOfWebAPP(content:Content){
    try {
      //salvar capa de abertura
      const newOpenCover = content.openCover;

      newOpenCover.image = await saveImage(newOpenCover.image,newOpenCover.imageFirstState)
      delete newOpenCover.imageFirstState;
     
      
      const newAggregatorLogo = newOpenCover.aggregatorLogo;
      
      newAggregatorLogo.black =  await saveImage(newAggregatorLogo.black,newAggregatorLogo.blackFistState)
      delete newOpenCover.imageFirstState;

      newAggregatorLogo.white =  await saveImage(newAggregatorLogo.white,newAggregatorLogo.whiteFistState)
      delete newAggregatorLogo.whiteFistState;
     
     
      newOpenCover.aggregatorLogo = newAggregatorLogo
      content.openCover = newOpenCover
      
      
      //salvar capa de destaque
      const featureCoverAux = content.featureCover;
      
      const newFeatureCover = await Promise.all(await featureCoverAux.map(async (coverAux) => {
        coverAux.image = await saveImage(coverAux.image, coverAux.imageFistState)
        coverAux.aggregatorLogo.white = await saveImage(coverAux.aggregatorLogo.white, coverAux.aggregatorLogo.whiteFistState)
        coverAux.aggregatorLogo.black = await saveImage(coverAux.aggregatorLogo.black, coverAux.aggregatorLogo.blackFistState)

        delete coverAux.aggregatorLogo.blackFistState
        delete coverAux.imageFistState
        delete coverAux.aggregatorLogo.blackFistState

        return coverAux;
      }))
      
      
      
      content.featureCover = newFeatureCover
      
      return {
      success:true,
        data:content
      }
    } catch (error) {
      return {
        error:error,
        success:false
      }
    }
  }



  async function saveConfig() {
    try {
      setLoading(true)

      if (config) {
        // fazer verificação para salvar todas as imagens e remover campos auxiliares;
        const contentRes:any = await saveImagesOfWebAPP(config.content);

        if(!contentRes || !contentRes.success){
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao salvar configuração!',
          })
          return 
        }

        const appconfigRes = await PUT(`/appconfig/${config.id}`, {
          content: contentRes.data,
        })
        setLoading(false)
        if (appconfigRes.success === true) {

          Swal.fire({
            icon: 'success',
            title: 'Configuração alterada',
            text: 'Edição realizada com sucesso!',
          })
          setActiveTab('#tab1')
        } else {

          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar configuração!',
          })
        }


      } else {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Configuração inexistente!',
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  function nextPage(){
    const parseIntTab = 1 + parseInt(activeTab.split('#tab')[1]);

    if (parseIntTab === 4) {
      //await create()
      return
  }

  setActiveTab(`#tab${parseIntTab}`);

  }

  function previousPage(){
    const parseIntTab = parseInt(activeTab.split('#tab')[1]) - 1;

    if (parseIntTab === 0) {
        return
    }
    setActiveTab(`#tab${parseIntTab}`);
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`webapp.settings_webapp_aggregator`)}</span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='w-100'>
              <div className='card'>
                <div className='d-flex' style={{ padding: '0 2.25rem', height: 75, gap: '14px' }}>
                  <div
                    style={activeTab === '#tab1' ? itemMenuActive : itemMenuInactive}
                    /*onClick={() => {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Atenção',
                        text: '"Lembre-se de salvar antes de mudar de aba, caso contrário, perderá todas as modificações!',
                        showCancelButton: true,
                        confirmButtonText: "Ok",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          setActiveTab('#tab1')
                        }
                      })
                    }}*/
                  >
                    <span style={{
                      fontSize: '14px',
                      lineHeight: "30px"
                    }}>Capa de Abertura</span>
                  </div>

                  <div style={activeTab === '#tab2' ? itemMenuActive : itemMenuInactive}
                 /*  onClick={() =>

                    Swal.fire({
                      icon: 'warning',
                      title: 'Atenção',
                      text: '"Lembre-se de salvar antes de mudar de aba, caso contrário, perderá todas as modificações!',
                      showCancelButton: true,
                      confirmButtonText: "Ok",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setActiveTab('#tab2')
                      }
                    }
                    )
                  }*/
                  >
                    <span style={{
                      fontSize: '14px',
                      lineHeight: "30px"
                    }}>Capas de Destaque</span>
                  </div>

                  <div style={activeTab === '#tab3' ? itemMenuActive : itemMenuInactive}
                  /*
                  onClick={() =>
                    Swal.fire({
                      icon: 'warning',
                      title: 'Atenção',
                      text: '"Lembre-se de salvar antes de mudar de aba, caso contrário, perderá todas as modificações!',
                      showCancelButton: true,
                      confirmButtonText: "Ok",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        setActiveTab('#tab3')
                      }
                    }
                    )
                  }*/
                  >
                    <span style={{
                      fontSize: '14px',
                      lineHeight: "30px"
                    }}>Visual do Agregador</span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          {activeTab === '#tab1' && (
            <CapaAbertura openCover={config?.content?.openCover}
              save={saveConfig}
              nextPage={nextPage}
              previousPage={previousPage}
              setLoading={setLoading}
              config={config}
              setConfig={setConfig} />
          )

          }
          {activeTab === '#tab2' && (
            <CapaDestaque
              featureCover={config?.content.featureCover ? config?.content.featureCover : []}
              save={saveConfig}
              setLoading={setLoading}
              config={config}
              setConfig={setConfig}
              nextPage={nextPage}
              previousPage={previousPage}
            />
          )

          }
          {activeTab === '#tab3' && (
            <VisualAgregador
              webappcolor={config?.content?.webappcolor}
              aggregatorLogo={config?.content?.aggregatorLogo}
              save={saveConfig}
              config={config}
              previousPage={previousPage}
              setConfig={setConfig}
              setLoading={setLoading}
            />
          )

          }


        </div>


      )}
    </>
  )
}

export { WebAppAgregador }