import {FC} from 'react'
import styles from './index.module.css'
import { i18n } from '../../translate/i18n';

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

type CardPaymentType = {
    id: number;
    name: string;
    pluginconfigId: number | null;
    description: string | null;
    website: string | null;
    image: string | null;
    ableCredit: boolean | null;
    ableDebit: boolean | null;
    ableAlimentation: boolean | null;
    ablePix: boolean | null;
    ableMoney: boolean | null;
    activeItem: (paymentId:number, pluginconfigId: number | null, item: string, confirmFirst: boolean, doChange?: boolean) => Promise<void>
}

const CardPayment: FC<CardPaymentType> = ({id, name, pluginconfigId, description, website, image, ableCredit, ableDebit, ableAlimentation, ablePix, ableMoney, activeItem}) => {
  const navigate = useNavigate();
  return (
    <div className={styles.cardItem} key={id} >
        <div>
            <div className={styles.cardImage}>
                {image ? <img src={image} alt={name} /> : <p className='d-flex flex-row gap-2 mb-0 align-items-center'><i className="fa fa-money-bill" style={{ color: '#E19C15' }}></i>{name}</p>}
            </div>
            <div className={styles.cardDescription}>
                <h4>{name}</h4>
                {description && (
                    <p>{description}</p>
                )}
                {website && (
                    <a target='_blank' href={website}>
                        Ver o site oficial 
                        <svg style={{marginLeft: 5}} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.125 1.0625C7.125 0.757812 7.35938 0.5 7.6875 0.5H11.4141C11.7422 0.5 11.9766 0.757812 11.9766 1.0625V4.8125C11.9766 5.14062 11.7422 5.375 11.4141 5.375C11.1094 5.375 10.8516 5.14062 10.8516 4.8125V2.42188L5.625 7.64844C5.41406 7.88281 5.0625 7.88281 4.85156 7.64844C4.61719 7.4375 4.61719 7.08594 4.85156 6.85156L10.0781 1.625H7.6875C7.35938 1.625 7.125 1.39062 7.125 1.0625ZM1.6875 1.25H4.6875C4.99219 1.25 5.25 1.50781 5.25 1.8125C5.25 2.14062 4.99219 2.375 4.6875 2.375H1.6875C1.35938 2.375 1.125 2.63281 1.125 2.9375V10.8125C1.125 11.1406 1.35938 11.375 1.6875 11.375H9.5625C9.86719 11.375 10.125 11.1406 10.125 10.8125V7.8125C10.125 7.50781 10.3594 7.25 10.6875 7.25C10.9922 7.25 11.25 7.50781 11.25 7.8125V10.8125C11.25 11.75 10.4766 12.5 9.5625 12.5H1.6875C0.75 12.5 0 11.75 0 10.8125V2.9375C0 2.02344 0.75 1.25 1.6875 1.25Z" fill="#3C9EE6"/>
                        </svg>

                    </a>
                )}
            </div>
        </div>
        <div className='d-flex flex-column gap-2'>
            {ableCredit !== null && (
                <div className={styles.cardPayment}>
                    <p>{i18n.t(`payment.card`)}</p>
                    <button onClick={async () => await activeItem(id, pluginconfigId, 'card', true)} className={ableCredit ? styles.cardActive : styles.cardInactive}>{ableCredit ? 'Ativo' : 'Inativo'}</button>
                </div>
            )}
            {ableDebit !== null && (
                <div className={styles.cardPayment}>
                    <p>{i18n.t(`payment.debit`)}</p>
                    <button onClick={async () => await activeItem(id, pluginconfigId, 'debit', true)} className={ableDebit ? styles.cardActive : styles.cardInactive}>{ableDebit ? 'Ativo' : 'Inativo'}</button>
                </div>
            )}
            {ableAlimentation !== null && (
                <div className={styles.cardPayment}>
                    <p>{i18n.t(`payment.food`)}</p>
                    <button onClick={async () => await activeItem(id, pluginconfigId, 'food', true)} className={ableAlimentation ? styles.cardActive : styles.cardInactive}>{ableAlimentation ? 'Ativo' : 'Inativo'}</button>
                </div>
            )}
            {ablePix !== null && (
                <div className={styles.cardPayment}>
                    <p>{i18n.t(`payment.pix`)}</p>
                    <button onClick={async () => await activeItem(id, pluginconfigId, 'pix', true)} className={ablePix ? styles.cardActive : styles.cardInactive}>{ablePix ? 'Ativo' : 'Inativo'}</button>
                </div>
            )}
            {(ableMoney !== null && ableMoney !== undefined) && (
                <div className={styles.cardPayment}>
                    <p>{i18n.t(`payment.pay_money`)}{ableMoney}</p>
                    <button onClick={async () => await activeItem(id, pluginconfigId, 'money', true)} className={ableMoney ? styles.cardActive : styles.cardInactive}>{ableMoney ? 'Ativo' : 'Inativo'}</button>
                </div>
            )}
            <div className={styles.cardConfig}>
                <button onClick={() => {
                    pluginconfigId ? navigate(`/configuracoes/pagamentos/${id}`) : navigate(`/configuracoes/pagamentos/criar/${id}`)
                }}>
                    {i18n.t(`payment.config`)}
                </button>
            </div>
        </div>
    </div>
  )
}

export {CardPayment}
