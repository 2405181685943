import SVG from 'react-inlinesvg'

export const ListIcons = (props: {icons: any; category: any}) => {
  const {icons, category} = props
  const arr = icons.filter((row: any) => row.category === category)
  const handleClick = (id: any) => {}
  return arr.map((icon: any) => (
    <>
      <div
        style={{
          border: '1px solid #c9c9c9',
          margin: '2px',
          padding: '2px',
          borderRadius: '6px',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(icon.id)}
      >
        <SVG
          src={icon.svg}
          width={50}
          height={50}
          uniquifyIDs={true}
          description={icon.description}
          title={icon.description}
        />
      </div>
    </>
  ))
}
