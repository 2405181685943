/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import useAuth from '../../../../app/hooks/useAuth'

const AsideDefault: FC = () => {
  const { config, classes } = useLayout()
  const { loginInfo } = useAuth()
  const { aside } = config

  const [isClosed, setIsClosed] = useState(false)

  useEffect(() => {
    console.log(document.body.getAttribute('data-kt-aside-minimize'))

  }, [document.body.getAttribute('data-kt-aside-minimize')])

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {!isClosed && (
          <Link to='/dashboard'>
            <h2 style={{ color: '#FFF', margin: 0, fontSize: 16 }}>{loginInfo.pluginspace}</h2>
          </Link>
        )
        }
        {
          isClosed && (
            <div >
              <img src="/media/logos/favicon-ninegrid.png" alt="Metronic logo" className="h-30px" />
            </div>

          )
        }
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            onClick={() => {
              setIsClosed(!isClosed)
            }}
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin : Version: APP */}
      {process.env.REACT_APP_VERSION_ADMIN && (
        <div className='aside-logo flex-column-auto justify-content-center'>
          <Link to='/dashboard'>
            <h2 style={{ color: '#FFF', margin: 0, fontSize: 12 }}>Versão {process.env.REACT_APP_VERSION_ADMIN}</h2>
          </Link>
        </div>
      )}

      {/* end : Version: APP */}
    </div>

  )
}

export { AsideDefault }
