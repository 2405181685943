import {useEffect} from 'react'
import {Outlet, Route, Routes, useLocation, useParams, useSearchParams} from 'react-router-dom'
//import {Registration} from './components/Registration';
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'

const AuthLayout = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    console.log(searchParams.get("code"))
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
    
  }, [])

  return (
    <div className='d-flex flex-column flex-md-row h-100'>
      <div
        className='w-md-50 d-flex align-items-center justify-content-center'
        style={{
          backgroundColor: '#1e1e2d',
        }}
      >
        <img
          alt='Logo'
          src={'/ninegrid/login/login_ng.png'}
          className='w-100 w-md-75 p-10 p-md-0'
        />
      </div>

      <div className='w-md-50 d-flex flex-md-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <div className='bg-white w-100 w-lg-75 p-md-10 p-lg-15 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      {/*<Route path='registration' element={<Registration />} />*/}
      <Route path='esqueceu-senha' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
