import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../../services/api'
import { cpfToNumber, removeEspacosEmBranco } from '../../../../utils/formater'
import { isCpfValid } from '../../../../functions/document'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid';
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import usePluginsSpace from '../../../../hooks/usePluginSpace'

type PermissionType = {
  id: number
  name: string
}

type UserType = {
  name: string
  type: string
  email: string
  password: string
  permissionGroupId: number
  image: string
  cpf?: string
  sellerCode?: string,
  numberPhone?: string,
  content?: any
}

type UserTypeUsers = {
  select: string;
  value: string;
};

const CriarUsuariosContent: FC = () => {
  const navigate = useNavigate()
  const { loginInfo } = useAuth()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<string>('user')
  const [email, setEmail] = useState<string>('')
  const [image, setImage] = useState<File | null>(null)
  const [password, setPassword] = useState<string>('')
  const [permissionId, setPermissionId] = useState<number>(0)
  const [permissions, setPermissions] = useState<PermissionType[]>([])
  const [cpf, setCpf] = useState<string | null>(null)
  const [sellerCode, setSellerCode] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [typeUsers, setTypeUsers] = useState<UserTypeUsers[]>([]);
  const [allowDiscount, setAllowDiscount] = useState<boolean>(false)
  const [discountValue, setDiscountValue] = useState<number>(0)
  const [pluginSpace, setPluginSpace] = useState<string>('')

  const createUser = async () => {
    try {
      if (!name || !type || !email || !password || !permissionId || (!cpf && type === 'seller')) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        if (type === 'seller') {
          if (!isCpfValid(cpf || '')) {
            Swal.fire({
              icon: 'error',
              title: 'CPF inválido',
              text: 'O CPF informado é inválido. Verifique e tente novamente!',
            })
            return
          }
        }

        setLoading(true)
        let imageRes = {
          success: false,
          image: '',
        }

        let objUser: UserType = {
          name: removeEspacosEmBranco(name),
          type,
          email: removeEspacosEmBranco(email),
          password: removeEspacosEmBranco(password),
          permissionGroupId: permissionId,
          numberPhone: phone,
          image: 'default/default-user.jpg',
          content: {
            allow_discount: allowDiscount,
            discount_value: discountValue
          }
        }
        if (type === 'seller' && cpf) {
          objUser.cpf = cpfToNumber(cpf).toString()
          objUser.sellerCode = sellerCode
        }
        const userRes = await POST('/users', objUser)
        if (image) {
          const formData = new FormData()
          formData.append('file', image)
          formData.append('nameImage', `user-${uuid()}.jpg`)
          imageRes = await POST('/upload-images/upload-one', formData)
          if (imageRes.success) {
            await PUT(`/users/${userRes.data.id}`, {
              image: imageRes.image,
            })
          }
        }
        setLoading(false)
        if (userRes.success === true) {
          if(loginInfo.plugintype === 'aggregator') {
            Swal.fire({
              icon: 'success',
              title: 'Usuário cadastrado',
              text: 'Cadastro realizado com sucesso!',
            }).then(() => navigate('/config/user'))
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Usuário cadastrado',
              text: 'Cadastro realizado com sucesso!',
            }).then(() => navigate('/configuracoes/usuarios'))
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar usuário!',
          })
        }
      }
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Erro ao cadastrar usuário!',
      })
    }
  }

  const getPermissions = async () => {
    try {
      setLoading(true)
      const groupRes = await GET('/permission-group')
      const newGroup = [{ id: 0, name: i18n.t(`user.select_a_access_level`) }]
      groupRes.data.map((permission: PermissionType) => {
        newGroup.push({
          id: permission.id,
          name: permission.name,
        })
      })
      setPermissions(newGroup)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }
  
  const getUsers = async () => {
    try {
      const pluginSpace = await GET(`pluginspace/${loginInfo.pluginspaceId}`)

      const pluginSpaceType = pluginSpace.data[0].type;

      if(pluginSpaceType === "hospitality"){
      setTypeUsers([
        { select: 'Gerente da Pluginspace', value: 'owner' },
        { select: 'Usuário', value: 'user' },
      ]);
      }else if(pluginSpaceType === "aggregator"){
        setTypeUsers([
          { select: 'Gerente da Pluginspace', value: 'owner' },
          { select: 'Usuário', value: 'user' },
        ]);
      }else {
      setTypeUsers([
        { select: 'Gerente da Pluginspace', value: 'owner' },
        { select: 'Usuário', value: 'user' },
        { select: 'Vendedor', value: 'seller' },
      ]);
      }
    } catch (e) {
      console.log(e)
    }
  }

  const generatePassword = (len: number) => {
    var pwd = [],
      cc = String.fromCharCode,
      R = Math.random,
      rnd,
      i
    pwd.push(cc(48 + (0 | (R() * 10)))) // push a number
    pwd.push(cc(65 + (0 | (R() * 26)))) // push an upper case letter
    for (i = 2; i < len; i++) {
      rnd = 0 | (R() * 62) // generate upper OR lower OR number
      pwd.push(cc(48 + rnd + (rnd > 9 ? 7 : 0) + (rnd > 35 ? 6 : 0)))
    }
    // shuffle letters in password
    setPassword(
      pwd
        .sort(function () {
          return R() - 0.5
        })
        .join('')
    )
  }

  useEffect(() => {
    getPermissions()
    getUsers()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`user.create_user`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`user.here_are_listed_the_users_along_with_their_respective_actions`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom d-flex justify-content-between flex-wrap gap-10'>
              <div className='mt-6'>
                <Input
                  inputAttr={{
                    type: 'image',
                  }}
                  label={i18n.t(`user.profile_picture`)}
                  value={image}
                  change={(value: BaseSyntheticEvent) =>
                    setImage(value ? value.target.files[0] : null)
                  }
                />
              </div>

              <div className='d-flex flex-grow-1'>
                <div className='w-50 mb-6'>
                  <div className='me-5 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder={i18n.t(`user.name`)}
                      label={i18n.t(`user.name`)}
                      value={name}
                      change={(value: string) => setName(value)}
                    />
                  </div>
                  <div className='me-5 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='E-mail'
                      label='E-mail'
                      value={email}
                      change={(value: string) => setEmail(value)}
                    />
                  </div>
                  <div className='flex-grow-1 me-5'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      placeholder={i18n.t(`user.select_a_access_level`)}
                      label={i18n.t(`user.access_level`)}
                      options={permissions?.map((permission) => ({
                        value: permission.id,
                        select: permission.name,
                      }))}
                      value={permissionId}
                      change={(value: string) => setPermissionId(parseInt(value))}
                    />
                  </div>
                  {type === 'seller' && (
                    <>
                      <div className='me-5 flex-grow-1'>
                        <Input
                          inputAttr={{
                            type: 'cpf',
                          }}
                          placeholder='XXX.XXX.XXX-XX'
                          label='CPF'
                          value={cpf}
                          change={(value: string) => setCpf(value)}
                        />
                      </div>
                      <div className='me-5 flex-grow-1'>
                        <Input
                          inputAttr={{
                            type: 'string',
                          }}
                          placeholder=''
                          label='Código do vendedor no ERP'
                          value={sellerCode}
                          change={(value: string) => setSellerCode(value)}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className='w-50'>
                  <div className='flex-grow-1 ms-5'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      placeholder={i18n.t(`user.select_type`)}
                      label={i18n.t(`user.type`)}
                      options={typeUsers}
                      value={type}
                      change={(value: string) => {
                        setType(value)
                        setCpf(null)
                      }}
                    />
                  </div>
                  <div className='flex-grow-1 ms-5'>
                    <Input
                      inputAttr={{
                        type: 'password',
                      }}
                      placeholder={i18n.t(`user.password`)}
                      label={i18n.t(`user.password`)}
                      autoComplete='new-password'
                      value={password}
                      change={(value: string) => setPassword(value)}
                    />
                    <div className='d-flex justify-content-end'>
                      <Button
                        text={i18n.t(`user.generate_pass`)}
                        color='primary'
                        size='small'
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => generatePassword(8)}
                        btnClass='fw-normal w-90px fs-8 mt-2 px-0'
                      />
                    </div>
                    {type === 'seller' &&
                      (
                        <div className='flex-grow-1 ms-5'>
                          <Input
                            inputAttr={{
                              type: 'tel',
                            }}
                            placeholder={i18n.t(`user.phone`)}
                            label={i18n.t(`user.phone`)}
                            value={phone}
                            change={(value: string) => setPhone(value)}
                            minNumber={0}
                            maxNumber={100}
                          />
                        </div>

                      )
                    }

                    {((loginInfo.profile === 'admin') || (loginInfo.profile === 'owner') && type === 'seller') && (
                      // novos campos para definir desconto
                      <>
                        <div className='flex-grow-1 ms-5'>
                          <Input
                            inputAttr={{
                              type: 'select',
                            }}
                            placeholder={i18n.t(`user.discount_permission`)}
                            label={i18n.t(`user.discount_permission`)}
                            options={[
                              { select: 'Sim', value: true },
                              { select: 'Não', value: false },
                            ]}
                            value={allowDiscount}
                            change={(value: boolean) => {
                              setAllowDiscount(value)
                            }
                            }
                          />
                        </div>
                        <div className='flex-grow-1 ms-5'>
                          <Input
                            inputAttr={{
                              type: 'number',
                            }}
                            placeholder={i18n.t(`user.discount_value`)}
                            label={i18n.t(`user.discount_value`)}
                            value={discountValue}
                            change={(value: number) => setDiscountValue(value)}
                            minNumber={0}
                            maxNumber={100}
                          />
                        </div>

                      </>
                    )
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 d-flex justify-content-end '>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='info'

                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                  width="100px"
                />
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  width="100px"
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    createUser()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
          </div>
        </div>
      )}
    </>
  )
}

const CriarUsuarios: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`user.user`), path: 'configuracoes/usuarios', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'configuracoes/usuarios/criar', isActive: true },
        ]}
      >
        {i18n.t(`user.create_user`)}
      </PageTitle>
      <CriarUsuariosContent />
    </>
  )
}

export { CriarUsuarios }
