const messages = {
  es: {
    translations: {
      permissions: {
        food: 'Smart Order',
        smartorder: 'Catálogo',
        category: 'Categorías',
        subcategory: 'Subcategorías',
        product: 'Productos',
        mark: 'Marcas',
        grid: 'Red',
        variation: 'Variación',
        catalog: 'Catálogos',
        integration: 'Integraciones',
        int_pos: 'PDV',
        int_gateway: 'Medios de pago',
        messaging: 'Mensajero',
        sms: 'Status e SMS',
        multichannel: 'Multicanal',
        report: 'Informes',
        saleorder: 'Peticiones',
        indicators: 'Indicadores',
        orderReport: 'Peticiones',
        imports: 'Importación de Imágenes',
        importProductImage: 'Productos',
        importProductImageSKU: 'Variaciones',
        importCategoryImage: 'Categorías',
        customers: 'Clientes',
        customer_report: 'Lista de Clientes',
        measurement_unit: 'Unidades de Medida',
        logs: 'Registros',
        settings: 'Ajustes',
        charge_integration: 'Cargas',
        summaryAccess: 'Acesos',
        orders: 'Peticiones',
        support: 'Soporte',
        application: 'Webapp',
        messenger: 'Mensajería',
        others: 'Otros',
        settings_store: 'Configuraciones de la Tienda',
        settings_hotel: 'Configuraciones del Hotel',
        modalities: 'Modalidades',
        delivery_tax: 'Gastos de Envío',
        shifts: 'Turnos',
        payment_plans: 'Planes de Pago',
        settings_account: 'Configuraciones de Cuenta',
        account: 'Cuenta',
        groups: 'Grupos',
        users: 'Usuários',
        terms: 'Términos',
        highlights: 'Reflejos',
        menu: 'Menú',
        productReport: 'Producto',
        optional: 'Opcional',
        locations: 'Sectores',
        service: 'Servicios',
        service_items: 'Servicios',
        service_category: 'Categorías',
        feed: 'Feed',
        content: 'Contenido',
        queue: 'Cola de espera',
        queue_config: 'Ajustes',
        queue_manager: 'Gestión',
        checkout: 'Datos del cliente',
        booking: 'Reserva',
        booking_management: 'Gestión',
        booking_config: 'Ajustes',
        booking_environment: 'Ambientes',
        booking_day: 'Reservas del dia',
        booking_report: 'Informes',
        liveShop: 'Catálogo en Vivo',
        icon_library: 'Biblioteca de iconos',
        stores: 'Tiendas',
        segments: 'Segmentos',
        payment: 'Pagamentos',
        serviceCategory: 'Categoría de servicio',
        SDS: 'KDS',
        sds_manage: 'Mi SDS',
        sds_layout: 'Personalización',
        sds_link: 'Ajustes',
        sds_display: 'Displays',

        /* Agregador */
        config: 'Configuración',
        config_account: 'Detalles de la cuenta',
        config_sector: 'Sector de operación',
        config_user: 'Usuarios',
        config_term: 'Términos',
        subCategory: 'Subcategoria',
        footer:'Características del pie de página',
        footerFeature:'Habilitar funciones',
        footerContent:'Fuente de recursos' ,
        footerContentAddEdit:'Recursos',
        webapp:'Personalizar',
        webapp_qrcode:'Generar Codigo QR',
        ia_menu: 'Inteligencia Artificial',
        ia_config: 'Ajustes',
        ia_fit: 'Capacitación',
        publication_highlight_banner: "Featured Banner",
        publication: "Advertising"
      },
      buttons: {
        create: 'Crear',
        back: 'Vuelve',
        register_pluginspace: 'Regístrese Pluginspace',
        access: 'Acceso',
        add_modality: 'Añadir modalidad',
        add_km: 'Añadir kilómetros',
        zip_code_list: 'lista de códigos postales',
        add_zip_code: 'Añadir código postal',
        area: 'Áreas de Carga',
        list_of_negative_renge_zip_code: 'Lista de códigos postales negativos',
        add_negative_zip_code: 'Agregar código postal negativo',
        add_user: 'Agregar usuario',
        generate_password: 'Generar contraseña',
        generate_sku: 'Generar SKU',
        add_group: 'Añadir grupo',
        save: 'Ahorrar',
        add_term: 'Añadir término',
        add_integration: 'Añadir integración',
        filter: 'Filtrar',
        search: 'Buscar',
        register_product: 'Registrar producto',
        choose_files: 'Seleccionar archivos',
        add_categories: 'añadir categorías',
        add_subcategories: 'Añadir subcategorías',
        add_brand: 'añadir etiqueta',
        close: 'Cerca',
        add_grade: 'agregar cuadrícula',
        add: 'Agregar',
        add_variation: 'añadir variación',
        confirm_grades: 'confirmar cuadrículas',
        add_catalog: 'añadir catálogo',
        generete_qr_code: 'Generar código QR',
        personalize: 'Personalizar',
        settings: 'Ajustes',
        add_interval: 'Agregar rango',
        add_gateway: 'Agregar puerta de enlace',
        overview: 'Visión general',
        load_csv: 'Cargar archivo',
        download_csv: 'Descargar XLSX Predeterminada',
        att_page: 'Actualizar página',
        export: 'Exportar',
        add_service: 'Registrar Servicio',
        add_environment: 'Añadir ambiente',
      },

      general: {
        delete_item: '¿Quieres eliminar este artículo?',
        delete_product_observation: 'También se eliminarán las variaciones de este producto. ¿Quieres continuar de todos modos?',
        delete_classification_observation: 'Eliminando esta categoría, todos los productos relacionados con ella pasan a General, ¿quieres continuar?',
        delete: 'Eliminar',
        edit: 'Editar',
        attention: 'Advertencia',
        actions: 'Comportamiento',
        here_are_listed_the_users_along_with_their_respective_actions:
          'Aquí se enumeran los itens junto con sus respectivas acciones.',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
          'Complete la información a continuación y haga clic en el botón crear para guardar los cambios',
        save_changes_edit:
          'Complete la información a continuación y haga clic en el botón ahorrar para guardar los cambios',
        active: 'Activo',
        inactive: 'Inactivo',
        showing: 'Mostrando',
        of: 'de',
        to: 'a',
        records: 'registros',
        today: 'Hoy',
        copy: 'Copiar',
        copied: 'Copiado',
        linkS3: 'link',
        linkedS3: 'Linked',
        downloadS3: 'Download',
        downloadedS3: 'Descarga Iniciada',
        copy_link: 'Copiar enlace del vendedor',
        test: 'Testar'
      },

      pluginspaces: {
        hospitality: 'Hoteles',
        food: 'Food',
        healthcare: 'Hospitalidad',
        varejo: 'Varejo',
        fashion: 'Moda',
      },

      dashboard: {
        totals_by_products: 'Totales por producto',
        totals_by_category: 'Totales por categoría',
        last_orders: 'últimas órdenes',
        products: 'Productos',
        quantity: 'Cantidad',
        total: 'Total',
        category: 'Categoría',
      },

      modalities: {
        modalities: 'Modalidades',
        here_are_listed_the_modalities_along_with_their_respective_actions:
          'A continuación se enumeran las modalidades junto con sus respectivas acciones.',
        delivery: 'Entrega',
        withdraw: 'Retiro',
        active: 'Activo',
        inactive: 'Inactivo',
        name: 'Nombre',
        code: 'Código',
        actions: 'Comportamiento',
        search: 'Buscar',
        type: 'Escribe',
        sector_name: 'Nombre del sector',
        table: 'Mesa',
        room: 'Habitación',
        counter: 'Encimera',
        internal: 'Entrega Interna',
      },

      delivery_fee: {
        delivery_fee: 'Gastos de envío',
        create_delivery_fee: 'Crear cargo de entrega',
        edit_delivery_fee: 'Editar taxa de entrega',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
          'Complete la información a continuación y haga clic en el botón crear para guardar los cambios',
        save_changes_edit:
          'Complete la información a continuación y haga clic en el botón ahorrar para guardar los cambios',
        km_min: 'Km Mínimo',
        km_max: 'Km Máximo',
        value: 'Valor',
        actions: 'Comportamiento',
        patterns: 'Estándares de importación',
        import_file:
          'Para importar los archivos, la primera celda del archivo debe seguir uno de los siguientes patrones:',
        import_delivery: 'Para importar por range: kmmin, kmmax, Value',
        import_cep: 'Para importar CEP: cep_inicio, cep_fim, valor',
        import_cep_negative: 'Para importar CEP negativo: cep_inicio, cep_fim',
        model: 'Modelo',
        continue_import:
          'Todos sus registros se sobrescribirán, ¿está seguro de que desea continuar?',
        cep_delivery: 'CEPs de Entrega',
        cep_info:
          'Aquí se enumeran los códigos postales para las entregas junto con sus respectivas acciones.',
        cep_start: 'CEP Comienzo',
        cep_end: 'CEP Fin',
        create_cep: 'Crear CEPs de Entrega',
        edit_cep: 'Editar CEPs de Entrega',
        cep_delivery_negative: 'CEPs Negativos de Entrega',
        cep_info_negative:
          'Aquí se enumeran los códigos postales negativos para las entregas junto con sus respectivas acciones.',
        create_cep_negative: 'Crear CEP Negativo',
        edit_cep_negative: 'Editar CEP Negativo',
        delete_cep_negative: 'Deletar CEP Negativo',
      },

      user: {
        user: 'Usuários',
        here_are_listed_the_users_along_with_their_respective_actions:
          'Aquí se enumeran los usuarios junto con sus respectivas acciones.',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
          'Complete la información a continuación y haga clic en el botón crear para guardar los cambios',
        save_changes_edit:
          'Complete la información a continuación y haga clic en el botón ahorrar para guardar los cambios',
        picture: 'Imagen',
        name: 'Nombre',
        type: 'Escribe',
        registration: 'Registro',
        create_user: 'Ccrear usuarios',
        edit_user: 'Editar usuário',
        profile_picture: 'foto de perfil',
        password: 'Contraseña',
        generate_pass: 'Generar Contraseña',
        access_level: 'Grupo de acceso',
        select_a_access_level: 'Seleccione un grupo de acceso',
        select_type: 'Seleccione um escribe',
        active: 'Activo',
        actions: 'Comportamiento',

        discount_permission: 'Permiso para descuento',
        discount_value: 'Valor máximo de descuento (%)',
        phone: 'Número de teléfono'
      },

      groups: {
        groups: 'Grupos',
        here_are_listed_the_groups_along_with_their_respective_actions:
          'Aquí se enumeran los grupos junto con sus respectivas acciones.',
        name: ' Nombre',
        Integrations: 'integraciones',
        means_of_payment: 'Medios de pago',
        products: 'Productos',
        category: 'Categorías',
        subcategory: 'Subcategoría',
        indicators: 'Indicadores',
        requests: 'Peticiones',
        reports: 'Informes',
        messenger: 'Mensajero',
      },

      shifts: {
        shifts: 'Turnos',
        here_are_listed_the_shifts_for_each_day_of_the_week:
          'Aquí se listan los turnos para cada día de la semana',
        monday: 'Lunes',
        tuesday: 'Martes',
        wednesday: 'Miércoles',
        thursday: 'Quinto',
        friday: 'Viernes',
        saturday: 'Sábado',
        sunday: 'Domingo',
        start: 'Comienzo',
        end: 'El fin',
      },

      feedCategory: {
        feedCategory: 'Categoría de contenido'
      },

      account: {
        account: 'Cuenta',
        edit_account: 'Editar cuenta',
        registration_number: 'CNPJ',
        corporate_name: 'Razón social',
        name_of_the_establishment: 'Nombre del establecimiento',
        login_of_person_in_charge: 'Login del establecimiento de la cuenta',
        subdomain: 'Subdomínio',
        code: 'código',
        default_language: 'Idioma predeterminado',
        default_currency: 'Moneda predeterminada',
        name_of_person_in_charge: 'Nombre de la persona a cargo',
        address: 'Dirección',
        zip_code: 'Código postal',
        number: 'Número',
        neighbourhood: 'Barrio',
        city: 'Ciudad',
        state: 'Expresar',
        public_place: 'Lugar público',
        address_complement: 'Complementar',
      },

      terms: {
        terms: 'Terminos de uso',
        here_are_the_terms_of_acceptance_of_the_sales_platform:
          'Aquí están los términos de aceptación de la plataforma de ventas',
        create_your_term: 'Crea tu termino',
        term_name: 'Nombre del término',
        valid_until: 'Válido hasta',
        name: 'Nombre',
        expirated: 'Expira',
      },

      payment_plans: {
        payment_plans: 'Planes de pago',
        here_are_listed_the_payment_plans_along_with_their_respective_actions:
          'Aquí se enumeran los planes de pago junto con sus respectivas acciones',
        create_payment_plans: 'Crear planes de pago',
        fill_the_information_below_and_click_the_create_button_to_save_the_changes:
          'Complete la información a continuación y haga clic en el botón crear para guardar los cambios',
        erp_integration: 'Integración ERP',
        integration_type: 'Tipo de integración',
        select_an_integration: 'Seleccione una integración',
        card_flag: 'Bandera de tarjeta',
        flag: 'Bandeira',
        type: 'Tipo',
        erp_code: 'Código ERP',
        card_installment: 'Entrega',
        payment_plan: 'Plan de pago',
        installment: 'Parte',
        time: 'Fecha Tope',
        credit: 'Crédito',
        debit: 'Débito',
      },

      indicators: {
        indicators: 'Indicadores',
        orders: 'Peticiones',
        filters: 'Filtros',
        first: 'Comienzo',
        end: 'El fin',
        total_order: 'Pedidos totales',
        revenue: 'receta',
        orders_by_location: 'Pedidos por modalidad',
        orders_by_month: 'Pedido por mes',
        orders_by_day: 'Pedido por dia',
        orders_by_hour: 'Pedido por hora',
        orders_by_month_sector: 'Pedidos por mes y modalidad',
        orders_by_day_sector: 'Pedidos por día y modalidad',
        orders_by_hour_sector: 'Pedidos por hora y modalidad',
        product: 'productos',
        categories: 'Categorías',
        sales_by_category: 'ventas por categoría',
        revenue_by_category: 'Ingresos por categoría',
        average_ticket: 'Boleto promedio',
        quantity: 'La cantidad',
        summary: 'Resumen',
        summaryAccess: 'Acesos',
        summaryAccessGroup: 'Acesos por periodo',
        summaryTimeAccessGroup: 'Totales por tiempo',
        summaryIPAccessGroup: 'Totales por usuarios',
        summarySellerCart: 'Total por vendedor',
      },

      customer_list: {
        customer_list: 'Lista de cliente',
        listed_customers: 'Aquí se enumeran los clientes junto con sus respectivas acciones.',
        select_time: 'Seleccione un período',
        name: 'Nombre',
        first: 'Início',
        end: 'Fin',
        phone: 'Teléfono',
        date: 'Fecha',
        detail: 'Detalles del Cliente',
        total_profit: 'Beneficio Total',
        total_itens: 'Articulos Totales',
        total_orders: 'Pedidos Totales',
        order_between: 'Pedidos entre',
        order_detail: 'Detalle de la orden',
      },

      smart_order: {
        smart_order: 'Orden inteligente',
        crop_image: 'Recortar Imagen',
        product: 'productos',
        here_are_listed_the_products_along_with_their_respective_actions:
          'Aquí se enumeran los productos junto con sus respectivas acciones.',
        name: 'Nombre',
        code: 'Código',
        product_data: 'Datos del Producto',
        mark: 'Marca',
        measurement_unit: 'Unidad de Medida',
        description: 'Descripción',
        technical_specification: 'Especificación Técnica',
        from: 'en',
        characters: 'caracteres',
        order: 'Ordenar',
        product_image: 'Imágenes del Producto',
        additional_images: 'Imágenes Adicionales',

        categories: 'Categorías',
        here_are_listed_the_categories_along_with_their_respective_actions:
          'Aquí se enumeran las categorías junto con sus respectivas acciones.',
        image: 'Imagen del producto',
        image_variation: 'Imagen de la variación',
        category: 'Categoría',
        category_parent: 'Categoria Pai',
        category_name: 'Nombre de la categoría',
        category_code: 'Código de la categoría',
        perfil_image: 'Foto de perfil',

        subcategories: 'Subcategorias',
        subcategory: 'Subcategoria',
        here_are_listed_the_subcategories_along_with_their_respective_actionsc:
          'Aquí se enumeran las subcategorías junto con sus respectivas acciones.',
        select_category: 'Seleccione una categoría',

        marks: 'Marcas',
        listened_marks: 'Aquí se enumeran las marcas junto con sus respectivas acciones.',

        measurements_units: 'Unidades de Medidas',
        listened_measurement:
          'Aquí se enumeran las unidades de medidas junto con sus respectivas acciones.',

        grid_products: 'Grilla de Productos',
        listened_grid:
          'Aquí se enumeran las grillas de productos junto con sus respectivas acciones.',
        grid_name: 'Nombre de la grilla',
        variation: 'Variación',
        grid: 'Grilla',

        variations: 'Variaciones',
        listened_variations: 'Aquí se enumeran las variaciones junto con sus respectivas acciones.',
        modality: 'Modalidad',
        select_modality: 'Seleccione una modalidad',
        prod: 'Producto',
        select_product: 'Seleccione un producto',
        no_variation: 'Producto sin variacion',
        variation_product: 'Variación del producto',
        cost_price: 'Precio Justo',
        sell_price: 'Precio de Venta',
        promotional_price: 'Precio Promocional',
        weight: 'Peso',
        height: 'Altura',
        width: 'Ancho',
        depth: 'Profundidad',
        availability: 'Cual es la disponibilidad del producto?',
        when_product_stock: 'Cuando el producto se agota?',
        stock: 'Cantidad de producto en stock',
        min_stock: 'Stock mínimo',
        price: 'Precio',

        catalogs: 'Catálogos',
        catalog: 'Catálogo',
        listened_catalogs: 'Aquí se enumeran los catálogos junto con sus respectivas acciones.',
        validity: 'Validez',
        first: 'Comienzo',
        end: 'Fin',
        search_products: 'Búsqueda de Producto',

        product_status: 'Estado del product',
        sku: 'SKU/EAN',
        status_variation: 'Estado del variación',
        specification: 'Especificación',
        price_promotional: 'Precio de descuento',
        inventory: 'Existencias',

        service_title: 'Aquí se enumeran los servicios junto con sus respectivas acciones.',

        initial_hour: 'Hora de inicio',
        final_hour: 'Hora de finalización',
      },

      webapp: {
        webapp: 'Webapp',
        generate_qrcode: 'Generar QR Code',
        personalize: 'Personalizar',
        settings: 'Ajustes',
        link: 'Link de Webapp',

        webapp_data: 'Datos de Webapp',
        name: 'Nombre',
        place: 'Lugar',
        product_pagination: 'Número de productos en paginación',
        logo_ninegrid: 'Logotipo de Ninegrid',
        light: 'Clara',
        dark: 'Oscuro',
        logo_totvs: 'Logotipo de TOTVS',
        use_icon_menu: 'Usar icono en el menú de la aplicación web',
        description: 'Descripción',
        icon: 'Icono',
        color_setting: 'Ajuste de color',
        background_webapp: 'Fondo de la Webapp',
        background_header: 'Fondo de la cabeçalho',
        background_default_webapp: 'Color predeterminado de la aplicación web',
        text_header: 'Texto de encabezado',
        icon_header: 'Iconos de encabezado',
        background_footer: 'Fondo de pie de página',
        icon_footer: 'Iconos de pie de página',
        highlight_footer: 'Pie de página resaltado',
        notification_cart: 'Notificación de carrito',
        notification_text: 'Texto de notificación',
        home_button: 'Botón de inicio',
        text_home_button: 'Texto del botón de inicio',
        title_bar: 'Barra de título',
        text_title_bar: 'Texto del barra de título',
        icon_title_bar: 'Iconos del barra de título',
        text_category: 'Texto de categorías',
        webapp_button: 'Botóns del Webapp',
        text_button: 'Texto del botóns',
        background_menu: 'Fondo del menú lateral',
        text_menu: 'Texto del menú lateral',
        order_status: 'Status del pedido',
        order_active_status: 'Status del pedido activo',
        text_order_active_status: 'Texto de status de pedido',
        info_cart: 'Información del carrito',

        settings_webapp: 'Ajustes de Webapp',
        type_delivery: 'Tipo de restricción de entrega',
        integration: 'Integración',
        select_integration: 'Seleccione una integración',
        payment_method_card: 'Medios de pago Tarjeta',
        payment_method_pix: 'Medios de pago Pix',
        payment_method_delivery: 'Medios de pago en la entrega',
        consent_letter: 'Activar carta de consentimento',
        product_no_stock: 'Vender produtos agotados',
        show_subcategory: 'Mostrar subcategoría',
        show_observation: 'Mostrar nota',
        use_category_list: 'Ver subcategoría en la lista',
        remove_show_mode: 'Eliminar modo de presentación',
        no_shadow: 'No agregue sombra en las categorías.',
        is_order_number: 'Ordenar categoria',
        show_layout: 'Mostrar Layout',
        image_type: 'Estilo de imagen del producto',
        withdraw_fee: 'Gasto de Retiro',
        minimum_value: 'Valor Mínimo de Pedido',
        seller_checkout: 'Método de Comprobación',
        no_sales: 'No permitir ventas',
        safe_mode: 'Modo de Seguridad',
        time_safe_mode: 'Tiempo de Sesión en Modo Seguro (en minutos)',
        limit_characteres_explanation:
          'Para tiendas con integración Wintor o Protheus, la longitud recomendada es de no maximo 50 caracteres.',
        limit_characteres: 'Limite de caracteres',
        skip_entry: 'Saltar la pantalla de entrada',
        payment_table: 'Pago em la mesa',
        slides_homePage: 'Imágenes destacadas iniciales de la página de inicio',
        slides_initial: 'Imágenes destacadas iniciales',
        slides_not_found: 'No se encontró ninguna imagen del control deslizante',

        externalLink: 'Enlace externo',
        buttonType: 'Tipo de Botón',
        ableUserLocation: 'Permitir búsqueda de direcciones por ubicación',
        
        seller_cart_limit_time : 'Tiempo de vencimiento del cesta de la compra del vendedor en hora',
        seller_cart_limit_time_explanation : 'Tiempo para que el cliente pueda utilizar cesta de la compra del vendedor',
        settings_webapp_aggregator:'Personalizar',
        generate_qrcode_webapp_aggregator:'Generar Qrcodes',
        qrcode_webapp_aggregator:'Web Application',
      },

      integration: {
        integration: 'Integración',
        means_of_payment: 'Medios de pago',
        here_are_listed_the_means_of_payment_along_with_their_respective_actions:
          'A continuación se enumeran los medios de pago junto con sus respectivas acciones',
        PDV: 'Integración PDV',
        listed_here_are_the_integrations_along_with_their_respective_actions:
          'Aquí se enumeran las integraciones junto con sus respectivas acciones.',
        integration_name: 'Nombre de integración',
        type_of_integration: 'Tipo de integración',
        actions: 'Comportamiento',
        search: 'Buscar',
        example: 'Ejemplo de Payload',

        loads: 'Cargas',
        name: 'Nombre',
        price: 'Precio',
        stock: 'Inventario',
        measurements_units: 'Unidades de Medidas',
        grid: 'Grilla',
        classifications: 'Clasificadores',
        products: 'Productos',
        status_sync: 'Sincronización de Status',
        marks: 'Marcas',
        orders: 'Peticiones',
        trigger_load: 'Carga de disparo',
        first: 'Comienzo',
        end: 'Fin',
        load: 'Cargar',
        messenger: 'Mensajería',
        others: 'Otros',
        test: '¿Quieres probar la integración',
        params_test: 'Ver configuración para esta integración',
        copy_params: 'Copiar parámetros',
        result_test: 'Resultados de la prueba de integración',
        success_1: '¡Éxito! Integración',
        success_2: 'fue exitoso.',
        result_log: 'Registro de resultados',
        copy_result: 'Copiar resultado',
        search_result: 'Buscar en resultados',
        error_1: 'Integración',
        error_2: 'devolvió un error. Consulte el registro a continuación para obtener más información.'
      },

      reports: {
        orders: 'Peticiones',
        order_report: 'Informe de Peticiones',
        order_list: 'Lista de Peticiones',
        first: 'Comienzo',
        end: 'Fin',
        firstTime: 'Hora inicial',
        endTime: 'Hora final',
        orders_between: 'Peticiones entre',
        order_number: 'Número del Peticione',
        date: 'Data',
        consumer: 'Consumidor',
        modality: 'Modalidad',
        total_value: 'Valor Total',
        cancel_order: 'Cancelar peticione',

        products: 'Productos',
        quantity: 'Cantidad',
        product: 'Producto',
        value: 'Valor',
        total: 'Total',
        order_status: 'Status del Peticione',
        payment_method: 'Forma de pago',
        billing_address: 'Dirección de envio',
        product_value: 'Valor de los Productos',
        discount: 'Descuento',
        order: 'Peticione',

        listened_logs: 'Aquí se enumeran los logs junto con sus respectivas acciones.',
        methods: 'Métodos',
        log_details: 'Detalles del Log',

        withdraw: 'Retiro',
        delivery: 'Entrega',
        table: 'Mesa',
        responsible_withdraw: 'Responsable del retiro',
        responsible_delivery: 'Responsable de recepción',
        address_withdraw: 'Dirección de retiro',
        address_delivery: 'Dirección de entrega',

        seller: 'Vendedor',
        external_code: 'Orden Externa',
        payment: 'Pagamento',
        internal_delivery: 'Entrega Interna'
      },

      imports: {
        imports: 'Importaciones',
        import_image_product: 'Importaciones de Imágenes para Productos',
        import_image_category: 'Importaciones de Imágenes para Categorías',
        listened_import_product:
          'Aquí se enumeran las imágenes para productos junto con sus respectivas acciones.',
        no_upload_images: 'No subas imagenes',
        first: 'Comienzo',
        end: 'Fin',
        total_products: 'Productos Totales',
        sync_products: 'Productos Sincronizados',
        import_image_sku: 'Importaciones de Imágenes para Variaciones',
        listened_import_sku:
          'Aquí se enumeran las imágenes para variaciones junto con sus respectivas acciones.',
        verify_verification: 'Verificación de variación',
        product_code: 'Código del producto',
        code: 'Código',
        total_variations: 'Variaciónes Totales',
        sync_variations: 'Variaciónes Sincronizadas',
        total_categories: 'Categorías Totales',
        sync_categories: 'Categorías Sincronizadas',
        problem_title: 'Imágenes con problema',
        images_success: 'las imágenes se cargaron exitosamente.',
        images_error: 'las imágenes tuvieron un problema y no fueron enviadas.',
        file: 'Archivo',
        status: 'Estado de importación',
        new_import: 'Realizar una nueva importación',
        import_xls: 'Importar imágenes con archivo XLS',
        import_repo: 'Importar imágenes desde Google Drive o Dropbox',
        steps: 'Paso a paso para importar',
        step_1: '1. Coloque todas las imágenes en un servidor web público.',
        step_2: '2. Asegúrese de que el servidor sea público y accesible a través de la web.',
        step_3: '3. Descargue nuestro archivo XLSX en blanco.',
        step_4: '4. Rellena los datos de tu banco de imágenes.',
        step_5: '5. Por favor cargue el archivo completo.',
        step_1_repo:
          '1. Asegúrese de que la carpeta de la tienda esté creada como "fotos_loja_<identificador de la tienda>".',
        step_2_repo: '2. Crea la carpeta "produtos" dentro de la carpeta de tu tienda.',
        step_2_repo_sku: '2. Crea la carpeta "variacoes" dentro de la carpeta de tu tienda.',
        step_2_repo_category: '2. Crea la carpeta "categorias" dentro de la carpeta de tu tienda.',
        step_3_repo: '3. Confirme que los archivos sean como "<código del producto>.<extensión>".',
        step_3_repo_sku: '3. Confirme que los archivos sean como "<sku>.<extensión>".',
        step_3_repo_category:
          '3. Confirme que los archivos sean como "<código del categoría>.<extensión>".',
        step_4_repo: '4. Si el producto es un producto adicional, verifique si tiene "_<número>".',
        step_4_repo_sku:
          '4. Confirma que el variacione es una imagen y tiene la extensión JPG o PNG.',
        step_4_repo_category:
          '4. Confirma que el categoría es una imagen y tiene la extensión JPG o PNG.',
        step_5_repo: '5. Confirma que el producto es una imagen y tiene la extensión JPG o PNG.',
        download: 'Descargar archivo XLSX en blanco',
        download_problems: 'Exportar informes',
        upload: 'Enviar archivo',
        upload_anex: 'Adjuntar archivo',
        import_dropbox: 'Importar con Dropbox',
        import_drive: 'Importar con Google Drive',
        spreadsheet: 'Planilha',
        gdrive: 'Google Drive',
        dropbox: 'Dropbox',
        type: 'Tipo',
        type_resize: 'Tipo de Resize',
        agroup_color: 'Agrupar fotos por color'
      },

      sms: {
        sms: 'SMS',
        listened_sms: 'Aquí se enumeran las mensajes (SMS) junto con sus respectivas acciones.',
        order: 'Orden',
        name: 'Nombre',
        message: 'Mensaje',
        markers: 'Las etiquetas [solicitud] y [estado] deben estar en el mensaje',
        waiting: 'Esperando',
        opened: 'En abierto',
        ready: 'Listo',
        refused: 'rechazado',
      },

      multichannel: {
        multichannel: 'Multicanal',
        listened_multichannel:
          'Aquí se enumeran los ajustes de multicanal junto con sus respectivas acciones.',
      },

      queue: {
        index: 'Cola de espera',
        config: 'Configuración de la cola',
        able: 'Permitir cola',
        link: 'Link:',
        limit_people: 'Número máximo de personas',
        limit_people_text: 'Límite de cola de espera',
        limit_time: 'Límite de tiempo para mostrar alerta',
        limit_time_text: 'Tiempo máximo en minutos para considerar retraso',
        image_title: 'Definición de imágenes',
        image_text:
          'El sistema de cola muestra 2 imágenes. Cargue fotos de su restaurante o seleccione fotos predeterminadas a continuación. Las fotos cargadas se ajustarán al tamaño de 350x250 píxeles.',
        image: 'Imagen',
        position: 'Posición',
        time: 'Tiempo de espera',
        people: 'gente',
        cancel: 'Cancelación',
        cancel_text: '¿Quieres eliminar a esta persona de la cola?',
        cancel_button: 'Eliminar',
        confirm: 'Confirmación',
        confirm_text: '¿Quieres confirmar la llegada de la reserva?',
        confirm_button: 'Confirmar llegada',
        empty: 'No hay lista de espera en este momento',
        confirm_all: 'Confirmar todo',
        cancel_all: 'Cancelar todo',
        observation: 'Observación',
        use_terms: 'Activar termos de consentimento',
        total_people: 'Número total de personas por grupo',
        total_people_text: 'Límite de cola de espera por grupo',
      },
      booking: {
        index: 'Reserva',
        config: 'Ajustes de reserva',
        able: 'Permitir reserva',
        link: 'Link:',
        image_title: 'Definición de imágenes',
        image_text:
          'El sistema de reserva muestra 2 imágenes. Cargue fotos de su restaurante o seleccione fotos predeterminadas a continuación. Las fotos cargadas se ajustarán al tamaño de 350x250 píxeles.',
        image: 'Imagen',
        days_week: 'Dias de la semana',
        booking_day: '¿Permitir reservas en este día?',
        booking_day_text: 'Defina a continuación los tiempos liberados para las reservas.',
        booking_day_subtext:
          'Al seleccionar una hora, se definirá una ventana de 1 hora. Ejemplo: seleccionando 19:00 la gente podrá hacer reservas entre las 19:00 y las 20:00.',
        max_people: 'Número máximo de personas por reserva',
        max_people_text: 'Es el tamaño máximo de un grupo de personas que pueden hacer una reserva',
        expiry_time: 'Tiempo máximo hasta que caduque la reserva',
        expiry_time_text: 'Tiempo en minutos de espera a que llegue el encargado de la reserva',
        day_confirm: 'Requiere confirmación en el día',
        day_confirm_text: 'El usuario necesita confirmar la reserva en la fecha elegida',
        payment_confirm: 'Requiere pago por adelantado en la reserva',
        value_payment_confirm: 'Importe del pago por adelantado',
        week: 'Semana',
        month: 'Mes',
        year: 'Año',
        use_terms: 'Activar termos de consentimento',
        empty: 'No hay reserva en este momento',
        use_time: 'Permitir reserva por periodo',
        time: 'Duración de la reserva (HH:mm)'
      },
      booking_day: {
        booking: 'Reservas del dia',
        date: 'Fecha',
        people: 'personas',
        expiry: 'Expira en',
        cancel_title: 'Cancelación',
        cancel_subtitle: '¿Quieres cancelar la reserva?',
        cancel_button: 'Cancelar la reserva',
        confirm_title: 'Confirmación',
        confirm_subtitle: '¿Quieres confirmar la llegada de la reserva?',
        confirm_button: 'Confirmar llegada',
      },
      booking_report: {
        report: 'Informes',
        search: 'Buscar reservas',
        time: 'Seleccionar periodo',
        date_start: 'Fecha de inicio',
        date_end: 'Fecha final',
        confirm: 'Confirmación',
        noShow: 'No show',
        confirmed: 'Reservas confirmadas',
        canceled: 'Reservas canceladas',
        open: 'En abierto',
        map: 'Mapa de reservas',
        booking: 'reservas',
      },
      booking_report_management: {
        management: 'Gestión de reservas',
        search: 'Buscar reservas',
        phone: 'Teléfono',
        date_start: 'Fecha de inicio',
        date_end: 'Fecha final',
        today: 'Hoy',
        tomorrow: 'Mañana',
        calendar: 'Calendario',
        see_all: 'Ver todo',
        people: 'personas',
        search_text: 'Resultados por búsqueda de teléfono o selección de fecha',
      },
      environment: {
        map: 'Mapa de ambientes',
        environment: 'Ambiente',
        index: 'Ambientes',
        name: 'Nombre',
        tables: 'Numero de mesas',
        seats_available: 'Asientos totales disponibles',
        actions: 'Comportamiento',
      },
      week_day: {
        sun: 'Domingo',
        mon: 'Lunes',
        tue: 'Martes',
        wed: 'Miércoles',
        thur: 'Jueves',
        fri: 'Viernes',
        sat: 'Sábado',
      },
      week_day_full: {
        sun: 'Domingo',
        mon: 'Lunes',
        tue: 'Martes',
        wed: 'Miércoles',
        thur: 'Jueves',
        fri: 'Viernes',
        sat: 'Sábado',
      },
      checkout: {
        title: 'Dados do Cliente',
        here_are_listed_the_modalities_along_with_their_respective_actions:
          'Aquí se enumeran los campos necesarios para crear el cliente.',
        active_mandatory: 'Campo obligatorio',
        active_visibility: 'Campo Visible',
      },

      liveShop: {
        liveShop: 'Catálogo en Vivo',
        liveShop_list: 'Aquí se enumeran los catálogos en vivo junto con sus respectivas acciones.',
        create: 'Crear Catálogos en Vivo',
        edit: 'Editar Catálogos en Vivo',
        dateInit: 'Comenzo',
        hourInit: 'Hora de Início',
        dateEnd: 'Fin',
        hourEnd: 'Hora de Finalización',
        status: 'Status',
        initDate: 'Data y Hora de Início',
        fimDate: 'Data y Hora de Finalización',
        name: 'Nombre',
        variations: 'Variaciones del producto',
        search_products_Attentions:
          'Los Productos y Variaciones devueltos deben tener un estado activado, tener precio, stock e imagen.',
        alert_delete: '¡No es posible eliminar un Live cuando comenzó o terminó!',
        alert_Edit: '¡No es posible editar un Live cuando comenzó o terminó!',
        delete: '¡Eliminar catálogo en vivo!',
      },

      logs: {
        //PagSeguro
        pagseguroPaymentCodePhone: 'Error en el código telefónico de Pagseguro',
        pagseguroPaymentErrorCountry: 'Error de código de país de Pagseguro',
        pagseguroPaymentErrorEmail: 'Erro de email de Pagseguro',
        authenticatePagseguro: 'Autenticación de Pagseguro',
        pagseguroPayment:  'Pago de Pagseguro',
        pagseguroPaymentCatch: 'Pago de Pagseguro',
        pagseguroPaymentResponse: 'Respuesta de pago de Pagseguro',
        pagseguroPaymentTransactions: 'Transaccion de pago de Pagseguro',
        pagseguroReturnPayment: 'Devolución de reversión de pago de Pagseguro',
        paymentPixPagseguro: 'Pix pago Pagseguro',

        pagseguroPaymentGateway: 'Gateway de Pago Pagseguro',
        getAuthPagseguroEmailSpace: 'Autenticación do Pagseguro por email',
        getOneApplicationClientId: 'Pegar aplicação do cliente Pagseguro',
        createAuthPagseguro: 'Crear autenticación Pagseguro',
        generatePublicKeyPagseguro: 'Generar clave pública de Pagseguro',
        updateCodeRedirectApplication: 'Actualización del código de la aplicación Pagseguro',
        deleteAuthPagseguroByEmail: 'Quitar autenticación Pagseguro',
        createPluginconfig: 'Configuración del  plugin',

        //Totvs pagamento HUB
        refundTotvs: 'Totvs reembolso',
        refundPayCard: 'Totvs reembolso con tarjeta',
        paymentTotvs: 'Totvs pago',
        paymentPixTotvs: 'Totvs pix pago',

        //Card Structure
        cardStructure: 'Estructura de la tarjeta',
        cardStructureInvalidPlugin: 'Plugin inválido para el gateway',
        cardStructureGatewayError: 'Error de tarjeta para el gateway',
        cardStructureData: 'Datos de la tarjeta',
        cardStructureError: 'Error de datos de la tarjeta',

        cardStructureDecrypt: 'Descifrado de la cartão',
        cardStructureFields: 'Campos de tarjeta',
        refundCard: 'Reembolso de tarjeta',

        //Status
        analysisSatusOrder: 'Estado del pedido para revisión',
        statusPayPix: 'Pix estado',

        //Antifraude
        analyseAntifraud: 'Análisis antifraude',

        //estoque
        addSkuInventory: 'Retirar del stock',
        subtractSku: 'Añadir al estoque',

        // Order-Pedido
        cancelOrder: 'Cancelar pedido',
        sendOrder: 'Enviar pedido',
        sendOrderRefund: 'Reembolso del pedido',
        sendOrderError: 'Enviar error de pedido',
        sendOrderResultRefund: 'Resultado del pedido de reembolso',
        sendOrderResultData: 'Enviar resultado del pedido',
        sendOrderAntifrud: 'Pedido de envío antifraude',
        sendOrderPaymentStruct: 'Estructura de pago del pedido',

        sendOrderDescryptor: 'Decripto del pedido',
        sendOrderStatus: 'Estado del pedido',
        sendOrderConfigNotFound: 'Configuración de envío de pedidos',
        sendOrderMandatoryCustomer: 'Cliente de pedido',
        sendOrderMandatory: 'Estructura del pedido',
        sendOrderResultGateway: 'Resultado de la gateway del pedido',

        //Pre-Order
        preOrderStatusPix: 'Estado del Pix del pedido antecipado',
        processQueuePreOrder: 'Cola de proceso de pedido anticipado',
        getAllPreOrderBySpace: 'Obtenga un pedido anticipado mediante pluginspace',
        sendPreOrder: 'Enviar pedido anticipado',
        makeOrderTransparent: 'Verificación de pedido',
        statusPreOrder: 'Atualización del estado del Pix do pedido anticipado',

        //Totvs Wintor
        buildOrderTotvsWinthor: 'Crear pedido Winthor',
        sendOrderWinthor: 'Enviar pedido Winthor',
        sendCustomerOrderWinthor: 'Enviar cliente Winthor',

        //Totvs Varejo
        buildOrderTotvsVarejo: 'Crear pedido Varejo',
        setConfimTicket: 'Confirmación de billete',
        SyncOrderLive: 'Sincronización de Pedidos',
        ObterProdutos: 'Obtener productos',
        taskPrice: 'Carga de precio',
        calculationPromotion: 'Cálculo de promoción',
        PayloadCalcPromotion: 'Carga útil Cálculo de promoción',

        //Totvs Moda
        changeStatus: 'Troca de estado',
        sendOrderFashion: 'Enviar pedido Fashion',
        sendCustomerFashion: 'Envio do cliente para Fashion',
        searchPersonTotvsByCPF: 'Buscar cliente Fashion',
        sendErrorCustomerFashion: 'Error en el envío del cliente Fashion',
        buildCustomerTotvsFashion: 'Enviar cliente Fashion',
        ResumeSnyOrderFashion: 'Resumen sincronía con Fashion',
        
        //Totvs Food
        updateStatusOrderFoodIntegration: 'Troca de estado Food integration',
        buildOrderTotvsFood: 'Crear pedido Food',
        changeStatusOrderFood: 'Troca de estado Food',
        sendOrderTotvsFood: 'Enviar pedido Food',
        confirmMenu: 'Menú confirmado',
        getMenu: 'Obtener Menú Totvs',

        //Totvs Live
        sendCustomerLive: 'Enviar cliente Live',
        sendOrderLive: 'Enviar pedido Live',

        //Synkar
        updateStatusOrderSynkar: 'Actualización estado del pedido Synkar',

        //SDS
        sdsGetOrders: 'Obtener pedidos SDS',
        sdsOrderIn: 'Envío de los pedidos SDS',
        sdsOrderInResult: 'Enviar pedido SDS',
        sdsSyncCardapio: 'Sincronización del menú con SDS',
        'sendOrderfood-sdsOrderIn': 'Error al enviar pedido SDS',

        //S3
        createRoute: 'Crear ruta s3',
        getStatusfiftythree: 'Ruta 53 estado',
        updateDistribution: 'Distribución update',
        deleteRoute: 'Deleta Ruta S3',
        deleteDistribution: 'Deleta distribución',

        //Erede
        refundErede: 'Erede reembolso',
        paymentErede: 'Erede pago',

        //Cielo
        refundCielo: 'Cielo reembolso',
        paymentCielo: 'Cielo pago',

        //pagamento offline
        paymentOffline: 'Pago sin conexión',

        //Geral
        chargeSyncOrder: 'Sincronización de estado',
        chargeSyncOrderErro: 'Erro de sincronización de estado',

        chargeProductLiveErro: 'Error en la sincronización del producto de Live',
        chargeBalanceLiveErro: 'Erro na Sincronia de Estoque de Live',
        chargePriceLiveErro: 'Error en sincronización de stock de Live',

        chargeProductLive: 'Sincronización de productos de Live',
        chargeBalanceLive: 'Sincronización de stock de Live',
        chargePriceLive: 'Sincronización de precios de Live',

        chargeProductErro: 'Error en la sincronización del producto',
        chargeBalanceErro: 'Erro na Sincronia de Estoque',
        chargePriceErro: 'Error en sincronización de stock',

        chargeProduct: 'Sincronización de productos',
        chargeBalance: 'Sincronización de stock',
        chargePrice: 'Sincronización de precios',

        taskBalanceIntegration: 'Sincronización de stock',
        taskBalanceCharge: 'Se inició la carga de stock',
        SnyOrder: 'Sincronización del estado del pedido',
        syncronyzePriceInteration: 'Carga de precios',
        syncronyzePrice: 'Sincronización de precios',
        chargeClassification: 'Carga de categorías y subcategorías',
        chargeGrid: 'Carga de grade',
        chargeGridItens: 'Elementos de carga de la cuadrícula',
        chargeMeasure: 'Carga de unidad de medida',
        deleteAllProducts: 'Eliminar todos los productos',

        chargeGridToken: 'Obtener token para la carga de la cuadrícula',
        classificationChargeToken: 'Obtener token para la carga de clasificación',
        syncronyzeBalanceToken: 'Obtener token para la sincronización de inventario',
        syncronyzePriceToken: 'Obtener token para la sincronización de precios',
        chargeMeasureToken: 'Obtener token para la carga de la unidad',
        taskClassificationBySpace: 'Carga de clasificación',
        syncStatusOrderPayload: 'Carga útil de sincronización de pedidos',
        syncStatusOrder: 'Sincronización del estado del pedido',
        syncronyzeBalancePayload: 'Carga útil de sincronización de inventario',
        syncronyzeBalance: 'Sincronización de inventario',
        syncronyzePricePayload: 'Carga útil de la sincronización de precios',
        priceSkuVariationItens: 'Precio de los artículos de variación',
        configSyncOrderBySpace: 'Configuración de sincronización de pedidos',
        configSyncOrder: 'Configuración de sincronización de pedidos',
        taskGridChargeBySpace: 'Carga de rejilla',
        productChargeInternal: 'Carga de productos',
        taskChargeProductBySpace: 'Carga de productos',
        skuLocationVariation: 'Sincronización de Variaciones por Ubicación',

        automaticChargeBalance: 'Sincronización automática de inventario',
        automaticChargePrice: 'Sincronización automática de precios',
        automaticChargeClassification: 'Sincronización automática de Clasificación',
        automaticTaskGridCharge: 'Sincronización automática de Grid',
        automaticTaskMeassure: 'Sincronización automática de Unidad de Medida',
        automaticSnyOrder: 'Sincronización automática Status de Order',
        chargeMeasureItens: 'Elementos de carga Unitaria Medida',
        searchMeasurementAll: 'Unidad de medida de carga',
        stockSkuVariation: 'Sincronismos de variación',
        taskGridCharge: 'Carga de Grid',
        taskMeassure: 'Unidad de medida de carga',

        getCredential: 'Obtener Token',
        LoadPricePerList: 'Carga de Precios',
        processPriceList: 'Procesamiento de la Lista de Precios',
        updateProductKit: 'Actualización de Productos en Kit',
        UpdateProductSingle: 'Actualización de Productos',
        updateProductStatus: 'Actualización del Estado del Producto',
        taskBalanceIntegrationLink: 'Enlace de integración de inventario',
        taskPriceIntegrationLink: 'Enlace de integración de precios',
        taskProductIntegrationLink: 'Enlace de integración de productos',
        token: 'Obtención de Token',
        automaticChargeProduct: 'Carga automática de productos',
       

        //Desconto Ninegrid
        calculateDiscount: 'Cálculo de descuento usando ninegrid',
        sendDiscountNinegrid: 'Descuento Ninegrid',

        //Customer Cart
        updateCustomerCartStatus: 'Actualización del carrito de compra del Cliente',

        //Cliente
        createCustomer: 'Creación de Cliente',

        //clearSale
        statusAnalisis: 'Análisis de estado',
        antifraudValidation: 'Validación antifraude',
        clearSale: 'ClearSale Garantido',
        searchOrderStatusClearSale: 'Búsqueda de estado de ClearSale',
      },

      copy: {
        definition: 'Definición de Pluginspace',
        origin: 'Seleccione el Pluginspace de origen',
        destination: 'Seleccione el Pluginspace de destino',
        configurations: 'Copiar configuración',
        store: 'Configuración de la tienda',
        deliveryFee: 'Tarifa de envío',
        shifts: 'Turnos',
        paymentPlans: 'Planes de pago',
        clientData: 'Datos del cliente',
        account: 'Configuración de la Cuenta',
        groups: 'Grupos',
        terms: 'Términos',
        webapp: 'Webapp',
        webappConfig: 'Configuración',
        webappPersonalize: 'Personalización',
        integrations: 'Integraciones',
        integrationPayment: 'Métodos de pago',
        integrationPdv: 'PDV',
        integrationMessaging: 'Mensajería',
        integrationOthers: 'Otros',
        button: 'Copiar Pluginspace',
      },

      payment: {
        methods: 'Métodos de pago',
        method: 'Método de pago',
        gateway: 'Gateway de pago',
        card: 'Tarjeta de crédito',
        debit: 'Tarjeta de débito',
        food: 'Cupones de alimentos',
        pix: 'Pix',
        pay_money: 'Dinheiro',
        config: 'Ajustes',
        add: 'Configurar método de pago',
        back: 'Volver a las gateways de pago',
        basic_settings: 'Configuraciones básicas',
        modalities: 'Modalidades donde se utilizará este método de pago',
        add_payment: 'Agregar nuevo método de pago',
        code_erp: 'Código ERP',
        installments: 'Número de plazos',
        edit_payment: 'Editar método de pago',
        delete: 'Excluir método de pago',
        money: 'Pago en dinero',
        max_installments: '¿Cuál es el número máximo de cuotas?',
        confirm: 'Confirmar la eliminación',
        delete_payment: '¿Quieres eliminar el método de pago',
        change: '¿Quieres realizar el cambio?',
        inative: '¿Quieres desactivar este método de pago?',
        inative_change: '¿Quieres activar este método de pago e desactivar el viejo?',
        motive: 'Por favor, proporciona el motivo'
      },
      freight: {
        title: 'Métodos de envío',
        map: 'Mapa',
        map_text: 'Opción de pago al momento de la entrega del pedido',
        range: 'Radio',
        range_text: 'Opción de pago al momento de la entrega del pedido',
        cep: 'Lista de CEP',
        cep_text: 'Opción de pago al momento de la entrega del pedido',
        config: 'Ajustes',
      },
      icon: {
        title: 'Iconos',
        subtitle: 'Here it is listed all icons'
      },
      chat: {
        you: 'Tú',
        now: 'Enviado ahora',
        send: 'Enviar',
        type_message: 'Escribe un mensaje...',
        initial_message: 'Bienvenido al chat de IA de Ninegrid',
        error_message: "No pude encontrar una respuesta a tu mensaje",
        tip_language: 'Responder en español por favor.',
        title_config: 'Configuración de Inteligencia Artificial',
        config: 'Configurar Inteligencia Artificial',
        fit: 'Capacitación da Inteligencia Artificial',
        fit_header: 'Aquí se enumeran las preguntas con sus respuestas creadas',
        add_question: 'Agregar pregunta',
        edit_question: 'Editar pregunta',
        delete_question: '¿Quieres eliminar la siguiente pregunta?',
        question: 'Pregunta',
        answer: 'Respuesta',
        date: 'Data',
        actions: 'Comportamiento',
        active: 'Activo',
        delete: 'Borrar',
        sync: 'Sincronizar IA'
      },
      sumary_access: {
        cart: 'Carrito',
        seller: 'Inicio de Sesión del Vendedor',
        category: 'Categorías',
        pdv: 'Detalles del Producto',
        subcategory: 'Subcategoría',
        product: 'Lista de Productos',
        home: 'Pantalla Principal',
        'search-order': 'Pedidos',
        'store-info': 'Información de la Tienda',
        customer: 'Registro de Cliente',
        receipt: 'Recibo de Pago',
        order: 'Pago',
        card: 'Pago con Tarjeta',
        'order-qrcode': 'Pago con Pix del Cliente',
        'success-order': 'Compra Exitosa',
        'filter/pdv': 'Resultados del Filtro',
        filter: 'Selección de Filtros',
        'seller-qrcode': 'Enlace de Pago',
        'vendor-order': 'Consulta del Carrito del Vendedor',
        'finalize-order': 'Resumen de la Compra',
    }
    },
  },
}

export { messages }
