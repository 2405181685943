/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { PUT, GET } from '../../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const EditStatusContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [order, setOrder] = useState('0')
  const [name, setName] = useState('')
  const [status, setStatus] = useState(true)
  const [message, setMessage] = useState('')

  const getStatus = async () => {
    try {
      setLoading(true)
      const resStatus = (await GET(`/status/${id}`)).data[0]
      setOrder(resStatus.order)
      setName(resStatus.name)
      setStatus(resStatus.displayStatusWebapp)
      setMessage(resStatus.messageStatus)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const update = async () => {
    try {
      setLoading(true)
      const resStatus = await PUT(`/status/${id}`, {
        displayStatusWebapp: status,
        messageStatus: message,
        name: name,
      })

      setLoading(false)
      if (resStatus.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Status atualizado',
          text: 'Atualização realizada com sucesso!',
        }).then(() => navigate(`/message`))
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao atualizar o status!',
        }).then(() => navigate(`/message/edit/${id}`))
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getStatus()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`general.edit`)} Status</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                {/* <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    value={order}
                    placeholder='Ordem'
                    label='Ordem'
                    disabled
                  />
                </div> */}
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    value={name}
                    change={setName}
                    placeholder={i18n.t(`sms.name`)}
                    label={i18n.t(`sms.name`)}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Status'
                    value={status}
                    change={setStatus}
                    options={[
                      { select: i18n.t(`general.active`), value: 'true' },
                      { select: i18n.t(`general.inactive`), value: 'false' },
                    ]}
                  />
                </div>
              </div>
              <div className='d-flex flex-column'>
                <Input
                  inputAttr={{
                    type: 'textarea',
                  }}
                  value={message}
                  change={setMessage}
                  label={i18n.t(`sms.message`)}
                  placeholder='Escreva aqui uma mensagem para este status'
                  maxCaracteres={200}
                  rows={4}
                />
                <p className='mt-4'>{i18n.t(`sms.markers`)}</p>
              </div>
            </div>
            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => update()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const EditarStatus: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`buttons.message`), path: 'message', isActive: true },
          { title: i18n.t(`general.edit`), path: 'message/edit', isActive: true },
        ]}
      >
        {i18n.t(`general.edit`)} Status
      </PageTitle>
      <EditStatusContent />
    </>
  )
}

export { EditarStatus }
