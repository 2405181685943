import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../../../_metronic/layout/core";
import { i18n } from "../../../translate/i18n";
import Swal from 'sweetalert2'
import { GET, POST } from '../../../services/api'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import SVG from 'react-inlinesvg';
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs";
import { Button } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";

type Icon = {
    id: number;
    svg: string;
    description: string;
    category: string;
}



const IconContent: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate()
    const [svgContent, setSvgContent] = useState<string | null>(null);
    const [name, SetName] = useState<string>('');
    const [icone, setIcone] = useState<string | null>(null);
    const [icones, setIcones] = useState<Icon[]>([])

    function readSvg(file: File) {
        if (file) {
            const reader = new FileReader();
            reader.onload = (e: any) => {
                const content = e.target.result;
                if (typeof content === 'string' && content.startsWith('data:image/svg+xml;base64,')) {
                    // O arquivo é um SVG
                    const icone = atob(content.split('base64,')[1])
                    setIcone(icone);
                } else {
                    // O arquivo não é um SVG
                    alert('Por favor, selecione um arquivo SVG.');
                }
            };
            reader.readAsDataURL(file); // Lê o arquivo como data URL
        }
    }


    async function getIcones() {
        try {
            setLoading(true)
            const res = await GET('svgwebapp')

            if (!res || !res.success) {
                Swal.fire({
                    icon: 'error',
                    titleText: 'Erro',
                    text: 'Erro ao encontrar os tipos'
                })
                setLoading(false)
                return
            }

            setIcones(res.data)

            setLoading(false)

        } catch (error) {
            console.log(error)
            setLoading(false)

            Swal.fire({
                icon: 'error',
                titleText: 'Erro',
                text: 'Erro ao encontrar os tipos'
            })
        }
    }

    async function createSvg() {
        try {
            if (!name || !icone) {
                Swal.fire(
                    {
                        icon: "warning",
                        title: "Atenção",
                        text: "Verifique os campos faltantes"
                    }
                )
                return
            }

            setLoading(true)

            const body = {
                svg: icone,
                description: name,
                category: 'Sem Categoria'
            }

            const res = await POST('svgwebapp', body)

            if (!res || !res.success) {
                Swal.fire({
                    icon: 'error',
                    titleText: 'Erro',
                    text: 'Erro ao cadastrar o Icone'
                })
                setLoading(false)
                return
            }
            setLoading(false)

            navigate('/dashboard')
        } catch (error) {
            console.log(error)
            setLoading(false)

            Swal.fire({
                icon: 'error',
                titleText: 'Erro',
                text: 'Erro ao cadastrar o Icone'
            })
        }
    }

    useEffect(() => {
        getIcones()
    }, [])

    return (
        <>
            {
                loading ? (
                    <Loading />
                ) : (
                    <div className="d-flex" style={{
                        gap: '2rem'
                    }}>
                        <div className='row g-5 w-100' >
                            <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`icon.title`)}</span>
                                        <BreadCrumbs />
                                    </h3>
                                </div>
                                <div className='card-body d-flex gap-5 flex-column  justify-content-between'>
                                    <div className="d-flex gap-5 flex-column">
                                        <div className="w-50">
                                            <Input
                                                inputAttr={{
                                                    type: 'default',
                                                }}
                                                label={'Nome'}
                                                placeholder={'Nome'}
                                                value={name}
                                                change={(value: any) => {
                                                    SetName(value)
                                                }}
                                            />
                                        </div>
                                        <div className="w-50">
                                            <Input
                                                inputAttr={{
                                                    type: 'file',
                                                }}
                                                label={'Icone'}
                                                acceptFile=".svg"
                                                placeholder={'Icone'}
                                                change={(value: any) => {
                                                    readSvg(value ? value.target.files[0] : null)
                                                }}
                                            />
                                        </div>
                                        <div className="w-50">
                                            {icone && (
                                                <div>
                                                    <h2>Ícone Selecionado:</h2>
                                                    <SVG src={icone} style={{
                                                        width: '45px',
                                                        height: '45px'
                                                    }} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mt-2 d-flex justify-content-between">
                                        <Button
                                            text='Voltar'
                                            color='info'
                                            size='small'
                                            horizontalPadding={5}
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => {
                                                navigate('/dashboard')
                                            }}
                                            btnClass='fw-bolder me-3'
                                        />
                                        <Button
                                            text='Salvar'
                                            color='primary'
                                            size='small'
                                            horizontalPadding={5}
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => {
                                                createSvg()
                                            }}
                                            btnClass='fw-bolder'
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='row g-5 w-100' style={{
                            maxHeight: "60rem"
                        }}>
                            <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`icon.title`)}</span>
                                    </h3>
                                </div>
                                <div className='card-body d-flex gap-5 flex-row flex-wrap overflow-auto'>
                                    {icones.map(icon => (
                                        <div className="d-flex flex-column p-4">
                                            <span>{icon.description}</span>
                                            <SVG src={icon.svg} style={{
                                                width: '45px',
                                                height: '45px'
                                            }} />
                                        </div>
                                    ))

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}


const Icon: FC = () => {

    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`imports.imports`), path: 'icon', isActive: true },
                ]}
            >
                {i18n.t(`imports.problem_title`)}
            </PageTitle>
            <IconContent />
        </>
    )
}

export { Icon }