import { FC, useEffect, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import { Button } from "../../../components/Button/Button"
import SVG from 'react-inlinesvg';
import Swal from "sweetalert2"
import { Input } from "../../../components/Input/Input"
import { GET, POST, PUT } from "../../../services/api"
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import usePluginsSpace from "../../../hooks/usePluginSpace"
import { Datatable } from '../../../components/Datatable/Datatable'




type segmentoAgregador = {
    name: string;
    order: number;
    image: string
    type: string;
    active: boolean;

}

type classIcon = {
    name: string
    data: Icon[]
}

type Icon = {
    id: number;
    svg: string;
    description: string;
    category: string;
}

type TermoType = {
    description: string;
  }


const SegmentsEditContent: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { pluginSpace } = usePluginsSpace()
    const [types, setTypes] = useState<any[]>([{
        select: 'Selecione',
        value: '',
    }, {
        select: 'hospitality',
        value: 'hospitality',
    },
    {
        select: 'food',
        value: 'food',
    },
    {
        select: 'healthcare',
        value: 'healthcare',
    },

    {
        select: 'varejo',
        value: 'varejo',
    },
    {
        select: 'fashion',
        value: 'fashion',
    },
    {
        select: 'service',
        value: 'service',
    },
    {
        select: 'housegarden',
        value: 'housegarden',
    }])
    const [loading, setLoading] = useState(false)
    const [loadingIcons, setLoadingIcons] = useState(false)
    const [form, setForm] = useState<segmentoAgregador>({
        name: '',
        order: 0,
        image: '',
        type: '',
        active: true,
    }
    )
    const [icones, setIcones] = useState<Icon[]>([])
    const [iconesFiltered, setIconesFiltered] = useState<Icon[]>([])

    const [categoryIcons, setCategoryIcons] = useState<any[]>([{
        select: 'Todos',
        value: -1
    }])
    const [categoryIconsSelect, setCategoryIconsSelect] = useState<any>(-1)
    const [icon, setIcon] = useState<string>('')
    const [termos, setTermos] = useState<Array<TermoType>>([]);

    async function create() {
        if (form.name === ''
            || form.type === ''
        ) {
            Swal.fire(
                {
                    icon: 'warning',
                    title: 'Atenção, campos faltando',
                    text: 'Verifique os campos novamente'
                }
            )
            return
        }

        try {
            setLoading(true)



            if (icon) {
                const iconImage = await svgToPng(icon)

                const formData = new FormData()
                formData.append('file', iconImage)
                formData.append('nameImage', `svg/${form.name.toLowerCase().trim()}.png`)
                formData.append('originalSize', 'true')
                const imageRes = await POST('/upload-images/upload-one', formData)
                const urlImage = imageRes.success ? imageRes.image.split('https://s3.amazonaws.com/img.ninegrid.com/')[1] : ''



                if (!imageRes.success) {
                    Swal.fire(
                        {
                            icon: 'error',
                            title: 'Erro',
                            text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                        }
                    )
                    setLoading(false)
                    return
                }

                const urlSliced = urlImage.split('/')
                form.image = `${urlSliced[2]}/${urlSliced[3]}`
            } 
            const response = await PUT(`segment/${id}`, form)

            if (!response || !response.success) {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                    }
                )
                setLoading(false)
                return
            }



            Swal.fire(
                {
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Cadastro do segmento realizado com sucesso'
                }
            )




            setLoading(false)

            navigate('/segmentos')
        } catch (error) {
            setLoading(false)
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Erro',
                    text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                }
            )


        }





    }

    async function getIcones() {
        try {
            setLoading(true)

            const svgCategory = await POST('svgcategory/filter', {
                status: true
            });

            const auxCategory = [{
                select: 'Todos',
                value: -1
            }]

            svgCategory.data.map((el: any) => {
                auxCategory.push({
                    select: el.name,
                    value: el.id
                }
                )
            })

            setCategoryIcons(auxCategory)



            const res = await GET('svgwebapp')

            if (!res || !res.success) {
                Swal.fire({
                    icon: 'error',
                    titleText: 'Erro',
                    text: 'Erro ao encontrar os tipos'
                })
                setLoading(false)
                return
            }

            setIcones(res.data)
            setIconesFiltered(res.data)


            await getSegment()



        } catch (error) {
            console.log(error)
            setLoading(false)

            Swal.fire({
                icon: 'error',
                titleText: 'Erro',
                text: 'Erro ao encontrar os tipos'
            })
        }
    }

    function changeFilterIcons() {
        setLoadingIcons(true)
        const filtered = icones.filter(icon => {

            if (categoryIconsSelect !== -1) {


                return icon.category === categoryIconsSelect ? true : false

            }
            return true


        })

        setIconesFiltered(filtered)


        setTimeout(() => {
            setLoadingIcons(false)
        }, 1000)
    }

    async function getSegment() {
        try {
            const seg = await GET(`segment/${id}`)

            if (seg) {
                setForm({
                    name: seg.data.name,
                    order: seg.data.order,
                    type: seg.data.type,
                    image: seg.data.image,
                    active: seg.data.active,
                })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    async function teste() {
        try {
            console.log("Click");
            
        } catch (error) {
            console.log(error)
        }
    }

    function svgToPng(svgString: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const image = new Image();
            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx!.drawImage(image, 0, 0);

                // Convert canvas to Blob
                canvas.toBlob(blob => {
                    if (blob) {
                        const pngFile = new File([blob], form.name.toLowerCase().trim(), { type: 'image/png' });
                        resolve(pngFile);
                    } else {
                        reject(new Error('Failed to convert SVG to PNG File'));
                    }
                }, 'image/png');
            };

            image.src = 'data:image/svg+xml;base64,' + btoa(svgString);
        });

    }

    useEffect(() => {
        getIcones()
    }, [])


    return (
        <>
            {loading ? <Loading /> :
                <div className='row g-5 gx-xxl-12'>
                    <div className='d-flex justify-content-between flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                            <div className='w-100 card'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-4 mb-1'>Termos</span>
                                        <BreadCrumbs />
                                        <span className='text-muted mt-1 fw-bold fs-7'>Aqui estão listados os termos de aceite da plataforma de vendas</span>
                                    </h3>
                                    <div className='card-toolbar'>
                                    {/* <Button
                                    text='Importar lista'
                                    color='light'
                                    iconColor='#7e8299'
                                    icon='bi bi-box-arrow-in-down-left'
                                    size='small'
                                    horizontalPadding={6}
                                    btnAttr={{
                                    type: 'button',
                                    }}
                                    click={() => {}}
                                    btnClass='fw-bolder me-5'
                                    /> */}
                                    <Button
                                    text='Adicionar termo'
                                    color='primary'
                                    iconColor='#FFF'
                                    icon='fas fa-plus'
                                    size='small'
                                    horizontalPadding={6}
                                    btnAttr={{
                                    type: 'button',
                                    }}
                                    click={() => navigate('/configuracoes/usuarios/criar')}
                                    btnClass='fw-bolder'
                                    />
                                    </div>
                                </div>
                                <div className='px-10 py-5'>
                                <Datatable
                                    data={termos}
                                    headers={[
                                    { key: i18n.t(`user.picture`), value: 'image', type: 'image' },
                                    { key: i18n.t(`user.name`), value: 'name', type: 'default' },
                                    { key: 'E-mail', value: 'email', type: 'default' },
                                    { key: i18n.t(`user.type`), value: 'type', type: 'default' },
                                    { key: i18n.t(`user.registration`), value: 'created', type: 'date' },
                                    { key: i18n.t(`user.actions`), value: 'acoes', type: '' },
                                    ]}
                                    
                                    color='primary'
                                /> 
                                </div>
                            </div>
                        </div>
                        
                    </div >
                    <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
                        <Button
                            text='Voltar'
                            color='info'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={() => {
                                Swal.fire({
                                    title: 'Você tem certeza?',
                                    text: 'Os dados que foram digitados serão perdidos.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim, voltar!',
                                    cancelButtonText: 'Não, cancelar!',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate('/segmentos')
                                    }
                                })
                            }}
                            btnClass='fw-bolder me-3'
                        />
                        <Button
                            text='Salvar'
                            color='primary'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={create}
                            btnClass='fw-bolder'
                        />
                    </div>
                </div >
            }
        </>


    )
}

const SegmentsEditarAgregador: FC = () => {
    const { id } = useParams()
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`Termo`), path: '/config/term', isActive: true },
                  ]}
                >
                  {i18n.t(`Termo`)}
            </PageTitle>
            <SegmentsEditContent />
        </>
    )
}

export { SegmentsEditarAgregador }

/*
import { FC, useEffect, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import { Button } from "../../../components/Button/Button"
import SVG from 'react-inlinesvg';
import Swal from "sweetalert2"
import { Input } from "../../../components/Input/Input"
import { GET, POST, PUT } from "../../../services/api"

import usePluginsSpace from "../../../hooks/usePluginSpace"





type segmentoAgregador = {
    name: string;
    order: number;
    image: string
    type: string;
    active: boolean;

}

type classIcon = {
    name: string
    data: Icon[]
}

type Icon = {
    id: number;
    svg: string;
    description: string;
    category: string;
}




const SegmentsEditContent: FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const { pluginSpace } = usePluginsSpace()
    const [types, setTypes] = useState<any[]>([{
        select: 'Selecione',
        value: '',
    }, {
        select: 'hospitality',
        value: 'hospitality',
    },
    {
        select: 'food',
        value: 'food',
    },
    {
        select: 'healthcare',
        value: 'healthcare',
    },

    {
        select: 'varejo',
        value: 'varejo',
    },
    {
        select: 'fashion',
        value: 'fashion',
    },
    {
        select: 'service',
        value: 'service',
    },
    {
        select: 'housegarden',
        value: 'housegarden',
    }])
    const [loading, setLoading] = useState(false)
    const [loadingIcons, setLoadingIcons] = useState(false)
    const [form, setForm] = useState<segmentoAgregador>({
        name: '',
        order: 0,
        image: '',
        type: '',
        active: true,
    }
    )
    const [icones, setIcones] = useState<Icon[]>([])
    const [iconesFiltered, setIconesFiltered] = useState<Icon[]>([])

    const [categoryIcons, setCategoryIcons] = useState<any[]>([{
        select: 'Todos',
        value: -1
    }])
    const [categoryIconsSelect, setCategoryIconsSelect] = useState<any>(-1)
    const [icon, setIcon] = useState<string>('')


    async function create() {
        if (form.name === ''
            || form.type === ''
        ) {
            Swal.fire(
                {
                    icon: 'warning',
                    title: 'Atenção, campos faltando',
                    text: 'Verifique os campos novamente'
                }
            )
            return
        }

        try {
            setLoading(true)



            if (icon) {
                const iconImage = await svgToPng(icon)

                const formData = new FormData()
                formData.append('file', iconImage)
                formData.append('nameImage', `svg/${form.name.toLowerCase().trim()}.png`)
                formData.append('originalSize', 'true')
                const imageRes = await POST('/upload-images/upload-one', formData)
                const urlImage = imageRes.success ? imageRes.image.split('https://s3.amazonaws.com/img.ninegrid.com/')[1] : ''



                if (!imageRes.success) {
                    Swal.fire(
                        {
                            icon: 'error',
                            title: 'Erro',
                            text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                        }
                    )
                    setLoading(false)
                    return
                }

                const urlSliced = urlImage.split('/')
                form.image = `${urlSliced[2]}/${urlSliced[3]}`
            } 
            const response = await PUT(`segment/${id}`, form)

            if (!response || !response.success) {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                    }
                )
                setLoading(false)
                return
            }



            Swal.fire(
                {
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Cadastro do segmento realizado com sucesso'
                }
            )




            setLoading(false)

            navigate('/segmentos')
        } catch (error) {
            setLoading(false)
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Erro',
                    text: 'Não foi possivel cadastrar o segmento, tente novamente mais tarde'
                }
            )


        }





    }

    async function getIcones() {
        try {
            setLoading(true)

            const svgCategory = await POST('svgcategory/filter', {
                status: true
            });

            const auxCategory = [{
                select: 'Todos',
                value: -1
            }]

            svgCategory.data.map((el: any) => {
                auxCategory.push({
                    select: el.name,
                    value: el.id
                }
                )
            })

            setCategoryIcons(auxCategory)



            const res = await GET('svgwebapp')

            if (!res || !res.success) {
                Swal.fire({
                    icon: 'error',
                    titleText: 'Erro',
                    text: 'Erro ao encontrar os tipos'
                })
                setLoading(false)
                return
            }

            setIcones(res.data)
            setIconesFiltered(res.data)


            await getSegment()



        } catch (error) {
            console.log(error)
            setLoading(false)

            Swal.fire({
                icon: 'error',
                titleText: 'Erro',
                text: 'Erro ao encontrar os tipos'
            })
        }
    }

    function changeFilterIcons() {
        setLoadingIcons(true)
        const filtered = icones.filter(icon => {

            if (categoryIconsSelect !== -1) {


                return icon.category === categoryIconsSelect ? true : false

            }
            return true


        })

        setIconesFiltered(filtered)


        setTimeout(() => {
            setLoadingIcons(false)
        }, 1000)
    }

    async function getSegment() {
        try {
            const seg = await GET(`segment/${id}`)

            if (seg) {
                setForm({
                    name: seg.data.name,
                    order: seg.data.order,
                    type: seg.data.type,
                    image: seg.data.image,
                    active: seg.data.active,
                })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    function svgToPng(svgString: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const image = new Image();
            image.onload = () => {
                canvas.width = image.width;
                canvas.height = image.height;
                ctx!.drawImage(image, 0, 0);

                // Convert canvas to Blob
                canvas.toBlob(blob => {
                    if (blob) {
                        const pngFile = new File([blob], form.name.toLowerCase().trim(), { type: 'image/png' });
                        resolve(pngFile);
                    } else {
                        reject(new Error('Failed to convert SVG to PNG File'));
                    }
                }, 'image/png');
            };

            image.src = 'data:image/svg+xml;base64,' + btoa(svgString);
        });

    }

    useEffect(() => {
        getIcones()
    }, [])


    return (
        <>
            {loading ? <Loading /> :
                <div className='row g-5 gx-xxl-12'>
                    <div className='d-flex justify-content-between flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                            <div className='w-100 card'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-4 mb-1'>Dados do Segmento</span>
                                    </h3>
                                </div>
                                <div className='px-10 py-5'>
                                    <Input
                                        inputAttr={{
                                            type: 'default',
                                        }}
                                        placeholder='Nome do Segmento'
                                        label='Nome do Segmento'
                                        value={form.name || ''}
                                        change={(value: any) => setForm({ ...form, name: value })}
                                    />
                                    <div className="d-flex justify-content-between gap-5">
                                        <div className="w-100">
                                            <Input
                                                inputAttr={{
                                                    type: 'select',
                                                }}
                                                label='Tipo'
                                                options={types}
                                                value={form.type || ''}
                                                change={(value: any) => setForm({ ...form, type: value })}
                                            />
                                        </div>
                                        <div className="w-100">
                                            <Input
                                                inputAttr={{
                                                    type: 'number',
                                                }}
                                                label='Ordem'
                                                value={form.order}
                                                change={(value: any) => setForm({ ...form, order: value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between gap-5">

                                        <div className="d-flex flex-column justify-content-center gap-5 mt-5">
                                            <span>Icone selecionado</span>
                                            {icon &&
                                                <div className="p-8">
                                                    <SVG src={icon} style={{
                                                        width: '45px',
                                                        height: 'auto'
                                                    }} />
                                                </div>

                                            }
                                            {!icon &&
                                                <div className="p-8">
                                                    <img src={`https://s3.amazonaws.com/img.ninegrid.com/${pluginSpace?.subdomain}/aggregator/${form.image}`} style={{
                                                        width: '45px',
                                                        height: 'auto'
                                                    }} />
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div className='w-100 card h-100'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-4 mb-1'>Icones</span>
                                    </h3>
                                </div>
                                <div className="d-flex flex-column overflow-auto  gap-5 p-8" style={{
                                    height: '28rem'
                                }}>
                                    <div>
                                        <Input
                                            inputAttr={{
                                                type: 'select',
                                            }}
                                            label='Categoria do Icone'
                                            options={categoryIcons}
                                            value={categoryIconsSelect || ''}
                                            change={(value: any) => {
                                                setCategoryIconsSelect(value)
                                                changeFilterIcons()
                                            }}
                                        />
                                    </div>

                                    <div className=" d-flex overflow-auto flex-wrap gap-5">
                                        {loadingIcons ? (
                                            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                <Loading />
                                            </div>
                                        ) : (

                                            <>
                                                {
                                                    iconesFiltered.map(icon => (
                                                        <div className="d-flex flex-column p-4"
                                                            onClick={() => {
                                                                setIcon(icon.svg)
                                                            }}
                                                        >
                                                            <span>{icon.description}</span>
                                                            <SVG src={icon.svg} style={{
                                                                width: '45px',
                                                                height: 'auto'
                                                            }} />
                                                        </div>
                                                    ))
                                                }

                                            </>


                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                    <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
                        <Button
                            text='Voltar'
                            color='info'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={() => {
                                Swal.fire({
                                    title: 'Você tem certeza?',
                                    text: 'Os dados que foram digitados serão perdidos.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim, voltar!',
                                    cancelButtonText: 'Não, cancelar!',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate('/segmentos')
                                    }
                                })
                            }}
                            btnClass='fw-bolder me-3'
                        />
                        <Button
                            text='Salvar'
                            color='primary'
                            size='small'
                            horizontalPadding={5}
                            btnAttr={{
                                type: 'button',
                            }}
                            click={create}
                            btnClass='fw-bolder'
                        />
                    </div>
                </div >
            }
        </>


    )
}

const SegmentsEditarAgregador: FC = () => {
    const { id } = useParams()
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: `${i18n.t(`permissions.segments`)}`, path: '/segmentos', isActive: false },
                    { title: `${i18n.t(`general.edit`)} ${i18n.t(`permissions.segments`)}`, path: `/lojas/editar/${id}`, isActive: true },
                ]}
            >
                {`${i18n.t(`general.edit`)} ${i18n.t(`permissions.segments`)}`}
            </PageTitle>
            <SegmentsEditContent />
        </>
    )
}

export { SegmentsEditarAgregador }

*/
