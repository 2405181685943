/* eslint-disable jsx-a11y/anchor-is-valid */
import { Children, FC, Fragment, useEffect, useState } from 'react'
import { i18n } from '../../translate/i18n'
import { toDate, toDateTime, toMoney } from '../../utils/formater'
import { Button } from '../Button/Button'
import { Input } from '../Input/Input'
import { Loading } from '../Loading/Loading'
import { DatatableSub } from './DatatableSub'
import { Dropdown } from 'react-bootstrap'
import SVG from 'react-inlinesvg';
type header = {
  key: string
  value: string
  type: string
  badge?: any
  disable?: boolean
  action?: (param: any) => void,
  size?: string,
  show?: boolean,
  noLimit?: boolean,
  noOrder?: boolean
}

type SubItem = {
  value: string,
  props: Props
  noLimit?: boolean
}


type IndexSubitemType = {
  new: number;
  old?: IndexSubitemType;
}

type DropdownIconType = {
  text: string;
  action: (param: any) => void
}

type option = {
  icon: string
  iconColor?: string
  path?: string
  action?: (param: any) => void
  title?: string
  isButton?: boolean
  textButton?: string
  color?: string
  fieldToShow?: string
  valueToShow?: string
  dropdownItems?: DropdownIconType[]
  verifyConditionItem?: string,
  verifyConditionValue?: any, 
}

type MetaType = {
  hasNextPage: boolean
  hasPreviousPage: boolean
  itemCount: number
  page: number
  pageCount: number
  take: number
}

export type OrderType = {
  field: string;
  type: 'ASC' | 'DESC';
}

type Props = {
  data: any[]
  extendData?: any
  headers: header[]
  options?: option[]
  color?: string | 'primary'
  meta?: MetaType
  search?: boolean
  backgroundRow?: string
  subItems?: any
  onChangeData?: (take: number, page: number, search: string, order?: OrderType) => Promise<void>
}

const Datatable: FC<Props> = (props) => {
  const { data, options, headers, color, meta, onChangeData, search, backgroundRow } = props
  const [stateSort, setStateSort] = useState<OrderType>({field: '', type: 'ASC'})
  const [pagination, setPagination] = useState<any[]>([])
  const [perView, setPerView] = useState<number>((meta && meta.take) || 100)
  const [perViewList, setPerViewList] = useState<object[]>([])
  const [list, setList] = useState<any[]>([])
  const [query, setQuery] = useState<string>('')
  const [indexPagination, setIndexPagination] = useState<number>(-1)
  const [copyData, setCopyData] = useState<any[]>([])
  const [loadingTable, setLoadingTable] = useState(false);

  const prepareList = (size: number) => {
    const numbers = []
    if (size > 0) {
      for (let i = 1; i <= size; i++) {
        numbers.push(i)
      }
    }
    setList(numbers)
  }

  const find = (query: string) => {
    const response: Array<any | null> = []
    data.map((item: object, index: number) => {
      Object.entries(item).map((element) => {
        if (!response.includes(data[index])) {
          if (String(element[1]).toLowerCase() === query.toLowerCase()) {
            response.unshift(data[index]) //Items Iguais!
          } else {
            if (String(element[1]).toLowerCase().includes(query.toLowerCase())) {
              response.push(props.data[index]) //Items semelhantes!
            }
          }
        }
      })
    })
    return response
  }

  const divide = (payload: Array<any> | any, divider: number | any) => {
    const divideArray = [...payload]
    return new Array(Math.ceil(divideArray.length / divider))
      .fill(null)
      .map(() => divideArray.splice(0, divider))
  }

  const sortTable = async (type: 'ASC' | 'DESC', key: string, param: any) => {
    setStateSort({field: key, type})
    const sortData: Array<any> = []
    if(onChangeData){
      setLoadingTable(true)
      await onChangeData(perView, ((meta && meta.page) && parseInt(meta.page.toString()) - 1) || 1, query, {field: key, type})
      setLoadingTable(false)
    } else {
      pagination.map((el: Array<any>) => {
        el.map((item: object) => {
          sortData.push(item)
        })
      })
      if (param === 'number') {
        if (type === 'DESC') {
          sortData.sort((a: any, b: any) => b[key] - a[key])
        } else {
          sortData.sort((a: any, b: any) => a[key] - b[key])
        }
      } else if (param === 'datetime') {
        if (type == "DESC") {
          sortData.sort((a: any, b: any) => {
            const dataA: any = converterParaData(a.date);
            const dataB: any = converterParaData(b.date);
  
            return dataB - dataA;
          });
        } else {
          sortData.sort((a: any, b: any) => {
            const dataA: any = converterParaData(a.date);
            const dataB: any = converterParaData(b.date);
            return dataA - dataB;
          });
        }
      }
      else {
        if (type === 'DESC') {
          sortData.sort((a: any, b: any) => String(b[key]).localeCompare(String(a[key])))
        } else {
          sortData.sort((a: any, b: any) => String(a[key]).localeCompare(String(b[key])))
        }
      }
      const sortDataDivided = divide(sortData, Number(perView))
      setPagination(sortDataDivided)
      setCopyData(sortDataDivided[0])
      setIndexPagination(0)
      setLoadingTable(true)
      setTimeout(() => {
        setLoadingTable(false)
      }, 100)
    }
  }

  const showExtendData = (key: any) => {
    const newCopyData = copyData.map((i) => i);
    newCopyData.map((data: any) => {
      if (data == key) {
        if (!data.extend) data.extend = true;
        else data.extend = false;
      }
    })
    setCopyData(newCopyData)
  }

  function converterParaData(dataStr: any) {

    return new Date(dataStr)
  }

  useEffect(() => {
    setCopyData(data)
    const divideData = divide(data, Number(perView))
    setPagination(divideData)
    setCopyData(divideData[0])
    prepareList((meta && meta.pageCount) || divideData.length)
    if(indexPagination === -1)
      setIndexPagination(((meta && meta.page) && parseInt(meta.page.toString()) - 1) || 0)
    const list = [
      { select: 10, value: 10 },
      { select: 25, value: 25 },
      { select: 50, value: 50 },
      { select: 100, value: 100 }
    ]
    setPerViewList(list)
    setLoadingTable(true)
    setTimeout(() => {
      setLoadingTable(false)
    }, 100)
  }, [data])


  useEffect(() => {
    searchItems()
  }, [query]);

  async function searchItems() {
    if (search) {
      // setLoadingTable(true);
      // onChangeData && await onChangeData(perView, (meta && meta.page - 1) || 1, query)
      // setLoadingTable(false);
    } else {
      let result;

      result = find(query)
      setLoadingTable(true)
      setTimeout(() => {
        setLoadingTable(false)
      }, 100)
      if (result && result.length > 0) {
        const divideData = divide(result, perView)
        setPagination(divideData)
        setCopyData(divideData[0])
        prepareList((query ? divideData.length : meta && meta.pageCount) || 0)
      } else {
        setCopyData([])
        prepareList(0)
      }
    }
  }

  function changeItem(item: any, object: any, value: any) {
    const newData = copyData.map((c) => {
      if (c === item) {
        c[object.value] = value;
      }
      return c
    })
    setCopyData(newData);
  }

  function generateSubitem(subitem: any, indexSubitem: number, textItem: string, childrenPosition: number) {
    textItem += `${indexSubitem + 1}.`
    return (
      <>
        <tr className='flex-grow-1 d-flex justify-content-between' key={textItem}>
          {headers.map((object, indexHeader) => (
            <Fragment key={object.value}>
              {(object.key !== 'Ações' && object.key !== 'Actions' && object.key !== 'Comportamiento') && (
                <td
                  style={indexHeader === 0 ? { paddingLeft: `10px !important`, minWidth: '10em' } : { minWidth: '10em' }}
                  className={`w-25 mx-2 d-flex align-items-center text-break ${indexHeader === 0 ? `px-${4 * childrenPosition}` : 'px-0'}`}
                >
                  {indexHeader === 0 && <span className='text-dark fw-thin me-2'>{textItem.substring(0, textItem.length - 1)}</span>}
                  {object.type === 'default' && (
                    <span className='text-dark fw-thin'>{
                      (subitem[object.value] && subitem[object.value].length) > 30 ?
                        (object.noLimit ? subitem[object.value] : `${subitem[object.value].substr(0, 30)}...`)
                        :
                        subitem[object.value]
                    }</span>
                  )}
                  {object.type === 'html' && (
                    <span className='text-dark fw-thin w-100'>
                      <div dangerouslySetInnerHTML={{ __html: `${subitem[object.value]}` }}>
                      </div>
                    </span>
                  )}
                  {object.type === 'money' && (
                    <span className='text-dark fw-thin'>{toMoney(subitem[object.value])}</span>
                  )}
                  {object.type === 'date' && (
                    <span className='text-dark fw-thin'>{toDate(subitem[object.value])}</span>
                  )}
                  {object.type === 'datetime' && (
                    <span className='text-dark fw-thin'>{subitem[object.value] ? toDateTime(subitem[object.value]) : '-'}</span>
                  )}
                  {object.type === 'image' && (
                    <>
                      {subitem[object.value] ? (
                        <img
                          src={subitem[object.value]}
                          alt=''
                          style={{ objectFit: 'cover' }}
                          className='w-50px h-50px bg-light rounded'
                        />
                      ) : (
                        <img
                          src='https://via.placeholder.com/150x150'
                          alt=''
                          style={{ objectFit: 'cover' }}
                          className='w-50px h-50px bg-light rounded'
                        />
                      )}
                    </>
                  )}
                  {object.type === 'checkBox' && (
                    <Input
                      value={subitem[object.value]}
                      checked={subitem[object.value]}
                      change={() => (object.action ? object.action(subitem) : {})}
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      size={object.size}
                      disabled={object.disable}
                      checkSwift={false}
                    />
                  )}
                  {object.type === 'check' && (
                    <Input
                      value={subitem[object.value]}
                      checked={subitem[object.value]}
                      change={() => (object.action ? object.action(subitem) : {})}
                      inputAttr={{
                        type: 'checkbox',
                      }}
                      size={object.size}
                      disabled={object.disable}
                      checkSwift={true}
                    />
                  )}

                  {object.type === 'bullet' && (
                    <div className='d-flex align-items-center'>
                      <span className='w-5px h-30px rounded bg-secondary me-5'></span>
                      <span className='text-dark fw-thin'>{subitem[object.value]}</span>
                    </div>
                  )}
                  {object.type === 'badge' && object.badge && (
                    <span
                      className={`badge badge-light-${object.badge[subitem[object.value].toLowerCase()]
                        } fs-6 text-capitalize`}
                    >
                      {subitem[object.value]}
                    </span>
                  )}
                </td>
              )}
            </Fragment>
          ))}
          {options && options.length > 0 && (
            <td className='d-flex flex-wrap w-25 mx-2' style={{ minWidth: '10em' }}>
              {options?.map((option, index) => (
                <div
                  className={`d-flex align-items-center me-5 ${option.icon && option.icon.includes('trash')
                    ? 'text-hover-danger'
                    : `${option.icon.includes('eye') ? '' : 'text-hover-primary'}`
                    } cursor-pointer`}
                  key={index}
                >
                  {option.isButton && (
                    <Button
                      text={option.textButton ?? ''}
                      color={option.color ?? 'primary'}
                      icon={option.icon ?? ''}
                      iconColor={option.iconColor ?? ''}
                      size='small'
                      title={option.title}
                      btnAttr={{
                        type: 'button',
                      }}
                      click={() => option.action && option.action(subitem)}
                      btnClass='py-2 px-3'
                    />
                  )}
                  {!option.isButton && option.icon && (
                    <i
                      title={option.title}
                      className={`${option.icon} fs-4`}
                      onClick={() => option.action && option.action(subitem)}
                    ></i>
                  )}
                  {/* {option.icon && option.icon.includes('plus') && (
                    <i
                      title={option.title}
                      className={`${option.icon} fs-4`}
                      onClick={() => option.action && option.action(item)}
                    ></i>
                  )} */}
                </div>
              ))}
            </td>
          )}
        </tr>
        {(subitem.subitems && subitem.subitems.length > 0) &&
          subitem.subitems.map((newSubitem: any, indexSubitemNew: number) => generateSubitem(newSubitem, indexSubitemNew, textItem, childrenPosition + 1))
        }
      </>
    )
  }

  useEffect(() => {
    const divideData = divide(data, Number(perView))
    setPagination(divideData)
    setCopyData(divideData[0])
    // setIndexPagination((meta && meta.page - 1) || 0)
    prepareList((meta && meta.pageCount) || divideData.length)
  }, [perView])

  useEffect(() => {
    if (!meta && indexPagination !== -1) setCopyData(pagination[indexPagination])
  }, [indexPagination])

  return (
    <div>
      <div className='d-flex justify-content-between align-items-start mb-8'>
        <div className='col-1'>
          <Input
            value={perView}
            options={perViewList}
            inputAttr={{
              type: 'select',
              name: 'select',
            }}
            change={async (value: number) => {
              setPerView(value)
              if (onChangeData) {
                setLoadingTable(true)
                await onChangeData(value, 1, query, stateSort)
                setLoadingTable(false)
              } else {
                setLoadingTable(true)
                setTimeout(() => {
                  setLoadingTable(false)
                }, 100)
              }

            }}
          />
        </div>
        <div className='col-3'>
          <Input
            value={query}
            inputAttr={{
              type: 'search',
              name: 'search',
            }}
            change={setQuery}
            blur={async () => {
              if (search) {
                setLoadingTable(true);
                onChangeData && await onChangeData(perView, ((meta && meta.page) && parseInt(meta.page.toString()) - 1) || 1, query, stateSort)
                setLoadingTable(false);
              }
            }}
            onKeyDown={async (value) => {
              if(value === 'Enter'){
                setLoadingTable(true);
                onChangeData && await onChangeData(perView, ((meta && meta.page) && parseInt(meta.page.toString()) - 1) || 1, query, stateSort)
                setLoadingTable(false);
              }
            }}
            placeholder={i18n.t(`buttons.search`)}
          />
        </div>
      </div>
      {/* begin::Table container */}
      {loadingTable ? (
        <Loading />
      ) : (
        <div className='table-responsive'>
          {/* begin::Table */}
          {copyData && data && copyData.length > 0 ? (
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead className='d-flex w-100'>
                <tr className='flex-grow-1 d-flex justify-content-between fw-bolder text-muted'>
                  {headers.map((header) => (
                    <th
                      style={{ minWidth: header.size == 'sm' ? '' : '10em', width: header.size == 'sm' ? '100px' : '', }}
                      className={`${header.size == 'sm' ? '' : 'w-25'} mx-1 text-uppercase cursor-pointer text-hover-primary px-0 d-flex flex-row align-items-center ${header.hasOwnProperty('show') ? (header.show ? '' : 'd-none') : ''}`}
                      key={header.value}
                      onClick={async () => {
                        if (header.key && !header.noOrder) {
                          await sortTable(
                            stateSort.type === 'DESC' ? 'ASC' : 'DESC',
                            header.value,
                            header.type == 'datetime' ? 'datetime' : typeof copyData[0][header.value]
                          )
                        }
                      }
                      }
                    >
                      {(header.key && !header.noOrder) && (<i style={{ marginRight: '.4em' }} className='fas fa-sort'></i>)}
                      {header.key}
                    </th>
                  ))}
                </tr>
              </thead>
              {/* end::Table head */}

              {/* begin::Table body */}
              <tbody className='w-100'>
                {copyData.map((item, indexItem) => (
                  <>
                    <tr
                      className='flex-grow-1 d-flex justify-content-between'
                      key={indexItem}
                      style={(item.subitems) ? { backgroundColor: '#E4EFFB', paddingLeft: 10, paddingRight: 10 } : { backgroundColor: backgroundRow ? backgroundRow : 'transparent' }}
                    >
                      {props.extendData && (
                        <td
                          onClick={() => showExtendData(item)}
                          className='cursor-pointer'
                        >
                          <i
                            style={{ marginRight: '.4em' }}
                            className={item.extend ? 'fas fa-chevron-down' : 'fas fa-chevron-right'}></i>
                        </td>
                      )}

                      {props.subItems && (
                        <td
                          onClick={() => showExtendData(item)}
                          className='cursor-pointer'
                        >
                          <i
                            style={{ marginRight: '.4em' }}
                            className={item.extend ? 'fas fa-chevron-down' : 'fas fa-chevron-right'}></i>
                        </td>
                      )}
                      {headers.map((object, indexHeader) => (
                        <Fragment key={object.value}>
                          {(object.key !== 'Ações' && object.key !== 'Actions' && object.key !== 'Comportamiento') && (
                            <td className={`${(object.size == 'sm') ? '' : object.size ? object.size : 'w-25'} mx-1 d-flex align-items-center text-break px-2 ${object.hasOwnProperty('show') ? (object.show ? '' : 'd-none') : ''}`} style={{ minWidth: object.size == 'sm' ? '' : '10em', width: object.size == 'sm' ? '100px' : '', }}>
                              {(indexHeader === 0 && item.subitems) && <span className='text-dark fw-thin me-2'>{`${indexItem + 1 + (Number(perView) * indexPagination)} `}</span>}
                              {object.type === 'default' && (
                                <span className='text-dark fw-thin'>{
                                  (item[object.value] && item[object.value].length) > 30 ?
                                    (object.noLimit ? item[object.value] : `${item[object.value].substr(0, 30)}...`)
                                    :
                                    item[object.value]
                                }</span>
                              )}
                              {object.type === 'html' && (
                                <span className='text-dark fw-thin w-100'>
                                  <div dangerouslySetInnerHTML={{ __html: `${item[object.value]}` }}>
                                  </div>
                                </span>
                              )}
                              {object.type === 'money' && (
                                <span className='text-dark fw-thin'>{toMoney(item[object.value])}</span>
                              )}
                              {object.type === 'input' && (
                                <Input
                                  inputAttr={{
                                    type: 'default',
                                  }}
                                  disabled={object.disable}
                                  change={(value: string) => {
                                    changeItem(item, object, value);
                                  }}
                                  blur={() => (object.action ? object.action(item) : {})}
                                  value={item[object.value]}
                                />
                              )}
                              {object.type === 'input-number' && (
                                <Input
                                  inputAttr={{
                                    type: 'number',
                                  }}
                                  change={(value: number) => {
                                    changeItem(item, object, value);
                                  }}
                                  blur={() => (object.action ? object.action(item) : {})}
                                  value={item[object.value]}
                                  disabled={object.disable}
                                />
                              )}



                              {object.type === 'input-time' && (
                                <Input
                                  inputAttr={{
                                    type: 'time',
                                  }}
                                  change={(value: number) => {
                                    changeItem(item, object, value);
                                  }}
                                  size={'md'}
                                  blur={() => (object.action ? object.action(item) : {})}
                                  value={item[object.value]}
                                  disabled={object.disable}
                                />
                              )}
                              {object.type === 'input-money' && (
                                <Input
                                  inputAttr={{
                                    type: 'money',
                                  }}
                                  disabled={object.disable}
                                  currency='BRL'
                                  locale='pt-BR'
                                  change={(value: string) => {
                                    changeItem(item, object, value);
                                  }}
                                  blur={() => (object.action ? object.action(item) : {})}
                                  value={item[object.value]}
                                />
                              )}
                              {object.type === 'date' && (
                                <span className='text-dark fw-thin'>{toDate(item[object.value])}</span>
                              )}
                              {object.type === 'datetime' && (
                                <span className='text-dark fw-thin'>{item[object.value] ? toDateTime(item[object.value]) : '-'}</span>
                              )}
                              {object.type === 'image' && (
                                <>
                                  {item[object.value] ? (
                                    <img
                                      src={item[object.value]}
                                      alt=''
                                      style={{ objectFit: 'cover' }}
                                      className='w-50px h-50px bg-light rounded'
                                    />
                                  ) : (
                                    <img
                                      src='https://via.placeholder.com/150x150'
                                      alt=''
                                      style={{ objectFit: 'cover' }}
                                      className='w-50px h-50px bg-light rounded'
                                    />
                                  )}
                                </>
                              )}
                               {object.type === 'icon' && (
                                <>
                                  {item[object.value] ? (
                                   <SVG src={item[object.value]} style={{
                                    width: '50px',
                                    height: '50px'
                                  }} />
                                  ) : (
                                    <img
                                      src='https://via.placeholder.com/150x150'
                                      alt=''
                                      style={{ objectFit: 'cover' }}
                                      className='w-50px h-50px bg-light rounded'
                                    />
                                  )}
                                </>
                              )}
                              {object.type === 'check' && (
                                <Input
                                  value={item[object.value]}
                                  checked={item[object.value]}
                                  change={() => (object.action ? object.action(item) : {})}
                                  inputAttr={{
                                    type: 'checkbox',
                                  }}
                                  checkSwift={true}
                                  size={object.size}
                                  disabled={object.disable}
                                />
                              )}

                              {object.type === 'checkBox' && (
                                <div className='w-100 d-flex justify-content-start'>
                                  <Input
                                    value={item[object.value]}
                                    checked={item[object.value]}
                                    change={() => (object.action ? object.action(item) : {})}
                                    inputAttr={{
                                      type: 'checkbox',
                                    }}
                                    checkSwift={false}
                                    size={object.size}
                                    disabled={object.disable}
                                  />
                                </div>
                              )}
                              {object.type === 'bullet' && (
                                <div className='d-flex align-items-center'>
                                  <span className='w-5px h-30px rounded bg-secondary me-5'></span>
                                  <span className='text-dark fw-thin'>{item[object.value]}</span>
                                </div>
                              )}
                              {object.type === 'badge' && object.badge && (
                                <span
                                  className={`badge badge-light-${object.badge[item[object.value].toLowerCase()]
                                    } fs-6 text-capitalize`}
                                >
                                  {item[object.value]}
                                </span>
                              )}
                            </td>
                          )}
                        </Fragment>
                      ))}
                      {options && options.length > 0 && (
                        <td className='d-flex flex-wrap w-25 mx-1' style={{ minWidth: '10em' }}>
                          {options
                          .filter((option) => {
                            
                            if(!option.verifyConditionItem || !option.verifyConditionValue){
                              return true
                            }
                            else if (item[option.verifyConditionItem] === option.verifyConditionValue){
                              return true
                            } else {
                              return false
                            }
                          })
                          .map((option, index) => (
                            <div
                              className={`d-flex align-items-center me-5 ${option.icon && option.icon.includes('trash')
                                ? 'text-hover-danger'
                                : `${option.icon.includes('eye') ? '' : 'text-hover-primary'}`
                                } cursor-pointer`}
                              key={index}
                            >
                              {(option.dropdownItems && option.icon) && (
                                <Dropdown
                                  style={{padding: 0, margin: 0}}
                                >
                                  <Dropdown.Toggle variant='default' size='sm' style={{padding: 0, margin: 0}}>
                                    <i
                                      title={option.title}
                                      className={`${option.icon} fs-4`}
                                    ></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className='p-0'>
                                      {option.dropdownItems.map((dropdownItem) => (
                                        <Dropdown.Item
                                          className='p-0 d-flex flex-row justify-content-center'
                                          onClick={() => dropdownItem.action(item)}
                                        >
                                          <div
                                            className='btn m-4 d-flex flex-row justify-content-center align-items-center'
                                            style={{color: '#6993FF', backgroundColor: '#E1E9FF', width: '90%'}}
                                          >                                            
                                            <p style={{color: '#6993FF', fontSize: '1rem'}} className='m-0'>
                                              {dropdownItem.text}
                                            </p>
                                          </div>
                                        </Dropdown.Item>
                                      ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                              {option.isButton && (
                                <Button
                                  text={option.textButton ?? ''}
                                  color={option.color ?? 'primary'}
                                  icon={option.icon ?? ''}
                                  iconColor={option.iconColor ?? ''}
                                  size='small'
                                  title={option.title}
                                  btnAttr={{
                                    type: 'button',
                                  }}
                                  click={() => option.action && option.action(item)}
                                  btnClass='py-2 px-3'
                                />
                              )}
                              {!option.isButton && !option.dropdownItems && option.icon && !option.fieldToShow && !option.valueToShow && (
                                <i
                                  title={option.title}
                                  className={`${option.icon} fs-4`}
                                  onClick={() => option.action && option.action(item)}
                                ></i>
                              )}
                              {/* {option.icon && option.icon.includes('plus') && (
                              <i
                                title={option.title}
                                className={`${option.icon} fs-4`}
                                onClick={() => option.action && option.action(item)}
                              ></i>
                            )} */}
                              {option.fieldToShow && option.valueToShow && (item[option.fieldToShow] === option.valueToShow) && (
                                <i
                                  title={option.title}
                                  className={`${option.icon} fs-4`}
                                  onClick={() => option.action && option.action(item)}
                                ></i>
                              )

                              }
                            </div>
                          ))}
                        </td>
                      )}
                    </tr>
                    {item.subitems && item.subitems.map((subitem: any, indexSubitem: number) => generateSubitem(subitem, indexSubitem, `${indexItem + 1 + (Number(perView) * indexPagination)}.`, 1))}
                    <tr >{props.extendData && item.extend && item[props.extendData.value] &&
                      <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3 w-25' style={{ minWidth: '10em' }}>
                        <thead className='d-flex w-100'>
                          <tr className='flex-grow-1 d-flex justify-content-between fw-bolder text-muted'>
                            {props.extendData.header.map((header: string, index: number) => (
                              <th
                                className={`mx-0 text-uppercase text-dark px-0`}
                                key={index}
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className='w-100'>
                          {item[props.extendData.value] && item[props.extendData.value].map((value: any, index: number) => {
                            return (
                              <tr className='flex-grow-1 d-flex justify-content-between' key={index}>
                                {Object.entries(value).map((objectExtend: any, indexObj: number) => (
                                  <td className='w-25 mx-2 d-flex align-items-center text-break px-0' key={`obj-${indexObj}`} style={{ minWidth: '10em' }}>
                                    <span className='text-dark fw-thin'>{objectExtend[1]}</span>
                                  </td>
                                ))}
                              </tr>)
                          })}
                        </tbody>
                      </table>
                    }</tr>
                    <tr>{props.subItems && item.extend && item[props.subItems.value] &&


                      <DatatableSub
                        headers={props.subItems.headers}
                        data={item[props.subItems.value]}
                        options={props.subItems.options}
                      />


                    }</tr>
                  </>
                ))}
              </tbody>
              {/* end::Table body */}
            </table>
          ) : (
            <div className='bg-gray-100 text-center fs-2 py-3 flex justify-center items-center opacity-25'>
              Nenhum item encontrado... <i className='fas fa-ghost ms-3 fs-1'></i>
            </div>
          )}

          {copyData && data && copyData.length > 0 && (
            <div className='py-3 d-flex justify-content-between text-xs h-100 w-100 text-gray-500 text-uppercase'>
              <span className='d-flex justify-content-between align-items-center col-6'>
                {i18n.t(`general.showing`)} {i18n.t(`general.of`)} {indexPagination === 0 ? 1 : indexPagination * perView} {i18n.t(`general.to`)}
                {` ${(indexPagination * Number(perView) + copyData.length)} `}
                {i18n.t(`general.of`)}
                {meta ? ` ${meta.itemCount} ` : data ? ` ${data.length} ` : ` ${0} `} {i18n.t(`general.records`)}
              </span>

              <span className='d-flex col-4 justify-content-end'>
                <nav aria-label='Table navigation'>
                  <ul className='d-flex inline-flex align-items-center m-0 list-unstyled'>
                    <li>
                      {((meta && meta.hasPreviousPage) || indexPagination > 0) && (
                        <button
                          className={`px-3 py-1 rounded border-0 bg-transparent text-hover-${color}`}
                          aria-label='Previous'
                          onClick={async () => {
                            setIndexPagination(indexPagination - 1)
                            if (onChangeData) {
                              setLoadingTable(true)
                              await onChangeData(perView, ((meta && meta.page) && parseInt(meta.page.toString()) - 1) || 1, query, stateSort)
                              setLoadingTable(false)
                            } else {
                              setLoadingTable(true)
                              setTimeout(() => {
                                setLoadingTable(false)
                              }, 100)
                            }
                          }}
                        >
                          <i className='fas fa-chevron-left ms-3'></i>
                        </button>
                      )}
                    </li>

                    {list.map((position: number) => (
                      <Fragment key={position}>
                        {position - 1 == indexPagination ? (
                          <button
                            className={`px-3 py-1 text-white transition-colors duration-150 bg-${color} border-0 rounded`}
                          >
                            {position}
                          </button>
                        ) : (
                          <>
                            {position - 1 <= indexPagination + 3 && position > indexPagination - 1 && (
                              <button
                                onClick={async () => {
                                  setIndexPagination(position - 1)
                                  if (onChangeData) {
                                    setLoadingTable(true)
                                    await onChangeData(perView, position, query, stateSort)
                                    setLoadingTable(false)
                                  } else {
                                    setLoadingTable(true)
                                    setTimeout(() => {
                                      setLoadingTable(false)
                                    }, 100)
                                  }
                                }}
                                className={`px-3 py-1 rounded border-0 bg-transparent`}
                              >
                                {position}
                              </button>
                            )}
                          </>
                        )}
                      </Fragment>
                    ))}

                    <li>
                      {((meta && meta.hasNextPage) || indexPagination < pagination.length - 1) && (
                        <button
                          className={`px-3 py-1 rounded border-0 bg-transparent text-hover-${color}`}
                          aria-label='Next'
                          onClick={async () => {
                            setIndexPagination(indexPagination + 1)
                            if (onChangeData) {
                              setLoadingTable(true)
                              await onChangeData(perView, ((meta && meta.page) && parseInt(meta.page.toString()) + 1) || 1, query, stateSort)
                              setLoadingTable(false)
                            } else {
                              setLoadingTable(true)
                              setTimeout(() => {
                                setLoadingTable(false)
                              }, 100)
                            }
                          }}
                        >
                          <i className='fas fa-chevron-right ms-3'></i>
                        </button>
                      )}
                    </li>
                  </ul>
                </nav>
              </span>
            </div>
          )}
          {/* end::Table */}
        </div>)}
      {/* end::Table container */}
    </div>
  )
}

export { Datatable }
export type { MetaType }
