import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {Loading} from '../../../../components/Loading/Loading'
import {GET, POST, PUT} from '../../../../services/api'
import {i18n} from '../../../../translate/i18n'
import {getPlugintypeIdByCode} from '../../../../functions/plugintype'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

const Content: FC = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [listClassification, setListClassification] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [categoryId, setCategoryId] = useState<any>('')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [order, setOrder] = useState<string>('0')
  const [status, setStatus] = useState<string>('true')

  const [image, setImage] = useState<File | null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | null>(null)
  const [disableFieldsTotvs, setDisableFieldTotvs] = useState(false)

  const getCategories = async (loading = true) => {
    try {
      setLoading(true)
      const res = await POST(`/classification/filter`, {
        id: id,
        plugintypeId: getPlugintypeIdByCode('service'),
      })
      console.log(res, id)
      res.data.map((row: ReturnType) => {
        setName(row.name)
        setCode(row.code)
      })

      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const edit = async () => {
    try {
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)

        const res = await PUT(`/classification/${id}`, {
          name,
          shortname: name,
          order: 0,
          code,
        })

        setLoading(false)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Categoria atualizada',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate('/hospitality/service-category'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao atualizar a categoria!',
          })
        }
        setLoading(false)
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ?? 'Erro ao atualizar a categoria!',
      })
    }
  }

  useEffect(() => {
    getCategories()
  }, [])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(
                  `general.edit`
                )} ${i18n.t(`smart_order.category`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body '>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.name`)}
                        label={i18n.t(`smart_order.name`)}
                        value={name}
                        change={(value: string) => setName(value)}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.code`)}
                        label={i18n.t(`smart_order.code`)}
                        value={code}
                        change={(value: string) => {
                          if (value.match('^[a-zA-Z0-9_]*$') != null) {
                            setCode(value)
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => edit()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const EditarCategorias: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`smart_order.categories`), path: 'service-category', isActive: true},
          {title: i18n.t(`general.edit`), path: 'service-category/editar', isActive: true},
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.category`)}`}
      </PageTitle>
      <Content />
    </>
  )
}

export {EditarCategorias}
