export function priceToFloat(price: string){
    return parseFloat((price.substring(3, price.length)).replace(",", ".")) * 10;
}

export function floatToPrice(value: number) {
    return "R$ " + value.toFixed(2).toString().replace(".", ",");
}

export function centsToFloat(value: number){
    if(value){
        const stringValue = value.toString();
        if(stringValue.includes('.')){
            return value/100
        } else {
            return parseFloat(stringValue.substring(0, stringValue.length-2) + '.' + stringValue.substring(stringValue.length-2, stringValue.length))
        }
    }
    return 0;
}