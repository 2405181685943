import { FC, useEffect, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import { Datatable } from "../../../components/Datatable/Datatable"
import { Button } from "../../../components/Button/Button"
import Swal from "sweetalert2"
import { GET, POST, DELETE, PUT } from "../../../services/api"
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"
import { LittleButton } from "../../../components/Button/LittleButton"
import { Input } from "../../../components/Input/Input"
import { getPlugintypeIdByCode } from "../../../functions/plugintype"
import useAuth from "../../../hooks/useAuth"
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import useModals from "../../../hooks/useModals"


type store = {
    id: number;
    pluginspaceId: number;
    aggregatorId: number;
    classificationId: number;
    order: number;
    name: string;
    description: string;
    image: string;
    link: string;
    type: string;
    active: boolean;
    exclusive: boolean;
    exclusivo?: string;
    instagram?: any;
    phoneWhatsapp: string;
    latitude: string;
    longitude: string;
    zipCode: number;
    created: string;
    updated: string;
    status?: string
    subdomain?: string
}

type LojasSegmentadas = {
    name: string,
    image: string
    qtd: string

}

const LojasContent: FC = () => {
    const navigate = useNavigate()
    const [data, setData] = useState<store[]>([])
    const [allStores, setAllStores] = useState<store[]>([])
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState<any[]>([])
    const [subCategories, setSubCategories] = useState<any[]>([])
    const [allCategories, setAllCategories] = useState<any[]>([])
    const { loginInfo } = useAuth();

    const [categorySelected, setCategorySelected] = useState<any>()
    const [subCategorySelected, setSubCategorySelected] = useState<any>()


    const { showDeleteModal, showWrapperModal } = useModals()
    const [id, setId] = useState<number | null>(null)





    async function getCategories() {
        try {
            const res = await POST(`/classification/filter`, {
                plugintypeId: getPlugintypeIdByCode('service'),
                deleted: 'not show',
            })

            const segmentosPluginSpace = await GET('pluginspacesegment');

            let newSegmento = segmentosPluginSpace.data.filter((segment: any) => {
                if (segment.pluginspaceId === loginInfo.pluginspaceId) {
                    return segment;
                }
            })

            newSegmento = newSegmento.map((segment: any) => {
                return segment.id.toString()
            })

            const newCategories = res.data .filter((category: any) => category.parent === null);
               

            const optionsCategory = [{ select: 'Todas', value: -1 }]

            newCategories.map((el: any) => {
                optionsCategory.push({
                    select: el.shortname,
                    value: el.code
                })
            })

            setAllCategories(res.data)

            setCategories(optionsCategory)
            setSubCategories([{ select: 'Selecione', value: -1 }])

            return res.data

        } catch (err) {
            console.log(err)
        }
    }


    async function getData() {
        try {
            const categories = await getCategories()
            const res = await GET('pluginspaceaggregator')
            if (res.success) {
                const data: store[] = res.data

                // Erro de parent

                data.map((store: any) => {
                    const subCategorie = categories.find((categoria: any) => categoria.id == store.classificationId)
                    const categorie = categories.find((categoria: any) => categoria.code == subCategorie.parent)

                    store.subCategoria = subCategorie.shortname;
                    store.categoria = categorie.shortname;

                    store.status = store.active ? 'Ativo' : 'Desativado'
                    store.exclusivo = store.exclusive ? 'Interno' : 'Externo'
                })

                setData(data)
                setAllStores(data)

                return
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao recuperar lojas',
            })
            console.log(error)
        }
    }

    async function getAll() {
        try {
            setLoading(true)

            await Promise.all([
                getData(),
            ])

            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        getAll()
    }, [])


    function changeSubcategories() {
        try {
            if (categorySelected) {
                const options: any[] = [{ select: 'Selecione', value: -1 }]

                allCategories.filter(el => el.parent === categorySelected).map(el => {
                    options.push({
                        select: el.shortname,
                        value: el.id
                    })
                })


                setSubCategories(options)

            } else {
                setSubCategories([{ select: 'Selecione', value: -1 }])
            }

            setSubCategorySelected(null)
        } catch (error) {
            console.log(error)
        }
    }


    function filterStores() {
        try {
            if(!categorySelected ){
                Swal.fire({
                    icon: 'warning',
                    title: 'Atenção',
                    text: 'Selecione uma Categoria para realizar o filtro'
                })
            }else if (categorySelected === -1 || categorySelected === '-1'){
                setData(allStores)
            }else if (!subCategorySelected || subCategorySelected === -1 || subCategorySelected === '-1' ) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Atenção',
                    text: 'Selecione uma Subcategoria para realizar o filtro'
                })

                setData(allStores)
            } else {
                setData(allStores.filter(el => el.classificationId == subCategorySelected))

            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        changeSubcategories()
    }, [categorySelected])


    const deleteProduct = async () => {
        try {
            setLoading(true)
            const deleteStoreRes = await DELETE(`/pluginspaceaggregator/${id}`)
            if (deleteStoreRes.success === true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Loja deletado',
                    text: 'Deleção realizada com sucesso!',
                }).then(async () => await getData())
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao deletar o loja!',
                }).then(async () => await getData())
            }
            setLoading(false)
        } catch (e) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao deletar o loja!',
            }).then(async () => await getData())
            console.log(e)
        }
    }

    const switchStatus = async (Id: number, status: boolean) => {
        setLoading(true)
        try {
            const switchStatusStore = await PUT(`/pluginspaceaggregator/${Id}`, {
                active: !status,
            })
            await getData();

            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }



    return (<>
        {loading ? (
            <Loading />
        ) : (
            <div className='row g-5 gx-xxl-12'>
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                    <div className='card-header py-5 d-flex flex-column'>
                        <div className="d-flex justify-content-between">
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>Lojas</span>
                                <BreadCrumbs />
                                <span className='text-muted mt-1 fw-bold fs-7'>
                                    Aqui estão listadas as lojas do agregador juntamente com suas respectivas ações.
                                </span>
                            </h3>


                            <div className='card-toolbar'>

                                <Button
                                    text='Cadastrar Lojas no Agregador'
                                    color='primary'
                                    iconColor='#FFF'
                                    icon='fas fa-plus'
                                    size='small'
                                    horizontalPadding={6}
                                    btnAttr={{
                                        type: 'button',
                                    }}
                                    click={() => navigate('/stores/criar')}
                                    btnClass='mb-5 fw-bolder'
                                />

                            </div>
                        </div>

                        <div className="d-flex gap-5 align-items-end h-100" style={{ marginTop: '39px', marginBottom: '49px' }}>
                            <div style={{ width: '200px' }}>
                                <Input
                                    inputAttr={{
                                        type: 'select',
                                    }}
                                    label={'Categoria'}
                                    options={categories}
                                    value={categorySelected}
                                    change={(value: any) => {
                                        setCategorySelected(value)
                                    }}
                                />
                            </div>
                            <div style={{ width: '200px' }}>
                                <Input
                                    inputAttr={{
                                        type: 'select',
                                    }}
                                    label={'Subcategoria'}
                                    options={subCategories}
                                    value={subCategorySelected}
                                    change={(value: any) => {
                                        setSubCategorySelected(value)
                                    }}
                                />
                            </div>

                            <div className="d-flex" style={{ height: '38px' }}>

                                <LittleButton
                                    text='Filtrar'
                                    color='primary'
                                    iconColor='#FFF'
                                    width="110px"
                                    height="32px"
                                    horizontalPadding={6}
                                    btnAttr={{
                                        type: 'button',
                                    }}
                                    click={() => filterStores()}
                                    btnClass='mb-5 fw-bolder'
                                />
                            </div>
                        </div>

                    </div>
                    <div className='card-body'>
                        <Datatable
                            data={data}
                            headers={
                                [
                                    { key: 'Loja', value: 'image', type: 'image' },
                                    { key: 'Ordem', value: 'order', type: 'default' },
                                    { key: 'Categoria', value: 'categoria', type: 'default' },
                                    { key: 'SubCategoria', value: 'subCategoria', type: 'default' },
                                    { key: 'Nome', value: 'name', type: 'default' },
                                    { key: 'Tipo', value: 'exclusivo', type: 'default' },
                                    { key: 'Descriçao', value: 'description', type: 'default' },

                                    { key: 'Status', value: 'active', type: 'check', action: (item) => switchStatus(item.id, item.active), },
                                    { key: 'Ações', value: 'acoes', type: '' },
                                ]
                            }
                            options={
                                [
                                    {
                                        icon: 'fas fa-pen',
                                        action: (item) => {
                                            navigate(`/stores/editar/${item.id}`)
                                        },
                                        title: 'Editar',
                                    },
                                    {
                                        icon: 'fas fa-trash',
                                        action: (item) => {
                                            showDeleteModal('Excluir Loja')
                                            setId(item.id)
                                        },
                                        title: 'Deletar',
                                    },

                                ]
                            }
                            color='primary'
                        />
                    </div>
                </div >
            </div >
        )}

        <ModalDelete confirmAction={() => deleteProduct()}
            text='delete_item'
        />
    </>)
}

const LojasAgregador: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: `${i18n.t(`permissions.stores`)}`, path: '/lojas', isActive: false },
                ]}
            >
                {i18n.t(`permissions.stores`)}
            </PageTitle>
            <LojasContent />
        </>
    )
}

export { LojasAgregador }
