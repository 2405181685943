import { BaseSyntheticEvent, FC, useEffect, useState } from "react"
import { Input } from "../Input/Input"
import { Button } from "../Button/Button"
import { OpenConver, appConfig } from "../../pages/Agregador/webApp/types/webAppAgregador.types"
import { POST } from "../../services/api"
import uuid from "react-uuid"


type Props = {
  config: appConfig | undefined,
  openCover: OpenConver | undefined
  save: () => Promise<void>
  setConfig: (value: any) => any
  setLoading: (value: any) => any
  nextPage: () => void
  previousPage: () => void
}




const CapaAbertura: FC<Props> = ({ config, openCover, save, setConfig, setLoading, nextPage, previousPage }) => {
  const [typeLogo, setTypeLogo] = useState(openCover?.aggregatorLogo.type ? openCover?.aggregatorLogo.type : 'white')
  // colors
  const [backgroundButton, SetBackgroundButton] = useState(openCover?.backgroundButton ? openCover?.backgroundButton : '')
  const [colorTextButton, SetColorTextButton] = useState(openCover?.colorTextButton ? openCover?.colorTextButton : '')
  const [colorBallCover, SetColorBallCover] = useState(openCover?.colorBallCover ? openCover?.colorBallCover : '')

  //shadow
  const [shadow, SetShadow] = useState(openCover?.shadow ? openCover?.shadow : false)
  //logoNinegrid
  const [logoNinegrid, SetLogoNinegrid] = useState(openCover?.ninegridLogo ? openCover?.ninegridLogo : '')
  //Pelicula
  const [film, SetFilm] = useState(openCover?.film ? openCover?.film : false)
  const [filmValue, SetFilmValue] = useState(openCover?.filmValue ? openCover?.filmValue : 0)

  //imagem da capa
  const [image, setImage] = useState<File | null | string>()
  const [firstStateimage, setFirstStateImage] = useState<null | string>()

  //logoMarca Branca
  const [whiteImage, setWhiteImage] = useState<File | null | string>()
  const [firstStateWhiteImage, setFirstStateWhiteImage] = useState<null | string>()
  //logoMarca Preta
  const [blackImage, setBlackImage] = useState<File | null | string>()
  const [firstStateBlackImage, setFirstStateBlackImage] = useState<null | string>()

  // tipo de transição de capa
  const [typeTransition, setTypeTransition] = useState<string>()
  // tempo de transição de capa
  const [timeTransition, setTimeTransition] = useState<number>()

  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }

  async function saveImage(image1: any, imageBk: any) {
    try {
      if (image1 && image1 !== imageBk) {
        if (firstStateimage) {
          deleteImage(firstStateimage)
        }
        const formData = new FormData()
        formData.append('file', image1)
        formData.append('nameImage', `appconfig-logo-${uuid()}.jpg`)
        formData.append('originalSize', 'true')
        const imageRes = await POST('/upload-images/upload-one', formData)
        return imageRes.success ? imageRes.image : ''
      }
      return image1
    } catch (error) {
      return ''
    }
  }

  function saveImageWithoutSendS3(image1: any, imageBk: any) {
    if (image1 && image1 !== imageBk) {
      return image1
    }
    return imageBk
  }

  async function saveParcial() {
    try {
      if (config) {
        const imageLink = await saveImageWithoutSendS3(image, firstStateimage)
        const whiteImageLink = await saveImageWithoutSendS3(whiteImage, firstStateWhiteImage)
        const blackImageLink = await saveImageWithoutSendS3(blackImage, firstStateBlackImage)

        config.content.openCover = {
          backgroundButton: backgroundButton,
          colorTextButton: colorTextButton,
          colorBallCover: colorBallCover,
          shadow: shadow,
          ninegridLogo: logoNinegrid,
          image: imageLink,
          imageFirstState: firstStateimage,
          film: film,
          filmValue: filmValue,
          aggregatorLogo: {
            white: whiteImageLink,
            whiteFistState: firstStateWhiteImage,
            black: blackImageLink,
            blackFistState: firstStateBlackImage,
            type: typeLogo
          },
          timeTransition: timeTransition ? timeTransition : 1000,
          typeTransition: typeTransition ? typeTransition : 'coverflow'
        }


        setConfig(config)

        nextPage();
      }

    } catch (error) {
      console.log(error)
    }
  }

  function openImages() {
    if (openCover && openCover.image) {
      const urlImage = openCover.image.substring(0, 4) === 'http' ?
        openCover.image : openCover.image ? process.env.REACT_APP_AWS_S3_URL + openCover.image : null

      setImage(urlImage)
      setFirstStateImage(urlImage)
    }

    if (openCover && openCover.aggregatorLogo && openCover.aggregatorLogo.white) {
      const urlImageWhite = openCover.aggregatorLogo.white.substring(0, 4) === 'http' ?
        openCover.aggregatorLogo.white : openCover.aggregatorLogo.white ? process.env.REACT_APP_AWS_S3_URL + openCover.aggregatorLogo.white : null

      setWhiteImage(urlImageWhite)
      setFirstStateWhiteImage(urlImageWhite)
    }

    if (openCover && openCover.aggregatorLogo && openCover.aggregatorLogo.black) {
      const urlImageBlack = openCover.aggregatorLogo.black.substring(0, 4) === 'http' ?
        openCover.aggregatorLogo.black : openCover.aggregatorLogo.black ? process.env.REACT_APP_AWS_S3_URL + openCover.aggregatorLogo.black : null
      setBlackImage(urlImageBlack)
      setFirstStateBlackImage(urlImageBlack)
    }
  }

  function loadTypeTransition(){
    try {
        if(config){

          if(config.content.openCover){
            const cover = {... config.content.openCover}
            setTimeTransition(cover.timeTransition ? cover.timeTransition : 1000)
            setTypeTransition(cover.typeTransition ? cover.typeTransition : 'coverflow')
          }         

        }


    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    openImages()
    loadTypeTransition()
  }, [])


  return (
    <div className='card' style={{ marginTop: '-1rem' }}>
      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold mb-1' style={{
            fontSize: '14px',
            lineHeight: "20px",
            color: '#677484'
          }}>Capa de Abertura</span>
          <span
            style={{
              fontSize: '11px',
              lineHeight: "20px",
              color: '#677484'
            }}
          >Aqui você configura a capa principal de abertura do seu agregador </span>
        </h3>
      </div>
      <div className='card-body'>

        <div className="row gap-6">
          <div className="col border-end" >
            <div className="d-flex flex-column gap-5 justify-content-center">
              <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Logo marca do agregador</h4>

              <div className="d-flex gap-3">
                <div className="mt-6 ">
                  <Input
                    inputAttr={{
                      type: 'newRadio',
                    }}
                    value={'white'}
                    checked={typeLogo === 'white'}
                    change={(value: boolean) => {
                      if (value) {
                        setTypeLogo('white')

                      }

                    }}
                  />
                </div>
                <Input
                  inputAttr={{
                    type: 'image',
                  }}
                  width="150"
                  height="150"
                  label={'Versão Branco'}
                  value={whiteImage}
                  imagemBackgroundDefault={'200x200'}
                  change={(value: BaseSyntheticEvent) => {
                    setWhiteImage(value ? value.target.files[0] : null)
                  }}

                />
              </div>
              <div className="d-flex gap-3">
                <div className="mt-6">
                  <Input
                    inputAttr={{
                      type: 'newRadio',
                    }}
                    value={'black'}
                    checked={typeLogo === 'black'}
                    change={(value: any) => {
                      if (value) {
                        setTypeLogo('black')
                      }
                    }}
                  />
                </div>
                <Input
                  inputAttr={{
                    type: 'image',
                  }}
                  width="150"
                  height="150"
                  imagemBackgroundDefault={'200x200'}
                  label={'Versão Preto'}
                  value={blackImage}
                  change={(value: BaseSyntheticEvent) => {
                    setBlackImage(value ? value.target.files[0] : null)
                  }}
                />
              </div>

              <div className="row">
                <div className="col d-flex align-items-center w-100 justify-content-center">

                  <span style={{
                    fontSize: '9px',
                    width: '150px',
                    lineHeight: '9px'
                  }} >
                    Posicionado no centro da tela – deve ser um png com fundo transparente 200x200.
                  </span>
                </div>
                <div className="col-5">

                </div>

              </div>
            </div>
          </div>
          <div className="col border-end " >
            <div className="d-flex flex-column" style={{ gap: '11px' }}>
              <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Transição  entre Capas </h4>
              <div className="d-flex flex-column mb-4" >

                <Input
                  inputAttr={{
                    type: 'number-agregador'
                  }}
                  width="90"
                  vertical={true}
                  label={'Tempo de transição'}
                  value={timeTransition}
                  minNumber={1000}
                  change={(value: any) => {
                    setTimeTransition(value)
                  }}
                />

               {/*
               
                <Input
                  inputAttr={{
                    type: 'select_small'
                  }}
                  vertical={true}
                  label={'Tipo de transição'}
                  value={typeTransition}
                  options={[
                    {
                      select:'Deslizar',
                      value:'coverflow'
                    },
                    {
                      select:'Girar',
                      value:'flip'
                    },
                    {
                      select:'Cubo',
                      value:'cube'
                    },
                  ]}
                  change={(value: any) => {
                    setTypeTransition(value)
                  }}
                />
               
               */

               }
              </div>
            </div>


            <div className="d-flex flex-column" style={{ gap: '11px' }}>
              <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Cor do botão de entrada </h4>
              <div className="d-flex flex-column" style={{ gap: '15px' }}>

                <Input
                  inputAttr={{
                    type: 'smallColor'
                  }}
                  label={'Fundo do botão de entrar'}
                  value={backgroundButton}
                  change={(value: any) => {
                    SetBackgroundButton(value)
                  }}
                />

                <Input
                  inputAttr={{
                    type: 'smallColor'
                  }}
                  label={'Texto do botão de entrar'}
                  value={colorTextButton}
                  change={(value: any) => {
                    SetColorTextButton(value)
                  }}
                />

                <Input
                  inputAttr={{
                    type: 'checkbox',
                  }}
                  label={'Sombra no botão de entrar'}
                  value={shadow}
                  checked={shadow}
                  change={(value: any) => {
                    SetShadow(value)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col border-end" >
            <div className="d-flex flex-column" style={{ gap: '11px' }}>
              <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Cor do Marcador</h4>
              <div className="d-flex flex-column" style={{ gap: '16px' }}>
                <Input
                  inputAttr={{
                    type: 'smallColor'
                  }}
                  label={'Cor do marcado da capa'}
                  value={colorBallCover}
                  change={(value: any) => {
                    SetColorBallCover(value)
                  }}
                />
                <div>
                  <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Cor da logo do Ninegrid</h4>

                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={''}
                    value={logoNinegrid}
                    options={[
                      { select: 'Selecione uma cor da logo', value: '' },
                      { select: 'Claro', value: 'white' },
                      { select: 'Escuro', value: 'black' },
                    ]}
                    change={(value: any) => {
                      SetLogoNinegrid(value)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col " >
            <div className="d-flex flex-column gap-4 h-100">
              <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Imagem - Capa de Abertura</h4>
              <div className="d-flex gap-4 align-items-center flex-column flex-exxl-row">
                <Input
                  inputAttr={{
                    type: 'image',
                  }}
                  imagemBackgroundDefault={'360x640'}
                  height={'350'}
                  width={'150'}
                  label={''}
                  value={image}
                  change={(value: BaseSyntheticEvent) => {
                    setImage(value ? value.target.files[0] : null)
                  }}
                />
                <div className="h-75 d-flex flex-column gap-4 justify-content-start ">
                  <div>
                    <span className="form-check" style={{ fontSize: '10px' }}>Para obter melhor resultados a
                      imagem deve ter
                      entre <b>100 e 600 kb</b> tamanho <b>340 x 640 </b>px
                    </span>
                  </div>

                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    label={'Pelicula'}
                    value={film}
                    change={(value: any) => {
                      SetFilm(value)
                    }}
                    checked={film}
                  />

                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    label={''}
                    value={filmValue}
                    change={(value: any) => {
                      SetFilmValue(value)
                    }}
                  />

                </div>
              </div>

              <div className="d-flex gap-4 h-100 align-items-end">
                <div className="d-flex w-100" style={{ gap: '20px' }}>
                  <Button text={"voltar"} color={"info"} click={() => previousPage()} btnClass="w-100" height="32px" />
                  <Button text={"Próximo"} color={"primary"} click={() => saveParcial()} btnClass="w-100" height="32px" />
                </div>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  )
}

export { CapaAbertura }