import { BaseSyntheticEvent, FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../../_metronic/layout/core'
import { Button } from '../../../../../components/Button/Button'
import { Input } from '../../../../../components/Input/Input'
import { Loading } from '../../../../../components/Loading/Loading'
import { POST } from '../../../../../services/api'
import { i18n } from '../../../../../translate/i18n'
import { BreadCrumbs } from '../../../../../components/BreadCrumbs/breadCrumbrs'

const CriarCepContent: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [zipStart, setZipStart] = useState('');
  const [zipEnd, setZipEnd] = useState('');
  const [price, setPrice] = useState(0);

  const createZip = async () => {
    try {
      if (!zipStart || !zipEnd  || zipStart.includes('_') || zipEnd.includes('_')) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const cepRes = await POST('/zip-code', {
          zipStart: parseInt(zipStart.replace("-", "")),
          zipEnd: parseInt(zipEnd.replace("-", "")),
          price: price,
          negativeRange: false
        })
        setLoading(false);
        if (cepRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'CEP cadastrado',
            text: 'Cadastro realizado com sucesso!'
          }).then(() => navigate('/configuracoes/taxa-entrega/cep'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar CEP!'
          })
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar CEP!'
      })
    }
  }

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`delivery_fee.create_cep`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`delivery_fee.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'cep',
                    }}
                    placeholder={i18n.t(`delivery_fee.cep_start`)}
                    label={i18n.t(`delivery_fee.cep_start`)}
                    change={(value: string) => setZipStart(value)}
                    value={zipStart}
                  />
                </div>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'cep',
                    }}
                    placeholder={i18n.t(`delivery_fee.cep_end`)}
                    label={i18n.t(`delivery_fee.cep_end`)}
                    change={(value: string) => setZipEnd(value)}
                    value={zipEnd}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'money',
                    }}
                    placeholder={i18n.t(`delivery_fee.value`)}
                    label={i18n.t(`delivery_fee.value`)}
                    currency='BRL'
                    locale='pt-BR'
                    change={(value: number) => setPrice(value)}
                    value={price}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="col-6 d-flex justify-content-start">
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className="col-6  d-flex justify-content-end">
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => createZip()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

const CriarCep: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`delivery_fee.delivery_fee`), path: 'configuracoes/taxa-entrega', isActive: true },
          { title: i18n.t(`delivery_fee.cep_delivery`), path: 'configuracoes/taxa-entrega/cep', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'configuracoes/taxa-entrega/cep/criar', isActive: true },
        ]}
      >
        {i18n.t(`delivery_fee.create_cep`)}
      </PageTitle>
      <CriarCepContent />
    </>
  )
}

export { CriarCep }
