/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { DELETE, GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import useModals from '../../../../hooks/useModals'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { floatToPrice } from '../../../../functions/price'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid'
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { getGmtStore } from '../../../../utils/utils'
import { ModalImagemCropped } from '../../../../components/Modals/ImagemCropped/ImagemCropped'
import { removeEspacosEmBranco } from '../../../../utils/formater'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'


type ProductType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

type SelectType = {
  value: string;
  select: string;
}

type ProductFractionedType = {
  id: string
  code: string
  name: string
  price: number
}

type ImageType = {
  id: number
  productId: string
  path: string
  imgsuffix: string
  typeimg: string
}

type ImageCreateType = {
  path: string
  productId?: string
  typeimg: string
  order: number
}

type OptionalType = {
  id: number
  name: string
  typeoptional: string
  productId: number
  required: number
  type: string
  max: number
  items: {
    id: number
    product: {
      id: number
      name: string
      code: string
    }
  }[]
}

type FractionedResponseType = {
  id: string;
  productFractionalId: string;
  productId: string;
}

const itemMenu = {
  height: '100%',
  padding: '20px 20px 0',
  alignItems: 'center',
  display: 'flex',
  cursor: 'pointer',
  borderBottom: '4px solid #FFFFFF',
}

const itemMenuActive = {
  ...itemMenu,
  color: '#6993ff',
  borderBottom: '4px solid #6993ff',
}

const itemMenuInactive = {
  ...itemMenu,
  color: 'black',
  borderBottom: '4px solid #CCCCCC',
  marginLeft: 5,
  marginRight: 5
}

const PageContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { showWrapperModal, showDeleteModal, showImageCropped } = useModals()
  const [action, setAction] = useState<'createList' | 'addProduct'>('createList')

  const [selectedId, setSelectedId] = useState<number | null>(null)
  const [selecteOptionalIndex, setSelecteOptionalIndex] = useState<number | null>(null)

  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState('#tab1')

  const [type, setType] = useState<string>('product')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [status, setStatus] = useState<boolean>(true)
  const [description, setDescription] = useState<string>('')

  const [listClassification, setListClassification] = useState<any[]>([])

  const [categoryId, setCategoryId] = useState<string>('0')
  const [categories, setCategories] = useState<any[]>([])
  const [subcategoryId, setSubCategoryId] = useState<string>('0')
  const [subcategories, setSubCategories] = useState<any[]>([])

  const [markId, setMarkId] = useState<string>('0')
  const [locations, setLocations] = useState<any[]>([])
  const [variations, setVariations] = useState<any[]>([])
  const [measurementId, setMeasurementId] = useState<string>('')
  const [order, setOrder] = useState<string>('0')
  const [image, setImage] = useState<File | string | null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | string | null>(null)
  const [imageId, setImageId] = useState<Number>(0)
  const [imagesProducts, setImagesProducts] = useState<ImageType[]>([])
  const [extraImages, setExtraImages] = useState<FileList>()
  const [extraImagesUrl, setExtraImagesUrl] = useState<ImageType[]>([])
  const [deletedImages, setDeletedImages] = useState<number[]>([])

  const [imageCrop, setImageCrop] = useState<string | null>('')
  const [isCropped, setCropper] = useState<any>(null)

  const [optionals, setOptionals] = useState<any[]>([])
  const [optionalList, setOptionalList] = useState<OptionalType[]>([])

  const [optionalName, setOptionalName] = useState<string>('')
  const [optionalType, setOptionalType] = useState<string>('radio')
  const [optionalMax, setOptionalMax] = useState(0)
  const [optionalRequired, setOptionalRequired] = useState(0)
  const [hasMenu, setHasMenu] = useState(false)

  const [pricing, setPricing] = useState<null | string>('proportional')
  const [qtdProductsFractioned, setQtdProductsFractioned] = useState(1)
  const [showPriceFractioned, setShowPriceFractioned] = useState(false)
  const [sellAsFractioned, setSellAsFractioned] = useState(false)
  const [productsFractioned, setProductsFractioned] = useState<ProductFractionedType[]>([])
  const [searchProducts, setSearchProducts] = useState<ProductFractionedType[]>([]);
  const [allProductsFractioned, setAllProductsFractioned] = useState<ProductFractionedType[]>([])
  const [fractions, setFractions] = useState<FractionedResponseType[]>([])

  const [initialHour, setInitialHour] = useState<null | string>(null);
  const [finalHour, setFinalHour] = useState<null | string>(null);

  const [canAlert, setCanAlert] = useState(false);

  const getLocations = async () => {
    try {
      const res = await GET('/location')
      let _locations: { id: any; name: any }[] = []
      res.data
        .filter((c: any) => c.type === 'internal')
        .map((item: any) => {
          _locations.push({ id: item.id, name: item.name })
        })
      setLocations(_locations)
    } catch (e) {
      console.log(e)
    }
  }

  const getCategories = async () => {
    try {
      const res = await POST(`/classification/filter`, {
        status: true,
        /*O Campo abaixo só é utilizando para o Hotel, 
        nessa rota a troca do pluginType dele ocorre usando esse campo 
        */
        plugintypeId: 7
      })
      const newList = listClassification
      res.data.map((row: ProductType) => {
        newList.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: row.name,
        })
      })
      setListClassification(newList)
      const cat = newList.filter(function (row) {
        return row.parent === null || row.parent == ''
      })

      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      cat.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((row: any, idx) => {
        categories.push({
          value: row.code,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)
      setSubCategories([
        {
          value: '0',
          select: 'Selecione',
        },
      ])
    } catch (e) {
      console.log(e)
    }
  }

  const getSubCategories = async (code: string) => {
    const subcategories = [
      {
        value: '0',
        select: 'Selecione',
      },
    ]
    const sub = listClassification.filter(function (row) {
      return row.parent == code
    })
    sub.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)).map((row: any) => {
      subcategories.push({
        value: row.id,
        select: row.name,
      })
    })
    setSubCategories(subcategories)
  }

  const getProductFractioned = async () => {
    try {
      const resProduct = await GET('/product/fractioned')
      const newList: ProductFractionedType[] = [];
      resProduct.data.map((row: any) => {
        newList.push({
          id: row.id,
          name: row.name,
          code: row.code,
          price: row.variations[0].price
        })
      })
      const fractionsFromProduct = fractions.map((fract: any) => {
        const prodFind = newList.find((prod) => prod.id === fract.productFractionalId)
        return {
          id: fract.productFractionalId,
          code: prodFind?.code ? prodFind.code : '',
          name: prodFind?.name ? prodFind.name : '',
          price: prodFind?.price ? prodFind.price : 0
        }
      }).filter((fract) => fract.code !== '');
      setAllProductsFractioned(newList)
      setProductsFractioned(fractionsFromProduct)
      return fractionsFromProduct
    } catch(e) {
      console.log(e)
      return []
    }
  }

  const getProduct = async () => {
    setLoading(true)
    try {
      await getLocations()
      await getCategories()
      const res = await GET(`/product/with-variations/${id}`)

      if (res.data) {
        if (res.data[0].classificationId) {
          const cat = listClassification.filter(
            (row) => parseInt(row.id) === parseInt(res.data[0].classificationId)
          )
          if (cat[0] && cat[0].parent) {
            setCategoryId(cat[0].parent)
            getSubCategories(cat[0].parent)
            setSubCategoryId(cat[0].id)
          }
        }
        setMarkId(res.data[0].markId)
        setMeasurementId(res.data[0].measurementUnitId)
        setType(res.data[0].type)
        setName(res.data[0].name)
        setCode(res.data[0].code)
        setDescription(res.data[0].description)
        setStatus(res.data[0].status)
        setOrder(res.data[0].order ?? '0')
        setImagesProducts(res.data[0].images)

        setPricing(res.data[0].typeFractional ? res.data[0].typeFractional : 'proportional')
        setQtdProductsFractioned(res.data[0].quantityFractional ? res.data[0].quantityFractional : 1)
        setShowPriceFractioned(res.data[0].showFractionalPrice ? res.data[0].showFractionalPrice : false)
        setSellAsFractioned(res.data[0].sellAsFractional ? res.data[0].sellAsFractional : false)

        setInitialHour(res.data[0].initialHour ? res.data[0].initialHour : false)
        setFinalHour(res.data[0].finalHour ? res.data[0].finalHour : false)

        if(res.data[0] && res.data[0].fractions && res.data[0].fractions.length > 0){
          setFractions(res.data[0].fractions)
        }

        let urlProductImage = ''
        const imgProduct = res.data[0].images.find(
          (image: ImageType) => image.typeimg === 'imgsmall'
        )
        if (res.data[0].images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
          urlProductImage =
            imgProduct.path.substring(7, imgProduct.path.length) + '/' ===
              process.env.REACT_APP_AWS_S3_URL.substring(7, process.env.REACT_APP_AWS_S3_URL.length)
              ? imgProduct.path + '/' + imgProduct.imgsuffix
              : imgProduct.path
        }
        const imagesAdd = res.data[0].images.filter((image: ImageType) => image.typeimg === 'image')
        setExtraImagesUrl(imagesAdd)
        setFirstStateImage(urlProductImage)
        setImage(urlProductImage)
        setImageId(res.data[0].images[0] ? res.data[0].images[0].id : 0)
        setVariations(res.data[0].variations)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getOptionalList = async () => {
    setLoading(true)
    try {
      await getOptionals()
      const res = await GET(`/product/${id}/optional-list`)
      if (res.data) {
        const _optionalList = res.data.map((row: any) => ({
          id: row.id,
          name: row.name,
          typeoptional: row.typeoptional,
          productId: row.productId,
          required: row.required,
          type: row.type,
          max: row.max,
          items: row.items,
        }))
        setOptionalList(_optionalList)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getOptionals = async () => {
    setLoading(true)
    try {
      const res = await GET(`/product/optionals`)
      if (res.data) {
        setOptionals(res.data)
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const editProduct = async () => {
    try {
      if (categoryId === '0' || subcategoryId === '0' || !name) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        setLoading(true)
        const gmtStore = await getGmtStore();
        const fractionFromProduct = productsFractioned && productsFractioned.length > 0 ? productsFractioned : await getProductFractioned();
        const data = {
          name: removeEspacosEmBranco(name),
          description: description,
          order: parseInt(order),
          code: code ?? '',
          classificationId: parseInt(subcategoryId),
          type: type,
          status: status,
          measurementUnitId: parseInt(measurementId),
          parent: '',
          markId: parseInt(markId),
          typeFractional: type === 'fractional' ? pricing : null,
          quantityFractional: type === 'fractional' ? qtdProductsFractioned : null,
          sellAsFractional: type !== 'fractional' ? sellAsFractioned : false,
          variations: variations.map(c => ({ sku: c.sku, status: c.status, price: c.price, inventory: c.inventory, locationId: c.locationId })),
          fractions: fractionFromProduct.map((prod) => ({
              productFractionalId: prod.id
            })),
          showFractionalPrice: type === 'fractional' ? showPriceFractioned : false,
          initialHour: initialHour
            ? (initialHour.includes("+")
              ? initialHour.split("+")[0] + gmtStore
              : (initialHour.includes("-")
                ? initialHour.split("-")[0] + gmtStore
                : initialHour + gmtStore))
            : null,
          finalHour: finalHour
            ? (finalHour.includes("+")
              ? finalHour.split("+")[0] + gmtStore
              : (finalHour.includes("-")
                ? finalHour.split("-")[0] + gmtStore
                : finalHour + gmtStore))
            : null,
        }
        const resProd = await PUT(`/product/${id}`, data)
        const newDeletedImages = Object.assign(deletedImages)
        let dataImages: ImageCreateType[] = []
        if (resProd.success === true) {
          let imageRes = {
            success: false,
            image: '',
          }
          if (image && image !== firstStateImage) {
            const formData = new FormData()
            formData.append('file', image)
            formData.append('nameImage', `product-${uuid()}.jpg`)
            imageRes = await POST('/upload-images/upload-one', formData)
            imagesProducts.map((image) => {
              if (image.typeimg !== 'image') {
                deletedImages.push(image.id)
              }
            })
            dataImages = [
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'imgsmall',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'mainpicture',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: id,
                typeimg: 'thumbpicture',
                order: 0,
              },
            ]
          } else if (!image) {
            imagesProducts.map((image) => {
              if (image.typeimg !== 'image') {
                deletedImages.push(image.id)
              }
            })
          }
          if (extraImages) {
            await Promise.all(
              Array.from(extraImages).map(async (image, index) => {
                let imageExtraRes = {
                  success: false,
                  image: '',
                }
                const formData = new FormData()
                formData.append('file', image)
                formData.append('nameImage', `product-${uuid()}.jpg`)
                imageExtraRes = await POST('/upload-images/upload-one', formData)
                if (imageExtraRes.success == true) {
                  dataImages.push({
                    path: imageExtraRes.image,
                    productId: id,
                    typeimg: 'image',
                    order: 0,
                  })
                }
              })
            )
          }
          await POST('/productimage/multiple', {
            data: dataImages,
          })
          if (newDeletedImages.length > 0) {
            await POST(`productimage/delete-multiple`, {
              data: newDeletedImages,
            })
          }

          setLoading(false)
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Produto atualizado',
            text: 'Produto atualizado com sucesso!',
          }).then(() => navigate('/product_food'))
        } else {
          setCanAlert(true)
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao atualizar o produto!',
          })
        }
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao atualizar o produto!',
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getProduct()
      await getOptionalList()
      await getHasMenu()
    }

    const productFood = localStorage.getItem('product-food-edited-saved')
    if (productFood && JSON.parse(productFood)) loadLocal()
    else fetchData()

  }, [])

  useEffect(() => {
    return () => {
      if (canAlert && !window.location.pathname.includes(`/product_food/editar/${id}`)) {
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/product_food/editar/${id}`)
          } else {
            localStorage.removeItem('product-food-edited-saved')
          }
        })
      } else if (!canAlert) localStorage.removeItem('product-food-edited-saved')
    }
  }, [window.location.pathname, canAlert])

  const getHasMenu = async () => {
    try {
      setLoading(true)
      const resMenu = await POST('order-food/has-menu')
      if (resMenu) {
        setHasMenu(resMenu.success);
      }
      setLoading(false)
    } catch (error) {
      console.log(error);
    }
  }

  const addOptionalList = async () => {
    const _optionalName = optionalName;
    try {
      if (!optionalName) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const data = {
          name: optionalName,
          productId: parseInt(id!),
          required: optionalRequired,
          type: optionalType,
          max: optionalMax,
        }
        const res = await POST(`/optional`, data)
        if (res && res.success === false) {
          throw new Error();
        }
        setOptionalName('')
        setOptionalType('')
        setOptionalMax(0)
        setOptionalRequired(0)
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Lista cadastrada',
          text: 'Lista cadastrada com sucesso!',
        }).then(() => getOptionalList())
      }
    } catch (e) {
      setLoading(false)
      if (_optionalName.toLocaleLowerCase() === 'adicionais') {
        Swal.fire({
          icon: 'error',
          title: 'Nome inválido',
          text: 'A palavra adicionais é uma palavra reservada e não pode ser utilizada',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao salvar lista!',
        })
      }
    }
  }

  const removeOptionalItem = (i: number, j: number) => {
    let _optionalList = [...optionalList]
    if (_optionalList[i]) {
      _optionalList[i].items.splice(j, 1)
      setOptionalList(_optionalList)
    }
  }

  const addOptionalItem = () => {
    let _optionalList = [...optionalList]
    if (selecteOptionalIndex !== null) {
      const items = _optionalList[selecteOptionalIndex].items
      optionals
        .filter((c) => c.checked && c.checked === true)
        .map((c) => {
          if (items.filter((e) => e.product.id === c.id).length === 0) {
            items.push({ id: 0, product: { id: c.id, name: c.name, code: c.code } })
          }
        })
    }
    optionals.map((c) => delete c.checked)
  }

  const deleteOptionalList = async () => {
    try {
      setLoading(true)
      await DELETE(`/optional/${selectedId}`)
      Swal.fire({
        icon: 'success',
        title: 'Lista deletada',
        text: 'Deleção realizada com sucesso!',
      }).then(async () => await getOptionalList())
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar lista!',
      }).then(async () => await getOptionalList())
      console.log(e)
    }
  }

  const updateOptionalList = async (index: number) => {
    const _optional = optionalList[index]
    try {
      if (_optional && _optional.id) {
        if (!_optional.name) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!',
          })
        } else {
          setLoading(true)
          const data = {
            name: _optional.name,
            typeoptional: _optional.typeoptional,
            productId: _optional.productId,
            required: _optional.required,
            type: _optional.type,
            max: _optional.max,
            items: _optional.items.map((c) => parseInt(`${c.product.id}`)),
          }
          const res = await PUT(`/optional/${_optional.id}`, data);
          if (res && res.success === false) {
            throw new Error();
          }
          setLoading(false)
          Swal.fire({
            icon: 'success',
            title: 'Lista atualizada',
            text: 'Lista atualizada com sucesso!',
          }).then(() => getOptionalList())
        }
      }
    } catch (e) {
      setLoading(false)
      if (_optional.name.toLocaleLowerCase() === 'adicionais') {
        Swal.fire({
          icon: 'error',
          title: 'Nome inválido',
          text: 'A palavra adicionais é uma palavra reservada e não pode ser utilizada',
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao salvar lista!',
        })
      }
    }
  }

  const removeProductFractioned = (idDeleted: string) => {
    const newProductsFractioned = [...productsFractioned];
    setProductsFractioned(newProductsFractioned.filter((p) => p.id !== idDeleted))
  }
  const addProductFractioned = () => {
    const newProductsFractioned = [...productsFractioned]
    searchProducts.forEach((product) => {
      if (!newProductsFractioned.includes(product)) newProductsFractioned.push(product);
    })
    setProductsFractioned(newProductsFractioned)
  }

  const getCropData = async () => {
    if (isCropped) {
      const file = await fetch(isCropped.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "newAvatar.png", { type: "image/png" });
        });

      setImage(file)
      setImageCrop('')
    }
  };

  const getImagetoCrop = (e: any) => {
    if (e?.target.files) {
      showImageCropped()
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };

  const validateTime = (value: string) => {
    if (value) {
      value = value.replaceAll('_', '0')
      const values = value.split(':')
      let val1 = parseInt(values[0]) > 23 ? 23 : values[0]
      let val2 = parseInt(values[0]) > 23 || parseInt(values[1]) > 59 ? 59 : values[1]
      let val3 = parseInt(values[0]) > 23 || parseInt(values[2]) > 59 ? 59 : values[2]
      value = `${val1}:${val2}:${val3}`
    }
    return value
  }

  const loadLocal = () => {
    const productFood = localStorage.getItem('product-food-edited-saved')
    if (productFood && JSON.parse(productFood)) {
      const jsonProductFood = JSON.parse(productFood);
      setAction(jsonProductFood.action)
      setSelectedId(jsonProductFood.selectedId)
      setSelecteOptionalIndex(jsonProductFood.selecteOptionalIndex)
      setActiveTab(jsonProductFood.activeTab)
      setType(jsonProductFood.type)
      setName(jsonProductFood.name)
      setCode(jsonProductFood.code)
      setStatus(jsonProductFood.status)
      setDescription(jsonProductFood.description)
      setListClassification(jsonProductFood.listClassification)
      setCategoryId(jsonProductFood.categoryId)
      setCategories(jsonProductFood.categories)
      setSubCategoryId(jsonProductFood.subcategoryId)
      setSubCategories(jsonProductFood.subcategories)
      setMarkId(jsonProductFood.markId)
      setLocations(jsonProductFood.locations)
      setVariations(jsonProductFood.variations)
      setMeasurementId(jsonProductFood.measurementId)
      setOrder(jsonProductFood.order)
      setOptionals(jsonProductFood.optionals)
      setOptionalList(jsonProductFood.optionalList)
      setOptionalName(jsonProductFood.optionalName)
      setOptionalType(jsonProductFood.optionalType)
      setOptionalMax(jsonProductFood.optionalMax)
      setOptionalRequired(jsonProductFood.optionalRequired)
      setHasMenu(jsonProductFood.hasMenu)
      setPricing(jsonProductFood.pricing)
      setQtdProductsFractioned(jsonProductFood.qtdProductsFractioned)
      setShowPriceFractioned(jsonProductFood.showPriceFractioned)
      setSellAsFractioned(jsonProductFood.sellAsFractioned)
      setProductsFractioned(jsonProductFood.productsFractioned)
      setSearchProducts(jsonProductFood.searchProducts)
      setAllProductsFractioned(jsonProductFood.allProductsFractioned)
      setInitialHour(jsonProductFood.initialHour)
      setFinalHour(jsonProductFood.finalHour)
      if (typeof jsonProductFood.image === 'string') setImage(jsonProductFood.image)
      if (typeof jsonProductFood.firstStateImage === 'string') setFirstStateImage(firstStateImage)
      setExtraImagesUrl(jsonProductFood.extraImagesUrl)
      localStorage.removeItem('product-food-edited-saved')
    }
  }

  const saveLocal = () => {
    localStorage.setItem('product-food-edited-saved', JSON.stringify({
      action,
      selectedId,
      selecteOptionalIndex,
      activeTab,
      type,
      name,
      code,
      status,
      description,
      listClassification,
      categoryId,
      categories,
      subcategoryId,
      subcategories,
      markId,
      locations,
      variations,
      measurementId,
      order,
      optionals,
      optionalList,
      optionalName,
      optionalType,
      optionalMax,
      optionalRequired,
      hasMenu,
      pricing,
      qtdProductsFractioned,
      showPriceFractioned,
      sellAsFractioned,
      productsFractioned,
      searchProducts,
      allProductsFractioned,
      initialHour,
      finalHour,
      image,
      firstStateImage,
      extraImagesUrl
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    action,
    selectedId,
    selecteOptionalIndex,
    activeTab,
    type,
    name,
    code,
    status,
    description,
    listClassification,
    categoryId,
    categories,
    subcategoryId,
    subcategories,
    markId,
    locations,
    variations,
    measurementId,
    order,
    optionals,
    optionalList,
    optionalName,
    optionalType,
    optionalMax,
    optionalRequired,
    hasMenu,
    pricing,
    qtdProductsFractioned,
    showPriceFractioned,
    sellAsFractioned,
    productsFractioned,
    searchProducts,
    allProductsFractioned,
    initialHour,
    finalHour,
    image,
    firstStateImage,
    extraImagesUrl
  ])

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('product-food-edited-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-qnt-between'>
            <div className='w-100'>
              <div className='card'>
                <div className='d-flex' style={{ padding: '0 2.25rem', height: 75 }}>
                  <div
                    style={activeTab === '#tab1' ? itemMenuActive : itemMenuInactive}
                    onClick={() => setActiveTab('#tab1')}
                  >
                    <span>Informações básicas</span>
                   
                  </div>
                  {type === 'fractional' && (
                    <div 
                      style={activeTab === '#tab3' ? itemMenuActive : itemMenuInactive} 
                      onClick={async () => {
                        setActiveTab('#tab3')
                        if(!productsFractioned || productsFractioned.length === 0) {
                          setLoading(true)
                          await getProductFractioned();
                          setLoading(false)
                        }
                      }}
                    >
                      <span>Produto fracionado</span>
                    </div>
                  )}
                  <div
                    style={activeTab === '#tab2' ? itemMenuActive : itemMenuInactive}
                    onClick={() => setActiveTab('#tab2')}
                  >
                    <span>Adicionais</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {activeTab === '#tab1' && (
            <>
              <div className='d-flex justify-content-between flex-wrap w-100 gap-5'>
                <div style={{ flex: 1 }}>
                  <div className='card pb-5 h-100'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-4 mb-1'>Informações básicas</span>
                        <BreadCrumbs />
                      </h3>
                    </div>
                    <div className='px-10 py-5'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Tipo'
                        options={[
                          { select: 'Adicional', value: 'optional' },
                          { select: 'Produto', value: 'product' },
                          { select: 'Produto e Adicional', value: 'product_optional' },
                          { select: 'Fracionado', value: 'fractional' },
                        ]}
                        value={type}
                        change={(value: string) => {
                          setCanAlert(true)
                          setType(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Nome'
                        label='Nome'
                        value={name}
                        change={(value: string) => {
                          setCanAlert(true)
                          setName(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Código'
                        label='Código'
                        value={code}
                        change={(value: string) => {
                          setCanAlert(true)
                          setCode(value)
                        }}
                        disabled={hasMenu}
                      />

                      {type !== 'fractional' &&
                        <Input
                          inputAttr={{
                            type: 'checkbox',
                          }}
                          checked={sellAsFractioned}
                          change={(value: boolean) => {
                            setCanAlert(true)
                            setSellAsFractioned(value)
                          }}
                          label='Vender como fracionado'
                          disabled={hasMenu}
                        />
                      }


                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Status'
                        options={[
                          { select: 'Ativo', value: true },
                          { select: 'Inativo', value: false },
                        ]}
                        value={status}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          setStatus(value)
                        }
                        }
                      />
                      <Input
                        inputAttr={{
                          type: 'textarea',
                        }}
                        placeholder='Descrição'
                        label='Descrição'
                        value={description}
                        change={(value: string) => {
                          setCanAlert(true)
                          setDescription(value)
                        }
                        }
                        rows={5}
                      />
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.order`)}
                        label={i18n.t(`smart_order.order`)}
                        smalltext='(para exibição no webapp)'
                        value={order}
                        change={(value: string) => {
                          setCanAlert(true)
                          setOrder(value)
                        }
                        }
                      />
                      <div className="mt-6">
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          value={initialHour}
                          change={(value: string) => {
                            setCanAlert(true)
                            setInitialHour(value)
                          }
                          }
                          placeholder='00:00:00'
                          label={`${i18n.t(`smart_order.initial_hour`)}`}
                          blur={(value: string) => setInitialHour(validateTime(value))}
                        />
                      </div>
                      <div className="mt-6">
                        <Input
                          inputAttr={{
                            type: 'time',
                          }}
                          value={finalHour}
                          change={(value: string) => {
                            setCanAlert(true)
                            setFinalHour(value)
                          }
                          }
                          placeholder='00:00:00'
                          label={`${i18n.t(`smart_order.final_hour`)}`}
                          blur={(value: string) => setFinalHour(validateTime(value))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  {type === 'fractional' ?
                    <div className='card pb-5 mb-10'>
                      <div className='card-header py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-4 mb-1'>Configurações</span>
                        </h3>
                      </div>
                      <div className='px-10 py-5'>
                        <div className="row">
                          <div className="col-lg-8">
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              value={pricing}
                              label='Definir precificação'
                              options={[
                                { select: 'Cobrar valor proporcional', value: 'proportional' },
                                { select: 'Cobrar valor maior', value: 'biggest' },
                              ]}
                              change={(value: string | null) => {
                                setCanAlert(true)
                                setPricing(value)
                              }}
                            />
                            <p style={{ fontSize: 12, fontWeight: 300, color: '#333333', marginTop: 4, marginLeft: 2 }}>
                              Cobrar valor proporcional / Cobrar valor maior
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8">
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              value={qtdProductsFractioned}
                              label='Quantidade máxima de produtos'
                              options={[
                                { select: '1', value: 1 },
                                { select: '2', value: 2 },
                                { select: '3', value: 3 },
                                { select: '4', value: 4 },
                              ]}
                              change={(value: number) => {
                                setCanAlert(true)
                                setQtdProductsFractioned(value)
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <Input
                              inputAttr={{
                                type: 'checkbox',
                              }}
                              checked={showPriceFractioned}
                              change={(value: boolean) => {
                                setCanAlert(true)
                                setShowPriceFractioned(value)
                              }}
                              label='Exibir valor do fracionado'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    :
                    <div className='card pb-5 mb-10'>
                      <div className='card-header py-5'>
                        <h3 className='card-title align-items-start flex-column'>
                          <span className='card-label fw-bold fs-4 mb-1'>Preços</span>
                        </h3>
                      </div>
                      <div className='px-10 py-5'>
                        {locations.map((item, i) => (
                          <div key={`location-${i}`} className='row'>
                            <div className='col-lg-4'>
                              <Input
                                inputAttr={{
                                  type: 'select',
                                }}
                                label='Status'
                                options={[
                                  { select: 'Ativo', value: true },
                                  { select: 'Inativo', value: false },
                                ]}
                                value={
                                  variations.find((c) => c.locationId === item.id)
                                    ? variations.find((c) => c.locationId === item.id).status
                                    : ''
                                }
                                change={(value: string) => {
                                  setCanAlert(true)
                                  let _variations = [...variations]
                                  _variations[
                                    variations.findIndex((c) => c.locationId === item.id)
                                  ].status = value ?? false
                                  setVariations(_variations)
                                }}
                              />
                            </div>
                            <div className='col-lg-4'>
                              <Input
                                inputAttr={{
                                  type: 'money',
                                }}
                                currency='BRL'
                                locale='pt-BR'
                                placeholder={item.name}
                                label={item.name}
                                value={
                                  variations.find((c) => c.locationId === item.id)
                                    ? variations.find((c) => c.locationId === item.id).price
                                    : ''
                                }
                                change={(value: number) => {
                                  setCanAlert(true)
                                  let _variations = [...variations]
                                  _variations[
                                    variations.findIndex((c) => c.locationId === item.id)
                                  ].price = value ? value : 0
                                  setVariations(_variations)
                                }}
                              />
                            </div>
                            <div className='col-lg-4'>
                              <Input
                                inputAttr={{
                                  type: 'number',
                                }}
                                placeholder='Estoque'
                                label='Estoque'
                                value={
                                  variations.find((c) => c.locationId === item.id)
                                    ? variations.find((c) => c.locationId === item.id).inventory
                                    : 0
                                }
                                change={(value: string) => {
                                  setCanAlert(true)
                                  let _variations = [...variations]
                                  const findLocationVariation = variations.findIndex((c) => c.locationId === item.id)
                                  if (findLocationVariation > -1) {
                                    _variations[
                                      variations.findIndex((c) => c.locationId === item.id)
                                    ].inventory = value ? parseInt(value) : 0
                                    setVariations(_variations)
                                  } else {
                                    _variations.push({
                                      inventory: parseInt(value),
                                      locationId: item.id,
                                      sku: _variations[0].sku
                                    })
                                    setVariations(_variations)
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  }
                  <div className='card pb-5 mb-10'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-4 mb-1'>Categorias</span>
                      </h3>
                    </div>
                    <div className='px-10 py-5'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Categoria'
                        options={categories}
                        value={categoryId}
                        change={(value: string) => {
                          setCanAlert(true)
                          setCategoryId(value)
                          getSubCategories(value)
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Subcategoria'
                        options={subcategories}
                        value={subcategoryId}
                        change={(value: string) => {
                          setCanAlert(true)
                          setSubCategoryId(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='card pb-5'>
                    <div className='card-header py-5'>
                      <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-4 mb-1'>Imagens do produto</span>
                      </h3>
                    </div>
                    <div className='d-flex justify-content-center w-100'>
                      <div className='px-10 py-5'>
                        {/*
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      value={image}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                    */
                          <>
                            <Input
                              inputAttr={{
                                type: 'image',
                              }}
                              value={image}
                              change={
                                (value: BaseSyntheticEvent) => {
                                  setCanAlert(true)
                                  setImage(value ? value.target.files[0] : null)
                                  if (value) {
                                    getImagetoCrop(value ? value : null)
                                  } else {
                                    setImageCrop('')
                                    getImagetoCrop(null)
                                  }
                                }
                              }
                            />

                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  text='Voltar'
                  color='info'
                  size='small'
                  horizontalPadding={5}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    setCanAlert(false)
                    navigate('/product_food')
                  }}
                  btnClass='fw-bolder me-3'
                />
                <Button
                  text='Salvar'
                  color='primary'
                  size='small'
                  horizontalPadding={5}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    editProduct()
                  }}
                  btnClass='fw-bolder'
                />
              </div>
            </>
          )}

          {activeTab === '#tab2' && (
            <div className='d-flex justify-content-between'>
              <div className='w-100'>
                <div className='card pb-5'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Adicionais</span>
                    </h3>
                    <div className='card-toolbar'>
                      <Button
                        text='Criar lista de adicionais'
                        color='primary'
                        iconColor='#FFF'
                        icon='fas fa-plus'
                        size='small'
                        horizontalPadding={6}
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          setOptionalName('')
                          setOptionalType('radio')
                          setOptionalMax(0)
                          setOptionalRequired(0)
                          setAction('createList')
                          showWrapperModal('Criar lista de adicionais')
                        }}
                        btnClass='mb-5 fw-bolder'
                      />
                    </div>
                  </div>
                  <div>
                    {optionalList.length === 0 && (
                      <div className='p-10 text-center'>
                        <h3 className='text-gray-600 fs-6 mb-2'>Nenhuma lista encontrada</h3>
                      </div>
                    )}

                    {optionalList.map((optional, i) => (
                      <div
                        key={`option-${i}`}
                        className={'px-10'}
                        style={{ borderBottom: '1px solid #eff2f5', paddingBottom: 20 }}
                      >
                        <div className='row'>
                          <div className='col-5'>
                            <Input
                              inputAttr={{
                                type: 'default',
                              }}
                              label='Nome lista'
                              value={optional.name}
                              change={(value: string) => {
                                let _optionals = [...optionalList]
                                _optionals[i].name = value
                                setOptionalList(_optionals)
                              }}
                            />
                          </div>
                          <div className='col-3'>
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              label='Tipo de Seleção'
                              options={[
                                { select: 'Seleção única', value: 'radio' },
                                { select: 'Seleção múltipla', value: 'checkbox' },
                                { select: 'Seleção de quantidade', value: 'amount' },
                              ]}
                              value={optional.type}
                              change={(value: string) => {
                                let _optionals = [...optionalList]
                                _optionals[i].type = value
                                setOptionalList(_optionals)
                              }}
                            />
                          </div>

                          <div className='col-2'>
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              label='Obrigatório'
                              options={[
                                { select: 'Não', value: '0' },
                                { select: 'Sim', value: '1' },
                              ]}
                              value={optional.required}
                              change={(value: string) => {
                                let _optionals = [...optionalList]
                                _optionals[i].required = parseInt(value)
                                setOptionalList(_optionals)
                              }}
                            />
                          </div>
                          <div className='col-2'>
                            <Input
                              inputAttr={{
                                type: 'select',
                              }}
                              label='Máximo'
                              options={[
                                { select: 'Sem limte', value: '0' },
                                { select: '1', value: '1' },
                                { select: '2', value: '2' },
                                { select: '3', value: '3' },
                                { select: '4', value: '4' },
                                { select: '5', value: '5' },
                                { select: '6', value: '6' },
                                { select: '7', value: '7' },
                                { select: '8', value: '8' },
                                { select: '9', value: '9' },
                                { select: '10', value: '10' },
                              ]}
                              value={optional.max}
                              change={(value: string) => {
                                let _optionals = [...optionalList]
                                _optionals[i].max = value ? parseInt(value) : 0
                                setOptionalList(_optionals)
                              }}
                            />
                          </div>
                        </div>
                        {optional.items.length > 0 && (
                          <div className='row py-5'>
                            <div className='col-12'>
                              <label>Produtos</label>
                              {optional.items.map((item, j) => (
                                <div
                                  key={`optional-${j}`}
                                  className='d-flex'
                                  style={{ alignItems: 'center' }}
                                >
                                  <Input
                                    inputAttr={{
                                      type: 'default',
                                    }}
                                    value={item.product ? `${item.product.code} - ${item.product.name}` : ''}
                                    disabled={true}
                                  />
                                  <i
                                    className={`fas fa-trash fs-4 mt-6 px-5`}
                                    onClick={() => removeOptionalItem(i, j)}
                                    style={{ cursor: 'pointer' }}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        <div className='d-flex w-100 py-5'>
                          <div className='col-6 d-flex justify-content-start'>
                            <Button
                              text='Adicionar produto'
                              color='primary'
                              size='small'
                              horizontalPadding={5}
                              btnAttr={{
                                type: 'button',
                              }}
                              click={() => {
                                setSelecteOptionalIndex(i)
                                setAction('addProduct')
                                showWrapperModal('Adicionar produto')
                              }}
                              btnClass='fw-bolder'
                            />
                          </div>
                          <div className='col-6  d-flex justify-content-end'>
                            <Button
                              text='Salvar lista'
                              color='primary'
                              size='small'
                              horizontalPadding={5}
                              btnAttr={{
                                type: 'button',
                              }}
                              click={() => {
                                updateOptionalList(i)
                              }}
                              btnClass='fw-bolder'
                            />
                            <Button
                              text='Excluir lista'
                              color='danger'
                              size='small'
                              horizontalPadding={5}
                              btnAttr={{
                                type: 'button',
                              }}
                              click={() => {
                                setSelectedId(optional.id)
                                showDeleteModal('Excluir produto')
                              }}
                              btnClass='fw-bolder ms-3'
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className='card-footer'>

                    <div className=' d-flex justify-content-end py-6'>
                      <Button
                        text='Adicionar lista'
                        color='primary'
                        size='small'
                        horizontalPadding={5}
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          addOptionalList()
                        }}
                        btnClass='fw-bolder'
                      />
                    </div>
                  </div> */}
                </div>
              </div>
              <ModalDelete confirmAction={() => deleteOptionalList()} />
            </div>
          )}

          {activeTab === '#tab3' && (
            <div className='d-flex justify-content-between'>
              <div className='w-100 me-5'>
                <div className='card pb-5'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bold fs-4 mb-1'>Produto fracionado</span>
                    </h3>
                  </div>
                  <span className='py-5' style={{ padding: '0 2.25rem', color: '#9FA3B5', fontSize: 12, fontWeight: 400 }}>
                    Crie listas com produtos adicionais e defina como eles serão exibidos junto ao produto principal.
                  </span>
                  <div className='card-footer' style={{ borderTop: 'none' }}>
                    <div className='d-flex flex-column justify-content-center align-items-start'>
                      <h2 style={{ fontSize: 14, fontWeight: 600 }}>
                        Produtos
                      </h2>
                      {
                        productsFractioned.map((product) => (
                          <div className="row" style={{ width: '70%' }}>
                            <div className='col col-lg-4'>
                              <span>{product.code} - {product.name}</span>
                            </div>
                            <div className='col col-lg-4'>
                              <span>{floatToPrice(product.price)}</span>
                            </div>
                            <div className='col col-lg-4'>
                              <span style={{ cursor: 'pointer' }}>
                                <i className='fas fa-trash' onClick={() => removeProductFractioned(product.id)} />
                              </span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <div className=' d-flex justify-content-end py-6'>
                      <Button
                        text='Adicionar produto'
                        color='primary'
                        size='small'
                        horizontalPadding={5}
                        btnAttr={{
                          type: 'button',
                        }}
                        click={() => {
                          setSearchProducts([]);
                          showWrapperModal('Adicionar produto')
                        }}
                        btnClass='fw-bolder'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === '#tab3' ?
            <ModalWrapper confirmAction={() => addProductFractioned()} width={50} textAction="Adicionar" height={30}>
              <div className='d-flex flex-column justify-content-between align-items-center'>
                <div className='flex-grow-1' style={{ width: '90%' }}>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    search
                    placeholder={'Comece a digitar para procurar um produto'}
                    options={
                      allProductsFractioned
                        .filter((product) => !productsFractioned.includes(product))
                        .filter((product) => !searchProducts.includes(product))
                        .map((product) => ({ value: product.id, select: `${product.code} - ${product.name}` }))
                    }
                    value={null}
                    change={(selectedProduct: SelectType) => {
                      const productToAdd = allProductsFractioned.find((product) => product.id === selectedProduct.value)
                      if (productToAdd) {
                        const newSearchProducts = [...searchProducts];
                        if (!newSearchProducts.includes(productToAdd)) {
                          newSearchProducts.push(productToAdd);
                          setSearchProducts(newSearchProducts)
                        }
                      }
                    }}
                  />
                </div>
                <div className='flex-grow-1 mt-4' style={{ width: '90%' }}>
                  {searchProducts.map((product) => (
                    <div className="d-flex flex-row align-items-center">
                      <span
                        className={`form-check-label fw-normal text-gray-800 ms-1`}
                      >
                        {product.name}
                      </span>
                      <i
                        style={{ marginLeft: '1rem', cursor: 'pointer' }}
                        className='fas fa-times'
                        onClick={() => {
                          setSearchProducts(searchProducts.filter((productFilter) => product.id !== productFilter.id))
                        }}
                      >
                      </i>
                    </div>
                  ))}
                </div>
              </div>
            </ModalWrapper>
            :
            <ModalWrapper
              confirmAction={
                action === 'createList' ? () => addOptionalList() : () => addOptionalItem()
              }
            >
              {action === 'createList' && (
                <div>
                  <div className='row'>
                    <div className='col-12'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        label='Nome lista'
                        value={optionalName}
                        change={(value: string) => {
                          setOptionalName(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Tipo de Seleção'
                        options={[
                          { select: 'Seleção única', value: 'radio' },
                          { select: 'Seleção múltipla', value: 'checkbox' },
                          { select: 'Seleção de quantidade', value: 'amount' },
                        ]}
                        value={optionalType}
                        change={(value: string) => {
                          setOptionalType(value)
                        }}
                      />
                    </div>

                    <div className='col-4'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Obrigatório'
                        options={[
                          { select: 'Não', value: '0' },
                          { select: 'Sim', value: '1' },
                        ]}
                        value={optionalRequired}
                        change={(value: string) => {
                          setOptionalRequired(parseInt(value))
                        }}
                      />
                    </div>
                    <div className='col-4'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Máximo'
                        options={[
                          { select: 'Sem limte', value: '0' },
                          { select: '1', value: '1' },
                          { select: '2', value: '2' },
                          { select: '3', value: '3' },
                          { select: '4', value: '4' },
                          { select: '5', value: '5' },
                          { select: '6', value: '6' },
                          { select: '7', value: '7' },
                          { select: '8', value: '8' },
                          { select: '9', value: '9' },
                          { select: '10', value: '10' },
                        ]}
                        value={optionalMax}
                        change={(value: string) => {
                          setOptionalMax(parseInt(value))
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {action === 'addProduct' && (
                <div className='d-flex justify-content-between' style={{ height: 330, overflowY: 'scroll' }}>
                  <div className='mx-10 flex-grow-1'>
                    <div className='row'>
                      <div className='col-12'>
                        {optionals.map((item, index) => (
                          <Input
                            key={`optional-product-${index}`}
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            checked={optionals[index].checked === true}
                            change={(value: boolean) => {
                              let _optionals = [...optionals]
                              _optionals[index].checked = value
                              setOptionals(_optionals)
                            }}
                            label={`${item.code} - ${item.name}`}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ModalWrapper>
          }
        </div>
      )}

      <ModalImagemCropped

        imageCrop={imageCrop}
        setCropper={setCropper}
        getCropData={getCropData}
      />
    </>
  )
}

const EditarProdutos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Produtos', path: 'product_food', isActive: true },
          { title: 'Editar', path: '#', isActive: true },
        ]}
      >
        Editar Produto
      </PageTitle>
      <PageContent />
    </>
  )
}

export { EditarProdutos }
