/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {toDate} from '../../../../utils/formater'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type Props = {
  startDate: string
  setStartDate: React.Dispatch<React.SetStateAction<string>>
  finalDate: string
  setFinalDate: React.Dispatch<React.SetStateAction<string>>
}

const TransacoesContent: FC<Props> = (data) => (
  <>
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Relatório de Transações</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              Aqui estão registradas as transações durante o período selecionado.
            </span>
          </h3>
        </div>
        <div className='card-body'>
          <div className='w-100 mb-5'>
            <h4 className='text-gray-800'>Selecione o período</h4>
            <div className='d-flex align-items-end'>
              <Input
                inputAttr={{type: 'date'}}
                label='Início'
                value={data.startDate}
                change={data.setStartDate}
                maxLength={data.finalDate}
              />
              <div className='mx-10'>
                <Input
                  inputAttr={{type: 'date'}}
                  label='Fim'
                  value={data.finalDate}
                  change={data.setFinalDate}
                  minLength={data.startDate}
                />
              </div>
              <Button color='primary' text='Procurar' click={() => {}} btnClass='h-45px' />
            </div>

            <div className='d-flex align-items-center justify-content-between border-bottom border-top py-4 mt-8 fs-4 fw-bold text-gray-800'>
              <p className='m-0'>{`Pedidos entre ${toDate(data.startDate)} até ${toDate(
                data.finalDate
              )}`}</p>
              <div className='d-flex align-items-center'>
                <Button color='primary' text='PDF' size='small' click={() => {}} />
                <Button
                  color='primary'
                  text='Excel'
                  size='small'
                  click={() => {}}
                  btnClass='mx-3'
                />
                <Button color='primary' text='CSV' size='small' click={() => {}} />
              </div>
            </div>
          </div>
          <Datatable
            data={[
              {
                id: 23,
                nsu_transaction: 1661637,
                status: 'Pago',
                value: 45,
                order: 'CHAR_876E6FED-F89A-4A78-80D2-6833B8546FEC',
                nsu_merchant: 'CHAR_876E6FED-F89A-4A78-80D2-6833B8546FEC',
                date: '05/05/2022 07:57:04',
              },
              {
                id: 23,
                nsu_transaction: 1661637,
                status: 'Cancelado',
                value: 45,
                order: 'CHAR_876E6FED-F89A-4A78-80D2-6833B8546FEC',
                nsu_merchant: 'CHAR_876E6FED-F89A-4A78-80D2-6833B8546FEC',
                date: '05/05/2022 07:57:04',
              },
            ]}
            headers={[
              {key: 'Id', value: 'id', type: 'default'},
              {key: 'NSU Transação', value: 'nsu_transaction', type: 'default'},
              {
                key: 'Status',
                value: 'status',
                type: 'badge',
                badge: {
                  pago: 'success',
                  cancelado: 'danger',
                },
              },
              {key: 'Valor', value: 'value', type: 'money'},
              {key: 'Pedido', value: 'order', type: 'default'},
              {key: 'NSU Merchant', value: 'nsu_merchant', type: 'default'},
              {key: 'Data', value: 'date', type: 'default'},
              {key: 'Ações', value: 'acoes', type: ''},
            ]}
            options={[
              {icon: '', isButton: true, textButton: 'Detalhes', action: () => {}, color: 'info'},
              {icon: '', isButton: true, textButton: 'Cancelar', action: () => {}, color: 'danger'},
            ]}
            color='primary'
          />
        </div>
      </div>
    </div>
  </>
)

const Transacoes: FC = () => {
  const dataAtual = new Date()
  const dataAtualArray = dataAtual.toLocaleDateString().split('T')[0].split('/')

  const [startDate, setStartDate] = useState(`${dataAtualArray[2]}-${dataAtualArray[1]}-01`)
  const [finalDate, setFinalDate] = useState(
    `${dataAtualArray[2]}-${dataAtualArray[1]}-${dataAtualArray[0]}`
  )

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Relatório de Transações', path: 'relatorios/transacoes', isActive: true},
        ]}
      >
        Relatório de Transações
      </PageTitle>
      <TransacoesContent
        startDate={startDate}
        setStartDate={setStartDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
      />
    </>
  )
}

export {Transacoes}
