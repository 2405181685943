import { GET, POST } from "../services/api";

type PluginconfigType = {
    name: string;
    plugintemplateId: number;
    deleted: string;
}

export async function verifyWinthor(){
    try{
        var pluginconfigRes = await POST(`/pluginconfig/filter`, {
            plugintemplateId: 45
        })
        if(!pluginconfigRes.success){
            pluginconfigRes = await POST(`/pluginconfig/filter`, {
                plugintemplateId: 15
            })
        }
        if(pluginconfigRes && pluginconfigRes.data && pluginconfigRes.data[0] && pluginconfigRes.data[0].content){
            const contentPluginconfig = pluginconfigRes.data[0].content;
            if(contentPluginconfig.active === '1'){
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    } catch(e){
        return false;
    }
}

export async function verifyTotvsVarejo(){
    try{
      const res = await GET(`/pluginconfig`);
      const totvsIntegration = res.data.filter((pluginconfig:PluginconfigType) => pluginconfig.plugintemplateId === 15)
      if(totvsIntegration && totvsIntegration.length > 0){
        return true
      } else {
        return false
      }
    } catch(e) {
      return false;
    }
}