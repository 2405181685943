import { FC, useEffect, useState } from 'react'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'
import { Loading } from '../../../components/Loading/Loading'
import { GET } from '../../../services/api'
import Swal from 'sweetalert2'
import useAuth from '../../../hooks/useAuth'

type QRCodeType = {
  type: string
  label: string
  iniInterval: number
  endInterval: number
  intervals: {
    iniInterval: number
    endInterval: number
  }[]
  url: number,
  hash?: string
}

type LandmarkType = {
  uuid: string;
  name: string;
  tag: number;
  description: string | null;
  mapId: string;
}

const GenerateQRCodeContent: FC = () => {
  const { data } = useParams()
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false)
  const [qrCode, setQRCode] = useState<QRCodeType | undefined>()
  const [intervals, setIntervals] = useState<string[]>([])
  const [landmarks, setLandmarks] = useState<LandmarkType[]>([])

  useEffect(() => {
    if (qrCode && qrCode.type !== 'counter') {
      let _intervals: string[] = []
      if (
        qrCode.iniInterval > 0 &&
        qrCode.endInterval > 0 &&
        qrCode.iniInterval <= qrCode.endInterval
      ) {
        for (let i = qrCode.iniInterval; i <= qrCode.endInterval; i++) {
          if (_intervals.indexOf(i.toString()) === -1) {
            _intervals.push(i.toString())
          }
        }
      }
      qrCode.intervals.map((interval) => {
        if (
          interval.iniInterval > 0 &&
          interval.endInterval > 0 &&
          interval.iniInterval <= interval.endInterval
        ) {
          for (let i = interval.iniInterval; i <= interval.endInterval; i++) {
            if (_intervals.indexOf(i.toString()) === -1) {
              _intervals.push(i.toString())
            }
          }
        }
      })
      setIntervals(_intervals)
    }
  }, [qrCode])

  useEffect(() => {
    if (data) {
      try {
        setLoading(true)
        const decodedData = atob(data)
        const qrCodeData = JSON.parse(decodedData);
        if (qrCodeData.type && qrCodeData.type === 'synkar') {
          setQRCode(qrCodeData)
          getQrCodeSynkar()
        } else if (qrCodeData.type && qrCodeData.type === 'counter') {
          qrCodeData.intervals = ['Balcão']
          setQRCode(qrCodeData)
          setIntervals(['Balcão'])
          setLoading(false)
        } else {
          setQRCode(qrCodeData)
          setLoading(false)
        }
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }, [data])

  async function getQrCodeSynkar() {
    try {
      setLoading(true);
      const synkarRes = await GET('/synkar/landmarks')
      setLoading(false)
      if (synkarRes && synkarRes.success && synkarRes.data && synkarRes.data.length > 0) {
        const intervalsNew: string[] = [];
        const newLandmarks: LandmarkType[] = [];
        synkarRes.data.forEach((landmark: LandmarkType) => {
          newLandmarks.push(landmark)
          intervalsNew.push(landmark.uuid)
        })
        setIntervals(intervalsNew)
        setLandmarks(newLandmarks)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao conectar com synkar',
        })
      }
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Falha ao conectar com synkar',
      })
    }
  }

  function setValueQrCode(url: number, type: string, interval: string, label: string) {
    if (loginInfo.plugintype === 'hospitality') {
      return `${url}/hospitality/app/${qrCode?.hash}/${interval}`
    }

    if(type === 'table' || type === 'card' || type === 'internal-delivery'){
      return `${url}/${type}/${interval}/${label}`  
    }

    return `${url}/${type}/${interval}`
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div style={{ margin: 8 }}>
          <div className='w-100' >
            <div
              className='d-flex justify-content-center align-items-center'
              style={{
                height: '5vh',
                background: '#F00',
                marginBottom: '1rem'
              }}>
              <span className='text-white fs-4 text-center'>Recomendamos que faça um teste nos qrcodes gerados, pois caso seja impresso indevidamente, não é possível fazer um redirecionamento</span>
            </div>

          </div>
          {qrCode &&
            intervals.map((interval, i) => (
              <div key={i} style={{ float: 'left' }}>
                <div style={{ border: '1px dashed #CCC', padding: 15 }}>
                  <QRCode 
                    value={landmarks[i] ? 
                      `${qrCode.url}/${qrCode.type}/${btoa(JSON.stringify({ interval, landmark: landmarks[i].name }))}` : 
                      `${setValueQrCode(qrCode.url, qrCode.type, btoa(`${interval}`), btoa(qrCode.label))}`} size={175} 
                    />
                  <div style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: 12, paddingTop: 15 }}>
                    {landmarks[i] ? landmarks[i].name : qrCode.label} {interval}
                  </div>
                </div>
                <div style={{ textAlign: 'center', fontFamily: 'Arial', fontSize: 12, paddingTop: 15 }}>
                  <a 
                    href={landmarks[i] ? 
                      `${qrCode.url}/${qrCode.type}/${btoa(JSON.stringify({ interval, landmark: landmarks[i].name }))}` : 
                      `${setValueQrCode(qrCode.url, qrCode.type, btoa(`${interval}`), btoa(qrCode.label))}`} target='_blank' rel="noreferrer"
                    >
                    Link {interval}
                  </a>
                </div>
              </div>
            ))}
        </div >
      )}
    </>
  )
}

const GenerateQRCodeWebapp: FC = () => {
  return <GenerateQRCodeContent />
}

export { GenerateQRCodeWebapp }
