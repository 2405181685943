/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../../../_metronic/layout/core'
import {Datatable} from '../../../../../../components/Datatable/Datatable'
import {Button} from '../../../../../../components/Button/Button'
import {useNavigate} from 'react-router-dom'
import useModals from '../../../../../../hooks/useModals'
import { DELETE, POST } from '../../../../../../services/api'
import Swal from 'sweetalert2'
import { Loading } from '../../../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../../../components/Modals/General/ModalDelete/ModalDelete'
import { i18n } from '../../../../../../translate/i18n'
import { BreadCrumbs } from '../../../../../../components/BreadCrumbs/breadCrumbrs'

type CepType = {
  id: number;
  zipStart: string;
  zipEnd: string;
}

const CepNegativoContent: FC = () => {
  const {showDeleteModal} = useModals()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [ceps, setCeps] = useState<Array<CepType>>([]);
  const [id, setId] = useState<number | null>(null);

  const getCeps = async () => {
    try {
      setLoading(true);
      const cepRes = await POST('/zip-code/filter', { negativeRange: true })
      const newCeps = cepRes.data.map((cep:CepType) => {
        let newCepStart = cep.zipStart.toString();
        let newCepEnd = cep.zipEnd.toString();
        if(newCepStart.length < 8){
          let leftZeros = '';
          for(let i = 0; i < 8 - newCepStart.length; i++){
            leftZeros += '0';
          }
          newCepStart = leftZeros + newCepStart;
        }

        if(newCepEnd.length < 8){
          let leftZeros = '';
          for(let i = 0; i < 8 - newCepEnd.length; i++){
            leftZeros += '0';
          }
          newCepEnd = leftZeros + newCepEnd;
        }
        return {
          id: cep.id,
          zipStart: `${newCepStart.substring(0, 5)}-${newCepStart.substring(5, 8)}`,
          zipEnd: `${newCepEnd.substring(0, 5)}-${newCepEnd.substring(5, 8)}`,
        }
      })
      setCeps(newCeps);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const deleteCep = async () => {
    try{
      setLoading(true);
      const cepRes = await DELETE(`/zip-code/${id}`);
      if(cepRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'CEP deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getCeps())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar CEP!'
        }).then(async () => await getCeps())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar CEP!'
      }).then(async () => await getCeps())
      console.log(e)
    }
  }

  useEffect(() => {
    getCeps();
  }, [])

  return (
    <>
    { loading ? <Loading /> :
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`delivery_fee.cep_delivery_negative`)}</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              {i18n.t(`delivery_fee.cep_info_negative`)}
            </span>
          </h3>
          <div className='card-toolbar d-flex justify-content-end w-100 gap-5'>
            <Button
              text={i18n.t(`buttons.add_negative_zip_code`)}
              color='primary'
              iconColor='#FFF'
              icon='fas fa-plus'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => navigate('/configuracoes/taxa-entrega/cep/negativo/criar')}
              btnClass='fw-bolder'
            />
            <Button
              text={i18n.t(`buttons.back`)}
              color='primary'
              iconColor='#FFF'
              icon='bi bi-arrow-left fs-4'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => navigate('/configuracoes/taxa-entrega/cep')}
              btnClass='fw-bolder'
            />
          </div>
        </div>
        <div className='card-body'>
          <Datatable
            data={ceps}
            headers={[
              {key: i18n.t(`delivery_fee.cep_start`), value: 'zipStart', type: 'default'},
              {key: i18n.t(`delivery_fee.cep_end`), value: 'zipEnd', type: 'default'},
              {key: i18n.t(`delivery_fee.actions`), value: 'acoes', type: ''},
            ]}
            options={[
              {
                icon: 'fas fa-pen',
                action: (item) => {
                  navigate(`/configuracoes/taxa-entrega/cep/negativo/editar/${item.id}`)
                },
                title: i18n.t(`delivery_fee.edit_cep_negative`),
              },
              {icon: 'fas fa-trash', action: (item) => {
                showDeleteModal(i18n.t(`delivery_fee.delete_cep_negative`))
                setId(item.id);
              }, title: i18n.t(`delivery_fee.delete_cep_negative`)},
            ]}  
            color='primary'
          />
        </div>
      </div>
      <ModalDelete confirmAction={() => deleteCep()} /> 
    </div>}
    </>
  )
}

const CepNegativo: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`delivery_fee.delivery_fee`), path: 'configuracoes/taxa-entrega', isActive: true},
          {title: i18n.t(`delivery_fee.cep_delivery`), path: 'configuracoes/taxa-entrega/cep', isActive: true},
          {
            title: i18n.t(`delivery_fee.cep_delivery_negative`),
            path: 'configuracoes/taxa-entrega/cep/negativo',
            isActive: true,
          },
        ]}
      >
        {i18n.t(`delivery_fee.cep_delivery_negative`)}
      </PageTitle>
      <CepNegativoContent />
    </>
  )
}

export {CepNegativo}
