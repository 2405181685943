import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type TemplateResponseType = {
  id: number;
  name: string;
  parameters: any;
}

type ConfigInputsType = {
  name: string;
  label: string;
  value?: string;
}

const EditarMeioPagamentoContent: FC = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState<string>('');
  const [templates, setTemplates] = useState<TemplateResponseType[]>([]);
  const [configInputs, setConfigInputs] = useState<(ConfigInputsType | undefined)[]>([]);
  const [templateId, setTemplateId] = useState<number | null>();

  const getIntegration = async () => {
    try {
      const newTemplates = await getTemplates();
      setLoading(true);
      const integrationRes = await GET(`/pluginconfig/${id}`)
      if (
        integrationRes.data &&
        integrationRes.data[0] &&
        (
          integrationRes.data[0].plugintemplateId === 20 ||
          integrationRes.data[0].plugintemplateId === 10 ||
          integrationRes.data[0].plugintemplateId === 47
        )
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Não é possível editar este plugin de pagamento!'
        }).then(() => navigate('/pluginconfig/gateway'))
      }
      setName(integrationRes.data[0].name);
      changeTemplate(integrationRes.data[0].plugintemplateId, integrationRes.data[0].content, newTemplates)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const getTemplates = async () => {
    try {
      setLoading(true);
      const templateRes = await POST('/plugintemplate/filter', { plugintypeId: 14 })
      const newTemplates: TemplateResponseType[] = [{ id: 0, name: 'Selecione um template', parameters: null }]
      templateRes.data.filter((template: TemplateResponseType) => template.id !== 12).map((template: TemplateResponseType) => {
        newTemplates.push({
          id: template.id,
          name: template.name,
          parameters: JSON.parse(template.parameters)
        })
      })
      setTemplates(newTemplates)
      setLoading(false);
      return newTemplates
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const changeTemplate = (selectedId: number, configuration?: any, newTemplates?: TemplateResponseType[]) => {
    setTemplateId(selectedId)
    if (selectedId !== 0) {
      let template;
      if (templates.length === 0 && newTemplates) {
        template = newTemplates.find((templateFinded) => templateFinded.id === selectedId);
      } else {
        template = templates.find((templateFinded) => templateFinded.id === selectedId);
      }
      const newInputs: (ConfigInputsType | undefined)[] = Object.entries(template?.parameters.config).map((configInput) => {
        if (configInput[0] !== undefined && configInput[1] !== undefined && typeof (configInput[1]) === 'string') {
          const input: ConfigInputsType = {
            name: configInput[0],
            label: configInput[1],
            value: configuration && configuration[configInput[0]] ? configuration[configInput[0]] : ''
          }
          return input
        }
      })

      setConfigInputs(newInputs)
    }
  }

  const editIntegration = async () => {
    if (templateId) {
      const template = templates.find((templateFinded) => templateFinded.id === templateId);
      let integrationContent: any = {}
      Object.entries(template?.parameters.config).map((configInput) => {
        const element: any = document.getElementsByName(configInput[0])[0];
        integrationContent[configInput[0]] = element.value;
      })
      try {
        if (!name || !templateId) {
          Swal.fire({
            icon: 'error',
            title: 'Campos faltantes',
            text: 'Verifique se preencheu todos os campos!'
          })
        } else {
          setLoading(true);
          const integrationRes = await PUT(`/pluginconfig/${id}`, {
            plugintypeId: 14,
            plugintemplateId: templateId,
            name: name,
            content: integrationContent
          })
          setLoading(false);
          if (integrationRes.success === true) {
            Swal.fire({
              icon: 'success',
              title: 'Integração alterada',
              text: 'Edição realizada com sucesso!'
            }).then(() => navigate('/pluginconfig/gateway'))
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao alterar integração!'
            })
          }
        }
      } catch (e) {
        setLoading(false);
        console.log(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao alterar integração!'
        })
      }
    }
  }

  useEffect(() => {
    getIntegration();
  }, [])

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(`general.edit`)} ${i18n.t(`integration.means_of_payment`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'text',
                    }}
                    placeholder={i18n.t(`integration.integration_name`)}
                    label={i18n.t(`integration.integration_name`)}
                    change={(value: string) => setName(value)}
                    value={name}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`integration.type_of_integration`)}
                    change={(value: string) => { changeTemplate(parseInt(value)) }}
                    options={templates.map((template) => ({
                      value: template.id,
                      select: template.name
                    }))}
                    value={templateId}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-between align-items-center flex-wrap gap-3'>
                {configInputs.map((config) => (
                  <div style={{ flex: '40%' }} key={config?.name}>
                    <Input
                      inputAttr={{
                        type: 'text',
                      }}
                      placeholder={config?.label}
                      label={config?.label}
                      name={config?.name}
                      defaultValue={config?.value}
                      change={() => { }}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="col-6 d-flex justify-content-start">
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className="col-6  d-flex justify-content-end">
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => editIntegration()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

const EditarMeioPagamento: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`integration.means_of_payment`), path: 'pluginconfig/gateway', isActive: true },
          { title: i18n.t(`general.edit`), path: 'pluginconfig/gateway/edit', isActive: true },
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`integration.means_of_payment`)}`}
      </PageTitle>
      <EditarMeioPagamentoContent />
    </>
  )
}

export { EditarMeioPagamento }
