/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent } from 'react'
import { Datatable, MetaType } from '../../../components/Datatable/Datatable'
import { GET, POST } from '../../../services/api'
import { Loading } from '../../../components/Loading/Loading'
import { i18n } from '../../../translate/i18n'
import { Button } from '../../../components/Button/Button'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../components/Input/Input'
import { PageTitle } from '../../../../_metronic/layout/core'
import { CSVLink } from 'react-csv'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import useAuth from '../../../../app/hooks/useAuth';

type ProductType = {
  id: number
  sku: string
  name: string
  code: string
  specification: string
  haveImage: boolean
  haveImageVariation: string
  inventory: number
  price: number
  promotionalPrice: number
  productStatus: boolean
  variationStatus: boolean
}

type ProductDownloadType = {
  'Código Produto': string
  'Nome Produto': string
  'Status Produto': string
  'Imagem Produto': string
  'Categoria produto': string
  'SKU/EAN': string
  Variação: string
  'Status Variação': string
  'Imagem Variação': string
  Preço: string
  'Preço Promocional': string
  Estoque: string
}

type ProductResponseType = {
  id: number
  sku: string
  name: string
  classificationName: string
  code: string
  context: string
  imgs: string
  inventory: number
  price: number
  promotionalPrice: number
  productStatus: boolean
  variationStatus: boolean
  variationImg: string
}

type ClassificationType = {
  id: string
  name: string
}

const ProdutosRelatoriosContent: FC = () => {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<ProductType[]>([])
  const [productsDownload, setProductsDownload] = useState<ProductDownloadType[]>([])
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const [haveImage, setHaveImage] = useState(-1)
  const [haveImageVariation, setHaveImageVariation] = useState(-1)
  const [havePrice, setHavePrice] = useState(-1)
  const [productStatus, setProductStatus] = useState(-1)
  const [variationStatus, setVariationStatus] = useState(-1)
  const [haveInventory, setHaveInventory] = useState(-1)
  const [classificationId, setClassificationId] = useState(-2)
  const [classifications, setClassifications] = useState<ClassificationType[]>([])
  const [locationId, setLocationId] = useState(0)
  const [categories, setCategories] = useState<any[]>([]);
  const { loginInfo } = useAuth()

  useEffect(() => {
    getLocation()
    getClassifications()
  }, [])

  const getLocation = async () => {
    try {
      const res = await GET('location')
      console.log('Location', res)
      if (res.success && res.data && res.data.length > 0) {
        const location = res.data.find((loc: { id: number; code: string }) => loc.code === 'delivery')
        if (location) setLocationId(location.id)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getClassifications = async () => {
    setLoading(true)
    try {
      const res = await GET('classification')
      if (res.success) {
        setClassifications([
          {
            id: -2,
            name: 'Selecione uma categoria',
          },
          {
            id: -1,
            name: 'Nenhuma',
          },
          {
            id: 0,
            name: 'Todas',
          },
          ,
          ...res.data,
        ])
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getCategories = async (take: number = 10, page: number = 1, search: string = '', order: any = {field: 'name', type: 'ASC'}) => {
    try {
      const res = await GET(`/classification/categories?page=${page}&search=${search}&take=${take}&field=${order.field}&order=${order.type}`)
      const newCategories = res.data.map((row: any) => {
        return {
          id: row.id,
          name: `${row.name}`,
          status: row.status,
          code: row.code,
          parent: row.parent,
          order: row.order
        }
      })
      setCategories(newCategories)
    } catch (e) {
      console.log(e)
    }
  }

  // 'public/default/no-image.png'



  const getProducts = async () => {
    try {
      const resCat = await GET('classification')
      const res = await POST(`/product/with-variations`, {
        haveImage,
        havePrice,
        productStatus,
        variationStatus,
        haveInventory,
        classificationId: parseInt(classificationId.toString()),
        locationId,
      })  
      const variationImage = haveImageVariation > 0 ? 'Sim' : haveImageVariation < 0 ? 'Ambas' : 'Não';
      let newRes = res.data;
      if(variationImage !== 'Ambas') {
        newRes = newRes.filter((row: ProductResponseType) => {
          const checkImage = row.variationImg ? 'Sim' : 'Não';
          return checkImage === variationImage;
        })
      }
      const newProducts = newRes.map((row: ProductResponseType) => {
        let specification = `<div class='d-flex flex-column'>`
        const contextFormated = JSON.parse(row.context) 
        if (contextFormated) {
          Object.entries(contextFormated).map((item) => {
            specification += `<p>${item[0]}: ${item[1]}</p>`
          })
        } 
        specification += '</div>'
        return {
          id: row.id,
          sku: row.sku,
          name: row.name,
          code: row.code,
          specification: specification,
          haveImage: parseInt(row.imgs) > 0 ? 'Sim' : 'Não',
          haveImageVariation: row.variationImg ? 'Sim' : 'Não',
          inventory: row.inventory,
          price: row.price,
          promotionalPrice: row.promotionalPrice,
          productStatus: row.productStatus ? 'Ativado' : 'Desativado',
          variationStatus: row.variationStatus ? 'Ativado' : 'Desativado',
        }
      })
      const newProductsDownload: ProductDownloadType[] = res.data.map(
        (row: ProductResponseType) => {
          let specification = ``
          const contextFormated = JSON.parse(row.context)
          if (contextFormated) {
            Object.entries(contextFormated).map((item, index) => {
              specification += `${item[0]}: ${item[1]} ${index < Object.entries(contextFormated).length - 1 ? ' | ' : ' '
                }`
            })
          }

          const imgCatgoria = resCat.data.find((category: any) => category.name === row.classificationName) || { name: 'Categoria Geral', img: 'public/default/no-image.png' };
          
          if(loginInfo.plugintype !== 'food') {
            return {
              'Código Produto': row.code,
              'Nome Produto': row.name,
              'Status Produto': row.productStatus ? 'Ativado' : 'Desativado',
              'Imagem Produto': parseInt(row.imgs) > 0 ? 'Sim' : 'Não',
              'Categoria Produto': row.classificationName,
              'Imagem Categoria': imgCatgoria.img === 'public/default/no-image.png' ? 'Não' : 'Sim',
              'SKU/EAN': row.sku,
              Variação: specification,
              'Status Variação': row.variationStatus ? 'Ativado' : 'Desativado',
              'Imagem Variação': row.variationImg ? 'Sim' : 'Não',
              Preço: row.price,
              'Preço Promocional': row.promotionalPrice,
              Estoque: row.inventory,
            }
          } else {
            return {
              'Código Produto': row.code,
              'Nome Produto': row.name,
              'Status Produto': row.productStatus ? 'Ativado' : 'Desativado',
              'Imagem Produto': parseInt(row.imgs) > 0 ? 'Sim' : 'Não',
              'Categoria Produto': row.classificationName,
              'Imagem Categoria': imgCatgoria.img === 'public/default/no-image.png' ? 'Não' : 'Sim',
              'SKU/EAN': row.sku,
              Variação: specification,
              Preço: row.price,
              'Preço Promocional': row.promotionalPrice,
              Estoque: row.inventory,
            }
          }
        }
      )
      setProducts(newProducts)
      setProductsDownload(newProductsDownload)
      setMeta(res.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <div className='card-toolbar d-flex justify-content-between w-100'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>  {i18n.t(`smart_order.product`)}</span>
                  <BreadCrumbs />
                </h3>
                {/* <Button
                  text='Migração'
                  color='primary'
                  iconColor='#FFF'
                  icon='bi bi-arrow-left-right'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {}}
                  btnClass='mb-5 fw-bolder me-5'
                /> */}
                <CSVLink
                  data={productsDownload}
                  asyncOnClick={true}
                  filename={'products.csv'}
                  target='_blank'
                >
                  <Button
                    text={i18n.t(`buttons.export`)}
                    color='primary'
                    iconColor='#FFF'
                    // icon='fas fa-plus'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => { }}
                    btnClass='mb-5 fw-bolder'
                  />
                </CSVLink>
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex justify-content-between align-items-end flex-wrap gap-5'>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Imagem produto'
                    options={[
                      {
                        value: -1,
                        select: 'Ambas',
                      },
                      {
                        value: 0,
                        select: 'Não',
                      },
                      {
                        value: 1,
                        select: 'Sim',
                      },
                    ]}
                    value={haveImage}
                    change={(value: string) => setHaveImage(parseInt(value))}
                  />
                </div>

                {loginInfo.plugintype !== 'food' && (
                  <div className='flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      label='Imagem variação'
                      options={[
                        {
                          value: -1,
                          select: 'Ambas',
                        },
                        {
                          value: 0,
                          select: 'Não',
                        },
                        {
                          value: 1,
                          select: 'Sim',
                        },
                      ]}
                      value={haveImageVariation}
                      change={(value: string) => setHaveImageVariation(parseInt(value))}
                    />
                  </div>
                )}

                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Preço'
                    options={[
                      {
                        value: -1,
                        select: 'Ambas',
                      },
                      {
                        value: 0,
                        select: 'Não',
                      },
                      {
                        value: 1,
                        select: 'Sim',
                      },
                    ]}
                    value={havePrice}
                    change={(value: string) => setHavePrice(parseInt(value))}
                  />
                </div>

                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Status do produto'
                    options={[
                      {
                        value: -1,
                        select: 'Ambas',
                      },
                      {
                        value: 0,
                        select: 'Desativado',
                      },
                      {
                        value: 1,
                        select: 'Ativado',
                      },
                    ]}
                    value={productStatus}
                    change={(value: string) => setProductStatus(parseInt(value))}
                  />
                </div>

                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Status da variação'
                    options={[
                      {
                        value: -1,
                        select: 'Ambas',
                      },
                      {
                        value: 0,
                        select: 'Desativado',
                      },
                      {
                        value: 1,
                        select: 'Ativado',
                      },
                    ]}
                    value={variationStatus}
                    change={(value: string) => setVariationStatus(parseInt(value))}
                  />
                </div>

                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Estoque'
                    options={[
                      {
                        value: -1,
                        select: 'Ambas',
                      },
                      {
                        value: 0,
                        select: 'Sem estoque',
                      },
                      {
                        value: 1,
                        select: 'Com estoque',
                      },
                    ]}
                    value={haveInventory}
                    change={(value: string) => setHaveInventory(parseInt(value))}
                  />
                </div>

                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Categoria'
                    options={classifications.map((classification) => ({
                      value: parseInt(classification.id),
                      select: classification.name,
                    }))}
                    value={classificationId}
                    change={(value: number) => setClassificationId(value)}
                  />
                </div>

                <div className='flex-grow-1 mb-2'>
                  <Button
                    text='Filtrar'
                    color='primary'
                    size='small'
                    icon='fas fa-filter'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      setLoading(true)
                      getProducts()
                    }}
                    btnClass='fw-bolder '
                  />
                </div>
              </div>
              <Datatable
                data={products}
                headers={[
                  { key: i18n.t(`smart_order.code`), value: 'code', type: 'default' },
                  { key: i18n.t(`smart_order.name`), value: 'name', type: 'default' },
                  {
                    key: i18n.t(`smart_order.product_status`),
                    value: 'productStatus',
                    type: 'default',
                  },
                  { key: i18n.t(`smart_order.sku`), value: 'sku', type: 'default' },
                  { key: i18n.t(`smart_order.specification`), value: 'specification', type: 'html' },
                  {
                    key: i18n.t(`smart_order.status_variation`),
                    value: 'variationStatus',
                    type: 'default',
                  },
                  { key: i18n.t(`smart_order.image`), value: 'haveImage', type: 'default' },
                  ...(loginInfo.plugintype !== 'food'
                    ? [{ key: i18n.t('smart_order.image_variation'), value: 'haveImageVariation', type: 'default' }]
                    : []),
                  { key: i18n.t(`smart_order.price`), value: 'price', type: 'money' },
                  {
                    key: i18n.t(`smart_order.price_promotional`),
                    value: 'promotionalPrice',
                    type: 'money',
                  },
                  { key: i18n.t(`smart_order.inventory`), value: 'inventory', type: 'default' },
                ]}
                color='primary'
              />
            </div>
          </div>
          <ModalWrapper confirmAction={() => { }}>
            <div className='flex-grow-1'>
              <p>{i18n.t(`general.attention`)}</p>
              <p>
                Para importar produtos, a primeira linha do arquivo tem que seguir os seguintes
                padrões:
              </p>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <div
                  style={{
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  Para importar apenas produtos: categoria, nome, codigo, descricao,
                  especificacao_tecnica, status, ordem
                </div>
                <div style={{ width: '30%' }}>
                  <Button
                    text={'Modelo'}
                    color='primary'
                    iconColor='#FFF'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      window.open('/file/modelo-produtos-simples.xlsx', '')
                    }}
                    btnClass='fw-bolder me-5'
                  />
                </div>
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '1em' }}>
                <div
                  style={{
                    maxWidth: '50em',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  Para importar produtos com variações únicas: categoria, nome, codigo, descricao,
                  especificacao_tecnica, status, ordem, preco, preco_promocional, estoque
                </div>
                <div style={{ marginLeft: '1em' }}>
                  <Button
                    text={'Modelo'}
                    color='primary'
                    iconColor='#FFF'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      window.open('/file/modelo-produtos-variacao-unica.xlsx', '')
                    }}
                    btnClass='fw-bolder me-5'
                  />
                </div>
              </div>
            </div>
            <br />
            <div className='flex-grow-1 text-center'>
              <Input
                inputAttr={{
                  type: 'file',
                }}
                acceptFile={'.xlsx,.xls'}
                change={(value: BaseSyntheticEvent) => { }}
              />
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const ProdutosRelatorios: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          {
            title: i18n.t(`smart_order.product`),
            path: 'product_report',
            isActive: true,
          },
        ]}
      >
        {i18n.t(`smart_order.product`)}
      </PageTitle>
      <ProdutosRelatoriosContent />
    </>
  )
}

export { ProdutosRelatorios }
