/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid'
import { Turno } from '../../../../components/Turno/Turno'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import usePluginsSpace from '../../../../hooks/usePluginSpace'

type ShiftTypeResponse = {
  timeStart: string
  timeStart2: string
  timeEnd: string
  timeEnd2: string
  weekday: number
  locationId: number | string | undefined
  checked?: boolean
  status?: boolean | undefined
}

const PageContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const { pluginSpace } = usePluginsSpace()
  const [loading, setLoading] = useState(false)

  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [content, setContent] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [order, setOrder] = useState<number>(0)
  const [alloworder, setAlloworder] = useState<number>(0)
  const [status, setStatus] = useState<boolean>(false)
  const [image, setImage] = useState<File | string | null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | string | null>(null)

  const diasSemana = [
    i18n.t(`shifts.sunday`),
    i18n.t(`shifts.monday`),
    i18n.t(`shifts.tuesday`),
    i18n.t(`shifts.wednesday`),
    i18n.t(`shifts.thursday`),
    i18n.t(`shifts.friday`),
    i18n.t(`shifts.saturday`),
  ]
  const [diaCheck, setDiaCheck] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)

  // Domingo
  const [inicioTurno01, setInicioTurno01] = useState<string>('')
  const [fimTurno01, setFimTurno01] = useState<string>('')
  const [inicioTurno02, setInicioTurno02] = useState<string>('')
  const [fimTurno02, setFimTurno02] = useState<string>('')
  const [check0, setCheck0] = useState<boolean>(false)

  // Segunda
  const [inicioTurno11, setInicioTurno11] = useState<string>('')
  const [fimTurno11, setFimTurno11] = useState<string>('')
  const [inicioTurno12, setInicioTurno12] = useState<string>('')
  const [fimTurno12, setFimTurno12] = useState<string>('')
  const [check1, setCheck1] = useState<boolean>(false)

  // Terça
  const [inicioTurno21, setInicioTurno21] = useState<string>('')
  const [fimTurno21, setFimTurno21] = useState<string>('')
  const [inicioTurno22, setInicioTurno22] = useState<string>('')
  const [fimTurno22, setFimTurno22] = useState<string>('')
  const [check2, setCheck2] = useState<boolean>(false)

  // Quarta
  const [inicioTurno31, setInicioTurno31] = useState<string>('')
  const [fimTurno31, setFimTurno31] = useState<string>('')
  const [inicioTurno32, setInicioTurno32] = useState<string>('')
  const [fimTurno32, setFimTurno32] = useState<string>('')
  const [check3, setCheck3] = useState<boolean>(false)

  // Quinta
  const [inicioTurno41, setInicioTurno41] = useState<string>('')
  const [fimTurno41, setFimTurno41] = useState<string>('')
  const [inicioTurno42, setInicioTurno42] = useState<string>('')
  const [fimTurno42, setFimTurno42] = useState<string>('')
  const [check4, setCheck4] = useState<boolean>(false)

  // Sexta
  const [inicioTurno51, setInicioTurno51] = useState<string>('')
  const [fimTurno51, setFimTurno51] = useState<string>('')
  const [inicioTurno52, setInicioTurno52] = useState<string>('')
  const [fimTurno52, setFimTurno52] = useState<string>('')
  const [check5, setCheck5] = useState<boolean>(false)

  // Sábado
  const [inicioTurno61, setInicioTurno61] = useState<string>('')
  const [fimTurno61, setFimTurno61] = useState<string>('')
  const [inicioTurno62, setInicioTurno62] = useState<string>('')
  const [fimTurno62, setFimTurno62] = useState<string>('')
  const [check6, setCheck6] = useState<boolean>(false)

  const getLocation = async () => {
    try {
      const res = await GET(`location/${id}`)
      if (res.success) {
        setName(res.data[0].name ?? '')
        setContent(res.data[0].content ?? '')
        setCode(res.data[0].code ?? '')
        setDescription(res.data[0].description ?? '')
        setStatus(res.data[0].status ?? false)
        setAlloworder(res.data[0].alloworder ?? false)
        setOrder(res.data[0].order ?? 0)
        const image = res.data[0].img
        if (image) {
          const img =
            image && process.env.REACT_APP_AWS_S3_URL && pluginSpace
              ? `${process.env.REACT_APP_AWS_S3_URL}${pluginSpace.subdomain}/${image}`
              : process.env.REACT_APP_AWS_S3_URL + 'default/no-image.png'
          setImage(img)
          setFirstStateImage(img)
        }

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Não foi possível recuperar o setor!',
        })
        navigate(`/hospitality/configuracoes/setores/`)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const getShifts = async () => {
    try {
      setLoading(true)
      const shiftRes = await POST(`location-operation/filter`, { locationId: id })

      console.log(shiftRes)

      shiftRes.data.map((shift: ShiftTypeResponse) => {
        switch (shift.weekday) {
          case 0:
            setInicioTurno01(shift.timeStart)
            setInicioTurno02(shift.timeStart2)
            setFimTurno01(shift.timeEnd)
            setFimTurno02(shift.timeEnd2)
            setCheck0(
              !(
              shift.timeStart == '00:00:00' &&
              shift.timeEnd == '00:00:00' &&
              shift.timeStart2 == '00:00:00' &&
              shift.timeEnd2 == '00:00:00'
            )
          )
            break
          case 1:
            setInicioTurno11(shift.timeStart)
            setInicioTurno12(shift.timeStart2)
            setFimTurno11(shift.timeEnd)
            setFimTurno12(shift.timeEnd2)
            setCheck1(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 2:
            setInicioTurno21(shift.timeStart)
            setInicioTurno22(shift.timeStart2)
            setFimTurno21(shift.timeEnd)
            setFimTurno22(shift.timeEnd2)
            setCheck2(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 3:
            setInicioTurno31(shift.timeStart)
            setInicioTurno32(shift.timeStart2)
            setFimTurno31(shift.timeEnd)
            setFimTurno32(shift.timeEnd2)
            setCheck3(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 4:
            setInicioTurno41(shift.timeStart)
            setInicioTurno42(shift.timeStart2)
            setFimTurno41(shift.timeEnd)
            setFimTurno42(shift.timeEnd2)
            setCheck4(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 5:
            setInicioTurno51(shift.timeStart)
            setInicioTurno52(shift.timeStart2)
            setFimTurno51(shift.timeEnd)
            setFimTurno52(shift.timeEnd2)
            setCheck5(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
          case 6:
            setInicioTurno61(shift.timeStart)
            setInicioTurno62(shift.timeStart2)
            setFimTurno61(shift.timeEnd)
            setFimTurno62(shift.timeEnd2)
            setCheck6(
              !(
                shift.timeStart == '00:00:00' &&
                shift.timeEnd == '00:00:00' &&
                shift.timeStart2 == '00:00:00' &&
                shift.timeEnd2 == '00:00:00'
              )
            )
            break
        }
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const changeShifts = async () => {
    try {
      setLoading(true)
      let data: ShiftTypeResponse[] = []
      if (check0) {
        data.push({
          weekday: 0,
          timeStart: inicioTurno01 === '' ? '00:00:00' : inicioTurno01,
          timeStart2: inicioTurno02 === '' ? '00:00:00' : inicioTurno02,
          timeEnd: fimTurno01 === '' ? '00:00:00' : fimTurno01,
          timeEnd2: fimTurno02 === '' ? '00:00:00' : fimTurno02,
          locationId: id,
          checked: check0,
        })
      }
      if (check1) {
        data.push({
          weekday: 1,
          timeStart: inicioTurno11 === '' ? '00:00:00' : inicioTurno11,
          timeStart2: inicioTurno12 === '' ? '00:00:00' : inicioTurno12,
          timeEnd: fimTurno11 === '' ? '00:00:00' : fimTurno11,
          timeEnd2: fimTurno12 === '' ? '00:00:00' : fimTurno12,
          locationId: id,
          checked: check1,
        })
      }
      if (check2) {
        data.push({
          weekday: 2,
          timeStart: inicioTurno21 === '' ? '00:00:00' : inicioTurno21,
          timeStart2: inicioTurno22 === '' ? '00:00:00' : inicioTurno22,
          timeEnd: fimTurno21 === '' ? '00:00:00' : fimTurno21,
          timeEnd2: fimTurno22 === '' ? '00:00:00' : fimTurno22,
          locationId: id,
          checked: check2,
        })
      }
      if (check3) {
        data.push({
          weekday: 3,
          timeStart: inicioTurno31 === '' ? '00:00:00' : inicioTurno31,
          timeStart2: inicioTurno32 === '' ? '00:00:00' : inicioTurno32,
          timeEnd: fimTurno31 === '' ? '00:00:00' : fimTurno31,
          timeEnd2: fimTurno32 === '' ? '00:00:00' : fimTurno32,
          locationId: id,
          checked: check3,
        })
      }
      if (check4) {
        data.push({
          weekday: 4,
          timeStart: inicioTurno41 === '' ? '00:00:00' : inicioTurno41,
          timeStart2: inicioTurno42 === '' ? '00:00:00' : inicioTurno42,
          timeEnd: fimTurno41 === '' ? '00:00:00' : fimTurno41,
          timeEnd2: fimTurno42 === '' ? '00:00:00' : fimTurno42,
          locationId: id,
          checked: check4,
        })
      }
      if (check5) {
        data.push({
          weekday: 5,
          timeStart: inicioTurno51 === '' ? '00:00:00' : inicioTurno51,
          timeStart2: inicioTurno52 === '' ? '00:00:00' : inicioTurno52,
          timeEnd: fimTurno51 === '' ? '00:00:00' : fimTurno51,
          timeEnd2: fimTurno52 === '' ? '00:00:00' : fimTurno52,
          locationId: id,
          checked: check5,
        })
      }
      if (check6) {
        data.push({
          weekday: 6,
          timeStart: inicioTurno61 === '' ? '00:00:00' : inicioTurno61,
          timeStart2: inicioTurno62 === '' ? '00:00:00' : inicioTurno62,
          timeEnd: fimTurno61 === '' ? '00:00:00' : fimTurno61,
          timeEnd2: fimTurno62 === '' ? '00:00:00' : fimTurno62,
          locationId: id,
          checked: check6,
        })
      }
      console.log(data)
      const shiftRes = await POST('/location-operation/change-all', { data })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar turnos!',
      }).then(() => navigate('/configuracoes/turnos'))
    }
  }

  const edit = async () => {
    try {
      if (name === '' || code === '' || description === '' || content === '') {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      }

      setLoading(true)
      const data = {
        type: 'internal',
        name: name,
        code: code,
        description: description,
        content: content,
        alloworder: alloworder,
        status: status,
      }
      const res = await PUT(`/location/${id}`, data)
      console.log('res', data, res)
      if (res.success === true) {
        console.log('Sucesso', res.dados.id)
        let imageRes = {
          success: false,
          image: '',
        }
        if (image && image !== firstStateImage) {
          const nameImg = `hotel-location-${uuid()}.jpg`
          const formData = new FormData()
          formData.append('file', image)
          formData.append('nameImage', nameImg)
          imageRes = await POST('/upload-images/upload-one', formData)
          if (imageRes.success === true) {
            const data = {
              img: nameImg,
            }
            await PUT(`/location/${id}`, data)
          }
        }
      }

      await changeShifts()

      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Setor atualizado',
        text: 'Sucesso ao atualizar o setor!',
      })
      navigate('/hospitality/configuracoes/setores')
    } catch (error) {
      setLoading(false)
      console.log(error)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o setor!',
      })
    }
  }
  useEffect(() => {
    const load = async () => {
      try {
        getLocation()
        getShifts()
      } catch (error) {
        setLoading(false)
      }
    }
    load()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-content-between'>
            <div className='w-50 me-5'>
              <div className='card pb-5 mb-10'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Dados do Setor</span>
                    <BreadCrumbs />
                  </h3>
                </div>

                <div className='px-10 py-5'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Nome'
                        label='Nome'
                        value={name}
                        change={(value: string) => setName(value)}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder='Código'
                        label='Código'
                        value={code}
                        change={(value: string) => setCode(value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <Input
                        inputAttr={{
                          type: 'textarea',
                        }}
                        placeholder='Descrição'
                        label='Descrição'
                        value={description}
                        change={(value: string) => setDescription(value)}
                        rows={5}
                      />
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Pedidos (Habilitar Carrinho)'
                        options={[
                          { select: 'Ativo', value: true },
                          { select: 'Inativo', value: false },
                        ]}
                        value={alloworder}
                        change={(value: number) => {
                          setAlloworder(value)
                        }}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Status'
                        options={[
                          { select: 'Ativo', value: true },
                          { select: 'Inativo', value: false },
                        ]}
                        value={status}
                        change={(value: boolean) => {
                          setStatus(value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.order`)}
                        label={i18n.t(`smart_order.order`)}
                        smalltext='(para exibição no webapp)'
                        value={order}
                        change={(value: number) => setOrder(value)}
                      />
                    </div>
                    <div className='col-lg-6'>
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        label='Template'
                        options={[
                          { select: 'Sem template', value: '' },
                          { select: 'Restaurante', value: 'restaurant' },
                          { select: 'Quarto', value: 'room' },
                          { select: 'Piscina', value: 'pool' },
                        ]}
                        value={content}
                        change={(value: any) => {
                          setContent(value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='card pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Imagem do Setor</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  <div className='px-10 py-5'>
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      value={image}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='w-50 ms-5'>
              <div className='card pb-5 mb-10'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>Horários de Funcionamento</span>
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <Turno
                    key={diasSemana[0]}
                    dia={diasSemana[0]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno01}
                    turnoInicio2={inicioTurno02}
                    turnoFim1={fimTurno01}
                    turnoFim2={fimTurno02}
                    setTurnoInicio1={(value: string) => setInicioTurno01(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno02(value)}
                    setTurnoFim1={(value: string) => setFimTurno01(value)}
                    setTurnoFim2={(value: string) => setFimTurno02(value)}
                    setChecked={(value: boolean) => setCheck0(value)}
                    checked={check0}
                  />

                  <Turno
                    key={diasSemana[1]}
                    dia={diasSemana[1]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno11}
                    turnoInicio2={inicioTurno12}
                    turnoFim1={fimTurno11}
                    turnoFim2={fimTurno12}
                    setTurnoInicio1={(value: string) => setInicioTurno11(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno12(value)}
                    setTurnoFim1={(value: string) => setFimTurno11(value)}
                    setTurnoFim2={(value: string) => setFimTurno12(value)}
                    setChecked={(value: boolean) => setCheck1(value)}
                    checked={check1}
                  />

                  <Turno
                    key={diasSemana[2]}
                    dia={diasSemana[2]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno21}
                    turnoInicio2={inicioTurno22}
                    turnoFim1={fimTurno21}
                    turnoFim2={fimTurno22}
                    setTurnoInicio1={(value: string) => setInicioTurno21(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno22(value)}
                    setTurnoFim1={(value: string) => setFimTurno21(value)}
                    setTurnoFim2={(value: string) => setFimTurno22(value)}
                    setChecked={(value: boolean) => setCheck2(value)}
                    checked={check2}
                  />

                  <Turno
                    key={diasSemana[3]}
                    dia={diasSemana[3]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno31}
                    turnoInicio2={inicioTurno32}
                    turnoFim1={fimTurno31}
                    turnoFim2={fimTurno32}
                    setTurnoInicio1={(value: string) => setInicioTurno31(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno32(value)}
                    setTurnoFim1={(value: string) => setFimTurno31(value)}
                    setTurnoFim2={(value: string) => setFimTurno32(value)}
                    setChecked={(value: boolean) => setCheck3(value)}
                    checked={check3}
                  />

                  <Turno
                    key={diasSemana[4]}
                    dia={diasSemana[4]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno41}
                    turnoInicio2={inicioTurno42}
                    turnoFim1={fimTurno41}
                    turnoFim2={fimTurno42}
                    setTurnoInicio1={(value: string) => setInicioTurno41(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno42(value)}
                    setTurnoFim1={(value: string) => setFimTurno41(value)}
                    setTurnoFim2={(value: string) => setFimTurno42(value)}
                    setChecked={(value: boolean) => setCheck4(value)}
                    checked={check4}
                  />

                  <Turno
                    key={diasSemana[5]}
                    dia={diasSemana[5]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno51}
                    turnoInicio2={inicioTurno52}
                    turnoFim1={fimTurno51}
                    turnoFim2={fimTurno52}
                    setTurnoInicio1={(value: string) => setInicioTurno51(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno52(value)}
                    setTurnoFim1={(value: string) => setFimTurno51(value)}
                    setTurnoFim2={(value: string) => setFimTurno52(value)}
                    setChecked={(value: boolean) => setCheck5(value)}
                    checked={check5}
                  />

                  <Turno
                    key={diasSemana[6]}
                    dia={diasSemana[6]}
                    diaCheckValue={diaCheck}
                    turnoInicio1={inicioTurno61}
                    turnoInicio2={inicioTurno62}
                    turnoFim1={fimTurno61}
                    turnoFim2={fimTurno62}
                    setTurnoInicio1={(value: string) => setInicioTurno61(value)}
                    setTurnoInicio2={(value: string) => setInicioTurno62(value)}
                    setTurnoFim1={(value: string) => setFimTurno61(value)}
                    setTurnoFim2={(value: string) => setFimTurno62(value)}
                    setChecked={(value: boolean) => setCheck6(value)}
                    checked={check6}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              text='Voltar'
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/hospitality/configuracoes/setores')
              }}
              btnClass='fw-bolder me-3'
            />
            <Button
              text='Salvar'
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                edit()
              }}
              btnClass='fw-bolder'
            />
          </div>
        </div>
      )}
    </>
  )
}

const HospitalityEditarSetor: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Setores', path: 'hospitality/configuracoes/setores', isActive: true },
          { title: 'Editar', path: '#', isActive: true },
        ]}
      >
        Criar Setor
      </PageTitle>
      <PageContent />
    </>
  )
}

export { HospitalityEditarSetor }
