/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {Loading} from '../../../../components/Loading/Loading'
import {PUT, GET} from '../../../../services/api'
import {toDate} from '../../../../utils/formater'
import moment from 'moment'
import {TypePredicateKind} from 'typescript'
import {setMaxListeners} from 'process'
import {useNavigate} from 'react-router-dom'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const SmsContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState<any[]>([])

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      await PUT(`/status/${Id}`, {
        activeSms: !status,
      })
      await getStatus(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const switchStatusWeb = async (Id: number, status: boolean) => {
    try {
      await PUT(`/status/${Id}`, {
        displayStatusWebapp: !status,
      })
      await getStatus(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getStatus = async (loading = true) => {
    try {
      setLoading(loading)
      const resStatus = (await GET('/status')).data
      const list = resStatus.map((row: any) => {
        return {
          id: row.id,
          order: row.order,
          status: row.activeSms,
          name: row.name,
          messageStatus: row.messageStatus,
          statusWeb: row.displayStatusWebapp,
        }
      })
      setList(list)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getStatus()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>SMS</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`sms.listened_sms`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <Datatable
                data={list}
                headers={[
                  {key: i18n.t(`sms.order`), value: 'order', type: 'default'},
                  {key: i18n.t(`sms.name`), value: 'name', type: 'default'},
                  {key: i18n.t(`sms.message`), value: 'messageStatus', type: 'default'},
                  {
                    key: `${i18n.t(`general.active`)} WebApp `,
                    value: 'statusWeb',
                    type: 'check',
                    action: (item) => {
                      switchStatusWeb(item.id, item.statusWeb)
                    },
                  },
                  // {
                  //   key: `${i18n.t(`general.active`)} SMS`,
                  //   value: 'status',
                  //   type: 'check',
                  //   action: (item) => {
                  //     switchStatus(item.id, item.status)
                  //   },
                  // },
                  {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      navigate(`/message/edit/${item.id}`)
                    },
                    title: `${i18n.t(`general.edit`)} SMS`,
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const Sms: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`sms.message`), path: 'message', isActive: true},
        ]}
      >
        SMS
      </PageTitle>
      <SmsContent />
    </>
  )
}

export {Sms}
