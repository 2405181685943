import { FC, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import style from './tooltip.module.css'

type Props = {
    text: string
    icon?: any
    link?: string
}


const renderTooltip = (props: any) => (

    <Tooltip id="button-tooltip" className={style.mytooltip}>
        <span>
            {props.text}
            {props.link && (
                <a href={props.link} target="_blank">{props.link}</a>
            )}
        </span>
    </Tooltip>
);



const MyTooltip: FC<Props> = (Props) => {

    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const {
        text,
        icon,
        link
    } = Props
    return (
        <div className='d-flex align-items-end mx-2'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >

            <OverlayTrigger
                placement="right"
                overlay={renderTooltip({ text, link })}
                trigger={['hover', 'focus']}
                delay={{ show: 0, hide: 0 }}
                show={showTooltip}

            >
                {icon ? (

                    <i
                        className={`bi bi-${icon} fs-4 text-black`}
                    ></i>

                ) :

                    (
                        <span>Hover me to see</span>
                    )
                }

            </OverlayTrigger >
        </div>

    )
};


export { MyTooltip }