import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import Swal from 'sweetalert2'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { Input } from '../../../components/Input/Input'
import moment from 'moment'
import { Button } from '../../../components/Button/Button'
import Chart from 'react-apexcharts'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type BookingStatusType = {
  status: string;
  quantity: number;
  percentage: string;
}

type BookingDayResponseType = {
  hour: string;
  quantity: string;
  day: string;
}

type BookingByDayChart = {
  name: string;
  data: { x: string; y: number }[]
}

const BookingReportContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [bookingsByStatus, setBookingsByStatus] = useState<BookingStatusType[]>([])
  const [bookingsByDay, setBookingsByDay] = useState<BookingByDayChart[]>([])

  const generateHeatMapByDays = (dayItems: BookingDayResponseType[]) => {
    const newBookingDays: BookingByDayChart[] = [0, 3, 6, 12, 15, 18, 21].map((hourItem) => ({
      name: hourItem < 10 ? `0${hourItem}:00 - ${hourItem < 7 ? `0${hourItem + 3}:00` : `${hourItem + 3}:00`}` : `${hourItem}:00 - ${hourItem < 7 ? `0${hourItem + 3}:00` : `${hourItem + 3}:00`}`,
      data: ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'].map((dayItem) => {
        const dayFind = dayItems.find((day) => (day.day === dayItem && (parseInt(day.hour.split(":")[0]) >= hourItem && parseInt(day.hour.split(":")[0]) < hourItem + 3)))
        if (dayFind) {
          return {
            x: i18n.t(`week_day.${dayItem}`),
            y: parseInt(dayFind.quantity)
          }
        }
        return {
          x: i18n.t(`week_day.${dayItem}`),
          y: 0
        }

      })
    }))
    setBookingsByDay(newBookingDays)
  }

  const getBookings = async (dateIn?: string, dateOut?: string) => {
    try {
      const res = await GET('/queueReservation/bookingConfig')
      if (res.success && res.data && res.data[0] && res.data[0].id && res.data[0].expiryTime) {
        const bookingConfigIdResponse = res.data[0].id;

        const statusResponse = await POST('/queueReservation/booking/report-status', {
          initialDate: dateIn ? dateIn : startDate,
          finalDate: dateOut ? dateOut : finalDate,
          bookingConfigId: bookingConfigIdResponse
        })
        if (statusResponse && statusResponse.data && statusResponse.data.length > 0) {
          setBookingsByStatus(statusResponse.data)
        } else {
          setBookingsByStatus([])
        }
        const dayResponse = await POST('/queueReservation/booking/report-days', {
          initialDate: dateIn ? dateIn : startDate,
          finalDate: dateOut ? dateOut : finalDate,
          bookingConfigId: bookingConfigIdResponse
        })
        if (dayResponse && dayResponse.data && dayResponse.data.length > 0) {
          generateHeatMapByDays(dayResponse.data)
        } else {
          setBookingsByDay([]);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Não foi possível encontrar configuração de reserva',
        }).then(() => navigate('/booking/config'))
      }

    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao encontrar reservas, verifique as configurações.',
      }).then(() => navigate('/booking/config'))
    }
  }

  const selectTime = async (time: string) => {
    switch (time) {
      case 'week':
        const nowDate = new Date();
        const dayWeek = nowDate.getDay();
        const initialDateWeek = new Date();
        initialDateWeek.setDate(nowDate.getDate() - dayWeek);
        const finalDateWeek = new Date();
        finalDateWeek.setDate(nowDate.getDate() + (6 - dayWeek));
        setStartDate(initialDateWeek.toISOString().split("T")[0]);
        setFinalDate(finalDateWeek.toISOString().split("T")[0]);
        await init(initialDateWeek.toISOString().split("T")[0], finalDateWeek.toISOString().split("T")[0]);
        break;
      case 'month':
        const nowMonth = (new Date().getMonth()) + 1;
        const nowYear = new Date().getFullYear();
        const initialDateMonth = `${nowYear}-${nowMonth < 10 ? `0${nowMonth}` : nowMonth}-01`;
        let finalDateMonth = `${nowYear}-${nowMonth + 1 < 10 ? `0${nowMonth + 1}` : nowMonth + 1}-01`;
        if (nowMonth >= 12) {
          finalDateMonth = `${nowYear + 1}-01-01`;
        }
        setStartDate(initialDateMonth);
        setFinalDate(finalDateMonth);
        await init(initialDateMonth, finalDateMonth);
        break;
      case 'year':
        const year = new Date().getFullYear()
        const initialDateYear = `${year}-01-01`;
        const finalDateYear = `${year + 1}-01-01`;
        setStartDate(initialDateYear);
        setFinalDate(finalDateYear);
        await init(initialDateYear, finalDateYear);
        break;
      default:
        await init();
    }
  }

  const init = async (dateIn?: string, dateOut?: string) => {
    setLoading(true)
    await getBookings(dateIn, dateOut);
    setLoading(false)
  }


  useEffect(() => {
    init();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='d-flex flex-row gap-5 flex-wrap '>
          <div className="card w-100 d-flex flex-column">
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`booking_report.report`)}</span>
                <BreadCrumbs />
              </h3>

            </div>
            <div className="d-flex flex-row justify-content-between align-items-center px-4 py-2" style={{ borderBottom: '1px solid #B5B5C3' }}>
              <p style={{ color: '#434349', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report.time`)}</p>
              <div className="d-flex flex-row gap-6 flex-wrap">
                <Button
                  color='primary'
                  text={i18n.t(`booking.week`)}
                  click={() => selectTime('week')}
                  btnClass='h-45px mt-6'
                />
                <Button
                  color='primary'
                  text={i18n.t(`booking.month`)}
                  click={() => selectTime('month')}
                  btnClass='h-45px mt-6'
                />
                <Button
                  color='primary'
                  text={i18n.t(`booking.year`)}
                  click={() => selectTime('year')}
                  btnClass='h-45px mt-6'
                />
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between align-items-center px-4 py-2">
              <p style={{ color: '#434349', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report.search`)}</p>
              <div className="d-flex flex-row gap-6 flex-wrap">
                <div className="d-flex flex-row gap-2 align-items-center mb-6">
                  <p className='mt-6' style={{ color: '#71718B', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report.date_start`)}</p>
                  <Input
                    inputAttr={{ type: 'date' }}
                    value={startDate}
                    change={setStartDate}
                    maxLength={finalDate}
                  />
                </div>
                <div className="d-flex flex-row gap-2 align-items-center mb-6">
                  <p className='mt-6' style={{ color: '#71718B', fontSize: 14, margin: 0 }}>{i18n.t(`booking_report.date_end`)}</p>
                  <Input
                    inputAttr={{ type: 'date' }}
                    value={finalDate}
                    change={setFinalDate}
                    minLength={startDate}
                  />
                </div>
                <Button
                  color='primary'
                  text={i18n.t(`buttons.search`)}
                  click={() => init()}
                  btnClass='h-45px mt-6'
                />
              </div>
            </div>
          </div>
          {bookingsByStatus.length > 0 && (
            <div className='card' style={{ minWidth: 450 }}>
              <div className='card-body d-flex flex-column gap-5'>
                <h2 style={{ fontWeight: 400, fontSize: 16 }}>{i18n.t(`booking_report.confirm`)}</h2>
                <Chart
                  options={{
                    labels: bookingsByStatus.map((booking) =>
                      booking.status ? i18n.t(`booking_report.${booking.status}`) : i18n.t(`booking_report.open`)
                    ),
                    colors: ['#9BA1B1', '#F8E687', '#5D8ED8', '#83C5BE']
                  }}
                  series={bookingsByStatus.map((booking) => booking.quantity)}
                  type='donut'
                />
              </div>
            </div>
          )}

          <div className='card' style={{ flex: 1 }}>
            <div className='card-body d-flex flex-column gap-5'>
              <h2 style={{ fontWeight: 400, fontSize: 16 }}>{i18n.t(`booking_report.map`)}</h2>
              <Chart
                options={{
                  dataLabels: {
                    enabled: true
                  },
                  colors: ["#008FFB"],

                }}

                series={bookingsByDay}
                type='heatmap'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const BookingReport: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`booking_report.report`), path: 'booking/report', isActive: true },
        ]}
      >
        {i18n.t(`booking_report.report`)}
      </PageTitle>
      <BookingReportContent />
    </>
  )
}

export { BookingReport }
