/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {AuthPage} from '../pages/Commons/Auth/AuthPage'
import {App} from '../App'
import useAuth from '../hooks/useAuth'
import { RedirectPage } from '../pages/Commons/Pagseguro/Redirect'
import { ResetPassword } from '../pages/Commons/Auth/reset-password'
import pt from 'date-fns/locale/pt-BR';
import ptBR from 'rsuite/locales/pt_BR';
import { CustomProvider } from 'rsuite'


/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {loginInfo} = useAuth()

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <CustomProvider locale={ptBR}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />

            {loginInfo.accessToken ? (
              <>
                <Route path='redirect/*' element={<RedirectPage />} />
                <Route path='/*' element={<PrivateRoutes />} />
                <Route index element={<Navigate to='/dashboard' />} />
              </>
            ) : (
              <>
                <Route path='redirect/*' element={<RedirectPage />} />
                <Route path='reset_password/:info' element={<ResetPassword />} />
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </CustomProvider>
    </BrowserRouter>
  )
}

export {AppRoutes}
