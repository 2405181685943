/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, MouseEvent, useEffect, useState, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import { formatCpfCnpj, formatPhone } from '../../../utils/formater'
import moment from 'moment'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import uuid from 'react-uuid'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'

type Form = {
    order: number,
    name: string,
    id: number,
    path: string,
    imgSuffix: string,
    typeImg: string,
    startDate: string,
    endDate: string, 
    status: boolean,
    classificationId: string,
    date: string,
}

const ContaContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [lojasPubli, setLojasPubli] = useState<any[]>([])

  const [activeTab, setActiveTab] = useState('#tab1')
  const [thema, setThema] = useState<any[]>([]);
  const [desc, setDesc] = useState<any>('');
  const [idDesc, setIdDesc] = useState<any>();
  const [alteraConta, setAlteraConta] = useState(0);
  const [alteraDes, setAlteraDes] = useState(0);

  const [id, setId] = useState(0)
  const [image, setImage] = useState<File | null>(null)
  const [imageBackUp,setImageBackUp] = useState();

  const getPluginspace = async () => {
    try {
      setLoading(true)
      setLojasPubli([])
      const res = await POST(`/publication-aggregator/filter`, {
        plugintypeId: getPlugintypeIdByCode('service'),
        deleted: 'not show',
      })
      const lojasPubli = res.data;
      const lojasPubliFilter = lojasPubli.map((loja: any) => {

        const [year, month, day] = loja.startDate.split('T')[0].split('-');
        const formattedDateStart = `${day}/${month}/${year}`;

        const [yearEnd, monthEnd, dayEnd] = loja.endDate.split('T')[0].split('-');
        const formattedDateEnd = `${dayEnd}/${monthEnd}/${yearEnd}`;

        return {
          order: loja.order,
          name: loja.store.name,
          id: loja.id,
          img: loja.image.path + loja.image.imgSuffix,
          typeImg: loja.image.typeImg,
          startDate: loja.startDate,
          endDate: loja.endDate, 
          status: loja.status,
          classificationId: loja.classificationId,
          date: `De ${formattedDateStart} a ${formattedDateEnd}`,
        }
      })

      setLojasPubli(lojasPubliFilter);
      
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deletePubli = async (id: number) => {
    if (!id) {
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a categoria!',
      }).then(async () => await init())
    } else {
      try {
        setLoading(true)
        const res = await DELETE(`publication-aggregator/${id}`)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Categoria deletada',
            text: 'Deleção realizada com sucesso!',
          }).then(async () => await init())
        } else {
          console.log(res)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao deletar a categoria!',
          }).then(async () => await init())
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a categoria!',
        }).then(async () => await init())
        console.log(e)
      }
    }
  }
  
  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }


  async function saveImage(image1: any, imageBk: any) {
    try {
      if (image1 && image1 !== imageBk) {
        if (imageBk) {
          deleteImage(imageBk)
        }
        const formData = new FormData()
        formData.append('file', image1)
        formData.append('nameImage', `logo-marca-${uuid()}.jpg`)
        formData.append('originalSize', 'true')
        const imageRes = await POST('/upload-images/upload-one', formData)
        return imageRes.success ? imageRes.image : ''
      }
      return image1
    } catch (error) {
      return ''
    }
  }

  // const update = async () => {
  //   try {
  //     setLoading(true)
  //     let newForm = Object.assign(form)
  //     newForm.cnpj = formatCpfCnpj(newForm.cnpj)
  //     newForm.zipcode = newForm.zipcode.replace('-', '')
  //     newForm.image = await saveImage(image, imageBackUp)
  //     if (id) {
  //       const res = await PUT(`/pluginspace/ninegrid/${id}`, newForm)
  //       setLoading(false)
  //       if (res.success) {
  //         Swal.fire({
  //           icon: 'success',
  //           title: 'Conta alterada!',
  //           text: 'Sua conta foi editada com sucesso!',
  //         })
  //       } else {
  //         Swal.fire({
  //           icon: res.typemsg ? res.typemsg : 'error',
  //           title: (res.typemsg && res.typemsg === 'warning') ? 'Atenção!' : 'Erro',
  //           text: res.message ? res.message : 'Não foi possível alterar a conta!'
  //         })
  //       }
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Erro!',
  //         text: 'Não foi possível alterar a conta!',
  //       })
  //     }
  //   } catch (e: any) {
  //     setLoading(false)
  //     Swal.fire({
  //       icon: (typeof e === 'string' && e.includes('alterada')) ? 'warning' : 'error',
  //       title: (typeof e === 'string' && e.includes('alterada')) ? 'Atenção' : 'Erro',
  //       text: (e.response && e.response.message && e.response.message[0]) ?
  //         e.response.message[0] :
  //         (typeof e === 'string' ? e : 'Não foi possível alterar a conta!')
  //     })
  //   }
  // }
 

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    setLoading(true)
    await getPluginspace()
    setLoading(false)
  }

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchClassification = await PUT(`publication-aggregator/${Id}`, {
        status: !status,
      })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Publicidade</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui você configura e publica os bannes em Destaque do seu Agregador.
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/agregador/publicidade/create')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={lojasPubli}
                headers={[
                  { key: 'Imagem', value: 'img', type: 'image', noOrder: true },
                  { key: 'Ordem', value: 'order', type: 'default' },
                  { key: 'Loja', value: 'name', type: 'default' },
                  { key: 'Data da publicação', value: 'date', type: 'default' },
                  // { key: 'Descrição', value: 'description', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: 'Ações', value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    title: 'Editar categoria',
                    action: (item) => {
                      navigate(`/agregador/publicidade/editar/${item.id}`)
                    },
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      deletePubli(item.id)
                    },
                    path: '',
                    title: 'Excluir categoria',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const PublicidadeAgregador: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`Publicidade Agregador`), path: '/agregador/publicidade', isActive: true },
        ]}
      >
        {i18n.t(`Publicidade Agregador`)}
      </PageTitle>
      <ContaContent />
    </>
  )
}

export { PublicidadeAgregador }
