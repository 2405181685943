import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { POST } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const CriarTaxaEntregaContent: FC = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [minKm, setMinKm] = useState<number | null>();
  const [maxKm, setMaxKm] = useState<number | null>();
  const [value, setValue] = useState(0);

  const createDelivery = async () => {
    try {
      if ((!minKm && minKm !== 0) || !maxKm) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const deliveryRes = await POST('/delivery', {
          minKm: minKm,
          maxKm: maxKm,
          value: value
        })
        setLoading(false);
        if (deliveryRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Taxa de entrega cadastrada',
            text: 'Cadastro realizado com sucesso!'
          }).then(() => navigate('/configuracoes/taxa-entrega/raio'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar taxa de entrega!'
          })
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar taxa de entrega!'
      })
    }
  }

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`delivery_fee.create_delivery_fee`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`delivery_fee.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    placeholder={i18n.t(`delivery_fee.km_min`)}
                    label={i18n.t(`delivery_fee.km_min`)}
                    minNumber={0}
                    change={(value: string) => setMinKm(parseInt(value.replace(",", ".")))}
                    value={minKm}
                  />
                </div>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    placeholder={i18n.t(`delivery_fee.km_max`)}
                    label={i18n.t(`delivery_fee.km_max`)}
                    minNumber={0}
                    change={(value: string) => setMaxKm(parseInt(value.replace(",", ".")))}
                    value={maxKm}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'money',
                    }}
                    placeholder={i18n.t(`delivery_fee.value`)}
                    label={i18n.t(`delivery_fee.value`)}
                    currency='BRL'
                    locale='pt-BR'
                    change={(value: number) => setValue(value)}
                    value={value}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="col-6 d-flex justify-content-start">
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className="col-6  d-flex justify-content-end">
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => createDelivery()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

const CriarTaxaEntrega: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`delivery_fee.delivery_fee`), path: 'configuracoes/taxa-entrega/raio', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'configuracoes/taxa-entrega/raio/criar', isActive: true },
        ]}
      >
        {i18n.t(`delivery_fee.create_delivery_fee`)}
      </PageTitle>
      <CriarTaxaEntregaContent />
    </>
  )
}

export { CriarTaxaEntrega }
