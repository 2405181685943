import { FC, useEffect, useState } from "react"
import { Button } from "../Button/Button"
import { Input } from "../Input/Input"
import { LittleButton } from "../Button/LittleButton"


type Props = {
  next: () => void
  back: () => void
  data: any
  setData: (value: any) => any
}

type RedeSocial = {
  status:boolean
  link:string
  type:string
}




const LinksLoja: FC<Props> = ({ next, back, data, setData }) => {
  const [redesSociais,setRedesSociais] = useState<RedeSocial[]>([])
  //Campos dos Links
  const [facebookLink,setFacebookLink] = useState('')
  const [facebookStatus,setFacebookStatus] = useState()

  const [instagramLink,setInstagramLink] = useState('')
  const [instagramStatus,setInstagramStatus] = useState()

  const [whatsappLink,setWhatsappLink] = useState('')
  const [whatsappStatus,setWhatsappStatus] = useState()

  const [linkedinLink,setLinkedinLink] = useState('')
  const [linkedinStatus,setLinkedinStatus] = useState()

  const [youtubeLink,setYoutubeLink] = useState('')
  const [youtubeStatus,setYoutubeStatus] = useState()

  const [googlemapsLink,setGoogleMapsLink] = useState('')
  const [googlemapsStatus,setGoogleMapsStatus] = useState()


  function nextPage() {
    const newData = data
    newData.config.redesSociais = redesSociais;
  
    setData(newData);
    next()

  }

  function backPage(){
    const newData = data
    newData.config.redesSociais = redesSociais;
  
    setData(newData);
    back()
  }

  function changeArray(type:string,field:string,value:any){
    const newRede = redesSociais.map((rede:any)=>{
      
      if(rede.type === type){
        rede[field] = value
      }
      return rede
    })

    setRedesSociais(newRede)
  }
  

  function showLinks(){
    if(data.config.redesSociais){
      setRedesSociais(data.config.redesSociais)
     
      setFacebookLink( data.config.redesSociais.find((el:any)=> el.type === 'facebook').link)
      setFacebookStatus( data.config.redesSociais.find((el:any)=> el.type === 'facebook').status)

      setInstagramLink( data.config.redesSociais.find((el:any)=> el.type === 'instagram').link)
      setInstagramStatus( data.config.redesSociais.find((el:any)=> el.type === 'instagram').status)

      setWhatsappLink( data.config.redesSociais.find((el:any)=> el.type === 'whatsapp').link)
      setWhatsappStatus( data.config.redesSociais.find((el:any)=> el.type === 'whatsapp').status)

      setLinkedinLink( data.config.redesSociais.find((el:any)=> el.type === 'linkedin').link)
      setLinkedinStatus( data.config.redesSociais.find((el:any)=> el.type === 'linkedin').status)

      setYoutubeLink( data.config.redesSociais.find((el:any)=> el.type === 'youtube').link)
      setYoutubeStatus( data.config.redesSociais.find((el:any)=> el.type === 'youtube').status)

      setGoogleMapsLink( data.config.redesSociais.find((el:any)=> el.type === 'googlemaps').link)
      setGoogleMapsStatus( data.config.redesSociais.find((el:any)=> el.type === 'googlemaps').status)

    }else{
      setRedesSociais([
        {
          status:false,
          link:'',
          type:'facebook'
        },
        {
          status:false,
          link:'',
          type:'instagram'
        },
        {
          status:false,
          link:'',
          type:'whatsapp'
        },
        {
          status:false,
          link:'',
          type:'linkedin'
        },
        {
          status:false,
          link:'',
          type:'youtube'
        },
        {
          status:false,
          link:'',
          type:'googlemaps'
        },
      ])
    }
  }

  useEffect(()=>{
    showLinks()
  },[])

  return (
    <div className="card mt-5" style={{ padding: '2rem 5rem', gap: '44px' }}>
      <div>
        <h3>Links da Loja Externa</h3>
        <span>Aqui você configura Links e Redes sociais da loja Externa.</span>
      </div>
      <div className="d-flex flex-column " style={{ gap: '72px' }}>
        <div className="row " style={{ marginTop: '14px' }}>
          <div className="col d-flex" style={{ gap: '12px' }}>
            <div className="d-flex justify-content-center align-items-start mt-3">
              <i className="bi bi-facebook " style={{
                fontSize: '30px'
              }}></i>
            </div>
            <div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label="Facebook"
                value={facebookStatus}
                checked={facebookStatus}
                change={(value: any) => {
                setFacebookStatus(value)
                changeArray('facebook','status',value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'link',
                }}
                value={facebookLink}
                change={(value: any) => {
                  changeArray('facebook','link',value)
                  setFacebookLink(value)
                }}
              />
            </div>
          </div>
          <div className="col d-flex" style={{ gap: '12px' }}>
            <div className="d-flex justify-content-center align-items-start mt-3">
              <i className="bi bi-instagram " style={{
                fontSize: '30px'
              }}></i>
            </div>
            <div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label="Instagram"
                value={instagramStatus}
                checked={instagramStatus}
                change={(value: any) => {
                  changeArray('instagram','status',value)
                 setInstagramStatus(value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'link',
                }}
                value={instagramLink}
                change={(value: any) => {
                  changeArray('instagram','link',value)
                  setInstagramLink(value)
                }}
              />
            </div>
          </div>
          <div className="col d-flex" style={{ gap: '12px' }}>
            <div className="d-flex justify-content-center align-items-start mt-3">
              <i className="bi bi-whatsapp " style={{
                fontSize: '30px'
              }}></i>
            </div>
            <div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label="WhatsApp"
                value={whatsappStatus}
                checked={whatsappStatus}
                change={(value: any) => {
                  changeArray('whatsapp','status',value)
                  setWhatsappStatus(value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'link',
                }}
                value={whatsappLink}
                change={(value: any) => {
                  changeArray('whatsapp','link',value)
                 setWhatsappLink(value)
                }}
              />
            </div>
          </div>
        </div>
        <div className="row " style={{ marginTop: '14px' }}>
          <div className="col d-flex" style={{ gap: '12px' }}>
            <div className="d-flex justify-content-center align-items-start mt-3">
              <i className="bi bi-linkedin " style={{
                fontSize: '30px'
              }}></i>
            </div>
            <div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label="Linkedin"
                value={linkedinStatus}
                checked={linkedinStatus}
                change={(value: any) => {
                  changeArray('linkedin','status',value)
                  setLinkedinStatus(value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'link',
                }}
                value={linkedinLink}
                change={(value: any) => {
                  changeArray('linkedin','link',value)
                  setLinkedinLink(value)
                }}
              />
            </div>
          </div>
          <div className="col d-flex" style={{ gap: '12px' }}>
            <div className="d-flex justify-content-center align-items-start mt-3">
              <i className="bi bi-youtube " style={{
                fontSize: '30px'
              }}></i>
            </div>
            <div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label="Youtube"
                value={youtubeStatus}
                checked={youtubeStatus}
                change={(value: any) => {
                  changeArray('youtube','status',value)
                  setYoutubeStatus(value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'link',
                }}
                value={youtubeLink}
                change={(value: any) => {
                  changeArray('youtube','link',value)
                  setYoutubeLink(value)
                }}
              />
            </div>
          </div>
          <div className="col d-flex" style={{ gap: '12px' }}>
            <div className="d-flex justify-content-center align-items-start mt-3">
           
            {mapsIcon}
            </div>
            <div>
              <Input
                inputAttr={{
                  type: 'checkbox',
                }}
                label="GoogleMaps"
                value={googlemapsStatus}
                checked={googlemapsStatus}
                change={(value: any) => {
                  changeArray('googlemaps','status',value)
                 setGoogleMapsStatus(value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'link',
                }}
                value={googlemapsLink}
                change={(value: any) => {
                  changeArray('googlemaps','link',value)
                  setGoogleMapsLink(value)
                }}
              />
            </div>
          </div>
        </div>

        <div className="row " style={{ marginTop: '14px' }}>
          <div className="d-flex  gap-5 justify-content-end mt-5">
            <LittleButton text={"voltar"} color={"info"} click={() => backPage()}   width="110px"  height="35px"/> 
            <LittleButton text={"Salvar"} color={"primary"} click={() => nextPage()}   width="110px"  height="35px"/>
          </div>
        </div>

      </div>

    </div>
  )


}

export { LinksLoja }



const mapsIcon =    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.3277 7.55666C19.3277 6.6771 19.6346 5.92493 20.2546 5.30015C20.8745 4.68143 21.6209 4.37207 22.4936 4.37207C23.3664 4.37207 24.1127 4.68143 24.7267 5.30015C25.3466 5.92493 25.6596 6.6771 25.6596 7.55666C25.6596 8.43621 25.3466 9.18838 24.7267 9.81316C24.1127 10.4319 23.3664 10.7473 22.4936 10.7412C21.6209 10.7412 20.8745 10.4319 20.2546 9.81316C19.6346 9.18838 19.3277 8.43621 19.3277 7.55666Z" stroke="#677484" stroke-miterlimit="10" />
                <path d="M29 7.55721C29 11.1785 22.6801 18.5425 22.4935 18.3605C22.3069 18.1786 15.9871 11.1785 15.9871 7.55721C15.9871 3.93588 18.9002 1 22.4935 1C26.0868 1 29 3.93588 29 7.55721Z" stroke="#677484" stroke-miterlimit="10" />
                <path d="M27.1222 12.7612C27.3328 13.7014 27.4412 14.678 27.4412 15.6789C27.4412 23.0368 21.5246 28.9995 14.2236 28.9995C6.92263 28.9995 1 23.0428 1 15.6849C1 8.32701 6.91661 2.36426 14.2176 2.36426C15.5538 2.36426 16.8479 2.56443 18.0637 2.93445" stroke="#677484" stroke-miterlimit="10" />
                <path d="M2.13158 21.2601L15.8547 7.42383" stroke="#677484" stroke-miterlimit="10" />
                <path d="M10.0704 13.4346L22.3671 25.8272" stroke="#677484" stroke-miterlimit="10" />
              </svg>