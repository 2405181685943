/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, BaseSyntheticEvent } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, PUT, POST, DELETE } from '../../../../services/api'
import useModals from '../../../../hooks/useModals'
import { Loading } from '../../../../components/Loading/Loading'
import { Dropdown } from 'react-bootstrap'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type PostType = {
  id: number
  title: string
  summary: string
  content: string
  img: string
  status: boolean
  classification: any
}


type categories = {
  value: string
  select: string
}

const Content: FC = () => {
  const navigate = useNavigate()
  const { showDeleteModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const [filterCategoria, setFilterCategoria] = useState<any>({
    label: 'Todos',
    value: 0,
  })
  const [categoria, setCategoria] = useState<categories[]>([])


  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchClassification = await PUT(`/post/${Id}`, {
        status: !status,
      })
      if (switchClassification.success) {
        const newCategories = categories.map((category) => {
          if (category.id === id) {
            category.status = !status
          }
          return category
        })
        setCategories(newCategories)
      }
    } catch (e) {
      console.log(e)
    }
  }

  let filterData = (array: any, search: string, searchFields: any[]) => {
    const arrayCopy = JSON.parse(JSON.stringify(array))

    return arrayCopy.filter((item: any) => {
      let found = searchFields.some((field) => {
        return item[field].toLowerCase().indexOf(search.toLowerCase()) > -1
      })
      return found
    })
  }

  const getList = async (search: string = '') => {
    try {
      setLoading(true)
      const res = await POST(`/post/filter`, {
        classificationId: filterCategoria.value
      })
      if (Object.getOwnPropertyDescriptor(res, 'data') === undefined) {
        return false;
      }
      const newCategories = res.data.map((row: PostType) => {
        return {
          id: row.id,
          title: `${row.title}`,
          summary: row.summary,
          content: row.content,
          status: row.status,
          img: row.img,
          classification: row.classification.name,
        }
      })
      const filterCategories = filterData(newCategories, search, ['title'])
      setCategories(
        filterCategories.sort((a: PostType, b: PostType) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
      )

      setMeta(res.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)

      console.log(e)
    }
  }
  const getCategories = async () => {
    try {

      const resCategorias = await POST(`/classification/filter`, {
        status: true,
        /*O Campo abaixo só é utilizando para o Hotel, 
        nessa rota a troca do pluginType dele ocorre usando esse campo 
        */
        plugintypeId: 10
      })

      const categories = resCategorias.data.map((el: any) => {
        return {
          value: el.id,
          select: el.name,
        }
      })

      categories.push({
        select: 'TODOS',
        value: 0,
      })

      categories.sort((a: any, b: any) =>
        a.select.toUpperCase() > b.select.toUpperCase()
          ? 1
          : a.select.toUpperCase() < b.select.toUpperCase()
            ? -1
            : 0
      )

      setCategoria(categories)

    } catch (error) {
      console.log(error)
    }
  }

  async function getContent() {
    try {
      const res = await POST(`/post/filter`, {
        classificationId: filterCategoria.value
      })
      if (Object.getOwnPropertyDescriptor(res, 'data') === undefined) {
        return false;
      }
      const newCategories = res.data.map((row: PostType) => {
        return {
          id: row.id,
          title: `${row.title}`,
          summary: row.summary,
          content: row.content,
          status: row.status,
          img: row.img,
          classification: row.classification.name,
        }
      })
      setCategories(
        newCategories.sort((a: PostType, b: PostType) =>
          a.title > b.title ? 1 : b.title > a.title ? -1 : 0
        )
      )

      setMeta(res.meta)

    } catch (e) {

      console.log(e)
    }
  }



  async function getAllData() {
    setLoading(true)

    await Promise.all([
      getContent(),
      getCategories()
    ])

    setLoading(false)
  }

  useEffect(() => {
    getAllData()
  }, [])

  async function deletePost() {
    try {
      const response = await DELETE(`/post/${id}`)

      if (!response || !response.success) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao deletar',
          text: 'Falha ao deletar conteudo!'
        }).then(async () => await getList())
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Conteudo deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getList())
      }


    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao deletar',
        text: 'Falha ao deletar conteudo!'
      }).then(async () => await getList())
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Conteúdos</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listadas os conteúdos dos Feeds.
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar Conteúdo'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/hospitality/content/content/create')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='d-flex w-25 '>
                  <div className=' align-items-end  justify-content-start me-5 flex-grow-1'>
                    <span className='align-self-start'>Categorias</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={categoria}
                      placeholder={'Categoria'}
                      search={true}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                  </div>
                </div>

                <div
                  className='d-flex align-items-end w-100 justify-content-end h-100'
                  style={{
                    marginTop: '48px',
                  }}
                >
                  <Button
                    text='Filtrar'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-filter'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => getList()}
                    btnClass='mb-0 fw-bolder'
                  />
                </div>
              </div>
              <Datatable
                data={categories}
                headers={[
                  { key: 'Imagem', value: 'img', type: 'image' },
                  { key: 'Categoria', value: 'classification', type: 'default' },
                  { key: 'Título', value: 'title', type: 'default' },
                  { key: 'Conteúdo', value: 'content', type: 'html' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: 'Ações', value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    title: 'Editar categoria',
                    action: (item) => {
                      navigate(`/hospitality/content/content/editar/${item.id}`)
                    },
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal('Excluir conteudo')
                      setId(item.id)
                    },
                    path: '',
                    title: 'Excluir conteudo',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
          <ModalDelete confirmAction={() => deletePost()} />
        </div>

      )}
    </>
  )
}

const FeedConteudos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Conteúdos', path: 'hospitality/feed/content', isActive: true },
        ]}
      >
        Conteúdos
      </PageTitle>
      <Content />
    </>
  )
}

export { FeedConteudos }
