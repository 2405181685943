/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT } from '../../../../services/api'
import { toDate } from '../../../../utils/formater'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { KTSVG } from '../../../../../_metronic/helpers'
import useModals from '../../../../hooks/useModals'
import Swal from 'sweetalert2'
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript'
import { i18n } from '../../../../translate/i18n'
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type CustomerAddressType = {
  city: string
  district: string
  state: string
  street: string
  zipCode: string
  number: string
  extra: string
}

type CustomerType = {
  id: number
  name: string
  customerAddress: CustomerAddressType
}

type LocationType = {
  id: number
  name: string
  code: string
}

type StatusType = {
  id: number
  name: string
  slug: string
  order: number
}

type OrderResponseType = {
  id: number
  orderidExt: string
  customer: CustomerType
  created: string
  location: LocationType
  total: number
  status: StatusType[]
  statusId: number
  content:any
}

type OrderType = {
  id: string
  orderidExt: string
  customer: string
  location: string
  created: string
  total: number
  status: string
  payment: string;
}

const PedidosContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { wrapperModal, showWrapperModal } = useModals()
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [finalDate, setFinalDate] = useState(moment().format('YYYY-MM-DD'))
  const [orders, setOrders] = useState<OrderType[]>([])
  const [orderId, setOrderId] = useState(0)
  const [ordersNoFormated, setOrdersNoFormated] = useState<any[]>([])
  const [header, setHeader] = useState([])
  const { loginInfo } = useAuth()

  const mapPayment = (flag: string) => {
    console.log(flag)
    switch(flag){
      case 'paymentOffline':
        return 'Pagamento na Entrega';
      case 'payOffline':
        return 'Pagamento na Entrega';
      case 'pix':
        return 'Pix';
      default:
        return 'Cartão de Crédito';
    }
  }

  const searchOrders = async () => {
    try {
      setLoading(true)
      const resOrders = await POST('/orders/filter', {
        startDate: `${startDate}T00:00:00`,
        finalDate: `${finalDate}T23:59:59`,
      })
      setOrdersNoFormated(resOrders.data)
      
      const newOrders: OrderType[] = resOrders.data.map((order: OrderResponseType) => {
        let colorStatus = ''
        const statusOrder = order.status.find((status) => status.id === order.statusId)
        switch (statusOrder ? statusOrder.order : 1) {
          case 1:
            colorStatus = '#4DD0E1'
            break
          case 2:
            colorStatus = '#8D6E63'
            break
          case 3:
            colorStatus = '#7E57C2'
            break
          case 4:
            colorStatus = '#2E7D32'
            break
          default:
            colorStatus = '#AD1457'
            break
        }

        const statusHtml = `
        <div style="display: flex; flex-direction:row; align-items: center; font-size: 12px; color: ${colorStatus}">
          <div style="border: 1px solid ${colorStatus}; border-radius: 100%; width: 16px; height: 16px; text-align: center; display: flex; flex-direction: row; align-items: center; justify-content: center">
            ${statusOrder ? statusOrder.order : ''}
          </div>
          <p class="mb-0" style="margin-left: 1em">${statusOrder ? statusOrder.name : ''}</p>
        </div>
        `

        let colorLocation = '#4DD0E1'
        if (order.location && order.location.code === 'delivery') {
          colorLocation = '#7E57C2'
        }
        const locationHtml = `
        <div style="display: flex; flex-direction:row; align-items: center; color: ${colorLocation}">
          ${order.location && order.location.code === 'delivery'
            ? '<svg xmlns="http://www.w3.org/2000/svg" fill="#7E57C2" width="20" height="20" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M280 32c-13.3 0-24 10.7-24 24s10.7 24 24 24h57.7l16.4 30.3L256 192l-45.3-45.3c-12-12-28.3-18.7-45.3-18.7H64c-17.7 0-32 14.3-32 32v32h96c88.4 0 160 71.6 160 160c0 11-1.1 21.7-3.2 32h70.4c-2.1-10.3-3.2-21-3.2-32c0-52.2 25-98.6 63.7-127.8l15.4 28.6C402.4 276.3 384 312 384 352c0 70.7 57.3 128 128 128s128-57.3 128-128s-57.3-128-128-128c-13.5 0-26.5 2.1-38.7 6L418.2 128H480c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H459.6c-7.5 0-14.7 2.6-20.5 7.4L391.7 78.9l-14-26c-7-12.9-20.5-21-35.2-21H280zM462.7 311.2l28.2 52.2c6.3 11.7 20.9 16 32.5 9.7s16-20.9 9.7-32.5l-28.2-52.2c2.3-.3 4.7-.4 7.1-.4c35.3 0 64 28.7 64 64s-28.7 64-64 64s-64-28.7-64-64c0-15.5 5.5-29.7 14.7-40.8zM187.3 376c-9.5 23.5-32.5 40-59.3 40c-35.3 0-64-28.7-64-64s28.7-64 64-64c26.9 0 49.9 16.5 59.3 40h66.4C242.5 268.8 190.5 224 128 224C57.3 224 0 281.3 0 352s57.3 128 128 128c62.5 0 114.5-44.8 125.8-104H187.3zM128 384c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z"/></svg>'
            : '<svg xmlns="http://www.w3.org/2000/svg" fill="#4DD0E1" width="20" height="20" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M36.8 192H603.2c20.3 0 36.8-16.5 36.8-36.8c0-7.3-2.2-14.4-6.2-20.4L558.2 21.4C549.3 8 534.4 0 518.3 0H121.7c-16 0-31 8-39.9 21.4L6.2 134.7c-4 6.1-6.2 13.2-6.2 20.4C0 175.5 16.5 192 36.8 192zM64 224V384v80c0 26.5 21.5 48 48 48H336c26.5 0 48-21.5 48-48V384 224H320V384H128V224H64zm448 0V480c0 17.7 14.3 32 32 32s32-14.3 32-32V224H512z"/></svg>'
          }
          <p class="mb-0" style="margin-left: 1em">${order.location ? order.location.name : 'Mesa/Comanda'}</p>
        </div>
        `

        const customerHtml = `
        <div style="display: flex; flex-direction:column;">
          <p class='mb-0'>${order.customer ? order.customer.name : '-/-'}</p>
          ${(order.customer && order.customer.customerAddress) ? `
            <p class='mb-0 text-muted'>
              ${order.customer.customerAddress.street} 
              ${order.customer.customerAddress.number
              ? ', ' + order.customer.customerAddress.number
              : ''
            } 
              ${order.customer.customerAddress.extra ? order.customer.customerAddress.extra : ''} 
            </p>
            <p class='mb-0 text-muted'>
              ${order.customer.customerAddress.city} 
              ${order.customer.customerAddress.state} 
              ${order.customer.customerAddress.zipCode
              ? ' - ' +
              order.customer.customerAddress.zipCode.substring(0, 5) +
              '-' +
              order.customer.customerAddress.zipCode.substring(5, 8)
              : ''
            } 
            </p>` : ``}
        </div>
        `;

        let payment = ''
        if(order.content && order.content.dataGateway && order.content.dataGateway.flagIdentifier){
          payment = mapPayment(order.content.dataGateway.flagIdentifier);
        }
        return {
          id: `#${order.id.toString()}`,
          orderidExt: order.orderidExt,
          customer: customerHtml,
          location: locationHtml,
          created: order.created,
          total: order.total / 100,
          status: statusHtml,
          payment
        }
      })
      setOrders(newOrders)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const cancelOrder = async () => {
    try {
      const orderToCancel = ordersNoFormated.find((order) => order.id === orderId)
      if (!orderToCancel) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao encontrar pedido ao cancelá-lo',
        })
        return
      }
      setLoading(true)
      const resStatus = await GET('/status')
      const statusCanceled = resStatus.data.find((status: StatusType) => status.slug === 'canceled')
      if (!statusCanceled) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao encontrar status do pedido',
        })
        return
      }
      if (orderToCancel.status.find((status: StatusType) => status.slug === 'canceled')) {
        setLoading(false)
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Pedido já foi cancelado.',
        })
        return
      }
      if (
        !orderToCancel.id ||
        !orderToCancel.content ||
        !orderToCancel.content.dataGateway ||
        !orderToCancel.content.dataGateway.pluginconfig_id
      ) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Pedido com dados faltantes, impossível cancelá-lo',
        })
        return
      }
      const resOrders = await PUT(`/orders/change-status/${orderToCancel.id}`, {
        statusId: statusCanceled.id,
        dataGateway: orderToCancel.content.dataGateway,
        gatewayId: orderToCancel.content.dataGateway.pluginconfig_id,
        statusSlug: 'canceled',
      })
      setLoading(false)
      if (resOrders.success) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Pedido cancelado com sucesso!',
        }).then(async () => await searchOrders())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao cancelar o pedido.',
        })
      }
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Falha ao cancelar o pedido.',
      })
      console.log(e)
    }
  }

  function configHeader() {
    const header: any = []

    if (loginInfo.plugintype === 'food') {
      header.push({ key: i18n.t(`reports.order_number`), value: 'id', type: 'default' },
        { key: i18n.t(`reports.date`), value: 'created', type: 'datetime' },
        { key: i18n.t(`reports.consumer`), value: 'customer', type: 'html' },
        { key: i18n.t(`reports.modality`), value: 'location', type: 'html' },
        { key: i18n.t(`reports.payment`), value: 'payment', type: 'default' },
        { key: i18n.t(`reports.total_value`), value: 'total', type: 'money' },
        { key: 'Status', value: 'status', type: 'html' },
        { key: i18n.t(`general.actions`), value: 'acoes', type: '' })
    } else {
      header.push({ key: i18n.t(`reports.order_number`), value: 'orderidExt', type: 'default' },
        { key: i18n.t(`reports.date`), value: 'created', type: 'datetime' },
        { key: i18n.t(`reports.consumer`), value: 'customer', type: 'html' },
        { key: i18n.t(`reports.modality`), value: 'location', type: 'html' },
        { key: i18n.t(`reports.payment`), value: 'payment', type: 'default' },
        { key: i18n.t(`reports.total_value`), value: 'total', type: 'money' },
        { key: 'Status', value: 'status', type: 'html' },
        { key: i18n.t(`general.actions`), value: 'acoes', type: '' })
    }

    setHeader(header)

  }

  useEffect(() => {
    configHeader()
    searchOrders()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`reports.order_list`)}</span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='card-body'>
              <div className='w-100 mb-5'>
                <div className='d-flex flex-row flex-wrap align-items-end gap-4'>
                  <Input
                    inputAttr={{ type: 'date' }}
                    label={i18n.t(`reports.first`)}
                    value={startDate}
                    change={setStartDate}
                    maxLength={finalDate}
                  />
                  <div>
                    <Input
                      inputAttr={{ type: 'date' }}
                      label={i18n.t(`reports.end`)}
                      value={finalDate}
                      change={setFinalDate}
                      minLength={startDate}
                    />
                  </div>
                  <Button
                    color='primary'
                    text={i18n.t(`buttons.search`)}
                    click={() => searchOrders()}
                    btnClass='h-45px'
                  />
                </div>

                <div className='d-flex align-items-center justify-content-between border-bottom border-top py-4 mt-8 fs-4 fw-bold text-gray-800'>
                  <p className='m-0'>{`${i18n.t(`reports.orders_between`)} ${toDate(startDate)} até ${toDate(
                    finalDate
                  )}`}</p>
                </div>
              </div>
              <Datatable
                data={orders}
                headers={header}
                options={[
                  {
                    icon: 'fas fa-eye',
                    action: (item) => {
                      window.open(`/order/saleorder/${item.id.split('#')[1]}`)
                      //navigate(`/order/saleorder/${item.id.split('#')[1]}`)
                    },
                    path: '',
                    title: i18n.t(`buttons.view`),
                  },
                  // { icon: "fas fa-box", action: () => {}, path: '', title: "Marcar como enviado" },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      if (item.id) {
                        setOrderId(parseInt(item.id.split('#')[1]))
                      }
                      showWrapperModal(i18n.t(`reports.cancel_order`))
                    },
                    path: '',
                    title: i18n.t(`reports.cancel_order`),
                  },
                ]}
                color='primary'
              />
            </div>
          </div>
          <ModalWrapper confirmAction={() => cancelOrder()}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='modal-body text-break pt-0'>
                <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-2 px-10'>
                  <KTSVG
                    path='/media/icons/duotune/general/gen044.svg'
                    className={`svg-icon`}
                    svgClassName={`h-100px w-100px`}
                  />
                  <p className='mt-2'>O pedido selecionado será estornado e cancelado.</p>
                  <p>Deseja mesmo cancelar este pedido ?</p>
                </div>
              </div>
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  )
}

const Pedidos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`reports.order_report`), path: 'order/saleorder', isActive: true },
        ]}
      >
        {i18n.t(`reports.order_report`)}
      </PageTitle>
      <PedidosContent />
    </>
  )
}

export { Pedidos }
