/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { Loading } from '../../../../components/Loading/Loading'
import useModals from '../../../../hooks/useModals'
import Swal from 'sweetalert2'
import { DELETE, GET, PUT, POST } from '../../../../services/api'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { i18n } from '../../../../translate/i18n'
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import { number } from 'echarts'
import { Input } from '../../../../components/Input/Input'
import { Dropdown } from 'react-bootstrap'
import type { OrderType } from '../../../../components/Datatable/Datatable'


type UserType = {
  id: number;
  image: string;
  name: string;
  email: string;
  type: string;
  created: string;
  status: boolean;
  cpf: string
}


type WebappType = {
  id: number
  title: string
  buttons: any[]
  webappLink: string
  type: string
}

type WebappResponseType = {
  id: number
  name: string
  type: string
  pluginSpace: {
    subdomain: string
  }
}

type categories = {
  value: string
  select: string
}

type filter = {
  order?: OrderType
  classificationId?: string
  status?: boolean
  productTimer?: boolean
  type?: string
}

type ImageType = {
  path: string
  imgsuffix: string
  productId: number
  typeimg: string
  order: number
}

const UsuariosContent: FC = () => {
  const { showDeleteModal } = useModals()

  const navigate = useNavigate()
  const [filterStatus, setFilterStatus] = useState<any>({
    label: 'Ambos',
    value: 2,
  })
  const [filterCategoria, setFilterCategoria] = useState<any>({
    label: 'Geral',
    value: 0,
  })
  const [products, setProducts] = useState<any[]>([])
  const [qtdSelected, setQtdSelected] = useState(0)
  const [categoria, setCategoria] = useState<categories[]>([])
  const [loading, setLoading] = useState(false);
  const { loginInfo } = useAuth()
  const [users, setUsers] = useState<Array<UserType>>([]);
  const [id, setId] = useState<number | null>(null);
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const url = useRef<string>('')
  const [options, setOptions] = useState<any>([
    {
      icon: 'fas fa-pen',
      action: (item: any) => {
        navigate(`/configuracoes/usuarios/editar/${item.id}`)
      },
      title: `${i18n.t(`general.edit`)} ${i18n.t(`user.user`)}`,
    },
    {
      icon: 'fas fa-trash', action: (item: any) => {
        showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`user.user`)}`)
        setId(item.id);
      }, title: `${i18n.t(`general.delete`)} ${i18n.t(`user.user`)}`
    },
  ])

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    setLoading(true);
    await filterProdutos();
    setLoading(false)
  }

  const switchUserType = (type: string) => {
    switch (type) {
      case 'admin':
        return 'Administrador';
      case 'owner':
        return 'Gerente da Pluginspace';
      case 'user':
        return 'Usuário';
      case 'application':
        return 'Usuário da aplicação';
      case 'seller':
        return 'Vendedor';
    }
  }

  const getUsers = async () => {
    try {
      setLoading(true);
      const usersRes = await GET(`/users`)
      const newUsers = usersRes.data.map((user: UserType) => {
        return {
          id: user.id,
          image: user.image.substring(0, 4) === 'http' ? user.image : process.env.REACT_APP_AWS_S3_URL + user.image,
          name: user.name,
          email: user.email,
          cpf: user.cpf,
          type: switchUserType(user.type),
          created: user.created,
          status: user.status
        }
      })
      setUsers(newUsers);
      setMeta(usersRes.meta)
      setLoading(false);

    } catch (e) {
      console.log(e)
      setLoading(false);

    }
  }

  const deleteUsers = async () => {
    try {
      setLoading(true);
      const usersRes = await DELETE(`/users/${id}`);
      if (usersRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Usuário deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getUsers())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar usuário!'
        }).then(async () => await getUsers())
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Erro ao deletar usuário!'
      }).then(async () => await getUsers())
      console.log(e)
    }
  }

  const switchStatus = async (userId: number, status: boolean) => {
    try {
      const switchUser = await PUT(`/users/${userId}`, {
        status: !status
      });
      if (switchUser.success) {
        const newUsers = users.map((user) => {
          if (user.id === id) {
            user.status = !status;
          }
          getUsers();
          return user;
        })
        setUsers(newUsers)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const copyLinkseller = async (user: UserType) => {
    if (user.type === 'Vendedor' && url) {
      const urlVendor = url.current + `/category?vendor=${window.btoa(user.cpf)}`


      navigator.clipboard.writeText(urlVendor);

      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Link do vendedor copiado com successo'
      })


    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção',
        text: 'Algo inesperado aconteceu, tente novamente mais tarde'
      })
    }
  }

  const configOption = () => {
    if (loginInfo.plugintype === 'fashion' || loginInfo.plugintype === 'varejo') {      
      setOptions([
        {
          icon: 'fas fa-pen',
          action: (item: any) => {
            navigate(`/configuracoes/usuarios/editar/${item.id}`)
          },
          title: `${i18n.t(`general.edit`)} ${i18n.t(`user.user`)}`,
        },
        {
          icon: 'fas fa-trash', action: (item: any) => {
            showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`user.user`)}`)
            setId(item.id);
          }, title: `${i18n.t(`general.delete`)} ${i18n.t(`user.user`)}`
        },
        {
          icon: 'fas fa-link',

          fieldToShow: 'type',
          valueToShow: 'Vendedor',
          action: (item: any) => {
            copyLinkseller(item)
          },
          title: `${i18n.t(`general.copy_link`)}`,
        }
      ])
    } else if (loginInfo.plugintype === 'aggregator') {
      setOptions([
        {
          icon: 'fas fa-pen',
          action: (item: any) => {
            console.log(item.id);
            navigate(`/config/user/editar/${item.id}`)
          },
          title: `${i18n.t(`general.edit`)} ${i18n.t(`user.user`)}`,
        },
        {
          icon: 'fas fa-trash', action: (item: any) => {
            showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`user.user`)}`)
            setId(item.id);
          }, title: `${i18n.t(`general.delete`)} ${i18n.t(`user.user`)}`
        }
      ])
    }
  }

  const getUrl = async () => {
    try {
      setLoading(true);

      const appRes = await GET('/appconfig')

      const newWebapps: WebappType[] = appRes.data
        .filter((webapp: WebappResponseType) => webapp.type === 'qr')
        .map((webapp: WebappResponseType) => {
          let url = ''
          if (process.env.REACT_APP_PROD === '1') {
            const host = window.location.hostname.split('.')[0]
            const prefix = process.env.REACT_APP_WEBAPP_URL?.split('//')[0]
            const domain = process.env.REACT_APP_WEBAPP_URL?.split('//')[1]
            // url = `${prefix}//${host}.${domain}/${window.btoa(webapp.pluginspace[0].subdomain)}${(webapp.type === 'seller' ? '/seller' : '')}`
            url =
              'https://webapp' +
              // webapp.pluginSpace.subdomain +
              '.ninegrid.com.br/' +
              window.btoa(webapp.pluginSpace.subdomain) +
              (webapp.type === 'seller' ? '/seller' : '')
          } else {
            url =
              process.env.REACT_APP_WEBAPP_URL +
              '/' +
              window.btoa(webapp.pluginSpace.subdomain) +
              (webapp.type === 'seller' ? '/seller' : '')
          }
          return {
            id: webapp.id,
            title: webapp.name,
            buttons: '',
            webappLink: url,
            type: webapp.type
          }
        })

      const newApp = newWebapps[0]

      url.current = newApp.webappLink
      setLoading(false);

    } catch (e) {
      console.log(e)
      setLoading(false);

    }
  }

  const getCategories = async () => {
    try {
      setLoading(true)

      const pluginSpaceType = loginInfo.plugintype;

      let categories;

      if(pluginSpaceType === 'aggregator'){
        categories = [{select: 'Geral', value: '0',},
          {select: 'Gerente da Pluginspace', value: '1' },
          {select: 'Usuário', value: '2' }];
      } else {
        categories = [{select: 'Geral', value: '0',},
          {select: 'Gerente da Pluginspace', value: '1' },
          {select: 'Usuário', value: '2' },
          {select: 'Vendedor', value: '3' }];
      }

      setCategoria(categories)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  const filterProdutos = async (take: number = 100, page: number = 1, search: string = '', order: OrderType = {field: 'name', type: 'ASC'}) => {
    if (!filterStatus) {
      Swal.fire({
        icon: 'error',
        title: 'Filtros não selecionados',
        text: 'Selecione corretamente os filtros',
      })
    } else {
      try {
        const usersRes = await GET(`/users`)

        const users = usersRes.data.map((user: UserType) => {
          return {
            id: user.id,
            image: user.image.substring(0, 4) === 'http' ? user.image : process.env.REACT_APP_AWS_S3_URL + user.image,
            name: user.name,
            email: user.email,
            cpf: user.cpf,
            type: switchUserType(user.type),
            created: user.created,
            status: user.status
          }
        })


        if(filterStatus.value == '1' || filterStatus.value == '0'){
          const newUsersStatus = users.filter((usuario: UserType) => usuario.status == filterStatus.value);
          if(!(filterCategoria.label == 'Geral')){
            const newUsers = newUsersStatus.filter((usuario: UserType) => usuario.type == filterCategoria.label)
            setUsers(newUsers);
          } else setUsers(newUsersStatus);
        } else {
          if(!(filterCategoria.label == 'Geral')){
            const newUsers = users.filter((usuario: UserType) => usuario.type == filterCategoria.label)
            setUsers(newUsers);
          } else setUsers(users);
        }        
        
      } catch (error) {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao filtrar os usuários',
        }).then(async () => await getUsers())
      }
    }
  }

  useEffect(() => {
    getUrl()
    configOption()
    getCategories()
  }, [])

  useEffect(() => {
    console.log("Updated URL:", url);
  }, [url]);

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`user.user`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`user.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}

                </span>
              </h3>
              <div className='card-toolbar'>
                {/* <Button
              text='Importar lista'
              color='light'
              iconColor='#7e8299'
              icon='bi bi-box-arrow-in-down-left'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {}}
              btnClass='fw-bolder me-5'
            /> */}
                <Button
                  text={i18n.t(`buttons.add_user`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/config/user/criar')}
                  btnClass='fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
            <div className='d-flex align-items-center justify-content-start'>
                <div className='d-flex w-50 '>
                  <div className=' align-items-end  justify-content-start me-5 flex-grow-1'>
                    <span className='align-self-start'>Tipo</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={categoria}
                      placeholder={'Categoria'}
                      search={true}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                  </div>

                  <div className=' flex-grow-1 align-items-center me-5 justify-content-between'>
                    <span className='align-self-start'>Status</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={[
                        {
                          value: 1,
                          select: 'Ativo',
                        },
                        {
                          value: 0,
                          select: 'Inativo',
                        },
                        {
                          value: 2,
                          select: 'Ambos',
                        },
                      ]}
                      value={filterStatus}
                      placeholder={'Status do Produto'}
                      search={true}
                      change={(value: any) => setFilterStatus(value)}
                    />
                  </div>
                </div>

                <div
                  className='d-flex align-items-end w-50 justify-content-end h-100'
                  style={{
                    marginTop: '48px',
                  }}
                >
                  <Button
                    text='Filtrar'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-filter'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={async () => {
                      setLoading(true);
                      await filterProdutos()
                      setLoading(false);
                    }}
                    btnClass='mb-0 fw-bolder'
                  />
                </div>
              </div>
              <Datatable
                data={users}
                headers={[
                  { key: i18n.t(`user.picture`), value: 'image', type: 'image' },
                  { key: i18n.t(`user.name`), value: 'name', type: 'default' },
                  { key: 'E-mail', value: 'email', type: 'default' },
                  { key: i18n.t(`user.type`), value: 'type', type: 'default' },
                  { key: i18n.t(`user.registration`), value: 'created', type: 'date' },
                  { key: i18n.t(`user.active`), value: 'status', type: 'check', action: (item) => switchStatus(item.id, item.status) },
                  { key: i18n.t(`user.actions`), value: 'acoes', type: '' },
                ]}
                options={options}
                color='primary'
              /> 
            </div>
          </div>
          <ModalDelete confirmAction={() => deleteUsers()} />
        </div>
        
        }
    </>
  )
}

const Usuarios: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`user.user`), path: 'configuracoes/usuarios', isActive: true },
        ]}
      >
        {i18n.t(`user.user`)}
      </PageTitle>
      <UsuariosContent />
    </>
  )
}

export { Usuarios }
