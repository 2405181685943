import { FC, useEffect, useState } from "react"
import { Loading } from "../../../components/Loading/Loading"
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"
import { i18n } from "../../../translate/i18n"
import { useNavigate } from "react-router-dom"
import { Input } from "../../../components/Input/Input"
import { Editor } from '@tinymce/tinymce-react'
import { Button } from "../../../components/Button/Button"
import { ModalWrapper } from "../../../components/Modals/General/ModalWrapper/ModalWrapper"
import useModals from "../../../hooks/useModals"
import Swal from "sweetalert2"
import { GET, POST } from "../../../services/api"
import SVG from 'react-inlinesvg';
import { EditorQuill } from "../../../components/EditorQuill/EditorQuill"

type Icon = {
  id: number;
  svg: string;
  description: string;
  category: string;
}



const CreateHabilitarRecursos: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [status, setStatus] = useState(true)
  const [order, setOrder] = useState<string>('0')
  const [iconSelected, setIconSelected] = useState<any>()
  const { showWrapperModal, closeWrapperModal } = useModals()
  const [icones, setIcones] = useState<Icon[]>([])
  const [app, setApp] = useState<any>()

  async function getIcones() {
    try {
      setLoading(true)
      const res = await GET('svgwebapp')

      if (!res || !res.success) {
        Swal.fire({
          icon: 'error',
          titleText: 'Erro',
          text: 'Erro ao encontrar os tipos'
        })
        setLoading(false)
        return
      }

      setIcones(res.data)

      setLoading(false)

    } catch (error) {
      console.log(error)
      setLoading(false)

      Swal.fire({
        icon: 'error',
        titleText: 'Erro',
        text: 'Erro ao encontrar os tipos'
      })
    }
  }



  async function create() {
    try {
      if (!title || !content || !iconSelected || !order) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Verifique todos os campos novamentes'
        })
        return
      }

      setLoading(true)

      const button = {
        name: title,
        routeContent: content,
        appConfigId: app.id,
        idSvgwebApp: iconSelected.id,
        order: order,
        status: status,
      }

      const resultCreateButton = await POST('appitems', button)
      setLoading(false)

      if (resultCreateButton && resultCreateButton.success) {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Recurso criado com sucesso'
        }).then(() => navigate('/footer/feature'))



      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao cadastrar o recurso'
        })
      }


    } catch (error) {

      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao cadastrar o recurso'
      })
      console.log(error)

    }
  }

  async function getInfo() {
    try {
      const appRes = await GET('/appconfig')
      const appId = appRes.data.filter((el: any) => el.type === 'qr')[0]
      setApp(appId)
    } catch (error) {

    }
  }

  async function getAllData() {
    setLoading(true)
    await Promise.all(
      [getIcones(), getInfo()]
    )
    setLoading(false)
  }

  useEffect(() => {
    getAllData()
  }, [])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(
                  `buttons.create`
                )} ${i18n.t(`permissions.footerContentAddEdit`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(
                    `feed.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`
                  )}
                </span>
              </h3>
            </div>
            <div className='card-body w-100'>
              <div className='d-flex justify-content-between w-100'>
                <div className="d-flex flex-column gap-3 w-50">
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    label="Titulo"
                    placeholder="Escolha um termo simples e direto com no máximos de 15 caracteres"
                    value={title}
                    maxCaracteres={15}
                    change={(value: string) => {
                      setTitle(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'checkbox',
                    }}
                    vertical={true}
                    checkSwift={true}
                    label="Status"
                    value={status}
                    checked={status}
                    change={(value: boolean) => {
                      setStatus(value)
                    }}
                  />
                </div>
                <div className="d-flex">
                  <Input
                    inputAttr={{
                      type: 'icon',
                    }}
                    onAction={() => showWrapperModal('Ícones')}
                    label="Ícone"
                    value={iconSelected}
                    change={(value: any) => {
                      setIconSelected(value)
                    }}
                  />
                </div>
              </div>
              <div>
                <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                  <div>
                    <Input
                      inputAttr={{
                        type: 'number',
                      }}
                      placeholder={i18n.t(`smart_order.order`)}
                      label={i18n.t(`smart_order.order`)}
                      value={order}
                      minNumber={0}
                      change={(value: string) => setOrder(value)}
                    />
                  </div>
                  <div className='w-100'>
                    <div className='mt-6'>
                      <label className='form-label fw-normal'>Descrição</label>
                      <EditorQuill
                        value={content}
                        change={(value) => {
                          setContent(value)
                        }}
                      />

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex w-100'>
              <div className='col-12 d-flex justify-content-end '>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='info'

                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                  width="200px"
                />
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  width="200px"
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    create()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <ModalWrapper confirmAction={() => {
      }}
        maxWidth={true}
        width={40}
      >
        <div className="d-flex  flex-column gap-5 card"
          style={{
            height: '20rem'
          }}>
          <div className="d-flex justify-content-end mt-5">
            <div className="p-4 border border-primary ">
              <SVG src={iconSelected?.svg} style={{
                width: '45px',
                height: '45px'
              }} />
            </div>
          </div>
          <div className="card-body d-flex gap-5 flex-row flex-wrap overflow-auto">
            {icones.map(icon => (
              <div className={`d-flex flex-column p-4`}

                onClick={() => setIconSelected(icon)}>
                <span>{icon.description}</span>
                <SVG src={icon.svg} style={{
                  width: '45px',
                  height: '45px'
                }} />
              </div>
            ))

            }

          </div>


        </div>

      </ModalWrapper>
    </>
  )
}

export { CreateHabilitarRecursos }