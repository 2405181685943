import { FC, useEffect, useState } from "react";
import { Datatable } from "../../../components/Datatable/Datatable";
import { Button } from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs";
import { GET } from "../../../services/api";
import Buffer from 'buffer'

type LoginData = {
  accessToken: string
  expiratedAt: string
  name: string,
  id: number | null
  email: string
  plugintype: string // Nome Plugin
  pluginspaceId: number | null //Id Loja
  pluginspace: string //Nome Loja
  profile: string
  plugintypeId: number | null // Id Plugin
  pluginAdm: boolean | null // É adm?
}


const SdsManagementHospitality: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])
  const [loginInfo, setloginInfo] = useState<LoginData>()

  async function getSDS() {
    try {
      setLoading(true)
      const response = await GET('sds_integration/get_all_sds_hospitality')
      setData(tratamentoDados(response.data))


      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const tratamentoDados = (dados: any) => {
    const array: any[] = [];

    for (const sds of dados) {
      const body = {
        id: sds.id,
        name: sds.name,
        setores: ''
      }


      for (const setores of sds.sdsAndProductSectors) {

        if (setores.active) {
          if (body.setores == '') {
            body.setores = setores.productSector[0].name
          } else {
            body.setores += ', ' + setores.productSector[0].name
          }
        }

      }
      array.push(body)

    }
    return array
  }


  async function getLoginSDSInfo() {
    try {
      setLoading(true)
      const response = await GET('sds_integration/login_info_hospitality')
      setloginInfo(response.data)
      setLoading(false)

    } catch (error) {
      setLoading(false)

    }
  }

  useEffect(() => {
    getSDS()
    getLoginSDSInfo()
  }, [])

  const openKDS = (sdsid: number | String) => {
    if (loginInfo) {
      const body = {
        idUser: loginInfo.id!.toString(),
        idSDS: sdsid
      }

      SetarUrl(body)
    }
  }

  const SetarUrl = (body: any) => {
    let url = ''//process.env.REACT_APP_WEBAPP_URL  + window.btoa(JSON.stringify(body))
    const corpoCode64 = btoa(JSON.stringify(body))


    switch (process.env.REACT_APP_PROD) {
      case '3':
        url = 'https://sds-app-dev.ninegrid.com.br/' + corpoCode64
        break
      case '0':
        url = 'https://sds-app-staging.ninegrid.com.br/' + corpoCode64
        break
      case '1':
        url = 'https://sds-app.ninegrid.com.br/' + corpoCode64
        break
    }
    window.open(url)
  }

  const SeePedidos = () => {

    if (loginInfo) {
      const body = {
        idUser: loginInfo.id!.toString(),
        idSDS: data[0].id
      }
      const corpoCode64 = btoa(JSON.stringify(body))
      let url = ''
      switch (process.env.REACT_APP_PROD) {
        case '0':
          url = 'https://sds-app-staging.ninegrid.com.br/' + corpoCode64 + '/pedidos'
          break
        case '1':
          url = 'https://sds-app.ninegrid.com.br/' + corpoCode64 + '/pedidos'
          break
        case '3':
          url = 'https://sds-app-dev.ninegrid.com.br/' + corpoCode64 + '/pedidos'
          break
      }
      window.open(url)
    }
  }



  return (
    <>
      {
        loading ? (
          <></>
        ) : (
          <div className='row g-5 gx-xxl-12'>
            <div className='card card-xxl-stretch mb-5 mb-xl-12'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Gestão de Display</span>
                  <BreadCrumbs />
                </h3>
                <div className='card-toolbar'>
                  <Button
                    text='Adicionar SDS'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-plus'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate('create')}
                    btnClass='mb-5 fw-bolder'
                  />
                </div>
              </div>
              <div className='card-body'>
                <div className='d-flex justify-content-end'>
                  <Button
                    text='Display de Pedidos'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-tv'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => SeePedidos()}
                    btnClass='mb-5 fw-bolder'
                  />
                </div>
                <Datatable
                  data={data}
                  headers={[
                    { key: 'Nome', value: 'name', type: 'default' },
                    { key: 'Setor', value: 'setores', type: 'default' },
                    { key: 'acoes', value: 'Ações', type: '' },
                  ]}
                  options={[
                    {
                      icon: 'fas fa-eye',
                      title: 'Visualizar SDS',
                      action: (item) => {
                        openKDS(item.id)
                      },
                    }
                  ]}
                  color='primary'
                />
              </div>
            </div>

          </div>
        )

      }
    </>
  )
}

export { SdsManagementHospitality }