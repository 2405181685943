import { BaseSyntheticEvent, FC, KeyboardEvent, SetStateAction, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import Swal from 'sweetalert2'
import { POST, PUT, GET } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid';
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type TypeListProduct = {
  id: string
  name: string
  price: string
  image: string
}

const EditarCatalogosContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingComp, setLoadingComp] = useState(false)
  const [name, setName] = useState('')
  const [order, setOrder] = useState('0')

  const [searchproduct, setSearchProduct] = useState('')
  const [listsearchproducts, setListSearchProducts] = useState<TypeListProduct[]>([])
  const [listProducts, setListProducts] = useState<TypeListProduct[]>([])

  const [startDate, setStartDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const [image, setImage] = useState<File | null>(null)
  const [firstStateImage, setFirstStateImage] = useState<File | null>(null)

  const searchProduct = async () => {
    try {
      setLoadingComp(true)
      const res = await POST('/product/filter', {
        name: searchproduct,
      })
      setListSearchProducts(res.data)
      setLoadingComp(false)
    } catch (e) {
      setLoadingComp(false)
      console.log(e)
    }
  }

  const addList = async (id: any) => {
    const newList = listProducts.map((product) => product)
    const oldList = newList.filter(function (row) {
      return row.id == id
    })
    const selected = listsearchproducts.filter(function (row) {
      return row.id == id
    })
    if (oldList.length == 0) newList.push(selected[0])
    setListProducts(newList)
  }

  const delList = (id: any) => {
    const newList = listProducts.filter((row) => row.id !== id)
    setListProducts(newList)
  }

  const getCatalog = async () => {
    try {
      setLoading(true)
      const resCatalog = (await GET(`/catalog/${id}`)).data[0]
      setListProducts(resCatalog.products)
      searchProduct();
      setName(resCatalog.catalog)
      setOrder(resCatalog.order)
      setStartDate(moment(resCatalog.dateStart).format('YYYY-MM-DD'))
      setFinalDate(moment(resCatalog.dateEnd).format('YYYY-MM-DD'))
      let imgCatalog = null
      if (resCatalog.imgCatalog) {
        imgCatalog = resCatalog.imgCatalog.substring(0, 4) === 'http'
          ? resCatalog.imgCatalog
          : process.env.REACT_APP_AWS_S3_URL + resCatalog.imgCatalog
      }
      setImage(imgCatalog)
      setFirstStateImage(imgCatalog)
      setLoading(false)
    } catch (e) {
      setLoadingComp(false)
      console.log(e)
    }
  }

  const update = async () => {
    try {
      setLoading(true)
      if (!name || !startDate || !finalDate || listProducts.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos e se há produtos adicionados!',
        })
        return
      }

      let imageRes = {
        success: false,
        image: '',
      }
      const products = listProducts.map((row) => row.id)
      const resCatalog = await PUT(`/catalog/${id}`, {
        catalog: name,
        dateStart: moment(startDate).format('YYYY-MM-DD hh:mm:00'),
        dateEnd: moment(finalDate).format('YYYY-MM-DD hh:mm:00'),
        order: order,
        products: products,
        imgCatalog: '',
      })
      if (image) {
        if (firstStateImage) {
          await POST('/upload-images/delete-one', {
            url: firstStateImage
          })
        }
        const formData = new FormData()
        formData.append('file', image)
        formData.append('nameImage', `catalog-${uuid()}.jpg`)
        imageRes = await POST('/upload-images/upload-one', formData)
        if (imageRes.success) {
          await PUT(`/catalog/${id}`, {
            fotoCatalog: imageRes.image,
          })
        }
      }
      setLoading(false)
      if (resCatalog.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Catálogo alterado',
          text: 'Edição realizada com sucesso!',
        }).then(() => navigate(`/catalog`))
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao alterar o catálogo!',
        })
      }
    } catch (e) {
      setLoadingComp(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getCatalog()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`smart_order.catalogs`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='card-body border-bottom'>
                <div className='d-flex flex-row flex-wrap gap-10'>
                  <div>
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      label={i18n.t(`smart_order.image`)}
                      value={firstStateImage}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <div className="row">
                      <div className='col-md-6'>
                        <Input
                          inputAttr={{
                            type: 'default',
                          }}
                          placeholder={i18n.t(`smart_order.name`)}
                          label={i18n.t(`smart_order.name`)}
                          value={name}
                          change={(value: string) => setName(value)}
                        />
                      </div>
                      <div className='col-md-2'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          placeholder={i18n.t(`smart_order.order`)}
                          label={i18n.t(`smart_order.order`)}
                          value={order}
                          change={(value: string) => setOrder(value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className='col-md-4'>
                        <Input
                          inputAttr={{ type: 'date' }}
                          label={i18n.t(`smart_order.first`)}
                          value={startDate}
                          change={setStartDate}
                          maxLength={finalDate}
                        />
                      </div>
                      <div className='col-md-4'>
                        <Input
                          inputAttr={{ type: 'date' }}
                          label={i18n.t(`smart_order.end`)}
                          value={finalDate}
                          change={setFinalDate}
                          minLength={startDate}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <hr />

                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group mt-5'>
                      <label className='form-label fw-normal'>{i18n.t(`smart_order.search_products`)}</label>
                      <div className='input-group'>
                        <input
                          type='text'
                          className='form-control fw-normal'
                          placeholder={i18n.t(`smart_order.search_products`)}
                          value={searchproduct}
                          onChange={(event) => setSearchProduct(event.target.value)}
                        />
                        <div className='input-group-append'>
                          <button
                            className='btn btn-success'
                            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                            type='button'
                            onClick={() => searchProduct()}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    {loadingComp ? (
                      <Loading />
                    ) : (
                      <Datatable
                        data={listsearchproducts}
                        headers={[
                          { key: '#ID', value: 'id', type: 'default' },
                          { key: i18n.t(`smart_order.prod`), value: 'name', type: 'default' },
                          { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                        ]}
                        options={[
                          {
                            icon: 'fas fa-plus',
                            action: (item) => {
                              addList(item.id)
                            },
                            title: 'adicionar',
                          },
                        ]}
                        color='primary'
                      />
                    )}
                  </div>

                  <div className='col-md-6'>
                    {listProducts.length > 0 && (
                      <Datatable
                        data={listProducts}
                        headers={[
                          { key: '#ID', value: 'id', type: 'default' },
                          { key: i18n.t(`smart_order.prod`), value: 'name', type: 'default' },
                          { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                        ]}
                        options={[
                          {
                            icon: 'fas fa-trash',
                            action: (item) => {
                              delList(item.id)
                            },
                            title: 'adicionar',
                          },
                        ]}
                        color='primary'
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className="d-flex w-100">
                  <div className="col-6 d-flex justify-content-start">
                    <Button
                      text={i18n.t(`buttons.back`)}
                      color='primary'
                      size='small'
                      horizontalPadding={6}
                      btnAttr={{
                        type: 'button',
                      }}
                      click={() => navigate(-1)}
                      btnClass='fw-bolder m-9'
                    />
                  </div>
                  <div className="col-6  d-flex justify-content-end">
                    <Button
                      text={i18n.t(`buttons.save`)}
                      color='primary'
                      size='small'
                      horizontalPadding={6}
                      btnAttr={{
                        type: 'button',
                      }}
                      click={() => update()}
                      btnClass='fw-bolder m-9'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const EditarCatalogos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.catalogs`), path: 'catalog', isActive: true },
          { title: i18n.t(`general.edit`), path: 'catalog/criar', isActive: true },
        ]}
      >
        {`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.catalog`)}`}
      </PageTitle>
      <EditarCatalogosContent />
    </>
  )
}

export { EditarCatalogos }
