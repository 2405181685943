/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { useNavigate } from 'react-router-dom'
import useModals from '../../../../hooks/useModals'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, PUT } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { CardFreight } from '../../../../components/CardFreight'

import MapImg from '../../../../assets/freights/map.jpeg'
import RadiusImg from '../../../../assets/freights/radius.jpeg'
import CepImg from '../../../../assets/freights/cep.jpeg'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type AppConfigType = {
  id: number;
  name: string;
  type: string;
  content: {
    pdv: any;
  };
  deliveryType: string;
}

const FluxoFreteContent: FC = () => {
  const { showWrapperModal, showDeleteModal, showWarningModal } = useModals()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [ableItem, setAbleItem] = useState('')
  const [appConfig, setAppConfig] = useState<AppConfigType | null>(null)



  const getConfig = async () => {
    try {
      setLoading(true)
      const appRes = await GET(`/appconfig`)
      if (appRes.data && appRes.data.length > 0) {
        const appConfigRes: AppConfigType = appRes.data.find((config: AppConfigType) => config.type === 'qr')
        if (appConfigRes) {
          setAppConfig(appConfigRes)
          setAbleItem(appConfigRes.deliveryType)
        }
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const activeItem = async (type: string) => {
    setAbleItem(type)
    if (appConfig) {
      const newAppConfig: AppConfigType = { ...appConfig }
      newAppConfig.deliveryType = type;
      newAppConfig.content.pdv.restrict_cep = type;
      await PUT(`/appconfig/${newAppConfig.id}`, {
        deliveryType: newAppConfig.deliveryType,
        content: newAppConfig.content
      })
      setAppConfig(newAppConfig)
    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{ i18n.t(`delivery_fee.delivery_fee`) }</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className="d-flex flex-row flex-wrap gap-7">
                <CardFreight
                  name={i18n.t(`freight.map`)}
                  description={i18n.t(`freight.map_text`)}
                  activeItem={activeItem}
                  able={ableItem === 'mapa'}
                  type='mapa'
                  image={MapImg}
                  key={1}
                  link='/configuracoes/taxa-entrega/map'
                />
                <CardFreight
                  name={i18n.t(`freight.range`)}
                  description={i18n.t(`freight.range_text`)}
                  activeItem={activeItem}
                  able={ableItem === 'area'}
                  type='area'
                  image={RadiusImg}
                  key={2}
                  link='/configuracoes/taxa-entrega/raio'
                />
                <CardFreight
                  name={i18n.t(`freight.cep`)}
                  description={i18n.t(`freight.cep_text`)}
                  activeItem={activeItem}
                  able={ableItem === 'cep'}
                  type='cep'
                  image={CepImg}
                  key={3}
                  link='/configuracoes/taxa-entrega/cep'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const FluxoFrete: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`freight.title`), path: 'configuracoes/taxa-entrega', isActive: true },
        ]}
      >
        {i18n.t(`freight.title`)}
      </PageTitle>
      <FluxoFreteContent />
    </>
  )
}

export { FluxoFrete }
