import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import useModals from '../../../../hooks/useModals'
import { DELETE, GET, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { useNavigate } from 'react-router-dom'
import { toDate } from '../../../../../app/utils/formater'
import moment from 'moment'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import useAuth from '../../../../../app/hooks/useAuth';


type ConsentResponseType = {
  id: number
  name: string
  active: boolean
  content: string | null
  expirated: Date
}

const TermosContent: FC = () => {
  const { showDeleteModal } = useModals()
  const navigate = useNavigate()

  const [consents, setConsents] = useState<any[]>([])
  const [consent, setConsent] = useState<ConsentResponseType>()
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)
  const { loginInfo } = useAuth()

  const getConsents = async () => {
    try {

      const consentRes = await GET(`/consent`)

      const consentList = consentRes.data.map((consent: ConsentResponseType) => {
        return {
          id: consent.id,
          name: consent.name,
          content: consent.content,
          status: consent.active ? 'ativo' : 'inativo',
          active: consent.active,
          expirated: toDate(consent.expirated.toString()),
        }
      })
      setConsents(consentList)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteConsent = async () => {
    try {
      setLoading(true)
      if (consent) {
        console.log(consent);
        const { id, name, content, expirated, active } = consent;
        await PUT(`/consent/${id}`, {
          name,
          content,
          expirated: moment(expirated, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          active
        })
        await getConsents()
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getConsents()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`terms.terms`)}</span>
                <BreadCrumbs />
                {loginInfo.plugintype !== 'aggregator' ? (
                  <span className='text-muted mt-1 fw-bold fs-7'>
                    {i18n.t(`terms.here_are_the_terms_of_acceptance_of_the_sales_platform`)}
                  </span>
                ) 
                : 
                <span className='text-muted mt-1 fw-bold fs-7'>
                {i18n.t("Aqui listamos os termos e aceites de uso do agregador.")}
              </span>}
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_term`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    navigate('/configuracoes/termos/criar')
                  }}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={consents}
                headers={[
                  { key: 'Id', value: 'id', type: 'default' },
                  { key: i18n.t(`terms.name`), value: 'name', type: 'default' },
                  { key: i18n.t(`terms.expirated`), value: 'expirated', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'badge',
                    badge: {
                      ativo: 'success',
                      inativo: 'danger',
                    },
                  },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      navigate(`/configuracoes/termos/editar/${item.id}`)
                    },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`terms.terms`)}`,
                  }
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalDelete confirmAction={() => deleteConsent()} />
        </div>
      )}
    </>
  )
}

const Termos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`terms.terms`), path: 'configuracoes/termos', isActive: true },
        ]}
      >
        {i18n.t(`terms.terms`)}
      </PageTitle>
      <TermosContent />
    </>
  )
}

export { Termos }
