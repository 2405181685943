import { BaseSyntheticEvent, FC, KeyboardEvent, SetStateAction, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import Swal from 'sweetalert2'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { i18n } from '../../../translate/i18n'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import usePluginsSpace from '../../../hooks/usePluginSpace'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type Live = {
    name: string,
    status: boolean,
    initialDate: Date;
    finalDate: Date;
}

type LiveProducts = {

}
type ImageType = {
    path: string
    imgsuffix: string
    productId: number
    typeimg: string
    order: number
}


const EditarLiveShopContent: FC<any> = () => {

    const navigate = useNavigate()
    const [idProduto, setIdProduto] = useState<number | null>(null)
    const [deleteProd, setDeleteProd] = useState<any>()
    const { id } = useParams()

    const { locations } = usePluginsSpace()

    const { showWrapperModal, showDeleteModal } = useModals()
    const [loading, setLoading] = useState(false)
    const [loadingComp, setLoadingComp] = useState(false)
    const [name, setName] = useState('')

    const [searchproduct, setSearchProduct] = useState('')
    const [listsearchproducts, setListSearchProducts] = useState<any[]>([])
    const [listProducts, setListProducts] = useState<any[]>([])

    const [startDate, setStartDate] = useState('')
    const [finalDate, setFinalDate] = useState('')

    const [startHour, setStartHour] = useState('12:00:00')
    const [finalHour, setFinalHour] = useState('12:00:00')

    const [canEdit, setCanEdit] = useState(false)


    const [product, setProduct] = useState<any>()

    const searchProduct = async () => {
        if (!searchproduct) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe o nome de um produto para pesquisar!',
            })
            return
        }
        try {
            setLoadingComp(true)
            
            const location = locations.find(el => el.code === 'withdraw')
            const page = 1;
            const take = 5000;

            const res = await POST(`/product/filter?page=${page}&take=${take}`, {
            //const res = await POST('/product/filter', {
                name: searchproduct,
                isInventoryGreaterThan: 1,
                status: true,
                hasImages: true,
                location: location?.id

            })

            const rawProdutos = res.data.filter((produto: any) => produto.images.length > 0).map((row: any) => {
                return {
                    productId: row.id,
                    codeProduct: row.code,
                    productDescription: row.description,
                    productName: row.name,
                    variantOptions: row.variations.filter((variation: { status: any; img: any }) => variation.status && variation.img).map((row: any) => {
                        let specification = `<div class='d-flex flex-column'>`
                        const contextFormated = JSON.parse(row.context);
                        if (contextFormated) {
                            Object.entries(contextFormated).map((item) => {
                                specification += `<p>${item[0]}: ${item[1]}</p>`
                            })
                        }
                        specification += '</div>'

                        return {
                            ...row,
                            imageUrl: row.img,
                            productSku: row.sku,
                            context: specification,
                            rawContext: JSON.parse(row.context),
                            stock: row.inventory,
                            selected: false
                        }
                    })
                }
            })

            const produtos = rawProdutos.filter((produto: any) => produto.variantOptions.length > 0)




            setListSearchProducts(produtos)
            setLoadingComp(false)

            if (!produtos.length) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Variações não encontradas',
                    text: 'Verifique se as variações desse produto possuem preço, imagem e estão ativas!'
                })
            }

        } catch (e) {
            setLoadingComp(false);
            console.log(e)
            console.error("Ocorreu um erro:", e.message);
            console.error("Stack Trace:", e.stack);
        }
    }


    const showModal = (id: any) => {

        const prod = listsearchproducts.find(prod => prod.productId === id)


        if (prod.variantOptions.length) {

            setProduct(prod)

            showWrapperModal(i18n.t(`liveShop.variations`))
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Variações não encontradas',
                text: 'Verifique se as variações desse produto possuem preço, imagem e estão ativas!'
            })
        }

    }

    const showModalSeleted = (id: any) => {
        const prod = listProducts.find(prod => prod.id === id)

        setProduct(prod)

        showWrapperModal(i18n.t(`liveShop.variations`))
    }

    const addList = async () => {
        //Pegar as variações selecionadas e add a lista de produtos

        const produto = { ...product, variantOptions: product.variantOptions.filter((variation: any) => variation.selected == true) }

        if (produto.variantOptions.length <= 0) {

            if (produto.id) {
                showDeleteModal('Excluir Produto')
                setIdProduto(produto.id)
            } else {
                delList(produto.productId)
            }

            return
        }
        const newList = listProducts.map((product) => product)
        const oldList = newList.filter(function (row) {
            return row.productId == produto.productId
        })
        const selected = listsearchproducts.filter(function (row) {
            return row.productId == produto.productId
        })
        if (oldList.length == 0) {
            newList.push(produto)
            setListProducts(newList)
        } else {
            const index = newList.findIndex((row) => row.productId == produto.productId)

            newList[index] = produto

            setListProducts(newList)
        }

        //f


    }
    const delList = (id: any) => {
        const newList = listProducts.filter((row) => row.productId !== id)
        setListProducts(newList)
    }

    const editLive = async () => {
        try {

            const liveCatalog = listProducts.map((produto: any) => {

                const schema = {
                    id: produto.id ? produto.id : 0,
                    liveId: id,
                    productId: produto.productId,
                    codeProduct: produto.codeProduct,
                    productName: produto.productName,
                    productDescription: produto.productDescription,
                    variantOptions: produto.variantOptions.map((variation: any) => {
                        const schemaVariations = {
                            productSku: variation.productSku,
                            discount: 0,
                            price: variation.price,
                            unitPrice: variation.price,
                            imageUrl: variation.imageUrl,
                            stock: variation.stock,
                            variation: [
                                variation.rawContext
                            ]
                        }

                        return schemaVariations
                    }),
                }

                return schema

            })


            if (!name || !startDate || !finalDate || !startHour || !finalHour || listProducts.length == 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos faltantes',
                    text: 'Verifique se preencheu todos os campos e se há produtos adicionados!',
                })
                return
            }
            setLoading(true)

            const dateI = `${startDate} ${startHour}`
            const dateF = `${finalDate} ${finalHour}`

            const resLive = await PUT(`/livemeta/${id}`, {
                name: name,
                initialDate: moment(dateI).format('YYYY-MM-DD hh:mm:00'),
                finalDate: moment(dateF).format('YYYY-MM-DD hh:mm:00'),
                status: true
            })

            if (resLive.success === true) {

                const resLiveCatalog = await POST('/livecatalog/multipleupdate', liveCatalog)

                if (resLiveCatalog.success === true) {
                    setLoading(false)
                    Swal.fire({
                        icon: 'success',
                        title: 'Catálogo ao Vivo cadastrado',
                        text: 'Cadastro realizado com sucesso!',
                    }).then(() => navigate(`/live_shop`))
                } else {
                    setLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao cadastrar o Catálogo ao Vivo!',
                    })
                }
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar o Catálogo ao Vivo!',
                })
            }












        } catch (e) {
            setLoadingComp(false)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Um erro ocorreu ao tentar salvar o catálogo!',
            })
            console.log(e)
        }
    }

    const setVariationSelected = (sku: number, value: boolean) => {


        const newVariations = product.variantOptions.map((variation: any) => {
            if (variation.productSku === sku) {
                variation.selected = value
            }

            return variation
        })

        const prodAux = { ...product, variantOptions: newVariations }

        setProduct(prodAux)

    }

    const getLiveShop = async () => {
        try {
            setLoading(true)

            const res = await GET(`livemeta/${id}`)
            const data = res.data[0]

            const init = data.initialDate.split('T')

            verifyCanEdit(data.initialDate)

            const end = data.finalDate.split('T')

            setName(data.name);

            setStartDate(init[0])
            setFinalDate(end[0])

            setStartHour(init[1])
            setFinalHour(end[1])

            const catalog = await POST('livecatalog/filter', { liveId: parseInt(id!), })

            const catalogData = catalog.data.map((catalog: any) => {
                const schema = {
                    ...catalog
                }

                schema.variantOptions = catalog.variantOptions.map((variacao: any) => {
                    let specification = `<div class='d-flex flex-column'>`
                    const contextFormated = JSON.parse(JSON.stringify(variacao.variation ? variacao.variation[0] : {}));
                    if (contextFormated) {
                        Object.entries(contextFormated).map((item) => {
                            specification += `<p>${item[0]}: ${item[1]}</p>`
                        })
                    }
                    specification += '</div>'

                    const schemaVariation = { ...variacao, selected: true, context: specification, rawContext: variacao.variation[0] }

                    return schemaVariation
                })

                return schema
            })

            setListProducts(catalogData)

            setLoading(false)


        } catch (error) {
            setLoading(false)
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao carregar catálogo!',
            })
        }
    }


    const verifyCanEdit = (dateInit: Date) => {
        try {
            const today = new Date().getTime()
            const initD = new Date(dateInit).getTime()


            if (today < initD) {
                setCanEdit(true)
            }
        } catch (error) {
            setCanEdit(false)
            return false
        }

    }

    const deleteProduct = async () => {
        if (deleteProd.id) {

            try {
                setLoading(true)
                await DELETE(`livecatalog/${deleteProd.id}`)
                delList(deleteProd.productId)
                setLoading(false)
            } catch (error) {
                setLoading(false)
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao deletar produto do catálogo!',
                })
            }
        } else {
            delList(deleteProd.productId)
        }
    }






    useEffect(() => {
        getLiveShop()
    }, [])
    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                        <div className='card-header py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`liveShop.liveShop`)}</span>
                                <BreadCrumbs />
                                <span className='text-muted mt-1 fw-bold fs-7'>
                                    {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                                </span>
                            </h3>
                        </div>
                        <div className='card-body'>
                            <div className='card-body border-bottom'>
                                <div className='d-flex flex-row flex-wrap gap-10'>
                                    <div style={{ flex: 1 }}>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{
                                                        type: 'default',
                                                    }}
                                                    placeholder={i18n.t(`smart_order.name`)}
                                                    label={i18n.t(`smart_order.name`)}
                                                    value={name}
                                                    change={(value: string) => setName(value)}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'date' }}
                                                    label={i18n.t(`liveShop.dateInit`)}
                                                    value={startDate}
                                                    change={setStartDate}
                                                    maxLength={finalDate}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'time' }}
                                                    label={i18n.t(`liveShop.hourInit`)}
                                                    value={startHour}
                                                    change={setStartHour}

                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'date' }}
                                                    label={i18n.t(`liveShop.dateEnd`)}
                                                    value={finalDate}
                                                    change={setFinalDate}
                                                    minLength={startDate}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'time' }}
                                                    label={i18n.t(`liveShop.hourEnd`)}
                                                    value={finalHour}
                                                    change={setFinalHour}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <hr />

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group mt-5'>
                                            <OverlayTrigger
                                                placement={'right'}
                                                trigger={['hover', 'focus']}

                                                overlay={
                                                    <Tooltip>
                                                        {i18n.t(`liveShop.search_products_Attentions`)}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {i18n.t(`smart_order.search_products`)} *
                                                </span>
                                            </OverlayTrigger>




                                            <label className='form-label fw-normal'>

                                            </label>
                                            <div className='input-group'>
                                                <input
                                                    type='text'
                                                    className='form-control fw-normal'
                                                    placeholder={i18n.t(`smart_order.search_products`)}
                                                    value={searchproduct}
                                                    onChange={(event) => setSearchProduct(event.target.value)}
                                                />
                                                <div className='input-group-append'>
                                                    <button
                                                        className='btn btn-success'
                                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                        type='button'
                                                        onClick={() => searchProduct()}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {loadingComp ? (
                                            <Loading />
                                        ) : (
                                            <Datatable
                                                data={listsearchproducts}
                                                headers={[
                                                    { key: '#ID', value: 'productId', type: 'default' },
                                                    { key: i18n.t(`smart_order.prod`), value: 'productName', type: 'default' },
                                                    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                                                ]}
                                                options={[
                                                    {
                                                        icon: 'fas fa-eye',
                                                        action: (item) => {
                                                            showModal(item.productId)
                                                        },
                                                        title: 'Variações',
                                                    },
                                                ]}
                                                color='primary'
                                            />
                                        )}
                                    </div>

                                    <div className='col-md-6'>
                                        {listProducts.length > 0 && (
                                            <Datatable
                                                data={listProducts}
                                                headers={[
                                                    { key: i18n.t(`smart_order.prod`), value: 'productName', type: 'default' },
                                                    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                                                ]}
                                                options={[
                                                    {
                                                        icon: 'fas fa-eye',
                                                        action: (item) => {
                                                            showModalSeleted(item.id)
                                                        },
                                                        title: 'Variações',
                                                    },
                                                    {
                                                        icon: 'fas fa-trash',
                                                        action: (item) => {

                                                            if (canEdit) {
                                                                showDeleteModal('Excluir Produto')
                                                                setDeleteProd(item)
                                                            } else {
                                                                Swal.fire({
                                                                    icon: 'warning',
                                                                    title: `${i18n.t(`general.attention`)}`,
                                                                    text: `${i18n.t(`liveShop.alert_edit`)}`,
                                                                })
                                                            }



                                                        },
                                                        title: 'adicionar',
                                                    },
                                                ]}
                                                color='primary'
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="d-flex w-100">
                                    <div className="col-6 d-flex justify-content-start">
                                        <Button
                                            text={i18n.t(`buttons.back`)}
                                            color='primary'
                                            size='small'
                                            horizontalPadding={6}
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => navigate(-1)}
                                            btnClass='fw-bolder m-9'
                                        />
                                    </div>
                                    <div className="col-6  d-flex justify-content-end">
                                        <Button
                                            text={i18n.t(`buttons.save`)}
                                            color='primary'
                                            size='small'
                                            disabled={!canEdit}
                                            horizontalPadding={6}
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => editLive()}
                                            btnClass='fw-bolder m-9'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalWrapper confirmAction={() => addList()}>
                        {product && (
                            <>
                                <div className='card-header py-5'>
                                    <h3>{product.name}</h3>

                                </div>
                                <div className='card-body '>
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <h4>#</h4>
                                        <h4>SKU</h4>
                                        <h4>Variação</h4>
                                        <h4>Imagem</h4>
                                    </div>
                                    {product.variantOptions.map((variation: any) =>
                                    (
                                        <div className='d-flex justify-content-between align-items-center my-2'>
                                            <Input
                                                value={variation.selected}
                                                checked={variation.selected}
                                                change={(value: boolean) => setVariationSelected(variation.productSku, value)}
                                                inputAttr={{
                                                    type: 'checkbox',
                                                }}
                                                size={'sm'}
                                                checkSwift={false}
                                            />

                                            <span>{variation.productSku}</span>
                                            <div dangerouslySetInnerHTML={{ __html: `${variation.context}` }}>
                                            </div>
                                            <img
                                                src={variation.imageUrl}
                                                alt=''
                                                style={{ objectFit: 'cover' }}
                                                className='w-75px h-75px bg-light rounded'
                                            />
                                        </div>
                                    )
                                    )


                                    }
                                </div>

                            </>
                        )

                        }
                    </ModalWrapper>
                    <ModalDelete confirmAction={() => deleteProduct()} />
                </div>
            )}
        </>
    )
}

const EditarLiveShop: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`liveShop.edit`), path: 'live_shop', isActive: true },
                ]}
            >
                {`${i18n.t(`liveShop.edit`)}`}
            </PageTitle>
            <EditarLiveShopContent />
        </>
    )
}



export { EditarLiveShop }