/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { useNavigate, useParams } from 'react-router-dom'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import { GET, POST } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { CSVLink } from 'react-csv'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import { exportExcel } from '../../../../utils/utils'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type LogDownloadType = {
  'Código': string;
  'Arquivo': string;
  'Status': string;
}

type LogType = {
  code: string;
  failureReason: string;
  file: string;
}

type ImportationType = {
  id: number
  timeStart: string
  timeEnd: string
  totalProducts: number
  synchronizedProducts: number
  log: { errors: LogType[] }
}

const DetalhesImportacaoImagemContent: FC = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const { id } = useParams();
  const [logs, setLogs] = useState<LogType[]>([])
  const [logsDownload, setLogsDownload] = useState<LogDownloadType[]>([])
  const [totalProducts, setTotalProducts] = useState(0);
  const [synchronizedProducts, setSynchronizedProducts] = useState(0)

  const getImportations = async () => {
    try {
      setLoading(true)
      const importationRes = await GET(`/upload-images-status/${id}`)
      if (importationRes && importationRes.data && importationRes.data.length) {
        const importation: ImportationType = importationRes.data[0];

        setTotalProducts(importation.totalProducts ? importation.totalProducts : 0);
        setSynchronizedProducts(importation.synchronizedProducts ? importation.synchronizedProducts : 0);
        if (importation.log && importation.log.errors) {
          setLogs((importation.log.errors && importation.log.errors.length > 0)
            ? importation.log.errors.map((log) => ({
              code: log.code,
              file: log.file,
              failureReason: `
              <div style='margin: 0; display: flex; flex-direction: row; align-items: center'>
                <i class="fa fa-times" style='color: #DD4A25; margin-right: 10px'></i>
                ${log.failureReason}
              </div>`
            }))
            : [])

          setLogsDownload((importation.log.errors && importation.log.errors.length > 0)
            ? importation.log.errors.map((log) => ({
              'Código': log.code,
              'Arquivo': log.file,
              'Status': log.failureReason
            }))
            : [])
        } else {
          setLogs([]);
          setLogsDownload([]);
        }
      }
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : '',
      }).then(() => navigate('/pluginconfig/others'))
    }
  }



  useEffect(() => {
    getImportations()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5 flex-row justify-content-space-between'>
              <div className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  {i18n.t(`imports.problem_title`)}
                  <BreadCrumbs />
                </span>
                <span className='text-muted mt-1 fw-bold fs-7'>
                  <p className='mt-2 mb-0'>{synchronizedProducts} {i18n.t(`imports.images_success`)}</p>
                  <p className='mt-2 mb-0'>{totalProducts - synchronizedProducts} {i18n.t(`imports.images_error`)}</p>
                </span>
              </div>
              <div className='d-flex flex-row align-items-center gap-5'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  iconColor='#FFF'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='mt-4 fw-bolder'
                />

                <Button
                  text={i18n.t(`imports.download_problems`)}
                  color='primary'
                  iconColor='#FFF'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={async () => await exportExcel(logsDownload)}
                  btnClass='mt-4 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <div className='mt-4'>
                <Datatable
                  data={logs}
                  backgroundRow='#FBE7E7'
                  headers={[
                    { key: i18n.t(`imports.code`), value: 'code', type: 'default', },
                    { key: i18n.t(`imports.file`), value: 'file', type: 'default', noLimit: true, size: 'w-50' },
                    { key: i18n.t(`imports.status`), value: 'failureReason', type: 'html', size: 'w-50' },
                  ]}
                  color='primary'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const DetalhesImportacaoImagem: FC = () => {
  const { loginInfo } = useAuth();
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`imports.imports`), path: loginInfo.plugintype === 'varejo' ? 'product_varejo/prepare_import_image_product' : 'product_fashion/prepare_import_image_product', isActive: true },
        ]}
      >
        {i18n.t(`imports.problem_title`)}
      </PageTitle>
      <DetalhesImportacaoImagemContent />
    </>
  )
}

export { DetalhesImportacaoImagem }
