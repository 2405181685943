/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const KdsContent: FC = () => (
  <>
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Kitchen Display System</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              Aqui estão listados os KDSs juntamente com suas respectivas ações.
            </span>
          </h3>
          <div className='card-toolbar'>
            <Button
              text='Display de Pedidos'
              color='primary'
              iconColor='#FFF'
              icon='bi bi-display'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {}}
              btnClass='mb-5 fw-bolder me-5'
            />
            <Button
              text='Adicionar KDS'
              color='info'
              iconColor='#FFF'
              icon='fas fa-plus'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {}}
              btnClass='mb-5 fw-bolder'
            />
          </div>
        </div>
        <div className='card-body'>
          <Datatable
            data={[
              {name: 'Bar', date: '05/03/2022 07:52:04'},
              {name: 'Copa', date: '18/02/2022 08:23:14'},
              {name: 'Cozinha', date: '22/08/2022 14:34:45'},
              {name: 'Geral', date: '06/12/2022 10:32:18'},
            ]}
            headers={[
              {key: 'Nome', value: 'name', type: 'default'},
              {key: 'Criado em', value: 'date', type: 'default'},
              {key: 'Ações', value: 'acoes', type: ''},
            ]}
            options={[
              {icon: 'fas fa-pen', path: '/kds/editar', title: 'Editar KDS'},
              {icon: 'fas fa-trash', action: () => {}, path: '', title: 'Excluir KDS'},
              {icon: '', isButton: true, textButton: 'Acessar', action: () => {}, color: 'primary'},
            ]}
            color='primary'
          />
        </div>
      </div>
    </div>
  </>
)

const Kds: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: 'Kitchen Display System', path: 'smart-order/ordem-pedido', isActive: true},
        ]}
      >
        Kitchen Display System
      </PageTitle>
      <KdsContent />
    </>
  )
}

export {Kds}
