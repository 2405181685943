import { FC, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { GET, GET_ADM, PUT, PUT_ADM } from '../../../services/api'
import { cpfToNumber } from '../../../utils/formater'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type Form = {
  name: string
  corporatename: string
  cnpj: string
  subdomain: string
  type: string
  status: boolean
  storeCode: string
  defaultlanguage: string
  defaultcurrency: string
  zipcode: string
  address: {
    street: string
    number: string
    extra: string
    district: string
    city: string
    state: string
  }
}

type User = {
  email: string
  name: string
}

const ContaContent: FC = () => {

  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState<Form>({
    name: '',
    corporatename: '',
    cnpj: '',
    subdomain: '',
    type: '',
    status: true,
    storeCode: '',
    defaultlanguage: 'pt',
    defaultcurrency: 'R$',
    zipcode: '',
    address: {
      street: '',
      number: '',
      extra: '',
      district: '',
      city: '',
      state: ''
    }
  });

  const [user, setUser] = useState<User>({
    email: '',
    name: ''
  });

  const getData = async () => {
    try {
      setLoading(true);
      const res = await GET_ADM(`/pluginspace/${id}`);
      if (res && res.data && res.data[0]) {
        const data = res.data[0]
        let _form = {
          name: data.name,
          corporatename: data.corporateName,
          cnpj: data.cnpj,
          subdomain: data.subdomain,
          type: data.type,
          status: data.status,
          storeCode: data.storeCode,
          defaultlanguage: data.defaultLanguage,
          defaultcurrency: data.defaultCurrency,
          zipcode: '',
          address: {
            street: '',
            number: '',
            extra: '',
            district: '',
            city: '',
            state: ''
          }
        }
        if (data.address && data.address.address) {
          _form.zipcode = data.address.cep
          _form.address = data.address.address
        }
        setForm(_form)
        if (data.user) {
          setUser({
            email: data.user.email,
            name: data.user.name,
          })
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setLoading(false);
      
    }
  }

  const update = async () => {
    try {
      setLoading(true);
      const res = await PUT_ADM(`/pluginspace/ninegrid/${id}`, form);
      setLoading(false);
      if(res.success){
        Swal.fire({
          icon: 'success',
          title: 'Pluginspace alterada',
          text: 'Edição realizada com sucesso!'
        }).then(() => navigate('/pluginspace'))
      } else {
        Swal.fire({
          icon: res.typemsg ? res.typemsg : 'error',
          title: (res.typemsg && res.typemsg === 'warning') ? 'Atenção!' : 'Erro',
          text: res.message ? res.message : 'Erro ao editar pluginspace!'
        })  
      }
    } catch (e:any) {
      setLoading(false);
      Swal.fire({
        icon: (typeof e === 'string' && e.includes('alterada')) ? 'warning' : 'error',
        title: (typeof e === 'string' && e.includes('alterada')) ? 'Atenção' : 'Erro',
        text: (e.response && e.response.message && e.response.message[0]) ? 
          e.response.message[0] : 
          (typeof e === 'string' ? e : 'Erro ao editar pluginspace!')
      })
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const getCEP = async (value: string) => {
    value = value.replace(/[^\d]/g, "")
    if (value && value.length === 8) {
      try {
        setLoading(true)
        const res = await GET(`https://viacep.com.br/ws/${value}/json/`)
        setForm({ ...form, address: { ...form.address, street: res.logradouro, district: res.bairro, city: res.localidade, state: res.uf } })
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
      }
    }
  }

  return (
    <>
      {loading ? <Loading /> :
      <div className='row g-5 gx-xxl-12'>
        <div className='d-flex justify-content-between flex-wrap'>
          <div style={{flex: 1}}>
            <div className='w-100 card'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>Dados da Conta</span>
                  <BreadCrumbs />
                </h3>
              </div>
              <div className='px-10 py-5'>
                <Input
                  inputAttr={{
                    type: 'cnpj',
                  }}
                  placeholder='CNPJ'
                  label='CNPJ'
                  value={form.cnpj || ''}
                  change={(value: any) => setForm({ ...form, cnpj: value.replace(/[^\d]/g, "") })}
                />
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Razão Social'
                  label='Razão Social'
                  value={form.corporatename || ''}
                  change={(value: any) => setForm({ ...form, corporatename: value })}
                />
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Nome do estabelecimento'
                  label='Nome do estabelecimento'
                  value={form.name || ''}
                  change={(value: any) => setForm({ ...form, name: value })}
                />
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Subdomínio'
                  label='Subdomínio ( subdomínio.ninegrid.com.br )'
                  value={form.subdomain || ''}
                  change={(value: any) => setForm({ ...form, subdomain: value })}
                />
                {/* <Input
                inputAttr={{
                  type: 'select',
                }}
                label='Tipo de Conta'
                options={types}
                value={type}
              /> */}
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Código'
                  label='Código'
                  value={form.storeCode || ''}
                  change={(value: any) => setForm({ ...form, storeCode: value })}
                />
                <Input
                  inputAttr={{
                    type: 'select',
                  }}
                  label='Idioma padrão'
                  options={[
                    { select: 'Português', value: 'pt' }
                  ]}
                  value={form.defaultlanguage || ''}
                  change={(value: any) => setForm({ ...form, defaultlanguage: value })}
                />
                <Input
                  inputAttr={{
                    type: 'select',
                  }}
                  label='Moeda padrão'
                  options={[
                    { select: 'Real', value: 'R$' }
                  ]}
                  value={form.defaultcurrency || ''}
                  change={(value: any) => setForm({ ...form, defaultcurrency: value })}
                />
              </div>
            </div>
          </div>
          <div className='justify-content-between' style={{flex: 1}}>
            <div className='w-100 card ms-5'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>Login do responsável da conta</span>
                </h3>
              </div>
              <div className='px-10 py-5'>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Nome do responsável'
                  label='Nome do responsável'
                  disabled={true}
                  value={user.name}
                />

                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Login'
                  label='Login'
                  disabled={true}
                  value={user.email}
                />
              </div>
            </div>
            <br />
            <div className='w-100 card ms-5'>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>Endereço</span>
                </h3>
              </div>
              <div className='px-10 py-5'>
                <div className='d-flex justify-content-between'>
                  <div className='me-3 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'cep',
                      }}
                      placeholder='CEP'
                      label='CEP'
                      value={form.zipcode || ''}
                      change={(value: any) => setForm({ ...form, zipcode: cpfToNumber(value) })}
                      blur={(value: string) => getCEP(value)}
                    />
                  </div>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder='Logradouro'
                    label='Logradouro'
                    value={form.address.street || ''}
                    change={(value: any) => setForm({ ...form, address: { ...form.address, street: value } })}
                  />
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='me-3 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'default',
                      }}
                      placeholder='Número'
                      label='Número'
                      minNumber={0}
                      value={form.address.number || ''}
                      change={(value: any) => setForm({ ...form, address: { ...form.address, number: value } })}
                    />
                  </div>
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder='Complemento'
                    label='Complemento'
                    value={form.address.extra || ''}
                    change={(value: any) => setForm({ ...form, address: { ...form.address, extra: value } })}
                  />
                </div>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Bairro'
                  label='Bairro'
                  value={form.address.district || ''}
                  change={(value: any) => setForm({ ...form, address: { ...form.address, district: value } })}
                />
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Cidade'
                  label='Cidade'
                  value={form.address.city || ''}
                  change={(value: any) => setForm({ ...form, address: { ...form.address, city: value } })}
                />
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Estado'
                  label='Estado'
                  value={form.address.state || ''}
                  change={(value: any) => setForm({ ...form, address: { ...form.address, state: value } })}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className='d-flex'>
          <div className='card w-100'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 mb-1'>Identidade Visual</span>
              </h3>
            </div>
            <div className='d-flex flex-wrap my-4 px-10 py-5'>
              <Input
                inputAttr={{
                  type: 'image',
                }}
                label='Logotipo'
              />
              <div className='ms-10'>
                <Input
                  inputAttr={{
                    type: 'color',
                  }}
                  label='Cor pluginspace'
                />
              </div>
              <div className='mx-10'>
                <Input
                  inputAttr={{
                    type: 'color',
                  }}
                  label='Fundo Logo'
                />
              </div>
              <Input
                inputAttr={{
                  type: 'select',
                }}
                label='Tema'
                options={[
                  { select: 'Escuro', value: 'escuro' },
                  { select: 'Claro', value: 'claro' },
                ]}
              />
            </div>
          </div>
        </div> */}
        <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
          <Button
              text='Voltar'
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                Swal.fire({
                  title: 'Você tem certeza?',
                  text: 'Os dados que foram digitados serão perdidos.',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Sim, voltar!',
                  cancelButtonText: 'Não, cancelar!',
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate('/pluginspace')
                  }
                })
              }}
              btnClass='fw-bolder me-3'
            />
          <Button
            text='Salvar'
            color='primary'
            size='small'
            horizontalPadding={5}
            btnAttr={{
              type: 'button',
            }}
            click={update}
            btnClass='fw-bolder'
          />
        </div>
      </div>
      }
    </>
  )
}

const EditarPluginspace: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Contas', path: 'pluginspace', isActive: true },
          { title: 'Criar Conta', path: 'pluginspace/conta', isActive: true },
        ]}
      >
        Editar Conta
      </PageTitle>
      <ContaContent />
    </>
  )
}

export { EditarPluginspace }
