import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import {DELETE, GET, POST, PUT} from '../../../../services/api'
import {Loading} from '../../../../components/Loading/Loading'
import {ModalDelete} from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type MeasurementResponseType = {
  id: number
  description: string
  shortname: string
  code: string
  status: boolean
}

const UnidadesMedidaContent: FC = () => {
  const {showWrapperModal, showDeleteModal} = useModals()
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState<'creating' | 'editing'>('creating')

  const [measurement, setMeasurement] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [description, setDescription] = useState<string>('')
  const [shortname, setShortName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [status, setStatus] = useState<boolean>(true)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      //setLoading(true)
      const switchMeasurement = await PUT(`/measurement/${Id}`, {
        status: !status,
      })
      if(switchMeasurement.success){
        const newMeasurement = measurement.map((unity)=> {
          if(unity.id === id){
            unity.status = !status;
          }
          return unity;
        })
        setMeasurement(newMeasurement)
      }
      //setLoading(false)
    } catch (e) {
      //setLoading(false)
      console.log(e)
    }
  }

  const getMeasurement = async (loading = true) => {
    try {
      setLoading(loading)
      const res = await GET(`/measurement`)
      const measurements = res.data.map((row: MeasurementResponseType) => {
        return {
          id: row.id,
          description: row.description,
          shortname: row.shortname,
          code: row.code,
          status: row.status,
        }
      })
      setMeasurement(measurements)
      setMeta(res.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const createMeasurement = async () => {
    try {
      if (!description || !code || !status) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const res = await POST('/measurement', {
          shortname: description,
          description,
          code,
          status: status.toString(),
        })
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Unidade de medida cadastrada',
            text: 'Cadastro realizado com sucesso!',
          }).then(async () => await getMeasurement())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar a unidade de medida!',
          }).then(async () => await getMeasurement())
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar a unidade de medida!',
      }).then(async () => await getMeasurement())
      console.log(e)
    }
  }
  const editMeasurement = async () => {
    try {
      if (!description || !code || !status) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const res = await PUT(`/measurement/${id}`, {
          description,
          code,
          status,
        })
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Unidade de medida alterada',
            text: 'Edição realizada com sucesso!',
          }).then(async () => await getMeasurement())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar a marca!',
          }).then(async () => await getMeasurement())
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar a marca!',
      }).then(async () => await getMeasurement())
      console.log(e)
    }
  }

  const deleteMeasurement = async () => {
    try {
      setLoading(true)
      const res = await DELETE(`/measurement/${id}`)
      if (res.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Unidade de medida deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getMeasurement())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a unidade de medida!',
        }).then(async () => await getMeasurement())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a unidade de medida!',
      }).then(async () => await getMeasurement())
      console.log(e)
    }
  }

  useEffect(() => {
    getMeasurement()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`smart_order.measurements_units`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`smart_order.listened_measurement`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    showWrapperModal(`${i18n.t(`buttons.add`)} ${i18n.t(`smart_order.measurement_unit`)}`)
                    setDescription('')
                    setCode('')
                    setId(null)
                    setAction('creating')
                  }}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={measurement}
                headers={[
                  {key: i18n.t(`smart_order.name`), value: 'description', type: 'default'},
                  {key: i18n.t(`smart_order.code`), value: 'code', type: 'default'},
                  {
                    key: i18n.t(`general.active`),
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      showWrapperModal(`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.measurement_unit`)}`)
                      setAction('editing')
                      setId(item.id)
                      setDescription(item.description)
                      setCode(item.code)
                    },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.measurement_unit`)}`,
                  },
                  // {
                  //   icon: 'fas fa-trash',
                  //   action: (item) => {
                  //     showDeleteModal('Excluir Unidade de Medida')
                  //     setId(item.id)
                  //   },
                  //   title: 'Excluir Unidade de Medida',
                  // },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalWrapper
            confirmAction={
              action === 'creating' ? () => createMeasurement() : () => editMeasurement()
            }
          >
            <div className='d-flex justify-content-between align-items-center'>
              <div className='mx-5 flex-grow-1'>
                <Input
                  focus={true}
                  inputAttr={{
                    type: 'default',
                  }}
                  value={description}
                  change={setDescription}
                  placeholder={i18n.t(`smart_order.measurement_unit`)}
                  label={i18n.t(`smart_order.measurement_unit`)}
                />
              </div>
              <div className='mx-5 flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  value={code}
                  change={setCode}
                  placeholder={i18n.t(`smart_order.code`)}
                  label={i18n.t(`smart_order.code`)}
                />
              </div>
              <div className='flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'select',
                  }}
                  value={status}
                  change={setStatus}
                  label={i18n.t(`general.active`)}
                  options={[
                    {select: i18n.t(`general.active`), value: 'true'},
                    {select: i18n.t(`general.inactive`), value: 'false'},
                  ]}
                />
              </div>
            </div>
          </ModalWrapper>

          <ModalDelete confirmAction={() => deleteMeasurement()} />
        </div>
      )}
    </>
  )
}

const UnidadesMedida: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`smart_order.measurements_units`), path: 'measurement_unit', isActive: true},
        ]}
      >
        {i18n.t(`smart_order.measurements_units`)}
      </PageTitle>
      <UnidadesMedidaContent />
    </>
  )
}

export {UnidadesMedida}
