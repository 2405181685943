import { Editor } from '@tinymce/tinymce-react'
import { BaseSyntheticEvent, FC, MouseEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import useAuth from '../../../hooks/useAuth'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type AppconfigResponseType = {
  name: string
  locationId: number
  info: string
  background: null | string
  banner: null | string
  img: null | string
  icon: null | string
  content: {
    noConfirmed?: boolean
    noShadow?: boolean
    qtdProductsPagination: number
    useLogoMenu: boolean
    colorLogo?: string
    useLogoTotvs?: boolean
    pdv: any
    orderCategory: string
    noUseViewMode?: boolean
    webappcolor: {
      backgroundbuttonhome: string
      backgroundcart: string
      backgroundfooter: string
      backgroundheader: string
      backgroundmenu: string
      backgroundstatus: string
      backgroundstatusactive: string
      backgroundtitlebar: string
      backgroundwebapp: string
      fontbutton: string
      fontbuttonhome: string
      fontcart: string
      fontcategory: string
      fontheader: string
      fontinfocart: string
      fontmenu: string
      fontstatus: string
      fonttitlebar: string
      highlightfooter: string
      iconsfooter: string
      iconsheader: string
      icontitlebar: string
      webappbutton: string
    }
    useCategoryList?: boolean
  }
}

type AppconfigResponseSendType = {
  name?: string
  locationId?: number
  info?: string
  background?: null | string
  banner?: null | string
  img?: null | string
  icon?: null | string
  content?: {
    noConfirmed?: boolean
    noShadow?: boolean
    qtdProductsPagination?: number
    useLogoMenu?: boolean
    colorLogo?: string
    useLogoTotvs?: boolean
    pdv?: any
    orderCategory?: string
    noUseViewMode?: boolean
    webappcolor?: {
      backgroundbuttonhome?: string
      backgroundcart?: string
      backgroundfooter?: string
      backgroundheader?: string
      backgroundmenu?: string
      backgroundstatus?: string
      backgroundstatusactive?: string
      backgroundtitlebar?: string
      backgroundwebapp?: string
      fontbutton?: string
      fontbuttonhome?: string
      fontcart?: string
      fontcategory?: string
      fontheader?: string
      fontinfocart?: string
      fontmenu?: string
      fontstatus?: string
      fonttitlebar?: string
      highlightfooter?: string
      iconsfooter?: string
      iconsheader?: string
      icontitlebar?: string
      webappbutton?: string
    }
    useCategoryList?: boolean
  }
}

type LocationResponseType = {
  id: number
  name: string
  type: string
}


type SlidersImage = {
  image: File,
  order: number
}


type ImageSliderAggregator = {
  id: number;
  pluginspaceId: number;
  aggregatorId: number;
  path: string;
  imgSuffix: string;
  order: number;
  typeImg: string;
  created: string;
  updated: string;
  deleted?: any;
}

const EditarWebappContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal, closeWrapperModal, showDeleteModal } = useModals()
  const { id } = useParams()
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [locationId, setLocationId] = useState<null | number>(null)
  const [info, setInfo] = useState('')
  const [background, setBackground] = useState<File | null | string>()
  const [banner, setBanner] = useState<File | null | string>()
  const [img, setImg] = useState<File | null | string>()
  const [icon, setIcon] = useState<File | null | string>()
  const [firstStateBackground, setFirstStateBackground] = useState<null | string>()
  const [firstStateBanner, setFirstStateBanner] = useState<null | string>()
  const [firstStateImg, setFirstStateImg] = useState<null | string>()
  const [firstStateIcon, setFirstStateIcon] = useState<null | string>()

  const [qtdProductsPagination, setQtdProductsPagination] = useState(10)
  const [useLogoMenu, setUseLogoMenu] = useState(false)
  const [colorLogo, setColorLogo] = useState('black')
  const [useLogoTotvs, setUseLogoTotvs] = useState(false)
  const [backgroundbuttonhome, setBackgroundbuttonhome] = useState<string>('')
  const [backgroundcart, setBackgroundcart] = useState<string>('')
  const [backgroundfooter, setBackgroundfooter] = useState<string>('')
  const [backgroundheader, setBackgroundheader] = useState<string>('')
  const [backgroundmenu, setBackgroundmenu] = useState<string>('')
  const [backgroundstatus, setBackgroundstatus] = useState<string>('')
  const [backgroundstatusactive, setBackgroundstatusactive] = useState<string>('')
  const [backgroundtitlebar, setBackgroundtitlebar] = useState<string>('')
  const [backgroundwebapp, setBackgroundwebapp] = useState<string>('')
  const [fontbutton, setFontbutton] = useState<string>('')
  const [fontbuttonhome, setFontbuttonhome] = useState<string>('')
  const [fontcart, setFontcart] = useState<string>('')
  const [fontcategory, setFontcategory] = useState<string>('')
  const [fontheader, setFontheader] = useState<string>('')
  const [fontinfocart, setFontinfocart] = useState<string>('')
  const [fontmenu, setFontmenu] = useState<string>('')
  const [fontstatus, setFontstatus] = useState<string>('')
  const [fonttitlebar, setFonttitlebar] = useState<string>('')
  const [highlightfooter, setHighlightfooter] = useState<string>('')
  const [iconsfooter, setIconsfooter] = useState<string>('')
  const [iconsheader, setIconsheader] = useState<string>('')
  const [icontitlebar, setIcontitlebar] = useState<string>('')
  const [webappbutton, setWebappbutton] = useState<string>('')
  const [pdv, setPdv] = useState<any>()
  const [useCategoryList, setUseCategoryList] = useState(false)
  const [noUseViewMode, setNoUseViewMode] = useState(false)
  const [noShadow, setNoShadow] = useState(false)
  const [noConfirmed, setConfirmed] = useState(false)
  const [loadingImages, setLoadingImages] = useState(false)
  const [orderCategory, setOrderCategory] = useState('category')

  const [canAlert, setCanAlert] = useState(false);

  /* Campos utilizados para o slider do agregador */

  const [slidesImageAggregator, setSlidesImageAggregator] = useState<any>()
  const [slidesImageAggregatorOrder, setSlidesImageAggregatorOrder] = useState<number>(0)

  const [slidesImageAggregatorPreviewHomePage, setSlidesImageAggregatorPreviewHomePage] = useState<SlidersImage[]>([])
  const [slidesImageAggregatorPreviewInitial, setSlidesImageAggregatorPreviewInitial] = useState<SlidersImage[]>([])

  const [ImageSlidesAggregatorInitial, setImageSlidesAggregatorInitial] = useState<ImageSliderAggregator[]>([])
  const [ImageSlidesAggregatorHomePage, setImageSlidesAggregatorHomePage] = useState<ImageSliderAggregator[]>([])

  const [action, setAction] = useState<'initial' | 'homepage'>('initial')

  const [idImageSlider, setIdImageSlider] = useState<number>(0)

  const getAppconfig = async () => {
    try {
      setLoading(true)
      const appRes = await GET(`/appconfig/${id}`)
      const appconfig: AppconfigResponseType = appRes.data[0]
      setName(appconfig.name)
      setLocationId(appconfig.locationId)
      setInfo(appconfig.info)

      setBackground(
        appconfig.background && appconfig.background.substring(0, 4) === 'http'
          ? appconfig.background
          : appconfig.background
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.background
            : null
      )
      setBanner(
        appconfig.banner && appconfig.banner.substring(0, 4) === 'http'
          ? appconfig.banner
          : appconfig.banner
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.banner
            : null
      )
      setImg(
        appconfig.img && appconfig.img.substring(0, 4) === 'http'
          ? appconfig.img
          : appconfig.img
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.img
            : null
      )
      setIcon(
        appconfig.icon && appconfig.icon.substring(0, 4) === 'http'
          ? appconfig.icon
          : appconfig.icon
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.icon
            : null
      )
      setFirstStateBackground(
        appconfig.background && appconfig.background.substring(0, 4) === 'http'
          ? appconfig.background
          : appconfig.background
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.background
            : null
      )
      setFirstStateBanner(
        appconfig.banner && appconfig.banner.substring(0, 4) === 'http'
          ? appconfig.banner
          : appconfig.banner
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.banner
            : null
      )
      setFirstStateImg(
        appconfig.img && appconfig.img.substring(0, 4) === 'http'
          ? appconfig.img
          : appconfig.img
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.img
            : null
      )
      setFirstStateIcon(
        appconfig.icon && appconfig.icon.substring(0, 4) === 'http'
          ? appconfig.icon
          : appconfig.icon
            ? process.env.REACT_APP_AWS_S3_URL + appconfig.icon
            : null
      )

      setPdv(appconfig.content.pdv)

      setQtdProductsPagination(appconfig.content.qtdProductsPagination)
      setUseLogoMenu(appconfig.content.useLogoMenu)
      setConfirmed(appconfig.content.noConfirmed ? appconfig.content.noConfirmed : false)
      setNoShadow(appconfig.content.noShadow ? appconfig.content.noShadow : false)
      setColorLogo(appconfig.content.colorLogo ? appconfig.content.colorLogo : 'black')
      setUseLogoTotvs(false)
      setBackgroundbuttonhome(appconfig.content.webappcolor.backgroundbuttonhome)
      setBackgroundcart(appconfig.content.webappcolor.backgroundcart)
      setBackgroundfooter(appconfig.content.webappcolor.backgroundfooter)
      setBackgroundheader(appconfig.content.webappcolor.backgroundheader)
      setBackgroundmenu(appconfig.content.webappcolor.backgroundmenu)
      setBackgroundstatus(appconfig.content.webappcolor.backgroundstatus)
      setBackgroundstatusactive(appconfig.content.webappcolor.backgroundstatusactive)
      setBackgroundtitlebar(appconfig.content.webappcolor.backgroundtitlebar)
      setBackgroundwebapp(appconfig.content.webappcolor.backgroundwebapp)
      setFontbutton(appconfig.content.webappcolor.fontbutton)
      setFontbuttonhome(appconfig.content.webappcolor.fontbuttonhome)
      setFontcart(appconfig.content.webappcolor.fontcart)
      setFontcategory(appconfig.content.webappcolor.fontcategory)
      setFontheader(appconfig.content.webappcolor.fontheader)
      setFontinfocart(appconfig.content.webappcolor.fontinfocart)
      setFontmenu(appconfig.content.webappcolor.fontmenu)
      setFontstatus(appconfig.content.webappcolor.fontstatus)
      setFonttitlebar(appconfig.content.webappcolor.fonttitlebar)
      setHighlightfooter(appconfig.content.webappcolor.highlightfooter)
      setIconsfooter(appconfig.content.webappcolor.iconsfooter)
      setIconsheader(appconfig.content.webappcolor.iconsheader)
      setIcontitlebar(appconfig.content.webappcolor.icontitlebar)
      setWebappbutton(appconfig.content.webappcolor.webappbutton)
      setUseCategoryList(
        appconfig.content.useCategoryList ? appconfig.content.useCategoryList : false
      )
      setNoUseViewMode(
        appconfig.content.noUseViewMode ? appconfig.content.noUseViewMode : false
      )
      setOrderCategory(
        appconfig.content.orderCategory ? appconfig.content.orderCategory : 'category'
      )
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }

  const saveAppconfig = async () => {
    try {
      if (!name || !locationId) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        setLoading(true)

        let appconfigData: AppconfigResponseSendType = {
          name,
          info,
          locationId,
        }
        if (img && img !== firstStateImg) {
          if (firstStateImg) deleteImage(firstStateImg)
          const formData = new FormData()
          formData.append('file', img)
          formData.append('nameImage', `appconfig-logo-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.img = imageRes.success ? imageRes.image : ''
        }
        if (!img || img === '') {
          appconfigData.img = img
        }

        if (icon && icon !== firstStateIcon) {
          if (firstStateIcon) deleteImage(firstStateIcon)
          const formData = new FormData()
          formData.append('file', icon)
          formData.append('nameImage', `appconfig-icon-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.icon = imageRes.success ? imageRes.image : ''
        }
        if (!icon || icon === '') {
          appconfigData.icon = icon
        }

        if (banner && banner !== firstStateBanner) {
          if (firstStateBanner) deleteImage(firstStateBanner)
          const formData = new FormData()
          formData.append('file', banner)
          formData.append('nameImage', `appconfig-banner-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.banner = imageRes.success ? imageRes.image : ''
        }
        if (!banner || banner === '') {
          appconfigData.banner = banner
        }

        if (background && background !== firstStateBackground) {
          if (firstStateBackground) deleteImage(firstStateBackground)
          const formData = new FormData()
          formData.append('file', background)
          formData.append('nameImage', `appconfig-background-${uuid()}.jpg`)
          formData.append('originalSize', 'true')
          const imageRes = await POST('/upload-images/upload-one', formData)
          appconfigData.background = imageRes.success ? imageRes.image : ''
        }
        if (!background || background === '') {
          appconfigData.background = background
        }

        appconfigData.content = {
          qtdProductsPagination,
          useLogoMenu,
          colorLogo,
          useLogoTotvs,
          noConfirmed,
          noShadow,
          orderCategory,
          noUseViewMode,
          pdv,
          webappcolor: {
            backgroundbuttonhome,
            backgroundcart,
            backgroundfooter,
            backgroundheader,
            backgroundmenu,
            backgroundstatus,
            backgroundstatusactive,
            backgroundtitlebar,
            backgroundwebapp,
            fontbutton,
            fontbuttonhome,
            fontcart,
            fontcategory,
            fontheader,
            fontinfocart,
            fontmenu,
            fontstatus,
            fonttitlebar,
            highlightfooter,
            iconsfooter,
            iconsheader,
            icontitlebar,
            webappbutton,
          },
          useCategoryList,
        }
        const appconfigRes = await PUT(`/appconfig/${id}`, appconfigData)
        setLoading(false)
        if (appconfigRes.success === true) {
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Configuração alterada',
            text: 'Edição realizada com sucesso!',
          }).then(() => navigate('/webapp'))
        } else {
          setCanAlert(true)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar configuração!',
          })
        }
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e && typeof (e) === 'string' ? e : 'Erro ao alterar configuração!',
      })
    }
  }

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('webapp-personalize-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    const configSaved = localStorage.getItem('webapp-personalize-saved')
    if (configSaved && JSON.parse(configSaved)) loadLocal()
    else getAppconfig()
    if (loginInfo.plugintype === 'aggregator') {
      getImageSlider()
    }
  }, [])

  useEffect(() => {
    return () => {
      if (canAlert && !window.location.pathname.includes(`/webapp/editar/${id}`)) {
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/webapp/editar/${id}`)
          } else {
            localStorage.removeItem('webapp-personalize-saved')
          }
        })
      } else if (!canAlert) localStorage.removeItem('webapp-personalize-saved')
    }
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const webappSaved = localStorage.getItem('webapp-personalize-saved')
    if (webappSaved && JSON.parse(webappSaved)) {
      setLoading(true)
      const jsonWebapp = JSON.parse(webappSaved);
      setName(jsonWebapp.name)
      setLocationId(jsonWebapp.locationId)
      setInfo(jsonWebapp.info)
      if (typeof jsonWebapp.background === 'string') setBackground(jsonWebapp.background)
      if (typeof jsonWebapp.banner === 'string') setBanner(jsonWebapp.banner)
      if (typeof jsonWebapp.img === 'string') setImg(jsonWebapp.img)
      if (typeof jsonWebapp.icon === 'string') setIcon(jsonWebapp.icon)
      if (typeof jsonWebapp.firstStateBackground === 'string') setFirstStateBackground(jsonWebapp.firstStateBackground)
      if (typeof jsonWebapp.firstStateBanner === 'string') setFirstStateBanner(jsonWebapp.firstStateBanner)
      if (typeof jsonWebapp.firstStateImg === 'string') setFirstStateImg(jsonWebapp.firstStateImg)
      if (typeof jsonWebapp.firstStateIcon === 'string') setFirstStateIcon(jsonWebapp.firstStateIcon)
      setQtdProductsPagination(jsonWebapp.qtdProductsPagination)
      setUseLogoMenu(jsonWebapp.useLogoMenu)
      setColorLogo(jsonWebapp.colorLogo)
      setUseLogoTotvs(jsonWebapp.useLogoTotvs)
      setBackgroundbuttonhome(jsonWebapp.backgroundbuttonhome)
      setBackgroundcart(jsonWebapp.backgroundcart)
      setBackgroundfooter(jsonWebapp.backgroundfooter)
      setBackgroundheader(jsonWebapp.backgroundheader)
      setBackgroundmenu(jsonWebapp.backgroundmenu)
      setBackgroundstatus(jsonWebapp.backgroundstatus)
      setBackgroundstatusactive(jsonWebapp.backgroundstatusactive)
      setBackgroundtitlebar(jsonWebapp.backgroundtitlebar)
      setBackgroundwebapp(jsonWebapp.backgroundwebapp)
      setFontbutton(jsonWebapp.fontbutton)
      setFontbuttonhome(jsonWebapp.fontbuttonhome)
      setFontcart(jsonWebapp.fontcart)
      setFontcategory(jsonWebapp.fontcategory)
      setFontheader(jsonWebapp.fontheader)
      setFontinfocart(jsonWebapp.fontinfocart)
      setFontmenu(jsonWebapp.fontmenu)
      setFontstatus(jsonWebapp.fontstatus)
      setFonttitlebar(jsonWebapp.fonttitlebar)
      setHighlightfooter(jsonWebapp.highlightfooter)
      setIconsfooter(jsonWebapp.iconsfooter)
      setIconsheader(jsonWebapp.iconsheader)
      setIcontitlebar(jsonWebapp.icontitlebar)
      setWebappbutton(jsonWebapp.webappbutton)
      setPdv(jsonWebapp.pdv)
      setUseCategoryList(jsonWebapp.useCategoryList)
      setNoShadow(jsonWebapp.noShadow)
      setOrderCategory(jsonWebapp.orderCategory)
      localStorage.removeItem('webapp-personalize-saved')
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }
  }

  const saveLocal = () => {
    localStorage.setItem('webapp-personalize-saved', JSON.stringify({
      name,
      locationId,
      info,
      background,
      banner,
      img,
      icon,
      firstStateBackground,
      firstStateBanner,
      firstStateImg,
      firstStateIcon,
      qtdProductsPagination,
      useLogoMenu,
      colorLogo,
      useLogoTotvs,
      backgroundbuttonhome,
      backgroundcart,
      backgroundfooter,
      backgroundheader,
      backgroundmenu,
      backgroundstatus,
      backgroundstatusactive,
      backgroundtitlebar,
      backgroundwebapp,
      fontbutton,
      fontbuttonhome,
      fontcart,
      fontcategory,
      fontheader,
      fontinfocart,
      fontmenu,
      fontstatus,
      fonttitlebar,
      highlightfooter,
      iconsfooter,
      iconsheader,
      icontitlebar,
      webappbutton,
      pdv,
      useCategoryList,
      noShadow,
      orderCategory,
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    name,
    locationId,
    info,
    background,
    banner,
    img,
    icon,
    firstStateBackground,
    firstStateBanner,
    firstStateImg,
    firstStateIcon,
    qtdProductsPagination,
    useLogoMenu,
    colorLogo,
    useLogoTotvs,
    backgroundbuttonhome,
    backgroundcart,
    backgroundfooter,
    backgroundheader,
    backgroundmenu,
    backgroundstatus,
    backgroundstatusactive,
    backgroundtitlebar,
    backgroundwebapp,
    fontbutton,
    fontbuttonhome,
    fontcart,
    fontcategory,
    fontheader,
    fontinfocart,
    fontmenu,
    fontstatus,
    fonttitlebar,
    highlightfooter,
    iconsfooter,
    iconsheader,
    icontitlebar,
    webappbutton,
    pdv,
    useCategoryList,
    noShadow,
    orderCategory,
  ])

  async function addImagesSlideInitial() {
    closeWrapperModal();
    try {
      setLoading(true)

      await Promise.all(slidesImageAggregatorPreviewInitial.map(async el => {
        const formData = new FormData()
        formData.append('file', el.image)
        formData.append('nameImage', `slider/slider-${uuid()}.jpg`)
        formData.append('originalSize', 'true')

        const imageRes = await POST('/upload-images/upload-one', formData)

        if (imageRes && imageRes.success) {
          const urlImage = imageRes.image.split('https://s3.amazonaws.com/img.ninegrid.com/')[1]
          const schema = {
            pluginspaceId: 0,
            order: el.order ? el.order : 1,
            path: 'https://s3.amazonaws.com/img.ninegrid.com/',
            imgSuffix: urlImage,
            typeImg: 'image',
          }

          const response = await POST('pluginspaceimages', schema)

          console.log(response)


        }



      }))

      setLoading(false)

      Swal.fire({
        icon: 'success',
        titleText: "Sucesso",
        text: 'Cadastro das imagens no slider concluido',
      }).then(() => {
        setSlidesImageAggregatorPreviewInitial([])
        getImageSlider()
      })


    } catch (error) {
      setLoading(false)
      console.log(error)

      Swal.fire(
        {
          icon: 'error',
          title: "Erro",
          text: 'Ocorreu um erro ao salvar as imagens'
        }
      )
    }
  }
  async function addImagesSlideHomePage() {
    closeWrapperModal();
    try {
      setLoading(true)

      await Promise.all(slidesImageAggregatorPreviewHomePage.map(async el => {
        const formData = new FormData()
        formData.append('file', el.image)
        formData.append('nameImage', `slider/slider-${uuid()}.jpg`)
        formData.append('originalSize', 'true')

        const imageRes = await POST('/upload-images/upload-one', formData)

        if (imageRes && imageRes.success) {
          const urlImage = imageRes.image.split('https://s3.amazonaws.com/img.ninegrid.com/')[1]
          const schema = {
            pluginspaceId: 0,
            order: el.order ? el.order : 1,
            path: 'https://s3.amazonaws.com/img.ninegrid.com/',
            imgSuffix: urlImage,
            typeImg: 'mainpicture',
          }

          const response = await POST('pluginspaceimages', schema)
        }



      }))

      setLoading(false)

      Swal.fire({
        icon: 'success',
        titleText: "Sucesso",
        text: 'Cadastro das imagens no slider concluido',
      }).then(() => {
        setSlidesImageAggregatorPreviewInitial([])
        getImageSlider()
      })


    } catch (error) {
      setLoading(false)
      console.log(error)

      Swal.fire(
        {
          icon: 'error',
          title: "Erro",
          text: 'Ocorreu um erro ao salvar as imagens'
        }
      )
    }
  }

  function setImageToSliderPreviewInitial() {

    if (!slidesImageAggregator) {
      closeWrapperModal();
      Swal.fire({
        icon: 'warning',
        titleText: "Atenção",
        text: 'Insira uma imagem valida para adicionar'
      }).then(() => showWrapperModal('Adicionar images ao Slider'))
    } else {
      setLoadingImages(true)

      const sliderAuxArray = slidesImageAggregatorPreviewInitial

      const newSlider: SlidersImage = {
        image: slidesImageAggregator,
        order: slidesImageAggregatorOrder
      }

      sliderAuxArray.push(newSlider)


      setSlidesImageAggregator(null)
      setSlidesImageAggregatorOrder(0)

      const newArray = sliderAuxArray.sort((a, b) => a.order - b.order);

      setSlidesImageAggregatorPreviewInitial(newArray)

      setTimeout(() => {
        setLoadingImages(false)
      }, 50)

    }




    return
  }

  function setImageToSliderPreviewHomePage() {

    if (!slidesImageAggregator) {
      closeWrapperModal();
      Swal.fire({
        icon: 'warning',
        titleText: "Atenção",
        text: 'Insira uma imagem valida para adicionar'
      }).then(() => showWrapperModal('Adicionar images ao Slider'))
    } else {
      setLoadingImages(true)

      const sliderAuxArray = slidesImageAggregatorPreviewHomePage

      const newSlider: SlidersImage = {
        image: slidesImageAggregator,
        order: slidesImageAggregatorOrder
      }

      sliderAuxArray.push(newSlider)


      setSlidesImageAggregator(null)
      setSlidesImageAggregatorOrder(0)

      const newArray = sliderAuxArray.sort((a, b) => a.order - b.order);

      setSlidesImageAggregatorPreviewHomePage(newArray)

      setTimeout(() => {
        setLoadingImages(false)
      }, 50)

    }




    return
  }

  async function getImageSlider() {
    try {
      setLoading(true)

      const images = await GET('pluginspaceimages');

      if (images.data) {
        setImageSlidesAggregatorInitial(images.data.data.filter((data: any) => data.typeImg === 'image'))
        setImageSlidesAggregatorHomePage(images.data.data.filter((data: any) => data.typeImg === 'mainpicture'))
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }



  const deleteImageSlider = async () => {
    try {
      setLoading(true)
      const gridRes = await DELETE(`/pluginspaceimages/${idImageSlider}`)
      if (gridRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Imagem deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getImageSlider())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a imagem!',
        }).then(async () => await getImageSlider())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a imagem!',
      }).then(async () => await getImageSlider())
      console.log(e)
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex flex-row justify-content-between flex-wrap gap-5 '>
            <div className='card' style={{ flex: 1, minWidth: '30em' }}>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>
                    {i18n.t(`webapp.webapp_data`)}
                  </span>

                  <BreadCrumbs />
                </h3>
              </div>
              <div className='px-10 py-5'>
                <div className='w-100 flex-wrap gap-5'>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='w-100'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`webapp.name`)}
                        label={i18n.t(`webapp.name`)}
                        value={name}
                        change={(value: string) => {
                          setCanAlert(true)
                          setName(value)
                        }}
                      />
                    </div>
                  </div>
                  {loginInfo.plugintype !== 'aggregator' &&
                    <div className='d-flex justify-content-between align-items-end flex-wrap gap-5'>
                      <div className='w-100'>
                        <Input
                          inputAttr={{
                            type: 'number',
                          }}
                          maxNumber={10}
                          label={i18n.t(`webapp.product_pagination`)}
                          value={qtdProductsPagination}
                          change={(value: string) => {
                            setCanAlert(true)
                            setQtdProductsPagination(parseInt(value))
                          }}
                        />
                      </div>
                      <div className='w-100'>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          placeholder={i18n.t(`webapp.logo_ninegrid`)}
                          label={i18n.t(`webapp.logo_ninegrid`)}
                          value={colorLogo}
                          change={(value: string) => {
                            setCanAlert(true)
                            setColorLogo(value)
                          }}
                          options={[
                            { select: i18n.t(`webapp.light`), value: 'black' },
                            { select: i18n.t(`webapp.dark`), value: 'white' },
                          ]}
                        />
                      </div>
                    </div>

                  }
                  {loginInfo.plugintype !== 'aggregator' &&
                    <>
                      <div className='d-flex justify-content-between align-items-start mt-2 flex-wrap gap-5'>
                        {/*
                    <div className='w-100 '>
                      <Input
                        inputAttr={{
                          type: 'checkbox',
                        }}
                        label={i18n.t(`webapp.logo_totvs`)}
                        checkSwift={false}
                        checked={useLogoTotvs}
                        value={useLogoTotvs}
                        change={(value: boolean) => setUseLogoTotvs(value)}
                      />
                    </div>
                    */}
                        <div className='w-100 '>
                          <Input
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            label={i18n.t(`webapp.use_icon_menu`)}
                            checkSwift={false}
                            checked={useLogoMenu}
                            value={useLogoMenu}
                            change={(value: boolean) => {
                              setCanAlert(true)
                              setUseLogoMenu(value)
                            }}
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-start mt-2 flex-wrap gap-2'>
                        <div className='w-100 '>
                          <Input
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            label={i18n.t(`webapp.use_category_list`)}
                            checkSwift={false}
                            checked={useCategoryList}
                            value={useCategoryList}
                            change={(value: boolean) => setUseCategoryList(value)}
                          />
                        </div>
                        <div className='w-100 '>
                          <Input
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            label={i18n.t(`webapp.remove_show_mode`)}
                            checkSwift={false}
                            checked={noUseViewMode}
                            value={noUseViewMode}
                            change={(value: boolean) => setNoUseViewMode(value)}
                          />
                        </div>
                        <div className='w-100 '>
                          <Input
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            label={i18n.t(`webapp.no_shadow`)}
                            checkSwift={false}
                            checked={noShadow}
                            value={noShadow}
                            change={(value: boolean) => setNoShadow(value)}
                          />
                        </div>
                        {/* <div className='w-100 '>
                          <Input
                            inputAttr={{
                              type: 'checkbox',
                            }}
                            label={i18n.t(`webapp.confirmed_comanda`)} Esta tarefa será finalizada em breve
                            checkSwift={false}
                            checked={noConfirmed}
                            value={noConfirmed}
                            change={(value: boolean) => setConfirmed(value)}
                          />
                        </div> */}
                      </div>
                      <div className='d-flex justify-content-between align-items-start mt-2 flex-wrap gap-5'>
                        <div className='w-100 '>
                          <Input
                            inputAttr={{
                              type: 'select',
                            }}
                            placeholder={i18n.t(`webapp.is_order_number`)}
                            label={i18n.t(`webapp.is_order_number`)}
                            value={orderCategory}
                            change={(value: string) => setOrderCategory(value)}
                            options={[
                              { select: i18n.t(`webapp.order_number`), value: 'order' },
                              { select: i18n.t(`webapp.order_category`), value: 'category' },
                            ]}
                          />
                        </div>
                        <div className='w-100 '></div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div className='w-100'>
                          <div className='mt-6'>
                            <label className='form-label fw-normal'>
                              {i18n.t(`webapp.description`)}
                            </label>
                            <Editor
                              apiKey={process.env.REACT_APP_TINY_KEY}
                              onEditorChange={(value: string) => setInfo(value)}
                              value={info}
                              init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                  'advlist autolink lists link image charmap print preview anchor',
                                  'searchreplace visualblocks code fullscreen',
                                  'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar:
                                  'undo redo | formatselect | ' +
                                  'bold italic forecolor backcolor | alignleft aligncenter ' +
                                  'alignright alignjustify | bullist numlist outdent indent | ' +
                                  'removeformat | help',
                                content_style:
                                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  }

                  <div className='d-flex justify-content-between align-items-center mt-6 flex-wrap gap-5'>
                    <div className='justify-content-center d-flex'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label='Background WebApp'
                        value={background}
                        change={(value: BaseSyntheticEvent) => {
                          setCanAlert(true)
                          setBackground(value ? value.target.files[0] : null)
                        }}
                      />
                    </div>
                    <div className='justify-content-center d-flex'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label='Logo Webapp'
                        value={img}
                        change={(value: BaseSyntheticEvent) => {
                          setCanAlert(true)
                          setImg(value ? value.target.files[0] : null)
                        }}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-6 flex-wrap gap-5'>
                    <div className='justify-content-center d-flex'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label={`${i18n.t(`webapp.icon`)} Webapp`}
                        value={icon}
                        change={(value: BaseSyntheticEvent) => {
                          setCanAlert(true)
                          setIcon(value ? value.target.files[0] : null)
                        }}
                      />
                    </div>
                    <div className='justify-content-center d-flex'>
                      {loginInfo.plugintype === 'aggregator' && <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label='Banner Webapp'
                        value={banner}
                        change={(value: BaseSyntheticEvent) => {
                          setCanAlert(true)
                          setBanner(value ? value.target.files[0] : null)
                        }}
                      />}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='card' style={{ flex: 1, minWidth: '30em' }}>
              <div className='card-header py-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>
                    {i18n.t(`webapp.color_setting`)}
                  </span>
                </h3>
              </div>
              {
                loginInfo.plugintype === 'aggregator' ?
                  (<div className='px-10 py-5'>
                    <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.background_webapp`)}
                          value={backgroundwebapp}
                          change={(value: string) => {
                            setCanAlert(true)
                            setBackgroundwebapp(value)
                          }
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.background_header`)}
                          value={backgroundheader}
                          change={(value: string) => {
                            setCanAlert(true)
                            setBackgroundheader(value)
                          }
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.text_header`)}
                          value={fontheader}
                          change={(value: string) => {
                            setCanAlert(true)
                            setFontheader(value)
                          }
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.icon_header`)}
                          value={iconsheader}
                          change={(value: string) => {
                            setCanAlert(true)
                            setIconsheader(value)
                          }
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.background_footer`)}
                          value={backgroundfooter}
                          change={(value: string) => {
                            setCanAlert(true)
                            setBackgroundfooter(value)
                          }
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.icon_footer`)}
                          value={iconsfooter}
                          change={(value: string) => {
                            setCanAlert(true)
                            setIconsfooter(value)
                          }
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.home_button`)}
                          value={backgroundbuttonhome}
                          change={(value: string) => {
                            setCanAlert(true)
                            setBackgroundbuttonhome(value)
                          }
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.text_home_button`)}
                          value={fontbuttonhome}
                          change={(value: string) => {
                            setCanAlert(true)
                            setFontbuttonhome(value)
                          }
                          }
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label='Botões do Webappp'
                          value={webappbutton}
                          change={(value: string) => {
                            setCanAlert(true)
                            setWebappbutton(value)
                          }
                          }
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <Input
                          inputAttr={{
                            type: 'color',
                          }}
                          label={i18n.t(`webapp.text_button`)}
                          value={fontbutton}
                          change={(value: string) => {
                            setCanAlert(true)
                            setFontbutton(value)
                          }
                          }
                        />
                      </div>
                    </div>
                  </div>) : (
                    <div className='px-10 py-5'>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.background_webapp`)}
                            value={backgroundwebapp}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundwebapp(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.background_header`)}
                            value={backgroundheader}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundheader(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_header`)}
                            value={fontheader}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontheader(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.icon_header`)}
                            value={iconsheader}
                            change={(value: string) => {
                              setCanAlert(true)
                              setIconsheader(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.background_footer`)}
                            value={backgroundfooter}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundfooter(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.icon_footer`)}
                            value={iconsfooter}
                            change={(value: string) => {
                              setCanAlert(true)
                              setIconsfooter(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.highlight_footer`)}
                            value={highlightfooter}
                            change={(value: string) => {
                              setCanAlert(true)
                              setHighlightfooter(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.notification_cart`)}
                            value={backgroundcart}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundcart(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.notification_text`)}
                            value={fontcart}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontcart(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.home_button`)}
                            value={backgroundbuttonhome}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundbuttonhome(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_home_button`)}
                            value={fontbuttonhome}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontbuttonhome(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.title_bar`)}
                            value={backgroundtitlebar}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundtitlebar(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_title_bar`)}
                            value={fonttitlebar}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFonttitlebar(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.icon_title_bar`)}
                            value={icontitlebar}
                            change={(value: string) => {
                              setCanAlert(true)
                              setIcontitlebar(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_category`)}
                            value={fontcategory}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontcategory(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label='Botões do Webappp'
                            value={webappbutton}
                            change={(value: string) => {
                              setCanAlert(true)
                              setWebappbutton(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_button`)}
                            value={fontbutton}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontbutton(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.background_menu`)}
                            value={backgroundmenu}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundmenu(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_menu`)}
                            value={fontmenu}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontmenu(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.order_status`)}
                            value={backgroundstatus}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundstatus(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.order_active_status`)}
                            value={backgroundstatusactive}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBackgroundstatusactive(value)
                            }
                            }
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.text_order_active_status`)}
                            value={fontstatus}
                            change={(value: string) => {
                              setCanAlert(true)
                              setFontstatus(value)
                            }
                            }
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between align-items-end mt-4 flex-wrap gap-5'>
                        <div style={{ flex: 1 }}>
                          <Input
                            inputAttr={{
                              type: 'color',
                            }}
                            label={i18n.t(`webapp.info_cart`)}
                            value={fontinfocart}
                            change={(value: string) => setFontinfocart(value)}
                          />
                        </div>
                        <div style={{ flex: 1 }} />
                      </div>
                    </div>
                  )
              }
            </div>
          </div>

          {loginInfo.plugintype === 'aggregator' &&
            <div className='w-100 d-flex gap-5'>
              <div className='card w-100'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-between flex-row'>
                    <span className='card-label fw-bold fs-4 mb-1'>
                      {i18n.t(`webapp.slides_initial`)}
                    </span>
                  </h3>

                  <Button
                    text={i18n.t(`buttons.add`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      setAction('initial')
                      showWrapperModal(`${i18n.t(`buttons.add`)} ${i18n.t(`webapp.slides_initial`)}`)
                    }
                    }
                    btnClass='fw-bolder'
                  />

                </div>
                <div className='card-body py-5'>
                  {ImageSlidesAggregatorInitial.length > 0 ? (
                    <div className='d-flex gap-5 flex-row overflow-auto'>
                      {ImageSlidesAggregatorInitial.map(img => (
                        <div className='position-relative'>
                          <i className="fa fa-times position-absolute text-danger top-0 me-5" aria-hidden="true"
                            onClick={() => {
                              showDeleteModal(`${i18n.t(`general.delete`)} Imagem do Slider`)
                              setIdImageSlider(img.id)

                            }}
                            style={{
                              fontSize: '2rem',
                              left: "85%"
                            }}

                          />
                          <img
                            src={`${img.path}${img.imgSuffix}`}
                            style={{
                              height: '25rem',
                              width: 'auto',
                              maxWidth: '150px'
                            }}
                          />
                        </div>
                      ))

                      }
                    </div>
                  ) : (
                    <>

                    </>
                  )

                  }
                </div>
              </div>
              <div className='card w-100'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-between flex-row'>
                    <span className='card-label fw-bold fs-4 mb-1'>
                      {i18n.t(`webapp.slides_homePage`)}
                    </span>
                  </h3>

                  <Button
                    text={i18n.t(`buttons.add`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      setAction('homepage')
                      showWrapperModal(`${i18n.t(`buttons.add`)} ${i18n.t(`webapp.slides_homePage`)}`)
                    }}
                    btnClass='fw-bolder'
                  />

                </div>
                <div className='card-body py-5'>
                  {ImageSlidesAggregatorHomePage.length > 0 ? (
                    <div className='d-flex gap-5 flex-row overflow-auto'>
                      {ImageSlidesAggregatorHomePage.map(img => (
                        <div className='position-relative'>
                          <i className="fa fa-times position-absolute text-danger top-0 me-5" aria-hidden="true"
                            onClick={() => {
                              showDeleteModal(`${i18n.t(`general.delete`)} Imagem do Slider`)
                              setIdImageSlider(img.id)

                            }}
                            style={{
                              fontSize: '2rem',
                              left: "85%"
                            }}

                          />
                          <img
                            src={`${img.path}${img.imgSuffix}`}
                            style={{
                              height: '25rem',
                              width: 'auto',
                              maxWidth: '150px'
                            }}
                          />
                        </div>
                      ))

                      }
                    </div>
                  ) : (
                    <>

                    </>
                  )

                  }
                </div>
              </div>
            </div>
          }

          <div className='d-flex justify-content-end mt-10'>
            <Button
              text={i18n.t(`buttons.back`)}
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/webapp')
              }}
              btnClass='fw-bolder me-3'
            />
            <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                setCanAlert(false)
                saveAppconfig()
              }}
              btnClass='fw-bolder'
            />
          </div>


          <ModalWrapper confirmAction={() => {
            action === 'initial' ? addImagesSlideInitial() : addImagesSlideHomePage()
          }}
            maxWidth={true}
          >
            <>
              {action === 'initial' ?
                (<>
                  <div className='d-flex flex-column p-8 '>
                    <div className='d-flex flex-column justify-content-center mb-5'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label={`${i18n.t(`webapp.slides`)} Webapp`}
                        value={slidesImageAggregator}
                        change={(value: BaseSyntheticEvent) => setSlidesImageAggregator(value ? value.target.files[0] : null)
                        }
                      />
                      <Input
                        inputAttr={{
                          type: 'number',
                        }}
                        label={`Ordem do ${i18n.t(`webapp.slides`)}`}
                        value={slidesImageAggregatorOrder}
                        change={(value: any) => setSlidesImageAggregatorOrder(parseInt(value))

                        }
                      />
                    </div>

                    <div className='w-100 d-flex justify-content-end'>
                      <Button text={`${i18n.t(`buttons.add`)}`} color={'primary'} click={() => {
                        setImageToSliderPreviewInitial()
                      }} />
                    </div>


                  </div>

                  <div className='card-body'>
                    <div className='d-flex gap-5 flex-row overflow-auto'>
                      {loadingImages ? (<>
                        <Loading />
                      </>) : (
                        <>
                          {slidesImageAggregatorPreviewInitial.map((img, index) => (
                            <div className='position-relative'>
                              <i className="fa fa-times position-absolute top-0 me-5" aria-hidden="true"
                                onClick={() => {
                                  setLoading(true)
                                  const newSlider = slidesImageAggregatorPreviewInitial
                                  newSlider.splice(index, 1);
                                  setSlidesImageAggregatorPreviewInitial(newSlider)

                                  setTimeout(() => {
                                    setLoading(false)
                                  }, 100)

                                }}
                                style={{
                                  fontSize: '2rem',
                                  left: "90%"
                                }}

                              />
                              <img
                                src={URL.createObjectURL(img.image)} alt=""
                                style={{
                                  width: '250px',
                                  height: '250px'
                                }}
                              /
                              >
                            </div>
                          ))
                          }
                        </>
                      )

                      }

                    </div>
                  </div>
                </>)
                :

                (<>
                  <div className='d-flex flex-column p-8 '>
                    <div className='d-flex flex-column justify-content-center mb-5'>
                      <Input
                        inputAttr={{
                          type: 'image',
                        }}
                        label={`${i18n.t(`webapp.slides`)} Webapp`}
                        value={slidesImageAggregator}
                        change={(value: BaseSyntheticEvent) => setSlidesImageAggregator(value ? value.target.files[0] : null)
                        }
                      />
                      <Input
                        inputAttr={{
                          type: 'number',
                        }}
                        label={`Ordem do ${i18n.t(`webapp.slides`)}`}
                        value={slidesImageAggregatorOrder}
                        change={(value: any) => setSlidesImageAggregatorOrder(parseInt(value))

                        }
                      />
                    </div>

                    <div className='w-100 d-flex justify-content-end'>
                      <Button text={`${i18n.t(`buttons.add`)}`} color={'primary'} click={() => {
                        setImageToSliderPreviewHomePage()
                      }} />
                    </div>


                  </div>

                  <div className='card-body'>
                    <div className='d-flex gap-5 flex-row overflow-auto'>
                      {loadingImages ? (<>
                        <Loading />
                      </>) : (
                        <>
                          {slidesImageAggregatorPreviewHomePage.map((img, index) => (
                            <div className='position-relative'>
                              <i className="fa fa-times position-absolute top-0 me-5" aria-hidden="true"
                                onClick={() => {
                                  setLoading(true)
                                  const newSlider = slidesImageAggregatorPreviewHomePage
                                  newSlider.splice(index, 1);
                                  setSlidesImageAggregatorPreviewHomePage(newSlider)

                                  setTimeout(() => {
                                    setLoading(false)
                                  }, 100)

                                }}
                                style={{
                                  fontSize: '2rem',
                                  left: "90%"
                                }}

                              />
                              <img
                                src={URL.createObjectURL(img.image)} alt=""
                                style={{
                                  width: '250px',
                                  height: '250px'
                                }}
                              /
                              >
                            </div>
                          ))
                          }
                        </>
                      )

                      }

                    </div>
                  </div>
                </>)

              }
            </>
          </ModalWrapper>

          <ModalDelete confirmAction={() => deleteImageSlider()} />
        </div >
      )}
    </>
  )
}

const EditarWebapp: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Webapp', path: 'webapp', isActive: true },
          { title: i18n.t(`general.edit`), path: 'webapp/editar', isActive: true },
        ]}
      >
        {`${i18n.t(`general.edit`)} Webapp`}
      </PageTitle>
      <EditarWebappContent />
    </>
  )
}

export { EditarWebapp }
