/* eslint-disable jsx-a11y/anchor-is-valid */
import { BaseSyntheticEvent, FC, useState, useEffect, useRef } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { GET, POST } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import Swal from 'sweetalert2'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import uuid from 'react-uuid';
import { ModalImagemCropped } from '../../../../components/Modals/ImagemCropped/ImagemCropped'
import useModals from '../../../../hooks/useModals'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ReturnType = {
  id: string
  parent: string
  code: string
  name: string
  description: string
  value: string
  select: string
}

const CriarProdutosContent: FC = () => {
  const navigate = useNavigate()

  const { showImageCropped } = useModals()
  const { loginInfo } = useAuth();
  const [listClassification, setListClassification] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [categoryId, setCategoryId] = useState<string>('0')
  const [categories, setCategories] = useState<any[]>([])
  const [subcategoryId, setSubcategoryId] = useState<string>('0')
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>()
  const [description, setDescription] = useState<string>('')
  const [technicalSpecification, setTechnicalSpecification] = useState<string>('')
  const [marks, setMarks] = useState<any[]>([])
  const [markId, setMarkId] = useState<string>('0')
  const [measurements, setMeasurements] = useState<any[]>([])
  const [measurementId, setMeasurementId] = useState<string>('')
  const [order, setOrder] = useState<string>('0')
  const [status, setStatus] = useState<boolean>(true)
  const [image, setImage] = useState<File | null>(null)
  const [extraImages, setExtraImages] = useState<FileList>()

  const [imageCrop, setImageCrop] = useState<string | null>('')
  const [isCropped, setCropper] = useState<any>(null)
  const [canAlert, setCanAlert] = useState(false)


  const generateClassificationTree = (categories: ReturnType[], parent: string | null, name: string): any => {
    const parentCategory = categories.find((category) => category.code === parent);
    if (parentCategory) return `${generateClassificationTree(categories, parentCategory.parent, parentCategory.name)} > ${name}`
    else return name;
  }

  const getCategories = async () => {
    try {
      const res = await GET(`/classification`)
      res.data.map((row: ReturnType) => {
        listClassification.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: generateClassificationTree(res.data, row.parent, row.name),
        })
      })
      const categories = [
        {
          value: '0',
          select: 'Selecione',
          key: -1,
        },
      ]
      listClassification.map((row: any, idx) => {
        categories.push({
          value: row.id,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)

    } catch (e) {
      console.log(e)
    }
  }

  const getMarks = async () => {
    try {
      const res = await GET('/mark')
      const marks = [
        {
          value: '0',
          select: 'Selecione',
        },
      ]
      res.data.map((row: ReturnType) => {
        marks.push({
          value: row.id,
          select: row.name,
        })
      })
      setMarks(marks)
    } catch (e) {
      console.log(e)
    }
  }

  const getMeasurements = async () => {
    try {
      const res = await GET('/measurement')
      const measurements = [
        {
          value: '0',
          select: 'Selecione',
        },
      ]
      res.data.map((row: ReturnType) => {
        measurements.push({
          value: row.id,
          select: row.description,
        })
      })
      setMeasurements(measurements)
    } catch (e) {
      console.log(e)
    }
  }

  const createProduct = async () => {
    const subcategorySelected: any = subcategoryId;
    try {
      if (
        subcategoryId == '0' ||
        subcategorySelected.value === '0' ||
        !name ||
        !description ||
        markId == '0' ||
        measurementId == '0'
      ) {
        setCanAlert(true)
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
        return
      } else {
        if (!code) {
          setCanAlert(true)
          Swal.fire({
            icon: 'warning',
            title: 'Código não pode ser vazio',
            text: 'Verifique se preencheu todos os campos!',
          })
          return
        }
        setLoading(true)
        const data = {
          name: name,
          description: description,
          order: parseInt(order),
          code: code ?? '',
          classificationId: parseInt(subcategorySelected.value),
          type: 'product',
          status: status,
          measurementUnitId: parseInt(measurementId),
          parent: '',
          markId: parseInt(markId),
          technicalSpecification: technicalSpecification,
        }

        const resProd = await POST('/product', data)
        if (resProd.success === true) {
          let imageRes = {
            success: false,
            image: '',
          }
          if (image) {
            const formData = new FormData()
            formData.append('file', image)
            formData.append('nameImage', `product-${uuid()}.jpg`)
            imageRes = await POST('/upload-images/upload-one', formData)
          }
          if (imageRes.success == true) {
            const data = [
              {
                path: imageRes.image,
                productId: resProd.dados.id,
                typeimg: 'imgsmall',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: resProd.dados.id,
                typeimg: 'mainpicture',
                order: 0,
              },
              {
                path: imageRes.image,
                productId: resProd.dados.id,
                typeimg: 'thumbpicture',
                order: 0,
              },
            ]
            if (extraImages) {
              await Promise.all(
                Array.from(extraImages).map(async (image) => {
                  let imageExtraRes = {
                    success: false,
                    image: '',
                  }
                  const formData = new FormData()
                  formData.append('file', image)
                  formData.append('nameImage', `product-${uuid()}.jpg`)
                  imageExtraRes = await POST('/upload-images/upload-one', formData)
                  if (imageExtraRes.success == true) {
                    data.push({
                      path: imageExtraRes.image,
                      productId: resProd.dados.id,
                      typeimg: 'image',
                      order: 0,
                    })
                  }
                })
              )
            }
            await POST('/productimage/multiple', {
              data,
            })
          }
          setCanAlert(false)
          Swal.fire({
            icon: 'success',
            title: 'Produto cadastrado',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/product_fashion'))
        } else {
          setCanAlert(true)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar o produto!',
          })
        }
        setLoading(false)
      }
    } catch (e) {
      setCanAlert(true)
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar o produto!',
      })
    }
  }

  const getCropData = async () => {
    if (isCropped) {
      const file = await fetch(isCropped.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then((blob) => {
          return new File([blob], "newAvatar.png", { type: "image/png" });
        });

      setImage(file)
      setImageCrop('')
    }
  };

  const getImagetoCrop = (e: any) => {
    if (e?.target.files) {
      showImageCropped()
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };
  
  const handleUnload = (e:BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('product-saved')
  }

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        await getCategories()
        await getMarks()
        await getMeasurements()
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    
    const productSaved = localStorage.getItem('product-saved')
    if(productSaved && JSON.parse(productSaved)) loadLocal()
    else load()
  }, [])

  useEffect(() => {
    return () => {
      if(canAlert && !window.location.pathname.includes(`/${loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion'}/criar`)){
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/${loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion'}/criar`)
          } else {
            localStorage.removeItem('product-saved')
          }
        })
      } else if(!canAlert) localStorage.removeItem('product-saved')
    } 
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const prouctSaved = localStorage.getItem('product-saved')
    if(prouctSaved && JSON.parse(prouctSaved)){
      const jsonProduct = JSON.parse(prouctSaved);
      setListClassification(jsonProduct.listClassification)
      setCategoryId(jsonProduct.categoryId)
      setCategories(jsonProduct.categories)
      setSubcategoryId(jsonProduct.subcategoryId)
      setName(jsonProduct.name)
      setCode(jsonProduct.code)
      setDescription(jsonProduct.description)
      setTechnicalSpecification(jsonProduct.technicalSpecification)
      setMarks(jsonProduct.marks)
      setMarkId(jsonProduct.markId)
      setMeasurements(jsonProduct.measurements)
      setMeasurementId(jsonProduct.measurementId)
      setOrder(jsonProduct.order)
      setStatus(jsonProduct.status)
      if(typeof jsonProduct.image === 'string') setImage(jsonProduct.image)
      localStorage.removeItem('product-saved')
    }
  }

  const saveLocal = () => {
    localStorage.setItem('product-saved', JSON.stringify({
      listClassification,
      categoryId,
      categories,
      subcategoryId,
      name,
      code,
      description,
      technicalSpecification,
      marks,
      markId,
      measurements,
      measurementId,
      order,
      status,
      image
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    listClassification,
    categoryId,
    categories,
    subcategoryId,
    name,
    code,
    description,
    technicalSpecification,
    marks,
    markId,
    measurements,
    measurementId,
    order,
    status,
    image
  ])

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='d-flex justify-content-between flex-wrap w-100 gap-5'>
            <div className='me-5' style={{ flex: 1 }}>
              <div className='card pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`smart_order.product_data`)}</span>
                    <BreadCrumbs />
                  </h3>
                </div>
                <div className='px-10 py-5'>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    search
                    label={i18n.t(`smart_order.category`)}
                    options={categories}
                    value={subcategoryId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setSubcategoryId(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`smart_order.mark`)}
                    options={marks}
                    value={markId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setMarkId(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label={i18n.t(`smart_order.measurement_unit`)}
                    options={measurements}
                    value={measurementId}
                    change={(value: string) => {
                      setCanAlert(true)
                      setMeasurementId(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.name`)}
                    label={i18n.t(`smart_order.name`)}
                    value={name}
                    change={(value: string) => {
                      setCanAlert(true)
                      setName(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.code`)}
                    label={i18n.t(`smart_order.code`)}
                    value={code}
                    change={(value: string) => {
                      setCanAlert(true)
                      setCode(value)
                    }}
                  />

                  <Input
                    inputAttr={{
                      type: 'textarea',
                    }}
                    placeholder={i18n.t(`smart_order.description`)}
                    label={i18n.t(`smart_order.description`)}
                    value={description}
                    change={(value: string) => {
                      setCanAlert(true)
                      setDescription(value)
                    }}
                    rows={5}
                    maxCaracteres={415}
                  />
                  <p className='mt-2'>
                    <small>{description.length} {i18n.t(`smart_order.from`)} 415 {i18n.t(`smart_order.characters`)}</small>
                  </p>

                  <Input
                    inputAttr={{
                      type: 'textarea',
                    }}
                    placeholder={i18n.t(`smart_order.technical_specification`)}
                    label={i18n.t(`smart_order.technical_specification`)}
                    value={technicalSpecification}
                    change={(value: string) => {
                      setCanAlert(true)
                      setTechnicalSpecification(value)
                    }}
                    rows={5}
                    maxCaracteres={415}
                  />
                  <p className='mt-2'>
                    <small>{technicalSpecification.length} {i18n.t(`smart_order.from`)} 415 {i18n.t(`smart_order.characters`)}</small>
                  </p>
                  <Input
                    inputAttr={{
                      type: 'select',
                    }}
                    label='Status'
                    options={[
                      { select: i18n.t(`general.active`), value: true },
                      { select: i18n.t(`general.inactive`), value: false },
                    ]}
                    value={status}
                    change={(value: boolean) => {
                      setCanAlert(true)
                      setStatus(value)
                    }}
                  />
                  <Input
                    inputAttr={{
                      type: 'default',
                    }}
                    placeholder={i18n.t(`smart_order.order`)}
                    label={i18n.t(`smart_order.order`)}
                    smalltext='(para exibição no webapp)'
                    value={order}
                    change={(value: string) => {
                      setCanAlert(true)
                      setOrder(value)
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className='card pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`smart_order.product_image`)}</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  <div className='px-10 py-5'>
                    {/*
                    <Input
                      inputAttr={{
                        type: 'image',
                      }}
                      value={image}
                      change={(value: BaseSyntheticEvent) =>
                        setImage(value ? value.target.files[0] : null)
                      }
                    />
                    */
                      <>
                        <Input
                          inputAttr={{
                            type: 'image',
                          }}
                          value={image}
                          change={(value: BaseSyntheticEvent) => {
                            setCanAlert(true)
                            setImage(value ? value.target.files[0] : null)
                            if (value) {
                              getImagetoCrop(value ? value : null)
                            } else {
                              setImageCrop('')
                              getImagetoCrop(null)
                            }
                          }}
                        />
                      </>
                    }
                  </div>
                </div>
              </div>

              <div className='card mt-4 pb-5'>
                <div className='card-header py-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-4 mb-1'>{i18n.t(`smart_order.additional_images`)}</span>
                  </h3>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  <div className='px-10 py-5'>
                    <input
                      type='file'
                      accept='.jpg, .jpeg, .png'
                      multiple
                      onChange={(e) => {
                        if (e.target.files && e.target.files.length <= 3) {
                          setCanAlert(true)
                          setExtraImages(e.target.files)
                        } else {
                          e.target.value = ''
                          Swal.fire({
                            icon: 'warning',
                            title: 'Atenção!',
                            text: 'O limite de imagens adicionais é de no máximo 3 arquivos.',
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <div className='d-flex justify-content-center w-100'>
                  {extraImages &&
                    Array.from(extraImages).map((image) => {
                      return (
                        <div className='px-10 py-5'>
                          <div className=' mt-1 w-150px'>
                            <div className='w-150px h-150px rounded'>
                              <img className='w-100 h-100' src={URL.createObjectURL(image)} />
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>

          <div style={{ width: 'auto', position: 'fixed', bottom: 40, right: 60 }}>
            <Button
              text={i18n.t(`buttons.back`)}
              color='info'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                navigate('/product_fashion')
              }}
              btnClass='fw-bolder me-3'
            />
            <Button
              text={i18n.t(`buttons.save`)}
              color='primary'
              size='small'
              horizontalPadding={5}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                setCanAlert(false)
                createProduct()
              }}
              btnClass='fw-bolder'
            />
          </div>
        </div>
      )}
      <ModalImagemCropped 
      
      imageCrop={imageCrop}
      setCropper={setCropper }
      getCropData={getCropData}
      />
    </>
  )
}

const CriarProdutos: FC = () => {
  const { loginInfo } = useAuth();
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.product`), path: loginInfo.plugintype === 'varejo' ? 'product_varejo' : 'product_fashion', isActive: true },
          { title: i18n.t(`buttons.create`), path: loginInfo.plugintype === 'varejo' ? 'product_varejo/criar' : 'product_fashion/criar', isActive: true },
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.product`)}`}
      </PageTitle>
      <CriarProdutosContent />
    </>
  )
}

export { CriarProdutos }
