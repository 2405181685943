/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useEffect, useState } from 'react'
import { GET } from '../../../../app/services/api'
import { i18n } from '../../../../app/translate/i18n'
import useAuth from '../../../../app/hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import usePluginsSpace from '../../../../app/hooks/usePluginSpace'

export type MenuType = {
  icon: string
  id: number
  name: string
  newIcon: string
  order: number
  parent: number
  route: string
  submenu: SubMenuType[]
  type: string
  permissionParent?: boolean

}

type SubMenuType = {
  id: number
  name: string
  newIcon: string
  order: number
  parent: number
  router: string,
  externalLink?: boolean
}

export function AsideMenuMain() {
  const intl = useIntl()


  const [menu, setMenu] = useState<MenuType[]>([])
  const { loginInfo, verifyAdm } = useAuth()
  const { integrations } = usePluginsSpace()
  const navigate = useNavigate();


  function verifyPermission() {
    
    if (menu && menu.length > 0) {
      let permission = false;
    
      menu.forEach((order: MenuType) => {
        if (order.submenu && order.submenu.length > 0) {
          order.submenu.forEach((submenu) => {
            const route = submenu.router.split("./")[1] ? submenu.router.split("./")[1] : submenu.router.split("./")[0]
            if (route && window.location.pathname.includes(route) && order.permissionParent) {
              permission = true;
            }
          })
        } else {
          const route = order.route.split("./")[1] ? order.route.split("./")[1] : order.route.split("./")[0]
          if (route && window.location.pathname.includes(route) && order.permissionParent) {
            permission = true;
          }
        }
      })
      if (
        !permission &&
        !window.location.pathname.includes('dashboard') &&
        !window.location.pathname.includes('pluginspace') &&
        !window.location.pathname.includes('icon') &&
        !window.location.pathname.includes('error') &&
        !window.location.pathname.includes('auth') &&
        !window.location.pathname.includes('copy-configurations')
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Você não tem permissão de acessar essa página!'
        })
        navigate('/dashboard')
      }
    }
  }

  async function fetchMenu() {
    try {
      const response = await GET('/pages/buildMenuPage')
      if (response) {
        let menuOrder: MenuType[] = response.sort((a: MenuType, b: MenuType) => a.order - b.order)

        setMenu(menuOrder)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchMenu()
  }, [])
  useEffect(() => {
    verifyPermission()
  }, [window.location.pathname])
  useEffect(() => {
    verifyPermission()
  }, [menu])

  function updateMenuSDS() {

    if(loginInfo.plugintype === 'hospitality'){
      menuSdsHospitality()
    }else{
      menuSdsFood()
    }
    
  }

  function menuSdsFood(){
    const hasSDS = menu.findIndex(menu => menu.name === 'SDS');
    const sdsConfig: any = integrations.find(integration => integration.plugintemplateId === 101)
    if (hasSDS !== -1 && sdsConfig) {

      const loginData = btoa(JSON.stringify({ email: sdsConfig.content.email, pdw: sdsConfig.content.pwd }))

      let url = ''
      switch (process.env.REACT_APP_PROD) {
        case '3':
          url = `https://sds-admin-dev.ninegrid.com.br/auth/login?loginInfo=${loginData}`
          break
        case '0':
          url = `https://sds-admin-staging.ninegrid.com.br/auth/login?loginInfo=${loginData}`
          break
        case '1':
          url = `https://sds-admin.ninegrid.com.br/auth/login?loginInfo=${loginData}`
          break
      }
      menu[hasSDS].submenu.push(
        {
          id: 1159,
          name: "sds_link",
          parent: 1158,
          router: url,
          newIcon: "<!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-03-11-144509/theme/html/demo1/dist/../src/media/svg/icons/Devices/Tablet.svg--><svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" version=\"1.1\"><g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><rect x=\"0\" y=\"0\" width=\"24\" height=\"24\"/><path d=\"M6.5,4 L6.5,20 L17.5,20 L17.5,4 L6.5,4 Z M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z\" fill=\"#000000\" fill-rule=\"nonzero\"/><polygon fill=\"#000000\" opacity=\"0.3\" points=\"6.5 4 6.5 20 17.5 20 17.5 4\"/></g></svg><!--end::Svg Icon-->",
          order: 99,
          externalLink: true
        }
      )

      setMenu(menu)
    }else if (hasSDS !== -1 && !sdsConfig){
      setMenu(menu.filter(m=> m.name !== 'SDS'))
    }
  }

  function menuSdsHospitality(){
    const sdsConfigHospitalitys= integrations.filter(integration => integration.plugintemplateId === 101)
    const hasSDS = menu.findIndex(menu => menu.name === 'SDS');
    const sdsConfigRestaurant:any = sdsConfigHospitalitys.find((integration:any)=> integration.content.type === '1')
    const sdsConfigService:any = sdsConfigHospitalitys.find((integration:any)=> integration.content.type === '2')

    let newMenu = menu.map(el=> el);

    if (hasSDS !== -1 && sdsConfigRestaurant) {

      const loginData = btoa(JSON.stringify({ email: sdsConfigRestaurant.content.email, pdw: sdsConfigRestaurant.content.pwd }))

      let url = ''
      switch (process.env.REACT_APP_PROD) {
        case '3':
          url = `https://sds-admin-dev.ninegrid.com.br/auth/login?loginInfo=${loginData}`
          break
        case '0':
          url = `https://sds-admin-staging.ninegrid.com.br/auth/login?loginInfo=${loginData}`
          break
        case '1':
          url = `https://sds-admin.ninegrid.com.br/auth/login?loginInfo=${loginData}`
          break
      }
      newMenu[hasSDS].submenu.push(
        {
          id: 1159,
          name: "sds_link",
          parent: 1158,
          router: url,
          newIcon: "<!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-03-11-144509/theme/html/demo1/dist/../src/media/svg/icons/Devices/Tablet.svg--><svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" version=\"1.1\"><g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><rect x=\"0\" y=\"0\" width=\"24\" height=\"24\"/><path d=\"M6.5,4 L6.5,20 L17.5,20 L17.5,4 L6.5,4 Z M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z\" fill=\"#000000\" fill-rule=\"nonzero\"/><polygon fill=\"#000000\" opacity=\"0.3\" points=\"6.5 4 6.5 20 17.5 20 17.5 4\"/></g></svg><!--end::Svg Icon-->",
          order: 99,
          externalLink: true
        }
      )

      
    }else if (hasSDS !== -1 && !sdsConfigRestaurant){
      newMenu = newMenu.filter(m=> m.name !== 'SDS')
    }

    if(sdsConfigService){
      const hasServiceMenu = menu.findIndex(menu => menu.name === 'service');

      if(hasServiceMenu !== -1){
        newMenu[hasServiceMenu].submenu.push(
          {
            id: 1159,
            name: "sds_display",
            parent: newMenu[hasServiceMenu].id,
            router: '/sds/manage-hospitality',
            newIcon: "<!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-03-11-144509/theme/html/demo1/dist/../src/media/svg/icons/Devices/Tablet.svg--><svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" version=\"1.1\"><g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><rect x=\"0\" y=\"0\" width=\"24\" height=\"24\"/><path d=\"M6.5,4 L6.5,20 L17.5,20 L17.5,4 L6.5,4 Z M7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,4 C5,2.8954305 5.8954305,2 7,2 Z\" fill=\"#000000\" fill-rule=\"nonzero\"/><polygon fill=\"#000000\" opacity=\"0.3\" points=\"6.5 4 6.5 20 17.5 20 17.5 4\"/></g></svg><!--end::Svg Icon-->",
            order: 99,
          }
        )
      }

    }


    setMenu(newMenu)
  }

  useEffect(() => {
    updateMenuSDS()
  }, [integrations.length,menu.length])


  return (
    <>
      {loginInfo.plugintype !== 'aggregator' &&
        < AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotune/general/gen001.svg'
          title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
          fontIcon='bi-app-indicator'
        />
      }
      {menu.map((item, i) => {
        if (item.submenu.length > 0 && item.route.includes("javascript")) {
          return (
            <AsideMenuItemWithSub
              key={`menu-${i}`}
              to=''
              title={i18n.t(`permissions.${item.name}`)}
              icon={
                item.newIcon
                  ? item.newIcon.replaceAll('fill="#000000"', 'fill="currentColor"')
                  : ''
              }
            >
              {item.submenu.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)).map((subItem, j) => {
                if (subItem.name === 'charge_integration') {
                  if (verifyAdm() === true) {
                    return (
                      <AsideMenuItem
                        key={`subitem-${j}`}
                        to={subItem.router ? subItem.router.replaceAll('./', '/') : ''}
                        title={i18n.t(`permissions.${subItem.name}`)}
                        hasBullet={true}
                      />
                    )
                  } else {
                    return
                  }
                } else {
                  return (
                    <AsideMenuItem
                      key={`subitem-${j}`}
                      to={subItem.router ? subItem.router.replaceAll('./', '/') : ''}
                      title={i18n.t(`permissions.${subItem.name}`)}
                      hasBullet={true}
                      externalLink={subItem.externalLink}
                    />
                  )
                }
              })}
            </AsideMenuItemWithSub>
          )
        } else {
          if (item.permissionParent) {
            return (
              <AsideMenuItem
                key={`item-${i}`}
                to={item.route ? item.route.replaceAll('./', '/') : ''}
                title={i18n.t(`permissions.${item.name}`)}
                hasBullet={false}
                icon={
                  item.newIcon
                    ? item.newIcon.replaceAll('fill="#000000"', 'fill="currentColor"')
                    : ''
                }
              />
            )
          }
        }
      })}
    </>
  )
}
