import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import { DELETE, GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type MarkResponseType = {
  id: number
  name: string
}

const MarcasContent: FC = () => {
  const { showWrapperModal, showDeleteModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState<'creating' | 'editing'>('creating')

  const [marks, setMarks] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [name, setName] = useState<string>('')
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const getMarks = async () => {
    try {
      setLoading(true)
      const markRes = await GET(`/mark`)
      const newModalities = markRes.data.map((mark: MarkResponseType) => {
        return {
          id: mark.id,
          name: mark.name,
        }
      })
      setMarks(newModalities)
      setMeta(markRes.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const createMark = async () => {
    try {
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const markRes = await POST('/mark', {
          name,
        })
        if (markRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Marca cadastrada',
            text: 'Cadastro realizado com sucesso!',
          }).then(async () => await getMarks())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar a marca!',
          }).then(async () => await getMarks())
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar a marca!',
      }).then(async () => await getMarks())
      console.log(e)
    }
  }
  const editMark = async () => {
    try {
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const markRes = await PUT(`/mark/${id}`, {
          name,
        })
        if (markRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Marca alterada',
            text: 'Edição realizada com sucesso!',
          }).then(async () => await getMarks())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar a marca!',
          }).then(async () => await getMarks())
        }
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar a marca!',
      }).then(async () => await getMarks())
      console.log(e)
    }
  }

  const deleteMark = async () => {
    try {
      setLoading(true)
      const markRes = await DELETE(`/mark/${id}`)
      if (markRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Marca deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getMarks())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar a marca!',
        }).then(async () => await getMarks())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar a marca!',
      }).then(async () => await getMarks())
      console.log(e)
    }
  }

  useEffect(() => {
    getMarks()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`smart_order.marks`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`smart_order.listened_marks`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_brand`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    showWrapperModal(i18n.t(`buttons.add_brand`))
                    setName('')
                    setId(null)
                    setAction('creating')
                  }}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={marks}
                headers={[
                  { key: i18n.t(`smart_order.name`), value: 'name', type: 'default' },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      showWrapperModal(`${i18n.t(`general.edit`)} ${i18n.t(`smart_order.mark`)}`)
                      setAction('editing')
                      setId(item.id)
                      setName(item.name)
                    },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.mark`)}`,
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`smart_order.mark`)}`)
                      setId(item.id)
                    },
                    title: `${i18n.t(`general.delete`)} ${i18n.t(`smart_order.mark`)}`,
                  },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalWrapper
            confirmAction={action === 'creating' ? () => createMark() : () => editMark()}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <div className='mx-5 flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  value={name}
                  change={setName}
                  placeholder={i18n.t(`smart_order.mark`)}
                  label={i18n.t(`smart_order.mark`)}
                />
              </div>
            </div>
          </ModalWrapper>

          <ModalDelete confirmAction={() => deleteMark()} />
        </div>
      )}
    </>
  )
}

const Marcas: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.marks`), path: 'mark', isActive: true },
        ]}
      >
        {i18n.t(`smart_order.marks`)}
      </PageTitle>
      <MarcasContent />
    </>
  )
}

export { Marcas }
