/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {toDate} from '../../../../utils/formater'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type Props = {
  startDate: string
  setStartDate: React.Dispatch<React.SetStateAction<string>>
  finalDate: string
  setFinalDate: React.Dispatch<React.SetStateAction<string>>
}

const TempoProducaoContent: FC<Props> = (data) => (
  <>
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Relatório de Tempo por Produto</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              Aqui estão registrados os tempos de produção durante o período selecionado.
            </span>
          </h3>
        </div>
        <div className='card-body'>
          <div className='w-100 mb-5'>
            <h4 className='text-gray-800'>Selecione o período</h4>
            <div className='d-flex align-items-end'>
              <Input
                inputAttr={{type: 'date'}}
                label='Início'
                value={data.startDate}
                change={data.setStartDate}
                maxLength={data.finalDate}
              />
              <div className='mx-10'>
                <Input
                  inputAttr={{type: 'date'}}
                  label='Fim'
                  value={data.finalDate}
                  change={data.setFinalDate}
                  minLength={data.startDate}
                />
              </div>
              <div className='me-10'>
                <Input
                  inputAttr={{type: 'select'}}
                  label='Setor'
                  options={[{select: 'Todos', value: 'todos'}]}
                />
              </div>
              <Button color='primary' text='Procurar' click={() => {}} btnClass='h-45px' />
            </div>

            <div className='d-flex align-items-center justify-content-between border-bottom border-top py-4 mt-8 fs-4 fw-bold text-gray-800'>
              <p className='m-0'>{`Pedidos entre ${toDate(data.startDate)} até ${toDate(
                data.finalDate
              )}`}</p>
              <div className='d-flex align-items-center'>
                <Button
                  color='primary'
                  text='Copiar'
                  size='small'
                  click={() => {}}
                  btnClass='me-2'
                />
                <Button
                  color='primary'
                  text='Excel'
                  size='small'
                  click={() => {}}
                  btnClass='ms-2'
                />
              </div>
            </div>
          </div>
          <Datatable
            data={[
              {
                product: '1104 - BRIGADEIRO CENTO',
                order: 16637,
                date: '05/05/2022',
                start: '08:00:04',
                finish: '08:24:14',
                time: '00:24:10',
              },
            ]}
            headers={[
              {key: 'Produto', value: 'product', type: 'default'},
              {key: 'Pedido', value: 'order', type: 'default'},
              {key: 'Data', value: 'date', type: 'default'},
              {key: 'Início', value: 'start', type: 'default'},
              {key: 'Fim', value: 'finish', type: 'default'},
              {key: 'Tempo', value: 'time', type: 'default'},
            ]}
            color='primary'
          />
        </div>
      </div>
    </div>
  </>
)

const TempoProducao: FC = () => {
  const dataAtual = new Date()
  const dataAtualArray = dataAtual.toLocaleDateString().split('T')[0].split('/')

  const [startDate, setStartDate] = useState(`${dataAtualArray[2]}-${dataAtualArray[1]}-01`)
  const [finalDate, setFinalDate] = useState(
    `${dataAtualArray[2]}-${dataAtualArray[1]}-${dataAtualArray[0]}`
  )

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {
            title: 'Relatório de Tempo por Produto',
            path: 'relatorios/tempo-producao',
            isActive: true,
          },
        ]}
      >
        Relatório de Tempo por Produto
      </PageTitle>
      <TempoProducaoContent
        startDate={startDate}
        setStartDate={setStartDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
      />
    </>
  )
}

export {TempoProducao}
