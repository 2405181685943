import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
import { Toolbar } from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../assets/ts/components'
import { SidebarPerfil } from '../../app/components/Modals/Topbar/SidebarPerfil/SidebarPerfil'
import { ModalText } from '../../app/components/Modals/General/ModalText/ModalText'
import { AlertStatusPluginSpace } from './components/Alert/Alert'
import { InboxCompose } from '../partials/layout/InboxCompose'
import { RightToolbar } from '../partials/layout/RightToolbar'
import { ButtonChatIA } from './components/ChatIA/ButtonChatIA'
import { DrawerMessenger } from '../partials'

const MasterLayout = () => {
  const [openChatIA, setOpenChatIA] = useState(false);

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        {/*   <RightToolbar />*/}

        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          <AlertStatusPluginSpace />
          <div id='kt_content' className='d-flex flex-column flex-column-fluid'>
            {/*<Toolbar />*/}
            <div className='post d-flex flex-column-fluid' id='kt_post' style={{
              paddingTop:"32px"
            }}>
              <Content>
                <Outlet />
              </Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <SidebarPerfil />

      <ButtonChatIA onClick={() => setOpenChatIA(!openChatIA)} />
      <DrawerMessenger open={openChatIA} setOpen={setOpenChatIA} />

      {/*Envio de Email */}
      <InboxCompose show={false} handleClose={function (): void {
        throw new Error('Function not implemented.')
      }} />

      <ModalText />
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
