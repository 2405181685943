import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, POST, PUT, } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import Swal from 'sweetalert2'

import styles from './config.module.css'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import { Button } from '../../../../components/Button/Button'

type PluginconfigType = {
  id: number;
  name: string;
  content: any;
  plugintemplateId: number;
  plugintypeId: number;
}

type TemplateType = {
  id: number;
  name: string;
  image: string | null;
  parameters: string;
  plugintypeId: number;
  pluginconfig: PluginconfigType;
  able: boolean | null;
}

type ConfigInputsType = {
  name: string;
  value: string;
}


const IAConfigItemContent: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<TemplateType | null>();
  const [configValues, setConfigValues] = useState<ConfigInputsType[]>([])


  const getPlugintemplate = async () => {
    try {
      const res = await GET(`/plugintemplate/ia/${id}`)
      if (res.success && res.data && res.data.length > 0) {
        setTemplate(res.data[0])
        const paymentConfig = JSON.parse(res.data[0].parameters).config
        setConfigValues(Object.entries(res.data[0].pluginconfig.content)
          .filter((item) => item[0] && !item[0].includes('able') && !item[0].includes('modelId') && !item[0].includes('agentId'))
          .map((item: any[]) => {
            return {
              name: paymentConfig[item[0]],
              value: item[1]
            }
          }))
        return
      }
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar configuração de IA.',
      })
      return
    } catch (e) {
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Falha ao recuperar configuração de IA.',
      })
    }
  }

  const init = async () => {
    setLoading(true)
    await getPlugintemplate();
    setLoading(false)
  }

  useEffect(() => {
    init();
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`chat.title_config`)}</span>
                <BreadCrumbs />
              </h3>
              
            </div>
            {template && (
              <div className='card-body d-flex flex-column gap-5' >
                <div className={styles.goBack}>
                  {template.image && (<img src={template.image} alt={template.name} />)} 
                </div>
                <div className={styles.configItem}>
                  <div className={styles.configHead}>
                    <h2>{i18n.t(`payment.basic_settings`)}</h2>
                    <button onClick={() => navigate(`/ia/config/edit/${id}`)}>{i18n.t(`general.edit`)}<i className="fa fa-cog"></i></button>
                  </div>
                  {configValues.filter(item => item.name && item.value).map((item) => (
                    <div key={item.name} className={styles.configValues}>
                      <p>{item.name}:</p>
                      <p style={{ color: '#6E7586' }}>{item.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className='d-flex w-100 justify-content-end p-4'>
              <Button
                text={i18n.t(`buttons.back`)}
                color='info'
                size='small'
                horizontalPadding={6}
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  navigate('/ia/config')
                }}
                btnClass='fw-bolder mt-4'
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const IAConfigItem: FC = () => {
  const { id } = useParams();

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`ia.title_config`), path: 'ia/config', isActive: true },
          { title: i18n.t(`ia.config`), path: `ia/config/${id}`, isActive: true },
        ]}
      >
        {i18n.t(`ia.config`)}
      </PageTitle>
      <IAConfigItemContent />
    </>
  )
}

export { IAConfigItem }
