import { BaseSyntheticEvent, FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Loading } from '../../../components/Loading/Loading'
import { GET, POST } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import { Input } from '../../../components/Input/Input'
import Swal from 'sweetalert2'
import uuid from 'react-uuid'
import styles from './config.module.css'
import useModals from '../../../hooks/useModals'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'


type BookingDayType = {
    day: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
    able: boolean;
    bookingHours: string[]
}

type BookingHourType = {
    hour: string
}

type BookingDayResponseType = {
    day: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
    able: boolean;
    hours: BookingHourType[]
}

type DayType = {
    name: 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
    nick: 'sun' | 'mon' | 'tue' | 'wed' | 'thur' | 'fri' | 'sat';
    able: boolean;
}


const BookingConfigContent: FC = () => {
    const { showWrapperModal } = useModals()
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState('');
    const [able, setAble] = useState(false);
    const [maxPeople, setMaxPeople] = useState(0);
    const [expiryTime, setExpiryTime] = useState(0);
    const [dayConfirm, setDayConfirm] = useState(false);
    const [paymentConfirm, setPaymentConfirm] = useState(false);
    const [valuePaymentConfirm, setValuePaymentConfirm] = useState(0);
    const [useDurationBooking, setUseDurationBooking] = useState(false);
    const [durationBooking, setDurationBooking] = useState('00:00');
    const [useTerms, setUseTerms] = useState(false);
    const [image1, setImage1] = useState<File | null | string>(null)
    const [firstStateImage1, setFirstStateImage1] = useState<File | null | string>(null)
    const [image2, setImage2] = useState<File | null | string>(null)
    const [firstStateImage2, setFirstStateImage2] = useState<File | null | string>(null)
    const [selectedImages, setSelectedImages] = useState<Array<string>>([]);

    const [daySelected, setDaySelected] = useState<BookingDayType | null>(null);
    const [bookingDays, setBookingsDays] = useState<BookingDayType[]>([])
    const [bookingDaysFirstState, setBookingsDaysFirstState] = useState<BookingDayType[]>([])
    const [days, setDays] = useState<DayType[]>([
        {
            name: 'sunday',
            nick: 'sun',
            able: false
        },
        {
            name: 'monday',
            nick: 'mon',
            able: false
        },
        {
            name: 'tuesday',
            nick: 'tue',
            able: false
        },
        {
            name: 'wednesday',
            nick: 'wed',
            able: false
        },
        {
            name: 'thursday',
            nick: 'thur',
            able: false
        },
        {
            name: 'friday',
            nick: 'fri',
            able: false
        },
        {
            name: 'saturday',
            nick: 'sat',
            able: false
        }
    ])

    const changeBookingConfig = async (saveDayHour?: boolean) => {
        try {
            setLoading(true)
            const bookingDaysSend = bookingDays.map((bk) => ({
                day: bk.day,
                able: bk.able,
                bookingHours: bk.bookingHours.map((hour) => ({ hour }))
            }))
            const res = await POST('/queueReservation/bookingConfig', {
                able,
                maxPeople,
                expiryTime,
                dayConfirm,
                paymentConfirm,
                valuePaymentConfirm: Math.trunc(valuePaymentConfirm * 100),
                bookingDays: (bookingDays === bookingDaysFirstState && !saveDayHour) ? [] : bookingDaysSend,
                useTerms,
                duration: useDurationBooking ? (parseInt(durationBooking.split(":")[0]) * 60) + parseInt(durationBooking.split(":")[1]) : 0
            })
            if (res && res.data && res.data) {
                const bookingConfig = res.data
                let imagesBooking = [];

                if (image1 && image1 !== firstStateImage1) {
                    if (firstStateImage1 && !firstStateImage1.toString().includes('img.ninegrid.com/exemplo/')) {
                        await POST('/upload-images/delete-one', {
                            url: firstStateImage1,
                        })
                    }
                    const formData = new FormData()
                    formData.append('file', image1)
                    formData.append('nameImage', `booking-config-${uuid()}.jpg`)
                    formData.append('originalSize', 'true')
                    const imageRes = await POST('/upload-images/upload-one', formData)
                    if (imageRes && imageRes.image) {
                        imagesBooking.push(imageRes.image);
                    }
                } else if (image1 && image1 === firstStateImage1) {
                    imagesBooking.push(image1)
                }
                if (selectedImages[0] && imagesBooking.length < 2) {
                    imagesBooking.push(selectedImages[0])
                }
                if (image2 && image2 !== firstStateImage2) {
                    if (firstStateImage2 && !firstStateImage2.toString().includes('img.ninegrid.com/exemplo/')) {
                        await POST('/upload-images/delete-one', {
                            url: firstStateImage2,
                        })
                    }
                    const formData = new FormData()
                    formData.append('file', image2)
                    formData.append('nameImage', `booking-config-${uuid()}.jpg`)
                    formData.append('originalSize', 'true')
                    const imageRes = await POST('/upload-images/upload-one', formData)
                    if (imageRes && imageRes.image) {
                        imagesBooking.push(imageRes.image);
                    }
                } else if (image2 && image2 === firstStateImage2) {
                    imagesBooking.push(image2)
                }
                if (selectedImages[1] && imagesBooking.length < 2) {
                    imagesBooking.push(selectedImages[1])
                }
                await POST('/queueReservation/bookingImage', {
                    images: imagesBooking,
                    bookingConfigId: bookingConfig.id
                })
                setLoading(false)
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Configuração realizada!',
                }).then(async () => await init())
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao configurar reserva!',
                })
            }
        } catch (e) {
            setLoading(false)
            console.log(e)
            Swal.fire({
                icon: 'error',
                title: 'Erro',
                text: 'Erro ao configurar reserva!',
            })
        }
    }

    const getBookingConfig = async () => {
        try {
            const res = await GET('/queueReservation/bookingConfig')
            if (res && res.success && res.data && res.data.length > 0) {
                const bookingConfig = res.data[0];
                setAble(bookingConfig.able)
                setMaxPeople(bookingConfig.maxPeople)
                setExpiryTime(bookingConfig.expiryTime)
                setPaymentConfirm(bookingConfig.paymentConfirm)
                setValuePaymentConfirm(bookingConfig.valuePaymentConfirm / 100)
                setDayConfirm(bookingConfig.dayConfirm)
                setUseTerms(bookingConfig.useTerms)
                setUseDurationBooking(bookingConfig.duration > 0 ? true : false)
                if (bookingConfig.duration > 0) {
                    const hour = Math.trunc(bookingConfig.duration / 60);
                    const minute = bookingConfig.duration - (hour * 60);
                    setDurationBooking(`${hour > 9 ? hour : `0${hour}`}:${minute > 9 ? minute : `0${minute}`}`)
                }
                const bookingDaysGet = bookingConfig.days.map((dayBk: BookingDayResponseType) => ({
                    able: dayBk.able,
                    day: dayBk.day,
                    bookingHours: dayBk.hours.map((hourBk) => hourBk.hour)
                }))
                const newDays = days.map((dayItem) => {
                    const bookingFind = bookingConfig.days.find((dayBooking: BookingDayResponseType) => dayBooking.day === dayItem.name)
                    if (bookingFind) dayItem.able = bookingFind.able
                    return dayItem
                })
                setDays(newDays);
                setBookingsDays(bookingDaysGet)
                setBookingsDaysFirstState(bookingDaysGet)
                setImage1(null);
                setImage2(null);
                setFirstStateImage1(null);
                setFirstStateImage2(null);
                if (bookingConfig.images) {
                    let urls = [];
                    const images = bookingConfig.images.sort((a: any, b: any) => (parseInt(a.id) > parseInt(b.id)) ? 1 : ((parseInt(b.id) > parseInt(a.id)) ? -1 : 0))
                    if (images[0]) {
                        if (images[0].url.includes('img.ninegrid.com/exemplo/')) {
                            urls.push(images[0].url);
                        } else {
                            setImage1(images[0].url)
                            setFirstStateImage1(images[0].url)
                        }
                    } else {
                        setImage1(null)
                        setFirstStateImage1(null)
                    }
                    if (images[1]) {
                        if (images[1].url.includes('img.ninegrid.com/exemplo/')) {
                            urls.push(images[1].url);
                        } else {
                            setImage2(images[1].url)
                            setFirstStateImage2(images[1].url)
                        }
                    } else {
                        setImage2(null)
                        setFirstStateImage2(null)
                    }
                    setSelectedImages(urls)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getLink = async () => {
        const appRes = await GET('/appconfig');
        if (appRes && appRes.data) {
            const webapp = appRes.data.find((app: any) => app.type === 'qr');
            let url = '';
            if (webapp) {
                if (process.env.REACT_APP_PROD === '1') {
                    url =
                        'https://webapp' +
                        '.ninegrid.com.br/' +
                        window.btoa(webapp.pluginSpace.subdomain) +
                        '/booking'
                } else {
                    url =
                        process.env.REACT_APP_WEBAPP_URL +
                        '/' +
                        window.btoa(webapp.pluginSpace.subdomain) +
                        '/booking'
                }
            }
            setLink(url)
        }
    }

    const init = async () => {
        setLoading(true)
        await getLink();
        await getBookingConfig();
        setLoading(false)
    }

    const selectImage = (url: string) => {
        if (selectedImages.includes(url)) {
            setSelectedImages(selectedImages.filter((i) => i !== url));
        } else {
            if (selectedImages.length < 2) {
                const newSelecteds = selectedImages.map((i) => i);
                newSelecteds.push(url);
                setSelectedImages(newSelecteds);
            }
        }
    }

    const changeDayBookingConfig = async () => {
        const newDays = bookingDays.map((b) => b);
        newDays.forEach((bk) => {
            if (daySelected && bk.day === daySelected.day) {
                bk = daySelected;
            }
        })
        const newDaysItems = days.map((dayItem) => {
            const bookingFind = newDays.find((dayBooking) => dayBooking.day === dayItem.name)
            if (bookingFind) dayItem.able = bookingFind.able
            return dayItem
        })
        setDays(newDaysItems);
        setBookingsDays(newDays)
        await changeBookingConfig(true);
    }

    const changeHour = (hour: string) => {
        if (daySelected) {
            setLoading(true);
            const day: BookingDayType = Object.assign(daySelected);
            if (day.bookingHours.includes(hour)) {
                const hours = day.bookingHours.filter((h) => h !== hour);
                day.bookingHours = hours;
                setDaySelected(day);
            } else {
                day.bookingHours.push(hour)
                setDaySelected(day);
            }
            setTimeout(() => {
                setLoading(false);
            }, 200)
        }
    }

    const validate = (value: string) => {
        if (value) {
            value = value.replaceAll('_', '0')
            const values = value.split(':')
            let val1 = parseInt(values[0]) > 23 ? 23 : values[0]
            let val2 = parseInt(values[0]) > 23 || parseInt(values[1]) > 59 ? 59 : values[1]
            value = `${val1}:${val2}`
        }
        return value
    }

    useEffect(() => {
        init();
    }, [])

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                        <div className='card-header py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`booking.config`)}</span>
                                <BreadCrumbs />
                            </h3>
                        </div>
                        <div className='card-body'>
                            <div className="d-flex flex-row gap-2 align-items-end">
                                <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.able`)}</span>
                                <Input
                                    checkSwift
                                    inputAttr={{
                                        type: 'checkbox',
                                    }}
                                    checked={able}
                                    value={able}
                                    change={(value: boolean) => setAble(value)}
                                />
                                <span className='fw-bolder fs-6 mb-1 ml-2'>{i18n.t(`booking.link`)}</span>
                                <a target='_blank' className='fw-normal fs-6 mb-1' href={link}>{link}</a>
                            </div>
                            <div className="d-flex flex-row align-items-center mt-4 gap-4">
                                <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.days_week`)}</span>
                                {days.map((dayItem) => (
                                    <button
                                        className={dayItem.able ? styles.buttonActiveWeekDay : styles.buttonWeekDay}
                                        onClick={() => {
                                            const day = bookingDays.find((bk) => bk.day === dayItem.name)
                                            if (day) {
                                                setDaySelected(day)
                                            } else {
                                                const newDay: BookingDayType = {
                                                    able: false,
                                                    bookingHours: [],
                                                    day: dayItem.name
                                                }
                                                setDaySelected(newDay)
                                                const days = bookingDays.map((bk) => bk)
                                                days.push(newDay)
                                                setBookingsDays(days)
                                            }
                                            showWrapperModal(i18n.t(`week_day_full.${dayItem.nick}`))
                                        }}
                                    >
                                        {i18n.t(`week_day.${dayItem.nick}`)}
                                    </button>
                                ))}
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <div className="d-flex flex-column" style={{ maxWidth: '24em' }}>
                                    <Input
                                        inputAttr={{
                                            type: 'number',
                                        }}
                                        label={i18n.t(`booking.max_people`)}
                                        value={maxPeople}
                                        change={(value: number) => setMaxPeople(value)}
                                    />
                                    <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`booking.max_people_text`)}</span>
                                </div>
                                <div className="d-flex flex-column" style={{ maxWidth: '24em' }}>
                                    <Input
                                        inputAttr={{
                                            type: 'number',
                                        }}
                                        label={i18n.t(`booking.expiry_time`)}
                                        value={expiryTime}
                                        change={(value: number) => setExpiryTime(value)}
                                    />
                                    <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`booking.expiry_time_text`)}</span>
                                </div>
                                <div className="d-flex flex-column" style={{ maxWidth: '24em' }}>
                                    <Input
                                        inputAttr={{
                                            type: 'select',
                                        }}
                                        value={dayConfirm}
                                        change={(value: boolean) => setDayConfirm(value)}
                                        label={i18n.t(`booking.day_confirm`)}
                                        options={[
                                            { select: 'Sim', value: true },
                                            { select: 'Não', value: false },
                                        ]}
                                    />
                                    <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`booking.day_confirm_text`)}</span>
                                </div>
                                <div className="d-flex flex-row align-items-end gap-2">
                                    <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.payment_confirm`)}</span>
                                    <Input
                                        checkSwift
                                        inputAttr={{
                                            type: 'checkbox',
                                        }}
                                        checked={paymentConfirm}
                                        value={paymentConfirm}
                                        change={(value: boolean) => setPaymentConfirm(value)}
                                    />
                                </div>
                                <div className="d-flex flex-column" style={{ maxWidth: '25em' }}>
                                    <Input
                                        inputAttr={{
                                            type: 'money',
                                        }}
                                        currency='BRL'
                                        locale='pt-BR'
                                        label={i18n.t(`booking.value_payment_confirm`)}
                                        change={(value: number) => setValuePaymentConfirm(value)}
                                        value={valuePaymentConfirm}
                                    />
                                </div>
                                <div className="d-flex flex-row align-items-end gap-2">
                                    <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.use_time`)}</span>
                                    <Input
                                        checkSwift
                                        inputAttr={{
                                            type: 'checkbox',
                                        }}
                                        checked={useDurationBooking}
                                        value={useDurationBooking}
                                        change={(value: boolean) => setUseDurationBooking(value)}
                                    />
                                </div>
                                {useDurationBooking && (
                                    <div className="d-flex flex-column" style={{ maxWidth: '25em' }}>
                                        <Input
                                            inputAttr={{
                                                type: 'hour',
                                            }}
                                            value={durationBooking}
                                            change={(value: string) => setDurationBooking(value)}
                                            placeholder='00:00'
                                            label={`${i18n.t(`booking.time`)}`}
                                            blur={(value: string) => setDurationBooking(validate(value))}
                                        />
                                    </div>
                                )}
                                <div className="d-flex flex-column w-100">
                                    <Input
                                        inputAttr={{
                                            type: 'checkbox',
                                        }}
                                        label={i18n.t(`booking.use_terms`)}
                                        checked={useTerms}
                                        value={useTerms}
                                        change={(value: boolean) => setUseTerms(value)}
                                    />
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2 mt-4">
                                <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.image_title`)}</span>
                                <span className='fw-normal text-muted fs-7 mb-1 '>{i18n.t(`booking.image_text`)}</span>
                                <div className="d-flex flex-row gap-8">
                                    <Input
                                        inputAttr={{
                                            type: 'image',
                                        }}
                                        label={`${i18n.t(`booking.image`)} 1`}
                                        value={image1}
                                        change={(value: BaseSyntheticEvent) =>
                                            setImage1(value ? value.target.files[0] : null)
                                        }
                                    />
                                    <Input
                                        inputAttr={{
                                            type: 'image',
                                        }}
                                        label={`${i18n.t(`booking.image`)} 2`}
                                        value={image2}
                                        change={(value: BaseSyntheticEvent) =>
                                            setImage2(value ? value.target.files[0] : null)
                                        }
                                    />
                                </div>
                                <div className="d-flex flex-row flex-wrap gap-4 mt-6" style={{ width: '90%' }}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                        <div
                                            className='w-175px h-125px'
                                            style={selectedImages.includes(`https://s3.amazonaws.com/img.ninegrid.com/exemplo/${item}.png`) ? { border: '8px solid #5D8ED8' } : {}}
                                            onClick={() => selectImage(`https://s3.amazonaws.com/img.ninegrid.com/exemplo/${item}.png`)}
                                        >

                                            <img
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                src={`https://s3.amazonaws.com/img.ninegrid.com/exemplo/${item}.png`}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <div className="d-flex flex-row justify-content-end">
                                    <Button
                                        text={i18n.t(`buttons.save`)}
                                        color='primary'
                                        size='small'
                                        horizontalPadding={6}
                                        btnAttr={{
                                            type: 'button',
                                        }}
                                        click={() => changeBookingConfig()}
                                        btnClass='fw-bolder m-9'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalWrapper
                        confirmAction={async () => await changeDayBookingConfig()}
                    >
                        <div className="d-flex flex-column gap-6" style={{ maxWidth: 335 }}>
                            <div className="d-flex flex-row gap-2 align-items-end">
                                <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.booking_day`)}</span>
                                <Input
                                    checkSwift
                                    inputAttr={{
                                        type: 'checkbox',
                                    }}
                                    checked={daySelected?.able}
                                    value={daySelected?.able}
                                    change={(value: boolean) => {
                                        if (daySelected) {
                                            const day = Object.assign(daySelected)
                                            day.able = value
                                            setDaySelected(day)
                                        }
                                    }}
                                />
                            </div>
                            <span className='fw-bolder fs-6 mb-1'>{i18n.t(`booking.booking_day_text`)}</span>
                            <div className="d-flex flex-row flex-wrap">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((value) => (
                                    <div
                                        onClick={() => changeHour(value < 10 ? `0${value}:00` : `${value}:00`)}
                                        className={daySelected?.bookingHours.includes(value < 10 ? `0${value}:00` : `${value}:00`) ? styles.dayItemSelected : styles.dayItem}
                                    >
                                        <p>{value < 10 ? `0${value}` : value}:00</p>
                                    </div>
                                ))}
                            </div>
                            <span className='fw-normal text-muted fs-7 mb-1 my-1'>{i18n.t(`booking.booking_day_subtext`)}</span>
                        </div>

                    </ModalWrapper>
                </div>
            )}
        </>
    )
}

const BookingConfig: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`booking.config`), path: 'booking/config', isActive: true },
                ]}
            >
                {i18n.t(`booking.config`)}
            </PageTitle>
            <BookingConfigContent />
        </>
    )
}

export { BookingConfig }
