export function getPlugintypeIdByCode(code: string) {
  switch (code) {
    case 'general':
      return 1
    case 'support':
      return 2
    case 'iotapi':
      return 3
    case 'pdv':
      return 4
    case 'features':
      return 5
    case 'bi':
      return 6
    case 'hospitality':
      return 7
    case 'contact':
      return 8
    case 'feed':
      return 10
    case 'survey':
      return 11
    case 'service':
      return 12
    case 'gateway':
      return 14
    case 'varejo':
      return 15
    case 'email':
      return 16
    case 'fashion':
      return 17
    case 'food':
      return 18
    case 'retail':
      return 18
    case 'aggregator':
      return 21
  }
}
