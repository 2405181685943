import { BaseSyntheticEvent, FC, KeyboardEvent, SetStateAction, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import Swal from 'sweetalert2'
import { POST, PUT } from '../../../services/api'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { i18n } from '../../../translate/i18n'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import useModals from '../../../hooks/useModals'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import usePluginsSpace from '../../../hooks/usePluginSpace'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type Live = {
    name: string,
    status: boolean,
    initialDate: Date;
    finalDate: Date;
}

type LiveProducts = {

}
type ImageType = {
    path: string
    imgsuffix: string
    productId: number
    typeimg: string
    order: number
}


const CriarLiveShopContent: FC<any> = () => {

    const navigate = useNavigate()
    const { showWrapperModal } = useModals()
    const [loading, setLoading] = useState(false)
    const [loadingComp, setLoadingComp] = useState(false)
    const [name, setName] = useState('')
    const { locations } = usePluginsSpace()

    const [searchproduct, setSearchProduct] = useState('')
    const [listsearchproducts, setListSearchProducts] = useState<any[]>([])
    const [listProducts, setListProducts] = useState<any[]>([])

    const [startDate, setStartDate] = useState('')
    const [finalDate, setFinalDate] = useState('')

    const [startHour, setStartHour] = useState('12:00:00')
    const [finalHour, setFinalHour] = useState('12:00:00')


    const [product, setProduct] = useState<any>()

    const searchProduct = async () => {
        if (!searchproduct) {
            Swal.fire({
                icon: 'warning',
                title: 'Atenção',
                text: 'Informe o nome de um produto para pesquisar!',
            })
            return
        }
        try {
            setLoadingComp(true)

            
            const location = locations.find(el => el.code === 'withdraw')

            const res = await POST('/product/filter', {
                name: searchproduct,
                isInventoryGreaterThan: 1,
                status: true,
                hasImages: true,
                location: location?.id

            })
        

            const produtos = res.data.filter((produto: any) => produto.images.length > 0).map((row: any) => {
                let imgProduct = ''
                if (row.images.length > 0 && process.env.REACT_APP_AWS_S3_URL) {
                    let imgFind = row.images.find((img: ImageType) => img.typeimg === 'imgsmall')
                    if (imgFind) {
                        imgProduct =
                            imgFind.path.substring(7, imgFind.path.length) + '/' ===
                                process.env.REACT_APP_AWS_S3_URL.substring(7, process.env.REACT_APP_AWS_S3_URL.length)
                                ? imgFind.path + '/' + imgFind.imgsuffix
                                : imgFind.path
                    }
                }

                row.variations.map((variation: any) => {

                    variation.img = variation.img ? variation.img : imgProduct

                    return variation

                })


                return {
                    id: row.id,
                    code: row.code,
                    description: row.description,
                    name: row.name,
                    variations: row.variations.filter((variation: { status: any; img: any }) => variation.status).map((row: any) => {
                        let specification = `<div class='d-flex flex-column'>`
                        const contextFormated = JSON.parse(row.context);
                        if (contextFormated) {
                            // eslint-disable-next-line array-callback-return
                            Object.entries(contextFormated).map((item) => {
                                specification += `<p>${item[0]}: ${item[1]}</p>`
                            })
                        }
                        specification += '</div>'

                        return {
                            ...row,
                            context: specification,
                            rawContext: row.context,
                            selected: false
                        }
                    })
                }
            })



            setListSearchProducts(produtos)
            setLoadingComp(false)
        } catch (e) {
            setLoadingComp(false)
            console.log(e)
        }
    }


    const showModal = (id: any) => {
        const prod = listsearchproducts.find(prod => prod.id === id)


        if (prod.variations.length) {

            setProduct(prod)

            showWrapperModal(i18n.t(`liveShop.variations`))
        } else {
            Swal.fire({
                icon: 'warning',
                title: 'Variações não encontradas',
                text: 'Verifique se as variações desse produto possuem preço, imagem e estão ativas!'
            })
        }


    }

    const showModalSeleted = (id: any) => {
        const prod = listProducts.find(prod => prod.id === id)

        setProduct(prod)

        showWrapperModal(i18n.t(`liveShop.variations`))
    }

    const addList = async () => {
        //Pegar as variações selecionadas e add a lista de produtos

        const produto = { ...product, variations: product.variations.filter((variation: any) => variation.selected == true) }

        if (produto.variations.length <= 0) {
            delList(produto.id)
            return
        }
        const newList = listProducts.map((product) => product)
        const oldList = newList.filter(function (row) {
            return row.id == produto.id
        })
        const selected = listsearchproducts.filter(function (row) {
            return row.id == produto.id
        })
        if (oldList.length == 0) {
            newList.push(produto)
            setListProducts(newList)
        } else {
            const index = newList.findIndex((row) => row.id == produto.id)

            newList[index] = produto

            setListProducts(newList)
        }

        //f


    }
    const delList = (id: any) => {
        const newList = listProducts.filter((row) => row.id !== id)
        setListProducts(newList)
    }

    const create = async () => {
        try {

            if (!name || !startDate || !finalDate || !startHour || !finalHour || listProducts.length == 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Campos faltantes',
                    text: 'Verifique se preencheu todos os campos e se há produtos adicionados!',
                })
                return
            }
            setLoading(true)

            const dateI = `${startDate} ${startHour}`
            const dateF = `${finalDate} ${finalHour}`

            const resLive = await POST('/livemeta', {
                name: name,
                initialDate: moment(dateI).format('YYYY-MM-DD hh:mm:00'),
                finalDate: moment(dateF).format('YYYY-MM-DD hh:mm:00'),
                status: true
            })

            if (resLive.success === true) {
                const idLiveCatalog = resLive.data.id
                const liveCatalog = listProducts.map((produto: any) => {

                    const schema = {
                        liveId: idLiveCatalog,
                        productId: produto.id,
                        codeProduct: produto.code,
                        productName: produto.name,
                        description: produto.description,
                        variantOptions: produto.variations.map((variation: any) => {
                            const schemaVariations = {
                                productSku: variation.sku,
                                discount: 0,
                                price: variation.price,
                                unitPrice: variation.price,
                                imageUrl: variation.img,
                                stock: variation.inventory,
                                variation: [
                                    JSON.parse(variation.rawContext)
                                ]
                            }

                            return schemaVariations
                        }),
                    }

                    return schema

                })

                const resLiveCatalog = await POST('/livecatalog/multiple', liveCatalog)

                if (resLiveCatalog.success === true) {
                    setLoading(false)
                    Swal.fire({
                        icon: 'success',
                        title: 'Catálogo ao Vivo cadastrado',
                        text: 'Cadastro realizado com sucesso!',
                    }).then(() => navigate(`/live_shop`))
                } else {
                    setLoading(false)
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Erro ao cadastrar o Catálogo ao Vivo!',
                    })
                }
            } else {
                setLoading(false)
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar o Catálogo ao Vivo!',
                })
            }







            /*

            if (resLiveCatalog.success === true) {
                Swal.fire({
                    icon: 'success',
                    title: 'Catálogo ao Vivo cadastrado',
                    text: 'Cadastro realizado com sucesso!',
                }).then(() => navigate(`/live_shop`))
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Erro ao cadastrar o Catálogo ao Vivo!',
                })
            }
            */

        } catch (e) {
            setLoadingComp(false)
            console.log(e)
        }
    }

    const setVariationSelected = (id: number, value: boolean) => {


        const newVariations = product.variations.map((variation: any) => {
            if (variation.id === id) {
                variation.selected = value
            }

            return variation
        })

        const prodAux = { ...product, variations: newVariations }

        setProduct(prodAux)

    }

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='row g-5 gx-xxl-12'>
                    <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                        <div className='card-header py-5'>
                            <h3 className='card-title align-items-start flex-column'>
                                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`liveShop.liveShop`)}</span>
                                <BreadCrumbs />
                                <span className='text-muted mt-1 fw-bold fs-7'>
                                    {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                                </span>
                            </h3>
                        </div>
                        <div className='card-body'>
                            <div className='card-body border-bottom'>
                                <div className='d-flex flex-row flex-wrap gap-10'>
                                    <div style={{ flex: 1 }}>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{
                                                        type: 'default',
                                                    }}
                                                    placeholder={i18n.t(`smart_order.name`)}
                                                    label={i18n.t(`smart_order.name`)}
                                                    value={name}
                                                    change={(value: string) => setName(value)}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'date' }}
                                                    label={i18n.t(`liveShop.dateInit`)}
                                                    value={startDate}
                                                    change={setStartDate}
                                                    maxLength={finalDate}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'time' }}
                                                    label={i18n.t(`liveShop.hourInit`)}
                                                    value={startHour}
                                                    change={setStartHour}

                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'date' }}
                                                    label={i18n.t(`liveShop.dateEnd`)}
                                                    value={finalDate}
                                                    change={setFinalDate}
                                                    minLength={startDate}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <Input
                                                    inputAttr={{ type: 'time' }}
                                                    label={i18n.t(`liveShop.hourEnd`)}
                                                    value={finalHour}
                                                    change={setFinalHour}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <hr />

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group mt-5'>
                                            <OverlayTrigger
                                                placement={'right'}
                                                trigger={'hover'}

                                                overlay={
                                                    <Tooltip>
                                                        {i18n.t(`liveShop.search_products_Attentions`)}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>
                                                    {i18n.t(`smart_order.search_products`)} *
                                                </span>
                                            </OverlayTrigger>




                                            <label className='form-label fw-normal'>

                                            </label>
                                            <div className='input-group'>
                                                <input
                                                    type='text'
                                                    className='form-control fw-normal'
                                                    placeholder={i18n.t(`smart_order.search_products`)}
                                                    value={searchproduct}
                                                    onChange={(event) => setSearchProduct(event.target.value)}
                                                />
                                                <div className='input-group-append'>
                                                    <button
                                                        className='btn btn-success'
                                                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                                        type='button'
                                                        onClick={() => searchProduct()}
                                                    >
                                                        +
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        {loadingComp ? (
                                            <Loading />
                                        ) : (
                                            <Datatable
                                                data={listsearchproducts}
                                                headers={[
                                                    { key: '#ID', value: 'id', type: 'default' },
                                                    { key: i18n.t(`smart_order.prod`), value: 'name', type: 'default' },
                                                    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                                                ]}
                                                options={[
                                                    {
                                                        icon: 'fas fa-eye',
                                                        action: (item) => {
                                                            showModal(item.id)
                                                        },
                                                        title: 'Variações',
                                                    },
                                                ]}
                                                color='primary'
                                            />
                                        )}
                                    </div>

                                    <div className='col-md-6'>
                                        {listProducts.length > 0 && (
                                            <Datatable
                                                data={listProducts}
                                                headers={[
                                                    { key: '#ID', value: 'id', type: 'default' },
                                                    { key: i18n.t(`smart_order.prod`), value: 'name', type: 'default' },
                                                    { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                                                ]}
                                                options={[
                                                    {
                                                        icon: 'fas fa-eye',
                                                        action: (item) => {
                                                            showModalSeleted(item.id)
                                                        },
                                                        title: 'Variações',
                                                    },
                                                    {
                                                        icon: 'fas fa-trash',
                                                        action: (item) => {
                                                            delList(item.id)
                                                        },
                                                        title: 'adicionar',
                                                    },
                                                ]}
                                                color='primary'
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="d-flex w-100">
                                    <div className="col-6 d-flex justify-content-start">
                                        <Button
                                            text={i18n.t(`buttons.back`)}
                                            color='primary'
                                            size='small'
                                            horizontalPadding={6}
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => navigate(-1)}
                                            btnClass='fw-bolder m-9'
                                        />
                                    </div>
                                    <div className="col-6  d-flex justify-content-end">
                                        <Button
                                            text={i18n.t(`buttons.create`)}
                                            color='primary'
                                            size='small'
                                            horizontalPadding={6}
                                            btnAttr={{
                                                type: 'button',
                                            }}
                                            click={() => create()}
                                            btnClass='fw-bolder m-9'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalWrapper confirmAction={() => addList()}>
                        {product && (
                            <>
                                <div className='card-header py-5'>
                                    <h3>{product.name}</h3>

                                </div>
                                <div className='card-body '>
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <h4>#</h4>
                                        <h4>SKU</h4>
                                        <h4>Variação</h4>
                                        <h4>Imagem</h4>
                                    </div>
                                    {product.variations.map((variation: any) =>
                                    (
                                        <div className='d-flex justify-content-between align-items-center my-2'>
                                            <Input
                                                value={variation.selected}
                                                checked={variation.selected}
                                                change={(value: boolean) => setVariationSelected(variation.id, value)}
                                                inputAttr={{
                                                    type: 'checkbox',
                                                }}
                                                size={'sm'}
                                                checkSwift={false}
                                            />

                                            <span>{variation.sku}</span>
                                            <div dangerouslySetInnerHTML={{ __html: `${variation.context}` }}>
                                            </div>
                                            <img
                                                src={variation.img}
                                                alt=''
                                                style={{ objectFit: 'cover' }}
                                                className='w-75px h-75px bg-light rounded'
                                            />
                                        </div>
                                    )
                                    )
                                    }
                                </div>

                            </>
                        )

                        }
                    </ModalWrapper>
                </div>
            )}
        </>
    )
}

const CriarLiveShop: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Dashboard', path: 'dashboard', isActive: true },
                    { title: i18n.t(`liveShop.create`), path: 'live_shop', isActive: true },
                    { title: i18n.t(`buttons.create`), path: 'live_shop/criar', isActive: true },
                ]}
            >
                {`${i18n.t(`liveShop.create`)}`}
            </PageTitle>
            <CriarLiveShopContent />
        </>
    )
}



export { CriarLiveShop }