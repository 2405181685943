import { FC } from 'react'
import { useLocation, useMatch, useParams } from 'react-router-dom'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const CriarOpcionaisContent: FC = () => {
  const params = useParams()
  const { type } = params

  return (
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{`Criar ${type === 'itens-de-combo' ? 'itens de combo' : type
              }`}</span>
            <BreadCrumbs />
            {/* <span className='text-muted mt-1 fw-bold fs-7'>
              Preencha as informações abaixo e clique no botão criar para salvar as alterações.
            </span> */}
          </h3>
        </div>
        <div className='card-body border-bottom'>
          <div className='d-flex justify-content-between mb-5'>
            <div className='w-50'>
              <div className='me-5 flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Nome'
                  label='Nome'
                />
              </div>
              <div className='me-5 flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Código'
                  label='Código'
                />
              </div>
              {type === 'acompanhamentos' && (
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    placeholder='Quantidade máxima'
                    label='Quantidade máxima'
                    minNumber={0}
                  />
                </div>
              )}
              {type !== 'acompanhamentos' && (
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'money',
                    }}
                    placeholder='Preço'
                    label='Preço'
                    currency='BRL'
                    locale='pt-BR'
                  />
                </div>
              )}

            </div>

            <div className='w-50'>
              <div className='ms-5 flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'default',
                  }}
                  placeholder='Descrição'
                  label='Descrição'
                />
              </div>
              <div className='ms-5 flex-grow-1'>
                <Input
                  inputAttr={{
                    type: 'select',
                  }}
                  placeholder='Selecione um status'
                  label='Status'
                  options={[
                    { select: 'Ativo', value: 'ativo' },
                    { select: 'Inativo', value: 'inativo' },
                  ]}
                />
              </div>
              {type !== 'acompanhamentos' && (
                <div className='ms-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    placeholder='Quantidade máxima'
                    label='Quantidade máxima'
                    minNumber={0}
                  />
                </div>
              )}

            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <Button
            text='Criar'
            color='primary'
            size='small'
            horizontalPadding={6}
            btnAttr={{
              type: 'button',
            }}
            click={() => { }}
            btnClass='fw-bolder m-9'
          />
        </div>
      </div>
    </div>
  )
}

const CriarOpcionais: FC = () => {
  const params = useParams()
  const { type } = params

  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Opcionais', path: 'smart-order/opcionais', isActive: true },
          { title: `Criar ${type === 'itens-de-combo' ? 'itens de combo' : type}`, path: `#`, isActive: true },
        ]}
      >
        {`Criar ${type === 'itens-de-combo' ? 'itens de combo' : type}`}
      </PageTitle>
      <CriarOpcionaisContent />
    </>
  )
}

export { CriarOpcionais }
