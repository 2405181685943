/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import { PUT, POST } from '../../../services/api'
import useModals from '../../../hooks/useModals'
import { Loading } from '../../../components/Loading/Loading'

import { i18n } from '../../../translate/i18n'
import { Input } from '../../../components/Input/Input'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import { init } from 'i18next'

type ServiceType = {
  id: number
  name: string
  images: Array<string>
  code: string
  order: string
  status: boolean
}


type categories = {
  value: string
  select: string
}


const ListarServicosContent: FC = () => {
  const navigate = useNavigate()

  const { showDeleteModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [services, setServices] = useState<ServiceType[]>([])
  const [id, setId] = useState<number | null>(null)
  const [filterCategoria, setFilterCategoria] = useState<any>({
    label: 'Todos',
    value: 0,
  })
  const [categoria, setCategoria] = useState<categories[]>([])

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchProduct = await PUT(`/product/${Id}`, {
        status: `${!status}`,
      })
      if (switchProduct.success) {
        const newProducts = services.map((product) => {
          if (product.id === id) {
            product.status = !status
          }
          getAllData();
          return product
        })
        setServices(newProducts)
      }
    } catch (e) {
      console.log(e)
    }
  }


  const getCategories = async () => {
    try {

      const resCategorias = await POST(`/classification/filter`, {
        status: true,
        /*O Campo abaixo só é utilizando para o Hotel, 
        nessa rota a troca do pluginType dele ocorre usando esse campo 
        */
        plugintypeId: 12
      })

      const categories = resCategorias.data.map((el: any) => {
        return {
          value: el.id,
          select: el.name,
        }
      })

      categories.push({
        select: 'TODOS',
        value: 0,
      })

      categories.sort((a: any, b: any) =>
        a.select.toUpperCase() > b.select.toUpperCase()
          ? 1
          : a.select.toUpperCase() < b.select.toUpperCase()
            ? -1
            : 0
      )

      setCategoria(categories)

    } catch (error) {
      console.log(error)
    }
  }


  const getList = async () => {
    try {
      setLoading(true)
      const res = await POST('/product/filter', {
        type: 'service',
        classificationId: filterCategoria.value,
      })
      const list = res.data.map((row: any) => {
        return {
          id: row.id ? row.id : '',
          order: row.order ?? 0,
          name: row.name ? row.name : '',
          code: row.code,
          category: row.classification && row.classification.name ? row.classification.name : '',
          status: row.status ? row.status : false,
          description: row.description,
        }
      })
      setServices(list)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  async function getAllData() {
    setLoading(true)

    await Promise.all([
      getList(),
      getCategories()
    ])

    setLoading(false)
  }

  useEffect(() => {
    getAllData()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  {i18n.t(`permissions.service`)}
                </span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`smart_order.service_title`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_service`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/hospitality/service-item/create')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <div className='d-flex align-items-center justify-content-start'>
                <div className='d-flex w-25 '>
                  <div className=' align-items-end  justify-content-start me-5 flex-grow-1'>
                    <span className='align-self-start'>Categorias</span>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      options={categoria}
                      placeholder={'Categoria'}
                      search={true}
                      value={filterCategoria}
                      change={(value: any) => setFilterCategoria(value)}
                    />
                  </div>
                </div>

                <div
                  className='d-flex align-items-end w-100 justify-content-end h-100'
                  style={{
                    marginTop: '48px',
                  }}
                >
                  <Button
                    text='Filtrar'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-filter'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => getList()}
                    btnClass='mb-0 fw-bolder'
                  />
                </div>
              </div>
              <Datatable
                data={services}
                headers={[
                  { key: i18n.t(`smart_order.name`), value: 'name', type: 'default' },
                  { key: i18n.t(`smart_order.description`), value: 'description', type: 'default' },
                  { key: i18n.t(`smart_order.category`), value: 'category', type: 'default' },
                  {
                    key: i18n.t(`general.active`),
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    title: '',
                    action: (item) => {
                      navigate(`/hospitality/service-item/update/${item.id}`)
                    },
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal('')
                      setId(item.id)
                    },
                    title: 'Excluir Serviço',
                  },
                ]}
                color='primary'
              // onChangeData={async (take, page, search) => await getProducts(take, page, search)}
              // search
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const ListarServicos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          {
            title: i18n.t(`permissions.service`),
            path: 'service-item',
            isActive: true,
          },
        ]}
      >
        {i18n.t(`permissions.service`)}
      </PageTitle>
      <ListarServicosContent />
    </>
  )
}

export { ListarServicos }
