import React, { FC, useEffect, useRef, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { useNavigate } from 'react-router-dom'
import { GET, POST } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { floatToPrice } from '../../../../functions/price'
import { Button } from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import moment from 'moment'
import { i18n } from '../../../../translate/i18n'
import ReactECharts from 'echarts-for-react'
import useAuth from '../../../../hooks/useAuth'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const AcessosContent: FC = () => {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useState('')
  const [finalDate, setFinalDate] = useState('')

  const { loginInfo } = useAuth()


  const [names, setNames] = useState<any>([])
  const [total, setTotal] = useState<any>([])
  const [msgErrorBasic, setMsgErrorBasic] = useState('')
  const [msgErrorTime, setMsgErrorTime] = useState('')
  const [msgErrorIP, setMsgErrorIP] = useState('')
  const [msgErrorSeller, setMsgErrorSeller] = useState('')
  const [allDates, setAllDates] = useState<any>([])
  const [allSeries, setSeries] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [seriesData, setSeriesData] = useState<any>([])
  const [dataBasic, setDataBasic] = useState<any>([])
  const [seriesBasic, setSeriesBasic] = useState<any>([])
  const [dataTime, setDataTime] = useState<any>([])
  const [seriesTime, setSeriesTime] = useState<any>([])
  const [dataIP, setDataIP] = useState<any>([])
  const [seriesIP, setSeriesIP] = useState<any>([])
  const [dataSeller, setDataSeller] = useState<any>([])
  const [seriesSeller, setSeriesSeller] = useState<any>([])
  const [legendSeller, setLegendSeller] = useState<any>([])
  const [typePluginSpace, setTypePluginSpace] = useState<any>(`${loginInfo.plugintype}` !== 'food')

  const [loading, setLoading] = useState(false)

  const doChartBarBasic = async (once?: boolean) => {
    try {
      setLoading(true)
      const allSummary = await POST(`/summaryAccess/filtergroup`, {
        startDate: `${startDate}T00:00:00`,
        finalDate: `${finalDate}T23:59:59`,
      })
      setLoading(false)

      if (allSummary.data.data.length > 0) {
        setMsgErrorBasic('')
      } else {
        setMsgErrorBasic('Sem resultados')
        return
      }

      const data = allSummary.data.data.map((item: any) => {
        return i18n.t(`sumary_access.${item.name}`) 
      })
      const series = allSummary.data.data.map((item: any) => {
        return item.total
      })
      setDataBasic(data)
      setSeriesBasic(series)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const doChartTimePeriod = async (once?: boolean) => {
    try {
      setLoading(true)
      const allSummary = await POST(`/summaryAccess/timefiltergroup`, {
        startDate: `${startDate}T00:00:00`,
        finalDate: `${finalDate}T23:59:59`,
      })
      setLoading(false)

      if (allSummary.data.data.length > 0) {
        setMsgErrorTime('')
      } else {
        setMsgErrorTime('Sem resultados')
        return
      }

      const data = allSummary.data.data.map((item: any) => {
        return item.period
      })
      const series = allSummary.data.data.map((item: any) => {
        return item.total
      })
      setDataTime(data)
      setSeriesTime(series)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const doChartIPPeriod = async (once?: boolean) => {
    try {
      setLoading(true)
      const allSummary = await POST(`/summaryAccess/ipFilterGroup`, {
        startDate: `${startDate}T00:00:00`,
        endDate: `${finalDate}T23:59:59`,
      })
      setLoading(false)

      if (allSummary.data.data.length > 0) {
        setMsgErrorIP('')
      } else {
        setMsgErrorIP('Sem resultados')
        return
      }

      const data = allSummary.data.data.map((item: any) => {
        return moment(item.period).format('DD/MM/YYYY')
      })
      const series = allSummary.data.data.map((item: any) => {
        return item.total
      })
      setDataIP(data)
      setSeriesIP(series)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const doChartSellerPeriod = async (once?: boolean) => {
    try {
      setLoading(true)
      const allSummary = await POST(`/summarySellerCart/filter`, {
        startDate: `${startDate}T00:00:00`,
        endDate: `${finalDate}T23:59:59`,
        sellerName: (loginInfo.profile === "seller") ? loginInfo.name : null
      })
      setLoading(false)

      if (allSummary.data.entities.length > 0) {
        setMsgErrorSeller('')
      } else {
        setMsgErrorSeller('Sem resultados')
        return
      }

      const data = allSummary.data.entities.map((item: any) => {
        return moment(item.datePeriod).format('DD/MM/YYYY')
      })
      const dataUnique = data.filter((c: any, index: any) => {
        return data.indexOf(c) === index
      })

      const sellers = allSummary.data.entities.map((item: any) => {
        return item.sellerName
      })
      const sellerUnique = sellers.filter((c: any, index: any) => {
        return sellers.indexOf(c) === index
      })
      setLegendSeller(sellerUnique)

      let series: any[] = []
      sellerUnique.forEach((seller: any) => {
        let totals: any[] = []
        dataUnique.forEach((data: any) => {
          const ret = allSummary.data.entities.filter((item: any) => {
            return (
              moment(item.datePeriod).format('DD/MM/YYYY') === data && item.sellerName === seller
            )
          })
          ret.forEach((row: any) => {
            totals.push(row.total)
          })
        })
        series.push({ name: seller, type: 'line', stack: 'Total', data: totals })
      })
      setDataSeller(dataUnique)
      setSeriesSeller(series)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  // const getItemsFiltered = async (once?: boolean) => {
  //   try {
  //     setLoading(true)
  //     const allSummary = await POST(`/summaryAccess/filtergroup`, {
  //       startDate: `${startDate}T00:00:00`,
  //       finalDate: `${finalDate}T23:59:59`,
  //     })
  //     setLoading(false)

  //     console.log('Retorno', allSummary)
  //     console.log('Count', allSummary.data.itemCount)

  //     if (allSummary.data.itemCount > 0) {
  //       setMsgError('')
  //     } else {
  //       setMsgError('Sem resultados para o período selecionado')
  //       return
  //     }

  //     const allData = allSummary.data.entities.map((item: any) => {
  //       return {
  //         aggregatorName: item.aggregatorName,
  //         datePeriod: moment(item.datePeriod).format('DD/MM/YYYY'),
  //         total: item.total,
  //       }
  //     })
  //     console.log('datesArray', allData)

  //     let allDates = allData.map((item: any) => {
  //       return item.datePeriod
  //     })
  //     allDates = allDates.filter((c: any, index: any) => {
  //       return allDates.indexOf(c) === index
  //     })
  //     console.log('allDates', allDates)

  //     let seriesData: any[] = []
  //     allDates.forEach(function (i: any) {
  //       const res = allData.filter((c: any, index: any) => {
  //         return c.datePeriod == i
  //       })
  //       let total = 0
  //       res.map((item: any) => (total += item.total))
  //       seriesData.push({value: total, groupId: i})
  //     })
  //     setSeriesData(seriesData)

  //     let drill: any[] = []
  //     allDates.forEach(function (i: any) {
  //       const res = allData.filter((c: any, index: any) => {
  //         return c.datePeriod == i
  //       })
  //       const data = res.map((item: any) => {
  //         return [item.aggregatorName, item.total]
  //       })
  //       drill.push({dataGroupId: i, data: [data]})
  //     })
  //     //setDrillData(drill)
  //     console.log('drillData', drill)

  //     const namesArray = allSummary.data.entities.map((item: any) => {
  //       return item.aggregatorName
  //     })
  //     const allNames = namesArray.filter((c: any, index: any) => {
  //       return namesArray.indexOf(c) === index
  //     })
  //     // console.log(allNames)

  //     const series = allNames.map((item: any) => {
  //       const data = allDates.map((itemD: any) => {
  //         return allSummary.data.entities.filter((c: any, index: any) => {
  //           return moment(c.datePeriod).format('DD/MM/YYYY') === itemD && c.aggregatorName == item
  //         })
  //       })

  //       //if (data.length > 0) console.log('Data', data)

  //       let total: any[] = []
  //       data.forEach((itemF: any, index: number) => {
  //         if (itemF.length > 0) {
  //           total.push(itemF[0].total)
  //         }
  //       })
  //       // console.log('DATA--->', data)
  //       return {
  //         name: data[0].length > 0 ? data[0][0].aggregatorName : '',
  //         type: 'bar',
  //         label: labelOption,
  //         emphasis: {
  //           focus: 'series',
  //         },
  //         data: total,
  //       }
  //     })

  //     setAllDates(allDates)
  //     setSeries(series)

  //     // setTotalValuesByMonth(
  //     //   allMonthRes.data.map((monthItem: TimeItemsResponseType) => ({
  //     //     month: monthItem.month,
  //     //     total: monthItem.total.toFixed(2),
  //     //     sales: parseInt(monthItem.sales),
  //     //   }))
  //     // )

  //     // if (!once) {
  //     //   await getItemsFiltered(true)
  //     // }
  //   } catch (e) {
  //     setLoading(false)
  //     console.log(e)
  //   }
  // }

  useEffect(() => {
    setStartDate(moment().format('YYYY-MM-DD'))
    setFinalDate(moment().format('YYYY-MM-DD'))
  }, [])

  const optionsBasic3 = {
    xAxis: {
      type: 'category',
      data: dataBasic,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        data: seriesBasic,
        type: 'bar',
      },
    ],
  }

  const optionsBasic2 = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: 'category',
      data: dataBasic,
    },
    series: [
      {
        type: 'bar',
        data: seriesBasic,
      },
    ],
  }

  const optionsBasic = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: [
      {
        type: 'category',
        data: dataBasic,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        type: 'value',
      },
    ],
    series: [
      {
        name: 'total',
        type: 'bar',
        barWidth: '30%',
        data: seriesBasic,
      },
    ],
  }

  const optionsTime = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: seriesTime,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dataTime,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'total',
        type: 'line',
        stack: 'Total',
        data: seriesTime,
      },
    ],
  }

  const optionsIP = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: seriesIP,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dataIP,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'total',
        type: 'line',
        stack: 'Total',
        data: seriesIP,
      },
    ],
  }

  const optionsSeller = {
    tooltip: {
      trigger: 'axis',
    },
    legend: {
      data: legendSeller,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: dataSeller,
    },
    yAxis: {
      type: 'value',
    },
    series: seriesSeller,
  }
  
  return (
    <>
      {/* {loading ? (
        <Loading />
      ) : ( */}
      <div className='row g-5 gx-xxl-12'>
        <div className='d-flex justify-content-between gap-5'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12 w-100'>
            <div className='card-header'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>
                  {i18n.t(`indicators.summaryAccess`)}
                </span>

                <BreadCrumbs />
              </h3>
            </div>
            <div className='card-body'>
              <div>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>
                    {i18n.t(`indicators.filters`)}
                  </span>
                </h3>
              </div>
              <div className='d-flex gap-5 flex-wrap'>
                <Input
                  inputAttr={{
                    type: 'date',
                  }}
                  label={i18n.t(`indicators.first`)}
                  value={startDate}
                  change={(value: string) => setStartDate(value)}
                />
                <Input
                  inputAttr={{
                    type: 'date',
                  }}
                  label={i18n.t(`indicators.end`)}
                  value={finalDate}
                  change={(value: string) => setFinalDate(value)}
                />
                <div className='d-flex align-items-end'>
                  <Button
                    text={i18n.t(`buttons.filter`)}
                    color='primary'
                    size='small'
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => {
                      doChartBarBasic()
                      doChartTimePeriod()
                      doChartIPPeriod()
                      doChartSellerPeriod()
                    }}
                    btnClass='fw-bolder btn-info mb-1'
                    verticalPadding={3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between gap-5'>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex justify-content-between gap-5'>
              <div className='card mb-5 w-100'>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      {i18n.t(`indicators.summaryAccessGroup`)}
                    </span>
                  </h3>
                </div>
                <div className='card-body'>
                  {loading ? (
                    <Loading />
                  ) : msgErrorBasic == '' ? (
                    <ReactECharts option={optionsBasic2} style={{ height: 600 }} />
                  ) : (
                    msgErrorBasic
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between gap-5'>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex justify-content-between gap-5'>
              <div className='card mb-5 w-100'>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      {i18n.t(`indicators.summaryTimeAccessGroup`)}
                    </span>
                  </h3>
                </div>
                <div className='card-body'>
                  {loading ? (
                    <Loading />
                  ) : msgErrorTime == '' ? (
                    <ReactECharts option={optionsTime} />
                  ) : (
                    msgErrorTime
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between gap-5'>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex justify-content-between gap-5'>
              <div className='card mb-5 w-100'>
                <div className='card-header'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bolder fs-3 mb-1'>
                      {i18n.t(`indicators.summaryIPAccessGroup`)}
                    </span>
                  </h3>
                </div>
                <div className='card-body'>
                  {loading ? (
                    <Loading />
                  ) : msgErrorIP == '' ? (
                    <ReactECharts option={optionsIP} />
                  ) : (
                    msgErrorIP
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {typePluginSpace ? (
          <div className='d-flex justify-content-between gap-5'>
            <div className='d-flex flex-column w-100'>
              <div className='d-flex justify-content-between gap-5'>
                <div className='card mb-5 w-100'>
                  <div className='card-header'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>
                        {i18n.t(`indicators.summarySellerCart`)}
                      </span>
                    </h3>
                  </div>
                  <div className='card-body'>
                    {loading ? (
                      <Loading />
                    ) : msgErrorSeller == '' ? (
                      <ReactECharts option={optionsSeller} />
                    ) : (
                      msgErrorSeller
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>) : null}
      </div>
      {/* )} */}
    </>
  )
}

const AcessosIndicadores: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Indicadores', path: 'indicadores/summary_access', isActive: false },
          {
            title: i18n.t(`indicators.summaryAccess`),
            path: 'indicadores/summary_access',
            isActive: true,
          },
        ]}
      >
        {i18n.t(`indicators.summaryAccess`)}
      </PageTitle>
      <AcessosContent />
    </>
  )
}

export { AcessosIndicadores }
