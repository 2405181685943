import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import { DELETE, GET, POST, PUT } from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import useAuth from '../../../../hooks/useAuth'
import usePluginsSpace from '../../../../hooks/usePluginSpace'

type SectorResponseType = {
  id: number
  name: string
  type: string
  status: boolean
  description: string | null
  code: string
  alloworder: boolean
  img: string
}

const PageContent: FC = () => {
  const navigate = useNavigate()
  const { pluginSpace } = usePluginsSpace()
  const { showWrapperModal, showDeleteModal } = useModals()

  const [sectors, setSectors] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null)
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<string>('delivery')
  const [status, setStatus] = useState<'ativo' | 'inativo'>('inativo')
  const [action, setAction] = useState<'creating' | 'editing'>('creating')
  const [loading, setLoading] = useState(false)

  const switchStatus = async (type: string, Id: number, status: boolean) => {
    try {
      const data = type === 'status' ? { status: !status } : { alloworder: !status }
      const res = await PUT(`/location/${Id}`, data)
      if (res.success) {
        const newSectors = sectors.map((row) => {
          if (row.id === Id) {
            if (type === 'status') row.status = !status
            if (type === 'alloworder') row.alloworder = !status
          }
          return row
        })
        setSectors(newSectors)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getSectors = async () => {
    try {
      setLoading(true)
      const res = await GET('/location')
      console.log(res)
      const filterSectors = res.data.filter((row: SectorResponseType) => row.type !== 'address')
      const newSectors = filterSectors.map((row: SectorResponseType) => {
        const img =
          row.img && process.env.REACT_APP_AWS_S3_URL && pluginSpace
            ? `${process.env.REACT_APP_AWS_S3_URL}${pluginSpace.subdomain}/${row.img}`
            : process.env.REACT_APP_AWS_S3_URL + 'default/no-image.png'
        return {
          id: row.id,
          code: row.code ?? '',
          name: row.name ?? '',
          status: row.status ?? false,
          alloworder: row.alloworder ?? false,
          img: img ?? '',
        }
      })
      setSectors(newSectors)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteSector = async () => {
    try {
      const locationRes = await DELETE(`/location/${id}`)
      if (locationRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Setor excluído',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getSectors())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o setor!',
        }).then(async () => await getSectors())
      }
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o setor!',
      }).then(async () => await getSectors())
      console.log(e)
    }
  }

  useEffect(() => {
    if(pluginSpace)
      getSectors()
  }, [pluginSpace])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Setores</span>
                <BreadCrumbs />
                {/* <span className='text-muted mt-1 fw-bold fs-7'>
                  Aqui estão listadas as modalidades juntamente com suas respectivas ações.
                </span> */}
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar setor'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    navigate('/hospitality/configuracoes/setores/criar')
                  }}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={sectors}
                headers={[
                  { key: 'Id', value: 'id', type: 'default' },
                  { key: 'Cod', value: 'code', type: 'default' },
                  { key: 'Nome', value: 'name', type: 'default' },
                  {
                    key: 'Status',
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus('status', item.id, item.status),
                  },
                  {
                    key: 'Pedidos',
                    value: 'alloworder',
                    type: 'check',
                    action: (item) => switchStatus('alloworder', item.id, item.alloworder),
                  },
                  { key: 'Imagem', value: 'img', type: 'image' },
                  { key: 'Ações', value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      navigate(`/hospitality/configuracoes/setores/editar/${item.id}`)
                    },
                    title: 'Editar setor',
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal('Excluir Setor')
                      setId(item.id)
                    },
                    title: 'Excluir setor',
                  },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalDelete confirmAction={() => deleteSector()} />
        </div>
      )}
    </>
  )
}

const HospitalitySetores: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: 'Setores', path: '#', isActive: true },
        ]}
      >
        Editar Setor
      </PageTitle>
      <PageContent />
    </>
  )
}

export { HospitalitySetores }
