/* eslint-disable jsx-a11y/anchor-is-valid */
import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../../_metronic/layout/core'
import {Datatable} from '../../../../../components/Datatable/Datatable'
import {Button} from '../../../../../components/Button/Button'
import {useNavigate} from 'react-router-dom'
import { DELETE, POST } from '../../../../../services/api'
import { Loading } from '../../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../../components/Modals/General/ModalDelete/ModalDelete'
import useModals from '../../../../../hooks/useModals'
import Swal from 'sweetalert2'
import { i18n } from '../../../../../translate/i18n'
import { ModalWarning } from '../../../../../components/Modals/General/ModalWarning/ModalWarning'
import { ModalWrapper } from '../../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { Input } from '../../../../../components/Input/Input'
import { BreadCrumbs } from '../../../../../components/BreadCrumbs/breadCrumbrs'

type CepType = {
  id: number;
  zipStart: string;
  zipEnd: string;
  price: number;
}

const CepContent: FC = () => {
  const {showDeleteModal, showWrapperModal, showWarningModal} = useModals()

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [ceps, setCeps] = useState<Array<CepType>>([]);
  const [id, setId] = useState<number | null>(null);
  const [fileToImport, setFileToImport] = useState<File | null>(null)

  const getCeps = async () => {
    try {
      setLoading(true);
      const cepRes = await POST('/zip-code/filter', { negativeRange: false })
      const newCeps = cepRes.data.map((cep:CepType) => {
        let newCepStart = cep.zipStart.toString();
        let newCepEnd = cep.zipEnd.toString();
        if(newCepStart.length < 8){
          let leftZeros = '';
          for(let i = 0; i < 8 - newCepStart.length; i++){
            leftZeros += '0';
          }
          newCepStart = leftZeros + newCepStart;
        }

        if(newCepEnd.length < 8){
          let leftZeros = '';
          for(let i = 0; i < 8 - newCepEnd.length; i++){
            leftZeros += '0';
          }
          newCepEnd = leftZeros + newCepEnd;
        }
        return {
          id: cep.id,
          zipStart: `${newCepStart.substring(0, 5)}-${newCepStart.substring(5, 8)}`,
          zipEnd: `${newCepEnd.substring(0, 5)}-${newCepEnd.substring(5, 8)}`,
          price: cep.price,
        }
      })
      setCeps(newCeps);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const deleteCep = async () => {
    try{
      setLoading(true);
      const cepRes = await DELETE(`/zip-code/${id}`);
      if(cepRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'CEP deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getCeps())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar CEP!'
        }).then(async () => await getCeps())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar CEP!'
      }).then(async () => await getCeps())
      console.log(e)
    }
  }

  const importFile = async () => {
    try {
      if (!fileToImport) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se foi selecionado um arquivo!',
        })
      } else {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', fileToImport)

        const importRes = await POST('/delivery/import', formData)
        setLoading(false)
        if (importRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            text: 'Importação realizada com sucesso!',
          }).then(async () => await getCeps())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao importar arquivo!',
          }).then(async () => await getCeps())
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e,
      }).then(async () => await getCeps())
    }
    setFileToImport(null)
  }

  useEffect(() => {
    getCeps();
  }, [])

  return (
    <>
    { loading ? <Loading /> :
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`delivery_fee.cep_delivery`)}</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              {i18n.t(`delivery_fee.cep_info`)}
            </span>
          </h3>
          <div className='card-toolbar d-flex justify-content-end w-100 gap-5'>
            <Button
              text={i18n.t(`buttons.add_zip_code`)}
              color='primary'
              iconColor='#FFF'
              icon='fas fa-plus'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => navigate('/configuracoes/taxa-entrega/cep/criar')}
              btnClass='fw-bolder'
            />
            <Button
              text={i18n.t(`buttons.list_of_negative_renge_zip_code`)}
              color='primary'
              iconColor='#FFF'
              icon='bi bi-list fs-4'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => navigate('/configuracoes/taxa-entrega/cep/negativo')}
              btnClass='fw-bolder'
            />
            <Button
              text=''
              color='primary'
              iconColor='#FFF'
              icon='bi bi-three-dots fs-4 p-0'
              size='small'
              btnAttr={{
                type: 'button',
              }}
              click={() => showWrapperModal(i18n.t(`delivery_fee.patterns`))}
              btnClass='fw-bolder'
            />
            <Button
              text={i18n.t(`buttons.back`)}
              color='primary'
              iconColor='#FFF'
              icon='bi bi-arrow-left fs-4'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => navigate('/configuracoes/taxa-entrega')}
              btnClass='fw-bolder'
            />
          </div>
        </div>
        <div className='card-body'>
          <Datatable
            data={ceps}
            headers={[
              {key: i18n.t(`delivery_fee.cep_start`), value: 'zipStart', type: 'default'},
              {key: i18n.t(`delivery_fee.cep_end`), value: 'zipEnd', type: 'default'},
              {key: i18n.t(`delivery_fee.value`), value: 'price', type: 'money'},
              {key: i18n.t(`delivery_fee.actions`), value: 'acoes', type: ''},
            ]}
            options={[
              {
                icon: 'fas fa-pen',
                action: (item) => {
                  navigate(`/configuracoes/taxa-entrega/cep/editar/${item.id}`)
                },
                title: `${i18n.t(`general.edit`)} ${i18n.t(`delivery_fee.cep_delivery`)}`,
              },
              {icon: 'fas fa-trash', action: (item) => {
                showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`delivery_fee.cep_delivery`)}`)
                setId(item.id);
              }, title: `${i18n.t(`general.delete`)} ${i18n.t(`delivery_fee.cep_delivery`)}`},
            ]}  
            color='primary'
          />
        </div>
      </div>
      <ModalWarning
        confirmAction={() => importFile()}
        warningMessage={i18n.t(`delivery_fee.continue_import`)}
      />
      <ModalWrapper confirmAction={() => showWarningModal(`${i18n.t(`general.attention`)}!`)}>
        <div className='flex-grow-1'>
          <p>{i18n.t(`general.attention`)}</p>
          <p>
            {i18n.t(`delivery_fee.import_file`)}
          </p>
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '50em'
              }}
            >
              {i18n.t(`delivery_fee.import_cep`)}
            </div>
            <div style={{marginLeft: '1em'}}>
              <Button
                text={i18n.t(`delivery_fee.model`)}
                color='primary'
                iconColor='#FFF'
                size='small'
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  window.open('/file/modelo-cep-range07-10-2022.xlsx', '')
                }}
                btnClass='fw-bolder me-5'
              />
            </div>
          </div>
          <br />
          <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
            <div
              style={{
                maxWidth: '50em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {i18n.t(`delivery_fee.import_cep_negative`)}
            </div>
            <div style={{marginLeft: '1em'}}>
              <Button
                text={i18n.t(`delivery_fee.model`)}
                color='primary'
                iconColor='#FFF'
                size='small'
                btnAttr={{
                  type: 'button',
                }}
                click={() => {
                  window.open('/file/modelo-cep-range-negativo07-10-2022.xlsx', '')
                }}
                btnClass='fw-bolder me-5'
              />
            </div>
          </div>
        </div>
        <br />
        <div className='flex-grow-1 text-center'>
          <Input
            inputAttr={{
              type: 'file',
            }}
            acceptFile={'.xlsx,.xls'}
            change={(value: BaseSyntheticEvent) =>
              setFileToImport(value ? value.target.files[0] : null)
            }
          />
        </div>
      </ModalWrapper>
      <ModalDelete confirmAction={() => deleteCep()} /> 
    </div>}
    </>
  )
}

const Cep: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`delivery_fee.delivery_fee`), path: 'configuracoes/taxa-entrega', isActive: true},
          {title: i18n.t(`delivery_fee.cep_delivery`), path: 'configuracoes/taxa-entrega/cep', isActive: true},
        ]}
      >
        {i18n.t(`delivery_fee.cep_delivery`)}
      </PageTitle>
      <CepContent />
    </>
  )
}

export {Cep}
