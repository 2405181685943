import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import {ModalWrapper} from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../../components/Input/Input'
import useModals from '../../../../hooks/useModals'
import {DELETE, GET, POST, PUT} from '../../../../services/api'
import { Loading } from '../../../../components/Loading/Loading'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ModalityResponseType = {
  id: number;
  name: string;
  type: string;
  status: boolean;
  description: string | null;
  code: string;
}

type ModalityType = {
  id: number;
  code: string;
  name: string;
  status: string;
}

const ModalidadesContent: FC = () => {
  const {showWrapperModal, showDeleteModal} = useModals()

  const [modalities, setModalities] = useState<any[]>([])
  const [id, setId] = useState<number | null>(null);
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<string>('delivery')
  const [status, setStatus] = useState<'ativo' | 'inativo'>('ativo')
  const [action, setAction] = useState<'creating' | 'editing'>('creating');
  const [loading, setLoading] = useState(false);

  const getModalities = async () => {
    try {
      setLoading(true);
      const modalitiesRes = await GET('/location')
      const filterModalities = modalitiesRes.data.filter((modality:ModalityResponseType) => modality.type !== 'address');
      const newModalities = filterModalities.map((modality:ModalityResponseType) => {
        return {
          id: modality.id,
          code: modality.code,
          name: modality.name,
          status: modality.status
        }
      })
      setModalities(newModalities);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const createModality = async () => {
    try{
      if(!name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const locationRes = await POST('/location', {
          name,
          type: 'internal',
          code: type,
          status: status === 'ativo' ? true : false
        });
        if(locationRes.success === true){
          Swal.fire({
            icon: 'success',
            title: 'Modalidade cadastrada',
            text: 'Cadastro realizado com sucesso!'
          }).then(async () => await getModalities())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar modalidade!'
          }).then(async () => await getModalities())
        }
        setLoading(false);
      }
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar modalidade!'
      }).then(async () => await getModalities())
      console.log(e)
    }
  }

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchClassification = await PUT(`/location/${Id}`, {
        status: !status,
      })
      if(switchClassification.success){
        const newModalities = modalities.map((modality)=> {
          if(modality.id === id){
            modality.status = !status;
          }
          return modality;
        })
        setModalities(newModalities)
      }
    } catch (e) {
      console.log(e)
    }
  }
  
  const editModality = async () => {
    try{
      if(!name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const locationRes = await PUT(`/location/${id}`, {
          name,
          code: type,
          status: status === 'ativo' ? true : false
        });
        if(locationRes.success === true){
          Swal.fire({
            icon: 'success',
            title: 'Modalidade alterada',
            text: 'Edição realizada com sucesso!'
          }).then(async () => await getModalities())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar modalidade!'
          }).then(async () => await getModalities())
        }
        setLoading(false);
      }
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar modalidade!'
      }).then(async () => await getModalities())
      console.log(e)
    }
  }

  const deleteModality = async () => {
    try{
      setLoading(true);
      const locationRes = await DELETE(`/location/${id}`);
      if(locationRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'Modalidade deletada',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getModalities())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar modalidade!'
        }).then(async () => await getModalities())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar modalidade!'
      }).then(async () => await getModalities())
      console.log(e)
    }
  }

  useEffect(() => {
    getModalities()
  }, [])

  return (
    <>
    { loading ? <Loading /> :
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`modalities.modalities`)}</span>
            <BreadCrumbs />
            <span className='text-muted mt-1 fw-bold fs-7'>
              {i18n.t(`modalities.here_are_listed_the_modalities_along_with_their_respective_actions`)}
            </span>
          </h3>
          <div className='card-toolbar'>
            <Button
              text={i18n.t(`buttons.add_modality`)}
              color='primary'
              iconColor='#FFF'
              icon='fas fa-plus'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                showWrapperModal(i18n.t(`buttons.add_modality`))
                setName('');
                setId(null);
                setAction('creating')
              }}
              btnClass='mb-5 fw-bolder'
            />
          </div>
        </div>
        <div className='card-body'>
          <Datatable
            data={modalities}
            headers={[
              {key: 'Id', value: 'id', type: 'default'},
              {key: i18n.t(`modalities.code`), value: 'code', type: 'default'},
              {key: i18n.t(`modalities.name`), value: 'name', type: 'default'},
              {
                key: i18n.t(`modalities.active`),
                value: 'status',
                type: 'check',
                action: (item) => switchStatus(item.id, item.status),
              },
              {key: i18n.t(`modalities.actions`), value: 'acoes', type: ''},
            ]}
            options={[
              {
                icon: 'fas fa-pen',
                action: (item) => {
                  showWrapperModal(`${i18n.t(`general.edit`)} ${i18n.t(`modalities.modalities`)}`)
                  setAction('editing')
                  setId(item.id);
                  setName(item.name)
                  setType(item.code)
                  setStatus(item.status)
                },
                title: `${i18n.t(`general.edit`)} ${i18n.t(`modalities.modalities`)}`,
              },
              {icon: 'fas fa-trash', action: (item) => {
                showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`modalities.modalities`)}`)
                setId(item.id);
              }, title: `${i18n.t(`general.delete`)} ${i18n.t(`modalities.modalities`)}`},
            ]}
            color='primary'
          />
        </div>
      </div>

      <ModalWrapper confirmAction={action === 'creating' ? () => createModality() : () => editModality()}>
        <div className='d-flex justify-content-between align-items-center flex-wrap gap-5'>
          <div className='flex-grow-1'>
            <Input
              inputAttr={{
                type: 'select',
              }}
              label={i18n.t(`modalities.type`)}
              value={type}
              change={setType}
              options={[
                {select: i18n.t(`modalities.delivery`), value: 'delivery'},
                {select: i18n.t(`modalities.withdraw`), value: 'withdraw'},
                {select: i18n.t(`modalities.table`), value: 'mesa'},
                {select: i18n.t(`modalities.room`), value: 'quarto'},
                {select: i18n.t(`modalities.counter`), value: 'counter'},
                {select: i18n.t(`modalities.internal`), value: 'internal-delivery'},
              ]}
            />
          </div>
          <div className='flex-grow-1'>
            <Input
              inputAttr={{
                type: 'default',
              }}
              value={name}
              change={setName}
              placeholder={i18n.t(`modalities.sector_name`)}
              label={i18n.t(`modalities.sector_name`)}
            />
          </div>
          <div className='flex-grow-1'>
            <Input
              inputAttr={{
                type: 'select',
              }}
              value={status}
              change={setStatus}
              label='Status'
              options={[
                {select: i18n.t(`modalities.active`), value: 'ativo'},
                {select: i18n.t(`modalities.inactive`), value: 'inativo'},
              ]}
            />
          </div>
        </div>
      </ModalWrapper>

      <ModalDelete confirmAction={() => deleteModality()} /> 
    </div>}
    </>
  )
}

const Modalidades: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`modalities.modalities`), path: 'configuracoes/modalidades', isActive: true},
        ]}
      >
        {i18n.t(`modalities.modalities`)}
      </PageTitle>
      <ModalidadesContent />
    </>
  )
}

export {Modalidades}
