import { FC, useEffect, useState } from 'react'
import { GET } from '../../../services/api'
import Swal from 'sweetalert2'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../components/Button/Button'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'
import { i18n } from '../../../translate/i18n'





type Card = {
  id: number,
  name: string,
  type: string,
  storeId: number,
}


const LayoutSDS: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Card[]>([])
  const getCards = async () => {
    try {
      setLoading(true)
      const response = await GET('sds_integration/get_all_layouts')
      setData(response.data)
      setLoading(false)
    } catch (e) {

      setLoading(false)

      if(e)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro carregar Layouts!',
        })
    }
  }

  useEffect(() => {
    getCards()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`permissions.sds_layout`)}</span>
                <BreadCrumbs />
              </h3>
              <div className='card-toolbar'>
                <Button
                  text='Adicionar Personalização'
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('create')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={data}
                headers={[
                  { key: 'Nome', value: 'name', type: 'default' },
                ]}
                options={[]}
                color='primary'
              />
            </div>
          </div>

        </div>
      )}
    </>
  )
}

export { LayoutSDS }