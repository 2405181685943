import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import GlobalModalsProvider from './context/globalModalsContext'
import {AuthInit} from './modules/auth'

const App = () => {
  useEffect(() => {
    cleanLocalValues()
  }, [])

  const cleanLocalValues = () => {
    const path = window.location.pathname
    if(!path.includes('product_fashion/criar') && !path.includes('product_varejo/criar')) localStorage.removeItem('product-saved')
    if(!path.includes('product_fashion/editar') && !path.includes('product_varejo/editar')) localStorage.removeItem('product-edited-saved')
    if(!path.includes('product_food/criar')) localStorage.removeItem('product-food-saved')
    if(!path.includes('product_food/editar')) localStorage.removeItem('product-food-edited-saved')
    if(!path.includes('variation/criar')) localStorage.removeItem('variation-saved')
    if(!path.includes('variation/editar')) localStorage.removeItem('variation-edited-saved')
    if(!path.includes('variation/criar')) localStorage.removeItem('variation-saved')
    if(!path.includes('variation/editar')) localStorage.removeItem('variation-edited-saved')
    if(!path.includes('planos-pagamento/criar')) localStorage.removeItem('plans-saved')
    if(!path.includes('planos-pagamento/editar')) localStorage.removeItem('plans-edited-saved')
    if(!path.includes('webapp/editar')) localStorage.removeItem('webapp-personalize-saved')
    if(!path.includes('webapp/configuracoes')) localStorage.removeItem('webapp-config-saved')
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <GlobalModalsProvider>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </GlobalModalsProvider>
    </Suspense>
  )
}

export {App}
