import { FC, useEffect, useState } from 'react'
import { Loading } from '../../../../components/Loading/Loading'
import { useParams } from 'react-router-dom'
import { PUT } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { Button } from '../../../../components/Button/Button'
import { isEmail } from '../../../../utils/validate'
import styles from '../components/Login.module.css'


type userChangePassword = {
    pluginspaceId: number;
    type: any;
    permissionGroupId: number;
    id: number;
    email?: string
    password?: string
}

const ResetPassword: FC = () => {

    const [newPassword, setNewPassword] = useState("")
    const [email, setEmail] = useState("")
    const [newPasswordCompare, setnewPasswordCompare] = useState("")
    const [loading, setLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const { info } = useParams()
    const [userChangePassword, setUserChangePassword] = useState<userChangePassword>({
        pluginspaceId: 0,
        type: '',
        permissionGroupId: 0,
        id: 0
    })
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)


    const [date, setDate] = useState("")
    const [isInvalid, setIsInvalid] = useState<boolean>(false)
    const [invalidText, setInvalidText] = useState<string>('')


    const handleChangePassword = async () => {
        const emailValidate = isEmail(email)

        const equalPassword = (newPasswordCompare === newPassword) && (newPassword !== "") && (newPasswordCompare !== "")

        const validateTime = new Date().toISOString() < date


        if (emailValidate && equalPassword && validateTime) {
            const obj: any = { ...userChangePassword }
            obj['email'] = email
            obj['password'] = newPassword

            delete obj.id
            delete obj.date

            try {
                setLoading(true)
                const response = await PUT(`users/changePassword/${userChangePassword.id}`, obj)

                if (response.success) {
                    setHasErrors(false)

                } else {
                    setHasErrors(true)
                }

                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }

        } else {
            let text = ''
            if (!equalPassword) text = 'As senhas devem ser iguais!'
            else if (!emailValidate) text = 'Por favor, digite um email válido!'
            else if (!validateTime) text = 'Tempo Expirado, favor pedir novamente para resetar a senha!'
            setInvalidText(text)
            setIsInvalid(true)
        }

    }


    const handleInfo = () => {
        const auxInfo = window.atob(info!)

        setUserChangePassword(JSON.parse(auxInfo))

        const aux = JSON.parse(auxInfo)

        setDate(aux.date)

    }


    useEffect(() => {
        handleInfo()
    }, [])

    return (
        <div className='d-flex flex-column flex-md-row h-100'>

            <div
                className='w-md-50 d-flex align-items-center justify-content-center'
                style={{
                    backgroundColor: '#1e1e2d',
                }}
            >
                {/*
                
                <img
                    alt='Logo'
                    src={'/ninegrid/login/login_bg.png'}
                    className='w-100 w-md-75 p-10 p-md-0'
                />
                */

                }
            </div>

            <div className='w-md-50 d-flex flex-md-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <div className='bg-white w-100 w-lg-75 p-md-10 p-lg-15 mx-auto'>
                    {isInvalid && (
                        <div className='mb-lg-15 alert alert-danger relative'>
                            <i
                                className={`bi bi-x-lg cursor-pointer text-danger ${styles.iconClose}`}
                                onClick={() => setIsInvalid(false)}
                            ></i>
                            <div className='alert-text font-weight-bold'>
                                <i className='bi bi-exclamation-circle fs-4 text-danger me-3'></i>
                                {invalidText}
                            </div>
                        </div>
                    )}

                    {hasErrors === true && (
                        <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>
                                Desculpe, alguns erros foram detectados. Por favor, tente novamente!
                            </div>
                        </div>
                    )}

                    {hasErrors === false && (
                        <div className='mb-10 bg-light-success p-8 rounded'>
                            <div className='text-success'>
                                Troca de senha realizada com sucesso!
                            </div>
                        </div>
                    )}

                    <div className='fv-row mb-10'>
                        <Input
                            inputAttr={{
                                type: 'email',
                            }}
                            label='E-mail'
                            placeholder='E-mail'
                            value={email}
                            change={setEmail}
                        />
                    </div>

                    <div className='fv-row mb-10'>
                        <Input
                            inputAttr={{
                                type: 'password',
                            }}
                            label='Senha'
                            placeholder='Senha'
                            value={newPassword}
                            change={setNewPassword}
                            autoComplete='new-password'
                        />

                        <Input
                            inputAttr={{
                                type: 'password',
                            }}
                            label='Senha Novamente'
                            placeholder='Senha Novamente'
                            value={newPasswordCompare}
                            change={setnewPasswordCompare}
                            autoComplete='new-password'
                        />
                        <br />
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='text-center text-md-end'>
                                <Button
                                    loading={loading}
                                    disabled={isSubmitting}
                                    color='primary'
                                    text='Trocar Senha'
                                    btnAttr={{
                                        type: 'button',
                                    }}
                                    click={() => handleChangePassword()}
                                    btnClass='w-auto'
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}


export { ResetPassword }