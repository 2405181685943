import {BaseSyntheticEvent, FC, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import uuid from 'react-uuid'
import Swal from 'sweetalert2'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Button} from '../../../../components/Button/Button'
import {Input} from '../../../../components/Input/Input'
import {Loading} from '../../../../components/Loading/Loading'
import {GET, POST, PUT} from '../../../../services/api'
import {i18n} from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const Content: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [randomCode, setRandomCode] = useState<string>('')

  const create = async () => {
    try {
      if (!name) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const res = await POST('/classification', {
          type: 'feed',
          name,
          shortname: name,
          order: 0,
          code: code === '' ? randomCode : code,
        })

        setLoading(false)
        if (res.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Categoria cadastrada',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/hospitality/content/category'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar a categoria!',
          })
        }
      }
    } catch (e: any) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ?? 'Erro ao cadastrar categoria!',
      })
    }
  }

  useEffect(() => {
    generateRandomNumber();
  }, [])

  const generateRandomNumber  = async () => {
    const min = 1;
    const max = 100;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    setRandomCode(randomNum.toString());
  }
  

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{`${i18n.t(
                  `buttons.create`
                )} ${i18n.t(`smart_order.category`)}`}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(
                    `general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`
                  )}
                </span>
              </h3>
            </div>
            <div className='card-body '>
              <div className='row'>
                <div className='col-lg-12'>
                  <div className='row'>
                    <div className='col-lg-8'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.name`)}
                        label={i18n.t(`smart_order.name`)}
                        value={name}
                        change={(value: string) => setName(value)}
                      />
                    </div>
                    <div className='col-lg-4'>
                      <Input
                        inputAttr={{
                          type: 'default',
                        }}
                        placeholder={i18n.t(`smart_order.code`)}
                        label={i18n.t(`smart_order.code`)}
                        value={code}
                        change={(value: string) => {
                          if (value.match('^[a-zA-Z0-9_]*$') != null) {
                            setCode(value)
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex w-100'>
              <div className='col-6 d-flex justify-content-start'>
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className='col-6  d-flex justify-content-end'>
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => create()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const FeedCriarCategorias: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`smart_order.categories`), path: 'category', isActive: true},
          {title: i18n.t(`buttons.create`), path: 'category/criar', isActive: true},
        ]}
      >
        {`${i18n.t(`buttons.create`)} ${i18n.t(`smart_order.categories`)}`}
      </PageTitle>
      <Content />
    </>
  )
}

export {FeedCriarCategorias}
