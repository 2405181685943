import { BaseSyntheticEvent, FC, useEffect, useState } from "react"
import { Input } from "../Input/Input"
import { Button } from "../Button/Button"
import { AggregatorLogo, Webappcolor, appConfig } from "../../pages/Agregador/webApp/types/webAppAgregador.types"
import uuid from "react-uuid"
import { POST } from "../../services/api"

type Props = {
  webappcolor: Webappcolor | undefined,
  aggregatorLogo: AggregatorLogo | undefined,
  config: appConfig | undefined,
  save: () => Promise<void>
  setConfig: (value: any) => any
  setLoading: (value: any) => any
  previousPage: () => any
}


const VisualAgregador: FC<Props> = ({ webappcolor, aggregatorLogo, save, setConfig, config, setLoading ,previousPage}) => {
  const [typeLogo, setTypeLogo] = useState(aggregatorLogo?.type ? aggregatorLogo?.type : 'white')
  const [borderRadius, setBorderRadius] = useState(webappcolor?.borderRadiusApp ? parseInt(webappcolor?.borderRadiusApp) : 0);
  //Cores
  const [backgroundHeaderButton, SetBackgroundHeaderButton] = useState(webappcolor?.backgroundHeaderButton ? webappcolor?.backgroundHeaderButton : '');
  const [iconColorHeader, SetIconColorHeader] = useState(webappcolor?.iconColorHeader ? webappcolor?.iconColorHeader : '');

  const [backgroundFilter, SetBackgroundFilter] = useState(webappcolor?.backgroundFilter ? webappcolor?.backgroundFilter : '');
  const [colorFilter, SetColorFilter] = useState(webappcolor?.colorFilter ? webappcolor?.colorFilter : '');
  const [iconColorFilter, SetIconColorFilter] = useState(webappcolor?.iconColorFilter ? webappcolor?.iconColorFilter : '');

  const [borderColorApp, SetBorderColorApp] = useState(webappcolor?.borderColorApp ? webappcolor?.borderColorApp : '');
  const [backgroundApp, SetBackgroundApp] = useState(webappcolor?.backgroundApp ? webappcolor?.backgroundApp : '');

  const [colorApp, SetColorApp] = useState(webappcolor?.colorApp ? webappcolor?.colorApp : '');

  const [iconColorApp, SetIconColorApp] = useState(webappcolor?.iconColorApp ? webappcolor?.iconColorApp : '');
  const [backgroundFooter, SetBackgroundFooter] = useState(webappcolor?.backgroundFooter ? webappcolor?.backgroundFooter : '');
  const [colorFoot, SetColorFoot] = useState(webappcolor?.colorFoot ? webappcolor?.colorFoot : '');
  const [iconColorFooter, SetIconColorFooter] = useState(webappcolor?.iconColorFooter ? webappcolor?.iconColorFooter : '');

  //logoMarca Branca
  const [whiteImage, setWhiteImage] = useState<File | null | string>()
  const [firstStateWhiteImage, setFirstStateWhiteImage] = useState<null | string>()
  //logoMarca Preta
  const [blackImage, setBlackImage] = useState<File | null | string>()
  const [firstStateBlackImage, setFirstStateBlackImage] = useState<null | string>()



  const deleteImage = async (image: string) => {
    try {
      await POST('/upload-images/delete-one', {
        url: image,
      })
    } catch (e) {
      return null
    }
  }


  async function saveImage(image1: any, imageBk: any) {
    try {
      if (image1 && image1 !== imageBk) {
        if (imageBk) {
          deleteImage(imageBk)
        }
        const formData = new FormData()
        formData.append('file', image1)
        formData.append('nameImage', `appconfig-logo-${uuid()}.jpg`)
        formData.append('originalSize', 'true')
        const imageRes = await POST('/upload-images/upload-one', formData)
        return imageRes.success ? imageRes.image : ''
      }
      return image1
    } catch (error) {
      return ''
    }
  }


  async function saveParcial() {
    try {
      if (webappcolor && config) {

        setLoading(true)

        if (aggregatorLogo) {
          aggregatorLogo.black = await saveImage(blackImage, firstStateBlackImage);
          aggregatorLogo.white = await saveImage(whiteImage, firstStateWhiteImage);
          aggregatorLogo.type = typeLogo
          config.content.aggregatorLogo = aggregatorLogo;
        } else {
          const newAggregatorLogo = {
            black: '',
            white: '',
            type: '',
          }
          newAggregatorLogo.black = await saveImage(blackImage, firstStateBlackImage);
          newAggregatorLogo.white = await saveImage(whiteImage, firstStateWhiteImage);
          newAggregatorLogo.type = typeLogo
          config.content.aggregatorLogo = newAggregatorLogo;
        }




        config.content.webappcolor = {
          backgroundHeaderButton: backgroundHeaderButton,
          iconColorHeader: iconColorHeader,
          backgroundFilter: backgroundFilter,
          colorFilter: colorFilter,
          iconColorFilter: iconColorFilter,
          backgroundApp: backgroundApp,
          iconColorApp: iconColorApp,
          borderColorApp: borderColorApp,
          borderRadiusApp: borderRadius.toString(),
          colorApp: colorApp,
          backgroundFooter: backgroundFooter,
          colorFoot: colorFoot,
          iconColorFooter: iconColorFooter
        }



        setConfig(config)

        await save();
      }

    } catch (error) {
      console.log(error)
    }
  }

  function saveImageWithoutSendS3(image1: any, imageBk: any) {
    if (image1 && image1 !== imageBk) {
      return image1
    }
    return imageBk
  }

  function previousPageBack(){
    try {
      if (webappcolor && config) {
        if (aggregatorLogo) {
          aggregatorLogo.black = saveImageWithoutSendS3(blackImage, firstStateBlackImage);
          aggregatorLogo.white = saveImageWithoutSendS3(whiteImage, firstStateWhiteImage);
          aggregatorLogo.type = typeLogo
          
          aggregatorLogo.blackFistState = firstStateBlackImage
          aggregatorLogo.whiteFistState = firstStateWhiteImage


          config.content.aggregatorLogo = aggregatorLogo;
        } else {
          const newAggregatorLogo:any = {
            black: '',
            white: '',
            type: '',
            whiteFistState: '',
            blackFistState: '',
          }
          newAggregatorLogo.black = saveImageWithoutSendS3(blackImage, firstStateBlackImage);
          newAggregatorLogo.white = saveImageWithoutSendS3(whiteImage, firstStateWhiteImage);
          newAggregatorLogo.blackFistState = firstStateBlackImage
          newAggregatorLogo.whiteFistState = firstStateWhiteImage
          newAggregatorLogo.type = typeLogo
          config.content.aggregatorLogo = newAggregatorLogo;
        }




        config.content.webappcolor = {
          backgroundHeaderButton: backgroundHeaderButton,
          iconColorHeader: iconColorHeader,
          backgroundFilter: backgroundFilter,
          colorFilter: colorFilter,
          iconColorFilter: iconColorFilter,
          backgroundApp: backgroundApp,
          iconColorApp: iconColorApp,
          borderColorApp: borderColorApp,
          borderRadiusApp: borderRadius.toString(),
          colorApp: colorApp,
          backgroundFooter: backgroundFooter,
          colorFoot: colorFoot,
          iconColorFooter: iconColorFooter
        }



        setConfig(config)

       previousPage()
      }

    } catch (error) {
      console.log(error)
    }
  }

  function openImages() {
    if (aggregatorLogo && aggregatorLogo.white && !aggregatorLogo.white.name ) {
      const urlImageWhite = aggregatorLogo.white.substring(0, 4) === 'http' ?
        aggregatorLogo.white : aggregatorLogo.white ? process.env.REACT_APP_AWS_S3_URL + aggregatorLogo.white : null

      setWhiteImage(urlImageWhite)
      setFirstStateWhiteImage(urlImageWhite)
    }else if(aggregatorLogo && aggregatorLogo.white && aggregatorLogo && aggregatorLogo.white.name){
      setWhiteImage(aggregatorLogo.white)
      setFirstStateWhiteImage(aggregatorLogo.white)
    }

    if (aggregatorLogo && aggregatorLogo.black && !aggregatorLogo.black.name) {
      const urlImageBlack = aggregatorLogo.black.substring(0, 4) === 'http' ?
        aggregatorLogo.black : aggregatorLogo.black ? process.env.REACT_APP_AWS_S3_URL + aggregatorLogo.black : null
      setBlackImage(urlImageBlack)
      setFirstStateBlackImage(urlImageBlack)
    }else if(aggregatorLogo && aggregatorLogo.black && aggregatorLogo.black.name){
      setBlackImage(aggregatorLogo.black)
      setFirstStateBlackImage(aggregatorLogo.black)
    }
  }

  useEffect(() => {
    openImages()
  }, [])


  return (
    <div className='card' style={{ marginTop: '-1rem' }}>
      <div className='card-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold mb-1' style={{
            fontSize: '14px',
            lineHeight: "20px",
            color: '#677484'
          }}>Visual do Agregador  · Cabeçalho</span>
          <span
            style={{
              fontSize: '11px',
              lineHeight: "20px",
              color: '#677484'
            }}
          >Aqui você configura o visual da tela principal do agregador. </span>
        </h3>
      </div>
      <div className='card-body row gap-6'>
        <div className="col border-end d-flex flex-column" style={{gap:'7px'}}>
          <div className="d-flex flex-column" style={{gap:'26px'}}>
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}>Logo marca do agregador</h4>
            <div className="d-flex flex-column justify-content-start" style={{ gap: '32px' }}>
              <div className="d-flex gap-3 mb-4">
                <div className="mt-6 ">
                  <Input
                    inputAttr={{
                      type: 'newRadio',
                    }}
                    value={'white'}
                    checked={typeLogo === 'white'}
                    change={(value: boolean) => {
                      if (value) {
                        setTypeLogo('white')

                      }

                    }}
                  />
                </div>
                <Input
                  inputAttr={{
                    type: 'image',
                  }}
                  width="100"
                  height="100"
                  label={'Versão Branco'}
                  value={whiteImage}
                  imagemBackgroundDefault={'150x50'}
                  change={(value: BaseSyntheticEvent) => {
                    setWhiteImage(value ? value.target.files[0] : null)
                  }}
                />
              </div>
              <div className="d-flex gap-3 mb-4">
                <div className="mt-6">
                  <Input
                    inputAttr={{
                      type: 'newRadio',
                    }}
                    value={'black'}
                    checked={typeLogo === 'black'}
                    change={(value: any) => {
                      if (value) {
                        setTypeLogo('black')
                      }
                    }}
                  />
                </div>
                <Input
                  inputAttr={{
                    type: 'image',
                  }}
                  width="100"
                  height="100"
                  imagemBackgroundDefault={'150x50'}
                  label={'Versão Preto'}
                  value={blackImage}
                  change={(value: BaseSyntheticEvent) => {
                    setBlackImage(value ? value.target.files[0] : null)
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="d-flex align-items-center w-100 justify-content-start col">


              <span className="form-check" style={{
                fontSize: '9px',
                width: '150px',
                lineHeight: '9px'
              }} >
                Logo deve estar na horizontal em formato png com fundo transparente 150x50 px.
              </span>
            </div>
            <div className="col-5">

            </div>
          </div>
        </div>

        <div className="col border-end d-flex flex-column" style={{ gap: '18px' }}>
          <div className="d-flex flex-column" style={{ gap: '11px' }}>
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Cor do cabeçalho</h4>
            <div className="d-flex flex-column" style={{ gap: '15px' }}>
              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Fundo do cabeçalho'}
                value={backgroundHeaderButton}
                change={(value: any) => {
                  SetBackgroundHeaderButton(value)
                }}
              />

              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Cor dos ícones do cabeçalho'}
                value={iconColorHeader}
                change={(value: any) => {
                  SetIconColorHeader(value)
                }}
              />
            </div>
          </div>
          <div className="d-flex flex-column" style={{ gap: '11px' }}>
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Campo de pesquisa</h4>
            <div className="d-flex flex-column" style={{ gap: '16px' }}>
              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Fundo do campo de pesquisa'}
                value={backgroundFilter}
                change={(value: any) => {
                  SetBackgroundFilter(value)
                }}
              />
              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Texto do campo de pesquisa'}
                value={colorFilter}
                change={(value: any) => {
                  SetColorFilter(value)
                }}
              />

              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Fundo do ícone de pesquisa'}
                value={iconColorFilter}
                change={(value: any) => {
                  SetIconColorFilter(value)
                }}
              />
            </div>
          </div>
        </div>


        <div className="col border-end d-flex flex-column  " style={{ gap: '22px' }}>
          <div className="d-flex flex-column" style={{ gap: '11px' }}>
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Cor e visual dos ícones</h4>
            <div className="d-flex flex-column" style={{ gap: '18px' }}>
              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Fundo do webapp '}
                value={backgroundApp}
                change={(value: any) => {
                  SetBackgroundApp(value)
                }}
              />

              <Input
                inputAttr={{
                  type: 'smallColor'
                }}
                label={'Fundo do ícone'}
                value={borderColorApp}
                change={(value: any) => {
                  SetBorderColorApp(value)
                }}
              />
            </div>
          </div>

          <div className="d-flex flex-column gap-4 align-items-start">
            <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }} >Borda do ícone</h4>
            <div className="d-flex gap-5 align-items-center h-100">
              <div className="form-control"

                style={{
                  height: '30px',
                  width: '30px',
                  border: 'solid',
                  borderWidth: '1px',
                  borderRadius: `${borderRadius / 2}%`
                }}>

              </div>

              <Input
                inputAttr={{
                  type: 'smallNumber'
                }}
                textCenter={true}
                label={''}
                height="30"
                width="70"
                minNumber={0}
                maxNumber={100}

                value={borderRadius}
                change={(value: any) => {
                  setBorderRadius(value)
                }}
              />
            </div>

          </div>

          <div className="d-flex flex-column" style={{ gap: '18px' }}>
            <Input
              inputAttr={{
                type: 'smallColor'
              }}
              label={'Cor dos ícones de subcategorias'}
              value={iconColorApp}
              change={(value: any) => {
                SetIconColorApp(value)
              }}
            />

            <Input
              inputAttr={{
                type: 'smallColor'
              }}
              label={'Cor dos textos dos ícones '}
              value={colorApp}
              change={(value: any) => {
                SetColorApp(value)
              }}
            />
          </div>
        </div>


        <div className="col" >
          <div className="d-flex flex-column h-100">
            <div className="d-flex flex-column h-100" style={{ gap: '13px' }}>
              <h4 style={{ fontSize: '12px', color: '#677484', lineHeight: '10px' }}> Cor do rodapé</h4>
              <div className="d-flex flex-column h-100" style={{ gap: '22px' }}>
                <Input
                  inputAttr={{
                    type: 'smallColor'
                  }}
                  label={'Fundo do rodapé'}
                  value={backgroundFooter}
                  change={(value: any) => {
                    SetBackgroundFooter(value)
                  }}
                />

                <Input
                  inputAttr={{
                    type: 'smallColor'
                  }}
                  label={'Cor dos ícones do rodapé'}
                  value={iconColorFooter}
                  change={(value: any) => {
                    SetIconColorFooter(value)
                  }}
                />
                <Input
                  inputAttr={{
                    type: 'smallColor'
                  }}
                  label={'Cor dos textos do rodapé'}
                  value={colorFoot}
                  change={(value: any) => {
                    SetColorFoot(value)
                  }}
                />
              </div>
            </div>
            <div className="d-flex gap-4 h-100 align-items-end w-100">
              <div className="d-flex w-100" style={{ gap: '20px' }}>
                <Button text={"voltar"} color={"info"} click={() => previousPageBack()} btnClass={'w-75'} />
                <Button text={"Salvar"} color={"primary"} click={() => saveParcial()} btnClass={'w-75'} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export { VisualAgregador }