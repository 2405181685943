import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Button } from '../../../components/Button/Button'
import { Input } from '../../../components/Input/Input'
import { Loading } from '../../../components/Loading/Loading'
import { ModalWrapper } from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import { WebAppInfoHotel, WebappInfo } from '../../../components/WebappInfo/WebappInfo'
import useModals from '../../../hooks/useModals'
import { DELETE, GET, POST, PUT } from '../../../services/api'
import { i18n } from '../../../translate/i18n'
import useAuth from '../../../hooks/useAuth'
import { getPlugintypeIdByCode } from '../../../functions/plugintype'
import { usingSynkar } from '../../../utils/utils'
import Swal from 'sweetalert2'
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { AppButton } from '../../../components/appButton'
import SVG from 'react-inlinesvg';
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'


export type WebappButtonType = {
  text: string
  color: string
  icon: string
  action: (value?: any) => any
}

type WebappType = {
  id: number
  title: string
  buttons: WebappButtonType[]
  webappLink: string
  type: string
}

type WebappResponseType = {
  id: number
  name: string
  type: string
  hash: string
  pluginSpace: {
    subdomain: string
  }
}

type QRCodeType = {
  type: string
  label: string
  iniInterval: number
  endInterval: number
  intervals: {
    iniInterval: number
    endInterval: number
  }[]
  url: string
  hash?: string
}

const initialQRCode: QRCodeType = {
  type: '',
  label: '',
  iniInterval: 1,
  endInterval: 1,
  intervals: [],
  url: '',
  hash: ''
}

type ModalityType = {
  id: number;
  code: string;
  name: string;
  status: boolean;
}

type Select = {
  label: string
  value: string
}

type ButtomSvgWebApp = {
  id: number;
  name: string;
  icon: string;
  svg?: any;
  auth: number;
  routeType: string;
  routeContent: string;
  status: boolean;
  background?: any;
  color?: any;
  order: number;
  appConfigId: number;
  pluginspaceId: number;
  idSvgwebApp: number;
  svgWebApp?: SvgWebApp;
  svgWebApp2?: string
}

type SvgWebApp = {
  id: number;
  svg: string;
  description: string;
  category: string;
}
type Icon = {
  id: number;
  svg: string;
  description: string;
  category: string;
}



const WebappContent: FC = () => {
  const navigate = useNavigate()
  const { showWrapperModal, closeWrapperModal, showDeleteModal } = useModals()

  const [loading, setLoading] = useState(false)
  const { loginInfo } = useAuth();

  const [webapps, setWebapps] = useState<WebappType[]>([])
  const [sectorOptions, setSectorOptions] = useState([
    { select: 'Selecione um setor', value: '' },
    { select: 'Comanda', value: 'card' },
    // // { select: 'Entrega', value: 'delivery' },
    { select: 'Mesa', value: 'table' },
    // // { select: 'Retirada', value: 'withdraw' },
  ])

  const [actions, setActions] = useState<'generateQrcode' | 'alert' | 'editButton' | 'createButton' | 'buttonIcone'>('generateQrcode')

  const [iniInterval, setIniInterval] = useState(0)
  const [endInterval, setEndInterval] = useState(0)
  const [qrCode, setQRCode] = useState<QRCodeType>(initialQRCode)
  // variavei usada no Hospitality para criar os botões.
  const [typeButton, setTypeButton] = useState<Select>()
  const [listClassification, setListClassification] = useState<any[]>([])
  const [categories, setCategories] = useState<any[]>([])
  const [categoryId, setCategoryId] = useState<Select>()
  const [buttonName, setButtonName] = useState<string>()
  const [externalLink, setExternalLink] = useState<string>()
  const [buttonsWebApp, setButtonsWebApp] = useState<ButtomSvgWebApp[]>([])

  const [buttonApp, setButtonApp] = useState<number>()

  const [icons, setIcons] = useState<Icon[]>([])
  const [iconSelected, setIconSelected] = useState<number>()
  const [id, setId] = useState<number | null>(null)

  const [initialQR, setInitialQR] = useState(1)
  const [finalQR, setFinalQR] = useState(1)



  const getApps = async () => {
    try {
      setLoading(true)
      const sectors = sectorOptions.map((s) => s);
      const haveSynkar = await usingSynkar()
      const modalities = await getModalities();
      const haveCounter = modalities.find((modality) => modality.code === 'counter' && modality.status === true)
      const haveInternalDelivery = modalities.find((modality) => modality.code === 'internal-delivery' && modality.status === true)
      if (haveSynkar)
        sectors.push({ select: 'Synkar', value: 'synkar' })

      if (haveCounter)
        sectors.push({ select: haveCounter.name, value: haveCounter.code })

      if (haveInternalDelivery)
        sectors.push({ select: haveInternalDelivery.name, value: haveInternalDelivery.code })

      setSectorOptions(sectors);

      if (loginInfo.plugintype === 'hospitality') {
        setSectorOptions([
          { select: 'Selecione um setor', value: '' },
          { select: 'Quarto', value: 'room' },
        ])
      }

      setLoading(true)
      const appRes = await GET('/appconfig')

      const filterWebapp = (getPlugintypeIdByCode(loginInfo.plugintype) === 21) ? ((webapp: WebappResponseType) => webapp.type === 'qr') : ((webapp: WebappResponseType) => webapp.type === 'qr' || webapp.type === 'seller')



      const newWebapps: WebappType[] = appRes.data
        .filter(filterWebapp)
        .map((webapp: WebappResponseType) => {
          let url = ''
          let hash = ''
          if (loginInfo.plugintype === 'hospitality') {
            url =
              'https://' +
              webapp.pluginSpace.subdomain +
              '.ninegrid.com.br'
            hash = webapp.hash
          } else if (loginInfo.plugintype === 'aggregator') {
            url =
              process.env.REACT_APP_AGGREGATORAPP_URL +
              '/' +
              window.btoa(webapp.pluginSpace.subdomain)
          } else if (process.env.REACT_APP_PROD === '1') {
            const host = window.location.hostname.split('.')[0]
            const prefix = process.env.REACT_APP_WEBAPP_URL?.split('//')[0]
            const domain = process.env.REACT_APP_WEBAPP_URL?.split('//')[1]
            // url = `${prefix}//${host}.${domain}/${window.btoa(webapp.pluginspace[0].subdomain)}${(webapp.type === 'seller' ? '/seller' : '')}`
            url =
              'https://webapp' +
              // webapp.pluginSpace.subdomain +
              '.ninegrid.com.br/' +
              window.btoa(webapp.pluginSpace.subdomain) +
              (webapp.type === 'seller' ? '/seller' : '')
          } else {
            url =
              process.env.REACT_APP_WEBAPP_URL +
              '/' +
              window.btoa(webapp.pluginSpace.subdomain) +
              (webapp.type === 'seller' ? '/seller' : '')
          }
          return {
            id: webapp.id,
            title: webapp.name,
            buttons: getPlugintypeIdByCode(loginInfo.plugintype) === 18 ?
              [
                {
                  text: i18n.t(`webapp.generate_qrcode`),
                  color: 'success',
                  icon: 'bi bi-qr-code fs-4',
                  action: () => showGenerateQRCode(url, '', hash),
                },
                {
                  text: i18n.t(`webapp.personalize`),
                  color: 'primary',
                  icon: 'bi bi-pencil-square fs-4',
                  action: () => navigate(`/webapp/editar/${webapp.id}`),
                },
                {
                  text: i18n.t(`webapp.settings`),
                  color: 'info',
                  icon: 'bi bi-gear fs-4',
                  action: () => navigate(`/webapp/configuracoes/${webapp.id}`),
                },
              ]
              : getPlugintypeIdByCode(loginInfo.plugintype) === 7 ? [
                {
                  text: i18n.t(`webapp.personalize`),
                  color: 'primary',
                  icon: 'bi bi-pencil-square fs-4',
                  action: () => navigate(`/webapp/editar/${webapp.id}`),
                },
                {
                  text: i18n.t(`webapp.generate_qrcode`),
                  color: 'success',
                  icon: 'bi bi-qr-code fs-4',
                  action: () => showGenerateQRCode(url, '', hash),
                }
              ] : getPlugintypeIdByCode(loginInfo.plugintype) === 21 ?
                [
                  {
                    text: i18n.t(`webapp.personalize`),
                    color: 'primary',
                    icon: 'bi bi-pencil-square fs-4',
                    action: () => navigate(`/webapp/editar/${webapp.id}`),
                  },
                ] :
                [
                  {
                    text: i18n.t(`webapp.personalize`),
                    color: 'primary',
                    icon: 'bi bi-pencil-square fs-4',
                    action: () => navigate(`/webapp/editar/${webapp.id}`),
                  },
                  {
                    text: i18n.t(`webapp.settings`),
                    color: 'info',
                    icon: 'bi bi-gear fs-4',
                    action: () => navigate(`/webapp/configuracoes/${webapp.id}`),
                  },
                ],
            webappLink: url,
            type: webapp.type
          }
        })
      setWebapps(newWebapps)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getApps()
  }, [])

  const showGenerateQRCode = (url: string, type: string, hash?: string) => {
    setQRCode({ ...initialQRCode, type, url, hash: hash ? hash : '' })
    setActions('generateQrcode')
    showWrapperModal('QR Code')
  }

  const addRange = () => {
    let _qrCode = { ...qrCode }
    _qrCode.intervals.push({ iniInterval: initialQR, endInterval: finalQR })
    setQRCode(_qrCode)
  }

  const removeRange = (i: number) => {
    let _qrCode = { ...qrCode }
    if (_qrCode.intervals[i]) {
      _qrCode.intervals.splice(i, 1)
      setQRCode(_qrCode)
    }
  }

  const generateQRCode = () => {
    if (!qrCode.type) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'É necessário selecionar um tipo de QRCode'
      })
    }
    const data = btoa(JSON.stringify(qrCode))
    
    window.open(`/webapp/generate-qr-code/${data}`)

    //fazer o modal informando que deve testar os qrcodes antes de imprimi
  }

  const verifyOptions = (type: string) => {
    if (type === 'table' || type === 'card') {
      return sectorOptions.filter(el => el.value === type)
    }
    return sectorOptions
  }

  const getModalities = async (): Promise<ModalityType[]> => {
    const modalitiesRes = await GET('/location')
    if (modalitiesRes && modalitiesRes.data) {
      return modalitiesRes.data
    } else {
      return []
    }
  }

  const generateClassificationTree = (
    categories: any[],
    parent: string | null,
    name: string
  ): any => {
    const parentCategory = categories.find((category) => category.code === parent)
    if (parentCategory)
      return `${generateClassificationTree(
        categories,
        parentCategory.parent,
        parentCategory.name
      )} > ${name}`
    else return name
  }

  const getCategoryContent = async () => {
    try {
      const res = await POST(`/classification/filter`, {
        plugintypeId: 10,
        status: true,
        deleted: 'not show',
      })
      res.data.map((row: any) => {
        listClassification.push({
          id: row.id,
          parent: row.parent,
          code: row.code,
          name: generateClassificationTree(res.data, row.parent, row.name),
        })
      })
      const categories = [
        {
          value: 0,
          select: 'Selecione',
          key: -1,
        },
      ]
      listClassification.map((row: any, idx) => {
        categories.push({
          value: row.id,
          select: row.name,
          key: idx,
        })
      })
      setCategories(categories)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const createButtomWebApp = async () => {
    try {
      //verificação das infos principais
      if (!buttonName || !typeButton) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Está faltando informações'
        })
        return null
      }
      if ((typeButton.value === 'content' && !categoryId?.value) || (typeButton.value === 'link' && !externalLink)) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção',
          text: 'Está faltando informações'
        })
        return null
      }
      setLoading(true)

      const routeType = typeButton.value === 'content' ? 'post' : typeButton.value === 'link' ? 'externallink' : 'link'


      const routerContent = typeButton.value === 'content' ? categoryId?.value : typeButton.value === 'link' ? externalLink : typeButton.value === 'menu' ? 'hospitality/rvc/' : 'hospitality/service/'

      const appId = webapps.filter(el => el.type === 'qr')[0]

      const button = {
        name: buttonName,
        routeContent: routerContent,
        routeType: routeType,
        appConfigId: appId.id,
        order: (buttonsWebApp.length + 1),
        status: true,
      }

      const resultCreateButton = await POST('appitems', button)
      if (!resultCreateButton || !resultCreateButton.success) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao cadastrar o botão'
        })
      } else {
        await getButtonsWebApp()
        setLoading(false)
        setButtonName('')
        setTypeButton({
          label: 'Selecione',
          value: ''
        })
        setCategoryId({
          label: 'Selecione',
          value: ''
        })
        setExternalLink('')
        closeWrapperModal()
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Sucesso ao cadastrar o botão'
        })
      }

    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao cadastrar o botão'
      })
      console.log(error)
    }
  }
  const getButtonsWebApp = async () => {
    try {
      const res = await POST(`/appitems/filter`)


      const buttonsSvgWebApp = res.data.sort((a: any, b: any) => a.order - b.order).map((bt: ButtomSvgWebApp) => {
        const schema = { ...bt }

        schema.svgWebApp2 = bt.svgWebApp?.svg ? bt.svgWebApp?.svg : ''

        return schema
      })

      setButtonsWebApp(buttonsSvgWebApp)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getIcons = async () => {
    try {
      const res = await POST(`/svgwebapp/filter`)

      setIcons(res.data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  useEffect(() => {
    getCategoryContent()
    getButtonsWebApp()
    getIcons()
  }, [])


  function changeInputType(value: any) {
    switch (value) {
      case 'content':
        return (<>
          <Input
            inputAttr={{
              type: 'select',
            }}
            label={'Tipo de conteúdo'}
            placeholder={'Selecione'}
            search
            options={categories}
            value={categoryId}
            change={(value: any) => {
              setCategoryId(value)
            }}
          />
        </>)
      case 'link':
        return (<>
          <Input
            inputAttr={{
              type: 'default',
            }}
            placeholder={i18n.t(`webapp.externalLink`)}
            label={i18n.t(`webapp.externalLink`)}
            value={externalLink}
            change={(value: string) => {
              setExternalLink(value)
            }}
          />
        </>)
      default:
        return (<></>)
    }
  }


  async function updateOrderButton(id: number, order: number) {
    try {
      setLoading(true)
      await PUT(`appitems/${id}`, {
        order: order
      })
    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao cadastrar o botão'
      })
      console.log(error)
    }
  }


  const onDragEnd = async (res: DropResult, list: any, setList: any) => {
    if (!res.destination) return

    setLoading(true)

    const { source, destination } = res;
    let copiedItems = [...list]

    const [removed] = copiedItems.splice(source.index, 1);



    copiedItems.splice(destination.index, 0, removed)

    const newCopiedItems = copiedItems.map((list, index) => {
      list.order = (index + 1);

      return list
    })

    setList(newCopiedItems)

    await Promise.all(newCopiedItems.map(async button => {
      await updateOrderButton(button.id, button.order)
    }))

    await getButtonsWebApp()

    setLoading(false)
  }

  function changeButtonIcon(button: ButtomSvgWebApp) {
    setButtonApp(button.id)
    setActions('buttonIcone')
    showWrapperModal(button.svgWebApp2 ? 'Editar Icone' : 'Adicionar Icone')
  }

  async function updateButtonIcon() {
    try {
      setLoading(true)
      const resultCreateButton = await PUT(`appitems/${buttonApp}`, {
        idSvgwebApp: iconSelected
      })
      if (!resultCreateButton || !resultCreateButton.success) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao mudar o ícone'
        })
      } else {
        await getButtonsWebApp()
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Sucesso ao mudar o ícone'
        })
      }

    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao mudar o ícone'
      })
      console.log(error)
    }
  }

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchButtom = await PUT(`/appitems/${Id}`, {
        status: !status ? 1 : 0,
      })
      if (switchButtom.success) {
        const newCatalogs = buttonsWebApp.map((catalog) => {
          if (catalog.id === Id) {
            catalog.status = !status;
          }
          return catalog;
        })
        setButtonsWebApp(newCatalogs)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }


  function changeButtonInfo(button: ButtomSvgWebApp) {
    setButtonApp(button.id)
    setButtonName(button.name);
    setTypeButton({
      label: 'routeType',
      value: button.routeType
    });

    if (button.routeType === 'externallink') {
      setExternalLink(button.routeContent)
    } else if (button.routeType === 'post') {
      const find = categories.find(el => el.value === button.routeContent)

      if (find) {
        setCategoryId({
          label: find.select,
          value: find.id
        })
      }

    }


    setActions('editButton')
    showWrapperModal('Editar Botão')
  }

  async function updateButtonName() {
    try {
      setLoading(true)

      const body: any = {
        name: buttonName
      }

      //const routerContent = typeButton.value === 'content' ? categoryId?.value : typeButton.value === 'link' ? externalLink 

      if (externalLink) {
        body.routeContent = externalLink
      }

      if (categoryId?.value) {
        body.routeContent = categoryId?.value
      }

      const resultCreateButton = await PUT(`appitems/${buttonApp}`, body)
      if (!resultCreateButton || !resultCreateButton.success) {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Um erro ocorreu ao editar'
        })
      } else {
        setButtonName('')
        setExternalLink('')
        setExternalLink('')
        setTypeButton({
          label: '',
          value: ''
        });
        setCategoryId({ label: '', value: "" })
        await getButtonsWebApp()
        setLoading(false)
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: 'Sucesso ao editar'
        })
      }

    } catch (error) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Um erro ocorreu ao editar'
      })
      console.log(error)
    }
  }

  function deleteItem(itemId: number) {
    showDeleteModal(`${i18n.t(`general.delete`)} Botão`)
    setId(itemId)
  }

  async function deleteButtom() {
    try {
      setLoading(true)
      const gridRes = await DELETE(`/appitems/${id}`)
      if (gridRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Botão deletado',
          text: 'Sucesso!',
        }).then(async () => await getButtonsWebApp())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o botão!',
        }).then(async () => await getButtonsWebApp())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o botão!',
      }).then(async () => await getButtonsWebApp())
      console.log(e)
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {loginInfo.plugintype === 'hospitality' ?
            (<>
              <div className='row g-5  gap-5 gx-xxl-12'>
                <div className='card card-xxl-stretch mb-5 mb-xl-12 col-8'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>Webapp</span>
                      <BreadCrumbs />
                    </h3>
                    <div>
                      <Button text={'Cadastrar Botão'}
                        size='small'
                        color={'primary'} click={() => {
                          setActions('createButton')
                          showWrapperModal('Criar Botão WebApp')
                        }
                        } />
                    </div>
                  </div>
                  <div className='card-body'>
                    <div className='d-flex gap-5 align-items-center'>
                      {['Pos', 'Ordem', 'Icone', 'Nome', 'Tipo', 'Status', 'Ação'].map(el => (
                        <div className='w-25 d-flex justify-content-center align-items-center '>
                          <span className=' mx-1 text-uppercase cursor-pointer text-hover-primary px-0 d-flex flex-row align-items-center'>{el}</span>
                        </div>
                      ))

                      }
                    </div>
                    <DragDropContext onDragEnd={result => onDragEnd(result, buttonsWebApp, setButtonsWebApp)}>
                      <Droppable droppableId="ROOT" type='group'>
                        {(provided, snapshot) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {buttonsWebApp.map((buttom: ButtomSvgWebApp, index: number) => (
                              <Draggable key={buttom.id} draggableId={buttom.id.toString()} index={index}>
                                {(provided) => (
                                  <div className='form-group mt-5' key={index}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className='d-flex gap-5 align-items-center'>
                                      <div className='w-25 d-flex justify-content-center align-items-center '>
                                        <button className='btn btn-dark' disabled>
                                          <i className="bi bi-arrow-down-up"></i>
                                        </button>
                                      </div>
                                      <AppButton value={buttom}
                                        action={() => changeButtonIcon(buttom)}
                                        edit={() => changeButtonInfo(buttom)}
                                        updateStatus={() => switchStatus(buttom.id, buttom.status)}
                                        deleteItem={() => deleteItem(buttom.id)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}

                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
                <div className='card card-xxl-stretch mb-5 mb-xl-12 col-3'>
                  <div className='card-body'>
                    {webapps.map((webapp, index) => (
                      <WebAppInfoHotel
                        key={`${webapp.title} - ${index}`}
                        title={webapp.title}
                        buttons={webapp.buttons}
                        webappLink={webapp.webappLink}
                        showQrCode={webapp.type !== 'table' && webapp.type !== 'card'}
                      />
                    ))}
                  </div>
                </div>
                <ModalWrapper confirmAction={() => actions === 'generateQrcode' ? generateQRCode() : actions === 'createButton' ? createButtomWebApp() : actions === 'buttonIcone' ? updateButtonIcon() : actions === 'editButton' ? updateButtonName() : ''}
                  textAction={actions === 'generateQrcode' ? 'Gerar QR Code(s)' : actions === 'createButton' ? 'Criar Botão' : actions === 'buttonIcone' ? 'Salvar' : actions === 'editButton' ? 'Editar Botão' : 'ok'}
                  maxWidth={true}
                  width={actions === 'buttonIcone' ? 60 : 40}
                  height={actions === 'buttonIcone' ? 50 : 30}
                  zIndex={1000}
                  shouldCloseModal={actions === 'createButton' ? false : true}
                >
                  <>
                    {actions == 'generateQrcode' ? (
                      <>
                        <div className='d-flex justify-content-between'>
                          <div className='w-100'>
                            <div>
                              <Input
                                inputAttr={{
                                  type: 'select',
                                }}
                                label='Setor'
                                options={sectorOptions}
                                value={qrCode.type}
                                change={(value: string) => {
                                  let _qrCode = { ...qrCode }
                                  _qrCode.type = value
                                  setQRCode(_qrCode)
                                }}
                              />
                              {(qrCode.type && qrCode.type !== 'synkar' && qrCode.type !== 'counter') && (
                                <Input
                                  inputAttr={{
                                    type: 'default',
                                  }}
                                  label='Label QR Code'
                                  value={qrCode.label}
                                  change={(value: string) => {
                                    let _qrCode = { ...qrCode }
                                    _qrCode.label = value
                                    setQRCode(_qrCode)
                                  }}
                                />
                              )}
                            </div>
                            {(qrCode.type !== 'synkar' && qrCode.type !== 'counter' && qrCode.type) &&
                              <>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='me-5 w-50'>
                                    <Input
                                      inputAttr={{
                                        type: 'number',
                                      }}
                                      label='Índice inicial'
                                      value={qrCode.iniInterval}
                                      change={(value: string) => {
                                        let _qrCode = { ...qrCode }
                                        _qrCode.iniInterval = value ? parseInt(value) : 1
                                        setInitialQR(_qrCode.iniInterval)
                                        if(_qrCode.iniInterval > finalQR){
                                          setFinalQR(_qrCode.iniInterval)
                                        }
                                        setQRCode(_qrCode)  
                                      }}
                                    />
                                  </div>
                                  <div className='me-5 w-50'>
                                    <Input
                                      inputAttr={{
                                        type: 'number',
                                      }}
                                      label='Índice final'
                                      value={qrCode.endInterval}
                                      minNumber={qrCode.iniInterval}
                                      change={(value: string) => {
                                        let _qrCode = { ...qrCode }
                                        _qrCode.endInterval = value ? parseInt(value) : 1
                                        if(_qrCode.endInterval >= qrCode.iniInterval){
                                          setFinalQR(_qrCode.endInterval)
                                          setQRCode(_qrCode)
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className='me-5 w-50'>
                                    <div className='mt-6'>
                                      <label className='form-label fw-normal'></label>
                                      <div className='d-flex align-items-center'>
                                        <Button
                                          text='Adicionar Intervalo'
                                          color='light-primary'
                                          click={(event) => {
                                            addRange()
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {qrCode.intervals.map((interval, i) => (
                                  <div
                                    key={`range-${i}`}
                                    className='d-flex justify-content-between align-items-center'
                                  >
                                    <div className='me-5 w-100'>
                                      <Input
                                        inputAttr={{
                                          type: 'number',
                                        }}
                                        label='Índice inicial'
                                        value={interval.iniInterval}
                                        change={(value: string) => {
                                          let _qrCode = { ...qrCode }
                                          if (_qrCode.intervals[i]) {
                                            _qrCode.intervals[i].iniInterval = value ? parseInt(value) : 1
                                            if(_qrCode.intervals[i].iniInterval > _qrCode.intervals[i].endInterval){
                                              _qrCode.intervals[i].endInterval = value ? parseInt(value) : 1
                                            }
                                            setQRCode(_qrCode)
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className='me-5 w-100'>
                                      <Input
                                        inputAttr={{
                                          type: 'number',
                                        }}
                                        label='Índice final'
                                        value={interval.endInterval}
                                        minNumber={qrCode.iniInterval}
                                        change={(value: string) => {
                                          let _qrCode = { ...qrCode }
                                          if (_qrCode.intervals[i]) {
                                            _qrCode.intervals[i].endInterval = value ? parseInt(value) : 1
                                            if(_qrCode.intervals[i].endInterval >= _qrCode.intervals[i].iniInterval){
                                              setQRCode(_qrCode)
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className='me-5 py-5'>
                                      <div className='mt-6'>
                                        <label className='form-label fw-normal'></label>
                                        <div className='d-flex align-items-center'>
                                          <Button
                                            icon='fas fa-trash'
                                            color='danger'
                                            size='small'
                                            click={() => removeRange(i)}
                                            text={''}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {actions === 'buttonIcone' ?
                          (<div className='d-flex flex-wrap gap-4 justify-content-center mt-2 overflow-auto' style={{
                            height: '35rem'
                          }}>
                            {icons.map((icon: Icon) => (
                              <div className={`d-flex flex-column p-4 border border border-2 justify-content-center align-items-center  ${icon.id === iconSelected ? 'border-success' : 'border-dark'}`}
                                onClick={() => {
                                  setIconSelected(icon.id)
                                }}
                              >
                                <SVG src={icon.svg} style={{
                                  width: '45px',
                                  height: '45px'
                                }} />
                              </div>
                            ))

                            }
                          </div>) : (
                            <>
                              {actions === 'editButton' ?
                                (
                                  <>
                                    <div className='d-flex flex-column'>
                                      <div className='d-flex gap-5'>
                                        <div className='w-100'>
                                          <Input
                                            inputAttr={{
                                              type: 'default',
                                            }}
                                            placeholder={i18n.t(`webapp.name`)}
                                            label={i18n.t(`webapp.name`)}
                                            value={buttonName}
                                            change={(value: string) => {
                                              setButtonName(value)
                                            }}
                                          />
                                        </div>
                                        <div className='w-50'>
                                          {typeButton?.value === 'externallink' && (
                                            <Input
                                              inputAttr={{
                                                type: 'default',
                                              }}
                                              placeholder={i18n.t(`webapp.externalLink`)}
                                              label={i18n.t(`webapp.externalLink`)}
                                              value={externalLink}
                                              change={(value: string) => {
                                                setExternalLink(value)
                                              }}
                                            />

                                          )
                                          }
                                          {typeButton?.value === 'post' &&
                                            (<Input
                                              inputAttr={{
                                                type: 'select',
                                              }}
                                              label={'Tipo de conteúdo'}
                                              placeholder={'Selecione'}
                                              search
                                              options={categories}
                                              value={categoryId}
                                              change={(value: any) => {
                                                setCategoryId(value)
                                              }}
                                            />)
                                          }
                                        </div>
                                      </div>
                                    </div>

                                  </>
                                ) : (
                                  <>

                                    <div className='d-flex flex-column'>
                                      <div className='d-flex gap-5'>
                                        <div className='w-50'>
                                          <Input
                                            inputAttr={{
                                              type: 'default',
                                            }}
                                            placeholder={i18n.t(`webapp.name`)}
                                            label={i18n.t(`webapp.name`)}
                                            value={buttonName}
                                            change={(value: string) => {
                                              setButtonName(value)
                                            }}
                                          />
                                        </div>
                                        <div className='w-50'>
                                          <Input
                                            inputAttr={{
                                              type: 'select',
                                            }}
                                            label={i18n.t(`webapp.buttonType`)}
                                            placeholder={'Selecione'}
                                            search
                                            options={[
                                              {
                                                select: 'Conteúdo',
                                                value: 'content'
                                              },
                                              {
                                                select: 'Serviço',
                                                value: 'service'
                                              },
                                              {
                                                select: 'Cardápio',
                                                value: 'menu'
                                              },
                                              {
                                                select: 'Link externo',
                                                value: 'link'
                                              },
                                            ]}
                                            value={typeButton}
                                            change={(value: any) => {
                                              setTypeButton(value)
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {
                                        changeInputType(typeButton?.value)
                                      }
                                    </div>

                                  </>
                                )
                              }
                            </>
                          )
                        }
                      </>
                    )


                    }
                  </>
                </ModalWrapper>
                <ModalDelete confirmAction={() => deleteButtom()} />
              </div>
            </>) : (<>
              <div className='row g-5 gx-xxl-12'>
                <div className='card card-xxl-stretch mb-5 mb-xl-12'>
                  <div className='card-header py-5'>
                    <h3 className='card-title align-items-start flex-column'>
                      <span className='card-label fw-bolder fs-3 mb-1'>Webapp</span>
                      <BreadCrumbs />
                    </h3>
                  </div>
                  <div className='card-body'>
                    {webapps.map((webapp, index) => (
                      <WebappInfo
                        key={`${webapp.title} - ${index}`}
                        title={webapp.title}
                        buttons={webapp.buttons}
                        webappLink={webapp.webappLink}
                        showQrCode={webapp.type !== 'table' && webapp.type !== 'card'}
                      />
                    ))}
                  </div>
                </div>
                <ModalWrapper 
                  confirmAction={() => actions === 'generateQrcode' ? generateQRCode() : ''} 
                  textAction={actions === 'generateQrcode' ? 'Gerar QR Code(s)' : 'Ok'}
                  noAction={
                    (
                      qrCode.label === '' && 
                      (qrCode.type === 'table' || qrCode.type === 'card' || qrCode.type === 'internal-delivery')
                    )
                  }
                >
                  <div className='d-flex justify-content-between'>
                    <div className='w-100'>
                      <div>
                        <Input
                          inputAttr={{
                            type: 'select',
                          }}
                          label='Setor'
                          options={sectorOptions}
                          value={qrCode.type}
                          change={(value: string) => {
                            let _qrCode = { ...qrCode }
                            _qrCode.type = value
                            if(value === 'card') _qrCode.label = 'COMANDA'
                            else if(value === 'table') _qrCode.label = 'MESA'
                            else _qrCode.label = ''
                            setQRCode(_qrCode)
                          }}
                        />
                        {(qrCode.type && qrCode.type !== 'synkar' && qrCode.type !== 'counter') && (
                          <Input
                            inputAttr={{
                              type: 'default',
                            }}
                            label='Label QR Code'
                            value={qrCode.label}
                            change={(value: string) => {
                              let _qrCode = { ...qrCode }
                              _qrCode.label = value
                              setQRCode(_qrCode)
                            }}
                            alarm={!qrCode.label}
                          />
                        )}
                      </div>
                      {(qrCode.type !== 'synkar' && qrCode.type !== 'counter' && qrCode.type) &&
                        <>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div className='me-5 w-50'>
                              <Input
                                inputAttr={{
                                  type: 'number',
                                }}
                                label='Índice inicial'
                                value={qrCode.iniInterval}
                                change={(value: string) => {
                                  let _qrCode = { ...qrCode }
                                  _qrCode.iniInterval = value ? parseInt(value) : 1
                                  if(_qrCode.iniInterval > _qrCode.endInterval){
                                    _qrCode.endInterval = value ? parseInt(value) : 1
                                  }
                                  setQRCode(_qrCode)
                                }}
                              />
                            </div>
                            <div className='me-5 w-50'>
                              <Input
                                inputAttr={{
                                  type: 'number',
                                }}
                                label='Índice final'
                                value={qrCode.endInterval}
                                minNumber={qrCode.iniInterval}
                                change={(value: string) => {
                                  let _qrCode = { ...qrCode }
                                  _qrCode.endInterval = value ? parseInt(value) : 1
                                  if(_qrCode.endInterval >= qrCode.iniInterval){
                                    setQRCode(_qrCode)
                                  }
                                }}
                              />
                            </div>
                            <div className='me-5 w-50'>
                              <div className='mt-6'>
                                <label className='form-label fw-normal'></label>
                                <div className='d-flex align-items-center'>
                                  <Button
                                    text='Adicionar Intervalo'
                                    color='light-primary'
                                    click={(event) => {
                                      addRange()
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {qrCode.intervals.map((interval, i) => (
                            <div
                              key={`range-${i}`}
                              className='d-flex justify-content-between align-items-center'
                            >
                              <div className='me-5 w-100'>
                                <Input
                                  inputAttr={{
                                    type: 'number',
                                  }}
                                  label='Índice inicial'
                                  value={interval.iniInterval}
                                  change={(value: string) => {
                                    let _qrCode = { ...qrCode }
                                    if (_qrCode.intervals[i]) {
                                      _qrCode.intervals[i].iniInterval = value ? parseInt(value) : 1
                                      if(_qrCode.intervals[i].iniInterval > _qrCode.intervals[i].endInterval){
                                        _qrCode.intervals[i].endInterval = value ? parseInt(value) : 1
                                      }
                                      setQRCode(_qrCode)
                                      
                                    }
                                  }}
                                />
                              </div>
                              <div className='me-5 w-100'>
                                <Input
                                  inputAttr={{
                                    type: 'number',
                                  }}
                                  label='Índice final'
                                  value={interval.endInterval}
                                  minNumber={qrCode.iniInterval}
                                  change={(value: string) => {
                                    let _qrCode = { ...qrCode }
                                    if (_qrCode.intervals[i]) {
                                      _qrCode.intervals[i].endInterval = value ? parseInt(value) : 0
                                      if(_qrCode.intervals[i].endInterval >= _qrCode.intervals[i].iniInterval){
                                        setQRCode(_qrCode)
                                      }
                                    }
                                  }}
                                />
                              </div>
                              <div className='me-5 py-5'>
                                <div className='mt-6'>
                                  <label className='form-label fw-normal'></label>
                                  <div className='d-flex align-items-center'>
                                    <Button
                                      icon='fas fa-trash'
                                      color='danger'
                                      size='small'
                                      click={() => removeRange(i)}
                                      text={''}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>}
                    </div>
                  </div>
                </ModalWrapper>
              </div>
            </>)

          }
        </>
      )
      }
    </>
  )
}

const Webapp: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[{ title: 'Webapp', path: 'webapp', isActive: true }]}>
        Webapp
      </PageTitle>
      <WebappContent />
    </>
  )
}

export { Webapp }
