/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Datatable } from '../../../../components/Datatable/Datatable'
import type { MetaType } from '../../../../components/Datatable/Datatable'
import { Button } from '../../../../components/Button/Button'
import { Loading } from '../../../../components/Loading/Loading'
import useModals from '../../../../hooks/useModals'
import { useNavigate } from 'react-router-dom'
import { DELETE, GET, POST } from '../../../../services/api'
import Swal from 'sweetalert2'
import { ModalDelete } from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import { getPlugintypeIdByCode } from '../../../../functions/plugintype'
import useAuth from '../../../../hooks/useAuth'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'
import { ModalWrapper } from '../../../../components/Modals/General/ModalWrapper/ModalWrapper'
import JsonFormatter from 'react-json-formatter'
import CopyToClipboard from 'react-copy-to-clipboard'

import styles from './index.module.css'

type IntegrationType = {
  id: number;
  name: string;
  type: string;
  templateType: string;
  templateId: number;
  typeId: number;
}

type IntegrationResponseType = {
  id: number;
  name: string;
  plugintemplateId: {
    id: number;
    name: string;
    parameters: string;
    plugintypeId: number;
    typeIntegration: string;
  }
}


const PdvContent: FC = () => {

  const { showDeleteModal, showWrapperModal, closeWrapperModal } = useModals()
  const { loginInfo } = useAuth();

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [integrations, setIntegrations] = useState<Array<IntegrationType>>([]);
  const [selectedConfig, setSelectedConfig] = useState<IntegrationType | null>(null);
  const [templateTest, setTemplateTest] = useState<any>()
  const [templateResult, setTemplateResult] = useState<any>()
  const [extendPayload, setExtendPayload] = useState(false)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const jsonStyle = {
    propertyStyle: { color: 'grey' },
    stringStyle: { color: 'white' },
    numberStyle: { color: 'white' },
    booleanStyle: { color: 'white' },
    style: { color: 'white' },
  }

  const getIntegrations = async (take: number = 10, page: number = 1) => {
    try {
      setLoading(true);
      const integrationRes = await POST(`/pluginconfig/filter`, {})
      const newIntegrations: IntegrationType[] = integrationRes.data.map((pluginconfig: IntegrationResponseType) => {
        return {
          id: pluginconfig.id,
          name: pluginconfig.name,
          type: pluginconfig.plugintemplateId.name,
          typeId: pluginconfig.plugintemplateId.plugintypeId,
          templateType: pluginconfig.plugintemplateId.typeIntegration,
          templateId: pluginconfig.plugintemplateId.id
        }
      })
      let integrationsGet: IntegrationType[] = []
      const plugincode = getPlugintypeIdByCode(loginInfo.plugintype);
      newIntegrations.forEach((integration) => {
        if (integration.templateType === 'pdv' && (integration.typeId === plugincode || integration.typeId === 1)) {
          integrationsGet.push(integration)
        }
      })
      setIntegrations(integrationsGet);
      setMeta(integrationRes.meta)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const deleteIntegration = async () => {
    try {
      setLoading(true);
      const integrationRes = await DELETE(`/pluginconfig/${selectedConfig?.id}`);
      if (integrationRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Integração deletada',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getIntegrations())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar integração!'
        }).then(async () => await getIntegrations())
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar integração!'
      }).then(async () => await getIntegrations())
      console.log(e)
    }
  }
  
  const getPayloadTest = async (pluginconfig: IntegrationType) => {
    try {
      setExtendPayload(false);
      setTemplateTest(null);
      setTemplateResult(null);
      setLoading(true);
      const integrationRes = await POST(`/schemasFashion`, {
        pluginconfigId: pluginconfig.id,
        plugintemplateId: pluginconfig.templateId,
      });
      setLoading(false);
      if(integrationRes){
        setTemplateTest(integrationRes)
        showWrapperModal(`${i18n.t(`general.test`)} ${i18n.t(`integration.integration`)}`)
        setSelectedConfig(pluginconfig);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao recuperar payoad de teste!'
        })
      }
    } catch (e:any) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e :'Erro ao recuperar payoad de teste!'
      })
    }
  }

  const testIntegration = async() => {
    try {
      if(!selectedConfig || !selectedConfig.id || !templateTest){
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao encontrar configuração de integração!'
        })
        return
      }
      setLoading(true);
      const integrationRes = await POST(`/test-integration`, {
        pluginconfigId: selectedConfig.id,
        jsonTest: templateTest,
        plugintemplateId: selectedConfig.templateId
      });
      setLoading(false);
      setTemplateTest(null);
      showWrapperModal(i18n.t(`integration.result_test`))
      setExtendPayload(false);
      setTemplateResult(integrationRes)
    } catch (e:any) {
      setLoading(false);
      closeWrapperModal();
      setTemplateTest(null);
      setTemplateResult(null);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e :'Erro ao testar payload'
      })
    }
  }
  useEffect(() => {
    getIntegrations();
  }, [])

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`integration.PDV`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`integration.listed_here_are_the_integrations_along_with_their_respective_actions`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_integration`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/pluginconfig/pdv/create')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={integrations}
                headers={[
                  { key: i18n.t(`integration.integration_name`), value: 'name', type: 'default' },
                  { key: i18n.t(`integration.type_of_integration`), value: 'type', type: 'default' },
                  { key: i18n.t(`general.actions`), value: 'acoes', type: '' },
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      navigate(`/pluginconfig/pdv/edit/${item.id}`)
                    },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`integration.integration`)}`,
                  },
                  {
                    icon: 'fas fa-trash', action: (item) => {
                      showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`integration.integration`)}`)
                      setSelectedConfig(item);
                    }, title: `${i18n.t(`general.delete`)} ${i18n.t(`integration.integration`)}`
                  },
                  {
                    icon: 'fa fa-play', action: async (item) => {
                      await getPayloadTest(item)
                    }, 
                    title: `${i18n.t(`general.test`)} ${i18n.t(`integration.integration`)}`,
                    verifyConditionItem: 'templateId',
                    verifyConditionValue: 23, 
                  },
                ]}
                color='primary'
              // meta={meta}
              // onChangeData={async (take, page) => await getIntegrations(take, page)}
              />
            </div>
          </div>
          <ModalDelete confirmAction={() => deleteIntegration()} />
          <ModalWrapper noCloseOverlay={templateResult && !templateTest} noCloseButton noAction confirmAction={() => {}} >
            {(templateTest && JSON.stringify(templateTest) && selectedConfig) ? (
              <div className='w-100 d-flex flex-column'>
                <div className="w-100 d-flex flex-row justify-content-center">
                  <svg width="204" height="197" viewBox="0 0 204 197" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_3409_3720)">
                    <path d="M159.301 0.286077L9.67877 32.3076C3.18191 33.698 -0.957669 40.0919 0.432766 46.5888L30.4222 186.716C31.8126 193.212 38.2065 197.352 44.7034 195.962L194.325 163.94C200.822 162.55 204.962 156.156 203.571 149.659L173.582 9.53208C172.191 3.03522 165.797 -1.10436 159.301 0.286077Z" fill="#E8EDFC"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M165.34 146.75C167 147.19 168.48 149.15 168.65 151.13C168.82 153.11 167.62 154.36 165.96 153.92C164.3 153.48 162.82 151.52 162.65 149.54C162.48 147.56 163.68 146.31 165.34 146.75Z" fill="#BDD0FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M138.12 144.13C140.97 144.88 143.52 148.26 143.82 151.67C144.12 155.08 142.04 157.23 139.19 156.48C136.34 155.73 133.79 152.35 133.49 148.94C133.19 145.53 135.27 143.38 138.12 144.13Z" fill="#BDD0FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M166.67 173.59C167.77 173.88 168.75 175.18 168.86 176.49C168.97 177.8 168.18 178.62 167.08 178.34C165.99 178.05 165.01 176.75 164.89 175.44C164.78 174.13 165.57 173.3 166.67 173.59Z" fill="#BDD0FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M196.92 139.82C198.02 140.11 199 141.41 199.11 142.72C199.22 144.03 198.43 144.86 197.33 144.57C196.24 144.28 195.26 142.98 195.14 141.68C195.03 140.37 195.82 139.54 196.92 139.83V139.82Z" fill="#BDD0FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M155.85 119.09C156.95 119.38 157.93 120.68 158.04 121.99C158.15 123.3 157.36 124.13 156.26 123.84C155.16 123.55 154.18 122.25 154.07 120.94C153.96 119.63 154.75 118.8 155.85 119.09Z" fill="#BDD0FB"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M118.11 146.98C119.21 147.27 120.18 148.57 120.3 149.88C120.42 151.19 119.62 152.02 118.52 151.73C117.42 151.44 116.44 150.14 116.33 148.83C116.22 147.52 117.01 146.69 118.11 146.98Z" fill="#BDD0FB"/>
                    <path d="M91.5099 42.4897H69.1399C67.5999 42.4897 66.3599 41.2397 66.3599 39.7097C66.3599 38.1697 67.6099 36.9297 69.1399 36.9297H91.5099C93.0499 36.9297 94.2899 38.1797 94.2899 39.7097C94.2899 41.2497 93.0399 42.4897 91.5099 42.4897Z" fill="#BDD0FB"/>
                    <path d="M75.03 31.5795H3.12998C1.58998 31.5795 0.349976 30.3295 0.349976 28.7995C0.349976 27.2595 1.59998 26.0195 3.12998 26.0195H75.04C76.58 26.0195 77.82 27.2695 77.82 28.7995C77.82 30.3395 76.57 31.5795 75.04 31.5795H75.03Z" fill="#BDD0FB"/>
                    <path d="M59.06 42.4799H11.77C10.23 42.4799 8.98999 41.2299 8.98999 39.6999C8.98999 38.1599 10.24 36.9199 11.77 36.9199H59.06C60.6 36.9199 61.84 38.1699 61.84 39.6999C61.84 41.2399 60.59 42.4799 59.06 42.4799Z" fill="#BDD0FB"/>
                    <path d="M63.23 64.27H11.77C10.23 64.27 8.98999 63.02 8.98999 61.49C8.98999 59.95 10.24 58.71 11.77 58.71H63.23C64.77 58.71 66.01 59.96 66.01 61.49C66.01 63.03 64.76 64.27 63.23 64.27Z" fill="#BDD0FB"/>
                    <path d="M91.7401 53.3696H19.83C18.29 53.3696 17.05 52.1196 17.05 50.5896C17.05 49.0496 18.3 47.8096 19.83 47.8096H91.7401C93.2801 47.8096 94.5201 49.0596 94.5201 50.5896C94.5201 52.1296 93.2701 53.3696 91.7401 53.3696Z" fill="#BDD0FB"/>
                    <path d="M70.7099 138.03H70.6299C63.5099 137.79 57.9199 132.02 57.9199 124.89C57.9199 122.77 58.4399 120.65 59.4399 118.77C59.4399 118.77 59.4999 118.65 59.5199 118.63L90.9699 64.1504C90.9699 64.1504 91.0199 64.0704 91.0499 64.0204L91.1599 63.8704C93.6199 60.3104 97.6699 58.1904 101.99 58.1904C106.31 58.1904 110.36 60.3104 112.82 63.8704L112.93 64.0204C112.93 64.0204 112.98 64.1004 113.01 64.1404L144.63 118.91C144.63 118.91 144.68 118.99 144.7 119.03L144.77 119.18C145.64 120.97 146.08 122.9 146.08 124.89C146.08 132.02 140.49 137.79 133.36 138.03H70.7099Z" fill="#EAB83B"/>
                    <path d="M139.04 121.951L138.51 121.031L108.13 68.4108L107.6 67.5008C106.37 65.7208 104.32 64.5508 102 64.5508C99.68 64.5508 97.62 65.7208 96.39 67.5008L95.86 68.4108L65.27 121.411L65.07 121.751C64.57 122.691 64.29 123.761 64.29 124.911C64.29 128.581 67.21 131.561 70.85 131.691H133.15C136.79 131.571 139.71 128.591 139.71 124.911C139.71 123.851 139.46 122.851 139.03 121.961L139.04 121.951Z" fill="white"/>
                    <path d="M101.99 108.561C98.97 108.561 96.52 106.111 96.52 103.091V87.0608C96.52 84.0408 98.96 81.5908 101.99 81.5908C105.02 81.5908 107.45 84.0408 107.45 87.0608V103.091C107.45 106.111 105 108.561 101.99 108.561Z" fill="#6E80FF"/>
                    <path d="M107.43 115.85C107.43 112.84 105 110.4 101.99 110.4C98.98 110.4 96.54 112.84 96.54 115.85C96.54 118.86 98.98 121.29 101.99 121.29C105 121.29 107.43 118.85 107.43 115.85Z" fill="#6E80FF"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3409_3720">
                    <rect width="203.68" height="196.23" fill="white" transform="translate(0.160034)"/>
                    </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className='w-100 d-flex flex-column mt-2 gap-2'>
                  <p>{i18n.t(`integration.test`)} "{selectedConfig?.name}"?</p>
                  <div>
                    <div 
                      style={{cursor: 'pointer'}} 
                      className='w-100 d-flex flex-row justify-content-between' onClick={() => setExtendPayload(!extendPayload)}
                    >
                      <b>{i18n.t(`integration.params_test`)}</b>
                      {extendPayload ? 
                        <i className="fa fa-chevron-down"></i>
                        :
                        <i className="fa fa-chevron-right"></i>

                      }
                    </div>
                    {extendPayload && (
                      <div
                        className='d-flex mt-2'
                        style={{ backgroundColor: 'black', overflowY: 'scroll', height: '40em', borderRadius: '8px' }}
                      >
                        <JsonFormatter json={JSON.stringify(templateTest)} tabWith={4} jsonStyle={jsonStyle} />
                      </div>
                    )}
                  </div>
                  {extendPayload && (
                    <CopyToClipboard text={JSON.stringify(templateTest)} >
                      <button className='btn btn-small fw-bolder btn-success w-100'>
                        <p style={{fontSize: 14, marginBottom: 0}}>{i18n.t(`integration.copy_params`)}</p>
                      </button>
                    </CopyToClipboard>
                  )}
                </div>
                <div className='w-100 d-flex flex-row justify-content-end'>
                  <button onClick={async () => await testIntegration()} className='btn btn-small fw-bolder mt-4' style={{backgroundColor: '#6E7586', color: '#FFF'}}>
                      <p style={{fontSize: 14, marginBottom: 0}}>{i18n.t(`general.test`)}</p>
                  </button>
                </div>
              </div>
            ) : (templateResult && JSON.stringify(templateResult) && selectedConfig) && (
              <div className='w-100 d-flex flex-column'>
                <div className='w-100 d-flex flex-column mt-2 gap-2'>
                  <div className={templateResult.success ? styles.successLog : styles.errorLog}>
                    <svg width="50" height="50" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.6875 2.82031L1.95312 15.5156C1.875 15.6328 1.875 15.7109 1.875 15.8281C1.875 16.1406 2.10938 16.375 2.42188 16.375H17.5391C17.8516 16.375 18.125 16.1406 18.125 15.8281C18.125 15.7109 18.0859 15.6328 18.0078 15.5156L10.2734 2.82031C10.2344 2.70312 10.1172 2.625 10 2.625C9.84375 2.625 9.76562 2.70312 9.6875 2.82031ZM8.08594 1.84375C8.47656 1.17969 9.21875 0.75 10 0.75C10.7422 0.75 11.4844 1.17969 11.875 1.84375L19.6094 14.5391C19.8438 14.9297 20 15.3594 20 15.8281C20 17.1562 18.9062 18.25 17.5391 18.25H2.42188C1.09375 18.25 0 17.1562 0 15.8281C0 15.3594 0.117188 14.9297 0.351562 14.5391L8.08594 1.84375ZM11.25 13.875C11.25 14.5781 10.6641 15.125 10 15.125C9.29688 15.125 8.75 14.5781 8.75 13.875C8.75 13.2109 9.29688 12.625 10 12.625C10.6641 12.625 11.25 13.2109 11.25 13.875ZM10.9375 6.6875V10.4375C10.9375 10.9844 10.5078 11.375 10 11.375C9.45312 11.375 9.0625 10.9844 9.0625 10.4375V6.6875C9.0625 6.17969 9.45312 5.75 10 5.75C10.5078 5.75 10.9375 6.17969 10.9375 6.6875Z" 
                      fill={templateResult.success ? '#67BF5F' : '#EB5353'}/>
                    </svg>
                    <p>
                      { templateResult.success 
                        ? i18n.t(`integration.success_1`) : 
                        i18n.t(`integration.error_1`)
                      } "{selectedConfig?.name}" 
                      { templateResult.success 
                        ? i18n.t(`integration.success_2`) : 
                        i18n.t(`integration.error_2`)
                      }
                    </p>
                  </div>
                  <div className='mt-2'>
                    <div 
                      style={{cursor: 'pointer'}} 
                      className='w-100 d-flex flex-row justify-content-between' onClick={() => setExtendPayload(!extendPayload)}
                    >
                      <b>{i18n.t(`integration.result_log`)}</b>
                      {extendPayload ? 
                        <i className="fa fa-chevron-down"></i>
                        :
                        <i className="fa fa-chevron-right"></i>

                      }
                    </div>
                    {extendPayload && (
                      <div
                        className='d-flex mt-2'
                        style={{ backgroundColor: 'black', overflowY: 'scroll', height: '20em', borderRadius: '8px' }}
                      >
                        <JsonFormatter json={JSON.stringify(templateResult)} tabWith={4} jsonStyle={jsonStyle} />
                      </div>
                    )}
                  </div>
                  {extendPayload && (
                    <CopyToClipboard text={JSON.stringify(templateResult)} >
                      <button className='btn btn-small fw-bolder btn-success w-100'>
                        <p style={{fontSize: 14, marginBottom: 0}}>{i18n.t(`integration.copy_result`)}</p>
                      </button>
                    </CopyToClipboard>
                  )}
                </div>
                <div className='w-100 d-flex flex-row justify-content-end'>
                  <button 
                    onClick={templateTest ? async () => await testIntegration() : () => closeWrapperModal()} 
                    className='btn btn-small fw-bolder mt-4' 
                    style={{backgroundColor: '#6E7586', color: '#FFF'}}
                  >
                      <p style={{fontSize: 14, marginBottom: 0}}>{templateTest ? i18n.t(`general.test`) : i18n.t(`buttons.close`)}</p>
                  </button>
                </div>
              </div>
            )}
          </ModalWrapper>
        </div>}
    </>
  )
}

const Pdv: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`integration.PDV`), path: 'pluginconfig/pdv', isActive: true },
        ]}
      >
        {i18n.t(`integration.PDV`)}
      </PageTitle>
      <PdvContent />
    </>
  )
}

export { Pdv }
