/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { GET, PUT } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const EditTaxaEntregaContent: FC = () => {
  const navigate = useNavigate()
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [minKm, setMinKm] = useState<number | null>();
  const [maxKm, setMaxKm] = useState<number | null>();
  const [value, setValue] = useState(0);

  const getDelivery = async () => {
    try {
      setLoading(true);
      const deliveryRes = await GET(`/delivery/${id}`)
      setMinKm(deliveryRes.data[0].minKm)
      setMaxKm(deliveryRes.data[0].maxKm)
      setValue(deliveryRes.data[0].value)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const editDelivery = async () => {
    try {
      if ((!minKm && minKm !== 0) || !maxKm) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const deliveryRes = await PUT(`/delivery/${id}`, {
          minKm,
          maxKm,
          value
        })
        setLoading(false);
        if (deliveryRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Taxa de entrega alterada',
            text: 'Edição realizada com sucesso!'
          }).then(() => navigate('/configuracoes/taxa-entrega/raio'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar taxa de entrega!'
          })
        }
      }
    } catch (e) {
      setLoading(false);
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar taxa de entrega!'
      })
    }
  }

  useEffect(() => {
    getDelivery();
  }, [])

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`delivery_fee.edit_delivery_fee`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                {i18n.t(`delivery_fee.save_changes_edit`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    placeholder={i18n.t(`delivery_fee.km_min`)}
                    label={i18n.t(`delivery_fee.km_min`)}
                    minNumber={0}
                    change={(value: string) => setMinKm(parseInt(value.replace(",", ".")))}
                    value={minKm}
                  />
                </div>
                <div className='me-5 flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'number',
                    }}
                    placeholder={i18n.t(`delivery_fee.km_max`)}
                    label={i18n.t(`delivery_fee.km_max`)}
                    minNumber={0}
                    change={(value: string) => setMaxKm(parseInt(value.replace(",", ".")))}
                    value={maxKm}
                  />
                </div>
                <div className='flex-grow-1'>
                  <Input
                    inputAttr={{
                      type: 'money',
                    }}
                    placeholder={i18n.t(`delivery_fee.value`)}
                    label={i18n.t(`delivery_fee.value`)}
                    currency='BRL'
                    locale='pt-BR'
                    change={(value: number) => setValue(value)}
                    value={value}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="col-6 d-flex justify-content-start">
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className="col-6  d-flex justify-content-end">
                <Button
                  text={i18n.t(`buttons.save`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => editDelivery()}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

const EditarTaxaEntrega: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`delivery_fee.delivery_fee`), path: 'configuracoes/taxa-entrega/raio', isActive: true },
          { title: i18n.t(`general.edit`), path: 'configuracoes/taxa-entrega/raio/editar', isActive: true },
        ]}
      >
        {i18n.t(`delivery_fee.edit_delivery_fee`)}
      </PageTitle>
      <EditTaxaEntregaContent />
    </>
  )
}

export { EditarTaxaEntrega }
