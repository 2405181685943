import { FC, useEffect, useState } from "react"
import { Loading } from '../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from "../../../../_metronic/layout/core"
import { i18n } from "../../../translate/i18n"
import Swal from "sweetalert2"
import { GET, POST } from "../../../services/api"
import { BreadCrumbs } from "../../../components/BreadCrumbs/breadCrumbrs"
import { ConfigLoja } from "../../../components/StoresAgregador/ConfigLoja"
import { DadosLoja } from "../../../components/StoresAgregador/DadosLoja"
import { ImagensLoja } from "../../../components/StoresAgregador/ImagemLoja"
import { LinksLoja } from "../../../components/StoresAgregador/LinksLoja"
import uuid from "react-uuid"
import { getPlugintypeIdByCode } from "../../../functions/plugintype"

const itemMenu = {
    height: '100%',
    padding: '20px 20px 0',
    alignItems: 'center',
    display: 'flex',
    color: '#677484',
    borderBottom: '4px solid #FFFFFF',
}

const itemMenuActive = {
    ...itemMenu,
    borderBottom: '4px solid #6993ff',
}

const itemMenuInactive = {
    ...itemMenu,
    borderBottom: '4px solid #CCCCCC',
}



export type lojaAgregador = {
    name: string;
    description: string;
    order: number;
    classificationId: string;
    pluginspaceId: number;
    image: string | File;
    config: any
    corporatename: string
    corporateName: string
    cnpj: any
    link: string;
    type: string;
    instagram: string;
    phoneWhatsapp: string;
    active: boolean;
    zipCode: string
    exclusive: boolean;
}
type dataType = {
    id: number
    name: string
    type: string
    subdomain: string
    status: string
    active: boolean
    created: Date
}




const LojasCreateContent: FC = () => {
    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('#tab1')
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState<lojaAgregador>({
        name: '',
        description: '',
        order: 0,
        classificationId: '',
        pluginspaceId: 0,
        config: {},
        cnpj: '',
        corporatename: '',
        corporateName: '',
        image: '',
        link: '',
        type: 'food',
        active: true,
        exclusive: false,
        instagram: '',
        phoneWhatsapp: '',
        zipCode: '',
    }
    )
    const [allCategories, setAllCategories] = useState<any[]>([])


    async function getCategories() {
        try {
            setLoading(true)
            const res = await POST(`/classification/filter`, {
                plugintypeId: getPlugintypeIdByCode('service'),
                deleted: 'not show',
            })
            setAllCategories(res.data)
            setLoading(false)
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }



    async function saveImage(image1: any, name: string) {
        try {
            const formData = new FormData()
            formData.append('file', image1)
            formData.append('nameImage', `store-${name}-${uuid()}.jpg`)
            formData.append('originalSize', 'true')
            const imageRes = await POST('/upload-images/upload-one', formData)
            return imageRes.success ? imageRes.image : ''

        } catch (error) {
            return ''
        }
    }


    async function create() {
        try {
            setLoading(true)

            const formToSend = form;

            if (!formToSend.image) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Atenção',
                    text: "Imagens faltando, voltar para sessão de imagens"
                })
                return
            }


            formToSend.image = await saveImage(formToSend.image, formToSend.name);

            const imagesStore = formToSend.config.images;
            const productsStore = formToSend.config.product;

            if (imagesStore && imagesStore.length > 0) {
                const newImagesStore = await Promise.all(imagesStore.map(async (image: { image: any }, index: number) => {
                    image.image = await saveImage(image.image, `${formToSend.name}_${index}`)
                    return image;
                }));
                formToSend.config.images = newImagesStore
            }

            if (productsStore && productsStore.length > 0) {
                const newProductStore = await Promise.all(productsStore.map(async (image: { image: any }, index: number) => {
                    image.image = await saveImage(image.image, `${formToSend.name}_${index}`)
                    return image;
                }));
                formToSend.config.product = newProductStore
            }



            const response = await POST('pluginspaceaggregator', formToSend)

            if (!response || !response.success) {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Erro',
                        text: 'Não foi possivel cadastrar a loja no agregador, tente novamente mais tarde'
                    }
                )
                setLoading(false)
                return
            }



            Swal.fire(
                {
                    icon: 'success',
                    title: 'Sucesso',
                    text: 'Cadastro da loja realizado com sucesso'
                }
            )
            setLoading(false)

            navigate('/stores')
        } catch (error) {
            setLoading(false)
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Erro',
                    text: 'Não foi possivel cadastrar a loja no agregador, tente novamente mais tarde'
                }
            )


        }

    }


    async function next() {
        const parseIntTab = 1 + parseInt(activeTab.split('#tab')[1]);

        if (parseIntTab === 5) {
            await create()
            return
        }

        setActiveTab(`#tab${parseIntTab}`);
    }
    function back() {
        const parseIntTab = parseInt(activeTab.split('#tab')[1]) - 1;

        if (parseIntTab === 0) {
            navigate('/stores')
            return
        }

        setActiveTab(`#tab${parseIntTab}`);
    }

    async function getData() {

        setLoading(false)
    }

    useEffect(() => {
        getCategories()

    }, [])



    return (
        <>
            {loading ? <Loading /> :
                <div className='row g-5 gx-xxl-12'>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <div style={{ flex: 1 }}>
                            <div className='w-100 card'>
                                <div className='card-header py-5'>
                                    <h3 className='card-title align-items-start flex-column'>
                                        <span className='card-label fw-bold fs-4 mb-1'>Dados da Loja</span>
                                        <BreadCrumbs />
                                    </h3>
                                </div>
                                <div className='w-100'>
                                    <div className='card'>
                                        <div className='d-flex' style={{ padding: '0 2.25rem', height: 75, gap: '14px' }}>
                                            <div
                                                style={activeTab === '#tab1' ? itemMenuActive : itemMenuInactive}

                                            >
                                                <span style={{
                                                    fontSize: '14px',
                                                    lineHeight: "30px"
                                                }}>Configurações da Loja</span>
                                            </div>

                                            <div style={activeTab === '#tab2' ? itemMenuActive : itemMenuInactive} >
                                                <span style={{
                                                    fontSize: '14px',
                                                    lineHeight: "30px"
                                                }}>Dados da Loja</span>
                                            </div>

                                            <div style={activeTab === '#tab3' ? itemMenuActive : itemMenuInactive}>
                                                <span style={{
                                                    fontSize: '14px',
                                                    lineHeight: "30px"
                                                }}>Imagens da Loja</span>
                                            </div>

                                            <div style={activeTab === '#tab4' ? itemMenuActive : itemMenuInactive}>
                                                <span style={{
                                                    fontSize: '14px',
                                                    lineHeight: "30px"
                                                }}>Links da Loja</span>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            {activeTab === '#tab1' && (
                                <ConfigLoja
                                    next={next}
                                    back={back}
                                    data={form}
                                    setData={setForm} categories={allCategories} />
                            )

                            }
                            {activeTab === '#tab2' && (
                                <DadosLoja
                                    next={next}
                                    back={back}
                                    data={form}
                                    setData={setForm} />
                            )

                            }
                            {activeTab === '#tab3' && (
                                <ImagensLoja
                                    next={next}
                                    back={back}
                                    data={form}
                                    setData={setForm} />
                            )

                            }

                            {activeTab === '#tab4' && (
                                <LinksLoja
                                    next={next}
                                    back={back}
                                    data={form}
                                    setData={setForm}
                                />
                            )

                            }
                        </div>
                    </div>

                </div>
            }


        </>


    )
}

const LojasCreateAgregador: FC = () => {
    return (
        <>
            <PageTitle
                breadcrumbs={[
                    { title: 'Lojas', path: '/lojas', isActive: false },
                    { title: `${i18n.t(`buttons.create`)} ${i18n.t(`permissions.stores`)}`, path: '/lojas/criar', isActive: true },
                ]}
            >
                {`${i18n.t(`buttons.create`)} ${i18n.t(`permissions.stores`)}`}
            </PageTitle>
            <LojasCreateContent />
        </>
    )
}

export { LojasCreateAgregador }

