/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Datatable } from '../../../components/Datatable/Datatable'
import { Button } from '../../../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import api, { GET, GET_ADM, POST_ADM } from '../../../services/api'
import { toDate } from '../../../utils/formater'
import { Loading } from '../../../components/Loading/Loading'
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2'
import { DecodedTokenData } from '../../../context/authContext'
import { parseJwt } from '../../../utils/utils'
import { i18n } from '../../../translate/i18n'
import useAuth from '../../../hooks/useAuth'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type dataType = {
  id: number
  name: string
  type: string
  subdomain: string
  status: string
  active: boolean
  created: Date
}

type typeType = {
  type: string
  icon: string
  quantity: string
}

const PluginspaceContent: FC = () => {
  const navigate = useNavigate()

  const [data, setData] = useState<dataType[]>([])
  const [types, setTypes] = useState<typeType[]>([])
  const [loading, setLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const { verifyPluginSpace,loginInfo } = useAuth()


  const getData = async () => {
    setLoading(true)
    setLoadingTable(true)


    try {


      //verifica se no token de adm, a plugin space é 2 (Ninegrid)
      const pluginSpaceis2 = verifyPluginSpace()

      if (pluginSpaceis2) {
        const res = await GET_ADM(`/pluginspace`)
        const _data = res.data.map((item: dataType) => {
          return {
            id: item.id,
            name: item.name,
            subdomain: item.subdomain,
            type: item.type,
            status: item.status ? 'ativo' : 'inativo',
            created: toDate(item.created.toString()),
          }
        })
        setData(_data)

      } else {
        const res = await POST_ADM(`/pluginspace/beyondchildren`)

        const _data = res.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            subdomain: item.subdomain,
            type: item.type,
            status: item.status ? 'ativo' : 'inativo',
            created: toDate(item.created.toString()),
          }
        })
        setData(_data)
      }

      setLoading(false)
      setLoadingTable(false)
    } catch (e) {
      setData([])
      setLoading(false)
      setLoadingTable(false)
      console.log(e)
    }
  }

  const getTypes = async () => {
    try {
      setLoading(true)
      const res = await GET_ADM(`/pluginspace/types`)
      const _data = res.map((item: typeType) => {
        return {
          type: item.type,
          icon: item.icon,
          quantity: item.quantity
        }
      })
      setTypes(res.filter((item: typeType) => validType(item.type)))
      setLoading(false)
    } catch (e) {
      setTypes([])
      setLoading(false)
      console.log(e)
    }
  }

  const validType = (type: string) => {
    if (type === 'hospitality' || type === 'food' || type === 'healthcare' || type === 'varejo' || type === 'fashion') {
      return true
    }
    return false
  }

  const accessPluginspace = async (pluginspaceId: number) => {
    try {
      setLoading(true)
      const res = await GET_ADM(`/token/login-adm/${pluginspaceId}`)
      if (process.env.REACT_APP_JWT_SALT && res && res.accessToken) {
        localStorage.setItem('loginInfo_ninegrid_access', JSON.stringify(res))
        const tkNoSalt = res.accessToken.split(process.env.REACT_APP_JWT_SALT).join('')
        const tk: DecodedTokenData | null = parseJwt(tkNoSalt)
        if (tk) {
          setLoading(false)
          window.location.reload();
          navigate('/dashboard')
        } else {
          setLoading(false)
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Falha ao acessar a Pluginspace!'
          })
        }
      } else {
        setLoading(false)
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Falha ao acessar a Pluginspace!'
        })
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Falha ao acessar a Pluginspace!'
      })
    }
  }

  useEffect(() => {
    getTypes()
    getData()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>Contas</span>
                <BreadCrumbs />
                
              </h3>
              <div className='card-toolbar'>
                {types.map((item, index) => (
                  <div key={`type-${index}`} style={{ padding: 6, margin: 3, textAlign: 'center', width: 120, height: 90, border: '1px solid #eff2f5', borderRadius: 5, flexDirection: 'row' }}>
                    <div style={{ textAlign: 'center', height: 30, display: 'block', width: '100%' }}>
                      {item.icon && (
                        <SVG src={item.icon} />
                      )}
                    </div>
                    <div>
                      {item.quantity}<br />
                      {i18n.t(`pluginspaces.${item.type}`) ?? '&nbsp;'}
                    </div>
                  </div>
                ))}
              </div>
              <div className='card-toolbar'>
                { loginInfo.profile && loginInfo.profile === 'admin' &&
                  <Button
                    text='Cadastrar Pluginspace'
                    color='primary'
                    iconColor='#FFF'
                    icon='fas fa-plus'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate('/pluginspace/criar')}
                    btnClass='mb-5 fw-bolder'
                  />
                }
              </div>
            </div>
            <div className='card-body'>
              {loadingTable ? (
                <Loading />
              ) : (
                <>
                  <Datatable
                    data={data}
                    headers={
                      [
                        { key: 'Tipo', value: 'type', type: 'bullet' },
                        { key: 'Id', value: 'id', type: 'default' },
                        { key: 'Nome', value: 'name', type: 'default' },
                        { key: 'Subdomínio', value: 'subdomain', type: 'default' },
                        {
                          key: 'Status',
                          value: 'status',
                          type: 'badge',
                          badge: {
                            ativo: 'success',
                            inativo: 'danger',
                          },
                        },
                        { key: 'Criado em', value: 'created', type: 'default' },
                        { key: 'Ações', value: 'acoes', type: '' },
                      ]
                    }
                    options={
                      [
                        { 
                          icon: 'la la-gear', 
                          action: () => { }, 
                          title: 'Configurações', 
                          dropdownItems: [
                            {text: 'COPIAR CONFIGURAÇÕES', action: (item) => navigate(`/pluginspace/copy-configurations/${item.id}`)}, 
                            // {text: 'COPIAR IMAGENS', action: () => {}}
                          ] 
                        },
                        {
                          icon: 'fas fa-pen',
                          action: (item) => {
                            navigate(`/pluginspace/editar/${item.id}`)
                          },
                          title: 'Editar',
                        },
                        { icon: '', isButton: true, textButton: 'Acessar', action: (item) => { accessPluginspace(item.id) }, color: 'info' },
                        //{ icon: "fas fa-trash", action: () => {}, path: '', title: "Excluir modalidade" },
                      ]
                    }
                    color='primary'
                  />
                </>
              )
              }
            </div>
          </div >
        </div >
      )}

    </>
  )
}

const Pluginspace: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[{ title: 'Contas', path: 'pluginspace', isActive: true }]}>
        Contas
      </PageTitle>
      <PluginspaceContent />
    </>
  )
}

export { Pluginspace }
