import { FC, SetStateAction, useState } from 'react'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import Swal from 'sweetalert2'
import { POST } from '../../../../services/api'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import { useNavigate } from 'react-router-dom'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

const CriarGradesContent: FC = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [variation, setVariation] = useState('')
  const [status, setStatus] = useState<boolean>(true)
  const [list, setList] = useState<string[]>([])

  const addList = () => {
    const newList = list;
    newList.push(variation)
    setVariation('');
    setList(newList)
  }

  const removeList = (variationPosition: number) => {
    const newList = list.filter((variationFiltered, index) => variationPosition !== index);
    setVariation('');
    setList(newList);
  }

  const changeValueFromList = (newVariation: string, variationPosition: number) => {
    const newList = list.map((variationFromList, index) => {
      if (index === variationPosition) {
        variationFromList = newVariation;
      }
      return variationFromList;
    })
    setList(newList);
  }

  const createGrid = async () => {
    try {
      if (!name || list.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!',
        })
      } else {
        setLoading(true)
        const gridRes = await POST('/grid', {
          name: name,
          variation: list,
          status: status
        })
        setLoading(false)
        if (gridRes.success === true) {
          Swal.fire({
            icon: 'success',
            title: 'Grade cadastrada',
            text: 'Cadastro realizado com sucesso!',
          }).then(() => navigate('/grid'))
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar a grade!',
          })
        }
      }
      return
    } catch (e) {
      setLoading(false)
      console.log(e)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar a grade!',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`buttons.create`)} {i18n.t(`smart_order.grid_products`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`general.fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body'>
              <div className='card-body border-bottom'>
                <div className='d-flex justify-content-between align-items-center'>
                  <div className='me-5 flex-grow-1'>
                    <Input
                      inputAttr={{
                        type: 'string',
                      }}
                      placeholder={i18n.t(`smart_order.grid_name`)}
                      label={i18n.t(`smart_order.grid_name`)}
                      change={(value: string) => setName(value)}
                      value={name}
                    />
                  </div>
                  <div className='ms-5 flex-grow-1 '>
                    <Input
                      inputAttr={{
                        type: 'select',
                      }}
                      value={status}
                      change={setStatus}
                      label={i18n.t(`general.active`)}
                      options={[
                        { select: i18n.t(`general.active`), value: true },
                        { select: i18n.t(`general.inactive`), value: false },
                      ]}
                    />
                  </div>
                </div>
                <div className='form-group mt-5'>
                  <label className='form-label fw-normal'>{i18n.t(`smart_order.variation`)}</label>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control fw-normal'
                      placeholder={i18n.t(`smart_order.variation`)}
                      value={variation}
                      onChange={(event) => setVariation(event.target.value)}
                    />
                    <div className='input-group-append'>
                      <button className='btn btn-success' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button' onClick={() => addList()}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
                {list.map((variationListed, index) => (
                  <div className='form-group mt-5' key={index}>
                    <div className='input-group'>
                      <input
                        type='text'
                        className='form-control fw-normal'
                        placeholder={i18n.t(`smart_order.variation`)}
                        value={variationListed}
                        onChange={(event) => changeValueFromList(event.target.value, index)}
                      />
                      <div className='input-group-append'>
                        <button className='btn btn-danger' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button' onClick={() => removeList(index)}>
                          -
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="d-flex w-100">
                <div className="col-6 d-flex justify-content-start">
                  <Button
                    text={i18n.t(`buttons.back`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => navigate(-1)}
                    btnClass='fw-bolder m-9'
                  />
                </div>
                <div className="col-6  d-flex justify-content-end">
                  <Button
                    text={i18n.t(`buttons.create`)}
                    color='primary'
                    size='small'
                    horizontalPadding={6}
                    btnAttr={{
                      type: 'button',
                    }}
                    click={() => createGrid()}
                    btnClass='fw-bolder m-9'
                  />
                </div> </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CriarGrades: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`smart_order.grid_products`), path: 'grid', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'grid/criar', isActive: true },
        ]}
      >
        {i18n.t(`smart_order.grid_products`)}
      </PageTitle>
      <CriarGradesContent />
    </>
  )
}

export { CriarGrades }
