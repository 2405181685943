const messages = {
  en: {
    translations: {
      permissions: {
        food: 'Smart Order',
        smartorder: 'Catalog',
        category: 'Categories',
        subcategory: 'Subcategories',
        product: 'Products',
        mark: 'Marks',
        grid: 'Grids',
        variation: 'Variations',
        catalog: 'Catalogs',
        integration: 'Integrations',
        int_pos: 'PDV',
        int_gateway: 'Means of Payment',
        messaging: 'Messaging',
        sms: 'Status & SMS',
        multichannel: 'Multichannel',
        report: 'Reports',
        saleorder: 'Orders',
        indicators: 'Indicators',
        orderReport: 'Orders',
        imports: 'Images Import',
        importProductImage: 'Products',
        importProductImageSKU: 'Variations',
        importCategoryImage: 'Categories',
        customers: 'Customers',
        customer_report: 'Customer List',
        measurement_unit: 'Measurement Unit',
        logs: 'Logs',
        settings: 'Settings',
        register_pluginspace: 'register_pluginspace',
        charge_integration: 'Charges',
        summaryAccess: 'Summary Access',
        messenger: 'Messenger',
        others: 'Others',
        settings_store: 'Store Settings',
        settings_hotel: 'Hotel Settings',
        modalities: 'Modalities',
        delivery_tax: 'Delivery Fee',
        shifts: 'Shifts',
        payment_plans: 'Payment Plans',
        settings_account: 'Account Settings',
        account: 'Account',
        groups: 'Groups',
        users: 'Users',
        terms: 'Terms',
        highlights: 'Highlights',
        menu: 'Menu',
        productReport: 'Products',
        optional: 'Optionals',
        locations: 'Sectors',
        service: 'Services',
        service_items: 'Services',
        service_category: 'Category',
        feed: 'Feed',
        content: 'Content',
        queue: 'Queue',
        queue_config: 'Settings',
        queue_manager: 'Management',
        checkout: 'Customer Data',
        booking: 'Booking',
        booking_management: 'Management',
        booking_config: 'Settings',
        booking_environment: 'Environment',
        booking_day: 'Bookings of the Day',
        booking_report: 'Reports',
        liveShop: 'Live Shop',
        icon_library: 'Icon Library',
        stores: 'Stores',
        segments: 'Segments',
        payment: 'Payments',
        serviceCategory: 'Service category',
        SDS: 'KDS',
        sds_manage: 'My KDS',
        sds_layout: 'Customization',
        sds_link: 'Settings',
        sds_display: 'Displays',
        /* Agregador */
        config: 'Configuration',
        config_account: 'Account details',
        config_sector: 'Operation sector',
        config_user: 'Users',
        config_term: 'Terms',
        subCategory: 'Subcategory',
        footer:'Footer Features',
        footerFeature:'Enable features',
        footerContent:'Resource Feed',
        footerContentAddEdit:'Resource',
        webapp:'Personalize',
        webapp_qrcode:'Generate QR Code',
        ia_menu: 'Artificial Intelligence',
        ia_config: 'Settings',
        ia_fit: 'Fit',
        publication_highlight_banner: "Banner Destacado",
        publication: "Publicidad"
      },
      buttons: {
        create: 'Create',
        back: 'Go Back',
        register_pluginspace: 'register_pluginspace',
        access: 'Access',
        add_modality: 'Add modality',
        add_km: 'Add Km',
        zip_code_list: 'Zip Code List',
        add_zip_code: 'Add Zip Code',
        area: 'Freight Areas',
        list_of_negative_renge_zip_code: 'List of Renge negative zip codes',
        add_negative_zip_code: 'Add negative zip code',
        add_user: 'Add user',
        generate_password: 'Generate password',
        generate_sku: 'Generate SKU',
        add_group: 'Add group',
        save: 'Save',
        add_term: 'Add term',
        add_integration: 'Add integration',
        filter: 'Filter',
        search: 'Search',
        register_product: 'Register product',
        choose_files: 'Choose files',
        add_categories: 'Add categories',
        add_subcategories: 'Add subcategories',
        add_brand: 'Add brand',
        close: 'Close',
        add_grade: 'Add grade',
        add: 'Add',
        add_variation: 'Add variation',
        confirm_grades: 'Confirm grades',
        add_catalog: 'Add catalog',
        generete_qr_code: 'Generete QR Code',
        personalize: 'Personalize',
        settings: 'Settings',
        add_interval: 'Add interval',
        add_gateway: 'Add gateway',
        overview: 'Overview',
        load_csv: 'Load file',
        download_csv: 'Default XLSX download',
        att_page: 'Refresh page',
        export: 'Export',
        add_service: 'Add Service',
        add_environment: 'Add environment',
      },

      general: {
        delete_item: 'Do you want to delete this item?',
        delete_product_observation: 'The variation of this product will be deleted as well, would like to continue?',
        delete_classification_observation: 'Deleting this category, all products related to it go to General, do you want to continue?',
        delete: 'Delete',
        edit: 'Edit',
        attention: 'Attention',
        actions: 'Actions',
        here_are_listed_the_users_along_with_their_respective_actions:
          'Here are listed the items along with their respective actions',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
          'Fill in the information below and click on the create button to save the changes',
        save_changes_edit:
          'Fill in the information below and click on the save button to confirm the changes',
        active: 'Active',
        inactive: 'Inactive',
        showing: 'Showing',
        of: 'of',
        to: 'to',
        records: 'records',
        today: 'Today',
        copy: 'Copy',
        linkS3: 'link',
        linkedS3: 'Linked',
        downloadS3: 'Download',
        downloadedS3: 'Download Started',
        copy_link: 'Copy Seller Link',
        copied: 'Copied',
        test: 'Test',
      },
      feedCategory: {
        feedCategory: 'Content category'
      },

      pluginspaces: {
        hospitality: 'Hospitality',
        food: 'Food',
        healthcare: 'Healthcare',
        varejo: 'Retail',
        fashion: 'Fashion',
      },

      dashboard: {
        totals_by_products: 'Totals by products',
        totals_by_category: 'Totals by category',
        last_orders: 'Last orders',
        products: 'Products',
        quantity: 'Quantity',
        total: 'Total',
        category: 'Category',
      },

      modalities: {
        modalities: 'Modalities',
        here_are_listed_the_modalities_along_with_their_respective_actions:
          'Here are listed the modalities along with their respective actions',
        delivery: 'Delivery',
        withdraw: 'Withdraw',
        active: 'Active',
        inactive: 'Inactive',
        name: 'Name',
        code: 'Code',
        actions: 'Actions',
        search: 'Search',
        type: 'Type',
        sector_name: 'Sector Name',
        table: 'Table',
        room: 'Room',
        counter: 'Counter',
        internal: 'Internal Delivery',
      },
      delivery_fee: {
        delivery_fee: 'Delivery fee',
        create_delivery_fee: 'Create delivery fee',
        edit_delivery_fee: 'Edit delivery fee',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
          'Fill in the information below and click on the create button to save the changes',
        save_changes_edit:
          'Fill in the information below and click on the save button to confirm the changes',
        km_min: 'Km Min',
        km_max: 'Km Max',
        value: 'Value',
        actions: 'Actions',
        patterns: 'Import Standards',
        import_file:
          'To import the files, the first cell of the file must follow one of the following patterns:',
        import_delivery: 'To import by range: kmmin, kmmax, Value',
        import_cep: 'To import zip code: cep_inicio, cep_fim, valor',
        import_cep_negative: 'To import zip code negative: cep_inicio, cep_fim, valor',
        model: 'Template',
        continue_import: 'All records will be overwritten, are you sure you want to continue?',
        cep_delivery: 'Zip Code Delivery',
        cep_info:
          'Here are listed the zip codes for deliveries along with their respective actions.',
        cep_start: 'Zip Code Start',
        cep_end: 'Zip Code End',
        create_cep: 'Create Zip Code',
        edit_cep: 'Edit Zip Code',
        cep_delivery_negative: 'Zip Code Delivery',
        cep_info_negative:
          'Here are listed the zip codes for deliveries along with their respective actions.',
        create_cep_negative: 'Create Negative Zip Code',
        edit_cep_negative: 'Edit Negative Zip Code',
        delete_cep_negative: 'Delete Negative Zip Code',
      },

      user: {
        user: 'User',
        here_are_listed_the_users_along_with_their_respective_actions:
          'Here are listed the users along with their respective actions',
        fill_in_the_information_below_and_click_on_the_create_button_to_save_the_changes:
          'Fill in the information below and click on the create button to save the changes',
        save_changes_edit:
          'Fill in the information below and click on the save button to confirm the changes',
        picture: 'Picture',
        name: 'Name',
        type: 'Type',
        registration: 'Registration',
        create_user: 'Create user',
        edit_user: 'Edit user',
        profile_picture: 'Profile picture',
        password: 'Password',
        generate_pass: 'Generate Password',
        access_level: 'Access Group',
        select_a_access_level: 'Select a access Group',
        select_type: 'Select a type',
        active: 'Actve',
        actions: 'Actions',

        discount_permission: 'Permission to Discount',
        discount_value: 'Maximum discount value (%)',
        phone: 'Phone number'
      },

      groups: {
        groups: 'Groups',
        here_are_listed_the_groups_along_with_their_respective_actions:
          'Here are listed the groups along with their respective actions',
        name: 'Name',
        Integrations: 'Integrations',
        means_of_payment: 'Means of payment',
        products: 'Products',
        category: 'Categories',
        subcategory: 'Subcategory',
        indicators: 'Indicators',
        requests: 'Orders',
        reports: 'Reports',
        messenger: 'Message',
      },

      shifts: {
        shifts: 'Shifts',
        here_are_listed_the_shifts_for_each_day_of_the_week:
          'Here are listed the shifts for each day of the week',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        start: 'Start',
        end: 'End',
      },

      account: {
        account: 'Account',
        edit_account: 'Edit account',
        registration_number: 'Registration number',
        corporate_name: 'Corporate name',
        name_of_the_establishment: 'Name of the establishment',
        login_of_person_in_charge: 'Login of the establishment account',
        subdomain: 'Subdomain',
        code: 'Code',
        default_language: 'Default language',
        default_currency: 'Default currency',
        name_of_person_in_charge: 'Name of person in charge',
        address: 'Address',
        zip_code: 'zip code',
        number: 'Number',
        neighborhood: 'Neighborhood',
        city: 'City',
        state: 'state',
        public_place: 'Public Place',
        address_complement: 'Complement',
      },

      terms: {
        terms: 'Terms',
        here_are_the_terms_of_acceptance_of_the_sales_platform:
          'Here are the terms of acceptance of the sales platform',
        create_your_term: 'Create your term',
        term_name: 'Term name',
        valid_until: 'Valid until',
        name: 'Name',
        expirated: 'Expirated',
      },

      payment_plans: {
        payment_plans: 'Payment plans',
        here_are_listed_the_payment_plans_along_with_their_respective_actions:
          'Here are listed the payment plans along with their respective actions',
        create_payment_plans: 'Create Payment Plans',
        fill_the_information_below_and_click_the_create_button_to_save_the_changes:
          'Fill in the information below and click the create button to save the changes',
        erp_integration: 'ERP Integration',
        integration_type: 'Integration Type',
        select_an_integration: 'Select an integration',
        card_flag: 'Card Flag',
        flag: 'Flag',
        type: 'Type',
        erp_code: 'ERP Code',
        card_installment: 'Installment',
        payment_plan: 'Payment plan',
        installment: 'Installment',
        time: 'Deadline',
        credit: 'Credit',
        debit: 'Debit',
      },

      indicators: {
        indicators: 'Indicators',
        orders: 'Orders',
        filters: 'Filters',
        first: 'Start',
        end: 'End',
        total_order: 'Total orders',
        revenue: 'Revenue',
        orders_by_location: 'Orders by type',
        orders_by_month: 'Order by month',
        orders_by_day: 'Order by day',
        orders_by_hour: 'Order by hour',
        orders_by_month_sector: 'Orders by month and type',
        orders_by_day_sector: 'Orders by day and type',
        orders_by_hour_sector: 'Orders by hour and type',
        product: 'Products',
        categories: 'Categories',
        sales_by_category: 'Sales by category',
        revenue_by_category: 'Revenue by category',
        average_ticket: 'Average ticket',
        quantity: 'Quantity',
        summary: 'Summary',
        summaryAccess: 'Summary Access',
        summaryAccessGroup: 'Access by period',
        summaryTimeAccessGroup: 'Total by time',
        summaryIPAccessGroup: 'Total by users',
        summarySellerCart: 'Total carts created by seller',
      },

      customer_list: {
        customer_list: 'Customer List',
        listed_customers: 'Here are listed the clients along with their respective actions.',
        select_time: 'Select a Period',
        name: 'Name',
        first: 'Start',
        end: 'End',
        phone: 'Phone',
        date: 'Date',
        detail: 'Customer Details',
        total_profit: 'Total Profit',
        total_itens: 'Total Items',
        total_orders: 'Total Orders',
        order_between: 'Orders between',
        order_detail: 'Order Detail',
      },

      smart_order: {
        smart_order: 'Smart Order',
        product: 'Products',
        crop_image: 'Crop Image',
        here_are_listed_the_products_along_with_their_respective_actions:
          'Here are listed the products along with their respective actions',
        name: 'Name',
        code: 'Code',
        product_data: 'Product Data',
        mark: 'Brand',
        measurement_unit: 'Unit of Measurement',
        description: 'Description',
        technical_specification: 'Technical Specification',
        from: 'from',
        characters: 'characters',
        order: 'Order',
        product_image: 'Product Image',
        additional_images: 'Additional Images',

        categories: 'Categories',
        here_are_listed_the_categories_along_with_their_respective_actions:
          'Here are listed the categories along with their respective actions',
        image: 'Product image',
        image_variation: 'Variation image',
        category: 'Category',
        category_parent: 'Parent Category',
        category_name: 'Category name',
        category_code: 'Category code',
        perfil_image: 'Perfil Image',

        subcategories: 'Subcategories',
        subcategory: 'Subcategory',
        here_are_listed_the_subcategories_along_with_their_respective_actionsc:
          'Here are listed the subcategories along with their respective actions',
        select_category: 'Select a category',

        marks: 'Brands',
        listened_marks: 'Here are listed the brands along with their respective actions',

        measurements_units: 'Units of Measurements',
        listened_measurement:
          'Here are listed the units of measurements along with their respective actions',

        grid_products: 'Grid Products',
        listened_grid: 'Here are listed the grid products along with their respective actions',
        grid_name: 'Grid name',
        variation: 'Variation',
        grid: 'Grid',

        variations: 'Variations',
        listened_variations: 'Here are listed the variations along with their respective actions',
        modality: 'Modality',
        select_modality: 'Select a modality',
        prod: 'Product',
        select_product: 'select a product',
        no_variation: 'Product with no variation',
        variation_product: 'Product Variation',
        cost_price: 'Cost Price',
        sell_price: 'Sale Price',
        promotional_price: 'Promotional Price',
        weight: 'Weight',
        height: 'Height',
        width: 'Width',
        depth: 'Depth',
        availability: 'What is the availability of the product?',
        when_product_stock: 'When the product runs out of stock?',
        stock: 'Quantity of products in stock',
        min_stock: 'Minimum Stock',
        price: 'Price',

        catalogs: 'Catalogs',
        catalog: 'Catalog',
        listened_catalogs: 'Here are listed the catalogs along with their respective actions',
        validity: 'Validity',
        first: 'Start',
        end: 'End',
        search_products: 'Product Search',

        product_status: 'Product status',
        sku: 'SKU/EAN',
        status_variation: 'Variation Status',
        specification: 'Specification',
        price_promotional: 'Discount price',
        inventory: 'Inventory',
        service_title: 'Here are listed the services along with their respective actions.',

        initial_hour: 'Start time',
        final_hour: 'Final time',
      },

      webapp: {
        webapp: 'Webapp',
        generate_qrcode: 'Generate QR Code',
        personalize: 'Personalize',
        settings: 'Settings',
        link: 'Webapp Link',

        webapp_data: 'Webapp Data',
        name: 'Name',
        place: 'Place',
        product_pagination: 'Number of products in pagination',
        logo_ninegrid: 'Ninegrid Logo',
        light: 'Light',
        dark: 'Dark',
        logo_totvs: 'TOTVS Logo',
        use_icon_menu: 'Use Icon in Webapp Menu',
        description: 'Description',
        icon: 'Icon',
        color_setting: 'Color Setting',
        background_webapp: 'Background Webapp',
        background_default_webapp: 'Defaut webApp background color',
        background_header: 'Background header',
        text_header: 'Text header',
        icon_header: 'Icon header',
        background_footer: 'Background footer',
        icon_footer: 'Icon footer',
        highlight_footer: 'Highlight footer',
        notification_cart: 'Notification cart',
        notification_text: 'Notification text',
        home_button: 'Home button',
        text_home_button: 'Home button text',
        title_bar: 'Title bar',
        text_title_bar: 'Title bar text',
        icon_title_bar: 'Title bar icon',
        text_category: 'Category text',
        webapp_button: 'Button webapp',
        text_button: 'Button text',
        background_menu: 'Side background menu',
        text_menu: 'Side text menu',
        order_status: 'Order Status',
        order_active_status: 'Active Order Status',
        text_order_active_status: 'Order status text',
        info_cart: 'Cart Information',
        settings_webapp: 'Settings Webapp',
        type_delivery: 'Delivery restriction type',
        integration: 'Integration',
        select_integration: 'Select an integration',
        payment_method_card: 'Payment Method Card',
        payment_method_pix: 'Payment Method Pix',
        payment_method_delivery: 'Delivery Payment Method',
        consent_letter: 'Activate consent letter',
        product_no_stock: 'Sale of out-of-stock products',
        show_subcategory: 'Show subcategory',
        show_observation: 'Show observation',
        use_category_list: 'View subcategory in list',
        remove_show_mode: 'Remove show mode',
        no_shadow: 'Do not add shadow in categories',
        is_order_number: 'Sort category',
        show_layout: 'Show Layout',
        image_type: 'Product image style',
        orders: 'Orders',
        support: 'Support',
        application: 'Webapp',

        withdraw_fee: 'Withdraw Fee',
        minimum_value: 'Minimum Value to Order',

        seller_checkout: 'Checout Method',
        no_sales: 'Do not allow sales',
        safe_mode: 'Safe Mode',
        time_safe_mode: 'Safe Mode Session Time (in minutes)',

        limit_characteres_explanation:
          'For stores with Wintor or Protheus integration, the recommended length is max 50 characters.',
        limit_characteres: 'Limit of characteres',

        skip_entry: 'Skip entry screen',
        payment_table: 'Payment at the table',

        slides_homePage: 'HomePage Featured Images',
        slides_initial: 'Initial Featured Images',
        slides_not_found: 'Not found image for slider',

        externalLink: 'External link',
        buttonType: 'Button type',
        ableUserLocation: 'Allow address search by location',

        seller_cart_limit_time : 'Seller cart expiration time in hour',
        seller_cart_limit_time_explanation : 'Time for the customer be able to use the seller cart',

        settings_webapp_aggregator:'Personalize',
        generate_qrcode_webapp_aggregator:'Generate Qrcodes',
        qrcode_webapp_aggregator:'Web Application',
      },

      integration: {
        integration: 'Integration',
        means_of_payment: 'Means of payment',
        here_are_listed_the_means_of_payment_along_with_their_respective_actions:
          'Here are listed the means of payment along with their respective actions',
        PDV: 'PDV',
        listed_here_are_the_integrations_along_with_their_respective_actions:
          'Listed here are the integrations along with their respective actions',
        integration_name: 'Integration name',
        type_of_integration: 'Type of integration',
        actions: 'Actions',
        search: 'Search',
        example: 'Payload Example',

        loads: 'Loads',
        name: 'Name',
        price: 'Price',
        stock: 'Stock',
        measurements_units: 'Measurements Units',
        grid: 'Grids',
        classifications: 'Classifications',
        products: 'Products',
        status_sync: 'Status Sync',
        marks: 'Brands',
        orders: 'Orders',
        trigger_load: 'Trigger Load',
        first: 'Start',
        end: 'End',
        load: 'Load',
        messenger: 'Messenger',
        others: 'Others',
        test: 'Do you want to test the integration',
        params_test: 'View settings for this integration',
        copy_params: 'Copy parameters',
        result_test: 'Integration test results',
        success_1: 'Success! Integration',
        success_2: 'was successful.',
        result_log: 'Result log',
        copy_result: 'Copy result',
        search_result: 'Search in results',
        error_1: 'Integration',
        error_2: 'returned an error. Check the log below for more information.'
      },

      reports: {
        orders: 'Orders',
        order_report: 'Order Report',
        order_list: 'Order List',
        first: 'Start',
        end: 'End',
        firstTime: 'Start time',
        endTime: 'End time',
        orders_between: 'Orders between',
        order_number: 'Order Number',
        date: 'Date',
        consumer: 'Consumer',
        modality: 'Modality',
        total_value: 'Total Value',
        cancel_order: 'Cancel Order',

        products: 'Products',
        quantity: 'Quantity',
        product: 'Product',
        value: 'Value',
        total: 'Total',
        order_status: 'Order Status',
        payment_method: 'Payment Method',
        billing_address: 'Billing Address',
        product_value: 'Value of Products',
        discount: 'Discount',
        order: 'Order',

        listened_logs: 'Listed here are the logs along with their respective actions',
        methods: 'Methods',
        log_details: 'Log Details',

        withdraw: 'Withdraw',
        delivery: 'Delivery',
        table: 'Table',
        responsible_withdraw: 'Responsible for the withdraw',
        responsible_delivery: 'Responsible for the reception',
        address_withdraw: 'Withraw address',
        address_delivery: 'Delivery address',

        seller: 'Seller',
        external_code: 'External Code',
        payment: 'Payment',
        internal_delivery: 'Internal Delivery'
      },

      imports: {
        imports: 'Imports',
        import_image_product: 'Imports of Images for Products',
        import_image_category: 'Importações de Imagens para Categories',
        listened_import_product:
          'Here are listed the product images along with their respective actions',
        no_upload_images: 'Do not upload images',
        first: 'Start',
        end: 'End',
        total_products: 'Total Products',
        sync_products: 'Synchronized Products',
        import_image_sku: 'Imports of Images for Variation',
        listened_import_sku:
          'Here are listed the variation images along with their respective actions',
        verify_verification: 'Variation check',
        product_code: 'Product Code',
        code: 'Code',
        total_variations: 'Total Variations',
        sync_variations: 'Synchronized Variations',
        total_categories: 'Total Categories',
        sync_categories: 'Synchronized Categories',
        problem_title: 'Images with problem',
        images_success: 'images were uploaded successfully.',
        images_error: 'images had a problem and were not sent.',
        file: 'File',
        status: 'Import Status',
        new_import: 'Perform a new import',
        import_xls: 'Import images with XLS file',
        import_repo: 'Import images from Google Drive or Dropbox',
        steps: 'Step by step for import',
        step_1: '1. Put all images on a public web server.',
        step_2: '2. Make sure the server is public and accessible via the web.',
        step_3: '3. Please download our blank XLSX file.',
        step_4: '4. Fill in the data from your image bank.',
        step_5: '5. Please upload the completed file.',
        step_1_repo: '1. Make sure the store folder is created as "fotos_loja_<store identifier>".',
        step_2_repo: '2. Create the "produtos" folder inside your store folder.',
        step_2_repo_sku: '2. Create the "variacoes" folder inside your store folder.',
        step_2_repo_category: '2. Create the "categorias" folder inside your store folder.',
        step_3_repo: '3. Confirm that the files are as "<product code>.<extension>".',
        step_3_repo_sku: '3. Confirm that the files are as "<sku>.<extension>".',
        step_3_repo_category: '3. Confirm that the files are as "<category code>.<extension>".',
        step_4_repo: '4. If the product is an additional product, check if it has "_<number>".',
        step_4_repo_sku:
          '4. Confirm that the variation is an image and has the extension JPG or PNG.',
        step_4_repo_category:
          '4. Confirm that the category is an image and has the extension JPG or PNG.',
        step_5_repo: '5. Confirm that the product is an image and has the extension JPG or PNG.',
        download: 'Download blank XLSX file',
        download_problems: 'Export report',
        upload: 'Send file',
        upload_anex: 'Attach file',
        import_dropbox: 'Import with Dropbox',
        import_drive: 'Import with Google Drive',
        spreadsheet: 'Spreadsheet',
        gdrive: 'Google Drive',
        dropbox: 'Dropbox',
        type: 'Type',
        type_resize: 'Resize Type',
        agroup_color: 'Group photos by color'
      },

      sms: {
        sms: 'SMS',
        listened_sms: 'Here are listed the messages (SMS) along with their respective actions',
        order: 'Order',
        name: 'Name',
        message: 'Message',
        markers: 'The tags [request] and [status] must be in the message',
        waiting: 'Awaiting',
        opened: 'Open',
        ready: 'Ready',
        refused: 'Refused',
      },

      multichannel: {
        multichannel: 'Multichannel',
        listened_multichannel:
          'Here are listed the settings from multichannel along with their respective actions',
      },

      queue: {
        index: 'Queue',
        config: 'Queue Configuration',
        able: 'Allow queue',
        link: 'Link:',
        limit_people: 'Maximum number of people',
        limit_people_text: 'Wait queue limit',
        limit_time: 'Time limit to display alert',
        limit_time_text: 'Maximum time in minutes to consider delay',
        image_title: 'Images',
        image_text:
          'The queue system displays 2 images. Upload photos of your restaurant, or select default photos below. The uploaded photos will be adjusted to the size of 350x250 pixels.',
        image: 'Image',
        position: 'Position',
        time: 'Waiting time',
        people: 'people',
        cancel: 'Cancellation',
        cancel_text: 'Do you want to remove this person from the queue?',
        cancel_button: 'Remove',
        confirm: 'Confirmation',
        confirm_text: "Do you want to confirm the booking's arrival?",
        confirm_button: 'Confirm arrival',
        empty: 'There is no waiting list at this time',
        confirm_all: 'Confirm all',
        cancel_all: 'Cancel all',
        observation: 'Observation',
        use_terms: 'Activate consent form',
        total_people: 'Total number of people per group',
        total_people_text: 'Wait queue limit per group',
      },
      booking: {
        index: 'Booking',
        config: 'Booking Configuration',
        able: 'Allow booking',
        link: 'Link:',
        image_title: 'Images',
        image_text:
          'The booking system displays 2 images. Upload photos of your restaurant, or select default photos below. The uploaded photos will be adjusted to the size of 350x250 pixels.',
        image: 'Image',
        days_week: 'Days of the week',
        booking_day: 'Allow reservations on this day?',
        booking_day_text: 'Define below the times released for reservations.',
        booking_day_subtext:
          'When selecting a time, a window of 1 hour will be defined. Example: by selecting 19:00 people will be able to make reservations between 19:00 and 20:00.',
        max_people: 'Maximum number of people per booking',
        max_people_text: 'It is the maximum size of a group of people that can make a reservation',
        expiry_time: 'Maximum time until reservation expires',
        expiry_time_text: 'Time in minutes to wait for the booker to arrive',
        day_confirm: 'Require confirmation on the day',
        day_confirm_text: 'User needs to confirm the reservation on the chosen date',
        payment_confirm: 'Require advance payment on booking',
        value_payment_confirm: 'Advance payment amount',
        week: 'Week',
        month: 'Month',
        year: 'Year',
        use_terms: 'Activate consent form',
        empty: 'There is no booking at this time',
        use_time: 'Allow reservation by period',
        time: 'Reservation duration (HH:mm)'
      },
      booking_day: {
        booking: 'Reservations of the day',
        date: 'Date',
        people: 'people',
        expiry: 'Expires in',
        cancel_title: 'Cancellation',
        cancel_subtitle: 'Do you want to cancel the reservation?',
        cancel_button: 'Cancel booking',
        confirm_title: 'Confirmation',
        confirm_subtitle: 'Do you want to confirm the arrival of the booker?',
        confirm_button: 'Confirm arrival',
      },
      booking_report: {
        report: 'Reports',
        time: 'Select period',
        search: 'Search for reservations',
        date_start: 'Start date',
        date_end: 'End date',
        confirm: 'Confirmatiom',
        noShow: 'No show',
        confirmed: 'Confirmed reservations',
        canceled: 'Canceled reservations',
        open: 'Opened',
        map: 'Reservations Map',
        booking: 'reservations',
      },
      booking_report_management: {
        management: 'Reservation Management',
        search: 'Search for reservations',
        phone: 'Phone',
        date_start: 'Start date',
        date_end: 'End date',
        today: 'Today',
        tomorrow: 'Tomorrow',
        calendar: 'Calendar',
        see_all: 'See all',
        people: 'people',
        search_text: 'Results by phone search or date selection',
      },
      environment: {
        map: 'Environments map',
        environment: 'Environment',
        index: 'Environments',
        name: 'Name',
        tables: 'Number of tables',
        seats_available: 'Total seats available',
        actions: 'Actions',
      },
      week_day: {
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thur: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
      },
      week_day_full: {
        sun: 'Sunday',
        mon: 'Monday',
        tue: 'Tuesday',
        wed: 'Wednesday',
        thur: 'Thursday',
        fri: 'Friday',
        sat: 'Saturday',
      },
      checkout: {
        title: 'Customer Data',
        here_are_listed_the_modalities_along_with_their_respective_actions:
          'Here are listed the necessary fields for creating the customer.',
        active_mandatory: 'Mandatory Field',
        active_visibility: 'Visible Field',
      },

      liveShop: {
        liveShop: 'Catálogo ao Vivo',
        liveShop_list: 'Here the Live Catalogs are listed along with their respective actions.',
        create: 'Create Live Shops',
        edit: 'Edit Live Shops',
        dateInit: 'Start',
        hourInit: 'Start Time',
        dateEnd: 'End',
        hourEnd: 'End Time',
        status: 'Status',
        initDate: 'Start Date and Time',
        fimDate: 'End Date and Time',
        name: 'Name',
        variations: 'Product Variations',
        search_products_Attentions:
          'The returned Products and Variations must have an activated status, have a price, stock and image',
        alert_delete: 'It is not possible to remove a Live when it started or ended!',
        alert_Edit: 'It is not possible to edit a Live when it started or ended!!',
        delete: 'Remove Live!',
      },

      logs: {
        //PagSeguro
        pagseguroPaymentCodePhone: 'Pagseguro Phone code error',
        pagseguroPaymentErrorCountry: 'Pagseguro Country code error',
        pagseguroPaymentErrorEmail: 'Pagseguro Email error',
        authenticatePagseguro: 'Pagseguro Authentication',
        pagseguroPayment: 'Pagseguro Payment',
        pagseguroPaymentCatch: 'Pagseguro Payment',
        pagseguroPaymentResponse: 'Pagseguro payment response',
        pagseguroPaymentTransactions: 'Pagseguro payment transaction',
        pagseguroReturnPayment: 'Pagseguro return payment',
        paymentPixPagseguro: 'Pagseguro Pix payment',

        pagseguroPaymentGateway: 'Pagseguro payment gateway',
        getAuthPagseguroEmailSpace: 'Pagseguro authentication by email',
        getOneApplicationClientId: 'Pagseguro get application by client',
        createAuthPagseguro: 'Pagseguro create authentication',
        generatePublicKeyPagseguro: 'Pagseguro public key',
        updateCodeRedirectApplication: 'Pagseguro updade capplication code',
        deleteAuthPagseguroByEmail: 'Pagseguro delete authentication',
        createPluginconfig: 'Pagseguro pluginConfig',

        //Totvs pagamento HUB
        refundTotvs: 'Totvs refund',
        refundPayCard: 'Totvs card refund',
        paymentTotvs: 'Totvs payment',
        paymentPixTotvs: 'Totvs Pix payment',

        //Card Structure
        cardStructure: 'Card Structure',
        cardStructureInvalidPlugin: 'Invalid gateway plugin',
        cardStructureGatewayError: 'Invalid card to gateway',
        cardStructureData: 'Card data',
        cardStructureError: 'Card data error',
        cardStructureDecrypt: 'Card decrypt',
        cardStructureFields: 'Card fields',
        refundCard: 'Card refund',

        //Status
        analysisSatusOrder: 'Analisis status order',
        statusPayPix: 'Pix status ',

        //Antifraude
        analyseAntifraud: 'Anti-Fraud Analysis',

        //estoque
        addSkuInventory: 'Add to stock',
        subtractSku: 'remove from stock',

        // Order-Pedido
        cancelOrder: 'Cancel order',
        sendOrder: 'Send order',
        sendOrderRefund: 'Refund order',
        sendOrderError: 'Send order error',
        sendOrderResultRefund: 'Refund order result',
        sendOrderResultData: 'Send order result',
        sendOrderAntifrud: 'Anti-fraud send order',
        sendOrderPaymentStruct: 'Order payment structure',

        sendOrderDescryptor: 'Order decrypt',
        sendOrderStatus: 'Order status',
        sendOrderConfigNotFound: 'Order shipping configuration',
        sendOrderMandatoryCustomer: 'Order customer',
        sendOrderMandatory: 'Order structure',
        sendOrderResultGateway: 'Order gateway result',

        //Pre-Order
        preOrderStatusPix: 'Pre Order pix status',
        processQueuePreOrder: 'Pre Order process queue',
        getAllPreOrderBySpace: 'Get pre order by pluginspace',
        sendPreOrder: 'Send pre order',
        makeOrderTransparent: 'Order verification',
        statusPreOrder: 'Update Pix status of pre order',

        //Totvs Wintor
        buildOrderTotvsWinthor: 'Create order Wintor',
        sendOrderWinthor: 'Send order Wintor',
        sendCustomerOrderWinthor: 'Send customer Wintor',

        //Totvs Varejo
        buildOrderTotvsVarejo: 'Create order Varejo',
        setConfimTicket: 'Ticket Confirmation',
        SyncOrderLive: 'Order Synchronization',
        ObterProdutos: 'Get Products',
        taskPrice: 'Price Load',
        calculationPromotion: 'Promotion Calculation',
        PayloadCalcPromotion: 'Payload Promotion Calculation',

        //Totvs Moda
        changeStatus: 'Change Status',
        sendOrderFashion: 'Send order Fashion',
        buildOrderTotvsFashion: 'Create order Fashion',
        sendCustomerFashion: 'Send customer Fashion',
        searchPersonTotvsByCPF: 'Search customer Fashion',
        sendErrorCustomerFashion: 'Send customer Fashion error',
        buildCustomerTotvsFashion: 'Create customer Fashion',
        ResumeSnyOrderFashion: 'Resumo sincronia com o Fashion',

        //Totvs Food
        updateStatusOrderFoodIntegration: 'Change status Food integration',
        buildOrderTotvsFood: 'Create order',
        changeStatusOrderFood: 'Change status Food',
        sendOrderTotvsFood: 'Send order Food',
        confirmMenu: 'Confirmed Menu',
        getMenu: 'Get Menu Totvs',

        //Totvs Live
        sendCustomerLive: 'Send customer Live',
        sendOrderLive: 'Send order Live',

        //Synkar
        updateStatusOrderSynkar: 'Update status order Synkar',

        //SDS
        sdsGetOrders: 'Get orders SDS',
        sdsOrderIn: 'Send orders SDS',
        sdsOrderInResult: 'Send order to SDS',
        sdsSyncCardapio: 'Sync menu with SDS',
        'sendOrderfood-sdsOrderIn': 'Error to send order SDS',

        //S3
        createRoute: 'Create s3 route',
        getStatusfiftythree: 'Status route s3',
        updateDistribution: 'Update distribution',
        deleteRoute: 'Delete route S3',
        deleteDistribution: 'Delete distribution',

        //Erede
        refundErede: 'Erede refound',
        paymentErede: 'Erede payment',

        //Cielo
        refundCielo: 'Cielo refund',
        paymentCielo: 'Cielo payment',

        //pagamento offline
        paymentOffline: 'Offline payment',

        //Geral
        chargeSyncOrderErro: 'Sync status error',
        chargeProductLiveErro: 'Sync Live products error',
        changeBalanceLiveErro: 'Sync Live  stock error',
        chargePriceLiveErro: 'Sync Live price error',
        chargeProductErro: 'Sync products error',
        changeBalanceErro: 'Sync stock error',
        chargePriceErro: 'Sync price error',
        chargeSyncOrder: 'Sync status',
        chargeProductLive: 'Sync products',
        changeBalanceLive: 'Sync stock',
        chargePriceLive: 'Sync price',
        taskBalanceIntegration: 'Stock load integration',
        taskBalanceCharge: 'Stock Load initalization',
        chargeBalance: 'Stock Load',
        SnyOrder: 'Sync status orders',
        syncronyzePriceInteration: 'Price load',
        syncronyzePrice: 'Sync price',
        chargeClassification: 'Category and subcategory load',
        chargeGrid: 'Grid load',
        chargeGridItens: 'Grid load items',
        chargeMeasure: 'Measure load',
        deleteAllProducts: 'Delete all products',

        chargeGridToken: 'Get token for grid load',
        classificationChargeToken: 'Get token for classification load',
        syncronyzeBalanceToken: 'Obtain token for inventory synchronization',
        syncronyzePriceToken: 'Get Token for Price Synchronization',
        chargeMeasureToken: 'Obtain token for unit loading of measure',
        taskClassificationBySpace: 'Classification Load',
        syncStatusOrderPayload: 'Order status synchronization payload',
        syncStatusOrder: 'Order status synchronization',
        syncronyzeBalancePayload: 'Inventory Synchronization Payload',
        syncronyzeBalance: 'Stock Synchronization',
        syncronyzePricePayload: 'Price Synchronization Payload',
        priceSkuVariationItens: 'Price of variation items',
        configSyncOrderBySpace: 'Order Synchronization Configuration',
        configSyncOrder: 'Order Sync Configuration',
        taskGridChargeBySpace: 'Grid load for Store',
        productChargeInternal: 'Product load',
        taskChargeProductBySpace: 'Product load',
        skuLocationVariation: 'Location-Based Variation Synchronization',

        automaticChargeBalance: 'Automatic Stock Sync',
        automaticChargePrice: 'Automatic Price Sync',
        automaticChargeClassification: 'Automatic Classification Sync',
        automaticTaskGridCharge: 'Automatic Grid Sync',
        automaticTaskMeassure: 'Automatic Unit Sync. Measurements',
        automaticSnyOrder: 'Automatic Order Status Sync',
        chargeMeasureItens: 'Measured Unit Load Items',
        searchMeasurementAll: 'Unit of measure load',
        stockSkuVariation: 'Variation Synchronisms',
        taskGridCharge: 'Grid Load',
        taskMeassure: 'Unit of measure load',

        getCredential: 'Get Token',
        LoadPricePerList: 'Load Prices',
        processPriceList: 'Price List Processing',
        updateProductKit: 'Product Kit Update',
        UpdateProductSingle: 'Product Update',
        updateProductStatus: 'Product Status Update',
        taskBalanceIntegrationLink: 'Stock Integration Link',
        taskPriceIntegrationLink: 'Price Integration Link',
        taskProductIntegrationLink: 'Product Integration Link',
        token: 'Token Retrieval',
        automaticChargeProduct: 'Automatic Product Load',

        //Desconto Ninegrid
        calculateDiscount: 'Ninegrid discount calculation',
        sendDiscountNinegrid: 'Ninegrid discount',

        //Customer Cart
        updateCustomerCartStatus: 'Update customer shoppingCart',

        //Cliente
        createCustomer: 'Create customer',

        //clearSale
        statusAnalisis: 'Status analisis',
        antifraudValidation: 'Ant-fraud validation',
        clearSale: 'ClearSale Garantido',
        searchOrderStatusClearSale: 'Search status ClearSale',
      },

      copy: {
        definition: 'Pluginspace Definition',
        origin: 'Select the Pluginspace origin',
        destination: 'Select the destination Pluginspace',
        configurations: 'Copy Settings',
        store: 'Store Settings',
        deliveryFee: 'Delivery Fee',
        shifts: 'Shifts',
        paymentPlans: 'Payment Plans',
        clientData: 'Client Data',
        account: 'Account Settings',
        groups: 'Groups',
        terms: 'Terms',
        webapp: 'Webapp',
        webappConfig: 'Settings',
        webappPersonalize: 'Personalization',
        integrations: 'Integrations',
        integrationPayment: 'Payment Methods',
        integrationPdv: 'PDV',
        integrationMessaging: 'Messaging',
        integrationOthers: 'Others',
        button: 'Copy Pluginspace',
      },

      payment: {
        methods: 'Payment Methods',
        method: 'Payment Method',
        gateway: 'Payment gateway',
        card: 'Credit card',
        debit: 'Debit card',
        food: 'Food voucher',
        pix: 'Pix',
        pay_money: 'Money',
        config: 'Settings',
        add: 'Configure payment method',
        back: 'Back to payment gateways',
        basic_settings: 'Basic settings',
        modalities: 'Modalities where this payment gateway will be used',
        add_payment: 'Add new payment method',
        code_erp: 'ERP Code:',
        installments: 'Number of installments',
        edit_payment: 'Edit new payment method',
        delete: 'Delete payment method',
        money: 'Cash payment',
        max_installments: 'What is the maximum number of installments?',
        confirm: 'Confirm deletion',
        delete_payment: 'Do you really want to delete',
        change: 'Do you want to make the change?',
        inative: 'Do you want to deactivate this payment method?',
        inative_change: 'Do you want to activate this payment method and deactivate the old one?',
        motive: 'Please provide the reason'
      },
      freight: {
        title: 'Shipping methods',
        map: 'Map',
        map_text: 'Option to pay upon order delivery',
        range: 'Radius',
        range_text: 'Option to pay upon order delivery',
        cep: 'List of CEP',
        cep_text: 'Option to pay upon order delivery',
        config: 'Settings',
      },

      icon: {
        title: 'Icons',
        subtitle: 'Here it is listed all icons'
      },
      chat: {
        you: 'You',
        now: 'Just now',
        send: 'Send',
        type_message: 'Type a message...',
        initial_message: 'Welcome to AI chat by Ninegrid',
        error_message: "I couldn't find a reply to your message",
        tip_language: 'Reply in English please.',
        title_config: 'Artificial Intelligence Configurations',
        config: 'Configure Artificial Intelligence',
        fit: 'Artificial Intelligence Training',
        fit_header: 'Here are listed the questions with their created answers',
        add_question: 'Add question',
        edit_question: 'Edit question',
        delete_question: 'Do you want to delete the question below?',
        question: 'Question',
        answer: 'Answer',
        date: 'Date',
        actions: 'Actions',
        active: 'Active',
        delete: 'Delete',
        sync: 'Sync IA'
      },
      sumary_access: {
        cart: 'Cart',
        seller: 'Seller Login',
        category: 'Categories',
        pdv: 'Product Details',
        subcategory: 'Subcategory',
        product: 'Product List',
        home: 'Home Screen',
        'search-order': 'Orders',
        'store-info': 'Store Information',
        customer: 'Customer Registration',
        receipt: 'Payment Receipt',
        order: 'Payment',
        card: 'Card Payment',
        'order-qrcode': 'Customer Pix Payment',
        'success-order': 'Purchase Success',
        'filter/pdv': 'Filter Results',
        filter: 'Filter Selection',
        'seller-qrcode': 'Payment Link',
        'vendor-order': 'Vendor Cart Check',
        'finalize-order': 'Purchase Summary',
    }

    },
  },
}

export { messages }
