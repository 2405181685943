import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import useModals from '../../../../hooks/useModals'
import styles from './ModalText.module.css'

const ModalText: FC = () => {
  const {textModal, closeTextModal} = useModals()

  return (
    <>
      {textModal.display && (
        <>
          <div onClick={() => closeTextModal()} className={styles.background}></div>
          <div className={`${styles.modalCustom} modal-dialog`}>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{textModal.title}</h5>
                <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  onClick={() => closeTextModal()}
                >
                  <KTSVG
                    path='/media/icons/duotune/arrows/arr061.svg'
                    className='svg-icon svg-icon-2x'
                  />
                </div>
              </div>
              <div className='modal-body text-break'>
                <pre>{textModal.text}</pre>
              </div>
              <div className='modal-footer'>
                <button type='button' className='btn btn-light' onClick={() => closeTextModal()}>
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {ModalText}
