import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import {messages} from './languages'

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

i18n.use(LanguageDetector).init({
  debug: false,
  defaultNS: ['translations'],
  fallbackLng: 'pt',
  ns: ['translations'],
  resources: messages,
  returnNull: false,
})

export {i18n}
