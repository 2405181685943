import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PageTitle } from '../../../../../_metronic/layout/core'
import { Button } from '../../../../components/Button/Button'
import { Input } from '../../../../components/Input/Input'
import { Loading } from '../../../../components/Loading/Loading'
import useAuth from '../../../../hooks/useAuth'
import api, { GET, POST } from '../../../../services/api'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type SelectType = {
  value: number;
  select: string;
}

type SelectIntegrationType = {
  value: number;
  select: string;
  plugintemplateId: number;
}

type IntegrationResponseType = {
  id: number;
  name: string;
  plugintemplateId: {
    id: number;
    name: string;
    parameters: string;
    plugintypeId: number
  }
}

type BrandResponseType = {
  id: number;
  tband: number;
  operator: string;
}

type IntegrationType = {
  gateway: {
    id: number;
    name: string;
    plugintemplateId: number;
  };
  brand: {
    id: number;
    name: string;
  };
  code: string;
  type: string;
}

type PlanType = {
  installment: number;
  code: number;
}

type PlanSendType = {
  installment: number;
  rate: string;
  paymentTerm: string;
  codePlan: number;
}

type IntegrationSendType = {
  nameGateway: string;
  brand: string;
  cardOperator: number;
  typePay: string;
  codePay: string;
  pluginspaceId: number | null;
  pluginconfigId: number;
  plugintemplateId: number;
  paymentPlan: PlanSendType[];
  hash: string;
}


const CriarPlanosPagamentosContent: FC = () => {

  const navigate = useNavigate();
  const { loginInfo } = useAuth();
  const [loading, setLoading] = useState(false);
  const [integrationId, setIntegrationId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [codeErpIntegration, setCodeErpIntegration] = useState('');
  const [installments, setInstallments] = useState(0);
  const [codeErpPlan, setCodeErpPlan] = useState(0);
  const [integrations, setIntegrations] = useState<Array<SelectIntegrationType>>([]);
  const [brands, setBrands] = useState<Array<SelectType>>([]);
  const [listIntegrations, setListIntegrations] = useState<IntegrationType[]>([]);
  const [listPlans, setListPlans] = useState<PlanType[]>([]);
  const [typeCard, setTypeCard] = useState('Credit');
  const [templateId, setTemplateId] = useState(0);
  const [canAlert, setCanAlert] = useState(false)

  const getIntegrations = async () => {
    try {
      setLoading(true);
      const integrationRes = await POST(`/pluginconfig/filter`, { plugintypeId: 14 })
      const newIntegrations: SelectIntegrationType[] = integrationRes.data.map((pluginconfig: IntegrationResponseType) => {
        return {
          value: pluginconfig.id,
          select: pluginconfig.name,
          plugintemplateId: pluginconfig.plugintemplateId.id
        }
      })
      setIntegrations([{ select: 'Selecione uma integração', value: 0, plugintemplateId: 0 }, ...newIntegrations]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const getBrands = async () => {
    try {
      setLoading(true);
      const brandRes = await GET(`/operator`)
      console.log(brandRes)
      const newBrands: SelectType[] = brandRes.data.map((brand: BrandResponseType) => {
        return {
          value: brand.tband,
          select: brand.operator,
        }
      })
      setBrands([{ select: 'Selecione uma bandeira', value: 0 }, ...newBrands]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const addListIntegration = () => {
    if (!integrationId || !brandId || !codeErpIntegration) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Preencha os campos para adicionar integração!'
      })
    } else {
      const findedIntegration = listIntegrations.find((l) => l.brand.id === brandId && l.gateway.id === integrationId && l.type === typeCard);
      if (findedIntegration) {
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Integração já adicionada!'
        })
        return
      }
      const newList = listIntegrations.map((l) => l);
      const gatewayFind = integrations.find((integration) => integration.value === integrationId);
      const brandName = brands.find((brand) => brand.value === brandId)?.select;
      if (gatewayFind && brandName) {
        newList.push({
          gateway: {
            id: integrationId,
            name: gatewayFind.select,
            plugintemplateId: gatewayFind.plugintemplateId
          },
          brand: {
            id: brandId,
            name: brandName
          },
          code: codeErpIntegration,
          type: typeCard
        });
      }
      setListIntegrations(newList);
      setBrandId(0);
      setCodeErpIntegration('');
      setTypeCard('Credit')
    }
  }

  const removeListIntegration = (integration: IntegrationType) => {
    const newList = listIntegrations.filter((l) => l !== integration);
    setListIntegrations(newList);
  }

  const addListPlan = () => {
    if (!installments || !codeErpPlan) {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Preencha os campos para adicionar plano!'
      })
    } else {
      const newList = listPlans.map((l) => l);
      newList.push({
        installment: installments,
        code: codeErpPlan
      });
      setCodeErpPlan(0);
      setInstallments(0);
      setListPlans(newList);
    }
  }

  const verifyIsCard = () => {
    return !(!brandId || brandId === 100 || brandId === 300 || brandId === 28 || brandId === 27 || brandId === 26)
  }

  const removeListPlan = (plan: PlanType) => {
    const newList = listPlans.filter((l) => l !== plan);
    setListPlans(newList);
  }

  const getDatas = async () => {
    await getIntegrations();
    await getBrands();
  }

  const createIntegrations = async () => {
    try {
      if (!listIntegrations.length || !listPlans.length) {
        setCanAlert(true)
        Swal.fire({
          icon: 'warning',
          title: 'Atenção!',
          text: 'Não foram adicionadas as listas de integrações ou planos.'
        })
        return
      } else {
        setLoading(true);

        const resPluginspace = await GET(`/pluginspace/this`);

        if (resPluginspace.data && resPluginspace.data[0] && resPluginspace.data[0].hash) {
          const dataIntegrationSend: IntegrationSendType[] = listIntegrations.map((integration) => ({
            nameGateway: integration.gateway.name,
            brand: integration.brand.name,
            cardOperator: integration.brand.id,
            typePay: integration.type,
            codePay: integration.code,
            pluginspaceId: loginInfo.pluginspaceId,
            pluginconfigId: integration.gateway.id,
            plugintemplateId: integration.gateway.plugintemplateId,
            paymentPlan: listPlans.map((plan) => ({
              installment: plan.installment,
              rate: '0',
              paymentTerm: '0',
              codePlan: plan.code
            })),
            hash: resPluginspace.data[0].hash
          }))
          const result = await POST(`orders/card-payment`, { data: dataIntegrationSend });
          setLoading(false);
          if (result.success === true) {
            setCanAlert(false)
            Swal.fire({
              icon: 'success',
              title: 'Integrações cadastradas',
              text: 'Cadastro realizado com sucesso!'
            }).then(() => navigate('/configuracoes/planos-pagamento'))
          } else {
            setCanAlert(true)
            Swal.fire({
              icon: 'error',
              title: 'Erro',
              text: 'Erro ao cadastrar integrações!'
            })
          }
        } else {
          setCanAlert(true)
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar integrações!'
          })
          return
        }
      }
    } catch (e: any) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: e ? e : 'Erro ao cadastrar usuário!'
      })
    }
  }

  const handleUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = true;
    localStorage.removeItem('plans-saved')
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [handleUnload]);

  useEffect(() => {
    const plansSaved = localStorage.getItem('plans-saved')
    if (plansSaved && JSON.parse(plansSaved)) loadLocal()
    else getDatas();

  }, [])

  useEffect(() => {
    return () => {
      if (canAlert && !window.location.pathname.includes(`/configuracoes/planos-pagamento/criar`)) {
        Swal.fire({
          title: 'Sair da página?',
          text: 'Há alterações não salvas que serão perdidas se você sair da página.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sair',
          cancelButtonText: 'Continuar editando',
        }).then((result) => {
          if (!result.isConfirmed) {
            navigate(`/configuracoes/planos-pagamento/criar`)
          } else {
            localStorage.removeItem('plans-saved')
          }
        })
      } else if (!canAlert) localStorage.removeItem('plans-saved')
    }
  }, [window.location.pathname, canAlert])

  const loadLocal = () => {
    const plansSaved = localStorage.getItem('plans-saved')
    if (plansSaved && JSON.parse(plansSaved)) {
      const jsonPlans = JSON.parse(plansSaved);
      setIntegrationId(jsonPlans.integrationId)
      setBrandId(jsonPlans.brandId)
      setCodeErpIntegration(jsonPlans.codeErpIntegration)
      setInstallments(jsonPlans.installments)
      setCodeErpPlan(jsonPlans.codeErpPlan)
      setIntegrations(jsonPlans.integrations)
      setBrands(jsonPlans.brands)
      setListIntegrations(jsonPlans.listIntegrations)
      setListPlans(jsonPlans.listPlans)
      setTypeCard(jsonPlans.typeCard)
      setTemplateId(jsonPlans.templateId)
      localStorage.removeItem('plans-saved')
    }
  }

  const saveLocal = () => {
    localStorage.setItem('plans-saved', JSON.stringify({
      integrationId,
      brandId,
      codeErpIntegration,
      installments,
      codeErpPlan,
      integrations,
      brands,
      listIntegrations,
      listPlans,
      typeCard,
      templateId
    }))
  }

  useEffect(() => {
    saveLocal();
  }, [
    integrationId,
    brandId,
    codeErpIntegration,
    installments,
    codeErpPlan,
    integrations,
    brands,
    listIntegrations,
    listPlans,
    typeCard,
    templateId
  ])

  return (
    <>
      {loading ? <Loading /> :
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`payment_plans.create_payment_plans`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`payment_plans.fill_the_information_below_and_click_the_create_button_to_save_the_changes`)}
                </span>
              </h3>
            </div>
            <div className='card-body border-bottom'>
              <div className='d-flex justify-content-between align-items-start mb-4 mt-2 flex-wrap gap-5'>
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>{i18n.t(`payment_plans.erp_integration`)}</h5>
                      <hr className="mb-10" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <Input
                        inputAttr={{
                          type: 'select',
                        }}
                        placeholder='Selecione'
                        label={i18n.t(`payment_plans.integration_type`)}
                        options={integrations}
                        value={integrationId}
                        change={(value: string) => {
                          setCanAlert(true)
                          setIntegrationId(parseInt(value))
                          setBrandId(0);
                          setListIntegrations([]);
                          setListPlans([]);
                          const integration = integrations.find((integration) => integration.value === parseInt(value))
                          setTemplateId(integration ? integration.plugintemplateId : 0)
                          setTypeCard('Credit')
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group col-md-7">
                          <Input
                            inputAttr={{
                              type: 'select',
                            }}
                            placeholder='Selecione'
                            label={i18n.t(`payment_plans.card_flag`)}
                            options={brands}
                            value={brandId}
                            change={(value: string) => {
                              setCanAlert(true)
                              setBrandId(parseInt(value))
                              setTypeCard('Credit')
                            }}
                          />
                        </div>
                        <div className="form-group col-md-5 mt-6">
                          <label className='form-label fw-normal'>{i18n.t(`payment_plans.erp_code`)}</label>
                          <div className='input-group'>
                            <input
                              type='text'
                              className='form-control fw-normal text-center'
                              placeholder=''
                              value={codeErpIntegration}
                              onChange={(event) => {
                                setCanAlert(true)
                                setCodeErpIntegration(event.target.value)
                              }}
                            />
                            <div className='input-group-append'>
                              <button className='btn btn-success' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button' onClick={() => addListIntegration()}>
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(templateId === 44 && verifyIsCard()) && (
                    <div className="mt-2 p-1 d-flex flex-row gap-4 justify-content-start align-items-center">
                      <Input
                        inputAttr={{
                          type: 'radio',
                        }}
                        name='cardType'
                        id='credit'
                        label={i18n.t(`payment_plans.credit`)}
                        checked={typeCard === 'Credit'}
                        value={'Credit'}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          if (value) setTypeCard('Credit')
                        }}
                      />
                      <Input
                        inputAttr={{
                          type: 'radio',
                        }}
                        name='cardType'
                        id='debit'
                        label={i18n.t(`payment_plans.debit`)}
                        checked={typeCard === 'Debit'}
                        value={'Debit'}
                        change={(value: boolean) => {
                          setCanAlert(true)
                          if (value) setTypeCard('Debit')
                        }}
                      />
                    </div>
                  )}
                  {listIntegrations && listIntegrations.length > 0 &&
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <div className="row">
                          <table className="table table-sm table-hover">
                            <thead>
                              <tr>
                                <th scope="col"><b>Gateway</b></th>
                                <th scope="col"><b>{i18n.t(`payment_plans.flag`)}</b></th>
                                <th scope="col"><b>{i18n.t(`payment_plans.erp_code`)}</b></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                listIntegrations.map((integration) => (
                                  <tr>
                                    <td>{integration.gateway.name}</td>
                                    <td>{integration.brand.name}</td>
                                    <td>{integration.code}</td>
                                    <td>
                                      <button
                                        className='btn btn-danger'
                                        style={{ height: 15, width: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        type='button'
                                        onClick={() => removeListIntegration(integration)}
                                      >
                                        X
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  }
                </div>
                <div className="col-md-5">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>{i18n.t(`payment_plans.payment_plans`)}</h5>
                      <hr className="mb-12" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="form-group col-md-6">
                          <Input
                            inputAttr={{
                              type: 'number',
                            }}
                            textCenter={true}
                            label={i18n.t(`payment_plans.installment`)}
                            value={installments}
                            change={(value: string) => {
                              setCanAlert(true)
                              setInstallments(parseInt(value))
                            }}
                          />
                        </div>
                        <div className="form-group col-md-6 mt-6">
                          <label className='form-label fw-normal'>{i18n.t(`payment_plans.erp_code`)}</label>
                          <div className='input-group'>
                            <input
                              type='number'
                              className='form-control fw-normal text-center'
                              placeholder=''
                              value={codeErpPlan}
                              onChange={(event) => setCodeErpPlan(parseInt(event.target.value))}
                            />
                            <div className='input-group-append'>
                              <button className='btn btn-success' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} type='button' onClick={() => addListPlan()}>
                                +
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {listPlans && listPlans.length > 0 &&
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="row">
                              <table className="table table-sm table-hover">
                                <thead>
                                  <tr>
                                    <th scope="col"><b>{i18n.t(`payment_plans.installment`)}</b></th>
                                    <th scope="col"><b>{i18n.t(`payment_plans.time`)}</b></th>
                                    <th scope="col"><b>{i18n.t(`payment_plans.erp_code`)}</b></th>
                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    listPlans.map((plan) => (
                                      <tr>
                                        <td>{plan.installment}</td>
                                        <td>{plan.installment * 30}</td>
                                        <td>{plan.code}</td>
                                        <td>
                                          <button
                                            className='btn btn-danger'
                                            style={{ height: 15, width: 8, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                            type='button'
                                            onClick={() => removeListPlan(plan)}
                                          >
                                            X
                                          </button>
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex w-100">
              <div className="col-6 d-flex justify-content-start">
                <Button
                  text={i18n.t(`buttons.back`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate(-1)}
                  btnClass='fw-bolder m-9'
                />
              </div>
              <div className="col-6  d-flex justify-content-end">
                <Button
                  text={i18n.t(`buttons.create`)}
                  color='primary'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => {
                    setCanAlert(false)
                    createIntegrations()
                  }}
                  btnClass='fw-bolder m-9'
                />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

const CriarPlanosPagamentos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`payment_plans.payment_plans`), path: 'configuracoes/planos-pagamento', isActive: true },
          { title: i18n.t(`buttons.create`), path: 'configuracoes/planos-pagamento/criar', isActive: true },
        ]}
      >
        {i18n.t(`payment_plans.payment_plans`)}
      </PageTitle>
      <CriarPlanosPagamentosContent />
    </>
  )
}

export { CriarPlanosPagamentos }
