import { FC } from 'react'
import { Helper, FAQ, Suport } from '../../../../../_metronic/partials/layout/helper/helper'

const ManualList: FC = () => {




  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-150px mt-5 p-0'
      data-kt-menu='true'
      data-kt-menu-overflow='true'
    >
      <Helper />
      <FAQ />
      <Suport />
    </div>
  )
}

export { ManualList }
