import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Datatable} from '../../../components/Datatable/Datatable'
import {Button} from '../../../components/Button/Button'
import {ModalWrapper} from '../../../components/Modals/General/ModalWrapper/ModalWrapper'
import {Input} from '../../../components/Input/Input'
import useModals from '../../../hooks/useModals'
import {DELETE, GET, POST, PUT} from '../../../services/api'
import { Loading } from '../../../components/Loading/Loading'
import { ModalDelete } from '../../../components/Modals/General/ModalDelete/ModalDelete'
import Swal from 'sweetalert2'
import { i18n } from '../../../translate/i18n'
import { BreadCrumbs } from '../../../components/BreadCrumbs/breadCrumbrs'

type BookingEnvironmentType = {
    name: string;
    tables: number;
    seatsAvailable: number;
}

const BookingEnvironmentContent: FC = () => {
  const {showWrapperModal, showDeleteModal} = useModals()

  const [environments, setEnvironments] = useState<BookingEnvironmentType[]>([])
  const [action, setAction] = useState<'creating' | 'editing'>('creating');
  const [id, setId] = useState<number | null>(null);
  const [name, setName] = useState<string>('')
  const [tables, setTables] = useState(0)
  const [seatsAvailable, setSeatsAvailable] = useState(0)
  const [loading, setLoading] = useState(false);

  const getEnvironments = async () => {
    try {
      setLoading(true);
      const bookingEnvironmentRes = await GET('/queueReservation/bookingEnvironment')
      if(bookingEnvironmentRes.success && bookingEnvironmentRes.data){
        setEnvironments(bookingEnvironmentRes.data);
      } else {
        setEnvironments([]);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e)
    }
  }

  const createEnvironment = async () => {
    try{
      if(!name || !tables || !seatsAvailable) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const environmentRes = await POST('/queueReservation/bookingEnvironment', {
          name,
          tables,
          seatsAvailable,
        });
        if(environmentRes.success === true){
          Swal.fire({
            icon: 'success',
            title: 'Ambiente cadastrado',
            text: 'Cadastro realizado com sucesso!'
          }).then(async () => await getEnvironments())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao cadastrar modalidade!'
          }).then(async () => await getEnvironments())
        }
        setLoading(false);
      }
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao cadastrar modalidade!'
      }).then(async () => await getEnvironments())
      console.log(e)
    }
  }
  
  const editEnvironment = async () => {
    try{
      if(!name || !tables || !seatsAvailable) {
        Swal.fire({
          icon: 'error',
          title: 'Campos faltantes',
          text: 'Verifique se preencheu todos os campos!'
        })
      } else {
        setLoading(true);
        const environmentRes = await PUT(`/queueReservation/bookingEnvironment/${id}`, {
            name,
            tables,
            seatsAvailable,
        });
        if(environmentRes.success === true){
          Swal.fire({
            icon: 'success',
            title: 'Ambiente alterado',
            text: 'Edição realizada com sucesso!'
          }).then(async () => await getEnvironments())
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro',
            text: 'Erro ao alterar modalidade!'
          }).then(async () => await getEnvironments())
        }
        setLoading(false);
      }
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao alterar modalidade!'
      }).then(async () => await getEnvironments())
      console.log(e)
    }
  }

  const deleteEnvironment = async () => {
    try{
      setLoading(true);
      const environmentRes = await DELETE(`/queueReservation/bookingEnvironment/${id}`);
      if(environmentRes.success === true){
        Swal.fire({
          icon: 'success',
          title: 'Ambiente deletado',
          text: 'Deleção realizada com sucesso!'
        }).then(async () => await getEnvironments())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar modalidade!'
        }).then(async () => await getEnvironments())
      }
      setLoading(false);
    } catch(e) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar modalidade!'
      }).then(async () => await getEnvironments())
      console.log(e)
    }
  }

  useEffect(() => {
    getEnvironments()
  }, [])

  return (
    <>
    { loading ? <Loading /> :
    <div className='row g-5 gx-xxl-12'>
      <div className='card card-xxl-stretch mb-5 mb-xl-12'>
        <div className='card-header py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`environment.index`)}</span>
            <BreadCrumbs />
          </h3>
          <div className='card-toolbar'>
            <Button
              text={i18n.t(`buttons.add_environment`)}
              color='primary'
              iconColor='#FFF'
              icon='fas fa-plus'
              size='small'
              horizontalPadding={6}
              btnAttr={{
                type: 'button',
              }}
              click={() => {
                showWrapperModal(i18n.t(`buttons.add_environment`))
                setName('');
                setTables(0);
                setSeatsAvailable(0)
                setId(null);
                setAction('creating')
              }}
              btnClass='mb-5 fw-bolder'
            />
          </div>
        </div>
        <div className='card-body'>
          <Datatable
            data={environments}
            headers={[
              {key: i18n.t(`environment.name`), value: 'name', type: 'default'},
              {key: i18n.t(`environment.tables`), value: 'tables', type: 'default'},
              {key: i18n.t(`environment.seats_available`), value: 'seatsAvailable', type: 'default'},
              {key: i18n.t(`environment.actions`), value: 'acoes', type: ''},
            ]}
            options={[
              {
                icon: 'fas fa-pen',
                action: (item) => {
                  showWrapperModal(`${i18n.t(`general.edit`)} ${i18n.t(`environment.environment`)}`)
                  setAction('editing')
                  setId(item.id);
                  setName(item.name)
                  setTables(item.tables)
                  setSeatsAvailable(item.seatsAvailable)
                },
                title: `${i18n.t(`general.edit`)} ${i18n.t(`environment.environment`)}`,
              },
              {icon: 'fas fa-trash', action: (item) => {
                showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`environment.environment`)}`)
                setId(item.id);
              }, title: `${i18n.t(`general.delete`)} ${i18n.t(`environment.environment`)}`},
            ]}
            color='primary'
          />
        </div>
      </div>

      <ModalWrapper confirmAction={action === 'creating' ? () => createEnvironment() : () => editEnvironment()}>
        <div className='d-flex justify-content-between align-items-center flex-wrap gap-2'>
          <div className='w-100'>
            <Input
              inputAttr={{
                type: 'default',
              }}
              value={name}
              change={setName}
              placeholder={i18n.t(`environment.name`)}
              label={i18n.t(`environment.name`)}
            />
          </div>
          <div className='w-100'>
            <Input
              inputAttr={{
                type: 'number',
              }}
              value={tables}
              change={setTables}
              placeholder={i18n.t(`environment.tables`)}
              label={i18n.t(`environment.tables`)}
            />
          </div>
          <div className='w-100'>
            <Input
              inputAttr={{
                type: 'number',
              }}
              value={seatsAvailable}
              change={setSeatsAvailable}
              placeholder={i18n.t(`environment.seats_available`)}
              label={i18n.t(`environment.seats_available`)}
            />
          </div>
        </div>
      </ModalWrapper>

      <ModalDelete confirmAction={() => deleteEnvironment()} /> 
    </div>}
    </>
  )
}

const BookingEnvironment: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`environment.map`), path: 'booking/environment', isActive: true},
        ]}
      >
        {i18n.t(`environment.map`)}
      </PageTitle>
      <BookingEnvironmentContent />
    </>
  )
}

export {BookingEnvironment}
