/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PageTitle } from '../../../../../_metronic/layout/core';
import { i18n } from '../../../../translate/i18n';
import ChatImg from '../../../../assets/ia/chatgpt.svg'
import useModals from '../../../../hooks/useModals';
import { Loading } from '../../../../components/Loading/Loading';
import { CardIA } from '../../../../components/CardIA';
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs';
import { GET, PUT } from '../../../../services/api';
import Swal from 'sweetalert2';

type ModelType = {
  id: number;
  image: string;
  description: string;
  name: string;
  status: boolean;
  typeIntegration: string;
  hasConfig: boolean;
}

type ConfigType = {
  id: number;
  name: string;
  content: any;
  plugintemplateId: number;
  plugintypeId: number;
}


const IAConfigContent: FC = () => {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [ableItem, setAbleItem] = useState(0)
  const [models, setModels] = useState<ModelType[]>([])

  //Templates já configurados
  const [pluginconfigs, setPluginconfigs] = useState<ConfigType[]>([])

  const activeItem = async (plugin: number) => {
    const newPluginconfigActive = pluginconfigs.find((pg) => pg.plugintemplateId === plugin)
    if(newPluginconfigActive){

      setAbleItem(plugin !== ableItem ? plugin : 0)
      newPluginconfigActive.content.able = true
      await PUT(`/pluginconfig/${newPluginconfigActive.id}`, {
        plugintypeId: newPluginconfigActive.plugintypeId,
        plugintemplateId: newPluginconfigActive.plugintemplateId,
        name: newPluginconfigActive.name,
        content: newPluginconfigActive.content,
      })

      const newPluginconfigInactive = pluginconfigs.find((pg) => pg.plugintemplateId === ableItem)
      if(newPluginconfigInactive){
        newPluginconfigInactive.content.able = false
        await PUT(`/pluginconfig/${newPluginconfigInactive.id}`, {
          plugintypeId: newPluginconfigInactive.plugintypeId,
          plugintemplateId: newPluginconfigInactive.plugintemplateId,
          name: newPluginconfigInactive.name,
          content: newPluginconfigInactive.content,
        })
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Atenção!',
        text: 'Para ativar é necessário configurar o plugin. Deseja configurar?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Configurar',
        cancelButtonText: 'Cancelar'
      }).then((resp) => {
        if(resp.isConfirmed) navigate(`/ia/config/criar/${plugin}`)
      })
      return
    }
  }

  const getConfigs = async () => {
    try{
      const configsRes = await GET('plugintemplate/ia')
      console.log(configsRes)
      if(configsRes && configsRes.data){
        const configsIA = configsRes.data.filter((model:ModelType) => model.status)
        const configsAtualRes = await GET('pluginconfig')
        const templatesIA = configsIA.map((model:ModelType) => model.id)
        if(configsAtualRes.data){
          let newTemplatesConfigs:ConfigType[] = []
          configsAtualRes.data.forEach((config:ConfigType) => {
            if(templatesIA.includes(config.plugintemplateId)){
              newTemplatesConfigs.push(config)
            }
          })
          newTemplatesConfigs.forEach((config) => {
            if(config.content.able === true) setAbleItem(config.plugintemplateId)
          })
          configsIA.forEach((model:ModelType) => {
            model.hasConfig = newTemplatesConfigs.findIndex((config) => model.id === config.plugintemplateId) !== -1
          })
          setModels(configsIA.sort((a:ModelType,b:ModelType) => a.id - b.id))
          setPluginconfigs(newTemplatesConfigs)
        }
      } else {
        setModels([])
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Falha ao recuperar modelos de IA.',
        })
      }
    } catch(e){
      console.log(e)
      setModels([])
        Swal.fire({
          icon: 'error',
          title: 'Erro!',
          text: 'Falha ao recuperar modelos de IA.',
        })
    }
  }

  const init = async () => {
    setLoading(true);
    await getConfigs();
    setLoading(false);
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{ i18n.t(`chat.title_config`) }</span>
                <BreadCrumbs />
              </h3>
            </div>
            <div className='card-body'>
              <div className="d-flex flex-row flex-wrap gap-7">
              {models.map((model) => (
                <CardIA
                  name={model.name}
                  description={model.description}
                  activeItem={activeItem}
                  able={ableItem === model.id}
                  templateId={model.id}
                  image={model.image}
                  key={model.id}
                  hasConfig={model.hasConfig}
                />
              ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const IAConfig: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          { title: 'Dashboard', path: 'dashboard', isActive: true },
          { title: i18n.t(`freight.title`), path: 'configuracoes/taxa-entrega', isActive: true },
        ]}
      >
        {i18n.t(`freight.title`)}
      </PageTitle>
      <IAConfigContent />
    </>
  )
}

export { IAConfig }
