import {FC, useEffect, useState} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {Datatable} from '../../../../components/Datatable/Datatable'
import type {MetaType} from '../../../../components/Datatable/Datatable'
import {Button} from '../../../../components/Button/Button'
import useModals from '../../../../hooks/useModals'
import {DELETE, GET, POST, PUT} from '../../../../services/api'
import {Loading} from '../../../../components/Loading/Loading'
import {ModalDelete} from '../../../../components/Modals/General/ModalDelete/ModalDelete'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import moment from 'moment'
import { i18n } from '../../../../translate/i18n'
import { BreadCrumbs } from '../../../../components/BreadCrumbs/breadCrumbrs'

type ResponseType = {
  id: number
  catalog: string
  status: boolean
  fotoCatalog: string
}

const CatalogosContent: FC = () => {
  const navigate = useNavigate()
  const {showDeleteModal} = useModals()
  const [loading, setLoading] = useState(false)

  const [catalogs, setCatalogs] = useState<ResponseType[]>([])
  const [id, setId] = useState<number | null>(null)
  const [meta, setMeta] = useState<MetaType | undefined>(undefined)

  const switchStatus = async (Id: number, status: boolean) => {
    try {
      const switchCatalog = await PUT(`/catalog/${Id}`, {
        status: !status ? 1 : 0,
      })
      if(switchCatalog.success){
        const newCatalogs = catalogs.map((catalog)=> {
          if(catalog.id === id){
            catalog.status = !status;
          }
          return catalog;
        })
        setCatalogs(newCatalogs)
      }
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const getCatalogs = async () => {
    try {
      setLoading(true)
      const res = await GET(`/catalog`)
      console.log(res)
      const news = res.data.map((row: any) => {
        let imgCatalog = null
        if(row.imgCatalog){
          imgCatalog = row.imgCatalog.substring(0, 4) === 'http'
                ? row.imgCatalog
                : process.env.REACT_APP_AWS_S3_URL + row.imgCatalog
        }
        return {
          id: row.id,
          catalog: row.catalog,
          status: row.status,
          order: row.order,
          totalproducts: row.products.length,
          imgCatalog: imgCatalog,
          validity: moment(row.dateStart)
            .format('DD/MM/YYYY')
            .concat(' - ', moment(row.dateEnd).format('DD/MM/YYYY')),
        }
      })
      setCatalogs(news)
      setMeta(res.meta)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const deleteCatalog = async () => {
    try {
      setLoading(true)
      const gridRes = await DELETE(`/catalog/${id}`)
      if (gridRes.success === true) {
        Swal.fire({
          icon: 'success',
          title: 'Catálogo deletada',
          text: 'Deleção realizada com sucesso!',
        }).then(async () => await getCatalogs())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Erro',
          text: 'Erro ao deletar o catálogo!',
        }).then(async () => await getCatalogs())
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Erro',
        text: 'Erro ao deletar o catálogo!',
      }).then(async () => await getCatalogs())
      console.log(e)
    }
  }

  useEffect(() => {
    getCatalogs()
  }, [])

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className='row g-5 gx-xxl-12'>
          <div className='card card-xxl-stretch mb-5 mb-xl-12'>
            <div className='card-header py-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bolder fs-3 mb-1'>{i18n.t(`smart_order.catalogs`)}</span>
                <BreadCrumbs />
                <span className='text-muted mt-1 fw-bold fs-7'>
                  {i18n.t(`smart_order.listened_catalogs`)}
                </span>
              </h3>
              <div className='card-toolbar'>
                <Button
                  text={i18n.t(`buttons.add_catalog`)}
                  color='primary'
                  iconColor='#FFF'
                  icon='fas fa-plus'
                  size='small'
                  horizontalPadding={6}
                  btnAttr={{
                    type: 'button',
                  }}
                  click={() => navigate('/catalog/criar')}
                  btnClass='mb-5 fw-bolder'
                />
              </div>
            </div>
            <div className='card-body'>
              <Datatable
                data={catalogs}
                headers={[
                  {key: i18n.t(`smart_order.image`), value: 'imgCatalog', type: 'image'},
                  {key: i18n.t(`smart_order.catalog`), value: 'catalog', type: 'default'},
                  {key: i18n.t(`smart_order.validity`), value: 'validity', type: 'default'},
                  {key: i18n.t(`smart_order.product`), value: 'totalproducts', type: 'default'},
                  {key: i18n.t(`smart_order.order`), value: 'order', type: 'default'},
                  {
                    key: i18n.t(`general.active`),
                    value: 'status',
                    type: 'check',
                    action: (item) => switchStatus(item.id, item.status),
                  },
                  {key: i18n.t(`general.actions`), value: 'acoes', type: ''},
                ]}
                options={[
                  {
                    icon: 'fas fa-pen',
                    action: (item) => {
                      navigate(`/catalog/editar/${item.id}`)
                    },
                    title: `${i18n.t(`general.edit`)} ${i18n.t(`smart_order.catalog`)}`,
                  },
                  {
                    icon: 'fas fa-trash',
                    action: (item) => {
                      showDeleteModal(`${i18n.t(`general.delete`)} ${i18n.t(`smart_order.catalog`)}`)
                      setId(item.id)
                    },
                    title: `${i18n.t(`general.delete`)} ${i18n.t(`smart_order.catalog`)}`,
                  },
                ]}
                color='primary'
              />
            </div>
          </div>

          <ModalDelete confirmAction={() => deleteCatalog()} />
        </div>
      )}
    </>
  )
}

const Catalogos: FC = () => {
  return (
    <>
      <PageTitle
        breadcrumbs={[
          {title: 'Dashboard', path: 'dashboard', isActive: true},
          {title: i18n.t(`smart_order.catalogs`), path: 'catalog', isActive: true},
        ]}
      >
        {i18n.t(`smart_order.catalogs`)}
      </PageTitle>
      <CatalogosContent />
    </>
  )
}

export {Catalogos}
