import { FC } from "react";
import './index.css'
import { useLocation } from "react-router-dom";
import usePluginsSpace from "../../../../app/hooks/usePluginSpace";
import { rotas } from '../../../../app/components/BreadCrumbs/breadCrumbrs'
import { log } from "console";


const FAQ: FC = () => {
    return (
        <div className="d-flex">
            <p className="fs-7 w-100 m-0 text-gray-700 fw-bold px-4 py-3 bg-hover-light text-hover-primary gap-5" onClick={() => {
                window.open('https://manual.ninegrid.com.br/index.php/perguntas/')
            }}>

                <i className="bi bi-info-lg fs-2 me-2"></i>
                <span>FAQ</span>
            </p>
        </div>
    )
}


const Suport: FC = () => {
    const { hasERP, isProtheus, isWintor } = usePluginsSpace()

    function goSuport() {

        if (!hasERP) {
            window.open('https://ninegrid-pb.atlassian.net/servicedesk/customer/portals')
        } else if(isProtheus || isWintor || hasERP){
            window.open('https://tdn.totvs.com/display/VD/Suporte')
        }

    }


    return (
        <>
            <div className="d-flex ">
                <p className="fs-7 w-100 m-0 text-gray-700 fw-bold px-4 py-3 bg-hover-light text-hover-primary gap-5" onClick={() =>
                    goSuport()
                }>

                    <i className="bi bi-chat-dots fs-2 me-2"></i>
                    <span>Suport</span>
                </p>
            </div >
        </>
    )
}



const Helper: FC = () => {

    const location = useLocation()
    const { menu } = usePluginsSpace()
    const { manual, isProtheus, isWintor, hasERP } = usePluginsSpace()


    function tradutorManual(path: string) {

        let manualName = null

        switch (path) {
            case 'webapp':
                manualName = 'application'
                break;

            case 'order/saleorder':
                manualName = 'order'
                break;

            case 'product_fashion':
                manualName = 'smartorder'
                break;

            case 'product_varejo':
                manualName = 'smartorder'
                break;

            case 'product_food':
                manualName = 'smartorder'
                break;

            case 'variation':
                manualName = 'smartorder'
                break;
            case 'grid':
                manualName = 'smartorder'
                break;
            case 'category':
                manualName = 'smartorder'
                break;
            case 'mark':
                manualName = 'smartorder'
                break;
            case 'measurement_unit':
                manualName = 'smartorder'
                break;
            case 'catalog':
                manualName = 'smartorder'
                break;

            /*Configuçãoo de Loja */
            case 'checkout':
                manualName = 'settings_store'
                break;
            case 'message':
                manualName = 'settings_store'
                break;
            case 'planos-pagamento':
                manualName = 'settings_store'
                break;
            case 'turnos':
                manualName = 'settings_store'
                break;
            case 'modalidades':
                manualName = 'settings_store'
                break;
            case 'taxa-entrega':
                manualName = 'settings_store'
                break;
            case 'checkout':
                manualName = 'settings_store'
                break;
            /*Configuçãoo da Conta */

            case 'conta':
                manualName = 'settings_account'
                break;
            case 'grupos':
                manualName = 'settings_account'
                break;
            case 'usuarios':
                manualName = 'settings_account'
                break;
            case 'termos':
                manualName = 'settings_account'
                break;

            /*Indicadores */
            case 'summary_access':
                manualName = 'indicators'
                break;
            case 'chartorders':
                manualName = 'indicators'
                break;
            case 'chartproducts':
                manualName = 'indicators'
                break;
            case 'report':
                manualName = 'report'
                break;
            /*Importar Imagem */
            case 'prepare_import_image_product':
                manualName = 'import_image'
                break;
            case 'prepare_import_image_categories':
                manualName = 'import_image'
                break;
            case 'prepare_import_image_product_sku':
                manualName = 'import_image'
                break;
            /*Integracao */

            case 'pluginconfig':
                manualName = 'integration'
                break;
            /* support*/

            case 'logs':
                manualName = 'support'
                break;
            case 'charge_integration':
                manualName = 'support'
                break;

            /* Fila de Espera*/
            case 'queue':
                manualName = null
                break;

            /* Booking */

            case 'booking':
                manualName = 'booking'
                break;

            /* Content = feed ? */
            case 'content':
                manualName = 'feed'
                break;

            case 'service':
                manualName = 'service'
                break;
        }

        // verificar se a pluginspace possui integracao com o Protheus ou com Wintor

        if (hasERP) {
            return null
        }

        if (isProtheus) {
            return `${manualName}_protheus`
        }

        if (isWintor) {
            return `${manualName}_wintor`
        }

        if (hasERP) {
            return null
        }

        return manualName
    }

    function goToManual2() {
        const rotasFiltradas = rotas.filter(route => {
            // Verifica se o caminho da rota corresponde exatamente ao caminho atual
            if (route.path === location.pathname) {
                return true;
            }

            // Verifica se o caminho da rota é o início do caminho atual
            if (location.pathname.includes(route.path + '/') && !location.pathname.includes('import')) {
                return true;
            }

            // Verifica se o caminho da rota é uma rota dinâmica e corresponde ao início do caminho atual
            if (route.path.includes('/:') && location.pathname.includes(route.path + '/')) {
                const staticPart = route.path.split('/:')[0];

                const hasSomethingAfter = route.path.split('/:')[1].split('/')

                if (location.pathname.includes(staticPart)) {
                    if (hasSomethingAfter.length > 1) {
                        return location.pathname.includes(hasSomethingAfter[hasSomethingAfter.length - 1]) ? true : false
                    }

                    return true;
                }
            }
            return false;
        });

        const crumbs = rotasFiltradas
            .map(({ path, ...rest }) => {
                const hasParams = path.includes(':');
                const newPath = hasParams
                    ? Object.keys(location.pathname).length
                        ? Object.keys(location.pathname).reduce(
                            (path, param: any) =>
                                path.replace(`:${param}`, location.pathname[param]),
                            path
                        )
                        : path
                    : path;

                return {
                    path: newPath,
                    ...rest
                };
            });
        // recuperar o menu lateral
        // achar qual que é o pai que esta no crumbsa e add

        let submenuTitle = ''
        menu.map(menu => {
            const find = menu.submenu.find(subM => subM.router.includes(crumbs[crumbs.length - 1].path))

            if (find && !submenuTitle) {
                submenuTitle = menu.name
                if(submenuTitle === "menu") submenuTitle = "smartorder"
            }

        })

        if (hasERP) {
            return window.open('https://tdn.totvs.com/pages/viewpage.action?pageId=829295035');
        }

        if (isProtheus) {
            return window.open('https://tdn.totvs.com/pages/viewpage.action?pageId=829295035');
        }

        if (isWintor) {
            return window.open('https://tdn.totvs.com/pages/viewpage.action?pageId=829295035');
        }

        if (submenuTitle) {
            const manualSelect = manual.find(manual => manual.name === submenuTitle)
            
            if (manualSelect) {
                window.open(manualSelect.route)
                
                return
            }
        } else if (crumbs[0]) {
            let name = ''
            if (crumbs[0].name.toLocaleLowerCase().includes('cliente')) {
                name = 'customer'
            }

            if(crumbs[0].name.toLocaleLowerCase().includes('web')){
                name = 'application'
            }

            if(crumbs[0].name.toLocaleLowerCase().includes('pedidos')){
                name = 'order'
            }

            const nameToUse = name ? name.toLowerCase() : crumbs[0].name.toLowerCase()
            const manualSelect = manual.find(manual => manual.name === nameToUse)
            
            if (manualSelect) {
                window.open(manualSelect.route)
                
                return
            }
        }
        
        window.open('https://manual.ninegrid.com.br/index.php/perguntas/')
    }
    return (
        <div className="d-flex gap-5">

            <p className="fs-7 w-100 m-0 text-gray-700 fw-bold px-4 py-3 bg-hover-light text-hover-primary gap-5" onClick={() => {
                goToManual2()
            }}>
                <i className="bi bi-book-fill fs-2 me-2"></i>
                <span> Manual </span>
            </p>
        </div>
    )
}

export { FAQ, Helper, Suport }